import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RowData, RowAttr, parseData, calcData, calcDataPl, calcDataKeppinRnk, calcTotalSub } from "@/store/saleslist/salesListTmp"; //saleslistのRowDataを流用する

import { SalesListTransColRowModel, headersRowMonthly, headersRowWeekly, headersRowDaily, termColKeys, drillDownMaxLv,drillDownLv1MaxLv,drillDownLv2MaxLv } from "@/components/saleslisttrans/SalesListTransTableModel";
import { RequestParam } from "@/assets/apitype/salesList";
import { YM, YW, CodeName } from "@/store/common";

import * as calcUtil from "@/util/calcUtil";
import * as compareUtil from "@/util/compareUtil";
import * as arrayUtil from "@/util/arrayUtil";
import moment from "moment";
import { dataTypeIs } from "./salesListTransSave";
moment.updateLocale("ja", {
  months: ["1月","2月","3月","4月","5月","6月","7月","8月","9月","10月","11月","12月",],
  weekdays: ["日曜日", "月曜日", "火曜日", "水曜日", "木曜日", "金曜日", "土曜日"],
  weekdaysShort: ["日", "月", "火", "水", "木", "金", "土"]
});

//強制表示行
export const mustVisibleRowsKeyMonthly = [
];
export const mustVisibleRowsKeyDaily = [
];
export const mustVisibleRowsKeyWeekly = [
];
//受注関連項目
export const unVisibleRowsKeyAdjus = [
  "JJT", "JJP", "JJR", "JJD",
  "JKT", "JKP", "JKR", "JKD",
  "JRT", "JRP", "JRD", 
  //欠品責任別
  "KI1KA", "KI1KR", "KI1KK",
  "KI2KA", "KI2KR", "KI2KK",
  "KI3KA", "KI3KR", "KI3KK",

  //欠品区分別
  "K10KA", "K10KR", "K10KK",
  "K11KA", "K11KR", "K11KK",
  "K12KA", "K12KR", "K12KK",
  "K13KA", "K13KR", "K13KK",
  "K14KA", "K14KR", "K14KK",
  "K15KA", "K15KR", "K15KK",
  "K16KA", "K16KR", "K16KK",
  "K17KA", "K17KR", "K17KK",
  "K19KA", "K19KR", "K19KK",
  "K20KA", "K20KR", "K20KK",
  "K21KA", "K21KR", "K21KK",
  "K22KA", "K22KR", "K22KK",
  "K29KA", "K29KR", "K29KK",
  "K30KA", "K30KR", "K30KK",
  "K31KA", "K31KR", "K31KK",
  "K32KA", "K32KR", "K32KK",
  "K33KA", "K33KR", "K33KK",
  "K41KA", "K41KR", "K41KK",
  "K34KA", "K34KR", "K34KK",
  "K39KA", "K39KR", "K39KK",
  "K40KA", "K40KR", "K40KK",
  "K42KA", "K42KR", "K42KK",
  "K43KA", "K43KR", "K43KK",
  "K44KA", "K44KR", "K44KK",
  "K49KA", "K49KR", "K49KK",
  "K50KA", "K50KR", "K50KK",
];

export type RetrievedId = {uuid: string, lv:number};

export type findedCell = {
  row: number,
  col: number,
  data: any,
  text: string,
}

export type RowInfo = {
  dataGroup:RowDataGroup,
  rowKey:string,

  rowIndex:number,  //同一集計行内でのrowKeyのindex
  rowIndexLast:boolean, //同一集計行内での最後のrowKeyフラグ

  rowHeaderIndex1:number,  //同一集計行内での同一rowHeader1のindex
  rowHeaderIndex2:number,  //同一集計行内での同一rowHeader1&2のindex
  rowHeaderIndex1Last:boolean,
  rowHeaderIndex2Last:boolean,

}

export type YMData = {
  YMID?: number | null, //年月
  data:RowData,
}

export type RowDataGroup = {
  TP: "total" | "grouping1" | "grouping2" | "lv1" | "lv2" | "lv3" | "lv4" | "lv5" | "lv6" | "lv7" | "lv8",
  drillDowned: boolean,
  no?: string,
  CAT: "def" | "sej" | "mix", //一般/SEJ/混在
  H1ID?: string | null, H2ID?: string | null, H3ID?: string | null, H4ID?: string | null, H5ID?: string | null, H6ID?: string | null, H7ID?: string | null, H8ID?: string | null,
  H1CD?: string | null, H1NM?: string | null, H2CD?: string | null, H2NM?: string | null, H3CD?: string | null, H3NM?: string | null, H4CD?: string | null, H4NM?: string | null, H5CD?: string | null, H5NM?: string | null, H6CD?: string | null, H6NM?: string | null, H7CD?: string | null, H7NM?: string | null, H8CD?: string | null, H8NM?: string | null,
  //TC/DC,自社/委託
  CDIV?: string | null,
  TDIV?: string | null,

  ymDatas:YMData[],
  dataTTL:RowData,
}

//Page State
export type SalesListTransTmpState = {
  //年月
  ymList: YM[],
  ywList: YW[],
  group2List: CodeName[],
  shitenList: CodeName[],
  centerList: CodeName[],
  makerList: CodeName[],
  categoryList: CodeName[],

  accordionOpen: boolean,
  //検索する条件
  requestParam : RequestParam,
  requestParamQueue : RequestParam[],
  //検索終了した条件
  retrievedIDs: RetrievedId[],
  //共有URL
  shareURL: string,

  progress: Record<string, unknown>,
  dataYMs: (YM|YW)[],
  dataGroups: RowDataGroup[],
  rowInfos: RowInfo[],
  rows: any[][],
  mergeCells: {row: number, col: number, rowspan: number, colspan: number}[],
  selectionRowStart: number,
  selectionRowEnd: number,
  selectionRowInfo:RowInfo,

  findKeyword:string,
  findedCellSelect: findedCell,
  findedCells: findedCell[],
  findedCellsOver: boolean,

  lastUpdate: string | null,
  adjus0Imported: boolean | null,
  errorMessage: string | null,
};

export const initialState: SalesListTransTmpState = {
  //年月
  ymList: [],
  ywList: [],
  group2List: [],
  shitenList: [],
  centerList: [],
  makerList: [],
  categoryList: [],

  accordionOpen: true,
  requestParam : {},
  requestParamQueue: [],
  retrievedIDs: [],
  //共有URL
  shareURL: '',

  progress: {},
  dataYMs: [],
  dataGroups: [],
  rowInfos: [],
  rows: [],
  mergeCells: null,
  selectionRowStart: -1,
  selectionRowEnd: -1,
  selectionRowInfo:null,

  findKeyword:'',
  findedCellSelect: null,
  findedCells: [],
  findedCellsOver: false,

  lastUpdate:  null,
  adjus0Imported: false,
  errorMessage: null,
};

//Page Slice
export type SalesListTransTmpReducer = {
  setYMList: (state:SalesListTransTmpState, action: PayloadAction<YM[]>) => void,
  setYWList: (state:SalesListTransTmpState, action: PayloadAction<YW[]>) => void,
  setGroup2List: (state: SalesListTransTmpState, action: PayloadAction<CodeName[]>) => void,
  setShitenList: (state: SalesListTransTmpState, action: PayloadAction<CodeName[]>) => void,
  setCenterList: (state: SalesListTransTmpState, action: PayloadAction<CodeName[]>) => void,
  setMakerList: (state: SalesListTransTmpState, action: PayloadAction<CodeName[]>) => void,
  setCategoryList: (state: SalesListTransTmpState, action: PayloadAction<CodeName[]>) => void,
  setAccordionOpen: (state:SalesListTransTmpState, action: PayloadAction<boolean>) => void,
  setRequestParam: (state:SalesListTransTmpState, action: PayloadAction<RequestParam>) => void,
  addRequestParamQueue: (state:SalesListTransTmpState, action: PayloadAction<RequestParam[]>) => void,
  setRequestParamQueue: (state:SalesListTransTmpState, action: PayloadAction<RequestParam[]>) => void,
  clearRequestParamQueue: (state:SalesListTransTmpState, action: PayloadAction<RequestParam[]>) => void,
  setRetrievedID: (state:SalesListTransTmpState, action: PayloadAction<RetrievedId>) => void,
  addRetrievedID: (state:SalesListTransTmpState, action: PayloadAction<RetrievedId>) => void,
  removeRetrievedIDsOfLv: (state:SalesListTransTmpState, action: PayloadAction<number>) => void,
  setShareURL: (state:SalesListTransTmpState, action: PayloadAction<string>) => void,
  putProgress: (state:SalesListTransTmpState, action: PayloadAction<string>) => void,
  removeProgress: (state:SalesListTransTmpState, action: PayloadAction<string>) => void,
  searched: (state: SalesListTransTmpState, action: PayloadAction<{
    param: RequestParam, datas: RowData[], sort: { colKey: string, rowKey: string, asc: boolean }, colRowModel: SalesListTransColRowModel, visibleRowsKey: string[],
    attrs: RowAttr[], grouping1: boolean, grouping2: boolean,
  }>) => void,
  execSort: (state: SalesListTransTmpState, action: PayloadAction<{
    sort: { colKey: string, rowKey: string, asc: boolean }, colRowModel: SalesListTransColRowModel, visibleRowsKey: string[], dataTypeIs:"daily"|"monthly"|"weekly",
    grouping1: boolean, grouping2: boolean
  }>) => void,
  setDatas: (state:SalesListTransTmpState, action: PayloadAction<{dataGroups:RowDataGroup[], colRowModel:SalesListTransColRowModel, visibleRowsKey:string[], dataTypeIs:"daily"|"monthly"|"weekly"}>) => void,
  rowSelectionChange: (state:SalesListTransTmpState, action: PayloadAction<{start:number,end:number}>) => void,
  refreshTable: (state: SalesListTransTmpState, action: PayloadAction<{
    colRowModel: SalesListTransColRowModel, visibleRowsKey: string[], dataTypeIs:"daily"|"monthly"|"weekly",
    sort: { colKey: string, rowKey: string, asc: boolean }, grouping1: boolean, grouping2: boolean
  }>) => void,
  clearFindKeyword: (state) => void,
  setFindKeyword: (state:SalesListTransTmpState, action: PayloadAction<string>) => void,
  setFindedCellSelect: (state:SalesListTransTmpState, action: PayloadAction<findedCell>) => void,
  setFindedCells: (state:SalesListTransTmpState, action: PayloadAction<{findedCells:findedCell[], over:boolean}>) => void,
  setLastUpdate: (state:SalesListTransTmpState, action: PayloadAction<string | null>) => void,
  setAdjus0Imported: (state:SalesListTransTmpState, action: PayloadAction<boolean | null>) => void,
  setErrorMessage: (state:SalesListTransTmpState, action: PayloadAction<string>) => void,
}

const createReducerContent = ():SalesListTransTmpReducer => {return {

    // Option YM
    setYMList(state:SalesListTransTmpState, action: PayloadAction<YM[]>) {
      state.ymList = action.payload;
    },
    setYWList(state:SalesListTransTmpState, action: PayloadAction<YW[]>) {
      state.ywList = action.payload;
    },
    setGroup2List(state: SalesListTransTmpState, action: PayloadAction<CodeName[]>) {
      state.group2List = action.payload;
    },
    setShitenList(state: SalesListTransTmpState, action: PayloadAction<CodeName[]>) {
      state.shitenList = action.payload;
    },
    setCenterList(state: SalesListTransTmpState, action: PayloadAction<CodeName[]>) {
      state.centerList = action.payload;
    },
    setMakerList(state: SalesListTransTmpState, action: PayloadAction<CodeName[]>) {
      state.makerList = action.payload;
    },
    setCategoryList(state: SalesListTransTmpState, action: PayloadAction<CodeName[]>) {
      state.categoryList = action.payload;
    },

    setAccordionOpen(state:SalesListTransTmpState, action: PayloadAction<boolean>) {
      state.accordionOpen = action.payload;
    },
    //検索条件
    setRequestParam(state:SalesListTransTmpState, action: PayloadAction<RequestParam>) {
      state.requestParam = action.payload;
    },
    addRequestParamQueue(state:SalesListTransTmpState, action: PayloadAction<RequestParam[]>) {
      state.requestParamQueue = [...state.requestParamQueue, ...action.payload];
    },
    setRequestParamQueue(state:SalesListTransTmpState, action: PayloadAction<RequestParam[]>) {
      state.requestParamQueue = action.payload;
    },
    clearRequestParamQueue(state) {
      state.requestParamQueue = [];
    },
    setRetrievedID(state:SalesListTransTmpState, action: PayloadAction<RetrievedId>) {
      state.retrievedIDs = [action.payload];
    },
    addRetrievedID(state:SalesListTransTmpState, action: PayloadAction<RetrievedId>) {
      const retrievedIDs = [...state.retrievedIDs];
      retrievedIDs.push(action.payload);
      state.retrievedIDs = retrievedIDs;
    },
    removeRetrievedIDsOfLv(state:SalesListTransTmpState, action: PayloadAction<number>) {
      let retrievedIDs = [...state.retrievedIDs];
      const lv = action.payload;
      retrievedIDs = retrievedIDs.filter(retrievedID => retrievedID.lv < lv);
      state.retrievedIDs = retrievedIDs;
    },

    setShareURL(state:SalesListTransTmpState, action: PayloadAction<string>) {
      state.shareURL = action.payload;
    },

    putProgress(state:SalesListTransTmpState, action: PayloadAction<string>) {
      const key = action.payload;
      const progressNew = {...state.progress};
      progressNew[key] = true;
      state.progress = progressNew;
    },
    removeProgress(state:SalesListTransTmpState, action: PayloadAction<string>) {
      const key = action.payload;
      const progressNew = {};
      Object.keys(state.progress).forEach(k => {
        if(key != k) {
          progressNew[k] = true;
        }
      })
      state.progress = progressNew;
    },
    searched(state: SalesListTransTmpState, action: PayloadAction<{
      param: RequestParam, datas: RowData[], sort: { colKey: string, rowKey: string, asc: boolean }, colRowModel: SalesListTransColRowModel, visibleRowsKey: string[],
      attrs: RowAttr[], grouping1: boolean, grouping2: boolean,
    }>) {
      const colRowModel = action.payload.colRowModel;
      const sort = action.payload.sort;
      //計算
      let datas = action.payload.datas;
      let attrs = action.payload.attrs;
      const grouping1 = action.payload.grouping1;
      const grouping2 = action.payload.grouping2;
      const param = action.payload.param;
      let visibleRowsKey = action.payload.visibleRowsKey;

      let lvMax = 1;
      for (let i = drillDownMaxLv; i >= 2; i--) {
        if (param['d' + i + '_axis']) {
          lvMax = i;
          break;
        }
      }

      const _dataTypeIs = dataTypeIs(param.dataType);

      //URL共有判定
      const notShare = !param.shareid;

      datas = parseData(datas);
      let dataYMs:(YM|YW)[];
      //日次検索の場合
      if(param.dataType == 'daily') {
        dataYMs = [];
        let ym = param.ym;
        const momentymd = moment(ym + '01');
        while(param.ym == ym) {
          dataYMs.push(new YM({code:momentymd.format('YYYYMMDD'), name:momentymd.format('MM/DD(ddd)'), thisMonth:false}));
          momentymd.add(1, 'days');
          ym = momentymd.format('YYYYMM');
        }
        {//重複除去
          //日付、実績あり（欠品またはPB実績有効）順で並び替え
          datas.sort((a, b) => {
            const objA = a;
            const objB = b;
            let comp = 0;
            comp = compareUtil.compareNumber2(objA ? objA.YMID : null, objB ? objB.YMID : null, true);
            if (comp == 0) {
              comp = compareUtil.compareNumber2(objA ? objA.KKT : null, objB ? objB.KKT : null, false);
              if (comp == 0) {
                comp = compareUtil.compareNumber2(objA ? objA.KJT : null, objB ? objB.KJT : null, false);
                if (comp == 0) {
                  comp = compareUtil.compareNumber2(objA ? objA.PBT : null, objB ? objB.PBT : null, false);
                }
              }
            }
            return comp;
          });
          const ids = datas.map(data => {
            let id: string = '' + data.YMID;
            for (let i = 1; i <= drillDownMaxLv; i++) {
              id = id + '' + data['H' + i + 'CD'];
            }
            return id;
          });
          datas = datas.filter((data, index) => {
            let id: string = '' + data.YMID;
            for (let i = 1; i <= drillDownMaxLv; i++) {
              id = id + '' + data['H' + i + 'CD'];
            }
            return ids.indexOf(id) === index;
          });
        }
      }
      //週次検索の場合
      else if(param.dataType == 'weekly'){
        dataYMs = state.ywList.filter(yw => yw.code >= param.ywFrom && yw.code <= param.yw);
      }
      //月次検索の場合
      else {
        dataYMs = state.ymList.filter(ym => ym.code >= param.ymFrom && ym.code <= param.ym);
      }
      dataYMs = dataYMs.sort((a,b) => a.code == b.code ? 0 : a.code < b.code ? -1 : 1);
      let dataGroups = convertDataGroups(datas);
      dataGroups = parseAttr(dataGroups, attrs);
      dataGroups = makeDatas(dataGroups, grouping1, grouping2);

      //ドリルダウンかどうか
      const drillDown = notShare && drillDownLv2MaxLv.indexOf(param.drillDownType) !== -1;
      if(drillDown) {
        const drillDownLv = drillDownLv2MaxLv.indexOf(param.drillDownType) + 2;
        dataGroups = calcDataGroups(dataGroups, state.dataGroups,
          [param.axisType, param.d2_axis, param.d3_axis, param.d4_axis, param.d5_axis, param.d6_axis, param.d7_axis, param.d8_axis, ]  
        ); //計算項目の計算
        dataGroups = doSortGroup(dataGroups, dataYMs, sort.colKey, sort.rowKey, sort.asc, true, false);  //一旦LVでソート
        const dataGroupMap = {};
        dataGroups.forEach(dataGroup => {
          //CAT:H1CD:H2CD:H3CD,CAT:H1CD:H2CD:H3CD, 形式
          let parentKey: string = dataGroup.CAT;
          for (let i = 2; i <= drillDownLv; i++) {
            parentKey = parentKey + ':' + dataGroup['H' + (i - 1) + 'ID'];
          }
          let subDataGroups = dataGroupMap[parentKey];
          if(!subDataGroups) {
            subDataGroups = [];
            dataGroupMap[parentKey] = subDataGroups;
          }
          subDataGroups.push(dataGroup);
        });

        dataGroups = [...state.dataGroups];
        Object.keys(dataGroupMap).forEach(parentKey => {
          let subDataGroups = dataGroupMap[parentKey];
          if(subDataGroups && subDataGroups.length > 0) {
            //CAT:H1CD:H2CD:H3CD 形式
            const parentKeys = parentKey.split(':');
            //差し込み位置の取得
            const parentIndex =
              drillDownLv == 8 ? dataIndexOfLv7(dataGroups, parentKeys[0], parentKeys[1], parentKeys[2], parentKeys[3], parentKeys[4], parentKeys[5], parentKeys[6], parentKeys[7]) :
              drillDownLv == 7 ? dataIndexOfLv6(dataGroups, parentKeys[0], parentKeys[1], parentKeys[2], parentKeys[3], parentKeys[4], parentKeys[5], parentKeys[6]) :
              drillDownLv == 6 ? dataIndexOfLv5(dataGroups, parentKeys[0], parentKeys[1], parentKeys[2], parentKeys[3], parentKeys[4], parentKeys[5]) :
              drillDownLv == 5 ? dataIndexOfLv4(dataGroups, parentKeys[0], parentKeys[1], parentKeys[2], parentKeys[3], parentKeys[4]) :
              drillDownLv == 4 ? dataIndexOfLv3(dataGroups, parentKeys[0], parentKeys[1], parentKeys[2], parentKeys[3]) :
              drillDownLv == 3 ? dataIndexOfLv2(dataGroups, parentKeys[0], parentKeys[1], parentKeys[2]) :
              drillDownLv == 2 ? dataIndexOfLv1(dataGroups, parentKeys[0], parentKeys[1]) :
              -1;
            if(parentIndex >= 0) {
              const parent = dataGroups[parentIndex];
              subDataGroups = doSortGroup(subDataGroups, dataYMs, sort.colKey, sort.rowKey, sort.asc, false, false);  //ソート
              subDataGroups = resetGroupRowNo(subDataGroups, dataGroups[parentIndex], lvMax, grouping1, grouping2); //列番号の振りなおし
              dataGroups = spliceDataGroup(parentIndex+1, subDataGroups, dataGroups); //差し込み
              parent.drillDowned = true; //ドリルダウン済みにマーク
            }
          }
        });
      }
      else {
        dataGroups = calcTotalGroup(dataGroups); //合計行の作成
        dataGroups = calcDataGroups(dataGroups, dataGroups,
          [param.axisType, param.d2_axis, param.d3_axis, param.d4_axis, param.d5_axis, param.d6_axis, param.d7_axis, param.d8_axis, ]  
        ); //計算項目の計算
        dataGroups = doSortGroup(dataGroups, dataYMs, sort.colKey, sort.rowKey, sort.asc, false, !notShare);  //ソート
        dataGroups = resetGroupRowNo(dataGroups, null, lvMax, grouping1, grouping2); //列番号の振りなおし
      }

      const rowInfos = convertRowInfos(dataGroups, visibleRowsKey, _dataTypeIs, state.adjus0Imported);
      Object.assign(state, {
        dataYMs: dataYMs,
        dataGroups: dataGroups,
        rowInfos: rowInfos,
        rows: convertRows(rowInfos, colRowModel, dataYMs, _dataTypeIs),
        // mergeCells: createMergeCells(rowInfos, colRowModel, visibleRowsKey), //描写が遅すぎる
      });
    },
    execSort(state:SalesListTransTmpState, action: PayloadAction<{
      sort: { colKey: string, rowKey: string, asc: boolean }, colRowModel: SalesListTransColRowModel, visibleRowsKey: string[], dataTypeIs:"daily"|"monthly"|"weekly",
      grouping1: boolean, grouping2: boolean
    }>) {
      const dataTypeIs = action.payload.dataTypeIs;
      const colRowModel = action.payload.colRowModel;
      const sort = action.payload.sort;
      const colKey = sort.colKey;
      const rowKey = sort.rowKey;
      const asc = sort.asc;
      const visibleRowsKey = action.payload.visibleRowsKey;
      const grouping1 = action.payload.grouping1;
      const grouping2 = action.payload.grouping2;

      let dataGroups = [...state.dataGroups];
      dataGroups = makeDatas(dataGroups, grouping1, grouping2);
      dataGroups = doSortGroup(dataGroups, state.dataYMs, colKey, rowKey, asc, false, false);
      dataGroups = resetGroupRowNo(dataGroups, null, drillDownMaxLv, grouping1, grouping2); //列番号の振りなおし

      const rowInfos = convertRowInfos(dataGroups, visibleRowsKey, dataTypeIs, state.adjus0Imported);
      Object.assign(state, {
        dataGroups: dataGroups,
        rowInfos: rowInfos,
        rows: convertRows(rowInfos, colRowModel, state.dataYMs, dataTypeIs),
        // mergeCells: createMergeCells(rowInfos, colRowModel, visibleRowsKey),
      });
    },
    setDatas(state:SalesListTransTmpState, action: PayloadAction<{dataGroups:RowDataGroup[], colRowModel:SalesListTransColRowModel, visibleRowsKey:string[], dataTypeIs:"daily"|"monthly"|"weekly"}>) {
      const dataTypeIs = action.payload.dataTypeIs;
      const colRowModel = action.payload.colRowModel;
      const dataGroups = action.payload.dataGroups;
      const visibleRowsKey = action.payload.visibleRowsKey;
      const rowInfos = convertRowInfos(dataGroups, visibleRowsKey, dataTypeIs, state.adjus0Imported);
      Object.assign(state, {
        dataGroups: dataGroups,
        rowInfos: rowInfos,
        rows: convertRows(rowInfos, colRowModel, state.dataYMs, dataTypeIs),
        // mergeCells: createMergeCells(rowInfos, colRowModel, visibleRowsKey),
      });
    },
    // editRowDatas(state:SalesListTransTmpState, action: PayloadAction<{row:number, key:string, value:string|number|object|null, relatedValues?: {key:string, value:string|number|object|null}[]}[]>) {
    //   const newData = [...state.rows];
    //   action.payload.forEach((editData)=>{
    //     let data = newData[editData.row];
    //     data[editData.key] = editData.value;
    //     //関連データの更新
    //     if(editData.relatedValues) {
    //       editData.relatedValues.forEach(relatedValue => {
    //         data[relatedValue.key] = relatedValue.value;
    //       })
    //     }
    //   })
    //   state.rows = newData;
    // },
    // setRowDatas(state:SalesListTransTmpState, action: PayloadAction<RowData[]>) {
    //   state.rows = action.payload;
    // },
    rowSelectionChange(state:SalesListTransTmpState, action: PayloadAction<{start:number,end:number}>){
      state.selectionRowStart = action.payload.start;
      state.selectionRowEnd = action.payload.end;
      state.selectionRowInfo = state.rowInfos[action.payload.start];
    },
    refreshTable(state:SalesListTransTmpState, action: PayloadAction<{
      colRowModel: SalesListTransColRowModel, visibleRowsKey: string[], dataTypeIs:"daily"|"monthly"|"weekly",
      sort: { colKey: string, rowKey: string, asc: boolean }, grouping1: boolean, grouping2: boolean
    }>) {
      console.log('refreshTable');
      const dataTypeIs = action.payload.dataTypeIs;
      const visibleRowsKey = action.payload.visibleRowsKey;
      const colRowModel = action.payload.colRowModel;
      const sort = action.payload.sort;
      const colKey = sort.colKey;
      const rowKey = sort.rowKey;
      const asc = sort.asc;
      const grouping1 = action.payload.grouping1;
      const grouping2 = action.payload.grouping2;

      let dataGroups = [...state.dataGroups];
      dataGroups = makeDatas(dataGroups, grouping1, grouping2);
      dataGroups = doSortGroup(dataGroups, state.dataYMs, colKey, rowKey, asc, false, false);
      dataGroups = resetGroupRowNo(dataGroups, null, drillDownMaxLv, grouping1, grouping2); //列番号の振りなおし

      const rowInfos = convertRowInfos(state.dataGroups, visibleRowsKey, dataTypeIs, state.adjus0Imported);
      Object.assign(state, {
        rowInfos: rowInfos,
        rows: convertRows(rowInfos, colRowModel, state.dataYMs, dataTypeIs),
        // mergeCells: createMergeCells(rowInfos, colRowModel, visibleRowsKey),
      });
    },
    clearFindKeyword(state) {
      Object.assign(state, {
        findKeyword: '',
        findedCellSelect: null,
        findedCells: [],
        findedCellsOver: false,
      });
    },
    setFindKeyword(state:SalesListTransTmpState, action: PayloadAction<string>) {
      state.findKeyword = action.payload;
    },
    setFindedCellSelect(state:SalesListTransTmpState, action: PayloadAction<findedCell>) {
      state.findedCellSelect = action.payload;
    },
    setFindedCells(state:SalesListTransTmpState, action: PayloadAction<{findedCells:findedCell[], over:boolean}>) {
      state.findedCells = action.payload.findedCells;
      state.findedCellsOver = action.payload.over;
    },
    setLastUpdate(state:SalesListTransTmpState, action: PayloadAction<string | null>) {
      state.lastUpdate = action.payload;
    },
    setAdjus0Imported(state:SalesListTransTmpState, action: PayloadAction<boolean | null>) {
      state.adjus0Imported = action.payload;
    },
    setErrorMessage(state:SalesListTransTmpState, action: PayloadAction<string>) {
      state.errorMessage = action.payload;
    }
}};

const createSliceContent = (name:string) => createSlice({
  name: name,
  initialState,
  reducers: createReducerContent(),
});


//RowDataGroupに変換
const convertDataGroups = (datas:RowData[]): RowDataGroup[] => {
  //コード順でソート
  const asc = true;
  datas = datas.sort((a,b) => {
    const objA = a;
    const objB = b;
    //H1～H4でソート
    const lvA = drillDownLv1MaxLv.indexOf(objA.TP);
    let comp = 0;
    if(lvA >= 0) {
      for (let i = 1; i <= drillDownMaxLv; i++) {
        comp = compareUtil.compareString(objA ? objA['H' + i + 'ID'] : null, objB ? objB['H' + i + 'ID'] : null, asc);
        if (!(comp == 0 && lvA >= i)) {
          break;
        }
      }
      if(comp != 0) {
        return comp;
      }
    }
  });

  const dataGroups:RowDataGroup[] = [];
  let preData = null;
  let dataGroup:RowDataGroup;
  datas.forEach(data => {
    let chk = true;
    if (preData) {
      for (let i = 1; i <= drillDownMaxLv; i++) {
        if (preData['H' + i + 'ID'] != data['H' + i + 'ID']) {
          chk = false;
          break;
        }
      }
    }

    if (!preData || !chk) {
      dataGroup = {
        TP: data.TP,
        drillDowned: data.drillDowned,
        CAT: data.CAT, //一般/SEJ/混在
        ymDatas:[],
        dataTTL:{},
      };
      for (let i = 1; i <= drillDownMaxLv; i++) {
        dataGroup['H' + i + 'ID'] = data['H' + i + 'ID'];
        dataGroup['H' + i + 'CD'] = data['H' + i + 'CD'];
        dataGroup['H' + i + 'NM'] = data['H' + i + 'NM'];
      }
      dataGroups.push(dataGroup);
    }
    dataGroup.ymDatas.push({YMID:data.YMID, data:data});
    dataGroup.dataTTL = calcTotalSub(dataGroup.dataTTL, data);
    preData = data;
  })
  dataGroups.forEach(dataGroup => {
    dataGroup.ymDatas.sort((a, b) => a.YMID - b.YMID);
  });
  return dataGroups;
}

const parseAttr = (dataGroups: RowDataGroup[], attrs: RowAttr[]): RowDataGroup[] => {
  dataGroups.forEach((dataGroup) => {
    const attr = attrs.find(attr => attr.H1CD == dataGroup.H1CD);
    dataGroup.CDIV = attr?.CDIV ?? '-';
    dataGroup.TDIV = attr?.TDIV ?? '-';
  });
  return dataGroups;
}
const makeDatas = (dataGroups: RowDataGroup[], grouping1: boolean, grouping2: boolean): RowDataGroup[] => {
  dataGroups = dataGroups.filter(dataGroup => dataGroup.TP != "grouping1"); //TC/DC小計行の削除
  dataGroups = dataGroups.filter(dataGroup => dataGroup.TP != "grouping2"); //自社/委託小計行の削除
  const doGrouping = grouping1;
  if (doGrouping) {
    dataGroups = calcGrouping(dataGroups, grouping1, grouping2); //小計行の作成

    const grouping1s = dataGroups.filter(data => data.TP === "grouping1");
    grouping1s.forEach(groupingData => {
      //横の合計
      groupingData.ymDatas.sort((a, b) => compareUtil.compareAny(a.YMID, b.YMID, true));
      groupingData.ymDatas.forEach(ymData => {
        const data: RowData = ymData.data;
        groupingData.dataTTL = calcTotalSub(groupingData.dataTTL, data);
      });
    });

    const grouping2s = dataGroups.filter(data => data.TP == "grouping2");
    grouping2s.forEach(groupingData => {
      //横の合計
      groupingData.ymDatas.sort((a, b) => compareUtil.compareAny(a.YMID, b.YMID, true));
      groupingData.ymDatas.forEach(ymData => {
        const data: RowData = ymData.data;
        groupingData.dataTTL = calcTotalSub(groupingData.dataTTL, data);
      });
    });
  }

  return dataGroups;
};

const calcGrouping = (dataGroups: RowDataGroup[], grouping1: boolean, grouping2: boolean): RowDataGroup[] => {
  if (!grouping1) {
    return;
  }

  const fncMakeGroupData = (
    dataGroup: RowDataGroup,
    TP: "grouping1" | "grouping2",
    groupingDiv: 'CDIV' | 'TDIV' | string,
    groupingMap,
    GCD_parent: string,
    groupingDataParent,
  ): [string, RowData] => {
    const groupingCD = getGroupingCD(groupingDiv, dataGroup);
    const GCD = (GCD_parent ? (GCD_parent + '_') : '') + groupingCD;
    let groupingData: RowDataGroup = groupingMap[GCD];
    if (!groupingData) {
      groupingData = {
        ...{
          TP: TP,
          CAT:  "def",  //暫定的にdefにする
          drillDowned: true,
          CDIV: dataGroup.CDIV,
          TDIV: TP === 'grouping2' ? dataGroup.TDIV : '',
          H1NM: dataGroup[groupingDiv],

          ymDatas: [],
          dataTTL:{},
        }
      };
      if (TP === 'grouping1') {
        groupingMap[GCD] = groupingData;
      }
      else {
        //子がいなければ追加しない
        const length1 = dataGroups.filter(d => {
          const flg1 = getGroupingCD('CDIV', dataGroup) == getGroupingCD('CDIV', d);
          return drillDownLv1MaxLv.indexOf(d.TP) !== -1 && flg1;
        }).length;
        const length2 = dataGroups.filter(d => {
          const flg1 = getGroupingCD('CDIV', dataGroup) == getGroupingCD('CDIV', d);
          const flg2 = getGroupingCD('TDIV', dataGroup) == getGroupingCD('TDIV', d);
          return drillDownLv1MaxLv.indexOf(d.TP) !== -1 && flg1 && flg2;
        }).length;

        if (length1 != length2) {
          groupingMap[GCD] = groupingData;
        }
      }
    }
    dataGroup.ymDatas.forEach(ymData => {
      const YMID = ymData.YMID;
      const data:RowData = ymData.data;
      let totalYMData = groupingData.ymDatas.find(ymData => ymData.YMID == YMID);
      if(!totalYMData) {
        totalYMData = {
          YMID:ymData.YMID,
          data: {},
        };
        groupingData.ymDatas.push(totalYMData);
      }
      let total:RowData = totalYMData.data;
      //縦の合計
      total = calcTotalSub(total, data);
    });
    return [GCD, groupingData];
  };

  const grouping1Map = {};
  const grouping2Map = {};
  dataGroups = dataGroups.filter(dataGroup => dataGroup.TP != "grouping1"); //TC/DC小計行の削除
  dataGroups = dataGroups.filter(dataGroup => dataGroup.TP != "grouping2"); //自社/委託小計行の削除

  //set No.
  const subToralLevel = (grouping2 ? 2 : grouping1 ? 1 : 0);

  dataGroups.forEach((dataGroup) => {
    if (drillDownLv1MaxLv.indexOf(dataGroup.TP) !== -1) {
      let [GCD1, grouping1Data] = fncMakeGroupData(dataGroup, "grouping1", "CDIV", grouping1Map, null, {});

      //小計もしくは、内訳で次の階層がある
      if (grouping2 && subToralLevel >= 2) {
        fncMakeGroupData(dataGroup, "grouping2", "TDIV", grouping2Map, GCD1, grouping1Data);
      }
    }
  });

  //あとのソートのために、makerは上に入れる。
  const newDataGroups = [];
  Object.keys(grouping1Map).forEach(GCD1 => newDataGroups.push(grouping1Map[GCD1]));
  if (grouping2) {
    Object.keys(grouping2Map).forEach(GCD2 => newDataGroups.push(grouping2Map[GCD2]));
  }
  return [...newDataGroups, ...dataGroups];
}
const getGroupingCD = (key: 'CDIV' | 'TDIV' | string, dataGroup: RowDataGroup): string => {
  if (!dataGroup) {
    return '';
  }
  switch (key) {
    case 'CDIV':
      return dataGroup.CDIV;
    case 'TDIV':
      return dataGroup.TDIV;
    default:
      return "";
  }
}

//合計行作成
const calcTotalGroup = (dataGroups:RowDataGroup[]): RowDataGroup[] => {
  const totalGroup:RowDataGroup = {
    TP: "total",
    CAT:  "def",  //暫定的にdefにする
    drillDowned: true,
    ymDatas: [],
    dataTTL:{},
  };

  //set No.
  dataGroups.forEach((dataGroup) => {
    if(dataGroup.TP == "lv1") {
      if(dataGroup.ymDatas) {
        dataGroup.ymDatas.forEach(ymData => {
          const YMID = ymData.YMID;
          const data:RowData = ymData.data;
          let totalYMData = totalGroup.ymDatas.find(ymData => ymData.YMID == YMID);
          if(!totalYMData) {
            totalYMData = {
              YMID:ymData.YMID,
              data: {},
            };
            totalGroup.ymDatas.push(totalYMData);
          }
          let total:RowData = totalYMData.data;
          //縦の合計
          total = calcTotalSub(total, data);
        });
      }
      //sej含む場合はmix
      if(dataGroup.CAT == "mix" || dataGroup.CAT == "sej" ) {
        totalGroup.CAT = 'mix';
      }
    }
  });

  //横の合計
  totalGroup.ymDatas.sort((a, b) => compareUtil.compareAny(a.YMID, b.YMID, true));
  totalGroup.ymDatas.forEach(ymData => {
    const data:RowData = ymData.data;
    totalGroup.dataTTL = calcTotalSub(totalGroup.dataTTL, data);
  });

  if(dataGroups.length > 0 && dataGroups[0].TP == "total") {
    dataGroups[0] = totalGroup;
  }
  else {
    dataGroups = [totalGroup, ...dataGroups];
  }

  return dataGroups;
}

//計算
const calcDataGroups = (dataGroups: RowDataGroup[], dataGroupsAll: RowDataGroup[], axisTypes: ("group1" | "group2" | "shiten" | "center" | "shiire" | "maker" | "tokuisaki" | "category" | string)[]): RowDataGroup[] => {
  const totalGroup = dataGroupsAll.find(dataGroup => dataGroup.TP == "total");

  const itemAttrFlgs:boolean[] = [];
  axisTypes.forEach((axisType, index) => {
    itemAttrFlgs.push(itemAttrFlgs[index-1] || ["maker","category",].indexOf(axisType) >= 0);
  });

  dataGroups.forEach(dataGroup => {

    const lv = 
      dataGroup.TP == "lv8" ? 8 :
      dataGroup.TP == "lv7" ? 7 :
      dataGroup.TP == "lv6" ? 6 :
      dataGroup.TP == "lv5" ? 5 :
      dataGroup.TP == "lv4" ? 4 :
      dataGroup.TP == "lv3" ? 3 :
      dataGroup.TP == "lv2" ? 2 :
      1;
    //構成比の計算
    const parentIndex = 
      dataGroup.TP == "lv8" ? dataIndexOfLv7(dataGroupsAll, dataGroup.CAT, dataGroup.H1ID, dataGroup.H2ID, dataGroup.H3ID, dataGroup.H4ID, dataGroup.H5ID, dataGroup.H6ID, dataGroup.H7ID) :
      dataGroup.TP == "lv7" ? dataIndexOfLv6(dataGroupsAll, dataGroup.CAT, dataGroup.H1ID, dataGroup.H2ID, dataGroup.H3ID, dataGroup.H4ID, dataGroup.H5ID, dataGroup.H6ID) :
      dataGroup.TP == "lv6" ? dataIndexOfLv5(dataGroupsAll, dataGroup.CAT, dataGroup.H1ID, dataGroup.H2ID, dataGroup.H3ID, dataGroup.H4ID, dataGroup.H5ID) :
      dataGroup.TP == "lv5" ? dataIndexOfLv4(dataGroupsAll, dataGroup.CAT, dataGroup.H1ID, dataGroup.H2ID, dataGroup.H3ID, dataGroup.H4ID) :
      dataGroup.TP == "lv4" ? dataIndexOfLv3(dataGroupsAll, dataGroup.CAT, dataGroup.H1ID, dataGroup.H2ID, dataGroup.H3ID) :
      dataGroup.TP == "lv3" ? dataIndexOfLv2(dataGroupsAll, dataGroup.CAT, dataGroup.H1ID, dataGroup.H2ID) :
      dataGroup.TP == "lv2" ? dataIndexOfLv1(dataGroupsAll, dataGroup.CAT, dataGroup.H1ID) :
      -1;

    const parentGroup = parentIndex == -1 ? totalGroup : dataGroupsAll[parentIndex];

    //各年月
    dataGroup.ymDatas.forEach(ymData => {
      const data:RowData = ymData.data;
      const parent:RowData = parentGroup ? parentGroup.ymDatas?.find(parentYmData => parentYmData.YMID == ymData.YMID)?.data : null;

      ymData.data = calcData(data, parentIndex, parent, itemAttrFlgs[lv-1]);
      calcDataPl(data, lv, axisTypes.slice(0, lv));
      data.keppinRnkColKeys = calcDataKeppinRnk(data);
    });

    //合計列
    {
      const parent:RowData = parentGroup?.dataTTL;
      dataGroup.dataTTL = calcData(dataGroup.dataTTL, parentIndex, parent, itemAttrFlgs[lv-1]);
      calcDataPl(dataGroup.dataTTL, lv, axisTypes.slice(0, lv));
      dataGroup.dataTTL.keppinRnkColKeys = calcDataKeppinRnk(dataGroup.dataTTL);
    }
  });

  return dataGroups;
}


//no振りなおし
//no振りなおし
const resetGroupRowNo = (dataGroups: RowDataGroup[], parentGroup: RowDataGroup, maxLv: number, grouping1: boolean, grouping2: boolean): RowDataGroup[] => {
  //set No.
  const TPs = []; //'lv1','lv2','lv3','lv4'
  if (grouping1) {
    TPs.push('grouping1');
  }
  if (grouping2) {
    TPs.push('grouping2');
  }
  for(let l = 1; l<=maxLv; l++){
    TPs.push('lv' + l);
  }
  let nos = [];
  TPs.forEach((TP, index) => {
    const indexOf = parentGroup ? TPs.indexOf(parentGroup.TP) : -1;
    const no = parentGroup && indexOf >= index ? parseInt(parentGroup.no.split("-")[index]) : 0;
    nos.push(no);
  });
  dataGroups = dataGroups.map((row) => {
    let no = row.TP == "total" ? "合計" : "";
    const indexOf = TPs.indexOf(row.TP);
    nos = nos.map((n, index) => {
      if (index < indexOf && n !== 0) {
        no = no + "" + n + "-";
      }
      else if(index == indexOf){
        no = no + "" + (++n);
      }
      else {
        n = 0;
      }
      return n;
    });

    return {
      ...row,
      no: no,
    }
  });
  return dataGroups;
}

//ドリルダウンの差し込み
const spliceDataGroup = (addIndex: number, addDataGroups:RowDataGroup[], destDataGroups:RowDataGroup[]): RowDataGroup[] => {
  const args = [].concat([addIndex,0]).concat(addDataGroups);
  Array.prototype.splice.apply(destDataGroups,args);
  return destDataGroups;
}

//マージを作成
// const createMergeCells = (rowInfos:RowInfo[], colRowModel:SalesListTransColRowModel, visibleRowsKey:string[]): {row: number, col: number, rowspan: number, colspan: number}[] => {
//   if(visibleRowsKey.length <= 1) {
//     return null;
//   }

//   const mergeCells:{row: number, col: number, rowspan: number, colspan: number}[] = [];

//   const cols = [
//     colRowModel.colFromKey('no'),
//     colRowModel.colFromKey('H1CD'),
//     colRowModel.colFromKey('H1NM'),
//     colRowModel.colFromKey('H2CD'),
//     colRowModel.colFromKey('H2NM'),
//     colRowModel.colFromKey('H3CD'),
//     colRowModel.colFromKey('H3NM'),
//     colRowModel.colFromKey('H4CD'),
//     colRowModel.colFromKey('H4NM'),
//     colRowModel.colFromKey('H5CD'),
//     colRowModel.colFromKey('H5NM'),
//   ];

//   let prev:RowInfo;
//   let rowStart:number;
//   let rowSpan:number;
//   rowInfos.forEach((rowInfo, index)=> {
//     if(!prev || prev.dataGroup.no != rowInfo.dataGroup.no) {
//       if(prev) {
//         cols.forEach(col => {
//           mergeCells.push({row: rowStart, col: col, rowspan: rowSpan, colspan: 1});
//         });
//       }
//       rowStart = index;
//       rowSpan = 0;
//     }
//     rowSpan++;
//     prev = rowInfo;
//   });
//   if(prev) {
//     cols.forEach(col => {
//       mergeCells.push({row: rowStart, col: col, rowspan: rowSpan, colspan: 1});
//     });
//   }
//   if(mergeCells.length == 0){
//     return null;
//   }

//   return mergeCells.length == 0 ? null : mergeCells;
// }
//行情報に変換
const convertRowInfos = (dataGroups:RowDataGroup[], visibleRowsKey:string[], dataTypeIs:"daily"|"monthly"|"weekly", adjus0Imported:boolean): RowInfo[] => {
  const rowKeyInfos:{
    rowKey: string,
    rowIndex:number,
    rowIndexLast:boolean,
    rowHeaderIndex1:number,
    rowHeaderIndex2:number,
    rowHeaderIndex1Last:boolean,
    rowHeaderIndex2Last:boolean,
  }[] = [];

  let rowHeaderCount1 = 0;
  let rowHeaderCount2 = 0;
  let prevHeadersRow1 = null;
  let prevHeadersRow2 = null;
  let prevRowKeyInfo;
  const headersRow = 
      dataTypeIs == "daily" ? headersRowDaily : 
      dataTypeIs == "monthly" ? headersRowMonthly : 
      dataTypeIs == "weekly" ? headersRowWeekly : 
      {};

  //強制表示
  visibleRowsKey = arrayUtil.union(visibleRowsKey, 
    dataTypeIs == "daily" ? mustVisibleRowsKeyDaily : 
    dataTypeIs == "monthly" ? mustVisibleRowsKeyMonthly : 
    dataTypeIs == "weekly" ? mustVisibleRowsKeyWeekly : 
    []);
  //受注未取り込み時の強制非表示
  if(!adjus0Imported) {
    visibleRowsKey = arrayUtil.not(visibleRowsKey, unVisibleRowsKeyAdjus);
  }

  visibleRowsKey.forEach((visibleRowKey, index) => {
    const headersRow1 = headersRow[0][visibleRowKey];
    const headersRow2 = headersRow[1][visibleRowKey];

    if(prevRowKeyInfo) {
      prevRowKeyInfo.rowHeaderIndex1Last = headersRow1 != prevHeadersRow1;
      prevRowKeyInfo.rowHeaderIndex2Last = headersRow1 != prevHeadersRow1 || headersRow2 != prevHeadersRow2;
    }

    rowHeaderCount1 = headersRow1 != prevHeadersRow1 ? 0 : rowHeaderCount1;
    rowHeaderCount2 = headersRow1 != prevHeadersRow1 || headersRow2 != prevHeadersRow2 ? 0 : rowHeaderCount2;

    rowKeyInfos.push({
      rowKey: visibleRowKey,
      rowIndex:index,
      rowIndexLast:visibleRowsKey.length - 1 == index,
      rowHeaderIndex1: rowHeaderCount1++,
      rowHeaderIndex2: rowHeaderCount2++,
      rowHeaderIndex1Last: false, //一時的に仮置き
      rowHeaderIndex2Last: false, //一時的に仮置き
    });

    prevRowKeyInfo = rowKeyInfos[rowKeyInfos.length - 1];
    prevHeadersRow1 = headersRow1;
    prevHeadersRow2 = headersRow2;
  });
  if(prevRowKeyInfo) {
    prevRowKeyInfo.rowHeaderIndex1Last = true;
    prevRowKeyInfo.rowHeaderIndex2Last = true;
  }

  const rowInfos:RowInfo[] = [];
  dataGroups.forEach(dataGroup => {
    rowKeyInfos.forEach((rowKeyInfo) => {
      rowInfos.push({...{
          dataGroup: dataGroup,
        }, ...rowKeyInfo}
      );
    })
  });
  return rowInfos;
}
  //配列データに変換
const convertRows = (rowInfos:RowInfo[], colRowModel:SalesListTransColRowModel, dataYMs: (YM|YW)[], dataTypeIs:"daily"|"monthly"|"weekly"): [][] => {
  const headersRow = 
    dataTypeIs == "daily" ? headersRowDaily :
    dataTypeIs == "monthly" ? headersRowMonthly :
    dataTypeIs == "weekly" ? headersRowWeekly :
    {};
  const rows = [];
  //set No.
  rowInfos.forEach((rowInfo) => {
    const dataGroup:RowDataGroup = rowInfo.dataGroup;
    const r = [];
    colRowModel.colKeys.forEach((colKey) => {
      switch (colKey) {
        case "rowHeader1":
          r.push(headersRow[0][rowInfo.rowKey]);
          break;
        case "rowHeader2":
          r.push(headersRow[1][rowInfo.rowKey]);
          break;
        case "rowHeader3":
          r.push(headersRow[2][rowInfo.rowKey]);
          break;
        case "TTL":
          r.push(dataGroup.dataTTL[rowInfo.rowKey]);
          break;
        default: {
          //期間列
          const termIndex = termColKeys.indexOf(colKey);
          if(termIndex >= 0) {
            const dataYM = dataYMs[termIndex];
            const YMID:number = dataYM ? parseInt(dataYM.code) : -1;
            const ymData:YMData = dataYM ? dataGroup.ymDatas.find(ymData => ymData.YMID == YMID) : null;
            r.push(ymData ? ymData.data[rowInfo.rowKey] : null);
          }
          else {
            r.push(dataGroup[colKey]);
          }
        }
          break;
      }
    });
    rows.push(r);
  });

  return rows;
}

//ソート
const doSortGroup = (dataGroups:RowDataGroup[], dataYMs: (YM|YW)[], colKey:string, rowKey:string, asc:boolean, lvKeySort:boolean, equalsIsCode:boolean): RowDataGroup[] => {
  dataGroups.sort((a, b) => {
    //合計行は常に上
    if (a.TP == "total" || b.TP == "total") {
      return a.TP == "total" ? -1 : 1;
    }

    let objA = a;
    let objB = b;
    if (lvKeySort) {
      const lvA = drillDownLv1MaxLv.indexOf(objA.TP);
      let comp = 0;
      if (lvA >= 0) {
        for (let i = 1; i <= drillDownMaxLv; i++) {
          comp = compareUtil.compareString(objA ? objA['H' + i + 'ID'] : null, objB ? objB['H' + i + 'ID'] : null, asc);
          if (!(comp == 0 && lvA >= i)) {
            break;
          }
        }
      }
      return comp;
    }

    //小計行は常に上
    if (a.CDIV == b.CDIV && (a.TP == "grouping1" || b.TP == "grouping1")) {
      return a.TP == "grouping1" ? -1 : 1;
    }
    if (a.TDIV == b.TDIV && (a.TP == "grouping2" || b.TP == "grouping2")) {
      return a.TP == "grouping2" ? -1 : 1;
    }

    //複数階層（親でソート）
    const lvA = ['grouping1', 'grouping2', ...drillDownLv1MaxLv].indexOf(a.TP) - 1;
    const lvB = ['grouping1', 'grouping2', ...drillDownLv1MaxLv].indexOf(b.TP) - 1;
    const minLv = Math.min(lvA, lvB);
    const findfunc = (data, lv, a): boolean => {
      if (lv === -1 ? (data.TP !== 'grouping1') : lv === 0 ? (data.TP !== 'grouping2') : (data.TP != 'lv' + lv)) {
        return false;
      }
      for (let i = -1; i <= lv; i++) {
        if (lv === -1 ? (data.CDIV != a.CDIV) : lv === 0 ? (data.TDIV != a.TDIV) : (data['H' + i + 'ID'] != a['H' + i + 'ID'])) {
          return false;
        }
      }
      return true;
    };

    //階層ごとに比較する
    for (let j = -1; j <= minLv; j++) {
      objA = dataGroups.find(data => findfunc(data, j, a));
      objB = dataGroups.find(data => findfunc(data, j, b));

      let comp = 0;
      const key = j === -1 ? 'CDIV' : j === 0 ? 'TDIV' : colKey;
      let va = null;
      let vb = null;
      if (rowKey && key == colKey) {
        let dataA: RowData = null;
        let dataB: RowData = null;
        if (colKey == 'TTL') {
          dataA = objA ? objA.dataTTL : null;
          dataB = objB ? objB.dataTTL : null;
        }
        else {
          const termIndex = termColKeys.indexOf(colKey);
          const dataYM = dataYMs[termIndex];
          //存在しない列の場合は、合計列を使う
          if (!dataYM) {
            dataA = objA ? objA.dataTTL : null;
            dataB = objB ? objB.dataTTL : null;
          }
          else {
            const ymDataA = objA ? objA.ymDatas.find(ymData => ymData.YMID == parseInt(dataYM.code)) : null;
            const ymDataB = objB ? objB.ymDatas.find(ymData => ymData.YMID == parseInt(dataYM.code)) : null;
            dataA = ymDataA ? ymDataA.data : null;
            dataB = ymDataB ? ymDataB.data : null;
          }
        }
        va = dataA ? dataA[rowKey] : null;
        vb = dataB ? dataB[rowKey] : null;
      }
      else {
        va = objA ? objA[key] : null;
        vb = objB ? objB[key] : null;
      }

      if (key === 'CDIV') {
        const ia = ['TC', 'DC', '特殊', '-'].indexOf(va);
        const ib = ['TC', 'DC', '特殊', '-'].indexOf(vb);
        comp = compareUtil.compareNumber(ia, ib, colKey === 'CDIV' ? asc : true);
      } else if (key === 'TDIV') {
        const ia = ['自社', '委託F', '委託H', '大創', '-'].indexOf(va);
        const ib = ['自社', '委託F', '委託H', '大創', '-'].indexOf(vb);
        comp = compareUtil.compareNumber(ia, ib, colKey === 'TDIV' ? asc : true);
      }
      //数値型
      else if (typeof va === 'number' || typeof vb === 'number') {
        comp = compareUtil.compareNumber2(va, vb, asc);
      }
      else if (typeof va === 'string' || typeof vb === 'string') {
        comp = compareUtil.compareString(va, vb, asc);
      }
      if (comp != 0) {
        return comp;
      }
      //次の階層で比較
    }

    let comp = 0;
    //それでもソートできない場合はコード順
    if (equalsIsCode) {
      const lvA = drillDownLv1MaxLv.indexOf(objA.TP);
      if (lvA >= 0) {
        for (let i = 1; i <= drillDownMaxLv; i++) {
          comp = compareUtil.compareString(objA ? objA['H' + i + 'ID'] : null, objB ? objB['H' + i + 'ID'] : null, asc);
          if (!(comp == 0 && lvA >= i)) {
            break;
          }
        }
      }
    }
    return comp;
  });

  return dataGroups;
}

//データの位置取得
export const dataIndexOfLv1 = (dataGroups:RowDataGroup[], CAT:string, H1:string):number => {
  //差し込み位置の取得
  let parentIndex;
  dataGroups.find((dataGroup, index) => {
    const match = dataGroup.TP == "lv1"
      && CAT == dataGroup.CAT
      && H1 == dataGroup.H1ID
    ;
    if(match) {
      parentIndex = index;
    }
    return match;
  });
  return parentIndex;
}
export const dataIndexOfLv2 = (dataGroups:RowDataGroup[], CAT:string, H1:string, H2:string):number => {
  //差し込み位置の取得
  let parentIndex;
  dataGroups.find((dataGroup, index) => {
    const match = dataGroup.TP == "lv2"
      && CAT == dataGroup.CAT
      && H1 == dataGroup.H1ID
      && H2 == dataGroup.H2ID
    ;
    if(match) {
      parentIndex = index;
    }
    return match;
  });
  return parentIndex;
}
export const dataIndexOfLv3 = (dataGroups:RowDataGroup[], CAT:string, H1:string, H2:string, H3:string):number => {
  //差し込み位置の取得
  let parentIndex;
  dataGroups.find((dataGroup, index) => {
    const match = dataGroup.TP == "lv3"
      && CAT == dataGroup.CAT
      && H1 == dataGroup.H1ID
      && H2 == dataGroup.H2ID
      && H3 == dataGroup.H3ID
    ;
    if(match) {
      parentIndex = index;
    }
    return match;
  });
  return parentIndex;
}
export const dataIndexOfLv4 = (dataGroups:RowDataGroup[], CAT:string, H1:string, H2:string, H3:string, H4:string):number => {
  //差し込み位置の取得
  let parentIndex;
  dataGroups.find((dataGroup, index) => {
    const match = dataGroup.TP == "lv4"
      && CAT == dataGroup.CAT
      && H1 == dataGroup.H1ID
      && H2 == dataGroup.H2ID
      && H3 == dataGroup.H3ID
      && H4 == dataGroup.H4ID
    ;
    if(match) {
      parentIndex = index;
    }
    return match;
  });
  return parentIndex;
}
export const dataIndexOfLv5 = (dataGroups:RowDataGroup[], CAT:string, H1:string, H2:string, H3:string, H4:string, H5:string):number => {
  //差し込み位置の取得
  let parentIndex;
  dataGroups.find((dataGroup, index) => {
    const match = dataGroup.TP == "lv5"
      && CAT == dataGroup.CAT
      && H1 == dataGroup.H1ID
      && H2 == dataGroup.H2ID
      && H3 == dataGroup.H3ID
      && H4 == dataGroup.H4ID
      && H5 == dataGroup.H5ID
    ;
    if(match) {
      parentIndex = index;
    }
    return match;
  });
  return parentIndex;
}
export const dataIndexOfLv6 = (dataGroups:RowDataGroup[], CAT:string, H1:string, H2:string, H3:string, H4:string, H5:string, H6:string):number => {
  //差し込み位置の取得
  let parentIndex;
  dataGroups.find((dataGroup, index) => {
    const match = dataGroup.TP == "lv6"
      && CAT == dataGroup.CAT
      && H1 == dataGroup.H1ID
      && H2 == dataGroup.H2ID
      && H3 == dataGroup.H3ID
      && H4 == dataGroup.H4ID
      && H5 == dataGroup.H5ID
      && H6 == dataGroup.H6ID
    ;
    if(match) {
      parentIndex = index;
    }
    return match;
  });
  return parentIndex;
}
export const dataIndexOfLv7 = (dataGroups:RowDataGroup[], CAT:string, H1:string, H2:string, H3:string, H4:string, H5:string, H6:string, H7:string):number => {
  //差し込み位置の取得
  let parentIndex;
  let parentDataGroup = dataGroups.find((dataGroup, index) => {
    const match = dataGroup.TP == "lv7"
      && CAT == dataGroup.CAT
      && H1 == dataGroup.H1ID
      && H2 == dataGroup.H2ID
      && H3 == dataGroup.H3ID
      && H4 == dataGroup.H4ID
      && H5 == dataGroup.H5ID
      && H6 == dataGroup.H6ID
      && H7 == dataGroup.H7ID
    ;
    if(match) {
      parentIndex = index;
    }
    return match;
  });
  return parentIndex;
}
export const getOptionLabel = (option: CodeName) => {
  return option && option.name ? (option.code + ' ' + option.name) : "";
}

//Page Slice Export
//salesListTransTmp
export const salesListTransTmpSlice = createSliceContent("salesListTransTmp");
export const salesListTransReport11TmpSlice = createSliceContent("salesListTransReport11Tmp");
export const salesListTransReport15TmpSlice = createSliceContent("salesListTransReport15Tmp");
export const salesListTransReport16TmpSlice = createSliceContent("salesListTransReport16Tmp");
export const salesListTransReport35TmpSlice = createSliceContent("salesListTransReport35Tmp");
export const salesListTransReport45TmpSlice = createSliceContent("salesListTransReport45Tmp");
