import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { CodeName, LastUpdate } from "@/store/common";
import * as common from "@/store/common";

// import { XrayColModel, } from "@/components/xray/CategoryRankTableModel";
import * as categoryRank from "@/components/xray/XrayCategoryRankTableModel";
import * as pbRank from "@/components/xray/XrayPbRankTableModel";
import * as group2Rank from "@/components/xray/XrayGroup2RankTableModel";
import * as centerRank from "@/components/xray/XrayCenterRankTableModel";
import * as makerRank from "@/components/xray/xray2/Xray2MakerRankTableModel";
import * as group2CenterRank from "@/components/xray/xray2/Xray2CenterRankTableModel";
import * as keppinRnkModel from "@/components/xray/dialog/Xray1KeppinRankingModel";
import * as infoModel from "@/components/xray/XrayInfoTableModel";


// import { RequestParam } from "@/assets/apitype/salesList";

import * as xrayRetrieve from "@/assets/apitype/xrayRetrieve";
import * as paramRetrieve from "@/assets/apitype/paramRetrieve";

import { YM } from "@/store/common";

import * as calcUtil from "@/util/calcUtil";
import * as compareUtil from "@/util/compareUtil";
import moment from "moment";


export type RetrievedId = { uuid: string };
export interface RowData {
  TP?: "total" | "lv1" | "lv2" | "lv3" | "lv4" | "lv5" | "lv6" | "lv7" | "lv8",
  drillDowned?: boolean,
  no?: string,
  CAT?: "def" | "sej" | "mix", //一般/SEJ/混在
  YMID?: number | null, //年月
  H1ID?: string | null, H2ID?: string | null, H3ID?: string | null, H4ID?: string | null, H5ID?: string | null, H6ID?: string | null, H7ID?: string | null, H8ID?: string | null
  //以下に貼り付け
  H1CD?: string | null, H1NM?: string | null, H2CD?: string | null, H2NM?: string | null, H3CD?: string | null, H3NM?: string | null, H4CD?: string | null, H4NM?: string | null, H5CD?: string | null, H5NM?: string | null, H6CD?: string | null, H6NM?: string | null, H7CD?: string | null, H7NM?: string | null, H8CD?: string | null, H8NM?: string | null,
  JJT?: number | null, JJP?: number | null, JJR?: number | null, JJD?: number | null,
  JKT?: number | null, JKP?: number | null, JKR?: number | null, JKD?: number | null,
  JRT?: number | null, JRP?: number | null, JRD?: number | null,
  //欠品責任別
  KI1KA?: number | null, KI1KR?: number | null,
  KI2KA?: number | null, KI2KR?: number | null,
  KI3KA?: number | null, KI3KR?: number | null,
  SLT?: number | null, SLP?: number | null, SLR?: number | null, SLD?: number | null,
  SDT?: number | null, SDP?: number | null, SDR?: number | null, SDD?: number | null,
  SRT?: number | null, SRP?: number | null, SRR?: number | null, SRD?: number | null,
  STT?: number | null, STP?: number | null, STR?: number | null, STD?: number | null, STB?: number | null, STBR?: number | null, STBD?: number | null,
  PBLT?: number | null, PBLP?: number | null, PBLR?: number | null, PBLD?: number | null,
  PBDT?: number | null, PBDP?: number | null, PBDR?: number | null, PBDD?: number | null,
  PBRT?: number | null, PBRP?: number | null, PBRR?: number | null, PBRD?: number | null,
  PBTT?: number | null, PBTP?: number | null, PBTR?: number | null, PBTD?: number | null,
  PBHT?: number | null, PBHP?: number | null, PBHD?: number | null,
  YHLT?: number | null, YHLP?: number | null, YHLR?: number | null, YHLD?: number | null,
  YHDT?: number | null, YHDP?: number | null, YHDR?: number | null, YHDD?: number | null,
  YHRT?: number | null, YHRP?: number | null, YHRR?: number | null, YHRD?: number | null,
  YHTT?: number | null, YHTP?: number | null, YHTR?: number | null, YHTD?: number | null,
  YHHT?: number | null, YHHP?: number | null, YHHD?: number | null,
  PTT?: number | null, PTP?: number | null, PTD?: number | null, PTB?: number | null, PTBR?: number | null, PTBD?: number | null,
  PRT?: number | null, PRP?: number | null, PRD?: number | null,
  DDT?: number | null, DDP?: number | null, DDR?: number | null, DDD?: number | null,
  DBT?: number | null, DBP?: number | null, DBR?: number | null, DBD?: number | null,
  DQT?: number | null, DQP?: number | null, DQR?: number | null, DQD?: number | null,
  DFT?: number | null, DFP?: number | null, DFR?: number | null, DFD?: number | null,
  DTT?: number | null, DTP?: number | null, DTR?: number | null, DTD?: number | null, DTB?: number | null, DTBR?: number | null, DTBD?: number | null,
  DRT?: number | null, DRP?: number | null, DRD?: number | null,
  NTT?: number | null, NTP?: number | null, NTR?: number | null, NTD?: number | null, NTB?: number | null, NTBR?: number | null, NTBD?: number | null,
  CIT?: number | null, CIP?: number | null, CIR?: number | null, CID?: number | null,
  CST?: number | null, CSP?: number | null, CSR?: number | null, CSD?: number | null,
  CTT?: number | null, CTP?: number | null, CTR?: number | null, CTD?: number | null, CTB?: number | null, CTBR?: number | null, CTBD?: number | null,
  OCT?: number | null, OCP?: number | null, OCR?: number | null, OCD?: number | null, OCB?: number | null, OCBR?: number | null, OCBD?: number | null,
  TST?: number | null, TSP?: number | null, TSR?: number | null, TSD?: number | null,
  CSTT?: number | null, CSTP?: number | null, CSTR?: number | null, CSTD?: number | null,
  OPT?: number | null, OPP?: number | null, OPR?: number | null, OPD?: number | null, OPB?: number | null, OPBR?: number | null, OPBD?: number | null,
  TPT?: number | null, TPP?: number | null, TPR?: number | null, TPD?: number | null,
  PPTT?: number | null, PPTP?: number | null, PPTR?: number | null, PPTD?: number | null,
  OET?: number | null, OEP?: number | null, OER?: number | null, OED?: number | null, OEB?: number | null, OEBR?: number | null, OEBD?: number | null,
  TET?: number | null, TEP?: number | null, TER?: number | null, TED?: number | null,
  ETT?: number | null, ETP?: number | null, ETR?: number | null, ETD?: number | null,
  KTT?: number | null, KTP?: number | null, KTR?: number | null, KTD?: number | null,
  KRT?: number | null, KRP?: number | null, KRD?: number | null,
  BTT?: number | null, BTP?: number | null, BTR?: number | null, BTD?: number | null,
  BRT?: number | null, BRP?: number | null, BRD?: number | null,
  ATT?: number | null, ATP?: number | null, ATR?: number | null, ATD?: number | null,
  ART?: number | null, ARP?: number | null, ARD?: number | null,
  GTT?: number | null, GTP?: number | null, GTR?: number | null, GTD?: number | null, GTB?: number | null, GTBR?: number | null, GTBD?: number | null,
  GRT?: number | null, GRP?: number | null, GRD?: number | null,
  ZTT?: number | null, ZTP?: number | null, ZTR?: number | null, ZTD?: number | null, ZTB?: number | null, ZTBR?: number | null, ZTBD?: number | null,
  ZRT?: number | null, ZRP?: number | null, ZRD?: number | null,
  ZCT?: number | null, ZCP?: number | null, ZCR?: number | null, ZCD?: number | null,
  ZCRT?: number | null, ZCRP?: number | null, ZCRD?: number | null,
  CCDT?: number | null, CDDP?: number | null, CDDR?: number | null, CDDD?: number | null,
  CDBT?: number | null, CDBP?: number | null, CDBR?: number | null, CDBD?: number | null,
  CDFT?: number | null, CDFP?: number | null, CDFR?: number | null, CDFD?: number | null,
  COCT?: number | null, COCP?: number | null, COCR?: number | null, COCD?: number | null,
  COPT?: number | null, COPP?: number | null, COPR?: number | null, COPD?: number | null,
  COET?: number | null, COEP?: number | null, COER?: number | null, COED?: number | null,
  CTPT?: number | null, CTPP?: number | null, CTPR?: number | null, CTPD?: number | null,
  CTST?: number | null, CTSP?: number | null, CTSR?: number | null, CTSD?: number | null,
  CTET?: number | null, CTEP?: number | null, CTER?: number | null, CTED?: number | null,
  KKT?: number | null,
  KJT?: number | null, KKR?: number | null,
  PBT?: number | null, PBR?: number | null,

  //構成比
  JJTK?: number | null, JJPK?: number | null,
  JKTK?: number | null, JKPK?: number | null,
  SLTK?: number | null, SLPK?: number | null, SDTK?: number | null, SDPK?: number | null, SRTK?: number | null, SRPK?: number | null, STTK?: number | null, STPK?: number | null,
  PBLTK?: number | null, PBLPK?: number | null, PBDTK?: number | null, PBDPK?: number | null, PBRTK?: number | null, PBRPK?: number | null, PBTTK?: number | null, PBTPK?: number | null,
  YHLTK?: number | null, YHLPK?: number | null, YHDTK?: number | null, YHDPK?: number | null, YHRTK?: number | null, YHRPK?: number | null, YHTTK?: number | null, YHTPK?: number | null,
  PTTK?: number | null, PTPK?: number | null,
  DDTK?: number | null, DDPK?: number | null, DBTK?: number | null, DBPK?: number | null, DQTK?: number | null, DQPK?: number | null, DFTK?: number | null, DFPK?: number | null, DTTK?: number | null, DTPK?: number | null,
  NTTK?: number | null, NTPK?: number | null,
  CITK?: number | null, CIPK?: number | null, CSTK?: number | null, CSPK?: number | null, CTTK?: number | null, CTPK?: number | null,
  OCTK?: number | null, OCPK?: number | null,
  TSTK?: number | null, TSPK?: number | null,
  CSTTK?: number | null, CSTPK?: number | null,
  OPTK?: number | null, OPPK?: number | null,
  TPTK?: number | null, TPPK?: number | null,
  PPTTK?: number | null, PPTPK?: number | null,
  OETK?: number | null, OEPK?: number | null,
  TETK?: number | null, TEPK?: number | null,
  ETTK?: number | null, ETPK?: number | null,
  KTTK?: number | null, KTPK?: number | null,
  BTTK?: number | null, BTPK?: number | null,
  ATTK?: number | null, ATPK?: number | null,
  GTTK?: number | null, GTPK?: number | null,
  ZTTK?: number | null, ZTPK?: number | null, ZCTK?: number | null, ZCPK?: number | null,
  CCDTK?: number | null, CDDPK?: number | null, CDBTK?: number | null, CDBPK?: number | null, CDFTK?: number | null, CDFPK?: number | null, COCTK?: number | null, COCPK?: number | null, COPTK?: number | null, COPPK?: number | null, COETK?: number | null, COEPK?: number | null, CTPTK?: number | null, CTPPK?: number | null, CTSTK?: number | null, CTSPK?: number | null, CTETK?: number | null, CTEPK?: number | null,
  //予算構成比
  STBK?: number | null, PTBK?: number | null, DTBK?: number | null, NTBK?: number | null, CTBK?: number | null, OCBK?: number | null, OPBK?: number | null, OEBK?: number | null, GTBK?: number | null, ZTBK?: number | null,

  CNDT?: number | null, CNDP?: number | null, CNDR?: number | null, CNDD?: number | null, CNDRT?: number | null, CNDRP?: number | null, CNDRD?: number | null,
  CNPT?: number | null, CNPP?: number | null, CNPR?: number | null, CNPD?: number | null, CNPRT?: number | null, CNPRP?: number | null, CNPRD?: number | null,
  CNGT?: number | null, CNGP?: number | null, CNGR?: number | null, CNGD?: number | null, CNGRT?: number | null, CNGRP?: number | null, CNGRD?: number | null,
  CNAT?: number | null, CNAP?: number | null, CNAR?: number | null, CNAD?: number | null, CNART?: number | null, CNARP?: number | null, CNARD?: number | null,
  CNET?: number | null, CNEP?: number | null, CNER?: number | null, CNED?: number | null, CNRT?: number | null, CNRP?: number | null, CNRD?: number | null,
  SNET?: number | null, SNEP?: number | null, SNER?: number | null, SNED?: number | null, SNRT?: number | null, SNRP?: number | null, SNRD?: number | null,
  ORST?: number | null, ORSP?: number | null, ORSR?: number | null, ORSD?: number | null, ORSRT?: number | null, ORSRP?: number | null, ORSRD?: number | null,
  ORPT?: number | null, ORPP?: number | null, ORPR?: number | null, ORPD?: number | null, ORPRT?: number | null, ORPRP?: number | null, ORPRD?: number | null,

  //
  SRTR?: number | null,
  JJTM?: number | null,
  JJPM?: number | null,
  JJRM?: number | null,
  JJTMK?: number | null,
  JJTW?: number | null,
  JJPW?: number | null,
  JJRW?: number | null,
  JJA?: string | null,
  RNKT?: number | null,
  RNKP?: number | null,
  zifamt?: number | null,
  zifrt?: number | null,
  zfskin9?: number | null,
  //xray2
  RNK?: string | null,
  CNTF?: string | null,
  STRP?: number | null,
  JRTP?: number | null,
  STRT?: number | null,
  JRTT?: number | null,
  STTP?: number | null,
  STPP?: number | null,
  STTT?: number | null,
  STPT?: number | null,
  SNPT?: number | null, SNPP?: number | null, SNPR?: number | null, SNPD?: number | null,
}

type XrayCommitTotalTmpState = {
  retrieveParam: xrayRetrieve.RequestParam,  //検索条件
  retrievedParam: xrayRetrieve.RequestParam,  //検索条件(検索済み)
  retrievedIDs: RetrievedId[],

  data: RowData,
  lastUpdate: LastUpdate,
  arariKakutei: boolean,
  keihiImported: boolean,
}
type XrayQuickTotalTmpState = {
  retrieveParam: xrayRetrieve.RequestParam,  //検索条件
  retrievedParam: xrayRetrieve.RequestParam,  //検索条件(検索済み)
  retrievedIDs: RetrievedId[],

  data: RowData,
  lastUpdate: LastUpdate,
  adjus0Imported: boolean,
}

type XrayCategoryRankTmpState = {
  retrieveParam: xrayRetrieve.RequestParam,  //検索条件
  retrievedParam: xrayRetrieve.RequestParam,  //検索条件(検索済み)
  retrievedIDs: RetrievedId[],

  count: number,

  datas: RowData[],
  rows: [][],
  lastUpdate: LastUpdate,
  adjus0Imported: boolean,
}
type XrayPbRankTmpState = {
  retrieveParam: xrayRetrieve.RequestParam,  //検索条件
  retrievedParam: xrayRetrieve.RequestParam,  //検索条件(検索済み)
  retrievedIDs: RetrievedId[],

  count: number,

  datas: RowData[],
  rows: [][],
  updateLabel: string | null,
}
type XrayGroup2RankTmpState = {
  retrieveParam: xrayRetrieve.RequestParam,  //検索条件
  retrievedParam: xrayRetrieve.RequestParam,  //検索条件(検索済み)
  retrievedIDs: RetrievedId[],

  count: number,
  xray2Keys: CodeName[],

  datas: RowData[],
  rnkDatas: keppinRnkModel.RowData[],
  rows: [][],
  adjus0Imported: boolean,
}
type XrayCenterRankTmpState = {
  retrieveParam: xrayRetrieve.RequestParam,  //検索条件
  retrievedParam: xrayRetrieve.RequestParam,  //検索条件(検索済み)
  retrievedIDs: RetrievedId[],

  count: number,

  datas: RowData[],
  rnkDatas: keppinRnkModel.RowData[],
  rows: [][],
  adjus0Imported: boolean,
}
type Xray1InfoTmpState = {
  retrieveParam: paramRetrieve.RequestParam,  //検索条件
  retrievedParam: paramRetrieve.RequestParam,  //検索条件(検索済み)
  retrievedIDs: RetrievedId[],

  datas: RowData[],
  rows: [][],
}

//xray2
type XrayGroup2CommitTotalTmpState = {
  retrieveParam: xrayRetrieve.RequestParam,  //検索条件
  retrievedParam: xrayRetrieve.RequestParam,  //検索条件(検索済み)
  retrievedIDs: RetrievedId[],

  data: RowData,
  lastUpdate: LastUpdate,
  arariKakutei: boolean,
  keihiImported: boolean,
}
type XrayGroup2QuickTotalTmpState = {
  retrieveParam: xrayRetrieve.RequestParam,  //検索条件
  retrievedParam: xrayRetrieve.RequestParam,  //検索条件(検索済み)
  retrievedIDs: RetrievedId[],

  data: RowData,
  lastUpdate: LastUpdate,
  adjus0Imported: boolean,
}
type Xray2MakerRankTmpState = {
  retrieveParam: xrayRetrieve.RequestParam,  //検索条件
  retrievedParam: xrayRetrieve.RequestParam,  //検索条件(検索済み)
  retrievedIDs: RetrievedId[],

  count: number,

  datasP: RowData[],
  rowsP: [][],
  datasT: RowData[],
  rowsT: [][],
  adjus0Imported: boolean,
  lastUpdate: LastUpdate,
  keihiImported: boolean,
}
type Xray2CenterRankTmpState = {
  retrieveParam: xrayRetrieve.RequestParam,  //検索条件
  retrievedParam: xrayRetrieve.RequestParam,  //検索条件(検索済み)
  retrievedIDs: RetrievedId[],

  count: number,
  selectedCenter: CodeName | null,

  datas: RowData[],
  rows: [][],
  adjus0Imported: boolean,
  lastUpdate: LastUpdate,
  keihiImported: boolean,
}

//Page State
export type XrayTmpState = {
  //xray1
  commitTotalState: XrayCommitTotalTmpState,
  quickTotalState: XrayQuickTotalTmpState,

  categoryRankState: XrayCategoryRankTmpState,
  pbRankState: XrayPbRankTmpState,
  group2RankState: XrayGroup2RankTmpState,
  centerRankState: XrayCenterRankTmpState,

  infoState: Xray1InfoTmpState,

  //xray2
  xray2Key: string,
  xray2Keys: CodeName[],
  group2CommitTotalState: XrayGroup2CommitTotalTmpState,
  group2QuickTotalState: XrayGroup2QuickTotalTmpState,
  group2CategoryRankState: XrayCategoryRankTmpState,
  group2PbRankState: XrayPbRankTmpState,
  group2MakerRankState: Xray2MakerRankTmpState,
  group2CenterRankState: Xray2CenterRankTmpState,
  group2CenterMakerRankState: Xray2MakerRankTmpState,

  progress: Record<string, unknown>,
  errorMessage: string | null,
};

export const initialState: XrayTmpState = {
  //xray1
  commitTotalState: {
    retrieveParam: null,
    retrievedParam: null,

    retrievedIDs: [],
    data: null,
    lastUpdate: null,
    arariKakutei: null,
    keihiImported: null,
  },
  quickTotalState: {
    retrieveParam: null,
    retrievedParam: null,

    retrievedIDs: [],
    data: null,
    lastUpdate: null,
    adjus0Imported: null,
  },
  categoryRankState: {
    retrieveParam: null,
    retrievedParam: null,

    retrievedIDs: [],

    count: 21,
    datas: [],
    rows: [],
    lastUpdate: null,
    adjus0Imported: null,
  },
  pbRankState: {
    retrieveParam: null,
    retrievedParam: null,

    count: 21,

    retrievedIDs: [],
    datas: [],
    rows: [],
    updateLabel: null,
  },
  group2RankState: {
    retrieveParam: null,
    retrievedParam: null,
    retrievedIDs: [],

    count: 30,
    xray2Keys: [],

    datas: [],
    rnkDatas: [],
    rows: [],
    adjus0Imported: null,
  },
  centerRankState: {
    retrieveParam: null,
    retrievedParam: null,

    count: 30,

    retrievedIDs: [],
    datas: [],
    rnkDatas: [],
    rows: [],
    adjus0Imported: null,
  },

  infoState: {
    retrieveParam: null,
    retrievedParam: null,
    retrievedIDs: [],
    datas: [],
    rows: [],
  },

  //xray2
  xray2Key: null,
  xray2Keys: [],
  group2CommitTotalState: {
    retrieveParam: null,
    retrievedParam: null,

    retrievedIDs: [],
    data: null,
    lastUpdate: null,
    arariKakutei: null,
    keihiImported: null,
  },
  group2QuickTotalState: {
    retrieveParam: null,
    retrievedParam: null,

    retrievedIDs: [],
    data: null,
    lastUpdate: null,
    adjus0Imported: null,
  },
  group2CategoryRankState: {
    retrieveParam: null,
    retrievedParam: null,

    retrievedIDs: [],

    count: 21,
    datas: [],
    rows: [],
    lastUpdate: null,
    adjus0Imported: null,
  },
  group2PbRankState: {
    retrieveParam: null,
    retrievedParam: null,

    count: 21,

    retrievedIDs: [],
    datas: [],
    rows: [],
    updateLabel: null,
  },
  group2MakerRankState: {
    retrieveParam: null,
    retrievedParam: null,

    count: 15,

    retrievedIDs: [],
    datasP: [],
    rowsP: [],
    datasT: [],
    rowsT: [],
    adjus0Imported: null,
    lastUpdate: null,
    keihiImported: null,
  },
  group2CenterRankState: {
    retrieveParam: null,
    retrievedParam: null,

    count: 20,
    selectedCenter: null,

    retrievedIDs: [],
    datas: [],
    rows: [],
    adjus0Imported: null,
    lastUpdate: null,
    keihiImported: null,
  },
  group2CenterMakerRankState: {
    retrieveParam: null,
    retrievedParam: null,

    count: 15,

    retrievedIDs: [],
    datasP: [],
    rowsP: [],
    datasT: [],
    rowsT: [],
    adjus0Imported: null,
    lastUpdate: null,
    keihiImported: null,
  },


  progress: {},

  errorMessage: null,
};

export type xray1Type = 'commit' | 'quick' | 'category' | 'pb' | 'group2' | 'center' | 'info';    //xray1
export type xray2Type = 'group2Commit' | 'group2Quick' | 'group2Category' | 'group2Pb' | 'group2Maker' | 'group2Center' | 'group2CenterMaker'; //xray2
type xrayType = xray1Type | xray2Type;

//Page Slice
export type XrayTmpReducer = {
  resetOnWillUnmount: (state: XrayTmpState) => void,
  setRetrieveParam: (state: XrayTmpState, action: PayloadAction<{
    type: xrayType,
    param: xrayRetrieve.RequestParam | paramRetrieve.RequestParam
  }>) => void,
  setRetrievedParam: (state: XrayTmpState, action: PayloadAction<{
    type: xrayType,
    param: xrayRetrieve.RequestParam | paramRetrieve.RequestParam
  }>) => void,
  setRetrievedID: (state: XrayTmpState, action: PayloadAction<{
    type: xrayType,
    id: RetrievedId
  }>) => void,
  removeRetrievedID: (state: XrayTmpState, action: PayloadAction<{ type: xrayType }>) => void,
  putProgress: (state: XrayTmpState, action: PayloadAction<string>) => void,
  removeProgress: (state: XrayTmpState, action: PayloadAction<string>) => void,
  searched: (state: XrayTmpState, action: PayloadAction<{
    type: xrayType,
    datas: RowData[],
    lastUpdate: LastUpdate,
    adjus0Imported: boolean,
    arariKakutei?: boolean,
    colModel: categoryRank.XrayColModel | pbRank.XrayColModel | group2Rank.XrayColModel | centerRank.XrayColModel,
  }>) => void,
  searched2: (state: XrayTmpState, action: PayloadAction<{
    type: xray2Type,
    datasP: RowData[],
    datasT: RowData[],
    adjus0Imported: boolean,
    lastUpdate: LastUpdate,
    colModelP: makerRank.XrayColModel | group2CenterRank.XrayColModel,
    colModelT: makerRank.XrayColModel | group2CenterRank.XrayColModel,
  }>) => void,
  searched3: (state: XrayTmpState, action: PayloadAction<{
    type: xray2Type,
    datasP: RowData[],
    datasT: RowData[],
    adjus0Imported: boolean,
    lastUpdate: LastUpdate,
    colModel: makerRank.XrayColModel | group2CenterRank.XrayColModel,
  }>) => void,
  searched4: (state: XrayTmpState, action: PayloadAction<{
    type: xrayType,
    datas: RowData[],
    rnkDatas: keppinRnkModel.RowData[],
    lastUpdate: LastUpdate,
    adjus0Imported: boolean,
    colModel: categoryRank.XrayColModel | pbRank.XrayColModel | group2Rank.XrayColModel | centerRank.XrayColModel,
  }>) => void,
  searched5: (state: XrayTmpState, action: PayloadAction<{
    type: xrayType,
    datas: common.Param[],
    colModel: infoModel.XrayColModel,
  }>) => void,
  setXray2Key: (state: XrayTmpState, action: PayloadAction<string>) => void,
  setXray2Keys: (state: XrayTmpState, action: PayloadAction<CodeName[]>) => void,
  setSelectedCenter: (state: XrayTmpState, action: PayloadAction<CodeName>) => void,
  setErrorMessage: (state: XrayTmpState, action: PayloadAction<string>) => void,
}

const createReducerContent = (): XrayTmpReducer => {
  return {
    resetOnWillUnmount(state: XrayTmpState) {
      state.xray2Key = initialState.xray2Key;
      state.xray2Keys = initialState.xray2Keys;
    },
    //検索条件
    setRetrieveParam(state: XrayTmpState, action: PayloadAction<{
      type: xrayType,
      param: xrayRetrieve.RequestParam | paramRetrieve.RequestParam
    }>) {
      switch (action.payload.type) {
        //xray1
        case 'commit': state.commitTotalState.retrieveParam = action.payload.param; break;
        case 'quick': state.quickTotalState.retrieveParam = action.payload.param; break;
        case 'category': state.categoryRankState.retrieveParam = action.payload.param; break;
        case 'pb': state.pbRankState.retrieveParam = action.payload.param; break;
        case 'group2': state.group2RankState.retrieveParam = action.payload.param; break;
        case 'center': state.centerRankState.retrieveParam = action.payload.param; break;
        //
        case 'info': state.infoState.retrieveParam = action.payload.param; break;
        //xray2
        case 'group2Commit': state.group2CommitTotalState.retrieveParam = action.payload.param; break;
        case 'group2Quick': state.group2QuickTotalState.retrieveParam = action.payload.param; break;
        case 'group2Category': state.group2CategoryRankState.retrieveParam = action.payload.param; break;
        case 'group2Pb': state.group2PbRankState.retrieveParam = action.payload.param; break;
        case 'group2Maker': state.group2MakerRankState.retrieveParam = action.payload.param; break;
        case 'group2Center': state.group2CenterRankState.retrieveParam = action.payload.param; break;
        case 'group2CenterMaker': state.group2CenterMakerRankState.retrieveParam = action.payload.param; break;
      }
    },
    setRetrievedParam(state: XrayTmpState, action: PayloadAction<{
      type: xrayType,
      param: xrayRetrieve.RequestParam | paramRetrieve.RequestParam
    }>) {
      switch (action.payload.type) {
        //xray1
        case 'commit': state.commitTotalState.retrievedParam = action.payload.param; break;
        case 'quick': state.quickTotalState.retrievedParam = action.payload.param; break;
        case 'category': state.categoryRankState.retrievedParam = action.payload.param; break;
        case 'pb': state.pbRankState.retrievedParam = action.payload.param; break;
        case 'group2': state.group2RankState.retrievedParam = action.payload.param; break;
        case 'center': state.centerRankState.retrievedParam = action.payload.param; break;
        //
        case 'info': state.infoState.retrievedParam = action.payload.param; break;
        //xray2
        case 'group2Commit': state.group2CommitTotalState.retrievedParam = action.payload.param; break;
        case 'group2Quick': state.group2QuickTotalState.retrievedParam = action.payload.param; break;
        case 'group2Category': state.group2CategoryRankState.retrievedParam = action.payload.param; break;
        case 'group2Pb': state.group2PbRankState.retrievedParam = action.payload.param; break;
        case 'group2Maker': state.group2MakerRankState.retrievedParam = action.payload.param; break;
        case 'group2Center': state.group2CenterRankState.retrievedParam = action.payload.param; break;
        case 'group2CenterMaker': state.group2CenterMakerRankState.retrievedParam = action.payload.param; break;
      }
    },
    setRetrievedID(state: XrayTmpState, action: PayloadAction<{
      type: xrayType,
      id: RetrievedId
    }>) {
      switch (action.payload.type) {
        //xray1
        case 'commit': state.commitTotalState.retrievedIDs = [action.payload.id]; break;
        case 'quick': state.quickTotalState.retrievedIDs = [action.payload.id]; break;
        case 'category': state.categoryRankState.retrievedIDs = [action.payload.id]; break;
        case 'pb': state.pbRankState.retrievedIDs = [action.payload.id]; break;
        case 'group2': state.group2RankState.retrievedIDs = [action.payload.id]; break;
        case 'center': state.centerRankState.retrievedIDs = [action.payload.id]; break;
        //
        case 'info': state.infoState.retrievedIDs = [action.payload.id]; break;
        //xray2
        case 'group2Commit': state.group2CommitTotalState.retrievedIDs = [action.payload.id]; break;
        case 'group2Quick': state.group2QuickTotalState.retrievedIDs = [action.payload.id]; break;
        case 'group2Category': state.group2CategoryRankState.retrievedIDs = [action.payload.id]; break;
        case 'group2Pb': state.group2PbRankState.retrievedIDs = [action.payload.id]; break;
        case 'group2Maker': state.group2MakerRankState.retrievedIDs = [action.payload.id]; break;
        case 'group2Center': state.group2CenterRankState.retrievedIDs = [action.payload.id]; break;
        case 'group2CenterMaker': state.group2CenterMakerRankState.retrievedIDs = [action.payload.id]; break;
      }
    },
    removeRetrievedID(state: XrayTmpState, action: PayloadAction<{ type: xrayType }>) {
      switch (action.payload.type) {
        //xray2
        case 'group2Commit': state.group2CommitTotalState.retrievedIDs = []; break;
        case 'group2Quick': state.group2QuickTotalState.retrievedIDs = []; break;
        case 'group2Category': state.group2CategoryRankState.retrievedIDs = []; break;
        case 'group2Pb': state.group2PbRankState.retrievedIDs = []; break;
        case 'group2Maker': state.group2MakerRankState.retrievedIDs = []; break;
        case 'group2Center': state.group2CenterRankState.retrievedIDs = []; break;
        case 'group2CenterMaker': state.group2CenterMakerRankState.retrievedIDs = []; break;
      }
    },
    putProgress(state: XrayTmpState, action: PayloadAction<string>) {
      const key = action.payload;
      const progressNew = { ...state.progress };
      progressNew[key] = true;
      state.progress = progressNew;
    },
    removeProgress(state: XrayTmpState, action: PayloadAction<string>) {
      const key = action.payload;
      const progressNew = {};
      Object.keys(state.progress).forEach(k => {
        if (key != k) {
          progressNew[k] = true;
        }
      })
      state.progress = progressNew;
    },
    searched(state: XrayTmpState, action: PayloadAction<{
      type: xrayType,
      datas: RowData[],
      lastUpdate: LastUpdate,
      arariKakutei?: boolean,
      adjus0Imported: boolean,
      colModel: categoryRank.XrayColModel | pbRank.XrayColModel | group2Rank.XrayColModel | centerRank.XrayColModel,
    }>) {
      //計算
      let datas = action.payload.datas;
      const lastUpdate = action.payload.lastUpdate;
      const arariKakutei = action.payload.arariKakutei;
      const adjus0Imported = action.payload.adjus0Imported;
      let lvMax = 1;
      const colModel = action.payload.colModel;
      const colKeys = colModel?.colKeys;
      const type = action.payload.type;
      let keihiImported;

      switch (action.payload.type) {
        case 'commit':
          datas = parseData(datas);
          datas = calcTotal(datas); //合計行の作成
          datas = calcDatas(type, datas, datas, []); //計算項目の計算
          {
            const total = datas.find(data => data.TP === 'total');
            keihiImported = total?.CNTF ? true : false;
          }
          Object.assign(state.commitTotalState, {
            data: datas.find(data => data.TP === 'total'),
            lastUpdate: lastUpdate,
            keihiImported: keihiImported,
            arariKakutei: arariKakutei,
          });
          break;
        case 'quick':
          datas = parseData(datas);
          datas = calcTotal(datas); //合計行の作成
          datas = calcDatas(type, datas, datas, []); //計算項目の計算
          Object.assign(state.quickTotalState, {
            data: datas.find(data => data.TP === 'total'),
            lastUpdate: lastUpdate,
            adjus0Imported: adjus0Imported,
          });
          break;
        case 'category':
          datas = parseData(datas);
          {
            datas = calcTotal(datas); //合計行の作成
            datas = calcDatas(type, datas, datas, []); //計算項目の計算
            datas = datas.filter(data => data.TP !== 'total');
            datas = datas.slice(0, state.categoryRankState.count);
            datas = resetRowNo(datas, null, lvMax); //列番号の振りなおし
          }
          Object.assign(state.categoryRankState, {
            datas: datas,
            rows: convertRows(datas, colKeys),
            lastUpdate: lastUpdate,
            adjus0Imported: adjus0Imported,
          });
          break;
        case 'pb':
          datas = parseData(datas);
          {
            datas = calcTotal(datas); //合計行の作成
            datas = calcDatas(type, datas, datas, []); //計算項目の計算
            datas = datas.filter(data => data.TP !== 'total');
            datas = resetRowNo(datas, null, lvMax); //列番号の振りなおし
          }
          Object.assign(state.pbRankState, {
            datas: datas,
            rows: convertRows(datas, colKeys),
            updateLabel: '受注/欠品表示目安 ' + lastUpdate.end_time,
          });
          break;
        case 'group2Commit':
          datas = parseData(datas);
          datas = calcTotal(datas); //合計行の作成
          datas = calcDatas(type, datas, datas, []); //計算項目の計算
          {
            const total = datas.find(data => data.TP === 'total');
            keihiImported = total?.CNTF ? true : false;
          }
          Object.assign(state.group2CommitTotalState, {
            data: datas.find(data => data.TP === 'total'),
            lastUpdate: lastUpdate,
            keihiImported: keihiImported,
            arariKakutei: arariKakutei,
          });
          break;
        case 'group2Quick':
          datas = parseData(datas);
          datas = calcTotal(datas); //合計行の作成
          datas = calcDatas(type, datas, datas, []); //計算項目の計算
          Object.assign(state.group2QuickTotalState, {
            data: datas.find(data => data.TP === 'total'),
            lastUpdate: lastUpdate,
            adjus0Imported: adjus0Imported,
          });
          break;
        case 'group2Category':
          datas = parseData(datas);
          {
            datas = calcTotal(datas); //合計行の作成
            datas = calcDatas(type, datas, datas, []); //計算項目の計算
            datas = datas.filter(data => data.TP !== 'total');
            datas = datas.slice(0, state.group2CategoryRankState.count);
            datas = resetRowNo(datas, null, lvMax); //列番号の振りなおし
          }
          Object.assign(state.group2CategoryRankState, {
            datas: datas,
            rows: convertRows(datas, colKeys),
            lastUpdate: lastUpdate,
            adjus0Imported: adjus0Imported,
          });
          break;
        case 'group2Pb':
          datas = parseData(datas);
          {
            datas = calcTotal(datas); //合計行の作成
            datas = calcDatas(type, datas, datas, []); //計算項目の計算
            datas = datas.filter(data => data.TP !== 'total');
            datas = resetRowNo(datas, null, lvMax); //列番号の振りなおし
          }
          Object.assign(state.group2PbRankState, {
            datas: datas,
            rows: convertRows(datas, colKeys),
            updateLabel: '受注/欠品表示目安 ' + lastUpdate.end_time,
          });
          break;
      }
    },
    searched2(state: XrayTmpState, action: PayloadAction<{
      type: xray2Type,
      datasP: RowData[],
      datasT: RowData[],
      adjus0Imported: boolean,
      lastUpdate: LastUpdate,
      colModelP: makerRank.XrayColModel | group2CenterRank.XrayColModel,
      colModelT: makerRank.XrayColModel | group2CenterRank.XrayColModel,
    }>) {
      //計算
      let datasP = action.payload.datasP;
      let datasT = action.payload.datasT;
      const adjus0Imported = action.payload.adjus0Imported;
      const lastUpdate = action.payload.lastUpdate;
      let lvMax = 1;
      const colModelP = action.payload.colModelP;
      const colKeysP = colModelP?.colKeys;
      const colModelT = action.payload.colModelT;
      const colKeysT = colModelT?.colKeys;
      const type = action.payload.type;
      let keihiImported;

      switch (action.payload.type) {
        case 'group2Maker':
          datasP = parseData(datasP);
          {
            datasP = calcTotal(datasP); //合計行の作成
            datasP = calcDatas(type, datasP, datasP, []); //計算項目の計算
            {
              const total = datasP.find(data => data.TP === 'total');
              keihiImported = total?.CNTF ? true : false;
            }
            datasP = datasP.filter(data => data.TP !== 'total');
          }
          datasT = parseData(datasT);
          {
            datasT = calcTotal(datasT); //合計行の作成
            datasT = calcDatas(type, datasT, datasT, []); //計算項目の計算
            datasT = datasT.filter(data => data.TP !== 'total');
          }
          {
            const count = Math.min(state.group2MakerRankState.count, datasP.length, datasT.length);
            datasP = datasP.slice(0, count);
            datasP = resetRowNo(datasP, null, lvMax); //列番号の振りなおし
            datasT = datasT.slice(0, count);
            datasT = resetRowNo(datasT, null, lvMax); //列番号の振りなおし
          }
          Object.assign(state.group2MakerRankState, {
            datasP: datasP,
            rowsP: convertRows(datasP, colKeysP),
            datasT: datasT,
            rowsT: convertRows(datasT, colKeysT),
            adjus0Imported: adjus0Imported,
            lastUpdate: lastUpdate,
            keihiImported: false,
          });
          break;
        case 'group2CenterMaker':
          datasP = parseData(datasP);
          {
            datasP = calcTotal(datasP); //合計行の作成
            datasP = calcDatas(type, datasP, datasP, []); //計算項目の計算
            datasP = datasP.filter(data => data.TP !== 'total');
          }
          datasT = parseData(datasT);
          {
            datasT = calcTotal(datasT); //合計行の作成
            datasT = calcDatas(type, datasT, datasT, []); //計算項目の計算
            datasT = datasT.filter(data => data.TP !== 'total');
          }
          {
            const count = Math.min(state.group2CenterMakerRankState.count, datasP.length, datasT.length);
            datasP = datasP.slice(0, count);
            datasP = resetRowNo(datasP, null, lvMax); //列番号の振りなおし
            datasT = datasT.slice(0, count);
            datasT = resetRowNo(datasT, null, lvMax); //列番号の振りなおし
          }
          Object.assign(state.group2CenterMakerRankState, {
            datasP: datasP,
            rowsP: convertRows(datasP, colKeysP),
            datasT: datasT,
            rowsT: convertRows(datasT, colKeysT),
            adjus0Imported: adjus0Imported,
            lastUpdate: lastUpdate,
            keihiImported: keihiImported,
          });
          break;
      }
    },
    searched3(state: XrayTmpState, action: PayloadAction<{
      type: xray2Type,
      datasP: RowData[],
      datasT: RowData[],
      adjus0Imported: boolean,
      lastUpdate: LastUpdate,
      colModel: makerRank.XrayColModel | group2CenterRank.XrayColModel,
    }>) {
      //計算
      let datasP = action.payload.datasP;
      let datasT = action.payload.datasT;
      let datas = [];
      const adjus0Imported = action.payload.adjus0Imported;
      const lastUpdate = action.payload.lastUpdate;
      let lvMax = 1;
      const colModel = action.payload.colModel;
      const colKeys = colModel?.colKeys;
      const type = action.payload.type;
      let keihiImported;

      switch (action.payload.type) {
        case 'group2Center':
          datasP = parseData(datasP);
          {
            datasP = calcTotal(datasP); //合計行の作成
            datasP = calcDatas(type, datasP, datasP, []); //計算項目の計算
            const total = datasP.find(data => data.TP === 'total');
            keihiImported = total?.CNTF ? true : false;
            datasP = datasP.filter(data => data.TP !== 'total');
          }
          datasT = parseData(datasT);
          {
            datasT = calcTotal(datasT); //合計行の作成
            datasT = calcDatas(type, datasT, datasT, []); //計算項目の計算
            datasT = datasT.filter(data => data.TP !== 'total');
          }
          {
            const count = Math.min(state.group2CenterRankState.count, datasP.length, datasT.length);
            datasP = datasP.slice(0, count);
            datasT = datasT.slice(0, count);

            datas = datasP.map(dataP => {
              const dataT = datasT.find(t => t.H1CD == dataP.H1CD);
              return {
                ...dataP,
                STRP: dataP.STR,
                JRTP: dataP.JRT,

                STT: dataT?.STT ? dataT.STT : 0,
                STRT: dataT?.STR ? dataT.STR : 0,
                STD: dataT?.STD ? dataT.STD : 0,
                STBR: dataT?.STBR ? dataT.STBR : 0,
                STBD: dataT?.STBD ? dataT.STBD : 0,
                JRTT: dataT?.JRT ? dataT.JRT : 0,

                STTP: dataP.STT,
                STPP: dataP.STP,
                STTT: dataT?.STT ? dataT.STT : 0,
                STPT: dataT?.STP ? dataT.STP : 0,
              };
            });

            datas = resetRowNo(datas, null, lvMax); //列番号の振りなおし
          }
          Object.assign(state.group2CenterRankState, {
            datas: datas,
            rows: convertRows(datas, colKeys),
            selectedCenter: new CodeName({ code: datasP[0].H1CD, name: datasP[0].H1NM }),
            adjus0Imported: adjus0Imported,
            lastUpdate: lastUpdate,
            keihiImported: keihiImported,
          });
          break;
      }
    },
    searched4(state: XrayTmpState, action: PayloadAction<{
      type: xrayType,
      datas: RowData[],
      rnkDatas: keppinRnkModel.RowData[],
      lastUpdate: LastUpdate,
      adjus0Imported: boolean,
      colModel: group2Rank.XrayColModel,
    }>) {
      //計算
      let datas = action.payload.datas;
      let rnkDatas = action.payload.rnkDatas;
      const adjus0Imported = action.payload.adjus0Imported;
      let lvMax = 1;
      const colModel = action.payload.colModel;
      const colKeys = colModel?.colKeys;
      const type = action.payload.type;

      switch (action.payload.type) {
        case 'group2':
          datas = parseData(datas);
          let xray2Keys;
          {
            datas = calcTotal(datas); //合計行の作成
            datas = calcDatas(type, datas, datas, []); //計算項目の計算
            datas = datas.filter(data => data.TP !== 'total');
            xray2Keys = datas
              .map((data, index) => new CodeName({ code: data.H1ID, name: data.H1NM, option1: '' + index })).
              filter((value, index, self) => self.findIndex(cn => cn.code == value.code) === index);
            datas = datas.slice(0, state.group2RankState.count);
            datas = resetRowNo(datas, null, lvMax); //列番号の振りなおし
          }

          rnkDatas.forEach(data => {
            if (typeof data.JKT === 'string') data.JKT = parseInt(data.JKT);
            if (typeof data.KI1KA === 'string') data.KI1KA = parseInt(data.KI1KA);
            if (typeof data.KI2KA === 'string') data.KI2KA = parseInt(data.KI2KA);
            if (typeof data.KI3KA === 'string') data.KI3KA = parseInt(data.KI3KA);
            if (typeof data.SLT === 'string') data.SLT = parseInt(data.SLT);

            data.JJT = calcUtil.plus(data.SLT, data.JKT);
            data.JJP = calcUtil.plus(data.SLP, data.JKP);

            data.JRT = calcUtil.divide(data.JKT, data.JJT);
            data.JRP = calcUtil.divide(data.JKP, data.JJP);

            data.JRD = calcUtil.multiply(calcUtil.minus(data.JRT, data.JRP), 100);

            //1.自社責に寄せる //0未満は0表記
            data.KI1KA = calcUtil.greater(0, calcUtil.minus(data.JKT, calcUtil.sum([data.KI2KA, data.KI3KA])));
            //欠品責任別
            data.KI1KR = calcUtil.divide(data.KI1KA, data.JJT);
            data.KI2KR = calcUtil.divide(data.KI2KA, data.JJT);
            data.KI3KR = calcUtil.divide(data.KI3KA, data.JJT);
          });

          Object.assign(state.group2RankState, {
            datas: datas,
            rnkDatas: rnkDatas,
            xray2Keys: xray2Keys,
            rows: convertRows(datas, colKeys),
            adjus0Imported: adjus0Imported,
          });
          break;
        case 'center':
          datas = parseData(datas);
          {
            datas = calcTotal(datas); //合計行の作成
            datas = calcDatas(type, datas, datas, []); //計算項目の計算
            datas = datas.filter(data => data.TP !== 'total');
            datas = resetRowNo(datas, null, lvMax); //列番号の振りなおし
          }

          rnkDatas.forEach(data => {
            if (typeof data.JKT === 'string') data.JKT = parseInt(data.JKT);
            if (typeof data.KI1KA === 'string') data.KI1KA = parseInt(data.KI1KA);
            if (typeof data.KI2KA === 'string') data.KI2KA = parseInt(data.KI2KA);
            if (typeof data.KI3KA === 'string') data.KI3KA = parseInt(data.KI3KA);
            if (typeof data.SLT === 'string') data.SLT = parseInt(data.SLT);

            data.JJT = calcUtil.plus(data.SLT, data.JKT);
            data.JJP = calcUtil.plus(data.SLP, data.JKP);

            data.JRT = calcUtil.divide(data.JKT, data.JJT);
            data.JRP = calcUtil.divide(data.JKP, data.JJP);

            data.JRD = calcUtil.multiply(calcUtil.minus(data.JRT, data.JRP), 100);

            //1.自社責に寄せる //0未満は0表記
            data.KI1KA = calcUtil.greater(0, calcUtil.minus(data.JKT, calcUtil.sum([data.KI2KA, data.KI3KA])));
            //欠品責任別
            data.KI1KR = calcUtil.divide(data.KI1KA, data.JJT);
            data.KI2KR = calcUtil.divide(data.KI2KA, data.JJT);
            data.KI3KR = calcUtil.divide(data.KI3KA, data.JJT);
          });

          Object.assign(state.centerRankState, {
            datas: datas,
            rnkDatas: rnkDatas,
            rows: convertRows(datas, colKeys),
            adjus0Imported: adjus0Imported,
          });
          break;
      }
    },
    searched5(state: XrayTmpState, action: PayloadAction<{
      type: xrayType,
      datas: common.Param[],
      colModel: infoModel.XrayColModel,
    }>) {
      let datas = action.payload.datas;
      const colModel = action.payload.colModel;
      const colKeys = colModel?.colKeys;

      switch (action.payload.type) {
        case 'info':
          Object.assign(state.infoState, {
            data: datas,
            rows: convertRows(datas, colKeys),
          });
          break;
      }
    },
    setXray2Key(state: XrayTmpState, action: PayloadAction<string>) {
      state.xray2Key = action.payload;
    },
    setXray2Keys(state: XrayTmpState, action: PayloadAction<CodeName[]>) {
      state.xray2Keys = action.payload;
    },
    setSelectedCenter(state: XrayTmpState, action: PayloadAction<CodeName>) {
      state.group2CenterRankState.selectedCenter = action.payload;
    },
    setErrorMessage(state: XrayTmpState, action: PayloadAction<string>) {
      state.errorMessage = action.payload;
    }
  }
};

const createSliceContent = (name: string) => createSlice({
  name: name,
  initialState,
  reducers: createReducerContent(),
});

//数値のパース(数値が文字列で返ってくる)
export const parseData = (datas: RowData[]): RowData[] => {
  //set No.
  datas.forEach((data) => {
    if (typeof data.JJT === 'string') data.JJT = parseInt(data.JJT);
    if (typeof data.JJP === 'string') data.JJP = parseInt(data.JJP);
    if (typeof data.JJTW === 'string') data.JJTW = parseInt(data.JJTW);
    if (typeof data.JJPW === 'string') data.JJPW = parseInt(data.JJPW);
    if (typeof data.JJTM === 'string') data.JJTM = parseInt(data.JJTM);
    if (typeof data.JJPM === 'string') data.JJPM = parseInt(data.JJPM);
    if (typeof data.JKT === 'string') data.JKT = parseInt(data.JKT);
    if (typeof data.JKP === 'string') data.JKP = parseInt(data.JKP);
    //欠品責任別
    if (typeof data.KI1KA === 'string') data.KI1KA = parseInt(data.KI1KA);
    if (typeof data.KI2KA === 'string') data.KI2KA = parseInt(data.KI2KA);
    if (typeof data.KI3KA === 'string') data.KI3KA = parseInt(data.KI3KA);

    if (typeof data.SLT === 'string') data.SLT = parseInt(data.SLT);
    if (typeof data.SLP === 'string') data.SLP = parseInt(data.SLP);
    if (typeof data.SDT === 'string') data.SDT = parseInt(data.SDT);
    if (typeof data.SDP === 'string') data.SDP = parseInt(data.SDP);
    if (typeof data.SRT === 'string') data.SRT = parseInt(data.SRT);
    if (typeof data.SRP === 'string') data.SRP = parseInt(data.SRP);
    if (typeof data.STT === 'string') data.STT = parseInt(data.STT);
    if (typeof data.STP === 'string') data.STP = parseInt(data.STP);
    if (typeof data.STB === 'string') data.STB = parseInt(data.STB);
    if (typeof data.PBLT === 'string') data.PBLT = parseInt(data.PBLT);
    if (typeof data.PBLP === 'string') data.PBLP = parseInt(data.PBLP);
    if (typeof data.PBDT === 'string') data.PBDT = parseInt(data.PBDT);
    if (typeof data.PBDP === 'string') data.PBDP = parseInt(data.PBDP);
    if (typeof data.PBRT === 'string') data.PBRT = parseInt(data.PBRT);
    if (typeof data.PBRP === 'string') data.PBRP = parseInt(data.PBRP);
    if (typeof data.YHLT === 'string') data.YHLT = parseInt(data.YHLT);
    if (typeof data.YHLP === 'string') data.YHLP = parseInt(data.YHLP);
    if (typeof data.YHDT === 'string') data.YHDT = parseInt(data.YHDT);
    if (typeof data.YHDP === 'string') data.YHDP = parseInt(data.YHDP);
    if (typeof data.YHRT === 'string') data.YHRT = parseInt(data.YHRT);
    if (typeof data.YHRP === 'string') data.YHRP = parseInt(data.YHRP);
    if (typeof data.PTT === 'string') data.PTT = parseInt(data.PTT);
    if (typeof data.PTP === 'string') data.PTP = parseInt(data.PTP);
    if (typeof data.PTB === 'string') data.PTB = parseInt(data.PTB);
    if (typeof data.DDT === 'string') data.DDT = parseInt(data.DDT);
    if (typeof data.DDP === 'string') data.DDP = parseInt(data.DDP);
    if (typeof data.DBT === 'string') data.DBT = parseInt(data.DBT);
    if (typeof data.DBP === 'string') data.DBP = parseInt(data.DBP);
    if (typeof data.DQT === 'string') data.DQT = parseInt(data.DQT);
    if (typeof data.DQP === 'string') data.DQP = parseInt(data.DQP);
    if (typeof data.DFT === 'string') data.DFT = parseInt(data.DFT);
    if (typeof data.DFP === 'string') data.DFP = parseInt(data.DFP);
    if (typeof data.DTT === 'string') data.DTT = parseInt(data.DTT);
    if (typeof data.DTP === 'string') data.DTP = parseInt(data.DTP);
    if (typeof data.DTB === 'string') data.DTB = parseInt(data.DTB);
    if (typeof data.NTT === 'string') data.NTT = parseInt(data.NTT);
    if (typeof data.NTP === 'string') data.NTP = parseInt(data.NTP);
    if (typeof data.NTB === 'string') data.NTB = parseInt(data.NTB);
    if (typeof data.CIT === 'string') data.CIT = parseInt(data.CIT);
    if (typeof data.CIP === 'string') data.CIP = parseInt(data.CIP);
    if (typeof data.CST === 'string') data.CST = parseInt(data.CST);
    if (typeof data.CSP === 'string') data.CSP = parseInt(data.CSP);
    if (typeof data.CTB === 'string') data.CTB = parseInt(data.CTB);
    if (typeof data.OCT === 'string') data.OCT = parseInt(data.OCT);
    if (typeof data.OCP === 'string') data.OCP = parseInt(data.OCP);
    if (typeof data.OCB === 'string') data.OCB = parseInt(data.OCB);
    if (typeof data.OPT === 'string') data.OPT = parseInt(data.OPT);
    if (typeof data.OPP === 'string') data.OPP = parseInt(data.OPP);
    if (typeof data.OPB === 'string') data.OPB = parseInt(data.OPB);
    if (typeof data.OET === 'string') data.OET = parseInt(data.OET);
    if (typeof data.OEP === 'string') data.OEP = parseInt(data.OEP);
    if (typeof data.OEB === 'string') data.OEB = parseInt(data.OEB);
    if (typeof data.TPT === 'string') data.TPT = parseInt(data.TPT);
    if (typeof data.TPP === 'string') data.TPP = parseInt(data.TPP);
    if (typeof data.TST === 'string') data.TST = parseInt(data.TST);
    if (typeof data.TSP === 'string') data.TSP = parseInt(data.TSP);
    if (typeof data.TET === 'string') data.TET = parseInt(data.TET);
    if (typeof data.TEP === 'string') data.TEP = parseInt(data.TEP);
    if (typeof data.BTT === 'string') data.BTT = parseInt(data.BTT);
    if (typeof data.BTP === 'string') data.BTP = parseInt(data.BTP);
    if (typeof data.ATT === 'string') data.ATT = parseInt(data.ATT);
    if (typeof data.ATP === 'string') data.ATP = parseInt(data.ATP);
    if (typeof data.GTT === 'string') data.GTT = parseInt(data.GTT);
    if (typeof data.GTP === 'string') data.GTP = parseInt(data.GTP);
    if (typeof data.GTB === 'string') data.GTB = parseInt(data.GTB);
    if (typeof data.ZTT === 'string') data.ZTT = parseInt(data.ZTT);
    if (typeof data.ZTP === 'string') data.ZTP = parseInt(data.ZTP);
    if (typeof data.ZTB === 'string') data.ZTB = parseInt(data.ZTB);
    if (typeof data.ETT === 'string') data.ETT = parseInt(data.ETT);
    if (typeof data.ETP === 'string') data.ETP = parseInt(data.ETP);
    if (typeof data.CNDT === 'string') data.CNDT = parseInt(data.CNDT);
    if (typeof data.CNDP === 'string') data.CNDP = parseInt(data.CNDP);
    if (typeof data.CNPT === 'string') data.CNPT = parseInt(data.CNPT);
    if (typeof data.CNPP === 'string') data.CNPP = parseInt(data.CNPP);
    if (typeof data.CNGT === 'string') data.CNGT = parseInt(data.CNGT);
    if (typeof data.CNGP === 'string') data.CNGP = parseInt(data.CNGP);
    if (typeof data.CNAT === 'string') data.CNAT = parseInt(data.CNAT);
    if (typeof data.CNAP === 'string') data.CNAP = parseInt(data.CNAP);
    if (typeof data.CNET === 'string') data.CNET = parseInt(data.CNET);
    if (typeof data.CNEP === 'string') data.CNEP = parseInt(data.CNEP);
    if (typeof data.SNET === 'string') data.SNET = parseInt(data.SNET);
    if (typeof data.SNEP === 'string') data.SNEP = parseInt(data.SNEP);
    if (typeof data.ORST === 'string') data.ORST = parseInt(data.ORST);
    if (typeof data.ORSP === 'string') data.ORSP = parseInt(data.ORSP);
    if (typeof data.CCDT === 'string') data.CCDT = parseInt(data.CCDT);
    if (typeof data.CDDP === 'string') data.CDDP = parseInt(data.CDDP);
    if (typeof data.CDBT === 'string') data.CDBT = parseInt(data.CDBT);
    if (typeof data.CDBP === 'string') data.CDBP = parseInt(data.CDBP);
    if (typeof data.CDFT === 'string') data.CDFT = parseInt(data.CDFT);
    if (typeof data.CDFP === 'string') data.CDFP = parseInt(data.CDFP);
    if (typeof data.COCT === 'string') data.COCT = parseInt(data.COCT);
    if (typeof data.COCP === 'string') data.COCP = parseInt(data.COCP);
    if (typeof data.COPT === 'string') data.COPT = parseInt(data.COPT);
    if (typeof data.COPP === 'string') data.COPP = parseInt(data.COPP);
    if (typeof data.COET === 'string') data.COET = parseInt(data.COET);
    if (typeof data.COEP === 'string') data.COEP = parseInt(data.COEP);
    if (typeof data.CTPT === 'string') data.CTPT = parseInt(data.CTPT);
    if (typeof data.CTPP === 'string') data.CTPP = parseInt(data.CTPP);
    if (typeof data.CTST === 'string') data.CTST = parseInt(data.CTST);
    if (typeof data.CTSP === 'string') data.CTSP = parseInt(data.CTSP);
    if (typeof data.CTET === 'string') data.CTET = parseInt(data.CTET);
    if (typeof data.CTEP === 'string') data.CTEP = parseInt(data.CTEP);
    if (typeof data.CSTT === 'string') data.CSTT = parseInt(data.CSTT);
    if (typeof data.CSTP === 'string') data.CSTP = parseInt(data.CSTP);
    if (typeof data.PPTT === 'string') data.PPTT = parseInt(data.PPTT);
    if (typeof data.PPTP === 'string') data.PPTP = parseInt(data.PPTP);
    if (typeof data.KKT === 'string') data.KKT = parseInt(data.KKT);
    if (typeof data.KJT === 'string') data.KJT = parseInt(data.KJT);
    if (typeof data.PBT === 'string') data.PBT = parseInt(data.PBT);

    if (typeof data.RNKT === 'string') data.RNKT = parseInt(data.RNKT);
    if (typeof data.RNKP === 'string') data.RNKP = parseInt(data.RNKP);
    if (typeof data.zifamt === 'string') data.zifamt = parseInt(data.zifamt);
    if (typeof data.SNPT === 'string') data.SNPT = parseInt(data.SNPT);
    if (typeof data.SNPP === 'string') data.SNPP = parseInt(data.SNPP);

  });
  return datas;
}
//合計行作成
const calcTotal = (datas: RowData[]): RowData[] => {
  const total: RowData = {
    TP: "total",
    CAT: "def",  //暫定的にdefにする
    drillDowned: true,
    no: "合計",
  };

  //set No.
  datas.forEach((data) => {
    if (data.TP == "lv1") {
      calcTotalSub(total, data);
    }
  });

  if (datas.length > 0 && datas[0].TP == "total") {
    datas[0] = total;
  }
  else {
    datas = [total, ...datas];
  }

  return datas;
}
//合計計算
export const calcTotalSub = (total: RowData, data: RowData): RowData => {
  //以下に貼り付け
  total.JJT = calcUtil.plus(total.JJT, data.JJT);
  total.JJP = calcUtil.plus(total.JJP, data.JJP);
  total.JJTW = calcUtil.plus(total.JJTW, data.JJTW);
  total.JJPW = calcUtil.plus(total.JJPW, data.JJPW);
  total.JJTM = calcUtil.plus(total.JJTM, data.JJTM);
  total.JJPM = calcUtil.plus(total.JJPM, data.JJPM);
  total.JKT = calcUtil.plus(total.JKT, data.JKT);
  total.JKP = calcUtil.plus(total.JKP, data.JKP);
  //欠品責任別
  total.KI1KA = calcUtil.plus(total.KI1KA, data.KI1KA);
  total.KI2KA = calcUtil.plus(total.KI2KA, data.KI2KA);
  total.KI3KA = calcUtil.plus(total.KI3KA, data.KI3KA);

  total.SLT = calcUtil.plus(total.SLT, data.SLT);
  total.SLP = calcUtil.plus(total.SLP, data.SLP);
  total.SDT = calcUtil.plus(total.SDT, data.SDT);
  total.SDP = calcUtil.plus(total.SDP, data.SDP);
  total.SRT = calcUtil.plus(total.SRT, data.SRT);
  total.SRTR = calcUtil.plus(total.SRTR, data.SRTR);
  total.SRP = calcUtil.plus(total.SRP, data.SRP);
  total.STT = calcUtil.plus(total.STT, data.STT);
  total.STP = calcUtil.plus(total.STP, data.STP);
  total.STB = calcUtil.plus(total.STB, data.STB);
  total.PBLT = calcUtil.plus(total.PBLT, data.PBLT);
  total.PBLP = calcUtil.plus(total.PBLP, data.PBLP);
  total.PBDT = calcUtil.plus(total.PBDT, data.PBDT);
  total.PBDP = calcUtil.plus(total.PBDP, data.PBDP);
  total.PBRT = calcUtil.plus(total.PBRT, data.PBRT);
  total.PBRP = calcUtil.plus(total.PBRP, data.PBRP);
  total.PBTT = calcUtil.plus(total.PBTT, data.PBTT);
  total.PBTP = calcUtil.plus(total.PBTP, data.PBTP);
  total.YHLT = calcUtil.plus(total.YHLT, data.YHLT);
  total.YHLP = calcUtil.plus(total.YHLP, data.YHLP);
  total.YHDT = calcUtil.plus(total.YHDT, data.YHDT);
  total.YHDP = calcUtil.plus(total.YHDP, data.YHDP);
  total.YHRT = calcUtil.plus(total.YHRT, data.YHRT);
  total.YHRP = calcUtil.plus(total.YHRP, data.YHRP);
  total.YHTT = calcUtil.plus(total.YHTT, data.YHTT);
  total.YHTP = calcUtil.plus(total.YHTP, data.YHTP);
  total.PTT = calcUtil.plus(total.PTT, data.PTT);
  total.PTP = calcUtil.plus(total.PTP, data.PTP);
  total.PTB = calcUtil.plus(total.PTB, data.PTB);
  total.DDT = calcUtil.plus(total.DDT, data.DDT);
  total.DDP = calcUtil.plus(total.DDP, data.DDP);
  total.DBT = calcUtil.plus(total.DBT, data.DBT);
  total.DBP = calcUtil.plus(total.DBP, data.DBP);
  total.DQT = calcUtil.plus(total.DQT, data.DQT);
  total.DQP = calcUtil.plus(total.DQP, data.DQP);
  total.DFT = calcUtil.plus(total.DFT, data.DFT);
  total.DFP = calcUtil.plus(total.DFP, data.DFP);
  total.DTT = calcUtil.plus(total.DTT, data.DTT);
  total.DTP = calcUtil.plus(total.DTP, data.DTP);
  total.DTB = calcUtil.plus(total.DTB, data.DTB);
  total.NTT = calcUtil.plus(total.NTT, data.NTT);
  total.NTP = calcUtil.plus(total.NTP, data.NTP);
  total.NTB = calcUtil.plus(total.NTB, data.NTB);
  total.CIT = calcUtil.plus(total.CIT, data.CIT);
  total.CIP = calcUtil.plus(total.CIP, data.CIP);
  total.CST = calcUtil.plus(total.CST, data.CST);
  total.CSP = calcUtil.plus(total.CSP, data.CSP);
  total.CTB = calcUtil.plus(total.CTB, data.CTB);
  total.OCT = calcUtil.plus(total.OCT, data.OCT);
  total.OCP = calcUtil.plus(total.OCP, data.OCP);
  total.OCB = calcUtil.plus(total.OCB, data.OCB);
  total.OPT = calcUtil.plus(total.OPT, data.OPT);
  total.OPP = calcUtil.plus(total.OPP, data.OPP);
  total.OPB = calcUtil.plus(total.OPB, data.OPB);
  total.OET = calcUtil.plus(total.OET, data.OET);
  total.OEP = calcUtil.plus(total.OEP, data.OEP);
  total.OEB = calcUtil.plus(total.OEB, data.OEB);
  total.TPT = calcUtil.plus(total.TPT, data.TPT);
  total.TPP = calcUtil.plus(total.TPP, data.TPP);
  total.TST = calcUtil.plus(total.TST, data.TST);
  total.TSP = calcUtil.plus(total.TSP, data.TSP);
  total.TET = calcUtil.plus(total.TET, data.TET);
  total.TEP = calcUtil.plus(total.TEP, data.TEP);
  total.BTT = calcUtil.plus(total.BTT, data.BTT);
  total.BTP = calcUtil.plus(total.BTP, data.BTP);
  total.ATT = calcUtil.plus(total.ATT, data.ATT);
  total.ATP = calcUtil.plus(total.ATP, data.ATP);
  total.GTT = calcUtil.plus(total.GTT, data.GTT);
  total.GTP = calcUtil.plus(total.GTP, data.GTP);
  total.GTB = calcUtil.plus(total.GTB, data.GTB);
  total.ZTT = calcUtil.plus(total.ZTT, data.ZTT);
  total.ZTP = calcUtil.plus(total.ZTP, data.ZTP);
  total.ZTB = calcUtil.plus(total.ZTB, data.ZTB);
  total.ETT = calcUtil.plus(total.ETT, data.ETT);
  total.ETP = calcUtil.plus(total.ETP, data.ETP);
  //
  total.CNDT = calcUtil.plus(total.CNDT, data.CNDT);
  total.CNDP = calcUtil.plus(total.CNDP, data.CNDP);
  total.CNPT = calcUtil.plus(total.CNPT, data.CNPT);
  total.CNPP = calcUtil.plus(total.CNPP, data.CNPP);
  total.CNGT = calcUtil.plus(total.CNGT, data.CNGT);
  total.CNGP = calcUtil.plus(total.CNGP, data.CNGP);
  total.CNAT = calcUtil.plus(total.CNAT, data.CNAT);
  total.CNAP = calcUtil.plus(total.CNAP, data.CNAP);
  total.CNET = calcUtil.plus(total.CNET, data.CNET);
  total.CNEP = calcUtil.plus(total.CNEP, data.CNEP);
  total.SNET = calcUtil.plus(total.SNET, data.SNET);
  total.SNEP = calcUtil.plus(total.SNEP, data.SNEP);
  total.ORST = calcUtil.plus(total.ORST, data.ORST);
  total.ORSP = calcUtil.plus(total.ORSP, data.ORSP);

  total.CCDT = calcUtil.plus(total.CCDT, data.CCDT);
  total.CDDP = calcUtil.plus(total.CDDP, data.CDDP);
  total.CDBT = calcUtil.plus(total.CDBT, data.CDBT);
  total.CDBP = calcUtil.plus(total.CDBP, data.CDBP);
  total.CDFT = calcUtil.plus(total.CDFT, data.CDFT);
  total.CDFP = calcUtil.plus(total.CDFP, data.CDFP);
  total.COCT = calcUtil.plus(total.COCT, data.COCT);
  total.COCP = calcUtil.plus(total.COCP, data.COCP);
  total.COPT = calcUtil.plus(total.COPT, data.COPT);
  total.COPP = calcUtil.plus(total.COPP, data.COPP);
  total.COET = calcUtil.plus(total.COET, data.COET);
  total.COEP = calcUtil.plus(total.COEP, data.COEP);
  total.CTPT = calcUtil.plus(total.CTPT, data.CTPT);
  total.CTPP = calcUtil.plus(total.CTPP, data.CTPP);
  total.CTST = calcUtil.plus(total.CTST, data.CTST);
  total.CTSP = calcUtil.plus(total.CTSP, data.CTSP);
  total.CTET = calcUtil.plus(total.CTET, data.CTET);
  total.CTEP = calcUtil.plus(total.CTEP, data.CTEP);
  total.CSTT = calcUtil.plus(total.CSTT, data.CSTT);
  total.CSTP = calcUtil.plus(total.CSTP, data.CSTP);
  total.PPTT = calcUtil.plus(total.PPTT, data.PPTT);
  total.PPTP = calcUtil.plus(total.PPTP, data.PPTP);
  total.KKT = calcUtil.plus(total.KKT, data.KKT);
  total.KJT = calcUtil.plus(total.KJT, data.KJT);
  total.PBT = calcUtil.plus(total.PBT, data.PBT);

  //sej含む場合はmix
  if (data.CAT == "mix" || data.CAT == "sej") {
    total.CAT = 'mix';
  }

  //xray2
  total.CNTF = total.CNTF ? total.CNTF : '' + data.CNTF;
  total.SNPT = calcUtil.plus(total.SNPT, data.SNPT);
  total.SNPP = calcUtil.plus(total.SNPP, data.SNPP);

  return total;
};
//計算
const calcDatas = (type: xrayType, datas: RowData[], datasAll: RowData[], axisTypes: ("group1" | "group2" | "shiten" | "center" | "maker" | "tokuisaki" | "category" | string)[]): RowData[] => {
  const total = datasAll.find(data => data.TP == "total");

  const itemAttrFlgs: boolean[] = [];
  axisTypes.forEach((axisType, index) => {
    itemAttrFlgs.push(itemAttrFlgs[index - 1] || ["maker", "category",].indexOf(axisType) >= 0);
  });
  const getDays = (now: moment.Moment = moment()): number => {
    let days = parseInt(now.format('DD'));
    if (days === 1) {
      const prev = now.clone().add(-1, 'month').endOf('month');
      days = parseInt(prev.format('DD'));
    }
    return days;
  }
  const days = getDays();
  datas.forEach((data) => {

    const lv = 1;
    //構成比の計算
    const parentIndex = -1;

    const parent = parentIndex == -1 ? total : datasAll[parentIndex];
    const itemAttrFlg: boolean = itemAttrFlgs[lv - 1];

    calcData(type, data, parentIndex, parent, itemAttrFlg, days);
  });

  return datas;
}
export const calcData = (type: xrayType, data: RowData, parentIndex: number, parent: RowData, itemAttrFlg: boolean, days: number): RowData => {
  //商品属性の場合、得意先課金を売上比按分する
  if (itemAttrFlg && parentIndex >= 0 && parent) {

    const STTK = calcUtil.divide(data.STT, parent.STT);
    const STPK = calcUtil.divide(data.STP, parent.STP);

    data.DDT = calcUtil.multiply(parent.DDT, STTK);// DDT: "値引",	
    data.DDP = calcUtil.multiply(parent.DDP, STPK);// DDP: "値引",	
    data.DBT = calcUtil.multiply(parent.DBT, STTK);// DBT: "リベート",	
    data.DBP = calcUtil.multiply(parent.DBP, STPK);// DBP: "リベート",	
    data.DQT = calcUtil.multiply(parent.DQT, STTK);// DQT: "リベート建値",	
    data.DQP = calcUtil.multiply(parent.DQP, STPK);// DQP: "リベート建値",	
    data.DFT = calcUtil.multiply(parent.DFT, STTK);// DFT: "振込リベート",	
    data.DFP = calcUtil.multiply(parent.DFP, STPK);// DFP: "振込リベート",	
    data.DTT = calcUtil.multiply(parent.DTT, STTK);// DTT: "値引計",	
    data.DTP = calcUtil.multiply(parent.DTP, STPK);// DTP: "値引計",	

    data.OCT = calcUtil.multiply(parent.OCT, STTK);// OCT: "センターフィ（相殺）",	
    data.OCP = calcUtil.multiply(parent.OCP, STPK);// OCP: "センターフィ（相殺）",	
    data.TST = calcUtil.multiply(parent.TST, STTK);// TST: "業務委託費（振込）",	
    data.TSP = calcUtil.multiply(parent.TSP, STPK);// TSP: "業務委託費（振込）",	
    data.OPT = calcUtil.multiply(parent.OPT, STTK);// OPT: "販促費（相殺）",	
    data.OPP = calcUtil.multiply(parent.OPP, STPK);// OPP: "販促費（相殺）",	
    data.TPT = calcUtil.multiply(parent.TPT, STTK);// TPT: "販促費（振込）",	
    data.TPP = calcUtil.multiply(parent.TPP, STPK);// TPP: "販促費（振込）",	
    data.OET = calcUtil.multiply(parent.OET, STTK);// OET: "ＥＯＳ費・他（相殺）",	
    data.OEP = calcUtil.multiply(parent.OEP, STPK);// OEP: "ＥＯＳ費・他（相殺）",	
    data.TET = calcUtil.multiply(parent.TET, STTK);// TET: "ＥＯＳ費・他（振込）",	
    data.TEP = calcUtil.multiply(parent.TEP, STPK);// TEP: "ＥＯＳ費・他（振込）",	
  }

  //ここに貼り付け
  data.JJR = calcUtil.divide(data.JJT, data.JJP);
  data.JJRW = calcUtil.divide(data.JJTW, data.JJPW);
  data.JJRM = calcUtil.divide(data.JJTM, data.JJPM);
  data.JJD = calcUtil.minus(data.JJT, data.JJP);

  data.JKT = calcUtil.greater(0, calcUtil.minus(data.JJT, data.SLT)); //=【受注額】本年実績-【配送売上】本年実績 //0未満は0表記
  data.JKP = calcUtil.greater(0, calcUtil.minus(data.JJP, data.SLP)); //=【受注額】前年実績-【配送売上】前年実績 //0未満は0表記

  data.JKR = calcUtil.divide(data.JKT, data.JKP);
  data.JKD = calcUtil.minus(data.JKT, data.JKP);
  data.JRT = calcUtil.divide(data.JKT, data.JJT);
  data.JRP = calcUtil.divide(data.JKP, data.JJP);
  data.JRD = calcUtil.multiply(calcUtil.minus(data.JRT, data.JRP), 100);

  //1.自社責に寄せる
  data.KI1KA = calcUtil.greater(0, calcUtil.minus(data.JKT, calcUtil.sum([data.KI2KA, data.KI3KA]))); //0未満は0表記
  //欠品責任別
  data.KI1KR = calcUtil.divide(data.KI1KA, data.JJT);
  data.KI2KR = calcUtil.divide(data.KI2KA, data.JJT);
  data.KI3KR = calcUtil.divide(data.KI3KA, data.JJT);

  data.SLR = calcUtil.divide(data.SLT, data.SLP);
  data.SLD = calcUtil.minus(data.SLT, data.SLP);
  data.SDR = calcUtil.divide(data.SDT, data.SDP);
  data.SDD = calcUtil.minus(data.SDT, data.SDP);
  data.SRR = calcUtil.divide(data.SRT, data.SRP);
  data.SRD = calcUtil.minus(data.SRT, data.SRP);

  data.SRTR = calcUtil.divide(data.SRT, data.STT);

  data.STR = calcUtil.divide(data.STT, data.STP);
  data.STD = calcUtil.minus(data.STT, data.STP);
  if (type === 'quick' || type === 'group2' || type === 'group2Quick' || type === 'group2Center') {
    const getMonth = (now: moment.Moment = moment()): moment.Moment => {
      let days = parseInt(now.format('DD'));
      if (days === 1) {
        return now.clone().add(-1, 'month').endOf('month');
      }
      return now;
    }
    const day = parseInt(getMonth().format('DD'));
    const days = parseInt(getMonth().endOf('month').format('DD'));
    const STB = data.STB * (day / days);
    data.STBR = calcUtil.divide(data.STT, STB);
    data.STBD = calcUtil.minus(data.STT, STB);
  } else {
    data.STBR = calcUtil.divide(data.STT, data.STB);
    data.STBD = calcUtil.minus(data.STT, data.STB);
  }
  data.PBTT = calcUtil.plus(calcUtil.plus(data.PBLT, data.PBDT), data.PBRT);
  data.PBTP = calcUtil.plus(calcUtil.plus(data.PBLP, data.PBDP), data.PBRP);
  data.PBLR = calcUtil.divide(data.PBLT, data.PBLP);
  data.PBLD = calcUtil.minus(data.PBLT, data.PBLP);
  data.PBDR = calcUtil.divide(data.PBDT, data.PBDP);
  data.PBDD = calcUtil.minus(data.PBDT, data.PBDP);
  data.PBRR = calcUtil.divide(data.PBRT, data.PBRP);
  data.PBRD = calcUtil.minus(data.PBRT, data.PBRP);
  data.PBTR = calcUtil.divide(data.PBTT, data.PBTP);
  data.PBTD = calcUtil.minus(data.PBTT, data.PBTP);
  data.PBHT = calcUtil.divide(data.PBTT, data.STT);
  data.PBHP = calcUtil.divide(data.PBTP, data.STP);
  data.PBHD = calcUtil.multiply(calcUtil.minus(data.PBHT, data.PBHP), 100);
  data.YHTT = calcUtil.plus(calcUtil.plus(data.YHLT, data.YHDT), data.YHRT);
  data.YHTP = calcUtil.plus(calcUtil.plus(data.YHLP, data.YHDP), data.YHRP);
  data.YHLR = calcUtil.divide(data.YHLT, data.YHLP);
  data.YHLD = calcUtil.minus(data.YHLT, data.YHLP);
  data.YHDR = calcUtil.divide(data.YHDT, data.YHDP);
  data.YHDD = calcUtil.minus(data.YHDT, data.YHDP);
  data.YHRR = calcUtil.divide(data.YHRT, data.YHRP);
  data.YHRD = calcUtil.minus(data.YHRT, data.YHRP);
  data.YHTR = calcUtil.divide(data.YHTT, data.YHTP);
  data.YHTD = calcUtil.minus(data.YHTT, data.YHTP);
  data.YHHT = calcUtil.divide(data.YHTT, data.STT);
  data.YHHP = calcUtil.divide(data.YHTP, data.STP);
  data.YHHD = calcUtil.multiply(calcUtil.minus(data.YHHT, data.YHHP), 100);
  data.PTD = calcUtil.minus(data.PTT, data.PTP);
  data.PTBR = calcUtil.divide(data.PTT, data.PTB);
  data.PTBD = calcUtil.minus(data.PTT, data.PTB);
  data.PRT = calcUtil.divide(data.PTT, data.STT);
  data.PRP = calcUtil.divide(data.PTP, data.STP);
  data.PRD = calcUtil.multiply(calcUtil.minus(data.PRT, data.PRP), 100);
  data.DDR = calcUtil.divide(data.DDT, data.DDP);
  data.DDD = calcUtil.minus(data.DDT, data.DDP);
  data.DBR = calcUtil.divide(data.DBT, data.DBP);
  data.DBD = calcUtil.minus(data.DBT, data.DBP);
  data.DQR = calcUtil.divide(data.DQT, data.DQP);
  data.DQD = calcUtil.minus(data.DQT, data.DQP);
  data.DFR = calcUtil.divide(data.DFT, data.DFP);
  data.DFD = calcUtil.minus(data.DFT, data.DFP);
  data.DTR = calcUtil.divide(data.DTT, data.DTP);
  data.DTD = calcUtil.minus(data.DTT, data.DTP);
  data.DTBR = calcUtil.divide(data.DTT, data.DTB);
  data.DTBD = calcUtil.minus(data.DTT, data.DTB);
  data.DRT = calcUtil.divide(data.DTT, data.STT);
  data.DRP = calcUtil.divide(data.DTP, data.STP);
  data.DRD = calcUtil.multiply(calcUtil.minus(data.DRT, data.DRP), 100);
  data.NTR = calcUtil.divide(data.NTT, data.NTP);
  data.NTD = calcUtil.minus(data.NTT, data.NTP);
  data.NTBR = calcUtil.divide(data.NTT, data.NTB);
  data.NTBD = calcUtil.minus(data.NTT, data.NTB);
  data.CIR = calcUtil.divide(data.CIT, data.CIP);
  data.CID = calcUtil.minus(data.CIT, data.CIP);
  data.CSR = calcUtil.divide(data.CST, data.CSP);
  data.CSD = calcUtil.minus(data.CST, data.CSP);
  data.CTT = calcUtil.sum([data.CIT, data.CST]);
  data.CTP = calcUtil.sum([data.CIP, data.CSP]);
  data.CTR = calcUtil.divide(data.CTT, data.CTP);
  data.CTD = calcUtil.minus(data.CTT, data.CTP);
  data.CTBR = calcUtil.divide(data.CTT, data.CTB);
  data.CTBD = calcUtil.minus(data.CTT, data.CTB);
  data.OCR = calcUtil.divide(data.OCT, data.OCP);
  data.OCD = calcUtil.minus(data.OCT, data.OCP);
  data.OCBR = calcUtil.divide(data.OCT, data.OCB);
  data.OCBD = calcUtil.minus(data.OCT, data.OCB);
  data.TSR = calcUtil.divide(data.TST, data.TSP);
  data.TSD = calcUtil.minus(data.TST, data.TSP);
  data.CSTT = calcUtil.sum([data.OCT, data.TST]);
  data.CSTP = calcUtil.sum([data.OCP, data.TSP]);
  data.CSTR = calcUtil.divide(data.CSTT, data.CSTP);
  data.CSTD = calcUtil.minus(data.CSTT, data.CSTP);
  data.OPR = calcUtil.divide(data.OPT, data.OPP);
  data.OPD = calcUtil.minus(data.OPT, data.OPP);
  data.OPBR = calcUtil.divide(data.OPT, data.OPB);
  data.OPBD = calcUtil.minus(data.OPT, data.OPB);
  data.TPR = calcUtil.divide(data.TPT, data.TPP);
  data.TPD = calcUtil.minus(data.TPT, data.TPP);
  data.PPTT = calcUtil.sum([data.OPT, data.TPT]);
  data.PPTP = calcUtil.sum([data.OPP, data.TPP]);
  data.PPTR = calcUtil.divide(data.PPTT, data.PPTP);
  data.PPTD = calcUtil.minus(data.PPTT, data.PPTP);
  data.OER = calcUtil.divide(data.OET, data.OEP);
  data.OED = calcUtil.minus(data.OET, data.OEP);
  data.OEBR = calcUtil.divide(data.OET, data.OEB);
  data.OEBD = calcUtil.minus(data.OET, data.OEB);
  data.TER = calcUtil.divide(data.TET, data.TEP);
  data.TED = calcUtil.minus(data.TET, data.TEP);
  data.ETT = calcUtil.sum([data.OET, data.TET]);
  data.ETP = calcUtil.sum([data.OEP, data.TEP]);
  data.ETR = calcUtil.divide(data.ETT, data.ETP);
  data.ETD = calcUtil.minus(data.ETT, data.ETP);
  data.KTT = calcUtil.sum([data.CSTT, data.PPTT, data.ETT]);
  data.KTP = calcUtil.sum([data.CSTP, data.PPTP, data.ETP]);
  data.KTR = calcUtil.divide(data.KTT, data.KTP);
  data.KTD = calcUtil.minus(data.KTT, data.KTP);
  data.KRT = calcUtil.divide(data.KTT, data.STT);
  data.KRP = calcUtil.divide(data.KTP, data.STP);
  data.KRD = calcUtil.multiply(calcUtil.minus(data.KRT, data.KRP), 100);

  //商品属性の場合、//修正前企業粗利 = 売買差益-値引計-得意先課金計
  if (itemAttrFlg && parentIndex >= 0 && parent) {
    data.BTT = calcUtil.minus(calcUtil.minus(data.PTT, data.DTT), data.KTT)
    data.BTP = calcUtil.minus(calcUtil.minus(data.PTP, data.DTP), data.KTP)
  }

  data.BTR = calcUtil.divide(data.BTT, data.BTP);
  data.BTD = calcUtil.minus(data.BTT, data.BTP);
  data.BRT = calcUtil.divide(data.BTT, data.STT);
  data.BRP = calcUtil.divide(data.BTP, data.STP);
  data.BRD = calcUtil.multiply(calcUtil.minus(data.BRT, data.BRP), 100);
  data.ATR = calcUtil.divide(data.ATT, data.ATP);
  data.ATD = calcUtil.minus(data.ATT, data.ATP);
  data.ART = calcUtil.divide(data.ATT, data.STT);
  data.ARP = calcUtil.divide(data.ATP, data.STP);
  data.ARD = calcUtil.multiply(calcUtil.minus(data.ART, data.ARP), 100);
  data.GTT = calcUtil.sum([data.BTT, data.DTT, data.CTT, data.CSTT, data.PPTT, data.ETT]);
  data.GTP = calcUtil.sum([data.BTP, data.DTP, data.CTP, data.CSTP, data.PPTP, data.ETP]);
  data.GTR = calcUtil.divide(data.GTT, data.GTP);
  data.GTD = calcUtil.minus(data.GTT, data.GTP);
  data.GTBR = calcUtil.divide(data.GTT, data.GTB);
  data.GTBD = calcUtil.minus(data.GTT, data.GTB);
  data.GRT = calcUtil.divide(data.GTT, data.STT);
  data.GRP = calcUtil.divide(data.GTP, data.STP);
  data.GRD = calcUtil.multiply(calcUtil.minus(data.GRT, data.GRP), 100);
  data.ZTT = calcUtil.sum([data.BTT, data.CTT, data.CSTT, data.PPTT, data.ETT]);
  data.ZTP = calcUtil.sum([data.BTP, data.CTP, data.CSTP, data.PPTP, data.ETP]);
  data.ZTR = calcUtil.divide(data.ZTT, data.ZTP);
  data.ZTD = calcUtil.minus(data.ZTT, data.ZTP);
  data.ZTBR = calcUtil.divide(data.ZTT, data.ZTB);
  data.ZTBD = calcUtil.minus(data.ZTT, data.ZTB);
  data.ZRT = calcUtil.divide(data.ZTT, data.NTT);
  data.ZRP = calcUtil.divide(data.ZTP, data.NTP);
  data.ZRD = calcUtil.multiply(calcUtil.minus(data.ZRT, data.ZRP), 100);
  data.ZCT = calcUtil.minus(data.ZTT, data.KTT);
  data.ZCP = calcUtil.minus(data.ZTP, data.KTP);
  data.ZCR = calcUtil.divide(data.ZCT, data.ZCP);
  data.ZCD = calcUtil.minus(data.ZCT, data.ZCP);
  data.ZCRT = calcUtil.divide(data.ZCT, data.NTT);
  data.ZCRP = calcUtil.divide(data.ZCP, data.NTP);
  data.ZCRD = calcUtil.multiply(calcUtil.minus(data.ZCRT, data.ZCRP), 100);
  data.CNDR = calcUtil.divide(data.CNDT, data.CNDP);
  data.CNDD = calcUtil.minus(data.CNDT, data.CNDP);
  data.CNPR = calcUtil.divide(data.CNPT, data.CNPP);
  data.CNPD = calcUtil.minus(data.CNPT, data.CNPP);
  data.CNGR = calcUtil.divide(data.CNGT, data.CNGP);
  data.CNGD = calcUtil.minus(data.CNGT, data.CNGP);
  data.CNAR = calcUtil.divide(data.CNAT, data.CNAP);
  data.CNAD = calcUtil.minus(data.CNAT, data.CNAP);
  data.CNER = calcUtil.divide(data.CNET, data.CNEP);
  data.CNED = calcUtil.minus(data.CNET, data.CNEP);
  data.CNDRT = calcUtil.divide(data.CNDT, calcUtil.plus(data.SLT, data.SRT));
  data.CNDRP = calcUtil.divide(data.CNDP, data.SLP);
  data.CNDRD = calcUtil.multiply(calcUtil.minus(data.CNDRT, data.CNDRP), 100);
  data.CNPRT = calcUtil.divide(data.CNPT, calcUtil.plus(data.SLT, data.SRT));
  data.CNPRP = calcUtil.divide(data.CNPP, data.SLP);
  data.CNPRD = calcUtil.multiply(calcUtil.minus(data.CNPRT, data.CNPRP), 100);
  data.CNGRT = calcUtil.divide(data.CNGT, calcUtil.plus(data.SLT, data.SRT));
  data.CNGRP = calcUtil.divide(data.CNGP, data.SLP);
  data.CNGRD = calcUtil.multiply(calcUtil.minus(data.CNGRT, data.CNGRP), 100);
  data.CNART = calcUtil.divide(data.CNAT, calcUtil.plus(data.SLT, data.SRT));
  data.CNARP = calcUtil.divide(data.CNAP, data.SLP);
  data.CNARD = calcUtil.multiply(calcUtil.minus(data.CNART, data.CNARP), 100);
  data.CNRT = calcUtil.divide(data.CNET, calcUtil.plus(data.SLT, data.SRT));
  data.CNRP = calcUtil.divide(data.CNEP, data.SLP);
  data.CNRD = calcUtil.multiply(calcUtil.minus(data.CNRT, data.CNRP), 100);
  data.SNER = calcUtil.divide(data.SNET, data.SNEP);
  data.SNED = calcUtil.minus(data.SNET, data.SNEP);
  data.SNRT = calcUtil.divide(data.SNET, data.STT);
  data.SNRP = calcUtil.divide(data.SNEP, data.STP);
  data.SNRD = calcUtil.multiply(calcUtil.minus(data.SNRT, data.SNRP), 100);
  data.ORSR = calcUtil.divide(data.ORST, data.ORSP);
  data.ORSD = calcUtil.minus(data.ORST, data.ORSP);
  data.ORSRT = calcUtil.divide(data.ORST, data.STT);
  data.ORSRP = calcUtil.divide(data.ORSP, data.STP);
  data.ORSRD = calcUtil.multiply(calcUtil.minus(data.ORSRT, data.ORSRP), 100);
  data.ORPT = calcUtil.minus(data.ZCT, data.ORST);
  data.ORPP = calcUtil.minus(data.ZCP, data.ORSP);
  data.ORPR = calcUtil.divide(data.ORPT, data.ORPP);
  data.ORPD = calcUtil.minus(data.ORPT, data.ORPP);
  data.ORPRT = calcUtil.divide(data.ORPT, data.STT);
  data.ORPRP = calcUtil.divide(data.ORPP, data.STP);
  data.ORPRD = calcUtil.multiply(calcUtil.minus(data.ORPRT, data.ORPRP), 100);

  data.CDDR = calcUtil.divide(data.CCDT, data.CDDP);
  data.CDDD = calcUtil.minus(data.CCDT, data.CDDP);
  data.CDBR = calcUtil.divide(data.CDBT, data.CDBP);
  data.CDBD = calcUtil.minus(data.CDBT, data.CDBP);
  data.CDFR = calcUtil.divide(data.CDFT, data.CDFP);
  data.CDFD = calcUtil.minus(data.CDFT, data.CDFP);
  data.COCR = calcUtil.divide(data.COCT, data.COCP);
  data.COCD = calcUtil.minus(data.COCT, data.COCP);
  data.COPR = calcUtil.divide(data.COPT, data.COPP);
  data.COPD = calcUtil.minus(data.COPT, data.COPP);
  data.COER = calcUtil.divide(data.COET, data.COEP);
  data.COED = calcUtil.minus(data.COET, data.COEP);
  data.CTPR = calcUtil.divide(data.CTPT, data.CTPP);
  data.CTPD = calcUtil.minus(data.CTPT, data.CTPP);
  data.CTSR = calcUtil.divide(data.CTST, data.CTSP);
  data.CTSD = calcUtil.minus(data.CTST, data.CTSP);
  data.CTER = calcUtil.divide(data.CTET, data.CTEP);
  data.CTED = calcUtil.minus(data.CTET, data.CTEP);
  data.KKR = calcUtil.divide(data.KKT, data.KJT);
  data.PBR = calcUtil.divide(data.PBT, data.STT);

  if (parent) {
    data.JJTK = calcUtil.divide(data.JJT, parent.JJT);
    data.JJTMK = calcUtil.divide(data.JJTM, parent.JJTM);
    data.JJA = data.RNKT == data.RNKP ? '' : data.RNKT < data.RNKP ? '↗' : '↘';

    data.JJPK = calcUtil.divide(data.JJP, parent.JJP);
    data.JKTK = calcUtil.divide(data.JKT, parent.JKT);
    data.JKPK = calcUtil.divide(data.JKP, parent.JKP);

    data.SLTK = calcUtil.divide(data.SLT, parent.SLT);
    data.SLPK = calcUtil.divide(data.SLP, parent.SLP);
    data.SDTK = calcUtil.divide(data.SDT, parent.SDT);
    data.SDPK = calcUtil.divide(data.SDP, parent.SDP);
    data.SRTK = calcUtil.divide(data.SRT, parent.SRT);
    data.SRPK = calcUtil.divide(data.SRP, parent.SRP);
    data.STTK = calcUtil.divide(data.STT, parent.STT);
    data.STPK = calcUtil.divide(data.STP, parent.STP);

    data.PBLTK = calcUtil.divide(data.PBLT, parent.PBLT);
    data.PBLPK = calcUtil.divide(data.PBLP, parent.PBLP);
    data.PBDTK = calcUtil.divide(data.PBDT, parent.PBDT);
    data.PBDPK = calcUtil.divide(data.PBDP, parent.PBDP);
    data.PBRTK = calcUtil.divide(data.PBRT, parent.PBRT);
    data.PBRPK = calcUtil.divide(data.PBRP, parent.PBRP);
    data.PBTTK = calcUtil.divide(data.PBTT, parent.PBTT);
    data.PBTPK = calcUtil.divide(data.PBTP, parent.PBTP);
    data.YHLTK = calcUtil.divide(data.YHLT, parent.YHLT);
    data.YHLPK = calcUtil.divide(data.YHLP, parent.YHLP);
    data.YHDTK = calcUtil.divide(data.YHDT, parent.YHDT);
    data.YHDPK = calcUtil.divide(data.YHDP, parent.YHDP);
    data.YHRTK = calcUtil.divide(data.YHRT, parent.YHRT);
    data.YHRPK = calcUtil.divide(data.YHRP, parent.YHRP);
    data.YHTTK = calcUtil.divide(data.YHTT, parent.YHTT);
    data.YHTPK = calcUtil.divide(data.YHTP, parent.YHTP);
    data.PTTK = calcUtil.divide(data.PTT, parent.PTT);
    data.PTPK = calcUtil.divide(data.PTP, parent.PTP);

    data.DDTK = calcUtil.divide(data.DDT, parent.DDT);
    data.DDPK = calcUtil.divide(data.DDP, parent.DDP);
    data.DBTK = calcUtil.divide(data.DBT, parent.DBT);
    data.DBPK = calcUtil.divide(data.DBP, parent.DBP);
    data.DQTK = calcUtil.divide(data.DQT, parent.DQT);
    data.DQPK = calcUtil.divide(data.DQP, parent.DQP);
    data.DFTK = calcUtil.divide(data.DFT, parent.DFT);
    data.DFPK = calcUtil.divide(data.DFP, parent.DFP);
    data.DTTK = calcUtil.divide(data.DTT, parent.DTT);
    data.DTPK = calcUtil.divide(data.DTP, parent.DTP);

    data.NTTK = calcUtil.divide(data.NTT, parent.NTT);
    data.NTPK = calcUtil.divide(data.NTP, parent.NTP);

    data.CITK = calcUtil.divide(data.CIT, parent.CIT);
    data.CIPK = calcUtil.divide(data.CIP, parent.CIP);
    data.CSTK = calcUtil.divide(data.CST, parent.CST);
    data.CSPK = calcUtil.divide(data.CSP, parent.CSP);
    data.CTTK = calcUtil.divide(data.CTT, parent.CTT);
    data.CTPK = calcUtil.divide(data.CTP, parent.CTP);

    data.OCTK = calcUtil.divide(data.OCT, parent.OCT);
    data.OCPK = calcUtil.divide(data.OCP, parent.OCP);

    data.TSTK = calcUtil.divide(data.TST, parent.TST);
    data.TSPK = calcUtil.divide(data.TSP, parent.TSP);

    data.CSTTK = calcUtil.divide(data.CSTT, parent.CSTT);
    data.CSTPK = calcUtil.divide(data.CSTP, parent.CSTP);

    data.OPTK = calcUtil.divide(data.OPT, parent.OPT);
    data.OPPK = calcUtil.divide(data.OPP, parent.OPP);

    data.TPTK = calcUtil.divide(data.TPT, parent.TPT);
    data.TPPK = calcUtil.divide(data.TPP, parent.TPP);

    data.PPTTK = calcUtil.divide(data.PPTT, parent.PPTT);
    data.PPTPK = calcUtil.divide(data.PPTP, parent.PPTP);

    data.OETK = calcUtil.divide(data.OET, parent.OET);
    data.OEPK = calcUtil.divide(data.OEP, parent.OEP);

    data.TETK = calcUtil.divide(data.TET, parent.TET);
    data.TEPK = calcUtil.divide(data.TEP, parent.TEP);

    data.ETTK = calcUtil.divide(data.ETT, parent.ETT);
    data.ETPK = calcUtil.divide(data.ETP, parent.ETP);

    data.KTTK = calcUtil.divide(data.KTT, parent.KTT);
    data.KTPK = calcUtil.divide(data.KTP, parent.KTP);

    data.BTTK = calcUtil.divide(data.BTT, parent.BTT);
    data.BTPK = calcUtil.divide(data.BTP, parent.BTP);

    data.ATTK = calcUtil.divide(data.ATT, parent.ATT);
    data.ATPK = calcUtil.divide(data.ATP, parent.ATP);

    data.GTTK = calcUtil.divide(data.GTT, parent.GTT);
    data.GTPK = calcUtil.divide(data.GTP, parent.GTP);

    data.ZTTK = calcUtil.divide(data.ZTT, parent.ZTT);
    data.ZTPK = calcUtil.divide(data.ZTP, parent.ZTP);
    data.ZCTK = calcUtil.divide(data.ZCT, parent.ZCT);
    data.ZCPK = calcUtil.divide(data.ZCP, parent.ZCP);

    data.CCDTK = calcUtil.divide(data.CCDT, parent.CCDT);
    data.CDDPK = calcUtil.divide(data.CDDP, parent.CDDP);
    data.CDBTK = calcUtil.divide(data.CDBT, parent.CDBT);
    data.CDBPK = calcUtil.divide(data.CDBP, parent.CDBP);
    data.CDFTK = calcUtil.divide(data.CDFT, parent.CDFT);
    data.CDFPK = calcUtil.divide(data.CDFP, parent.CDFP);
    data.COCTK = calcUtil.divide(data.COCT, parent.COCT);
    data.COCPK = calcUtil.divide(data.COCP, parent.COCP);
    data.COPTK = calcUtil.divide(data.COPT, parent.COPT);
    data.COPPK = calcUtil.divide(data.COPP, parent.COPP);
    data.COETK = calcUtil.divide(data.COET, parent.COET);
    data.COEPK = calcUtil.divide(data.COEP, parent.COEP);
    data.CTPTK = calcUtil.divide(data.CTPT, parent.CTPT);
    data.CTPPK = calcUtil.divide(data.CTPP, parent.CTPP);
    data.CTSTK = calcUtil.divide(data.CTST, parent.CTST);
    data.CTSPK = calcUtil.divide(data.CTSP, parent.CTSP);
    data.CTETK = calcUtil.divide(data.CTET, parent.CTET);
    data.CTEPK = calcUtil.divide(data.CTEP, parent.CTEP);

    data.STBK = calcUtil.divide(data.STB, parent.STB);
    data.PTBK = calcUtil.divide(data.PTB, parent.PTB);
    data.DTBK = calcUtil.divide(data.DTB, parent.DTB);
    data.NTBK = calcUtil.divide(data.NTB, parent.NTB);
    data.CTBK = calcUtil.divide(data.CTB, parent.CTB);
    data.OCBK = calcUtil.divide(data.OCB, parent.OCB);
    data.OPBK = calcUtil.divide(data.OPB, parent.OPB);
    data.OEBK = calcUtil.divide(data.OEB, parent.OEB);
    data.GTBK = calcUtil.divide(data.GTB, parent.GTB);
    data.ZTBK = calcUtil.divide(data.ZTB, parent.ZTB);

  }

  if (type === 'center') {
    data.zifrt = calcUtil.divide(data.zifamt, calcUtil.divide(data.SLT, days));
  }
  else if (type === 'group2Maker' || type === 'group2CenterMaker') {
    data.RNK = data.RNKT == data.RNKP ? '' : data.RNKT < data.RNKP ? '↗' : '↘';
  }
  data.SNPR = calcUtil.divide(data.SNPT, data.SNPP);
  data.SNPD = calcUtil.minus(data.SNPT, data.SNPP);

  return data;
}
//no振りなおし
const resetRowNo = (datas: RowData[], parent: RowData, maxLv: number): RowData[] => {
  //set No.
  const TPs = []; //'lv1','lv2','lv3','lv4'
  for (let l = 1; l <= maxLv; l++) {
    TPs.push('lv' + l);
  }
  let nos = [];
  TPs.forEach((TP, index) => {
    const indexOf = parent ? TPs.indexOf(parent.TP) : -1;
    const no = parent && indexOf >= index ? parseInt(parent.no.split("-")[index]) : 0;
    nos.push(no);
  });
  datas = datas.map((row) => {
    let no = row.TP == "total" ? "合計" : "";
    const indexOf = TPs.indexOf(row.TP);
    nos = nos.map((n, index) => {
      if (index < indexOf) {
        no = no + "" + n + "-";
      }
      else if (index == indexOf) {
        no = no + "" + (++n);
      }
      else {
        n = 0;
      }
      return n;
    });

    return {
      ...row,
      no: no,
    }
  });
  return datas;
}

//ドリルダウンの差し込み

//配列データに変換
const convertRows = (datas: RowData[] | common.Param[], colKeys: string[]): [][] => {
  const rows = [];
  //set No.
  datas.forEach((data) => {
    const r = [];
    colKeys.forEach((colKey) => {
      r.push(data[colKey]);
    });
    rows.push(r);
  });

  return rows;
}

//ソート


//Page Slice Export
export const xrayTmpSlice = createSliceContent("xrayTmp");
