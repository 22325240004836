import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CodeName } from "@/store/common";
import * as arrayUtil from "@/util/arrayUtil";
import { ZaikoKigenColRowModel, zaikoKigenColRowModelDefault } from "@/components/zaikokigen/ZaikoKigenTableModel";
import { centerItemAttributes, listSortOrders } from "@/store/zaikokigen/zaikoKigenCommon";
import { PageProps } from "@/assets/pageprops/zaikokigen";
import moment from 'moment';

//強制表示列
export const mustVisibleColumnsKey:string[] = [
  "no", 
  "rowHeader", 
];
//強制表示列(Excel出力時)
export const mustVisibleColumnsKeyForExcel:string[] = [
  "check",
  "kigenDateReal",
  "zaikoQtyBlReal",
];
//デフォルト表示列
export const defaultVisibleColumnsKey:string[] = [
  //以下に貼り付け
  // "bumonCD", //"部門"
  // "bumonNM", //"部門"
  // "centerCD", //"倉庫"
  "centerNM", //"倉庫"
  "itemCD", //"商品CD"
  // "makerCD", //"メーカーCD"
  "makerNM", //"メーカーNM"
  "itemNM", //"商品名"
  "janCD", //"JAN"
  // "category", //"商品カテゴリ"
  // "blIrisu", //"BL入数"
  // "csIrisu", //"CS入数"
  "irisu", //"入数"
  // "capacity", //"内容量"
  "bestBefore", //"賞味期間"
  "tolerance", //"許容期間"
  // "teika", //"定価"
  // "tatene", //"建値"
  "tanaban", //"棚番"

  "nohinbi",

  "zaikoKbn",
  "zaikoTotalBl",
  "zaikoTotalPs",
  "nyukaDate",
  "keikaDays",
  "zaikoQtyBl",
  "zaikoQtyPs",
  "zaikoQtyPsTotal",
  "zaikoAmtPsTotal",
  "nohinAbleDate2_3",
  "kigenDate2_3",
  "nohinAbleDate1_2",
  "kigenDate1_2",
  // "check",
  // "kigenDateReal",
  // "zaikoQtyBlReal",

];
//強制表示行
export const mustVisibleRowsKey:string[] = [
  'kigen',
];
//デフォルト表示行
export const defaultVisibleRowsKey:string[] = [
];

//デフォルト表示項目(4行必要)
export const defaultVisibleAttributes:string[][] = [
];

export const optionsNameList = [
  'optionMakers',
  'optionMakerCode',
  'optionBumons',
  'optionCenters',
  'optionCenterCode',
  'optionItemCode',
  'optionZaiko',
  'optionTeibanFlg',
];
export type ZaikoKigenOptions = {
  optionMakers?: CodeName[],        //メーカー
  optionMakerCode?: string,         //メーカーCD
  optionBumons?: CodeName[],        //部門
  optionCenters?: CodeName[],       //倉庫
  optionCenterCode?: string,        //倉庫CD
  optionItemCode?: string,          //商品CD
  optionZaiko?: '' |'zaiko_tanaban'|'zaiko'|'tanaban'| string,   //棚番
  optionTeibanFlg?: "" | "定番のみ" | "定番外のみ",
};
export const displaySettingsNameList = [
  'visibleAttributes',
  'checkedRowKey',
  'checkedColKey',
];
export type DisplaySettings = {
  visibleAttributes?: string[][],    //表示属性
  checkedRowKey?: string[], //行項目選択
  checkedColKey?: string[],  //列項目選択
};

//Page State
export type ZaikoKigenSaveState = {
  //検索条件
  ymd: Date | null,
  optionMakers:CodeName[],    //メーカー
  optionMakerCode:string,            //メーカーCD
  optionBumons:CodeName[],     //部門
  optionCenters:CodeName[],   //倉庫
  optionCenterCode:string,            //倉庫CD
  optionItemCode:string,            //商品CD
  optionZaiko:''|'zaiko_tanaban'|'zaiko'|'tanaban'|string,   //棚番
  optionTeibanFlg: "" | "定番のみ" | "定番外のみ",

  favoriteOptions: { title: CodeName, params: ZaikoKigenOptions }[] | null,
  favoriteDisplaySettings: { title: CodeName, params: DisplaySettings }[] | null,

  listSortOrder:CodeName,   //並び順
  listSortOrderDesc:boolean,   //昇順

  hiddenColumns: number[],
  hiddenColumnsKey: string[],
  visibleColumnsKey: string[],
  visibleRowsKey: string[],

  visibleAttributes:string[][],    //表示属性

  visibleZaikoKbnAll: boolean,
  visibleZaikoKbn1: boolean,
  visibleZaikoKbn2: boolean,
  visibleZaikoKbn3: boolean,
  visibleZaikoKbn4: boolean,
  visibleZaikoKbn5: boolean,
  visibleKigenPattern:''|'over'|'over1_2'|'over1_3'|'over999'|string,   //「賞味期限切れ」「１/２許容切れ」「１/３許容切れ」「99/99/99」
};

export const initialZaikoKigenState: ZaikoKigenSaveState = {
  // 基準日
  ymd: null,
  optionMakers:[],    //メーカー
  optionMakerCode:'',            //メーカーCD
  optionBumons:[],     //部門
  optionCenters:[],   //倉庫
  optionCenterCode:'',            //倉庫CD
  optionItemCode:'',            //商品CD
  optionZaiko:'zaiko',   //棚番
  optionTeibanFlg: "定番のみ",

  favoriteOptions: [],
  favoriteDisplaySettings: [],

  listSortOrder: listSortOrders.find(listSortOrder => listSortOrder.code == 'itemCD_centerCD'),   //並び順
  listSortOrderDesc: false,   //並び順

  hiddenColumns: [],
  hiddenColumnsKey: [],
  visibleColumnsKey: defaultVisibleColumnsKey,
  visibleRowsKey: defaultVisibleRowsKey,

  visibleAttributes: defaultVisibleAttributes,    //表示属性

  visibleZaikoKbnAll: false,
  visibleZaikoKbn1: false,
  visibleZaikoKbn2: false,
  visibleZaikoKbn3: false,
  visibleZaikoKbn4: false,
  visibleZaikoKbn5: false,
  visibleKigenPattern:'',

};

//Page Slice
export type ZaikoKigenSaveReducer = {
  initOnDidMount: (state:ZaikoKigenSaveState, action: PayloadAction<PageProps>) => void,
  resetOnWillUnmount: (state:ZaikoKigenSaveState) => void,
  clearOption: (state:ZaikoKigenSaveState, action: PayloadAction<{}>) => void,
  setYM: (state:ZaikoKigenSaveState, action: PayloadAction<Date>) => void,
  setOptionMakers: (state:ZaikoKigenSaveState, action: PayloadAction<CodeName[]>) => void,
  setOptionMakerCode: (state:ZaikoKigenSaveState, action: PayloadAction<string>) => void,
  setOptionBumons: (state:ZaikoKigenSaveState, action: PayloadAction<CodeName[]>) => void,
  setOptionCenters: (state:ZaikoKigenSaveState, action: PayloadAction<CodeName[]>) => void,
  setOptionCenterCode: (state:ZaikoKigenSaveState, action: PayloadAction<string>) => void,
  setOptionItemCode: (state:ZaikoKigenSaveState, action: PayloadAction<string>) => void,
  setOptionZaiko: (state:ZaikoKigenSaveState, action: PayloadAction<string>) => void,
  setOptionTeibanFlg: (state: ZaikoKigenSaveState, action: PayloadAction<"" | "定番のみ" | "定番外のみ">) => void,
  setFavoriteOptions: (state:ZaikoKigenSaveState, action: PayloadAction<{ title: CodeName, params: ZaikoKigenOptions }[]>) => void,
  setFavoriteDisplaySettings: (state:ZaikoKigenSaveState, action: PayloadAction<{ title: CodeName, params: DisplaySettings }[]>) => void,
  setListSortOrder: (state:ZaikoKigenSaveState, action: PayloadAction<CodeName>) => void,
  setListSortOrderDesc: (state:ZaikoKigenSaveState, action: PayloadAction<boolean>) => void,
  hideColumnKeysChange: (state:ZaikoKigenSaveState, action: PayloadAction<string[]>) => void,
  visibleRowKeysChange: (state:ZaikoKigenSaveState, action: PayloadAction<string[]>) => void,
  visibleAttributesChange: (state:ZaikoKigenSaveState, action: PayloadAction<string[][]>) => void,
  setVisibleZaikoKbnAll: (state:ZaikoKigenSaveState, action: PayloadAction<boolean>) => void,
  setVisibleZaikoKbn1: (state:ZaikoKigenSaveState, action: PayloadAction<boolean>) => void,
  setVisibleZaikoKbn2: (state:ZaikoKigenSaveState, action: PayloadAction<boolean>) => void,
  setVisibleZaikoKbn3: (state:ZaikoKigenSaveState, action: PayloadAction<boolean>) => void,
  setVisibleZaikoKbn4: (state:ZaikoKigenSaveState, action: PayloadAction<boolean>) => void,
  setVisibleZaikoKbn5: (state:ZaikoKigenSaveState, action: PayloadAction<boolean>) => void,
  setVisibleKigenPattern: (state:ZaikoKigenSaveState, action: PayloadAction<string>) => void,
}

const createReducerContent = (name:string, colRowModel:ZaikoKigenColRowModel, initialState: ZaikoKigenSaveState):ZaikoKigenSaveReducer => {return {
  //componentDidMount
  initOnDidMount(state:ZaikoKigenSaveState, action: PayloadAction<PageProps>) {
    const props:PageProps = action.payload;

    //自動検索する
    const autoRetrieve =
      // (props.bumons && props.bumons.length > 0) ||
      (props.centers && props.centers.length > 0) ||
      (props.makers && props.makers.length > 0) ||
      (props.items && props.items.length > 0)
    ;

    if(autoRetrieve) {
      //常に今日とする
      if(props.baseDate) {
        state.ymd = moment(props.baseDate).toDate();
      }
      else {
        const LastMonth = moment().startOf('day');
        state.ymd = LastMonth.toDate();
      }


      state.optionBumons = initialZaikoKigenState.optionBumons;

      state.optionCenters = initialZaikoKigenState.optionCenters;
      if(props.centers) {
        state.optionCenterCode = props.centers.join('\n');
      }
      else {
        state.optionCenterCode = initialZaikoKigenState.optionCenterCode;
      }

      state.optionMakers = initialZaikoKigenState.optionMakers;
      if(props.makers) {
        state.optionMakerCode = props.makers.join('\n');
      }
      else {
        state.optionMakerCode = initialZaikoKigenState.optionMakerCode;
      }

      if(props.items) {
        state.optionItemCode = props.items.join('\n');
      }
      else {
        state.optionItemCode = initialZaikoKigenState.optionItemCode;
      }

      if(props.zaiko) {
        state.optionZaiko = props.zaiko;
      }
      else {
        state.optionZaiko = initialZaikoKigenState.optionZaiko;
      }
      if(props.teiban) {
        state.optionTeibanFlg = props.teiban;
      }
      else {
        state.optionTeibanFlg = initialZaikoKigenState.optionTeibanFlg;
      }
      if (props.zaikoKbn) {
        const zaikoKbn = props.zaikoKbn.split(',');
        state.visibleZaikoKbn1 = zaikoKbn.indexOf('zaiko1') !== -1;
        state.visibleZaikoKbn2 = zaikoKbn.indexOf('zaiko2') !== -1;
        state.visibleZaikoKbn3 = zaikoKbn.indexOf('zaiko3') !== -1;
        state.visibleZaikoKbn4 = zaikoKbn.indexOf('zaiko4') !== -1;
        state.visibleZaikoKbn5 = zaikoKbn.indexOf('zaiko5') !== -1;
      }
    }
    else {
      //常に今日とする
      const LastMonth = moment().startOf('day');
      state.ymd = LastMonth.toDate();


      if(state.optionMakers === undefined) {
        state.optionMakers = initialZaikoKigenState.optionMakers;
      }
      if(state.optionMakerCode === undefined) {
        state.optionMakerCode = initialZaikoKigenState.optionMakerCode;
      }
      if(state.optionBumons === undefined) {
        state.optionBumons = initialZaikoKigenState.optionBumons;
      }
      if(state.optionCenters === undefined) {
        state.optionCenters = initialZaikoKigenState.optionCenters;
      }
      if(state.optionCenterCode === undefined) {
        state.optionCenterCode = initialZaikoKigenState.optionCenterCode;
      }
      if(state.optionItemCode === undefined) {
        state.optionItemCode = initialZaikoKigenState.optionItemCode;
      }
      if(state.optionZaiko === undefined) {
        state.optionZaiko = initialZaikoKigenState.optionZaiko;
      }
      if(state.optionTeibanFlg === undefined) {
        state.optionTeibanFlg = initialZaikoKigenState.optionTeibanFlg;
      }
    }

    if(state.favoriteOptions === undefined) {
      state.favoriteOptions = initialZaikoKigenState.favoriteOptions;
    }
    if(state.favoriteDisplaySettings === undefined) {
      state.favoriteDisplaySettings = initialZaikoKigenState.favoriteDisplaySettings;
    }
    if(!state.listSortOrder) {
      state.listSortOrder = initialState.listSortOrder;
    }
    if(state.listSortOrderDesc === undefined) {
      state.listSortOrderDesc = initialState.listSortOrderDesc;
    }

    //初期表示列0配列は、常に全項目表示とする
    if(initialState.visibleColumnsKey.length == 0) {
      state.visibleColumnsKey = colRowModel.colKeys;
    }
    else if(!state.visibleColumnsKey) {
      state.visibleColumnsKey = initialState.visibleColumnsKey;
    }
    //強制選択列
    state.visibleColumnsKey = arrayUtil.union(arrayUtil.and(colRowModel.colKeys, mustVisibleColumnsKey), state.visibleColumnsKey);
    //非表示列
    state.hiddenColumnsKey = arrayUtil.not(colRowModel.colKeys, state.visibleColumnsKey);

    //行項目
    if (!state.visibleRowsKey || state.visibleRowsKey.length == 0) {
      state.visibleRowsKey = (initialState.visibleRowsKey.length == 0) ? colRowModel.rowKeys : initialState.visibleRowsKey;
    }
    //強制選択行
    state.visibleRowsKey = arrayUtil.union(arrayUtil.and(colRowModel.rowKeys, mustVisibleRowsKey), state.visibleRowsKey);

    //表示項目
    if(!state.visibleAttributes || state.visibleAttributes.length < initialState.visibleAttributes.length) {
      state.visibleAttributes = initialState.visibleAttributes;
    }
    else {
      //6項目必要
      let visibleAttributes = [...state.visibleAttributes];
      let init = false;
      initialState.visibleAttributes.forEach((attrs, index) => {
        if(visibleAttributes[index].length != attrs.length) {
          init = true;
          visibleAttributes[index] = attrs;
        }
      });
      if(init) {
        state.visibleAttributes = visibleAttributes;
      }
    }

    if(state.visibleKigenPattern === undefined) {
      state.visibleKigenPattern = initialState.visibleKigenPattern;
    }

  },
  //componentWillUnmount
  resetOnWillUnmount(state:ZaikoKigenSaveState) {
    //初期表示列0配列は、常に全項目表示とするため、記憶しない
    if(initialState.visibleColumnsKey.length == 0) {
      state.visibleColumnsKey = [];
    }
  },
  clearOption(state:ZaikoKigenSaveState, action: PayloadAction<{}>) {
    const LastMonth = moment().startOf('day');
    state.ymd = LastMonth.toDate();

    state.optionMakers = initialZaikoKigenState.optionMakers;
    state.optionMakerCode = initialZaikoKigenState.optionMakerCode;
    state.optionBumons = initialZaikoKigenState.optionBumons;
    state.optionCenters = initialZaikoKigenState.optionCenters;
    state.optionCenterCode = initialZaikoKigenState.optionCenterCode;
    state.optionItemCode = initialZaikoKigenState.optionItemCode;
    state.optionZaiko = initialZaikoKigenState.optionZaiko;
    state.optionTeibanFlg = initialZaikoKigenState.optionTeibanFlg;
  },
  setYM(state:ZaikoKigenSaveState, action: PayloadAction<Date>) {
    state.ymd = action.payload;
  },
  setOptionMakers (state:ZaikoKigenSaveState, action: PayloadAction<CodeName[]>) {
    state.optionMakers = action.payload;
  },
  setOptionMakerCode (state:ZaikoKigenSaveState, action: PayloadAction<string>) {
    state.optionMakerCode = action.payload;
  },
  setOptionBumons (state:ZaikoKigenSaveState, action: PayloadAction<CodeName[]>) {
    state.optionBumons = action.payload;
  },
  setOptionItemCode (state:ZaikoKigenSaveState, action: PayloadAction<string>) {
    state.optionItemCode = action.payload;
  },
  setFavoriteOptions(state:ZaikoKigenSaveState, action: PayloadAction<{ title: CodeName, params: ZaikoKigenOptions }[]>) {
    state.favoriteOptions = action.payload;
  },
  setFavoriteDisplaySettings(state:ZaikoKigenSaveState, action: PayloadAction<{ title: CodeName, params: DisplaySettings }[]>) {
    state.favoriteDisplaySettings = action.payload;
  },
  setOptionCenters (state:ZaikoKigenSaveState, action: PayloadAction<CodeName[]>) {
    state.optionCenters = action.payload;
  },
  setOptionCenterCode (state:ZaikoKigenSaveState, action: PayloadAction<string>) {
    state.optionCenterCode = action.payload;
  },
  setOptionZaiko(state:ZaikoKigenSaveState, action: PayloadAction<string>) {
    state.optionZaiko = action.payload;
  },
  setOptionTeibanFlg(state: ZaikoKigenSaveState, action: PayloadAction<"" | "定番のみ" | "定番外のみ">) {
    state.optionTeibanFlg = action.payload;
  },
  setListSortOrder (state:ZaikoKigenSaveState, action: PayloadAction<CodeName>) {
    state.listSortOrder = action.payload;
  },
  setListSortOrderDesc (state:ZaikoKigenSaveState, action: PayloadAction<boolean>) {
    state.listSortOrderDesc = action.payload;
  },
  hideColumnKeysChange(state:ZaikoKigenSaveState, action: PayloadAction<string[]>){
    console.log('store.hideColumnKeysChange');
    //store更新
    state.hiddenColumns = colRowModel.colFromKeys(action.payload);
    state.hiddenColumnsKey = action.payload;
    state.visibleColumnsKey = arrayUtil.not(colRowModel.colKeys, action.payload);
  },
  visibleRowKeysChange(state:ZaikoKigenSaveState, action: PayloadAction<string[]>){
    console.log('store.visibleRowKeysChange');
    //store更新
    state.visibleRowsKey = action.payload;
  },
  visibleAttributesChange(state:ZaikoKigenSaveState, action: PayloadAction<string[][]>){
    console.log('store.visibleAttributesChange');
    //store更新
    state.visibleAttributes = action.payload;
  },
  setVisibleZaikoKbnAll(state:ZaikoKigenSaveState, action: PayloadAction<boolean>) {
    state.visibleZaikoKbnAll = action.payload;
  },
  setVisibleZaikoKbn1(state:ZaikoKigenSaveState, action: PayloadAction<boolean>) {
    state.visibleZaikoKbn1 = action.payload;
  },
  setVisibleZaikoKbn2(state:ZaikoKigenSaveState, action: PayloadAction<boolean>) {
    state.visibleZaikoKbn2 = action.payload;
  },
  setVisibleZaikoKbn3(state:ZaikoKigenSaveState, action: PayloadAction<boolean>) {
    state.visibleZaikoKbn3 = action.payload;
  },
  setVisibleZaikoKbn4(state:ZaikoKigenSaveState, action: PayloadAction<boolean>) {
    state.visibleZaikoKbn4 = action.payload;
  },
  setVisibleZaikoKbn5(state:ZaikoKigenSaveState, action: PayloadAction<boolean>) {
    state.visibleZaikoKbn5 = action.payload;
  },
  setVisibleKigenPattern(state:ZaikoKigenSaveState, action: PayloadAction<string>) {
    state.visibleKigenPattern = action.payload;
  },
}};

const createSliceContent = (name:string, colRowModel:ZaikoKigenColRowModel, initialState: ZaikoKigenSaveState) => createSlice({
  name: name,
  initialState: initialState,
  reducers: createReducerContent(name, colRowModel, initialState),
});

//Page Slice Export
//zaikoKigenSaveSlice
export const zaikoKigenSaveSlice = createSliceContent("zaikoKigenSave", zaikoKigenColRowModelDefault, initialZaikoKigenState);
