import moment from "moment";
moment.updateLocale("ja", {
  months: ["1月","2月","3月","4月","5月","6月","7月","8月","9月","10月","11月","12月",],
  weekdays: ["日曜日", "月曜日", "火曜日", "水曜日", "木曜日", "金曜日", "土曜日"],
  weekdaysShort: ["日", "月", "火", "水", "木", "金", "土"]
});

//ヘッダー列
export const headerColKeys = [
  'no',
  'attribute1Label', 
  'attribute1Value', 
  'teiban',
];
//行ヘッダー列
export const rowHeaderColKeys = [
  'rowHeader', 
];
//ヘッダー列
export const bodyColKeys = [
  'cycleCnt', //回転数
  'syukkaQtyAve', //出荷BL数
  'syukkaAmtAve', //配送売上
  'syukkaQtyTtl', //出荷BL数
  'syukkaAmtTtl', //配送売上

  'zaikoTtlPrd',  //合計 ｱｲﾃﾑ数
  'zaikoTtlQty',  //合計 在庫BL数
  'zaikoTtlAmt',  //合計 在庫金額
  'zaikoTtlRat',  //合計 在庫区分比

  'over1_3',
  'over1_2',
  'over',

  'zaiko999Prd',  //99999999 ｱｲﾃﾑ数
  'zaiko999Qty',  //99999999 在庫BL数
  'zaiko999Amt',  //99999999 在庫金額
  'zaiko999Rat',  //99999999 構成比

  'zaiko101Prd',  //101日以上 ｱｲﾃﾑ数
  'zaiko101Qty',  //101日以上 在庫BL数
  'zaiko101Amt',  //101日以上 在庫金額
  'zaiko101Rat',  //101日以上 構成比

  'zaiko031Prd',  //100～31日 ｱｲﾃﾑ数
  'zaiko031Qty',  //100～31日 在庫BL数
  'zaiko031Amt',  //100～31日 在庫金額
  'zaiko031Rat',  //100～31日 構成比

  'zaiko011Prd',  //30～11日 ｱｲﾃﾑ数
  'zaiko011Qty',  //30～11日 在庫BL数
  'zaiko011Amt',  //30～11日 在庫金額
  'zaiko011Rat',  //30～11日 構成比

  'zaiko000Prd',  //10未満 ｱｲﾃﾑ数
  'zaiko000Qty',  //10未満 在庫BL数
  'zaiko000Amt',  //10未満 在庫金額
  'zaiko000Rat',  //10未満 構成比

];

export const rowKeys:string[] = [
  'total',
  'zaiko1',	//在庫1(一般)
  'zaiko2',	//在庫2(特売)
  'zaiko3',	//在庫3(予約)
  'zaiko4',	//在庫4(出切)
  'zaiko5',	//在庫5(返品)
];
export const rowKeys0602: string[] = [
  'total',
  'zaiko1',	//在庫1(良品)
  'zaiko4',	//在庫4(出切)
  'zaiko5',	//在庫5(返品)
];
export const teibanKeys: string[] = [
  '全体',
  '定番',
  '定番外',
];

export const headersCol = [
  {
    no : "No.",
    attribute1Label: "倉庫属性", attribute1Value: "倉庫属性", 

    teiban: '定番',
    rowHeader: "項目", 

    syukkaQtyAve: "当月平均配送売上/日", //出荷BL数
    syukkaAmtAve: "当月平均配送売上/日", //配送売上
    syukkaQtyTtl: "当月配送売上累計", //出荷BL数
    syukkaAmtTtl: "当月配送売上累計", //配送売上
    cycleCnt: "回転数", //回転数

    zaikoTtlPrd: "合計",  //合計 ｱｲﾃﾑ数
    zaikoTtlQty: "合計",  //合計 在庫BL数
    zaikoTtlAmt: "合計",  //合計 在庫金額
    zaikoTtlRat: "合計",  //合計 在庫区分比

    over1_3: '%超過%',
    over1_2: '%超過%',
    over: '%超過%',

    zaiko101Prd: "経過日数 101日～",  //101日以上 ｱｲﾃﾑ数
    zaiko101Qty: "経過日数 101日～",  //101日以上 在庫BL数
    zaiko101Amt: "経過日数 101日～",  //101日以上 在庫金額
    zaiko101Rat: "経過日数 101日～",  //101日以上 構成比

    zaiko031Prd: "経過日数 31日～100日",  //100～31日 ｱｲﾃﾑ数
    zaiko031Qty: "経過日数 31日～100日",  //100～31日 在庫BL数
    zaiko031Amt: "経過日数 31日～100日",  //100～31日 在庫金額
    zaiko031Rat: "経過日数 31日～100日",  //100～31日 構成比

    zaiko011Prd: "経過日数 11日～30日",  //30～11日 ｱｲﾃﾑ数
    zaiko011Qty: "経過日数 11日～30日",  //30～11日 在庫BL数
    zaiko011Amt: "経過日数 11日～30日",  //30～11日 在庫金額
    zaiko011Rat: "経過日数 11日～30日",  //30～11日 構成比

    zaiko000Prd: "経過日数 当日～10日",  //10未満 ｱｲﾃﾑ数
    zaiko000Qty: "経過日数 当日～10日",  //10未満 在庫BL数
    zaiko000Amt: "経過日数 当日～10日",  //10未満 在庫金額
    zaiko000Rat: "経過日数 当日～10日",  //10未満 構成比

    zaiko999Prd: "経過日数 不明",  //99999999 ｱｲﾃﾑ数
    zaiko999Qty: "経過日数 不明",  //99999999 在庫BL数
    zaiko999Amt: "経過日数 不明",  //99999999 在庫金額
    zaiko999Rat: "経過日数 不明",  //99999999 構成比
  },
  {
    no : "",
    attribute1Label: "項目名", attribute1Value: "値", 

    teiban: 'フラグ',
    rowHeader : "名称", 

    syukkaQtyAve: "出荷BL数", //出荷BL数
    syukkaAmtAve: "建値金額", //配送売上
    syukkaQtyTtl: "出荷BL数", //出荷BL数
    syukkaAmtTtl: "建値金額", //配送売上

    cycleCnt: "", //回転数

    zaikoTtlPrd: "ｱｲﾃﾑ数",  //合計 ｱｲﾃﾑ数
    zaikoTtlQty: "在庫BL数",  //合計 在庫BL数
    zaikoTtlAmt: "在庫金額",  //合計 在庫金額
    zaikoTtlRat: "在庫区分比",  //合計 在庫区分比

    over1_3: '1/3 超過',
    over1_2: '1/2 超過',
    over: '賞味超過',

    zaiko101Prd: "ｱｲﾃﾑ数",  //101日以上 ｱｲﾃﾑ数
    zaiko101Qty: "在庫BL数",  //101日以上 在庫BL数
    zaiko101Amt: "在庫金額",  //101日以上 在庫金額
    zaiko101Rat: "構成比",  //101日以上 構成比

    zaiko031Prd: "ｱｲﾃﾑ数",  //100～31日 ｱｲﾃﾑ数
    zaiko031Qty: "在庫BL数",  //100～31日 在庫BL数
    zaiko031Amt: "在庫金額",  //100～31日 在庫金額
    zaiko031Rat: "構成比",  //100～31日 構成比

    zaiko011Prd: "ｱｲﾃﾑ数",  //30～11日 ｱｲﾃﾑ数
    zaiko011Qty: "在庫BL数",  //30～11日 在庫BL数
    zaiko011Amt: "在庫金額",  //30～11日 在庫金額
    zaiko011Rat: "構成比",  //30～11日 構成比

    zaiko000Prd: "ｱｲﾃﾑ数",  //10未満 ｱｲﾃﾑ数
    zaiko000Qty: "在庫BL数",  //10未満 在庫BL数
    zaiko000Amt: "在庫金額",  //10未満 在庫金額
    zaiko000Rat: "構成比",  //10未満 構成比

    zaiko999Prd: "ｱｲﾃﾑ数",  //99999999 ｱｲﾃﾑ数
    zaiko999Qty: "在庫BL数",  //99999999 在庫BL数
    zaiko999Amt: "在庫金額",  //99999999 在庫金額
    zaiko999Rat: "構成比",  //99999999 構成比

  },
];

export const headersRowMix = {
  total: '全体',
  zaiko1: '一般/良品',	//在庫1(一般)
  zaiko2: '特売',	//在庫2(特売)
  zaiko3: '予約',	//在庫3(予約)
  zaiko4: '出切',	//在庫4(出切)
  zaiko5: '返品',	//在庫5(返品)
};
export const headersRow = {
  total: '全体',
  zaiko1: '一般',	//在庫1(一般)
  zaiko2: '特売',	//在庫2(特売)
  zaiko3: '予約',	//在庫3(予約)
  zaiko4: '出切',	//在庫4(出切)
  zaiko5: '返品',	//在庫5(返品)
};
export const headersRow0602 = {
  total: '全体',
  zaiko1: '良品',	//在庫1(良品)
  zaiko4: '出切',	//在庫4(出切)
  zaiko5: '返品',	//在庫5(返品)
};

export const selectableCol =
{
  no : false,
  attribute1Label: true, 
  attribute1Value: false, 
  teiban: false,
  rowHeader : false, 

  syukkaQtyAve: true,
  syukkaAmtAve: true,
  syukkaQtyTtl: true,
  syukkaAmtTtl: true,
  cycleCnt: true,

  zaikoTtlPrd: true,
  zaikoTtlQty: true,
  zaikoTtlAmt: true,
  zaikoTtlRat: true,

  over1_3: true,
  over1_2: true,
  over: true,

  zaiko101Prd: true,
  zaiko101Qty: true,
  zaiko101Amt: true,
  zaiko101Rat: true,

  zaiko031Prd: true,
  zaiko031Qty: true,
  zaiko031Amt: true,
  zaiko031Rat: true,

  zaiko011Prd: true,
  zaiko011Qty: true,
  zaiko011Amt: true,
  zaiko011Rat: true,

  zaiko000Prd: true,
  zaiko000Qty: true,
  zaiko000Amt: true,
  zaiko000Rat: true,

  zaiko999Prd: true,
  zaiko999Qty: true,
  zaiko999Amt: true,
  zaiko999Rat: true,
}
export const selectableRow = {
  total: true,
  zaiko1: true,	//在庫1(一般)
  zaiko2: true,	//在庫2(特売)
  zaiko3: true,	//在庫3(予約)
  zaiko4: true,	//在庫4(出切)
  zaiko5: true,	//在庫5(返品)
};



export const headerColClasses = [
  {
    no : "zaikosummary_H",
    attribute1Label: "zaikosummary_H", 
    attribute1Value: "zaikosummary_H", 
    
    teiban : "zaikosummary_T",
    rowHeader : "zaikosummary_H",

    syukkaQtyAve: "zaikosummary_H_N",
    syukkaAmtAve: "zaikosummary_H_N",
    syukkaQtyTtl: "zaikosummary_H_N",
    syukkaAmtTtl: "zaikosummary_H_N",
    cycleCnt: "zaikosummary_H_N",

    zaikoTtlPrd: "zaikosummary_H_Z",
    zaikoTtlQty: "zaikosummary_H_Z",
    zaikoTtlAmt: "zaikosummary_H_Z",
    zaikoTtlRat: "zaikosummary_H_Z",

    over1_3: "zaikosummary_H_O3",
    over1_2: "zaikosummary_H_O2",
    over: "zaikosummary_H_O",

    zaiko101Prd: "zaikosummary_H_Z",
    zaiko101Qty: "zaikosummary_H_Z",
    zaiko101Amt: "zaikosummary_H_Z",
    zaiko101Rat: "zaikosummary_H_Z",

    zaiko031Prd: "zaikosummary_H_Z",
    zaiko031Qty: "zaikosummary_H_Z",
    zaiko031Amt: "zaikosummary_H_Z",
    zaiko031Rat: "zaikosummary_H_Z",

    zaiko011Prd: "zaikosummary_H_Z",
    zaiko011Qty: "zaikosummary_H_Z",
    zaiko011Amt: "zaikosummary_H_Z",
    zaiko011Rat: "zaikosummary_H_Z",

    zaiko000Prd: "zaikosummary_H_Z",
    zaiko000Qty: "zaikosummary_H_Z",
    zaiko000Amt: "zaikosummary_H_Z",
    zaiko000Rat: "zaikosummary_H_Z",

    zaiko999Prd: "zaikosummary_H_Z",
    zaiko999Qty: "zaikosummary_H_Z",
    zaiko999Amt: "zaikosummary_H_Z",
    zaiko999Rat: "zaikosummary_H_Z",
  },
  {
    no : "zaikosummary_H",
    attribute1Label: "zaikosummary_H", 
    attribute1Value: "zaikosummary_H", 

    teiban : "zaikosummary_T", 
    rowHeader : "zaikosummary_H", 

    syukkaQtyAve: "zaikosummary_H_N",
    syukkaAmtAve: "zaikosummary_H_N",
    syukkaQtyTtl: "zaikosummary_H_N",
    syukkaAmtTtl: "zaikosummary_H_N",
    cycleCnt: "zaikosummary_H_N",

    zaikoTtlPrd: "zaikosummary_H_Z",
    zaikoTtlQty: "zaikosummary_H_Z",
    zaikoTtlAmt: "zaikosummary_H_Z",
    zaikoTtlRat: "zaikosummary_H_Z",

    over1_3: "zaikosummary_H_O3",
    over1_2: "zaikosummary_H_O2",
    over: "zaikosummary_H_O",

    zaiko101Prd: "zaikosummary_H_Z",
    zaiko101Qty: "zaikosummary_H_Z",
    zaiko101Amt: "zaikosummary_H_Z",
    zaiko101Rat: "zaikosummary_H_Z",

    zaiko031Prd: "zaikosummary_H_Z",
    zaiko031Qty: "zaikosummary_H_Z",
    zaiko031Amt: "zaikosummary_H_Z",
    zaiko031Rat: "zaikosummary_H_Z",

    zaiko011Prd: "zaikosummary_H_Z",
    zaiko011Qty: "zaikosummary_H_Z",
    zaiko011Amt: "zaikosummary_H_Z",
    zaiko011Rat: "zaikosummary_H_Z",

    zaiko000Prd: "zaikosummary_H_Z",
    zaiko000Qty: "zaikosummary_H_Z",
    zaiko000Amt: "zaikosummary_H_Z",
    zaiko000Rat: "zaikosummary_H_Z",

    zaiko999Prd: "zaikosummary_H_Z",
    zaiko999Qty: "zaikosummary_H_Z",
    zaiko999Amt: "zaikosummary_H_Z",
    zaiko999Rat: "zaikosummary_H_Z",
  },
];
export const headerRowClasses = {
  total: 'zaikosummary_RH_total',
  zaiko1: 'zaikosummary_RH',	//在庫1(一般)
  zaiko2: 'zaikosummary_RH',	//在庫2(特売)
  zaiko3: 'zaikosummary_RH',	//在庫3(予約)
  zaiko4: 'zaikosummary_RH',	//在庫4(出切)
  zaiko5: 'zaikosummary_RH',	//在庫5(返品)
};

export const colWidths =
{
  no : 50,
  attribute1Label: 70, 
  attribute1Value: 150, 

  teiban : 60,
  rowHeader : 100, 

  syukkaQtyAve: 70,
  syukkaAmtAve: 90,
  syukkaQtyTtl: 80,
  syukkaAmtTtl: 100,
  cycleCnt: 60,

  zaikoTtlPrd: 60,
  zaikoTtlQty: 80,
  zaikoTtlAmt: 90,
  zaikoTtlRat: 80,

  over1_3: 80,
  over1_2: 80,
  over: 80,

  zaiko101Prd: 50,
  zaiko101Qty: 70,
  zaiko101Amt: 80,
  zaiko101Rat: 60,

  zaiko031Prd: 50,
  zaiko031Qty: 70,
  zaiko031Amt: 80,
  zaiko031Rat: 60,

  zaiko011Prd: 50,
  zaiko011Qty: 70,
  zaiko011Amt: 80,
  zaiko011Rat: 60,

  zaiko000Prd: 50,
  zaiko000Qty: 70,
  zaiko000Amt: 80,
  zaiko000Rat: 60,  

  zaiko999Prd: 50,
  zaiko999Qty: 70,
  zaiko999Amt: 80,
  zaiko999Rat: 60,  
};

export const colDataType =
{
  no : {type: 'text', readOnly: true,},
  attribute1Label: {type: 'text', readOnly: true, className: 'htRight',}, 
  attribute1Value: {type: 'text', readOnly: true,}, 

  teiban: {type: 'text', readOnly: true,},
  rowHeader: {type: 'text', readOnly: true,}, 

  syukkaQtyAve: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0.0', }, },
  syukkaAmtAve: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, },
  syukkaQtyTtl: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0.0', }, },
  syukkaAmtTtl: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, },
  cycleCnt: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0.00', }, },

  zaikoTtlPrd: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, },
  zaikoTtlQty: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0.0', }, },
  zaikoTtlAmt: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, },
  zaikoTtlRat: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0.0%', }, },

  over1_3: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, },
  over1_2: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, },
  over: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, },

  zaiko101Prd: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, },
  zaiko101Qty: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0.0', }, },
  zaiko101Amt: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, },
  zaiko101Rat: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0.0%', }, },

  zaiko031Prd: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, },
  zaiko031Qty: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0.0', }, },
  zaiko031Amt: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, },
  zaiko031Rat: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0.0%', }, },

  zaiko011Prd: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, },
  zaiko011Qty: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0.0', }, },
  zaiko011Amt: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, },
  zaiko011Rat: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0.0%', }, },

  zaiko000Prd: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, },
  zaiko000Qty: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0.0', }, },
  zaiko000Amt: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, },
  zaiko000Rat: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0.0%', }, },

  zaiko999Prd: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, },
  zaiko999Qty: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0.0', }, },
  zaiko999Amt: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, },
  zaiko999Rat: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0.0%', }, },
};

export const rowDataType = {};

export const rowRendererName =
{
};

//列定義情報（動的パラメータ）
interface ZaikoSummaryColRowModelInterface {
  //列固定
  fixedColumnsLeft: number,

  //テーブルの各列のキー
  colKeys: string[],
  //テーブルの各行のキー
  rowKeys: string[],
  teibanKeys: string[],
}

export class ZaikoSummaryColRowModel implements ZaikoSummaryColRowModelInterface {
  //列固定
  fixedColumnsLeft: number;
  //テーブルの各列のキー
  colKeys: string[];
  //テーブルの各列のキー
  rowKeys: string[];
  teibanKeys: string[];
  //ヘッダー
  nestedHeaders; //any[][]
  nestedHeadersBackup; //any[][]
  //ヘッダー区切り
  nestedHeadersGroupIndex: number[];
  //テーブルの各列の逆引き用マップ
  colKeysMap;
  //テーブルの各行の逆引き用マップ
  rowKeysMap;

  constructor(param: ZaikoSummaryColRowModelInterface) {
    this.fixedColumnsLeft = param.fixedColumnsLeft;
    this.colKeys = param.colKeys;
    this.rowKeys = param.rowKeys;
    this.teibanKeys = param.teibanKeys;

    //テーブルの各列のキー逆引き
    const colKeysMap = {};
    param.colKeys.forEach((colKey, index) => {
      colKeysMap[colKey] = index;
    });
    this.colKeysMap = colKeysMap;
    //テーブルの各行のキー逆引き
    const rowKeysMap = {};
    param.rowKeys.forEach((rowKey, index) => {
      rowKeysMap[rowKey] = index;
    });
    this.rowKeysMap = rowKeysMap;

    //nestedHeaders初期化
    this.initNestedHeaders();
  }

  //nestedHeaders初期化
  initNestedHeaders() {
    //nestedHeaders
    const nestedHeaders = [];
    const nestedHeadersGroupIndex = [];
    // const customBorders = [];
    headersCol.forEach((header, index) => {
      const lastHeader = index == headersCol.length - 1;
      const nestedHeader = [];

      let colspan=0;
      let preHeaderVal = null;
      this.colKeys.forEach((colKey) => {
        const headerVal = header[colKey] === '%超過%' ? colKey : header[colKey];
        if(lastHeader) {
          const val = ['over1_3', 'over1_2', 'over'].indexOf(headerVal) !== -1 ? '　' : headerVal;
          nestedHeader.push(val);
        }
        else {
          if(preHeaderVal !== null && preHeaderVal != headerVal){
            const val = ['over1_3', 'over1_2', 'over'].indexOf(preHeaderVal) !== -1 ? '　' : preHeaderVal;
            nestedHeader.push(colspan == 1 ? val : {label: val, colspan: colspan});
            colspan=0;
          }
          colspan++;
          preHeaderVal = headerVal;
        }
        //0行目でグループ
        if(index == 0) {
          nestedHeadersGroupIndex.push(colspan-1);
        }
      });
      if(!lastHeader && colspan > 0) {
        const val = ['over1_3', 'over1_2', 'over'].indexOf(preHeaderVal) !== -1 ? '　' : preHeaderVal;
        nestedHeader.push(colspan == 1 ? val : {label: val, colspan: colspan});
      }

      nestedHeaders.push(nestedHeader);
    });

    this.nestedHeaders = nestedHeaders;
    this.nestedHeadersBackup = nestedHeaders.map(nestedHeadersSub => [...nestedHeadersSub]);
    this.nestedHeadersGroupIndex = nestedHeadersGroupIndex;
  }

  //function
  keyFromCol(column:number):string {
    return this.colKeys[column];
  }
  keyFromCols(columns:number[]):string[] {
    const vals:string[] = [];
    columns.forEach(column => vals.push(this.colKeys[column]))
    return vals;
  }
  colFromKey(colKey:string):number {
    return this.colKeysMap[colKey];
  }
  colFromKeys(colKeys:string[]):number[] {
    const vals:number[] = [];
    colKeys.forEach(colKey => vals.push(this.colKeysMap[colKey]))
    return vals;
  }
  //function
  keyFromRow(row:number):string {
    const rowKeys = this.rowKeys;
    return rowKeys[row];
  }
  keyFromRows(rows:number[]):string[] {
    const rowKeys = this.rowKeys;
    const vals:string[] = [];
    rows.forEach(row => vals.push(rowKeys[row]))
    return vals;
  }
  rowFromKey(rowKey:string):number {
    const rowKeysMap = this.rowKeysMap;
    return rowKeysMap[rowKey];
  }
  rowFromKeys(rowKeys:string[]):number[] {
    const rowKeysMap = this.rowKeysMap;
    const vals:number[] = [];
    rowKeys.forEach(rowKey => vals.push(rowKeysMap[rowKey]))
    return vals;
  }
}

//ZaikoSummary用の列モデル
export const zaikoSummaryColRowModelDefault:ZaikoSummaryColRowModel = new ZaikoSummaryColRowModel({
  //列固定
  fixedColumnsLeft: headerColKeys.length + rowHeaderColKeys.length,

  //テーブルの各列のキー
  colKeys: [
    ...headerColKeys,
    ...rowHeaderColKeys,
    ...bodyColKeys,
  ],
  rowKeys: rowKeys,
  teibanKeys: teibanKeys,
});
