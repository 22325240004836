import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { ItemListColModel, RankData, cutRankDatas } from "@/components/itemlist/ItemListTableModel";
import { initialStateReportCut } from "@/store/itemlist/itemListSave";
import * as SalesListTableModel from "@/components/saleslist/SalesListTableModel";
import { drillDownMaxLv, teibans } from "@/components/itemlist/ItemListTableModel";

import * as calcUtil from "@/util/calcUtil";
import * as compareUtil from "@/util/compareUtil";
import { RequestParam } from "@/assets/apitype/itemList";

export type RetrievedId = {uuid: string};

import {PageProps} from "@/assets/pageprops/itemlist";
import { YM, CodeName } from "@/store/common";
import moment from 'moment';

export type findedCell = {
  row: number,
  col: number,
  data,
  text: string,
}

export interface RowData {
  TP?: "total" | "teiban" | "teibangai" | "grouping1" | "grouping2" | "grouping3" | "grouping4" | "grouping5" | "item",
  no?: string,
  YMID?: number | null, //年月
  CAT?: "def" | "sej" | "mix", //一般/SEJ
  SHCD?: string | null, MKCD?: string | null, IID?: string | null,
  //以下に貼り付け
  ISC?: string | null, ISN?: string | null, IMC?: string | null, IMN?: string | null, INM?: string | null, ICC?: string | null, ICN?: string | null, IPC?: string | null, IPN?: string | null, IJN?: string | null, ICD?: string | null, ICP?: string | null, IC1?: number | null, IC2?: number | null, ILI?: number | null, IPR?: number | null, 
  IBC?: string | null,
  IBN?: string | null,
  SCD?: string | null, SNM?: string | null,
  CCD?: string | null, CNM?: string | null,
  TCD?: string | null, TNM?: string | null,
  G2CD?: string | null, G2NM?: string | null,
  G1CD?: string | null, G1NM?: string | null,

  LQT?: number | null, LQP?: number | null, LQR?: number | null, LQD?: number | null,
  DQT?: number | null, DQP?: number | null, DQR?: number | null, DQD?: number | null,
  OQT?: number | null, OQP?: number | null, OQR?: number | null, OQD?: number | null,
  ULT?: number | null, ULP?: number | null, ULR?: number | null, ULD?: number | null,
  UDT?: number | null, UDP?: number | null, UDR?: number | null, UDD?: number | null,
  UPT?: number | null, UPP?: number | null, UPR?: number | null, UPD?: number | null,
  SQT?: number | null, SQP?: number | null, SQR?: number | null, SQD?: number | null,
  RQT?: number | null, RQP?: number | null, RQR?: number | null, RQD?: number | null,
  WQT?: number | null, WQP?: number | null, WQR?: number | null, WQD?: number | null,
  LQT_PS?: number | null, LQP_PS?: number | null, LQR_PS?: number | null, LQD_PS?: number | null,
  DQT_PS?: number | null, DQP_PS?: number | null, DQR_PS?: number | null, DQD_PS?: number | null,
  OQT_PS?: number | null, OQP_PS?: number | null, OQR_PS?: number | null, OQD_PS?: number | null,
  ULT_PS?: number | null, ULP_PS?: number | null, ULR_PS?: number | null, ULD_PS?: number | null,
  UDT_PS?: number | null, UDP_PS?: number | null, UDR_PS?: number | null, UDD_PS?: number | null,
  UPT_PS?: number | null, UPP_PS?: number | null, UPR_PS?: number | null, UPD_PS?: number | null,
  SQT_PS?: number | null, SQP_PS?: number | null, SQR_PS?: number | null, SQD_PS?: number | null,
  RQT_PS?: number | null, RQP_PS?: number | null, RQR_PS?: number | null, RQD_PS?: number | null,
  WQT_PS?: number | null, WQP_PS?: number | null, WQR_PS?: number | null, WQD_PS?: number | null,

  JJT?: number | null, JJP?: number | null, JJR?: number | null, JJD?: number | null, 
  JJQT?: number | null, JJQP?: number | null, JJQR?: number | null, JJQD?: number | null, 
  JJQT_PS?: number | null, JJQP_PS?: number | null,

  JST?: number | null, JSP?: number | null, JSR?: number | null, JSD?: number | null, 
  JSQT?: number | null, JSQP?: number | null, JSQR?: number | null, JSQD?: number | null, 
  JSQT_PS?: number | null, JSQP_PS?: number | null,

  JKT?: number | null, JKP?: number | null, JKR?: number | null, JKD?: number | null, 
  JKQT?: number | null, JKQP?: number | null, JKQR?: number | null, JKQD?: number | null, 
  JKQT_PS?: number | null, JKQP_PS?: number | null,
  JRT?: number | null, JRP?: number | null, JRD?: number | null, 
  //欠品責任別
  KI1KQ?: number | null, KI1KQ_PS?: number | null, KI1KA?: number | null, KI1KR?: number | null, 
  KI2KQ?: number | null, KI2KQ_PS?: number | null, KI2KA?: number | null, KI2KR?: number | null, 
  KI3KQ?: number | null, KI3KQ_PS?: number | null, KI3KA?: number | null, KI3KR?: number | null, 
  //欠品区分別
  K10KQ?: number | null, K10KQ_PS?: number | null, K10KA?: number | null, K10KR?: number | null, 
  K11KQ?: number | null, K11KQ_PS?: number | null, K11KA?: number | null, K11KR?: number | null, 
  K12KQ?: number | null, K12KQ_PS?: number | null, K12KA?: number | null, K12KR?: number | null, 
  K13KQ?: number | null, K13KQ_PS?: number | null, K13KA?: number | null, K13KR?: number | null, 
  K14KQ?: number | null, K14KQ_PS?: number | null, K14KA?: number | null, K14KR?: number | null, 
  K15KQ?: number | null, K15KQ_PS?: number | null, K15KA?: number | null, K15KR?: number | null, 
  K16KQ?: number | null, K16KQ_PS?: number | null, K16KA?: number | null, K16KR?: number | null, 
  K17KQ?: number | null, K17KQ_PS?: number | null, K17KA?: number | null, K17KR?: number | null, 
  K19KQ?: number | null, K19KQ_PS?: number | null, K19KA?: number | null, K19KR?: number | null, 
  K20KQ?: number | null, K20KQ_PS?: number | null, K20KA?: number | null, K20KR?: number | null, 
  K21KQ?: number | null, K21KQ_PS?: number | null, K21KA?: number | null, K21KR?: number | null, 
  K22KQ?: number | null, K22KQ_PS?: number | null, K22KA?: number | null, K22KR?: number | null, 
  K29KQ?: number | null, K29KQ_PS?: number | null, K29KA?: number | null, K29KR?: number | null, 
  K30KQ?: number | null, K30KQ_PS?: number | null, K30KA?: number | null, K30KR?: number | null, 
  K31KQ?: number | null, K31KQ_PS?: number | null, K31KA?: number | null, K31KR?: number | null, 
  K32KQ?: number | null, K32KQ_PS?: number | null, K32KA?: number | null, K32KR?: number | null, 
  K33KQ?: number | null, K33KQ_PS?: number | null, K33KA?: number | null, K33KR?: number | null, 
  K41KQ?: number | null, K41KQ_PS?: number | null, K41KA?: number | null, K41KR?: number | null, 
  K34KQ?: number | null, K34KQ_PS?: number | null, K34KA?: number | null, K34KR?: number | null, 
  K39KQ?: number | null, K39KQ_PS?: number | null, K39KA?: number | null, K39KR?: number | null, 
  K40KQ?: number | null, K40KQ_PS?: number | null, K40KA?: number | null, K40KR?: number | null, 
  K42KQ?: number | null, K42KQ_PS?: number | null, K42KA?: number | null, K42KR?: number | null, 
  K43KQ?: number | null, K43KQ_PS?: number | null, K43KA?: number | null, K43KR?: number | null, 
  K44KQ?: number | null, K44KQ_PS?: number | null, K44KA?: number | null, K44KR?: number | null, 
  K49KQ?: number | null, K49KQ_PS?: number | null, K49KA?: number | null, K49KR?: number | null, 
  K50KQ?: number | null, K50KQ_PS?: number | null, K50KA?: number | null, K50KR?: number | null, 

  SLT?: number | null, SLP?: number | null, SLR?: number | null, SLD?: number | null, SDT?: number | null, SDP?: number | null, SDR?: number | null, SDD?: number | null, SRT?: number | null, SRP?: number | null, SRR?: number | null, SRD?: number | null, STT?: number | null, STP?: number | null, STR?: number | null, STD?: number | null,
  PTT?: number | null, PTP?: number | null, PTD?: number | null, PRT?: number | null, PRP?: number | null, PRD?: number | null,
  CIT?: number | null, CIP?: number | null, CIR?: number | null, CID?: number | null, CST?: number | null, CSP?: number | null, CSR?: number | null, CSD?: number | null, CTT?: number | null, CTP?: number | null, CTR?: number | null, CTD?: number | null,
  BTT?: number | null, BTP?: number | null, BTR?: number | null, BTD?: number | null, BRT?: number | null, BRP?: number | null, BRD?: number | null, ATT?: number | null, ATP?: number | null, ATR?: number | null, ATD?: number | null, ART?: number | null, ARP?: number | null, ARD?: number | null,
  SHCT?: number | null, SHCP?: number | null, SHCR?: number | null, SHCD2?: number | null, 
  SHDT?: number | null, SHDP?: number | null, SHDR?: number | null, SHDD?: number | null, 
  SHRCT?: number | null, SHRCP?: number | null, SHRCR?: number | null, SHRCD?: number | null, 
  SHRDT?: number | null, SHRDP?: number | null, SHRDR?: number | null, SHRDD?: number | null, 
  SHT?: number | null, SHP?: number | null, SHR?: number | null, SHD?: number | null,   
  KUR?: number | null, KTR?: number | null, KRK?: string | null,
  ZTN?: string | null, RQTY?: number | null, RQTY_PS?: number | null, TQTY?: number | null, TQTY_PS?: number | null, YQTY?: number | null, YQTY_PS?: number | null, FQTY?: number | null, FQTY_PS?: number | null, AZKE?: number | null, AZKE_PS?: number | null, ZQTY?: number | null, ZQTY_PS?: number | null,
  SQTC?: number | null, SQTC_PS?: number | null,
  SQTCTKC?: number | null,
  SQTN?: number | null, SQTN_PS?: number | null,
  SQTNTKC?: number | null,

  TGTL?: number | null,
  TGCN?: string | null,
  TG01?: number | string | null, TG02?: string | null, TG03?: string | null, TG04?: string | null, TG05?: string | null, TG06?: string | null, TG07?: string | null, TG08?: string | null, TG09?: string | null, TG10?: string | null,
  TG11?: string | null, TG12?: string | null, TG13?: string | null, TG14?: string | null, TG15?: string | null, TG16?: string | null, TG17?: string | null, TG18?: string | null, TG19?: string | null, TG20?: string | null,
  TG21?: string | null, TG22?: string | null, TG23?: string | null, TG24?: string | null, TG25?: string | null, TG26?: string | null, TG27?: string | null, TG28?: string | null, TG29?: string | null, TG30?: string | null,
  TG31?: string | null, TG32?: string | null, TG33?: string | null, TG34?: string | null, TG35?: string | null, TG36?: string | null, TG37?: string | null, TG38?: string | null, TG39?: string | null, TG40?: string | null,
  TG41?: string | null, TG42?: string | null, TG43?: string | null, TG44?: string | null, TG45?: string | null, TG46?: string | null, TG47?: string | null, TG48?: string | null, TG49?: string | null, TG50?: string | null,
  TG01D?: string | null, TG02D?: string | null, TG03D?: string | null, TG04D?: string | null, TG05D?: string | null, TG06D?: string | null, TG07D?: string | null, TG08D?: string | null, TG09D?: string | null, TG10D?: string | null,
  TG11D?: string | null, TG12D?: string | null, TG13D?: string | null, TG14D?: string | null, TG15D?: string | null, TG16D?: string | null, TG17D?: string | null, TG18D?: string | null, TG19D?: string | null, TG20D?: string | null,
  TG21D?: string | null, TG22D?: string | null, TG23D?: string | null, TG24D?: string | null, TG25D?: string | null, TG26D?: string | null, TG27D?: string | null, TG28D?: string | null, TG29D?: string | null, TG30D?: string | null,
  TG31D?: string | null, TG32D?: string | null, TG33D?: string | null, TG34D?: string | null, TG35D?: string | null, TG36D?: string | null, TG37D?: string | null, TG38D?: string | null, TG39D?: string | null, TG40D?: string | null,
  TG41D?: string | null, TG42D?: string | null, TG43D?: string | null, TG44D?: string | null, TG45D?: string | null, TG46D?: string | null, TG47D?: string | null, TG48D?: string | null, TG49D?: string | null, TG50D?: string | null,
  SGTL?: number | null,
  SGCN?: string | null,
  SG01?: number | null, SG02?: number | null, SG03?: number | null, SG04?: number | null, SG05?: number | null, SG06?: number | null, SG07?: number | null, SG08?: number | null, SG09?: number | null, SG10?: number | null,
  SG11?: number | null, SG12?: number | null, SG13?: number | null, SG14?: number | null, SG15?: number | null, SG16?: number | null, SG17?: number | null, SG18?: number | null, SG19?: number | null, SG20?: number | null,
  SG21?: number | null, SG22?: number | null, SG23?: number | null, SG24?: number | null, SG25?: number | null, SG26?: number | null, SG27?: number | null, SG28?: number | null, SG29?: number | null, SG30?: number | null,
  SG31?: number | null, SG32?: number | null, SG33?: number | null, SG34?: number | null, SG35?: number | null, SG36?: number | null, SG37?: number | null, SG38?: number | null, SG39?: number | null, SG40?: number | null,
  SG41?: number | null, SG42?: number | null, SG43?: number | null, SG44?: number | null, SG45?: number | null, SG46?: number | null, SG47?: number | null, SG48?: number | null, SG49?: number | null, SG50?: number | null,
  HTD?: string | moment.Moment | null,
  KTB?: string | null,
  //センター別出荷数
  TBN?: string | null,
}

export interface TagData {
  tag_id?: string, tag_name?: string,
}
export interface TagItemData {
  tag_id?: string, IID?: string, ICD?: string
}

//Page State
export type ItemListTmpState = {
  accordionOpen: boolean,
  //年月
  ym: string | null,
  ymList: YM[],
  //集計軸
  axisType: "group2" | "shiten" | "shiire" | "maker" | null,
  //データ種別
  dataType: "quick" | "commit" | null,
  //検索対象
  containSEJ: boolean,

  group2List: CodeName[],
  group1List: CodeName[],
  shitenList: CodeName[],
  centerList: CodeName[],
  makerList: CodeName[],
  tokuisakiList: CodeName[],
  pbList: CodeName[],
  tagList: CodeName[],
  categoryList: CodeName[],

  d_cat: "def" | "sej" | "mix" | null,
  d1_axis: "group1" | "group2" | "shiten" | "center" | "maker" | "tokuisaki" | "category" | null,
  d2_axis: "group1" | "group2" | "shiten" | "center" | "maker" | "tokuisaki" | "category" | null,
  d3_axis: "group1" | "group2" | "shiten" | "center" | "maker" | "tokuisaki" | "category" | null,
  d4_axis: "group1" | "group2" | "shiten" | "center" | "maker" | "tokuisaki" | "category" | null,
  d5_axis: "group1" | "group2" | "shiten" | "center" | "maker" | "tokuisaki" | "category" | null,
  d6_axis: "group1" | "group2" | "shiten" | "center" | "maker" | "tokuisaki" | "category" | null,
  d7_axis: "group1" | "group2" | "shiten" | "center" | "maker" | "tokuisaki" | "category" | null,
  d8_axis: "group1" | "group2" | "shiten" | "center" | "maker" | "tokuisaki" | "category" | null,
  d1_target: string | null,
  d2_target: string | null,
  d3_target: string | null,
  d4_target: string | null,
  d5_target: string | null,
  d6_target: string | null,
  d7_target: string | null,
  d8_target: string | null,
  d1_targetNM: string | null,
  d2_targetNM: string | null,
  d3_targetNM: string | null,
  d4_targetNM: string | null,
  d5_targetNM: string | null,
  d6_targetNM: string | null,
  d7_targetNM: string | null,
  d8_targetNM: string | null,

  //検索する条件
  requestParam : RequestParam,
  //検索終了した条件
  retrievedIDs: RetrievedId[],
  requestedParam : RequestParam,

  //共有URL
  shareURL: string,

  progress: Record<string, unknown>,
  datasAll: RowData[],
  datas: RowData[],
  rows: [][],
  mergeCells: [],
  selectionRowStart: number,
  selectionRowEnd: number,

  tags: TagData[],
  tagItems: TagItemData[],
  selectedTags: TagData[],

  teibanGroup1s: CodeName[],
  shukkaGroup1s: CodeName[],

  findKeyword:string,
  findedCellSelect: findedCell,
  findedCells: findedCell[],
  findedCellsOver: boolean,

  isIME: boolean,
  initList: string[] | null,

  lastUpdate: string | null,
  adjus0Imported: boolean | null,
  errorMessage: string | null,
};

export const initialState: ItemListTmpState = {
  accordionOpen: true,
  //年月
  ym: null,
  ymList: [],
  //集計軸
  axisType: null,
  //データ種別
  dataType: null,
  //検索対象
  containSEJ: false,

  group2List: [],
  group1List: [],
  shitenList: [],
  centerList: [],
  makerList: [],
  tokuisakiList: [],
  pbList: [],
  tagList: [],
  categoryList: [],

  d_cat: null,
  d1_axis: null,
  d2_axis: null,
  d3_axis: null,
  d4_axis: null,
  d5_axis: null,
  d6_axis: null,
  d7_axis: null,
  d8_axis: null,
  d1_target: null,
  d2_target: null,
  d3_target: null,
  d4_target: null,
  d5_target: null,
  d6_target: null,
  d7_target: null,
  d8_target: null,
  d1_targetNM: null,
  d2_targetNM: null,
  d3_targetNM: null,
  d4_targetNM: null,
  d5_targetNM: null,
  d6_targetNM: null,
  d7_targetNM: null,
  d8_targetNM: null,

  requestParam : {},
  retrievedIDs: [],
  requestedParam : {},
  //共有URL
  shareURL: '',

  progress: {},
  datasAll: [],
  datas: [],
  rows: [],
  mergeCells: null,
  selectionRowStart: -1,
  selectionRowEnd: -1,

  tags: [],
  tagItems: [],
  selectedTags: [],

  teibanGroup1s: [],
  shukkaGroup1s: [],

  findKeyword:'',
  findedCellSelect: null,
  findedCells: [],
  findedCellsOver: false,

  // datasZaiko: [],
  isIME: false,
  initList: ["group2", "group1", "shiten", "center", "maker", "tokuisaki", "pb", "tag", "category"],

  lastUpdate: null,
  adjus0Imported: false,
  errorMessage: null,
};


//Page Slice
export type ItemListTmpReducer = {
  setAccordionOpen: (state:ItemListTmpState, action: PayloadAction<boolean>) => void,
  setPageQuery: (state:ItemListTmpState, action: PayloadAction<PageProps>) => void,
  setYMList: (state:ItemListTmpState, action: PayloadAction<YM[]>) => void,
  setContainSEJ: (state:ItemListTmpState, action: PayloadAction<boolean>) => void,
  setGroup2List: (state:ItemListTmpState, action: PayloadAction<CodeName[]>) => void,
  setGroup1List: (state:ItemListTmpState, action: PayloadAction<CodeName[]>) => void,
  setShitenList: (state:ItemListTmpState, action: PayloadAction<CodeName[]>) => void,
  setCenterList: (state:ItemListTmpState, action: PayloadAction<CodeName[]>) => void,
  setMakerList: (state:ItemListTmpState, action: PayloadAction<CodeName[]>) => void,
  setTokuisakiList: (state:ItemListTmpState, action: PayloadAction<CodeName[]>) => void,
  setPbList: (state:ItemListTmpState, action: PayloadAction<CodeName[]>) => void,
  setTagList: (state:ItemListTmpState, action: PayloadAction<CodeName[]>) => void,
  setCategoryList: (state:ItemListTmpState, action: PayloadAction<CodeName[]>) => void,
  setIsIME: (state:ItemListTmpState, action: PayloadAction<boolean>) => void,
  setInitList: (state:ItemListTmpState, action: PayloadAction<string>) => void,
  clearOption: (state) => void,
  setRequestParam: (state:ItemListTmpState, action: PayloadAction<RequestParam>) => void,
  setRetrievedID: (state:ItemListTmpState, action: PayloadAction<RetrievedId>) => void,
  addRetrievedID: (state:ItemListTmpState, action: PayloadAction<RetrievedId>) => void,
  setRequestedParam: (state:ItemListTmpState, action: PayloadAction<RequestParam>) => void,
  setShareURL: (state:ItemListTmpState, action: PayloadAction<string>) => void,
  putProgress: (state:ItemListTmpState, action: PayloadAction<string>) => void,
  removeProgress: (state:ItemListTmpState, action: PayloadAction<string>) => void,
  searched: (state:ItemListTmpState, action: PayloadAction<{
    param: RequestParam, datas: RowData[], sort: { key: string, asc: boolean }, 
    grouping1: 'item'|'shiire'|'maker'|'shiten'|'center'|'group2'|'group1'|'tokuisaki'|'category'|'pb'|string, 
    grouping2: 'item'|'shiire'|'maker'|'shiten'|'center'|'group2'|'group1'|'tokuisaki'|'category'|'pb'|string,
    grouping3: 'item'|'shiire'|'maker'|'shiten'|'center'|'group2'|'group1'|'tokuisaki'|'category'|'pb'|string,
    grouping4: 'item'|'shiire'|'maker'|'shiten'|'center'|'group2'|'group1'|'tokuisaki'|'category'|'pb'|string,
    grouping5: 'item'|'shiire'|'maker'|'shiten'|'center'|'group2'|'group1'|'tokuisaki'|'category'|'pb'|string,
    groupingOnly: boolean,
    keppinOnly: boolean,
    selectedTags: TagData[], tags: TagData[], tagItems: TagItemData[], selectedRanks: RankData[], isRank: boolean, pbKind: string, colModel: ItemListColModel
  }>) => void,
  execSort: (state:ItemListTmpState, action: PayloadAction<{sort:{key:string, asc:boolean}, 
    grouping1: 'item'|'shiire'|'maker'|'shiten'|'center'|'group2'|'group1'|'tokuisaki'|'category'|'pb'|string, 
    grouping2: 'item'|'shiire'|'maker'|'shiten'|'center'|'group2'|'group1'|'tokuisaki'|'category'|'pb'|string,
    grouping3: 'item'|'shiire'|'maker'|'shiten'|'center'|'group2'|'group1'|'tokuisaki'|'category'|'pb'|string,
    grouping4: 'item'|'shiire'|'maker'|'shiten'|'center'|'group2'|'group1'|'tokuisaki'|'category'|'pb'|string,
    grouping5: 'item'|'shiire'|'maker'|'shiten'|'center'|'group2'|'group1'|'tokuisaki'|'category'|'pb'|string,
    groupingOnly: boolean,
    colModel:ItemListColModel}>) => void,
  execGrouping: (state:ItemListTmpState, action: PayloadAction<{
    sort: { key: string, asc: boolean }, 
    grouping1: 'item'|'shiire'|'maker'|'shiten'|'center'|'group2'|'group1'|'tokuisaki'|'category'|'pb'|string, 
    grouping2: 'item'|'shiire'|'maker'|'shiten'|'center'|'group2'|'group1'|'tokuisaki'|'category'|'pb'|string,
    grouping3: 'item'|'shiire'|'maker'|'shiten'|'center'|'group2'|'group1'|'tokuisaki'|'category'|'pb'|string,
    grouping4: 'item'|'shiire'|'maker'|'shiten'|'center'|'group2'|'group1'|'tokuisaki'|'category'|'pb'|string,
    grouping5: 'item'|'shiire'|'maker'|'shiten'|'center'|'group2'|'group1'|'tokuisaki'|'category'|'pb'|string,
    groupingOnly: boolean,
    keppinOnly: boolean,
    selectedTags:TagData[], selectedRanks: RankData[], isRank:boolean, pbKind: string, colModel:ItemListColModel}>) => void,
  setDatas: (state:ItemListTmpState, action: PayloadAction<{datas:RowData[], colModel:ItemListColModel}>) => void,
  editRowDatas: (state:ItemListTmpState, action: PayloadAction<{row:number, key:string, value:string|number|object|null, relatedValues?: {key:string, value:string|number|object|null}[]}[]>) => void,
  rowSelectionChange: (state:ItemListTmpState, action: PayloadAction<{start:number,end:number}>) => void,
  refreshTable: (state:ItemListTmpState, action: PayloadAction<{colModel:ItemListColModel}>) => void,
  clearFindKeyword: (state) => void,
  setFindKeyword: (state:ItemListTmpState, action: PayloadAction<string>) => void,
  setFindedCellSelect: (state:ItemListTmpState, action: PayloadAction<findedCell>) => void,
  setFindedCells: (state:ItemListTmpState, action: PayloadAction<{findedCells:findedCell[], over:boolean}>) => void,
  setLastUpdate: (state:ItemListTmpState, action: PayloadAction<string | null>) => void,
  setAdjus0Imported: (state:ItemListTmpState, action: PayloadAction<boolean | null>) => void,
  setErrorMessage: (state:ItemListTmpState, action: PayloadAction<string>) => void,
}
const createReducerContent = ():ItemListTmpReducer => {return {
    setAccordionOpen(state:ItemListTmpState, action: PayloadAction<boolean>) {
      state.accordionOpen = action.payload;
    },

    //単品別一覧から渡されたQuery
    setPageQuery(state:ItemListTmpState, action: PayloadAction<PageProps>) {
      const newState = {
          ym: action.payload.ym,
          axisType: action.payload.axisType,
          dataType: action.payload.dataType,
          containSEJ: action.payload.containSEJ == '1',

          d_cat: action.payload.d_cat,
      };
      for (let i = 1; i <= SalesListTableModel.drillDownMaxLv; i++) {
        newState['d' + i + '_axis'] = action.payload['d' + i + '_axis'];
        newState['d' + i + '_target'] = action.payload['d' + i + '_target'];
        newState['d' + i + '_targetNM'] = action.payload['d' + i + '_targetNM'];
      }
      Object.assign(state, newState);
    },

    // Option YM
    setYMList(state:ItemListTmpState, action: PayloadAction<YM[]>) {
      state.ymList = action.payload;
    },

    //検索対象
    setContainSEJ(state:ItemListTmpState, action: PayloadAction<boolean>) {
      state.containSEJ = action.payload;
    },
    setGroup2List(state:ItemListTmpState, action: PayloadAction<CodeName[]>) {
      state.group2List = action.payload;
    },
    setGroup1List(state:ItemListTmpState, action: PayloadAction<CodeName[]>) {
      state.group1List = action.payload;
    },
    setShitenList(state:ItemListTmpState, action: PayloadAction<CodeName[]>) {
      state.shitenList = action.payload;
    },
    setCenterList(state:ItemListTmpState, action: PayloadAction<CodeName[]>) {
      state.centerList = action.payload;
    },
    setMakerList(state:ItemListTmpState, action: PayloadAction<CodeName[]>) {
      state.makerList = action.payload;
    },
    setTokuisakiList(state:ItemListTmpState, action: PayloadAction<CodeName[]>) {
      state.tokuisakiList = action.payload;
    },
    setPbList(state:ItemListTmpState, action: PayloadAction<CodeName[]>) {
      state.pbList = action.payload;
    },
    setTagList(state:ItemListTmpState, action: PayloadAction<CodeName[]>) {
      state.tagList = action.payload;
    },
    setCategoryList(state:ItemListTmpState, action: PayloadAction<CodeName[]>) {
      state.categoryList = action.payload;
    },
    setIsIME(state:ItemListTmpState, action: PayloadAction<boolean>){
      state.isIME = action.payload;
    },
    setInitList(state:ItemListTmpState, action: PayloadAction<string>) {
      if (state.initList.some(data => data == action.payload)) {
        state.initList = state.initList.filter(data => data != action.payload);
      }
    },

    clearOption(state) {
      Object.assign(state, {
        ym: state.ymList[0],
        axisType: initialState.axisType,
        dataType: initialState.dataType,
        containSEJ: initialState.containSEJ,
      });
    },

    //検索条件
    setRequestParam(state:ItemListTmpState, action: PayloadAction<RequestParam>) {
      state.requestParam = action.payload;
    },
    setRetrievedID(state:ItemListTmpState, action: PayloadAction<RetrievedId>) {
      state.retrievedIDs = [action.payload];
    },
    addRetrievedID(state:ItemListTmpState, action: PayloadAction<RetrievedId>) {
      const retrievedIDs = [...state.retrievedIDs];
      retrievedIDs.push(action.payload);
      state.retrievedIDs = retrievedIDs;
    },

    setRequestedParam(state:ItemListTmpState, action: PayloadAction<RequestParam>) {
      state.requestedParam = action.payload;
    },

    setShareURL(state:ItemListTmpState, action: PayloadAction<string>) {
      state.shareURL = action.payload;
    },

    putProgress(state:ItemListTmpState, action: PayloadAction<string>) {
      const key = action.payload;
      const progressNew = {...state.progress};
      progressNew[key] = true;
      state.progress = progressNew;
    },
    removeProgress(state:ItemListTmpState, action: PayloadAction<string>) {
      const key = action.payload;
      const progressNew = {};
      Object.keys(state.progress).forEach(k => {
        if(key != k) {
          progressNew[k] = true;
        }
      })
      state.progress = progressNew;
    },
    searched(state:ItemListTmpState, action: PayloadAction<{param: RequestParam, datas:RowData[], sort:{key:string, asc:boolean}, 
      grouping1: 'item'|'shiire'|'maker'|'shiten'|'center'|'group2'|'group1'|'tokuisaki'|'category'|'pb'|string, 
      grouping2: 'item'|'shiire'|'maker'|'shiten'|'center'|'group2'|'group1'|'tokuisaki'|'category'|'pb'|string,
      grouping3: 'item'|'shiire'|'maker'|'shiten'|'center'|'group2'|'group1'|'tokuisaki'|'category'|'pb'|string,
      grouping4: 'item'|'shiire'|'maker'|'shiten'|'center'|'group2'|'group1'|'tokuisaki'|'category'|'pb'|string,
      grouping5: 'item'|'shiire'|'maker'|'shiten'|'center'|'group2'|'group1'|'tokuisaki'|'category'|'pb'|string,
      groupingOnly: boolean,
      keppinOnly: boolean,
      selectedTags: TagData[], tags: TagData[], tagItems: TagItemData[], selectedRanks: RankData[], isRank: boolean, pbKind: string, colModel: ItemListColModel
    }>) {
      console.log('searched');
      const sort = action.payload.sort;
      const grouping1 = action.payload.grouping1;
      const grouping2 = action.payload.grouping2;
      const grouping3 = action.payload.grouping3;
      const grouping4 = action.payload.grouping4;
      const grouping5 = action.payload.grouping5;
      const groupingOnly = action.payload.groupingOnly;
      const keppinOnly = action.payload.keppinOnly;
      const selectedTags = action.payload.selectedTags;
      const selectedRanks = action.payload.selectedRanks;
      const isRank = action.payload.isRank;
      const pbKind = action.payload.pbKind;
      const colModel = action.payload.colModel;
      const param = action.payload.param;
      const teiban = state.requestedParam.teiban === undefined ? [] :
        state.requestedParam.teiban === 'teiban' ? ['teiban'] :
          state.requestedParam.teiban === 'teibangai' ? ['teibangai'] : ['teiban', 'teibangai'];

      //計算
      let datas = action.payload.datas;
      const tagItems = action.payload.tagItems;

      datas = parseData(datas);
      const datasAll = [...datas];

      datas = makeDatas(datas, sort, grouping1, grouping2, grouping3, grouping4, grouping5, groupingOnly, keppinOnly, selectedTags, tagItems, selectedRanks, isRank, pbKind, true, param.ym, teiban);
      datas = makeDatasTeiban(state, datas, datasAll, teiban);
      // datas = calcTotal(datas); //合計行の作成
      // if(groupingMaker) {
      //   datas = calcMaker(datas, groupingCenter); //メーカー行の作成
      // }
      // datas = calcDatas(datas, "all"); //計算項目の計算
      let key = sort.key;
      datas = doSort(datas, key, sort.asc, grouping1, grouping2, grouping3, grouping4, grouping5, groupingOnly);  //ソート
      datas = resetRowNo(datas, grouping1, grouping2, grouping3, grouping4, grouping5, groupingOnly); //列番号の振りなおし

      Object.assign(state, {
        datasAll: datasAll,
        datas: datas,
        rows: convertRows(datas, colModel),
        mergeCells: createMergeCells(datas),
        tags: action.payload.tags,
        tagItems: action.payload.tagItems,
      });
    },
    execSort(state:ItemListTmpState, action: PayloadAction<{sort:{key:string, asc:boolean}, 
      grouping1: 'item'|'shiire'|'maker'|'shiten'|'center'|'group2'|'group1'|'tokuisaki'|'category'|'pb'|string, 
      grouping2: 'item'|'shiire'|'maker'|'shiten'|'center'|'group2'|'group1'|'tokuisaki'|'category'|'pb'|string,
      grouping3: 'item'|'shiire'|'maker'|'shiten'|'center'|'group2'|'group1'|'tokuisaki'|'category'|'pb'|string,
      grouping4: 'item'|'shiire'|'maker'|'shiten'|'center'|'group2'|'group1'|'tokuisaki'|'category'|'pb'|string,
      grouping5: 'item'|'shiire'|'maker'|'shiten'|'center'|'group2'|'group1'|'tokuisaki'|'category'|'pb'|string,
      groupingOnly: boolean,
      colModel:ItemListColModel}>) {
      console.log('execSort');
      const sort = action.payload.sort;
      const grouping1 = action.payload.grouping1;
      const grouping2 = action.payload.grouping2;
      const grouping3 = action.payload.grouping3;
      const grouping4 = action.payload.grouping4;
      const grouping5 = action.payload.grouping5;
      const groupingOnly = action.payload.groupingOnly;
      const colModel = action.payload.colModel;

      let key = sort.key;
      const asc = sort.asc;

      let datas = [...state.datas];
      datas = doSort(datas, key, asc, grouping1, grouping2, grouping3, grouping4, grouping5, groupingOnly);
      datas = resetRowNo(datas, grouping1, grouping2, grouping3, grouping4, grouping5, groupingOnly); //列番号の振りなおし

      Object.assign(state, {
        datas: datas,
        //テーブル用のデータに変換
        rows: convertRows(datas, colModel),
      });
    },
    execGrouping(state:ItemListTmpState, action: PayloadAction<{sort:{key:string, asc:boolean}, 
      grouping1: 'item'|'shiire'|'maker'|'shiten'|'center'|'group2'|'group1'|'tokuisaki'|'category'|'pb'|string, 
      grouping2: 'item'|'shiire'|'maker'|'shiten'|'center'|'group2'|'group1'|'tokuisaki'|'category'|'pb'|string,
      grouping3: 'item'|'shiire'|'maker'|'shiten'|'center'|'group2'|'group1'|'tokuisaki'|'category'|'pb'|string,
      grouping4: 'item'|'shiire'|'maker'|'shiten'|'center'|'group2'|'group1'|'tokuisaki'|'category'|'pb'|string,
      grouping5: 'item'|'shiire'|'maker'|'shiten'|'center'|'group2'|'group1'|'tokuisaki'|'category'|'pb'|string,
      groupingOnly: boolean,
      keppinOnly: boolean,
      selectedTags:TagData[], selectedRanks: RankData[], isRank:boolean, pbKind: string, colModel:ItemListColModel}>) {
      console.log('execGrouping');
      const sort = action.payload.sort;
      const grouping1 = action.payload.grouping1;
      const grouping2 = action.payload.grouping2;
      const grouping3 = action.payload.grouping3;
      const grouping4 = action.payload.grouping4;
      const grouping5 = action.payload.grouping5;
      const groupingOnly = action.payload.groupingOnly;
      const keppinOnly = action.payload.keppinOnly;
      const selectedTags = action.payload.selectedTags;
      const selectedRanks = action.payload.selectedRanks;
      const isRank = action.payload.isRank;
      const pbKind = action.payload.pbKind;
      const colModel = action.payload.colModel;
      const param = state.requestedParam;
      const teiban = state.requestedParam.teiban === undefined ? [] :
        state.requestedParam.teiban === 'teiban' ? ['teiban'] :
          state.requestedParam.teiban === 'teibangai' ? ['teibangai'] : ['teiban', 'teibangai'];
      let datas = [...state.datasAll];
      datas = makeDatas(datas, sort, grouping1, grouping2, grouping3, grouping4, grouping5, groupingOnly, keppinOnly, selectedTags, state.tagItems, selectedRanks, isRank, pbKind, false, param.ym, teiban);
      datas = makeDatasTeiban(state, datas, state.datasAll, teiban);

      // //タグによる絞り込み
      // if(selectedTags && selectedTags.length > 0) {
      //   let tagItems = state.tagItems.filter(tagItem => selectedTags.indexOf(tagItem.tag_id) !== -1);
      //   let tagItemsIID = tagItems.map(tagItem => tagItem.IID);
      //   datas = state.datasAll.filter(data => data.TP != 'item' || tagItemsIID.indexOf(data.IID) !== -1);
      // }

      // if(groupingMaker) {
      //   datas = calcMaker(datas, groupingCenter); //メーカー行の作成
      //   datas = calcDatas(datas, "maker"); //計算項目の計算
      //   datas = calcDatas(datas, "center"); //計算項目の計算
      // }
      // else {
      //   datas = datas.filter(data => data.TP != "maker"); //メーカー行の削除
      //   datas = datas.filter(data => data.TP != "center"); //センター行の削除
      // }
      let key = sort.key;
      datas = doSort(datas, key, sort.asc, grouping1, grouping2, grouping3, grouping4, grouping5, groupingOnly);  //ソート
      datas = resetRowNo(datas, grouping1, grouping2, grouping3, grouping4, grouping5, groupingOnly); //列番号の振りなおし

      Object.assign(state, {
        datas: datas,
        selectedTags: selectedTags,
        //テーブル用のデータに変換
        rows: convertRows(datas, colModel),
        mergeCells: createMergeCells(datas),
      });
    },
    setDatas(state:ItemListTmpState, action: PayloadAction<{datas:RowData[], colModel:ItemListColModel}>) {
      console.log('setDatas');
      const datas = action.payload.datas;
      const colModel = action.payload.colModel;
      Object.assign(state, {
        datas: datas,
        //テーブル用のデータに変換
        rows: convertRows(datas, colModel),
      });
    },
    editRowDatas(state:ItemListTmpState, action: PayloadAction<{row:number, key:string, value:string|number|object|null, relatedValues?: {key:string, value:string|number|object|null}[]}[]>) {
      const newData = [...state.rows];
      action.payload.forEach((editData)=>{
        const data = newData[editData.row];
        data[editData.key] = editData.value;
        //関連データの更新
        if(editData.relatedValues) {
          editData.relatedValues.forEach(relatedValue => {
            data[relatedValue.key] = relatedValue.value;
          })
        }
      })
      state.rows = newData;
    },
    // setRowDatas(state:ItemListTmpState, action: PayloadAction<RowData[]>) {
    //   state.rows = action.payload;
    // },
    rowSelectionChange(state:ItemListTmpState, action: PayloadAction<{start:number,end:number}>){
      Object.assign(state, {
        selectionRowStart: action.payload.start,
        selectionRowEnd: action.payload.end,
      });
    },
    refreshTable(state:ItemListTmpState, action: PayloadAction<{colModel:ItemListColModel}>){
      console.log('refreshTable');
      const colModel = action.payload.colModel;
      state.rows = convertRows(state.datas, colModel);
    },
    clearFindKeyword(state) {
      Object.assign(state, {
        findKeyword: '',
        findedCellSelect: null,
        findedCells: [],
        findedCellsOver: false,
      });
    },
    setFindKeyword(state:ItemListTmpState, action: PayloadAction<string>) {
      state.findKeyword = action.payload;
    },
    setFindedCellSelect(state:ItemListTmpState, action: PayloadAction<findedCell>) {
      state.findedCellSelect = action.payload;
    },
    setFindedCells(state:ItemListTmpState, action: PayloadAction<{findedCells:findedCell[], over:boolean}>) {
      state.findedCells = action.payload.findedCells;
      state.findedCellsOver = action.payload.over;
    },
    // setDatasZaiko(state:ItemListTmpState, action: PayloadAction<RowDataItemZaiko[]>) {
    //   let datas = action.payload;
    //   Object.assign(state, {
    //     datasZaiko: datas,
    //   });
    // },
    setLastUpdate(state:ItemListTmpState, action: PayloadAction<string | null>) {
      state.lastUpdate = action.payload;
    },
    setAdjus0Imported(state:ItemListTmpState, action: PayloadAction<boolean | null>) {
      state.adjus0Imported = action.payload;
    },
    setErrorMessage(state:ItemListTmpState, action: PayloadAction<string>) {
      state.errorMessage = action.payload;
    }
}};
const createSliceContent = (name:string) => createSlice({
  name: name,
  initialState,
  reducers: createReducerContent(),
});

//数値のパース(数値が文字列で返ってくる)
export const parseData = (datas:RowData[]): RowData[] => {
  let tmp: number;
  //set No.
  datas.forEach((data) => {
    if(typeof data.IC1 === 'string') data.IC1 = parseInt(data.IC1);
    if(typeof data.IC2 === 'string') data.IC2 = parseInt(data.IC2);
    if(typeof data.ILI === 'string') data.ILI = parseInt(data.ILI);
    if(typeof data.IPR === 'string') data.IPR = parseInt(data.IPR);
    if(typeof data.LQT_PS === 'string') data.LQT_PS = (tmp = parseInt(data.LQT_PS)) ? tmp : null;
    if(typeof data.LQP_PS === 'string') data.LQP_PS = (tmp = parseInt(data.LQP_PS)) ? tmp : null;
    if(typeof data.DQT_PS === 'string') data.DQT_PS = (tmp = parseInt(data.DQT_PS)) ? tmp : null;
    if(typeof data.DQP_PS === 'string') data.DQP_PS = (tmp = parseInt(data.DQP_PS)) ? tmp : null;
    if(typeof data.JJT === 'string') data.JJT = parseInt(data.JJT);
    if(typeof data.JJP === 'string') data.JJP = parseInt(data.JJP);
    if(typeof data.JST === 'string') data.JST = parseInt(data.JST);
    if(typeof data.JSP === 'string') data.JSP = parseInt(data.JSP);
    if (typeof data.JKT === 'string') data.JKT = (tmp = parseInt(data.JKT)) ? tmp : null;
    if (typeof data.JKP === 'string') data.JKP = (tmp = parseInt(data.JKP)) ? tmp : null;
    if(typeof data.JJQT_PS === 'string') data.JJQT_PS = parseInt(data.JJQT_PS);
    if(typeof data.JJQP_PS === 'string') data.JJQP_PS = parseInt(data.JJQP_PS);
    if(typeof data.JSQT_PS === 'string') data.JSQT_PS = parseInt(data.JSQT_PS);
    if(typeof data.JSQP_PS === 'string') data.JSQP_PS = parseInt(data.JSQP_PS);
    if (typeof data.JKQT_PS === 'string') data.JKQT_PS = (tmp = parseInt(data.JKQT_PS)) ? tmp : null;
    if (typeof data.JKQP_PS === 'string') data.JKQP_PS = (tmp = parseInt(data.JKQP_PS)) ? tmp : null;
    //欠品責任別
    if (typeof data.KI1KQ_PS === 'string') data.KI1KQ_PS = (tmp = parseInt(data.KI1KQ_PS)) ? tmp : null;
    if (typeof data.KI1KA === 'string') data.KI1KA = (tmp = parseInt(data.KI1KA)) ? tmp : null;
    if (typeof data.KI2KQ_PS === 'string') data.KI2KQ_PS = (tmp = parseInt(data.KI2KQ_PS)) ? tmp : null;
    if (typeof data.KI2KA === 'string') data.KI2KA = (tmp = parseInt(data.KI2KA)) ? tmp : null;
    if (typeof data.KI3KQ_PS === 'string') data.KI3KQ_PS = (tmp = parseInt(data.KI3KQ_PS)) ? tmp : null;
    if (typeof data.KI3KA === 'string') data.KI3KA = (tmp = parseInt(data.KI3KA)) ? tmp : null;
    //欠品区分別
    if (typeof data.K10KQ_PS === 'string') data.K10KQ_PS = (tmp = parseInt(data.K10KQ_PS)) ? tmp : null;
    if (typeof data.K10KA === 'string') data.K10KA = (tmp = parseInt(data.K10KA)) ? tmp : null;
    if (typeof data.K11KQ_PS === 'string') data.K11KQ_PS = (tmp = parseInt(data.K11KQ_PS)) ? tmp : null;
    if (typeof data.K11KA === 'string') data.K11KA = (tmp = parseInt(data.K11KA)) ? tmp : null;
    if (typeof data.K12KQ_PS === 'string') data.K12KQ_PS = (tmp = parseInt(data.K12KQ_PS)) ? tmp : null;
    if (typeof data.K12KA === 'string') data.K12KA = (tmp = parseInt(data.K12KA)) ? tmp : null;
    if (typeof data.K13KQ_PS === 'string') data.K13KQ_PS = (tmp = parseInt(data.K13KQ_PS)) ? tmp : null;
    if (typeof data.K13KA === 'string') data.K13KA = (tmp = parseInt(data.K13KA)) ? tmp : null;
    if (typeof data.K14KQ_PS === 'string') data.K14KQ_PS = (tmp = parseInt(data.K14KQ_PS)) ? tmp : null;
    if (typeof data.K14KA === 'string') data.K14KA = (tmp = parseInt(data.K14KA)) ? tmp : null;
    if (typeof data.K15KQ_PS === 'string') data.K15KQ_PS = (tmp = parseInt(data.K15KQ_PS)) ? tmp : null;
    if (typeof data.K15KA === 'string') data.K15KA = (tmp = parseInt(data.K15KA)) ? tmp : null;
    if (typeof data.K16KQ_PS === 'string') data.K16KQ_PS = (tmp = parseInt(data.K16KQ_PS)) ? tmp : null;
    if (typeof data.K16KA === 'string') data.K16KA = (tmp = parseInt(data.K16KA)) ? tmp : null;
    if (typeof data.K17KQ_PS === 'string') data.K17KQ_PS = (tmp = parseInt(data.K17KQ_PS)) ? tmp : null;
    if (typeof data.K17KA === 'string') data.K17KA = (tmp = parseInt(data.K17KA)) ? tmp : null;
    if (typeof data.K19KQ_PS === 'string') data.K19KQ_PS = (tmp = parseInt(data.K19KQ_PS)) ? tmp : null;
    if (typeof data.K19KA === 'string') data.K19KA = (tmp = parseInt(data.K19KA)) ? tmp : null;
    if (typeof data.K20KQ_PS === 'string') data.K20KQ_PS = (tmp = parseInt(data.K20KQ_PS)) ? tmp : null;
    if (typeof data.K20KA === 'string') data.K20KA = (tmp = parseInt(data.K20KA)) ? tmp : null;
    if (typeof data.K21KQ_PS === 'string') data.K21KQ_PS = (tmp = parseInt(data.K21KQ_PS)) ? tmp : null;
    if (typeof data.K21KA === 'string') data.K21KA = (tmp = parseInt(data.K21KA)) ? tmp : null;
    if (typeof data.K22KQ_PS === 'string') data.K22KQ_PS = (tmp = parseInt(data.K22KQ_PS)) ? tmp : null;
    if (typeof data.K22KA === 'string') data.K22KA = (tmp = parseInt(data.K22KA)) ? tmp : null;
    if (typeof data.K29KQ_PS === 'string') data.K29KQ_PS = (tmp = parseInt(data.K29KQ_PS)) ? tmp : null;
    if (typeof data.K29KA === 'string') data.K29KA = (tmp = parseInt(data.K29KA)) ? tmp : null;
    if (typeof data.K30KQ_PS === 'string') data.K30KQ_PS = (tmp = parseInt(data.K30KQ_PS)) ? tmp : null;
    if (typeof data.K30KA === 'string') data.K30KA = (tmp = parseInt(data.K30KA)) ? tmp : null;
    if (typeof data.K31KQ_PS === 'string') data.K31KQ_PS = (tmp = parseInt(data.K31KQ_PS)) ? tmp : null;
    if (typeof data.K31KA === 'string') data.K31KA = (tmp = parseInt(data.K31KA)) ? tmp : null;
    if (typeof data.K32KQ_PS === 'string') data.K32KQ_PS = (tmp = parseInt(data.K32KQ_PS)) ? tmp : null;
    if (typeof data.K32KA === 'string') data.K32KA = (tmp = parseInt(data.K32KA)) ? tmp : null;
    if (typeof data.K33KQ_PS === 'string') data.K33KQ_PS = (tmp = parseInt(data.K33KQ_PS)) ? tmp : null;
    if (typeof data.K33KA === 'string') data.K33KA = (tmp = parseInt(data.K33KA)) ? tmp : null;
    if (typeof data.K41KQ_PS === 'string') data.K41KQ_PS = (tmp = parseInt(data.K41KQ_PS)) ? tmp : null;
    if (typeof data.K41KA === 'string') data.K41KA = (tmp = parseInt(data.K41KA)) ? tmp : null;
    if (typeof data.K34KQ_PS === 'string') data.K34KQ_PS = (tmp = parseInt(data.K34KQ_PS)) ? tmp : null;
    if (typeof data.K34KA === 'string') data.K34KA = (tmp = parseInt(data.K34KA)) ? tmp : null;
    if (typeof data.K39KQ_PS === 'string') data.K39KQ_PS = (tmp = parseInt(data.K39KQ_PS)) ? tmp : null;
    if (typeof data.K39KA === 'string') data.K39KA = (tmp = parseInt(data.K39KA)) ? tmp : null;
    if (typeof data.K40KQ_PS === 'string') data.K40KQ_PS = (tmp = parseInt(data.K40KQ_PS)) ? tmp : null;
    if (typeof data.K40KA === 'string') data.K40KA = (tmp = parseInt(data.K40KA)) ? tmp : null;
    if (typeof data.K42KQ_PS === 'string') data.K42KQ_PS = (tmp = parseInt(data.K42KQ_PS)) ? tmp : null;
    if (typeof data.K42KA === 'string') data.K42KA = (tmp = parseInt(data.K42KA)) ? tmp : null;
    if (typeof data.K43KQ_PS === 'string') data.K43KQ_PS = (tmp = parseInt(data.K43KQ_PS)) ? tmp : null;
    if (typeof data.K43KA === 'string') data.K43KA = (tmp = parseInt(data.K43KA)) ? tmp : null;
    if (typeof data.K44KQ_PS === 'string') data.K44KQ_PS = (tmp = parseInt(data.K44KQ_PS)) ? tmp : null;
    if (typeof data.K44KA === 'string') data.K44KA = (tmp = parseInt(data.K44KA)) ? tmp : null;
    if (typeof data.K49KQ_PS === 'string') data.K49KQ_PS = (tmp = parseInt(data.K49KQ_PS)) ? tmp : null;
    if (typeof data.K49KA === 'string') data.K49KA = (tmp = parseInt(data.K49KA)) ? tmp : null;
    if (typeof data.K50KQ_PS === 'string') data.K50KQ_PS = (tmp = parseInt(data.K50KQ_PS)) ? tmp : null;
    if (typeof data.K50KA === 'string') data.K50KA = (tmp = parseInt(data.K50KA)) ? tmp : null;

    if(typeof data.LQT_PS === 'string') data.LQT_PS = parseInt(data.LQT_PS);
    if(typeof data.LQP_PS === 'string') data.LQP_PS = parseInt(data.LQP_PS);
    if(typeof data.DQT_PS === 'string') data.DQT_PS = parseInt(data.DQT_PS);
    if(typeof data.DQP_PS === 'string') data.DQP_PS = parseInt(data.DQP_PS);
    if(typeof data.OQT === 'string') data.OQT = parseInt(data.OQT);
    if(typeof data.OQP === 'string') data.OQP = parseInt(data.OQP);
    if(typeof data.ULT_PS === 'string') data.ULT_PS = (tmp = parseInt(data.ULT_PS)) ? tmp : null;
    if(typeof data.ULP_PS === 'string') data.ULP_PS = (tmp = parseInt(data.ULP_PS)) ? tmp : null;
    if(typeof data.UDT_PS === 'string') data.UDT_PS = (tmp = parseInt(data.UDT_PS)) ? tmp : null;
    if(typeof data.UDP_PS === 'string') data.UDP_PS = (tmp = parseInt(data.UDP_PS)) ? tmp : null;
    if(typeof data.UPT === 'string') data.UPT = parseInt(data.UPT);
    if(typeof data.UPP === 'string') data.UPP = parseInt(data.UPP);
    if(typeof data.SQT_PS === 'string') data.SQT_PS = (tmp = parseInt(data.SQT_PS)) ? tmp : null;
    if(typeof data.SQP_PS === 'string') data.SQP_PS = (tmp = parseInt(data.SQP_PS)) ? tmp : null;
    if(typeof data.RQT_PS === 'string') data.RQT_PS = (tmp = parseInt(data.RQT_PS)) ? tmp : null;
    if(typeof data.RQP_PS === 'string') data.RQP_PS = (tmp = parseInt(data.RQP_PS)) ? tmp : null;
    if(typeof data.WQT_PS === 'string') data.WQT_PS = (tmp = parseInt(data.WQT_PS)) ? tmp : null;
    if(typeof data.WQP_PS === 'string') data.WQP_PS = (tmp = parseInt(data.WQP_PS)) ? tmp : null;
    if(typeof data.SLT === 'string') data.SLT = parseInt(data.SLT);
    if(typeof data.SLP === 'string') data.SLP = parseInt(data.SLP);
    if(typeof data.SDT === 'string') data.SDT = parseInt(data.SDT);
    if(typeof data.SDP === 'string') data.SDP = parseInt(data.SDP);
    if(typeof data.SRT === 'string') data.SRT = parseInt(data.SRT);
    if(typeof data.SRP === 'string') data.SRP = parseInt(data.SRP);
    if(typeof data.PTT === 'string') data.PTT = parseInt(data.PTT);
    if(typeof data.PTP === 'string') data.PTP = parseInt(data.PTP);
    if(typeof data.CIT === 'string') data.CIT = parseInt(data.CIT);
    if(typeof data.CIP === 'string') data.CIP = parseInt(data.CIP);
    if(typeof data.CST === 'string') data.CST = parseInt(data.CST);
    if(typeof data.CSP === 'string') data.CSP = parseInt(data.CSP);
    if(typeof data.BTT === 'string') data.BTT = parseInt(data.BTT);
    if(typeof data.BTP === 'string') data.BTP = parseInt(data.BTP);
    if(typeof data.STT === 'string') data.STT = parseInt(data.STT);
    if(typeof data.STP === 'string') data.STP = parseInt(data.STP);
    if(typeof data.ATT === 'string') data.ATT = parseInt(data.ATT);
    if(typeof data.ATP === 'string') data.ATP = parseInt(data.ATP);
    if(typeof data.RQTY_PS === 'string') data.RQTY_PS = parseInt(data.RQTY_PS);
    if(typeof data.TQTY_PS === 'string') data.TQTY_PS = parseInt(data.TQTY_PS);
    if(typeof data.YQTY_PS === 'string') data.YQTY_PS = parseInt(data.YQTY_PS);
    if(typeof data.FQTY_PS === 'string') data.FQTY_PS = parseInt(data.FQTY_PS);
    if(typeof data.AZKE_PS === 'string') data.AZKE_PS = parseInt(data.AZKE_PS);
    if(typeof data.SQTC_PS === 'string') data.SQTC_PS = parseInt(data.SQTC_PS);
    if(typeof data.SQTN_PS === 'string') data.SQTN_PS = parseInt(data.SQTN_PS);
    if(typeof data.SQTCTKC === 'string') data.SQTCTKC = (tmp = parseInt(data.SQTCTKC)) ? tmp : null;
    if(typeof data.SQTNTKC === 'string') data.SQTNTKC = (tmp = parseInt(data.SQTNTKC)) ? tmp : null;
    if (typeof data.TGTL === 'string') data.TGTL = parseInt(data.TGTL);
    if (typeof data.SGTL === 'string') data.SGTL = parseInt(data.SGTL);

    if(typeof data.HTD === 'string') data.HTD = moment(data.HTD);
    data.KTB = data.TGTL > 0 ? '定番' : '定番外';

    if(typeof data.SHCT === 'string') data.SHCT = parseInt(data.SHCT);
    if(typeof data.SHCP === 'string') data.SHCP = parseInt(data.SHCP);
    if(typeof data.SHDT === 'string') data.SHDT = parseInt(data.SHDT);
    if(typeof data.SHDP === 'string') data.SHDP = parseInt(data.SHDP);
    if(typeof data.SHRCT === 'string') data.SHRCT = parseInt(data.SHRCT);
    if(typeof data.SHRCP === 'string') data.SHRCP = parseInt(data.SHRCP);
    if(typeof data.SHRDT === 'string') data.SHRDT = parseInt(data.SHRDT);
    if(typeof data.SHRDP === 'string') data.SHRDP = parseInt(data.SHRDP);
    if(typeof data.SHT === 'string') data.SHT = parseInt(data.SHT);
    if(typeof data.SHP === 'string') data.SHP = parseInt(data.SHP);    
  });
  return datas;
}
//合計行作成
const calcTotals = (datas: RowData[], teiban: string[]): RowData[] => {
  const total:RowData = {
    TP: "total",
  };
  const totailTeiban: RowData = { TP: "teiban", };
  const totailTeibangai: RowData = { TP: "teibangai", };

  //set No.
  datas.forEach((data) => {
    if(data.TP == "item") {
      calcTotalSub(total, data);
      if (teiban.length === 2) {
        calcTotalSub(totailTeiban, data, '定番');
        calcTotalSub(totailTeibangai, data, '定番外');
      }
    }
  });

  if (teiban.length === 2) {
    if (datas.length > 1 && datas[1].TP == "teibangai") {
      datas[1] = totailTeibangai;
    }
    else if (datas.length > 2 && datas[2].TP == "teibangai") {
      datas[2] = totailTeibangai;
    } else {
      datas = [totailTeibangai, ...datas];
    }
    if (datas.length > 1 && datas[1].TP == "teiban") {
      datas[1] = totailTeiban;
    }
    else if (datas.length > 2 && datas[2].TP == "teiban") {
      datas[2] = totailTeiban;
    } else {
      datas = [totailTeiban, ...datas];
    }
  }
  if(datas.length > 0 && datas[0].TP == "total") {
    datas[0] = total;
  }
  else {
    datas = [total, ...datas];
  }

  return datas;
}


const makeDatas = (datas: RowData[], sort: { key: string, asc: boolean }, 
  grouping1: 'item'|'shiire'|'maker'|'shiten'|'center'|'group2'|'group1'|'tokuisaki'|'category'|'pb'|string, 
  grouping2: 'item'|'shiire'|'maker'|'shiten'|'center'|'group2'|'group1'|'tokuisaki'|'category'|'pb'|string,
  grouping3: 'item'|'shiire'|'maker'|'shiten'|'center'|'group2'|'group1'|'tokuisaki'|'category'|'pb'|string,
  grouping4: 'item'|'shiire'|'maker'|'shiten'|'center'|'group2'|'group1'|'tokuisaki'|'category'|'pb'|string,
  grouping5: 'item'|'shiire'|'maker'|'shiten'|'center'|'group2'|'group1'|'tokuisaki'|'category'|'pb'|string,
  groupingOnly: boolean,
  keppinOnly: boolean,
  selectedTags: TagData[], tagItems: TagItemData[], selectedRanks: RankData[], isRank: boolean, pbKind: string, calcAll: boolean, ym: string, teiban: string[]): RowData[] => {

  //タグによる絞り込み
  if(selectedTags && selectedTags.length > 0) {
    const tagItemsIID = tagItems.filter(tagItem => selectedTags.some(tag => tag.tag_id == tagItem.tag_id)).map(tagItem => tagItem.IID);
    datas = datas.filter(data => data.TP != 'item' || tagItemsIID.indexOf(data.IID) !== -1);
  }

  datas = calcTotals(datas, teiban); //合計行の作成
  const totalSLT = datas.find(data => data.TP == "total").SLT;
  const totalTeibanSLT = datas.find(data => data.TP == "teiban")?.SLT;
  const totalTeibangaiSLT = datas.find(data => data.TP == "teibangai")?.SLT;

  const doGrouping = !(!grouping1);
  if(doGrouping) {
    datas = calcGrouping(datas, grouping1, grouping2, grouping3, grouping4, grouping5, groupingOnly); //メーカー行の作成
    if(!calcAll) {
      datas = calcDatas(datas, "grouping1", totalSLT, totalTeibanSLT, totalTeibangaiSLT); //計算項目の計算
      datas = calcDatas(datas, "grouping2", totalSLT, totalTeibanSLT, totalTeibangaiSLT); //計算項目の計算
      datas = calcDatas(datas, "grouping3", totalSLT, totalTeibanSLT, totalTeibangaiSLT); //計算項目の計算
      datas = calcDatas(datas, "grouping4", totalSLT, totalTeibanSLT, totalTeibangaiSLT); //計算項目の計算
      datas = calcDatas(datas, "grouping5", totalSLT, totalTeibanSLT, totalTeibangaiSLT); //計算項目の計算
    }
  }
  else {
    datas = datas.filter(data => data.TP != "grouping1"); //メーカー行の削除
    datas = datas.filter(data => data.TP != "grouping2"); //センター行の削除
    datas = datas.filter(data => data.TP != "grouping3"); //センター行の削除
    datas = datas.filter(data => data.TP != "grouping4"); //センター行の削除
    datas = datas.filter(data => data.TP != "grouping5"); //センター行の削除
  }

  if(calcAll) {
    datas = calcDatas(datas, "all", totalSLT, totalTeibanSLT, totalTeibangaiSLT); //計算項目の計算
    if (teiban.length === 2) {
      const total = datas.find(data => data.TP == "total");
      total.KUR = null;
    }
  }

  //BL数の集計
  const total = datas.find(data => data.TP == "total");
  const totalTeiban = datas.find(data => data.TP == "teiban");
  const totalTeibangai = datas.find(data => data.TP == "teibangai");
  datas.forEach((data) => {
    if (data.TP == "item") {
      calcTotalSubPS(total, data, teiban);
      if (teiban.length === 2) {
        calcTotalSubPS(totalTeiban, data, teiban, '定番');
        calcTotalSubPS(totalTeibangai, data, teiban, '定番外');
      }
    }
  });
  calcDatas([total], "total", totalSLT, totalTeibanSLT, totalTeibangaiSLT); //計算項目の計算

  if(doGrouping) {
    const grouping1s = datas.filter(data => data.TP == "grouping1");
    grouping1s.forEach(target => {
      const itemDatas = datas.filter(data => data.TP == "item" && getGroupingCD(grouping1, target) == getGroupingCD(grouping1, data));
      itemDatas.forEach((data) => {
        calcTotalSubPS(target, data, teiban);
      });
    });
    calcDatas(grouping1s, "grouping1", totalSLT, totalTeibanSLT, totalTeibangaiSLT); //計算項目の計算

    const grouping2s = datas.filter(data => data.TP == "grouping2");
    grouping2s.forEach(target => {
      const itemDatas = datas.filter(data => data.TP == "item" && getGroupingCD(grouping1, target) == getGroupingCD(grouping1, data) && getGroupingCD(grouping2, target) == getGroupingCD(grouping2, data));
      itemDatas.forEach((data) => {
        calcTotalSubPS(target, data, teiban);
      });
    });
    calcDatas(grouping2s, "grouping2", totalSLT, totalTeibanSLT, totalTeibangaiSLT); //計算項目の計算

    const grouping3s = datas.filter(data => data.TP == "grouping3");
    grouping3s.forEach(target => {
      const itemDatas = datas.filter(data => data.TP == "item" && getGroupingCD(grouping1, target) == getGroupingCD(grouping1, data) && getGroupingCD(grouping2, target) == getGroupingCD(grouping2, data) && getGroupingCD(grouping3, target) == getGroupingCD(grouping3, data));
      itemDatas.forEach((data) => {
        calcTotalSubPS(target, data, teiban);
      });
    });
    calcDatas(grouping3s, "grouping3", totalSLT, totalTeibanSLT, totalTeibangaiSLT); //計算項目の計算

    const grouping4s = datas.filter(data => data.TP == "grouping4");
    grouping4s.forEach(target => {
      const itemDatas = datas.filter(data => data.TP == "item" && getGroupingCD(grouping1, target) == getGroupingCD(grouping1, data) && getGroupingCD(grouping2, target) == getGroupingCD(grouping2, data) && getGroupingCD(grouping3, target) == getGroupingCD(grouping3, data) && getGroupingCD(grouping4, target) == getGroupingCD(grouping4, data));
      itemDatas.forEach((data) => {
        calcTotalSubPS(target, data, teiban);
      });
    });
    calcDatas(grouping4s, "grouping4", totalSLT, totalTeibanSLT, totalTeibangaiSLT); //計算項目の計算

    const grouping5s = datas.filter(data => data.TP == "grouping5");
    grouping5s.forEach(target => {
      const itemDatas = datas.filter(data => data.TP == "item" && getGroupingCD(grouping1, target) == getGroupingCD(grouping1, data) && getGroupingCD(grouping2, target) == getGroupingCD(grouping2, data) && getGroupingCD(grouping3, target) == getGroupingCD(grouping3, data) && getGroupingCD(grouping4, target) == getGroupingCD(grouping4, data) && getGroupingCD(grouping5, target) == getGroupingCD(grouping5, data));
      itemDatas.forEach((data) => {
        calcTotalSubPS(target, data, teiban);
      });
    });
    calcDatas(grouping5s, "grouping5", totalSLT, totalTeibanSLT, totalTeibangaiSLT); //計算項目の計算

  }

  if (isRank) {
    //配送売上本年実績の降順でソートして計算する
    datas = doSort(datas, "SLT", false, grouping1, grouping2, grouping3, grouping4, grouping5, groupingOnly);
    const rankC3 = cutRankDatas.find(rank => rank[0] == "Cɤ");
    const rankC3Bl = typeof rankC3[4] == 'number' ? rankC3[4] : initialStateReportCut.rankC3Bl;
    const baseDate = moment(ym, 'YYYYMM').startOf('day');
    const setRand = (key: string) => {
      let sumKUR = 0;
      datas.filter(data => key == data.KTB).forEach((data) => {
        if (data.TP == "item") {
          sumKUR = calcUtil.plus(sumKUR, data.KUR);
          data.KTR = sumKUR;

          const par = sumKUR * 100;
          // if (data.orgKTB === '新規定番') {
          //   data.KRK = 'Zn';
          // } else
          if (data.SLT <= 100) {
            if (data.HTD && baseDate.diff(data.HTD, 'day') <= 0) {
              data.KRK = 'Zn';
            } else {
              data.KRK = 'ZX';
            }
          } else if (par >= 90) {
            if (data.SQTC <= rankC3Bl && data.SQTCTKC == 1) {
              data.KRK = 'Cɤ';
            } else {
              data.KRK = 'Cα';
            }
          } else if (par >= 70) {
            data.KRK = 'B';
          } else if (par >= 40) {
            data.KRK = 'A';
          } else {
            data.KRK = 'S';
          }
        }
      });
    }
    setRand('定番');
    setRand('定番外');
    const itemCnt = datas.filter(data => data.TP == 'item').length;
    cutRankDatas.map(rankdata  => {
      let cnt = datas.filter(data => data.TP == 'item' && data.KRK == rankdata[0]).length;
      if (rankdata[0] == "C") {
        cnt = datas.filter(data => data.TP == 'item' && ["Cα", "Cɤ"].indexOf(data.KRK) !== -1).length;
      } else if (rankdata[0] == "Z") {
        cnt = datas.filter(data => data.TP == 'item' && ["Zn", "ZX"].indexOf(data.KRK) !== -1).length;
      }
      rankdata[1] = itemCnt > 0 ? cnt : null;
      rankdata[2] = itemCnt > 0 ? itemCnt : null;
      rankdata[3] = calcUtil.divide(cnt, itemCnt);
    });

    //ランクによる絞り込み、選択時のみ
    if (selectedRanks.length > 0) {
      const ranks = selectedRanks?.map(rank => rank.rank_name);
      datas = datas.filter(data => data.TP != 'item' || ranks.indexOf(data.KRK) !== -1);
    }
    //PB区分によるしぼりこみ
    if (pbKind) {
      datas = pbKind == 'pb' ?
        datas.filter(data => data.TP != 'item' || data.IBN?.length > 0) :
        datas.filter(data => data.TP != 'item' || !data.IBN);
    }
  }

  if(groupingOnly) {
    datas = datas.filter(data => data.TP != "item");
  }
  if(keppinOnly) {
    datas = datas.filter(data => data.JKT);
  }

  return datas;
};
export const makeDatasTeiban = (state: ItemListTmpState, datas: RowData[], datasAll: RowData[], teiban: string[]): RowData[] => {
  const getGroup1s = (key1: string): CodeName[] => {
    let group1s: CodeName[] = [];
    datasAll.forEach((data, index1) => {
      const codeNames = data[key1] ? data[key1].split(', ') : [];
      codeNames.forEach((codeName, index2) => {
        const cn = codeName.split('_');
        group1s.push(new CodeName({ code: cn[0], name: cn[1] }));
      });
    });
    group1s = group1s
      .filter((value, index, self) => self.findIndex(cn => cn.code == value.code) === index)
      .sort((a, b) => compareUtil.compareAny(a.code, b.code, true));
    return group1s;
  }

  const teibanGroup1s = getGroup1s('TGCN');
  const shukkaGroup1s = getGroup1s('SGCN');

  state.teibanGroup1s = teibanGroup1s;
  state.shukkaGroup1s = shukkaGroup1s;
  //列に展開
  const now = moment().startOf('month');
  datas.forEach((data, index1) => {
    {//定番
      const codeNameDates = !data['TGCN'] ?
        [] :
        data['TGCN'].split(', ')
          .map(codeNameDate => {
            const cnd = codeNameDate.split('_');
            return { code: cnd[0], date: cnd[2] };
          });
      teibanGroup1s.forEach((group1, index2) => {
        const num = ('0' + (index2 + 1)).slice(-2);
        const teibanKey = `TG${num}`;
        const startKey = `TG${num}D`;
        const cnd = codeNameDates.find(codeNameDate => codeNameDate.code == group1.code);
        datas[index1][teibanKey] = cnd ? '〇' : '';

        const startDate = moment(cnd?.date,'YYYY-MM-DD').startOf('day');
        const day = cnd?.date ? startDate.diff(now, 'day') : -1; //発売日まで後何日?

        datas[index1][startKey] = day > 0 ? startDate.format('YYYY/MM/DD') : null;
      });
    }
    {//出荷
      const codeNamePSs = !data['SGCN'] ?
        [] :
        data['SGCN'].split(', ')
          .map(codeNamePS => {
            const cnp = codeNamePS.split('_');
            return { code: cnp[0], ps: parseInt(cnp[2]) };
          });
      shukkaGroup1s.forEach((group1, index2) => {
        const num = ('0' + (index2 + 1)).slice(-2);
        const ps = codeNamePSs.find(codeNamePSs => codeNamePSs.code == group1.code)?.ps ?? null;
        datas[index1][`SG${num}`] = ps && data.IC1 ? (Math.floor(ps / data.IC1 * 10) / 10) : null;
      });
    }
  });
  //定番数カウント
  const getNum = (val: number | string): number => {
    if (typeof val === 'string') {
      return val ? parseInt(val) : 0;
    }
    return val;
  }

  const total = datas.find(data => data.TP == "total");
  const totalTeiban = datas.find(data => data.TP == "teiban") ?? datas.find(data => data.TP == "total");
  const totalTeibangai = datas.find(data => data.TP == "teibangai") ?? datas.find(data => data.TP == "total");
  datasAll.forEach((data, index) => {
    if (data.TP == "item") {
      teibanGroup1s.forEach((group1, index2) => {
        const num = ('0' + (index2 + 1)).slice(-2);
        const teibanKey = `TG${num}`;
        if (teiban.indexOf('teiban') !== -1) {
          totalTeiban[teibanKey] = calcUtil.plus(getNum(totalTeiban[teibanKey]), data[teibanKey] === '〇' ? 1 : 0);
        }
        if (teiban.indexOf('teibangai') !== -1) {
          totalTeibangai[teibanKey] = calcUtil.plus(getNum(totalTeibangai[teibanKey]), data[teibanKey] === '〇' ? 0 : 1);
        }
        if (teiban.length === 2) {
          total[teibanKey] = totalTeiban[teibanKey] + totalTeibangai[teibanKey];
        }
      });
      shukkaGroup1s.forEach((group1, index2) => {
        const num = ('0' + (index2 + 1)).slice(-2);
        const shukkaKey = `SG${num}`;
        const tindex = teibanGroup1s.findIndex(teibanGroup1 => teibanGroup1.code == group1.code);
        const teibanKey = tindex >= 0 ? `TG${('0' + (tindex + 1)).slice(-2)}` : null;

        if (teiban.indexOf('teiban') !== -1 && data[teibanKey] === '〇') {
          totalTeiban[shukkaKey] = calcUtil.plus(getNum(totalTeiban[shukkaKey]), data[shukkaKey]);
        }
        if (teiban.indexOf('teibangai') !== -1 && data[teibanKey] !== '〇') {
          totalTeibangai[shukkaKey] = calcUtil.plus(getNum(totalTeibangai[shukkaKey]), data[shukkaKey]);
        }
        if (teiban.length === 2) {
          total[shukkaKey] = totalTeiban[shukkaKey] + totalTeibangai[shukkaKey];
        }
      });
    }
  });

  return datas;
}


//メーカー行作成
const calcGrouping = (datas:RowData[], 
  grouping1: 'item'|'shiire'|'maker'|'shiten'|'center'|'group2'|'group1'|'tokuisaki'|'category'|'pb'|string, 
  grouping2: 'item'|'shiire'|'maker'|'shiten'|'center'|'group2'|'group1'|'tokuisaki'|'category'|'pb'|string,
  grouping3: 'item'|'shiire'|'maker'|'shiten'|'center'|'group2'|'group1'|'tokuisaki'|'category'|'pb'|string,
  grouping4: 'item'|'shiire'|'maker'|'shiten'|'center'|'group2'|'group1'|'tokuisaki'|'category'|'pb'|string,
  grouping5: 'item'|'shiire'|'maker'|'shiten'|'center'|'group2'|'group1'|'tokuisaki'|'category'|'pb'|string,
  groupingOnly: boolean
): RowData[] => {
  if(!grouping1) {
    return;
  }

  const fncMakeGroupData = (
    data:RowData,
    TP: "total" | "grouping1" | "grouping2" | "grouping3" | "grouping4" | "grouping5" | "item",
    groupingDiv: 'item'|'shiire'|'maker'|'shiten'|'center'|'group2'|'group1'|'tokuisaki'|'category'|'pb'|string,
    groupingMap,
    GCD_parent:string,
    groupingDataParent,
    ):[string, RowData] => {
    const GCD = (GCD_parent ? (GCD_parent + '_') : '') + getGroupingCD(groupingDiv, data);
    let groupingData: RowData = groupingMap[GCD];
    if (!groupingData) {
      groupingData = {
        // ...groupingDataParent,
        ...{
          TP: TP,
  
          IID: groupingDiv != 'item' ? (groupingDataParent ? groupingDataParent.IID : '') : data.IID,
          ISC: groupingDiv != 'item' && groupingDiv != 'shiire' ? (groupingDataParent ? groupingDataParent.ISC : '') : data.ISC,
          ISN: groupingDiv != 'item' && groupingDiv != 'shiire' ? (groupingDataParent ? groupingDataParent.ISN : '') : data.ISN,
          IMC: groupingDiv != 'item' && groupingDiv != 'maker' ? (groupingDataParent ? groupingDataParent.IMC : '') : data.IMC,
          IMN: groupingDiv != 'item' && groupingDiv != 'maker' ? (groupingDataParent ? groupingDataParent.IMN : '') : data.IMN,
          INM: groupingDiv != 'item' ? (groupingDataParent ? groupingDataParent.INM : '') : data.INM,
          ICC: groupingDiv != 'item' && groupingDiv != 'category' ? (groupingDataParent ? groupingDataParent.ICC : '') : data.ICC,
          ICN: groupingDiv != 'item' && groupingDiv != 'category' ? (groupingDataParent ? groupingDataParent.ICN : '') : data.ICN,
          IPC: groupingDiv != 'item' ? (groupingDataParent ? groupingDataParent.IPC : '') : data.IPC,
          IPN: groupingDiv != 'item' ? (groupingDataParent ? groupingDataParent.IPN : '') : data.IPN,
          IJN: groupingDiv != 'item' ? (groupingDataParent ? groupingDataParent.IJN : '') : data.IJN,
          ICD: groupingDiv != 'item' ? (groupingDataParent ? groupingDataParent.ICD : '') : data.ICD,
          ICP: groupingDiv != 'item' ? (groupingDataParent ? groupingDataParent.ICP : '') : data.ICP,
          IC1: groupingDiv != 'item' ? (groupingDataParent ? groupingDataParent.IC1 : null) : data.IC1,
          IC2: groupingDiv != 'item' ? (groupingDataParent ? groupingDataParent.IC2 : null) : data.IC2,
          ILI: groupingDiv != 'item' ? (groupingDataParent ? groupingDataParent.ILI : null) : data.ILI,
          IPR: groupingDiv != 'item' ? (groupingDataParent ? groupingDataParent.IPR : null) : data.IPR,
          IBC: groupingDiv != 'item' && groupingDiv != 'pb' ? (groupingDataParent ? groupingDataParent.IBC : '') : data.IBC,
          IBN: groupingDiv != 'item' && groupingDiv != 'pb' ? (groupingDataParent ? groupingDataParent.IBN : '') : data.IBN,
  
          MKCD: groupingDiv != 'maker' ? (groupingDataParent ? groupingDataParent.MKCD : '') : data.MKCD,
  
          SCD: groupingDiv != 'shiten' ? (groupingDataParent ? groupingDataParent.SCD : '') : data.SCD,
          SNM: groupingDiv != 'shiten' ? (groupingDataParent ? groupingDataParent.SNM : '') : data.SNM,
  
          CCD: groupingDiv != 'center' ? (groupingDataParent ? groupingDataParent.CCD : '') : data.CCD,
          CNM: groupingDiv != 'center' ? (groupingDataParent ? groupingDataParent.CNM : '') : data.CNM,
  
          G2CD: groupingDiv != 'group2' ? (groupingDataParent ? groupingDataParent.G2CD : '') : data.G2CD,
          G2NM: groupingDiv != 'group2' ? (groupingDataParent ? groupingDataParent.G2NM : '') : data.G2NM,
  
          G1CD: groupingDiv != 'group1' ? (groupingDataParent ? groupingDataParent.G1CD : '') : data.G1CD,
          G1NM: groupingDiv != 'group1' ? (groupingDataParent ? groupingDataParent.G1NM : '') : data.G1NM,
  
          TCD: groupingDiv != 'tokuisaki' ? (groupingDataParent ? groupingDataParent.TCD : '') : data.TCD,
          TNM: groupingDiv != 'tokuisaki' ? (groupingDataParent ? groupingDataParent.TNM : '') : data.TNM,
        }
      };
      groupingMap[GCD] = groupingData;
    }
    calcTotalSub(groupingData, data);

    return [GCD, groupingData];
  };



  const grouping1Map = {};
  const grouping2Map = {};
  const grouping3Map = {};
  const grouping4Map = {};
  const grouping5Map = {};
  datas = datas.filter(data => data.TP != "grouping1"); //メーカー行の削除
  datas = datas.filter(data => data.TP != "grouping2"); //センター行の削除
  datas = datas.filter(data => data.TP != "grouping3"); //センター行の削除
  datas = datas.filter(data => data.TP != "grouping4"); //センター行の削除
  datas = datas.filter(data => data.TP != "grouping5"); //センター行の削除

  //set No.
  const hasItemSubTotal = [grouping1,grouping2,grouping3,grouping4,grouping5,].indexOf('item') != -1;
  const subToralLevel = (grouping5 ? 5 : grouping4 ? 4 : grouping3 ? 3 : grouping2 ? 2 : grouping1 ? 1 : 0) - (hasItemSubTotal ? 1 : 0);

  datas.forEach((data) => {
    if(data.TP == "item") {
      let [GCD1, grouping1Data] = fncMakeGroupData(data, "grouping1", grouping1, grouping1Map, null, {});

      //小計もしくは、内訳で次の階層がある
      if (grouping2 && subToralLevel>= 2) {
        let [GCD2, grouping2Data] = fncMakeGroupData(data, "grouping2", grouping2, grouping2Map, GCD1, grouping1Data);

        if (grouping3 && subToralLevel>= 3) {
          let [GCD3, grouping3Data] = fncMakeGroupData(data, "grouping3", grouping3, grouping3Map, GCD2, grouping2Data);

          if (grouping4 && subToralLevel>= 4) {
            let [GCD4, grouping4Data] = fncMakeGroupData(data, "grouping4", grouping4, grouping4Map, GCD3, grouping3Data);

            if (grouping5 && subToralLevel>= 5) {
              let [GCD5, grouping5Data] = fncMakeGroupData(data, "grouping5", grouping5, grouping5Map, GCD4, grouping4Data);
            }
          }
        }
      }
    }
  });

  // if(datas.length > 0 && datas[0].TP == "total") {
  //   datas[0] = total;
  // }
  //あとのソートのために、makerは上に入れる。
  const newDatas = [];
  Object.keys(grouping1Map).forEach(GCD1 => newDatas.push(grouping1Map[GCD1]));
  if (grouping2) {
    Object.keys(grouping2Map).forEach(GCD2 => newDatas.push(grouping2Map[GCD2]));
  }
  if (grouping3) {
    Object.keys(grouping3Map).forEach(GCD3 => newDatas.push(grouping3Map[GCD3]));
  }
  if (grouping4) {
    Object.keys(grouping4Map).forEach(GCD4 => newDatas.push(grouping4Map[GCD4]));
  }
  if (grouping5) {
    Object.keys(grouping5Map).forEach(GCD5 => newDatas.push(grouping5Map[GCD5]));
  }
  return [...newDatas, ...datas];
}

//メーカー行作成
export const calcTotalSub = (total: RowData, data: RowData, key?: string): RowData => {
      if (key && key != data.KTB) {
        return total;
      }
      //以下に貼り付け
      total.JJT = calcUtil.plus(total.JJT, data.JJT);
      total.JJP = calcUtil.plus(total.JJP, data.JJP);
      total.JST = calcUtil.plus(total.JST, data.JST);
      total.JSP = calcUtil.plus(total.JSP, data.JSP);
      total.JKT = calcUtil.plus(total.JKT, data.JKT);
      total.JKP = calcUtil.plus(total.JKP, data.JKP);
      total.JJQT_PS = calcUtil.plus(total.JJQT_PS, data.JJQT_PS);
      total.JJQP_PS = calcUtil.plus(total.JJQP_PS, data.JJQP_PS);
      total.JSQT_PS = calcUtil.plus(total.JSQT_PS, data.JSQT_PS);
      total.JSQP_PS = calcUtil.plus(total.JSQP_PS, data.JSQP_PS);
      total.JKQT_PS = calcUtil.plus(total.JKQT_PS, data.JKQT_PS);
      total.JKQP_PS = calcUtil.plus(total.JKQP_PS, data.JKQP_PS);

      //欠品責任別
      total.KI1KQ_PS = calcUtil.plus(total.KI1KQ_PS, data.KI1KQ_PS);
      total.KI1KA = calcUtil.plus(total.KI1KA, data.KI1KA);
      total.KI2KQ_PS = calcUtil.plus(total.KI2KQ_PS, data.KI2KQ_PS);
      total.KI2KA = calcUtil.plus(total.KI2KA, data.KI2KA);
      total.KI3KQ_PS = calcUtil.plus(total.KI3KQ_PS, data.KI3KQ_PS);
      total.KI3KA = calcUtil.plus(total.KI3KA, data.KI3KA);
      //欠品区分別
      total.K10KQ_PS = calcUtil.plus(total.K10KQ_PS, data.K10KQ_PS);
      total.K10KA = calcUtil.plus(total.K10KA, data.K10KA);
      total.K11KQ_PS = calcUtil.plus(total.K11KQ_PS, data.K11KQ_PS);
      total.K11KA = calcUtil.plus(total.K11KA, data.K11KA);
      total.K12KQ_PS = calcUtil.plus(total.K12KQ_PS, data.K12KQ_PS);
      total.K12KA = calcUtil.plus(total.K12KA, data.K12KA);
      total.K13KQ_PS = calcUtil.plus(total.K13KQ_PS, data.K13KQ_PS);
      total.K13KA = calcUtil.plus(total.K13KA, data.K13KA);
      total.K14KQ_PS = calcUtil.plus(total.K14KQ_PS, data.K14KQ_PS);
      total.K14KA = calcUtil.plus(total.K14KA, data.K14KA);
      total.K15KQ_PS = calcUtil.plus(total.K15KQ_PS, data.K15KQ_PS);
      total.K15KA = calcUtil.plus(total.K15KA, data.K15KA);
      total.K16KQ_PS = calcUtil.plus(total.K16KQ_PS, data.K16KQ_PS);
      total.K16KA = calcUtil.plus(total.K16KA, data.K16KA);
      total.K17KQ_PS = calcUtil.plus(total.K17KQ_PS, data.K17KQ_PS);
      total.K17KA = calcUtil.plus(total.K17KA, data.K17KA);
      total.K19KQ_PS = calcUtil.plus(total.K19KQ_PS, data.K19KQ_PS);
      total.K19KA = calcUtil.plus(total.K19KA, data.K19KA);
      total.K20KQ_PS = calcUtil.plus(total.K20KQ_PS, data.K20KQ_PS);
      total.K20KA = calcUtil.plus(total.K20KA, data.K20KA);
      total.K21KQ_PS = calcUtil.plus(total.K21KQ_PS, data.K21KQ_PS);
      total.K21KA = calcUtil.plus(total.K21KA, data.K21KA);
      total.K22KQ_PS = calcUtil.plus(total.K22KQ_PS, data.K22KQ_PS);
      total.K22KA = calcUtil.plus(total.K22KA, data.K22KA);
      total.K29KQ_PS = calcUtil.plus(total.K29KQ_PS, data.K29KQ_PS);
      total.K29KA = calcUtil.plus(total.K29KA, data.K29KA);
      total.K30KQ_PS = calcUtil.plus(total.K30KQ_PS, data.K30KQ_PS);
      total.K30KA = calcUtil.plus(total.K30KA, data.K30KA);
      total.K31KQ_PS = calcUtil.plus(total.K31KQ_PS, data.K31KQ_PS);
      total.K31KA = calcUtil.plus(total.K31KA, data.K31KA);
      total.K32KQ_PS = calcUtil.plus(total.K32KQ_PS, data.K32KQ_PS);
      total.K32KA = calcUtil.plus(total.K32KA, data.K32KA);
      total.K33KQ_PS = calcUtil.plus(total.K33KQ_PS, data.K33KQ_PS);
      total.K33KA = calcUtil.plus(total.K33KA, data.K33KA);
      total.K41KQ_PS = calcUtil.plus(total.K41KQ_PS, data.K41KQ_PS);
      total.K41KA = calcUtil.plus(total.K41KA, data.K41KA);
      total.K34KQ_PS = calcUtil.plus(total.K34KQ_PS, data.K34KQ_PS);
      total.K34KA = calcUtil.plus(total.K34KA, data.K34KA);
      total.K39KQ_PS = calcUtil.plus(total.K39KQ_PS, data.K39KQ_PS);
      total.K39KA = calcUtil.plus(total.K39KA, data.K39KA);
      total.K40KQ_PS = calcUtil.plus(total.K40KQ_PS, data.K40KQ_PS);
      total.K40KA = calcUtil.plus(total.K40KA, data.K40KA);
      total.K42KQ_PS = calcUtil.plus(total.K42KQ_PS, data.K42KQ_PS);
      total.K42KA = calcUtil.plus(total.K42KA, data.K42KA);
      total.K43KQ_PS = calcUtil.plus(total.K43KQ_PS, data.K43KQ_PS);
      total.K43KA = calcUtil.plus(total.K43KA, data.K43KA);
      total.K44KQ_PS = calcUtil.plus(total.K44KQ_PS, data.K44KQ_PS);
      total.K44KA = calcUtil.plus(total.K44KA, data.K44KA);
      total.K49KQ_PS = calcUtil.plus(total.K49KQ_PS, data.K49KQ_PS);
      total.K49KA = calcUtil.plus(total.K49KA, data.K49KA);
      total.K50KQ_PS = calcUtil.plus(total.K50KQ_PS, data.K50KQ_PS);
      total.K50KA = calcUtil.plus(total.K50KA, data.K50KA);

      total.LQT_PS = calcUtil.plus(total.LQT_PS, data.LQT_PS);
      total.LQP_PS = calcUtil.plus(total.LQP_PS, data.LQP_PS);
      total.DQT_PS = calcUtil.plus(total.DQT_PS, data.DQT_PS);
      total.DQP_PS = calcUtil.plus(total.DQP_PS, data.DQP_PS);
      total.ULT_PS = calcUtil.plus(total.ULT_PS, data.ULT_PS);
      total.ULP_PS = calcUtil.plus(total.ULP_PS, data.ULP_PS);
      total.UDT_PS = calcUtil.plus(total.UDT_PS, data.UDT_PS);
      total.UDP_PS = calcUtil.plus(total.UDP_PS, data.UDP_PS);
      total.SQT_PS = calcUtil.plus(total.SQT_PS, data.SQT_PS);
      total.SQP_PS = calcUtil.plus(total.SQP_PS, data.SQP_PS);
      total.RQT_PS = calcUtil.plus(total.RQT_PS, data.RQT_PS);
      total.RQP_PS = calcUtil.plus(total.RQP_PS, data.RQP_PS);
      total.WQT_PS = calcUtil.plus(total.WQT_PS, data.WQT_PS);
      total.WQP_PS = calcUtil.plus(total.WQP_PS, data.WQP_PS);
      total.SLT = calcUtil.plus(total.SLT, data.SLT);
      total.SLP = calcUtil.plus(total.SLP, data.SLP);
      total.SDT = calcUtil.plus(total.SDT, data.SDT);
      total.SDP = calcUtil.plus(total.SDP, data.SDP);
      total.SRT = calcUtil.plus(total.SRT, data.SRT);
      total.SRP = calcUtil.plus(total.SRP, data.SRP);
      total.PTT = calcUtil.plus(total.PTT, data.PTT);
      total.PTP = calcUtil.plus(total.PTP, data.PTP);
      total.CIT = calcUtil.plus(total.CIT, data.CIT);
      total.CIP = calcUtil.plus(total.CIP, data.CIP);
      total.CST = calcUtil.plus(total.CST, data.CST);
      total.CSP = calcUtil.plus(total.CSP, data.CSP);
      total.BTT = calcUtil.plus(total.BTT, data.BTT);
      total.BTP = calcUtil.plus(total.BTP, data.BTP);
      total.STT = calcUtil.plus(total.STT, data.STT);
      total.STP = calcUtil.plus(total.STP, data.STP);
      total.ATT = calcUtil.plus(total.ATT, data.ATT);
      total.ATP = calcUtil.plus(total.ATP, data.ATP);
      total.RQTY_PS = calcUtil.plus(total.RQTY_PS, data.RQTY_PS);
      total.TQTY_PS = calcUtil.plus(total.TQTY_PS, data.TQTY_PS);
      total.YQTY_PS = calcUtil.plus(total.YQTY_PS, data.YQTY_PS);
      total.FQTY_PS = calcUtil.plus(total.FQTY_PS, data.FQTY_PS);
      total.AZKE_PS = calcUtil.plus(total.AZKE_PS, data.AZKE_PS);
      total.SQTC_PS = calcUtil.plus(total.SQTC_PS, data.SQTC_PS);
      total.SQTN_PS = calcUtil.plus(total.SQTN_PS, data.SQTN_PS);

      total.SHCT = calcUtil.plus(total.SHCT, data.SHCT);
      total.SHCP = calcUtil.plus(total.SHCP, data.SHCP);
      total.SHDT = calcUtil.plus(total.SHDT, data.SHDT);
      total.SHDP = calcUtil.plus(total.SHDP, data.SHDP);
      total.SHRCT = calcUtil.plus(total.SHRCT, data.SHRCT);
      total.SHRCP = calcUtil.plus(total.SHRCP, data.SHRCP);
      total.SHRDT = calcUtil.plus(total.SHRDT, data.SHRDT);
      total.SHRDP = calcUtil.plus(total.SHRDP, data.SHRDP);
      total.SHT = calcUtil.plus(total.SHT, data.SHT);
      total.SHP = calcUtil.plus(total.SHP, data.SHP);

      return total;
}

export const calcTotalSubPS = (total: RowData, data: RowData, teiban: string[], key?: string) => {
  if (key) {
    switch (total.TP) {
      case "teiban":
        total.SGTL = calcUtil.plus(total.SGTL, data.SGTL > 0 ? 1 : 0);
        break;
      case "teibangai":
        total.SGTL = calcUtil.plus(total.SGTL, data.SGTL > 0 ? 0 : 1);
        break;
    }
    if (key != data.KTB) {
      return;
    }
  }
  total.JJQT = calcUtil.plus(total.JJQT, data.JJQT);
  total.JJQP = calcUtil.plus(total.JJQP, data.JJQP);
  total.JSQT = calcUtil.plus(total.JSQT, data.JSQT);
  total.JSQP = calcUtil.plus(total.JSQP, data.JSQP);
  total.JKQT = calcUtil.plus(total.JKQT, data.JKQT);
  total.JKQP = calcUtil.plus(total.JKQP, data.JKQP);

  //欠品責任別
  total.KI1KQ = calcUtil.plus(total.KI1KQ, data.KI1KQ);
  total.KI2KQ = calcUtil.plus(total.KI2KQ, data.KI2KQ);
  total.KI3KQ = calcUtil.plus(total.KI3KQ, data.KI3KQ);
  //欠品区分別
  total.K10KQ = calcUtil.plus(total.K10KQ, data.K10KQ);
  total.K11KQ = calcUtil.plus(total.K11KQ, data.K11KQ);
  total.K12KQ = calcUtil.plus(total.K12KQ, data.K12KQ);
  total.K13KQ = calcUtil.plus(total.K13KQ, data.K13KQ);
  total.K14KQ = calcUtil.plus(total.K14KQ, data.K14KQ);
  total.K15KQ = calcUtil.plus(total.K15KQ, data.K15KQ);
  total.K16KQ = calcUtil.plus(total.K16KQ, data.K16KQ);
  total.K17KQ = calcUtil.plus(total.K17KQ, data.K17KQ);
  total.K19KQ = calcUtil.plus(total.K19KQ, data.K19KQ);
  total.K20KQ = calcUtil.plus(total.K20KQ, data.K20KQ);
  total.K21KQ = calcUtil.plus(total.K21KQ, data.K21KQ);
  total.K22KQ = calcUtil.plus(total.K22KQ, data.K22KQ);
  total.K29KQ = calcUtil.plus(total.K29KQ, data.K29KQ);
  total.K30KQ = calcUtil.plus(total.K30KQ, data.K30KQ);
  total.K31KQ = calcUtil.plus(total.K31KQ, data.K31KQ);
  total.K32KQ = calcUtil.plus(total.K32KQ, data.K32KQ);
  total.K33KQ = calcUtil.plus(total.K33KQ, data.K33KQ);
  total.K41KQ = calcUtil.plus(total.K41KQ, data.K41KQ);
  total.K34KQ = calcUtil.plus(total.K34KQ, data.K34KQ);
  total.K39KQ = calcUtil.plus(total.K39KQ, data.K39KQ);
  total.K40KQ = calcUtil.plus(total.K40KQ, data.K40KQ);
  total.K42KQ = calcUtil.plus(total.K42KQ, data.K42KQ);
  total.K43KQ = calcUtil.plus(total.K43KQ, data.K43KQ);
  total.K44KQ = calcUtil.plus(total.K44KQ, data.K44KQ);
  total.K49KQ = calcUtil.plus(total.K49KQ, data.K49KQ);
  total.K50KQ = calcUtil.plus(total.K50KQ, data.K50KQ);



  total.LQT = calcUtil.plus(total.LQT, data.LQT);
  total.LQP = calcUtil.plus(total.LQP, data.LQP);
  total.LQR = calcUtil.divide(total.LQT, total.LQP);
  total.LQD = calcUtil.plus(total.LQD, data.LQD);
  total.DQT = calcUtil.plus(total.DQT, data.DQT);
  total.DQP = calcUtil.plus(total.DQP, data.DQP);
  total.DQR = calcUtil.divide(total.DQT, total.DQP);
  total.DQD = calcUtil.plus(total.DQD, data.DQD);
  total.OQT = calcUtil.plus(total.OQT, data.OQT);
  total.OQP = calcUtil.plus(total.OQP, data.OQP);
  total.OQR = calcUtil.divide(total.OQT, total.OQP);
  total.OQD = calcUtil.plus(total.OQD, data.OQD);
  total.ULT = calcUtil.plus(total.ULT, data.ULT);
  total.ULP = calcUtil.plus(total.ULP, data.ULP);
  total.ULR = calcUtil.divide(total.ULT, total.ULP);
  total.ULD = calcUtil.plus(total.ULD, data.ULD);
  total.UDT = calcUtil.plus(total.UDT, data.UDT);
  total.UDP = calcUtil.plus(total.UDP, data.UDP);
  total.UDR = calcUtil.divide(total.UDT, total.UDP);
  total.UDD = calcUtil.plus(total.UDD, data.UDD);
  total.UPT = calcUtil.plus(total.UPT, data.UPT);
  total.UPP = calcUtil.plus(total.UPP, data.UPP);
  total.UPR = calcUtil.divide(total.UPT, total.UPP);
  total.UPD = calcUtil.plus(total.UPD, data.UPD);
  total.RQT = calcUtil.plus(total.RQT, data.RQT);
  total.RQP = calcUtil.plus(total.RQP, data.RQP);
  total.RQR = calcUtil.divide(total.RQT, total.RQP);
  total.RQD = calcUtil.plus(total.RQD, data.RQD);
  total.WQT = calcUtil.plus(total.WQT, data.WQT);
  total.WQP = calcUtil.plus(total.WQP, data.WQP);
  total.WQR = calcUtil.divide(total.WQT, total.WQP);
  total.WQD = calcUtil.plus(total.WQD, data.WQD);
  total.SQT = calcUtil.plus(total.SQT, data.SQT);
  total.SQP = calcUtil.plus(total.SQP, data.SQP);
  total.SQR = calcUtil.divide(total.SQT, total.SQP);
  total.SQD = calcUtil.plus(total.SQD, data.SQD);
  total.RQTY = calcUtil.plus(total.RQTY, data.RQTY);
  total.TQTY = calcUtil.plus(total.TQTY, data.TQTY);
  total.YQTY = calcUtil.plus(total.YQTY, data.YQTY);
  total.FQTY = calcUtil.plus(total.FQTY, data.FQTY);
  total.AZKE = calcUtil.plus(total.AZKE, data.AZKE);
  total.ZQTY = calcUtil.plus(total.ZQTY, data.ZQTY);
  total.SQTC = calcUtil.plus(total.SQTC, data.SQTC);
  total.SQTN = calcUtil.plus(total.SQTN, data.SQTN);
          
  {
    switch (total.TP) {
      case "total":
        total.TGTL = calcUtil.plus(total.TGTL, 1);
        total.SGTL = calcUtil.plus(total.SGTL, 1);
        break;
      case "teiban":
        total.TGTL = calcUtil.plus(total.TGTL, data.TGTL > 0 ? 1 : 0);
        break;
      case "teibangai":
        total.TGTL = calcUtil.plus(total.TGTL, data.TGTL > 0 ? 0 : 1);
        break;
    }
  }
  return total;
}

//計算
const calcDatas = (datas:RowData[], targetTP:"all"|"total"|"grouping1"|"grouping2"|"grouping3"|"grouping4"|"grouping5"|"item", totalSLT:number, totalTeibanSLT:number, totalTeibangaiSLT:number): RowData[] => {
  totalTeibanSLT = totalTeibanSLT ? totalTeibanSLT : totalSLT;
  totalTeibangaiSLT = totalTeibangaiSLT ? totalTeibangaiSLT : totalSLT;

  datas.forEach((data) => {
    calcData(data, targetTP, totalSLT, totalTeibanSLT, totalTeibangaiSLT, true);
  });

  return datas;
}
export const calcData = (data:RowData, targetTP:"all"|"total"|"grouping1"|"grouping2"|"grouping3"|"grouping4"|"grouping5"|"item", totalSLT:number, totalTeibanSLT:number, totalTeibangaiSLT:number, calcKeppin:boolean): RowData => {
  let tmp: number;
  if(targetTP == "all" || data.TP == targetTP) {
    //ここに貼り付け
    data.JJR = calcUtil.divide(data.JJT, data.JJP);
    data.JJD = calcUtil.minus(data.JJT, data.JJP);

    data.JSR = calcUtil.divide(data.JST, data.JSP);
    data.JSD = calcUtil.minus(data.JST, data.JSP);

    if(calcKeppin) {
      data.JKT = (tmp = calcUtil.greater(0, calcUtil.minus(data.JJT, data.SLT))) ? tmp : null; //=【受注額】本年実績-【配送売上】本年実績 //0未満は0表記
      data.JKP = (tmp = calcUtil.greater(0, calcUtil.minus(data.JJP, data.SLP))) ? tmp : null; //=【受注額】前年実績-【配送売上】前年実績 //0未満は0表記
      data.JKQT_PS = calcUtil.minus(data.JJQT_PS, data.LQT_PS); //=【受注額】本年実績-【配送売上】本年実績
      data.JKQP_PS = calcUtil.minus(data.JJQP_PS, data.LQP_PS); //=【受注額】前年実績-【配送売上】前年実績
    }

    data.JKR = calcUtil.divide(data.JKT, data.JKP);
    data.JKD = calcUtil.minus(data.JKT, data.JKP);
    if(data.IC1) {
      data.JJQT = data.JJQT_PS && data.IC1 ? (Math.floor(data.JJQT_PS / data.IC1 * 10) / 10) : null;
      data.JJQP = data.JJQP_PS && data.IC1 ? (Math.floor(data.JJQP_PS / data.IC1 * 10) / 10) : null;
    }
    data.JJQR = calcUtil.divide(data.JJQT, data.JJQP);
    data.JJQD = calcUtil.minus(data.JJQT, data.JJQP);
    if(data.IC1) {
      data.JSQT = data.JSQT_PS && data.IC1 ? (Math.floor(data.JSQT_PS / data.IC1 * 10) / 10) : null;
      data.JSQP = data.JSQP_PS && data.IC1 ? (Math.floor(data.JSQP_PS / data.IC1 * 10) / 10) : null;
    }
    data.JSQR = calcUtil.divide(data.JSQT, data.JSQP);
    data.JSQD = calcUtil.minus(data.JSQT, data.JSQP);
    if(data.IC1) {
      data.JKQT = data.JKQT_PS && data.IC1 ? (Math.floor(data.JKQT_PS / data.IC1 * 10) / 10) : null;
      data.JKQP = data.JKQP_PS && data.IC1 ? (Math.floor(data.JKQP_PS / data.IC1 * 10) / 10) : null;
    }
    data.JKQR = calcUtil.divide(data.JKQT, data.JKQP);
    data.JKQD = calcUtil.minus(data.JKQT, data.JKQP);
    data.JRT = calcUtil.divide(data.JKT, data.JJT);
    data.JRP = calcUtil.divide(data.JKP, data.JJP);
    data.JRD = calcUtil.multiply(calcUtil.minus(data.JRT, data.JRP),100);

    //1.自社責に寄せる
    if(calcKeppin) {
      data.KI1KA = (tmp = calcUtil.greater(0, calcUtil.minus(data.JKT, calcUtil.sum([data.KI2KA, data.KI3KA])))) ? tmp : null; //0未満は0表記
      data.KI1KQ_PS = calcUtil.greater(0, calcUtil.minus(data.JKQT_PS, calcUtil.sum([data.KI2KQ_PS, data.KI3KQ_PS]))); //0未満は0表記

      //10.未入力に寄せる //0未満は0表記
      data.K10KA = calcUtil.greater(0, calcUtil.minus(data.JKT, calcUtil.sum([
        data.K11KA, data.K12KA, data.K13KA, data.K14KA, data.K15KA, data.K16KA, data.K17KA, data.K19KA,
        data.K20KA, data.K21KA, data.K22KA, data.K29KA,
        data.K30KA, data.K31KA, data.K32KA, data.K33KA, data.K34KA, data.K39KA,
        data.K40KA, data.K41KA, data.K42KA, data.K43KA, data.K44KA, data.K49KA,
        data.K50KA,
      ])));
       //0未満は0表記
      data.K10KQ_PS = calcUtil.greater(0, calcUtil.minus(data.JKQT_PS, calcUtil.sum([
        data.K11KQ_PS, data.K12KQ_PS, data.K13KQ_PS, data.K14KQ_PS, data.K15KQ_PS, data.K16KQ_PS, data.K17KQ_PS, data.K19KQ_PS,
        data.K20KQ_PS, data.K21KQ_PS, data.K22KQ_PS, data.K29KQ_PS,
        data.K30KQ_PS, data.K31KQ_PS, data.K32KQ_PS, data.K33KQ_PS, data.K34KQ_PS, data.K39KQ_PS,
        data.K40KQ_PS, data.K41KQ_PS, data.K42KQ_PS, data.K43KQ_PS, data.K44KQ_PS, data.K49KQ_PS,
        data.K50KQ_PS,
      ])));
    }

    if(data.IC1) {
      //欠品責任別
      data.KI1KQ = data.KI1KQ_PS && data.IC1 ? (Math.floor(data.KI1KQ_PS / data.IC1 * 10) / 10) : null;
      data.KI2KQ = data.KI2KQ_PS && data.IC1 ? (Math.floor(data.KI2KQ_PS / data.IC1 * 10) / 10) : null;
      data.KI3KQ = data.KI3KQ_PS && data.IC1 ? (Math.floor(data.KI3KQ_PS / data.IC1 * 10) / 10) : null;
      //欠品区分別
      data.K10KQ = data.K10KQ_PS && data.IC1 ? (Math.floor(data.K10KQ_PS / data.IC1 * 10) / 10) : null;
      data.K11KQ = data.K11KQ_PS && data.IC1 ? (Math.floor(data.K11KQ_PS / data.IC1 * 10) / 10) : null;
      data.K12KQ = data.K12KQ_PS && data.IC1 ? (Math.floor(data.K12KQ_PS / data.IC1 * 10) / 10) : null;
      data.K13KQ = data.K13KQ_PS && data.IC1 ? (Math.floor(data.K13KQ_PS / data.IC1 * 10) / 10) : null;
      data.K14KQ = data.K14KQ_PS && data.IC1 ? (Math.floor(data.K14KQ_PS / data.IC1 * 10) / 10) : null;
      data.K15KQ = data.K15KQ_PS && data.IC1 ? (Math.floor(data.K15KQ_PS / data.IC1 * 10) / 10) : null;
      data.K16KQ = data.K16KQ_PS && data.IC1 ? (Math.floor(data.K16KQ_PS / data.IC1 * 10) / 10) : null;
      data.K17KQ = data.K17KQ_PS && data.IC1 ? (Math.floor(data.K17KQ_PS / data.IC1 * 10) / 10) : null;
      data.K19KQ = data.K19KQ_PS && data.IC1 ? (Math.floor(data.K19KQ_PS / data.IC1 * 10) / 10) : null;
      data.K20KQ = data.K20KQ_PS && data.IC1 ? (Math.floor(data.K20KQ_PS / data.IC1 * 10) / 10) : null;
      data.K21KQ = data.K21KQ_PS && data.IC1 ? (Math.floor(data.K21KQ_PS / data.IC1 * 10) / 10) : null;
      data.K22KQ = data.K22KQ_PS && data.IC1 ? (Math.floor(data.K22KQ_PS / data.IC1 * 10) / 10) : null;
      data.K29KQ = data.K29KQ_PS && data.IC1 ? (Math.floor(data.K29KQ_PS / data.IC1 * 10) / 10) : null;
      data.K30KQ = data.K30KQ_PS && data.IC1 ? (Math.floor(data.K30KQ_PS / data.IC1 * 10) / 10) : null;
      data.K31KQ = data.K31KQ_PS && data.IC1 ? (Math.floor(data.K31KQ_PS / data.IC1 * 10) / 10) : null;
      data.K32KQ = data.K32KQ_PS && data.IC1 ? (Math.floor(data.K32KQ_PS / data.IC1 * 10) / 10) : null;
      data.K33KQ = data.K33KQ_PS && data.IC1 ? (Math.floor(data.K33KQ_PS / data.IC1 * 10) / 10) : null;
      data.K41KQ = data.K41KQ_PS && data.IC1 ? (Math.floor(data.K41KQ_PS / data.IC1 * 10) / 10) : null;
      data.K34KQ = data.K34KQ_PS && data.IC1 ? (Math.floor(data.K34KQ_PS / data.IC1 * 10) / 10) : null;
      data.K39KQ = data.K39KQ_PS && data.IC1 ? (Math.floor(data.K39KQ_PS / data.IC1 * 10) / 10) : null;
      data.K40KQ = data.K40KQ_PS && data.IC1 ? (Math.floor(data.K40KQ_PS / data.IC1 * 10) / 10) : null;
      data.K42KQ = data.K42KQ_PS && data.IC1 ? (Math.floor(data.K42KQ_PS / data.IC1 * 10) / 10) : null;
      data.K43KQ = data.K43KQ_PS && data.IC1 ? (Math.floor(data.K43KQ_PS / data.IC1 * 10) / 10) : null;
      data.K44KQ = data.K44KQ_PS && data.IC1 ? (Math.floor(data.K44KQ_PS / data.IC1 * 10) / 10) : null;
      data.K49KQ = data.K49KQ_PS && data.IC1 ? (Math.floor(data.K49KQ_PS / data.IC1 * 10) / 10) : null;
      data.K50KQ = data.K50KQ_PS && data.IC1 ? (Math.floor(data.K50KQ_PS / data.IC1 * 10) / 10) : null;
    }

    //欠品責任別
    data.KI1KR = calcUtil.divide(data.KI1KA, data.JJT);
    data.KI2KR = calcUtil.divide(data.KI2KA, data.JJT);
    data.KI3KR = calcUtil.divide(data.KI3KA, data.JJT);

    //欠品区分別
    data.K10KR = calcUtil.divide(data.K10KA, data.JJT);
    data.K11KR = calcUtil.divide(data.K11KA, data.JJT);
    data.K12KR = calcUtil.divide(data.K12KA, data.JJT);
    data.K13KR = calcUtil.divide(data.K13KA, data.JJT);
    data.K14KR = calcUtil.divide(data.K14KA, data.JJT);
    data.K15KR = calcUtil.divide(data.K15KA, data.JJT);
    data.K16KR = calcUtil.divide(data.K16KA, data.JJT);
    data.K17KR = calcUtil.divide(data.K17KA, data.JJT);
    data.K19KR = calcUtil.divide(data.K19KA, data.JJT);
    data.K20KR = calcUtil.divide(data.K20KA, data.JJT);
    data.K21KR = calcUtil.divide(data.K21KA, data.JJT);
    data.K22KR = calcUtil.divide(data.K22KA, data.JJT);
    data.K29KR = calcUtil.divide(data.K29KA, data.JJT);
    data.K30KR = calcUtil.divide(data.K30KA, data.JJT);
    data.K31KR = calcUtil.divide(data.K31KA, data.JJT);
    data.K32KR = calcUtil.divide(data.K32KA, data.JJT);
    data.K33KR = calcUtil.divide(data.K33KA, data.JJT);
    data.K41KR = calcUtil.divide(data.K41KA, data.JJT);
    data.K34KR = calcUtil.divide(data.K34KA, data.JJT);
    data.K39KR = calcUtil.divide(data.K39KA, data.JJT);
    data.K40KR = calcUtil.divide(data.K40KA, data.JJT);
    data.K42KR = calcUtil.divide(data.K42KA, data.JJT);
    data.K43KR = calcUtil.divide(data.K43KA, data.JJT);
    data.K44KR = calcUtil.divide(data.K44KA, data.JJT);
    data.K49KR = calcUtil.divide(data.K49KA, data.JJT);
    data.K50KR = calcUtil.divide(data.K50KA, data.JJT);

    if(data.IC1) {
      data.LQT = data.LQT_PS && data.IC1 ? (Math.floor(data.LQT_PS / data.IC1 * 10) / 10) : null;
      data.LQP = data.LQP_PS && data.IC1 ? (Math.floor(data.LQP_PS / data.IC1 * 10) / 10) : null;
    }
    data.LQR = calcUtil.divide(data.LQT, data.LQP);
    data.LQD = calcUtil.minus(data.LQT, data.LQP);
    if(data.IC1) {
      data.DQT = data.DQT_PS && data.IC1 ? (Math.floor(data.DQT_PS / data.IC1 * 10) / 10) : null;
      data.DQP = data.DQP_PS && data.IC1 ? (Math.floor(data.DQP_PS / data.IC1 * 10) / 10) : null;
    }
    data.DQR = calcUtil.divide(data.DQT, data.DQP);
    data.DQD = calcUtil.minus(data.DQT, data.DQP);
    data.OQT = calcUtil.plus(data.LQT, data.DQT);
    data.OQP = calcUtil.plus(data.LQP, data.DQP);
    data.OQR = calcUtil.divide(data.OQT, data.OQP);
    data.OQD = calcUtil.minus(data.OQT, data.OQP);
    if(data.IC1) {
      data.ULT = data.ULT_PS && data.IC1 ? (Math.floor(data.ULT_PS / data.IC1 * 10) / 10) : null;
      data.ULP = data.ULP_PS && data.IC1 ? (Math.floor(data.ULP_PS / data.IC1 * 10) / 10) : null;
    }
    data.ULR = calcUtil.divide(data.ULT, data.ULP);
    data.ULD = calcUtil.minus(data.ULT, data.ULP);
    if(data.IC1) {
      data.UDT = data.UDT_PS && data.IC1 ? (Math.floor(data.UDT_PS / data.IC1 * 10) / 10) : null;
      data.UDP = data.UDP_PS && data.IC1 ? (Math.floor(data.UDP_PS / data.IC1 * 10) / 10) : null;
    }
    data.UDR = calcUtil.divide(data.UDT, data.UDP);
    data.UDD = calcUtil.minus(data.UDT, data.UDP);
    data.UPT = calcUtil.plus(data.ULT, data.UDT);
    data.UPP = calcUtil.plus(data.ULP, data.UDP);
    data.UPR = calcUtil.divide(data.UPT, data.UPP);
    data.UPD = calcUtil.minus(data.UPT, data.UPP);
    if(data.IC1) {
      data.SQT = data.SQT_PS && data.IC1 ? (Math.floor(data.SQT_PS / data.IC1 * 10) / 10) : null;
      data.SQP = data.SQP_PS && data.IC1 ? (Math.floor(data.SQP_PS / data.IC1 * 10) / 10) : null;
    }
    data.SQR = calcUtil.divide(data.SQT, data.SQP);
    data.SQD = calcUtil.minus(data.SQT, data.SQP);
    if(data.IC1) {
      data.RQT = data.RQT_PS && data.IC1 ? (Math.floor(data.RQT_PS / data.IC1 * 10) / 10) : null;
      data.RQP = data.RQP_PS && data.IC1 ? (Math.floor(data.RQP_PS / data.IC1 * 10) / 10) : null;
    }
    data.RQR = calcUtil.divide(data.RQT, data.RQP);
    data.RQD = calcUtil.minus(data.RQT, data.RQP);
    if(data.IC1) {
      data.WQT = data.WQT_PS && data.IC1 ? (Math.floor(data.WQT_PS / data.IC1 * 10) / 10) : null;
      data.WQP = data.WQP_PS && data.IC1 ? (Math.floor(data.WQP_PS / data.IC1 * 10) / 10) : null;
    }

    data.LQR_PS = calcUtil.divide(data.LQT_PS, data.LQP_PS);
    data.LQD_PS = calcUtil.minus(data.LQT_PS, data.LQP_PS);
    data.DQR_PS = calcUtil.divide(data.DQT_PS, data.DQP_PS);
    data.DQD_PS = calcUtil.minus(data.DQT_PS, data.DQP_PS);
    data.OQT_PS = calcUtil.plus(data.LQT_PS, data.DQT_PS);
    data.OQP_PS = calcUtil.plus(data.LQP_PS, data.DQP_PS);
    data.OQR_PS = calcUtil.divide(data.OQT_PS, data.OQP_PS);
    data.OQD_PS = calcUtil.minus(data.OQT_PS, data.OQP_PS);
    data.ULR_PS = calcUtil.divide(data.ULT_PS, data.ULP_PS);
    data.ULD_PS = calcUtil.minus(data.ULT_PS, data.ULP_PS);
    data.UDR_PS = calcUtil.divide(data.UDT_PS, data.UDP_PS);
    data.UDD_PS = calcUtil.minus(data.UDT_PS, data.UDP_PS);
    data.UPT_PS = calcUtil.plus(data.ULT_PS, data.UDT_PS);
    data.UPP_PS = calcUtil.plus(data.ULP_PS, data.UDP_PS);
    data.UPR_PS = calcUtil.divide(data.UPT_PS, data.UPP_PS);
    data.UPD_PS = calcUtil.minus(data.UPT_PS, data.UPP_PS);
    data.SQR_PS = calcUtil.divide(data.SQT_PS, data.SQP_PS);
    data.SQD_PS = calcUtil.minus(data.SQT_PS, data.SQP_PS);
    data.RQR_PS = calcUtil.divide(data.RQT_PS, data.RQP_PS);
    data.RQD_PS = calcUtil.minus(data.RQT_PS, data.RQP_PS);
    data.WQR_PS = calcUtil.divide(data.WQT_PS, data.WQP_PS);
    data.WQD_PS = calcUtil.minus(data.WQT_PS, data.WQP_PS);
    data.WQR = calcUtil.divide(data.WQT, data.WQP);
    data.WQD = calcUtil.minus(data.WQT, data.WQP);
    data.SLR = calcUtil.divide(data.SLT, data.SLP);
    data.SLD = calcUtil.minus(data.SLT, data.SLP);
    data.SDR = calcUtil.divide(data.SDT, data.SDP);
    data.SDD = calcUtil.minus(data.SDT, data.SDP);
    data.SRR = calcUtil.divide(data.SRT, data.SRP);
    data.SRD = calcUtil.minus(data.SRT, data.SRP);
    // data.STT = calcUtil.sum([data.SLT, data.SDT, data.SRT]);
    // data.STP = calcUtil.sum([data.SLP, data.SDP, data.SRP]);
    data.STR = calcUtil.divide(data.STT, data.STP);
    data.STD = calcUtil.minus(data.STT, data.STP);
    data.PTD = calcUtil.minus(data.PTT, data.PTP);
    data.PRT = calcUtil.divide(data.PTT, data.STT);
    data.PRP = calcUtil.divide(data.PTP, data.STP);
    data.PRD = calcUtil.multiply(calcUtil.minus(data.PRT, data.PRP),100);
    data.CIR = calcUtil.divide(data.CIT, data.CIP);
    data.CID = calcUtil.minus(data.CIT, data.CIP);
    data.CSR = calcUtil.divide(data.CST, data.CSP);
    data.CSD = calcUtil.minus(data.CST, data.CSP);
    data.CTT = calcUtil.sum([data.CIT, data.CST]);
    data.CTP = calcUtil.sum([data.CIP, data.CSP]);
    data.CTR = calcUtil.divide(data.CTT, data.CTP);
    data.CTD = calcUtil.minus(data.CTT, data.CTP);
    data.BTR = calcUtil.divide(data.BTT, data.BTP);
    data.BTD = calcUtil.minus(data.BTT, data.BTP);
    data.BRT = calcUtil.divide(data.BTT, data.STT);
    data.BRP = calcUtil.divide(data.BTP, data.STP);
    data.BRD = calcUtil.multiply(calcUtil.minus(data.BRT, data.BRP),100);
    // data.ATT = calcUtil.sum([data.BTT, data.CTT]);
    // data.ATP = calcUtil.sum([data.BTP, data.CTP]);
    data.ATR = calcUtil.divide(data.ATT, data.ATP);
    data.ATD = calcUtil.minus(data.ATT, data.ATP);
    data.ART = calcUtil.divide(data.ATT, data.STT);
    data.ARP = calcUtil.divide(data.ATP, data.STP);
    data.ARD = calcUtil.multiply(calcUtil.minus(data.ART, data.ARP),100);
    data.KUR = calcUtil.divide(data.SLT, (data.TP === 'teiban' || data.KTB === '定番') ? totalTeibanSLT : (data.TP === 'teibangai' || data.KTB === '定番外') ? totalTeibangaiSLT : totalSLT);
    data.ZQTY_PS = calcUtil.plus(calcUtil.plus(calcUtil.plus(calcUtil.plus(data.RQTY_PS, data.TQTY_PS), data.YQTY_PS), data.FQTY_PS), data.AZKE_PS);
    if(data.IC1) {
      data.RQTY = data.RQTY_PS && data.IC1 ? (Math.floor(data.RQTY_PS / data.IC1 * 10) / 10) : null;
      data.TQTY = data.TQTY_PS && data.IC1 ? (Math.floor(data.TQTY_PS / data.IC1 * 10) / 10) : null;
      data.YQTY = data.YQTY_PS && data.IC1 ? (Math.floor(data.YQTY_PS / data.IC1 * 10) / 10) : null;
      data.FQTY = data.FQTY_PS && data.IC1 ? (Math.floor(data.FQTY_PS / data.IC1 * 10) / 10) : null;
      data.AZKE = data.AZKE_PS && data.IC1 ? (Math.floor(data.AZKE_PS / data.IC1 * 10) / 10) : null;
      data.ZQTY = data.ZQTY_PS && data.IC1 ? (Math.floor(data.ZQTY_PS / data.IC1 * 10) / 10) : null;
      data.SQTC = data.SQTC_PS && data.IC1 ? (Math.floor(data.SQTC_PS / data.IC1 * 10) / 10) : null;
      data.SQTN = data.SQTN_PS && data.IC1 ? (Math.floor(data.SQTN_PS / data.IC1 * 10) / 10) : null;
    }

    data.SHCR = calcUtil.divide(data.SHCT, data.SHCP);
    data.SHCD2 = calcUtil.minus(data.SHCT, data.SHCP);
    data.SHDR = calcUtil.divide(data.SHDT, data.SHDP);
    data.SHDD = calcUtil.minus(data.SHDT, data.SHDP);
    data.SHRCR = calcUtil.divide(data.SHRCT, data.SHRCP);
    data.SHRCD = calcUtil.minus(data.SHRCT, data.SHRCP);
    data.SHRDR = calcUtil.divide(data.SHRDT, data.SHRDP);
    data.SHRDD = calcUtil.minus(data.SHRDT, data.SHRDP);
    data.SHR = calcUtil.divide(data.SHT, data.SHP);
    data.SHD = calcUtil.minus(data.SHT, data.SHP);    
  }
  return data;
}

//no振りなおし
const resetRowNo = (datas:RowData[], 
  grouping1: 'item'|'shiire'|'maker'|'shiten'|'center'|'group2'|'group1'|'tokuisaki'|'category'|'pb'|string,
  grouping2: 'item'|'shiire'|'maker'|'shiten'|'center'|'group2'|'group1'|'tokuisaki'|'category'|'pb'|string,
  grouping3: 'item'|'shiire'|'maker'|'shiten'|'center'|'group2'|'group1'|'tokuisaki'|'category'|'pb'|string,
  grouping4: 'item'|'shiire'|'maker'|'shiten'|'center'|'group2'|'group1'|'tokuisaki'|'category'|'pb'|string,
  grouping5: 'item'|'shiire'|'maker'|'shiten'|'center'|'group2'|'group1'|'tokuisaki'|'category'|'pb'|string,
  groupingOnly: boolean

  ): RowData[] => {
  //set No.
  let TPs = [];
  if(grouping1) {
    TPs.push('grouping1');
  }
  if(grouping2) {
    TPs.push('grouping2');
  }
  if(grouping3) {
    TPs.push('grouping3');
  }
  if(grouping4) {
    TPs.push('grouping4');
  }
  if(grouping5) {
    TPs.push('grouping5');
  }
  if(!groupingOnly){
    TPs.push('item');
  }
  let nos = TPs.map(_ => 0);

  let indexOfBefore = -1;
  datas = datas.map((row) => {
    let no = row.TP == "total" ? "合計" : row.TP == "teiban" ? "定番小計" : row.TP == "teibangai" ? "定番外小計" : "";
    const indexOf = TPs.indexOf(row.TP);
    nos = nos.map((n, index) => {
      //階層が下がったらカウントをリセット
      if(indexOfBefore > indexOf && index > indexOf) {
        nos[index] = 0;
        n = 0;
      }

      if(index<indexOf){
        if(n) {
          no = no + "" + n + "-";
        }
      }
      else if(index == indexOf){
        no = no + "" + (++n);
      }
      return n;
    });
    indexOfBefore = indexOf;

    return {
      ...row,
      no: no,
    }
  });

  return datas;
}

//配列データに変換
const convertRows = (datas:RowData[], colModel:ItemListColModel): [][] => {
  const rows = [];
  //set No.
  datas.forEach((data) => {
    const r = [];
    colModel.colKeys.forEach((colKey) => {
      r.push(data[colKey]);
    });
    rows.push(r);
  });

  return rows;
}
//マージを作成
const createMergeCells = (datas:RowData[]): {row: number, col: number, rowspan: number, colspan: number}[] => {
  //マージいらない
  return null;
}

//ソート
const doSort = (datas:RowData[], colKey:string, asc:boolean, 
  grouping1: 'item'|'shiire'|'maker'|'shiten'|'center'|'group2'|'group1'|'tokuisaki'|'category'|'pb'|string,
  grouping2: 'item'|'shiire'|'maker'|'shiten'|'center'|'group2'|'group1'|'tokuisaki'|'category'|'pb'|string,
  grouping3: 'item'|'shiire'|'maker'|'shiten'|'center'|'group2'|'group1'|'tokuisaki'|'category'|'pb'|string,
  grouping4: 'item'|'shiire'|'maker'|'shiten'|'center'|'group2'|'group1'|'tokuisaki'|'category'|'pb'|string,
  grouping5: 'item'|'shiire'|'maker'|'shiten'|'center'|'group2'|'group1'|'tokuisaki'|'category'|'pb'|string,
  groupingOnly: boolean
  ): RowData[] => {
  console.log('doSort');
  //Totalのソート
  const sortContTotal = (colKey, asc, objA, objB): number => {
    //合計行は常に上
    if (['total', 'teiban', 'teibangai'].indexOf(objA.TP) !== -1 && ['total', 'teiban', 'teibangai'].indexOf(objB.TP) !== -1) {
      const ia = ['total', 'teiban', 'teibangai'].indexOf(objA.TP);
      const ib = ['total', 'teiban', 'teibangai'].indexOf(objB.TP);
      return compareUtil.compareNumber(ia, ib, true);
    }
    return ['total', 'teiban', 'teibangai'].indexOf(objA.TP) !== -1 ? -1 : 1;
  };
  //同じ階層同士のソート
  const sortCont = (TP, colKey, asc, objA, objB):number => {
    let comp = 0;
    const va = objA ? objA[colKey] : null;
    const vb = objB ? objB[colKey] : null;
    //数値型
    if(typeof va === 'number' || typeof vb === 'number') {
      comp = compareUtil.compareNumber2(va, vb, asc);
    }
    else if (colKey == 'KRK') {
      const ia = ['S', 'A', 'B', 'C', 'Cα', 'Cɤ', 'Z', 'ZX', 'Zn'].indexOf(va);
      const ib = ['S', 'A', 'B', 'C', 'Cα', 'Cɤ', 'Z', 'ZX', 'Zn'].indexOf(vb);
      comp = compareUtil.compareNumber(ia, ib, asc);
    }
    else if(typeof va === 'string' || typeof vb === 'string') {
      comp = compareUtil.compareString(va, vb, asc);
    }
    //それでもソートできない場合、メーカー小計ならばコード順とする
    if(comp == 0 && TP == 'grouping1') {
      const va = getGroupingCD(grouping1, objA);
      const vb = getGroupingCD(grouping1, objB);
      comp = compareUtil.compareString(va, vb, asc);
    }
    else if(comp == 0 && TP == 'grouping2') {
      const va = getGroupingCD(grouping2, objA);
      const vb = getGroupingCD(grouping2, objB);
      comp = compareUtil.compareString(va, vb, asc);
    }
    else if(comp == 0 && TP == 'grouping3') {
      const va = getGroupingCD(grouping3, objA);
      const vb = getGroupingCD(grouping3, objB);
      comp = compareUtil.compareString(va, vb, asc);
    }
    else if(comp == 0 && TP == 'grouping4') {
      const va = getGroupingCD(grouping4, objA);
      const vb = getGroupingCD(grouping4, objB);
      comp = compareUtil.compareString(va, vb, asc);
    }
    else if(comp == 0 && TP == 'grouping5') {
      const va = getGroupingCD(grouping5, objA);
      const vb = getGroupingCD(grouping5, objB);
      comp = compareUtil.compareString(va, vb, asc);
    }
    return comp;
  };

  datas.sort((a, b) => {
    //合計行は常に上
    if(a.TP == "total" || b.TP == "total" || a.TP == "teiban" || b.TP == "teiban" || a.TP == "teibangai" || b.TP == "teibangai") {
      return sortContTotal(colKey, asc, a, b);
    }

    let objA = a;
    let objB = b;

    const aGCD1 = getGroupingCD(grouping1, a);
    const bGCD1 = getGroupingCD(grouping1, b);
    const aGCD2 = getGroupingCD(grouping2, a);
    const bGCD2 = getGroupingCD(grouping2, b);
    const aGCD3 = getGroupingCD(grouping3, a);
    const bGCD3 = getGroupingCD(grouping3, b);
    const aGCD4 = getGroupingCD(grouping4, a);
    const bGCD4 = getGroupingCD(grouping4, b);
    const aGCD5 = getGroupingCD(grouping5, a);
    const bGCD5 = getGroupingCD(grouping5, b);

    const lvA = ['grouping1','grouping2','grouping3','grouping4','grouping5','item'].indexOf(a.TP);
    const lvB = ['grouping1','grouping2','grouping3','grouping4','grouping5','item'].indexOf(b.TP);

    const minLV = Math.min(lvA, lvB);
    let compareParentGroup:boolean = false;
    //LV1
    if(minLV == 0) {
      if(lvA != lvB && aGCD1 == bGCD1) {  //同じコードならLV低い方を上
        return lvA - lvB;
      }
      compareParentGroup = true;
    }
    //LV2
    else if(minLV == 1) {
      if(lvA != lvB && aGCD1 == bGCD1 && aGCD2 == bGCD2) {  //同じコードならLV低い方を上
        return lvA - lvB;
      }
      compareParentGroup = true;
    }
    //LV3
    else if(minLV == 2) {
      if(lvA != lvB && aGCD1 == bGCD1 && aGCD2 == bGCD2 && aGCD3 == bGCD3) {  //同じコードならLV低い方を上
        return lvA - lvB;
      }
      compareParentGroup = true;
    }
    //LV4
    else if(minLV == 3) {
      if(lvA != lvB && aGCD1 == bGCD1 && aGCD2 == bGCD2 && aGCD3 == bGCD3 && aGCD4 == bGCD4) {  //同じコードならLV低い方を上
        return lvA - lvB;
      }
      compareParentGroup = true;
    }
    //LV5
    else if(minLV == 4) {
      if(lvA != lvB && aGCD1 == bGCD1 && aGCD2 == bGCD2 && aGCD3 == bGCD3 && aGCD4 == bGCD4 && aGCD5 == bGCD5) {  //同じコードならLV低い方を上
        return lvA - lvB;
      }
      compareParentGroup = true;
    }
    //アイテムどうし
    else if(minLV == 5) {
      if(!(aGCD1 == bGCD1 && aGCD2 == bGCD2 && aGCD3 == bGCD3 && aGCD4 == bGCD4 && aGCD5 == bGCD5)) {  //異なるグループコードならば
        compareParentGroup = true;
      }
    }

    if(compareParentGroup) {
      if(aGCD1 != bGCD1) {
        if(a.TP != 'grouping1') {
          objA = datas.find(data => data.TP == 'grouping1' && getGroupingCD(grouping1, data) == aGCD1);
        }
        if(b.TP != 'grouping1') {
          objB = datas.find(data => data.TP == 'grouping1' && getGroupingCD(grouping1, data) == bGCD1);
        }
      }
      else if(aGCD2 != bGCD2) {
        if(a.TP != 'grouping2') {
          objA = datas.find(data => data.TP == 'grouping2' && getGroupingCD(grouping1, data) == aGCD1 && getGroupingCD(grouping2, data) == aGCD2);
        }
        if(b.TP != 'grouping2') {
          objB = datas.find(data => data.TP == 'grouping2' && getGroupingCD(grouping1, data) == bGCD1 && getGroupingCD(grouping2, data) == bGCD2);
        }
      }
      else if(aGCD3 != bGCD3) {
        if(a.TP != 'grouping3') {
          objA = datas.find(data => data.TP == 'grouping3' && getGroupingCD(grouping1, data) == aGCD1 && getGroupingCD(grouping2, data) == aGCD2 && getGroupingCD(grouping3, data) == aGCD3);
        }
        if(b.TP != 'grouping3') {
          objB = datas.find(data => data.TP == 'grouping3' && getGroupingCD(grouping1, data) == bGCD1 && getGroupingCD(grouping2, data) == bGCD2 && getGroupingCD(grouping3, data) == bGCD3);
        }
      }
      else if(aGCD4 != bGCD4) {
        if(a.TP != 'grouping4') {
          objA = datas.find(data => data.TP == 'grouping4' && getGroupingCD(grouping1, data) == aGCD1 && getGroupingCD(grouping2, data) == aGCD2 && getGroupingCD(grouping3, data) == aGCD3 && getGroupingCD(grouping4, data) == aGCD4);
        }
        if(b.TP != 'grouping4') {
          objB = datas.find(data => data.TP == 'grouping4' && getGroupingCD(grouping1, data) == bGCD1 && getGroupingCD(grouping2, data) == bGCD2 && getGroupingCD(grouping3, data) == bGCD3 && getGroupingCD(grouping4, data) == bGCD4);
        }
      }
      else if(aGCD5 != bGCD5) {
        if(a.TP != 'grouping5') {
          objA = datas.find(data => data.TP == 'grouping5' && getGroupingCD(grouping1, data) == aGCD1 && getGroupingCD(grouping2, data) == aGCD2 && getGroupingCD(grouping3, data) == aGCD3 && getGroupingCD(grouping4, data) == aGCD4 && getGroupingCD(grouping5, data) == aGCD5);
        }
        if(b.TP != 'grouping5') {
          objB = datas.find(data => data.TP == 'grouping5' && getGroupingCD(grouping1, data) == bGCD1 && getGroupingCD(grouping2, data) == bGCD2 && getGroupingCD(grouping3, data) == bGCD3 && getGroupingCD(grouping4, data) == bGCD4 && getGroupingCD(grouping5, data) == bGCD5);
        }
      }
    }
    
    const TP = objA ? objA.TP : objB ? objB.TP : null;
    return sortCont(TP, colKey, asc, objA, objB);
  });



  return datas;

}

//集計軸
export const axisName = (axis: "group1" | "group2" | "shiten" | "center" | "maker" | "tokuisaki" | "pb" | "tag" | "item" | "shiire" | "category" | string) => {
  switch(axis) {
    case "group1":
      return "企業グループ1";
    case "group2":
      return "企業グループ2";
    case "shiten":
      return "支店";
    case "center":
      return "倉庫";
    case "maker":
      return "メーカー";
    case "tokuisaki":
      return "得意先";
    case "pb":
      return "PB区分";
    case "tag":
      return "タグ";
    case "item":
      return "商品CDまたはJANコード";
    case "shiire":
      return "仕入先";
    case "category":
      return "商品カテゴリ";
    default:
      return "-";
  }
}

//データ種別
export const dataTypeName = (dataType: "quick" | "commit" | string) => {
  switch(dataType) {
    case "quick":
      return "月次速報";
    case "commit":
      return "月次確報";
    default:
      return "-";
  }
}
//定番/定番外
export const teibanName = (code: string) => {
  const name = teibans.find(teiban => teiban.code == code)?.name;
  return name ? name : "-";
}
//検索対象
export const containSEJName = (containSEJ:boolean) => {
  if(containSEJ) {
    return "SEJを含む";
  }
  else {
    return "一般";
  }
}

// 複数指定時の区切り文字
const delim = ",";

export const targetName = (targetNM:string) => {
  if(!targetNM) {
    return "-";
  }
  else {
    const targetNMs: string[] = targetNM.split(delim);
    return targetNMs[0];
  }
}

export const targetCnt = (params : RequestParam) => {
  const targets = [];
  for (let i = 1; i <= drillDownMaxLv; i++) {
    targets.push(params['d' + i + '_target']);
  }
  const names = targets.filter(target => target).map(target => ({ target: target, length: target.split(delim).length })).sort((a, b) => b.length - a.length);
  const targetNM = names[0].target;

  if (!targetNM) {
    return "";
  }
  else {
    const targetNMs: string[] = targetNM.split(delim);
    return '(他' + (targetNMs.length - 1) + '件)';
  }
}

//検索条件の複数指定判定
export const isMultipleConditions = (requestedParam: RequestParam): boolean => {
  if (!requestedParam.d1_targetNM) {
    return false;
  }
  const targetNMs: string[] = requestedParam.d1_targetNM.split(delim);
  return targetNMs.length > 1;
}

export const getOptionLabel = (option: CodeName) => {
  return option && option.name ? (option.code + ' ' + option.name) : "";
}
export const getOptionLabelGroup1 = (option: CodeName) => {
  // 企業グループ2CD(5桁)と'_'を削除
  return option && option.name ? (option.code.substring(5 + 1) + ' ' + option.name) : "";
}
export const getOptionLabelCenter = (option: CodeName) => {
  return option && option.name ? (option.code.replace('_', '') + ' ' + option.name) : "";
}
export const getOptionLabelPb = (option: CodeName) => {
  return option && option.name ? ((option.code === "全選択" ? '' : option.code + ' ') + option.name) : "";
}

const getGroupingCD = (key: 'item'|'shiire'|'maker'|'shiten'|'center'|'group2'|'group1'|'tokuisaki'|'category'|'pb'|string, data:RowData):string => {
  if(!data) {
    return '';
  }
  switch (key) {
    case 'item':
      return data.IID;
    case 'shiire':
      return data.ISC;
    case 'maker':
      return data.IMC;
    case 'shiten':
      return data.SCD;
    case 'center':
      return data.CCD;
    case 'group2':
      return data.G2CD;
    case 'group1':
      return data.G1CD;
    case 'tokuisaki':
      return data.TCD;
    case 'category':
      return data.ICC;
    case 'pb':
      return data.IBC;
    default:
      return "";
  }
}
const getGroupingNM = (key: 'item'|'shiire'|'maker'|'shiten'|'center'|'group2'|'group1'|'tokuisaki'|'category'|'pb'|string, data:RowData):string => {
  if(!data) {
    return '';
  }
  switch (key) {
    case 'shiire':
      return data.ISN;
    case 'maker':
      return data.IMN;
    case 'shiten':
      return data.SNM;
    case 'center':
      return data.CNM;
    case 'group2':
      return data.G2NM;
    case 'group1':
      return data.G1NM;
    case 'tokuisaki':
      return data.TNM;
    case 'category':
      return data.ICN;
    case 'pb':
      return data.IBN;
    default:
      return "";
  }
}


//Page Slice Export
//itemListTmp
export const itemListTmpSlice = createSliceContent("itemListTmp");
export const itemListDirectTmpSlice = createSliceContent("itemListDirectTmp");
export const salesItemListTmpSlice = createSliceContent("salesItemListTmp");
export const itemListReportSTmpSlice = createSliceContent("itemListReportSTmp");
export const itemListReportCutTmpSlice = createSliceContent("itemListReportCutTmp");
export const keppinItemListTmpSlice = createSliceContent("keppinItemListTmp");
