import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CodeName } from "@/store/common";

import { CenterInfo, ZaikoTotalData, ZaikoKeikaData, SyukkaData, listSortOrders, centerInfoAttributes } from '@/store/zaikosummary/zaikoSummaryCommon'
import * as zaikoSummaryRetrieve from "@/assets/apitype/zaikoSummaryRetrieve";
import { ZaikoSummaryColRowModel, rowKeys, rowKeys0602, teibanKeys, headersRow, headersRow0602, colDataType, rowDataType } from "@/components/zaikosummary/ZaikoSummaryTableModel";
import * as compareUtil from "@/util/compareUtil";
import * as arrayutil from "@/util/arrayUtil";
import * as editorUtil from "@/util/editorUtil";
import * as calcUtil from "@/util/calcUtil";

//0.00フォーマット
const formatterP00 = new Intl.NumberFormat('ja-JP', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 });
//0,0フォーマット
const formatterN0 = new Intl.NumberFormat('ja-JP', { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0 });

export type RowDataGroup2 = {
  syukkaData?: SyukkaData,

  zaikoTotalData?: ZaikoTotalData,

  zaikoKeikaDataAll?: ZaikoKeikaData,
  zaikoKeikaData1?: ZaikoKeikaData,
  zaikoKeikaData2?: ZaikoKeikaData,
  zaikoKeikaData3?: ZaikoKeikaData,
  zaikoKeikaData4?: ZaikoKeikaData,
  zaikoKeikaData5?: ZaikoKeikaData,
}
export type RowDataGroup = {
  centerInfo: CenterInfo,
  teibans: { '定番'?: RowDataGroup2, '定番外'?: RowDataGroup2, '未対応'?: RowDataGroup2, '全体'?: RowDataGroup2 },
}

export type RowInfo = {
  TP: "bumon" | "zaiko" | "total",
  no?: string,
  subno?: string,
  dataGroup:RowDataGroup,
  rowKey?: string,

  rowIndex?:number,  //同一集計行内でのrowKeyのindex
  rowIndexLast?:boolean, //同一集計行内での最後のrowKeyフラグ
  teiban?: string,
  teibanIndex?: number,
  teibanIndexLast?: boolean,

  syukkaData?: SyukkaData,
  zaikoTotalData?: ZaikoTotalData,
  zaikoKeikaData?: ZaikoKeikaData,
}

//Page State
export type ZaikoSummaryTmpState = {
  accordionOpen: boolean,

  makerList: CodeName[],  //メーカー
  bumonList: CodeName[], //部門
  centerList: CodeName[], //倉庫

  progress: Record<string, unknown>,
  retrieveParam: zaikoSummaryRetrieve.RequestParam,  //検索条件
  retrievedParam: zaikoSummaryRetrieve.RequestParam,  //検索条件(検索済み)
  shareParam: zaikoSummaryRetrieve.RequestParam,
  shareURL: string,  //共有URL

  dataGroupsAll: RowDataGroup[],
  dataGroups: RowDataGroup[],
  rowInfos: RowInfo[],
  fixedRowsTop :number,
  rows: any[][],
  mergeCells: {row: number, col: number, rowspan: number, colspan: number}[],

  lastUpdateTC: string | null,
  lastUpdateDC: string | null,
  lastUpdateHenpin: string | null,
  errorMessage: string | null,
};

export const initialState: ZaikoSummaryTmpState = {
  accordionOpen: true,
  makerList: [],
  bumonList: [],
  centerList: [],

  progress: {},
  retrieveParam: null,
  retrievedParam: null,
  shareParam: null,
  shareURL: '',  //共有URL

  dataGroupsAll: [],
  dataGroups: [],
  rowInfos: [],
  fixedRowsTop :0,
  rows: [],
  mergeCells: null,

  lastUpdateTC: null,
  lastUpdateDC: null,
  lastUpdateHenpin: null,
  errorMessage: null,
};

//Page Slice
export type ZaikoSummaryTmpReducer = {
  setAccordionOpen: (state:ZaikoSummaryTmpState, action: PayloadAction<boolean>) => void,
  setShareURL: (state: ZaikoSummaryTmpState, action: PayloadAction<string>) => void,
  setShareParam: (state: ZaikoSummaryTmpState, action: PayloadAction<zaikoSummaryRetrieve.RequestParam>) => void,
  putProgress: (state:ZaikoSummaryTmpState, action: PayloadAction<string>) => void,
  removeProgress: (state:ZaikoSummaryTmpState, action: PayloadAction<string>) => void,
  setErrorMessage: (state:ZaikoSummaryTmpState, action: PayloadAction<string>) => void,
  setMakerList: (state:ZaikoSummaryTmpState, action: PayloadAction<CodeName[]>) => void,
  setBumonList: (state:ZaikoSummaryTmpState, action: PayloadAction<CodeName[]>) => void,
  setCenterList: (state:ZaikoSummaryTmpState, action: PayloadAction<CodeName[]>) => void,
  setRetrieveParam: (state:ZaikoSummaryTmpState, action: PayloadAction<zaikoSummaryRetrieve.RequestParam>) => void,
  setRetrievedParam: (state:ZaikoSummaryTmpState, action: PayloadAction<zaikoSummaryRetrieve.RequestParam>) => void,

  searched: (state:ZaikoSummaryTmpState, action: PayloadAction<{param: zaikoSummaryRetrieve.RequestParam, centerInfos: CenterInfo[], 
    syukkaDatas: SyukkaData[], zaikoTotalDatas: ZaikoTotalData[], zaikoKeikaDatas: ZaikoKeikaData[], zaikoKeikaTotalDatas: ZaikoKeikaData[], colRowModel:ZaikoSummaryColRowModel, 
    listSortOrder: CodeName, listSortOrderDesc: boolean, visibleAttributes: string[][], visibleRowsKey: string[], visibleTeibanKey: string[], visibleOver: boolean,
  }>) => void,
  refreshTable: (state:ZaikoSummaryTmpState, action: PayloadAction<{colRowModel:ZaikoSummaryColRowModel, 
    listSortOrder: CodeName, listSortOrderDesc: boolean, visibleAttributes: string[][], visibleRowsKey: string[], visibleTeibanKey: string[], visibleOver: boolean,
  }>) => void,
  setLastUpdate: (state:ZaikoSummaryTmpState, action: PayloadAction<{lastUpdateTC: string, lastUpdateDC: string, lastUpdateHenpin: string}>) => void,
}

const createReducerContent = ():ZaikoSummaryTmpReducer => {return {
    setAccordionOpen(state:ZaikoSummaryTmpState, action: PayloadAction<boolean>) {
      state.accordionOpen = action.payload;
    },
    setShareURL(state: ZaikoSummaryTmpState, action: PayloadAction<string>) {
      state.shareURL = action.payload;
    },
    setShareParam(state: ZaikoSummaryTmpState, action: PayloadAction<zaikoSummaryRetrieve.RequestParam>) {
      state.shareParam = action.payload;
    },
    putProgress(state:ZaikoSummaryTmpState, action: PayloadAction<string>) {
      const key = action.payload;
      const progressNew = {...state.progress};
      progressNew[key] = true;
      state.progress = progressNew;
    },
    removeProgress(state:ZaikoSummaryTmpState, action: PayloadAction<string>) {
      const key = action.payload;
      const progressNew = {};
      Object.keys(state.progress).forEach(k => {
        if(key != k) {
          progressNew[k] = true;
        }
      })
      state.progress = progressNew;
    },
    setErrorMessage(state:ZaikoSummaryTmpState, action: PayloadAction<string>) {
      state.errorMessage = action.payload;
    },
    setMakerList(state:ZaikoSummaryTmpState, action: PayloadAction<CodeName[]>) {
      state.makerList = action.payload;
    },
    setBumonList(state:ZaikoSummaryTmpState, action: PayloadAction<CodeName[]>) {
      state.bumonList = action.payload;
    },
    setCenterList(state:ZaikoSummaryTmpState, action: PayloadAction<CodeName[]>) {
      state.centerList = action.payload;
    },
    setRetrieveParam(state:ZaikoSummaryTmpState, action: PayloadAction<zaikoSummaryRetrieve.RequestParam>) {
      state.retrieveParam = action.payload;
    },
    setRetrievedParam(state:ZaikoSummaryTmpState, action: PayloadAction<zaikoSummaryRetrieve.RequestParam>) {
      state.retrievedParam = action.payload;
    },

    searched(state:ZaikoSummaryTmpState, action: PayloadAction<{param: zaikoSummaryRetrieve.RequestParam, centerInfos: CenterInfo[], 
      syukkaDatas: SyukkaData[], zaikoTotalDatas: ZaikoTotalData[], zaikoKeikaDatas: ZaikoKeikaData[], zaikoKeikaTotalDatas: ZaikoKeikaData[], colRowModel:ZaikoSummaryColRowModel, 
      listSortOrder: CodeName, listSortOrderDesc: boolean, visibleAttributes: string[][], visibleRowsKey: string[], visibleTeibanKey: string[], visibleOver: boolean,
    }>) {
      const colRowModel = action.payload.colRowModel;
      const param = action.payload.param;

      let centerInfos = parseDataCenterInfo(action.payload.centerInfos);
      let syukkaDatas = parseDataSyukkaData(action.payload.syukkaDatas);
      let zaikoTotalDatas = parseDataZaikoTotalData(action.payload.zaikoTotalDatas);
      let zaikoKeikaDatas = parseDataZaikoKeikaData(action.payload.zaikoKeikaDatas);
      let zaikoKeikaTotalDatas = parseDataZaikoKeikaData(action.payload.zaikoKeikaTotalDatas);
      
      const listSortOrder = action.payload.listSortOrder;
      const listSortOrderDesc = action.payload.listSortOrderDesc;
      const visibleAttributes = action.payload.visibleAttributes;
      const visibleRowsKey = action.payload.visibleRowsKey;
      const visibleTeibanKey = action.payload.visibleTeibanKey;
      const visibleOver = action.payload.visibleOver;

      let dataGroupsAll = convertDataGroups(centerInfos, syukkaDatas, zaikoTotalDatas, zaikoKeikaDatas, zaikoKeikaTotalDatas);
      dataGroupsAll = calcDataGroups(dataGroupsAll);

      let dataGroups = filterDataGroups(dataGroupsAll);
      dataGroups = sortDataGroups(dataGroups, listSortOrder, listSortOrderDesc, visibleRowsKey);
      const [rowInfos, fixedRowsTop] = convertRowInfos(dataGroups, listSortOrder, visibleRowsKey, visibleTeibanKey, visibleOver);
      
      //store更新
      state.dataGroupsAll = dataGroupsAll;
      state.dataGroups = dataGroups;
      state.rowInfos = rowInfos;
      state.fixedRowsTop = fixedRowsTop;
      state.mergeCells = createMergeCells(rowInfos, colRowModel);
      state.rows = convertRows(rowInfos, colRowModel, visibleAttributes);
    },
    refreshTable(state:ZaikoSummaryTmpState, action: PayloadAction<{colRowModel:ZaikoSummaryColRowModel, 
      listSortOrder: CodeName, listSortOrderDesc: boolean, visibleAttributes: string[][], visibleRowsKey: string[], visibleTeibanKey: string[], visibleOver: boolean,
      }>){
      console.log('refreshTable');
      if(!state.dataGroups || state.dataGroups.length == 0) {
        return;
      }
      const listSortOrder = action.payload.listSortOrder;
      const listSortOrderDesc = action.payload.listSortOrderDesc;
      const visibleAttributes = action.payload.visibleAttributes;
      const visibleRowsKey = action.payload.visibleRowsKey;
      const visibleTeibanKey = action.payload.visibleTeibanKey;
      const visibleOver = action.payload.visibleOver;
      const colRowModel = action.payload.colRowModel;

      let dataGroups = state.dataGroups;
      dataGroups = sortDataGroups(dataGroups, listSortOrder, listSortOrderDesc, visibleRowsKey);
      const [rowInfos, fixedRowsTop] = convertRowInfos(dataGroups, listSortOrder, visibleRowsKey, visibleTeibanKey, visibleOver);
      //store更新
      state.rowInfos = rowInfos;
      state.fixedRowsTop = fixedRowsTop;
      state.mergeCells = createMergeCells(rowInfos, colRowModel);
      state.rows = convertRows(rowInfos, colRowModel, visibleAttributes);
    },
    setLastUpdate(state:ZaikoSummaryTmpState, action: PayloadAction<{lastUpdateTC: string, lastUpdateDC: string, lastUpdateHenpin: string}>) {
      state.lastUpdateTC= action.payload.lastUpdateTC;
      state.lastUpdateDC = action.payload.lastUpdateDC;
      state.lastUpdateHenpin = action.payload.lastUpdateHenpin;
    },

}};

const parseDataCenterInfo = (datas:CenterInfo[]): CenterInfo[] => {
  datas = [...datas];
  datas.forEach((data) => {
    // if(typeof data.termStartDate === 'string') data.blIrisu = parseInt(data.blIrisu);
    // if(typeof data.termEndDate === 'string') data.blIrisu = parseInt(data.blIrisu);

  });
  return datas;
}
const parseDataSyukkaData = (datas: SyukkaData[]): SyukkaData[] => {
  datas = [...datas];
  datas.forEach((data) => {
    if(typeof data.syukkaAmtAve === 'string') data.syukkaAmtAve = parseFloat(data.syukkaAmtAve);
    data.syukkaAmtAve = data.syukkaAmtAve ? Math.round(data.syukkaAmtAve) : null;  //整数に変換
    if(typeof data.syukkaQtyAve === 'string') data.syukkaQtyAve = parseFloat(data.syukkaQtyAve);
    if(typeof data.syukkaAmtTtl === 'string') data.syukkaAmtTtl = parseFloat(data.syukkaAmtTtl);
    data.syukkaAmtTtl = data.syukkaAmtTtl ? Math.round(data.syukkaAmtTtl) : null;  //整数に変換
    if(typeof data.syukkaQtyTtl === 'string') data.syukkaQtyTtl = parseFloat(data.syukkaQtyTtl);
    if(typeof data.daysCountOfToday === 'string') data.daysCountOfToday = parseInt(data.daysCountOfToday);
    if(typeof data.daysCountOfMonth === 'string') data.daysCountOfMonth = parseInt(data.daysCountOfMonth);
  });
  return datas;
}
const parseDataZaikoTotalData = (datas: ZaikoTotalData[]): ZaikoTotalData[] => {
  datas = [...datas];
  datas.forEach((data) => {
    if(typeof data.zaikoTtlPrdAll === 'string') data.zaikoTtlPrdAll = parseInt(data.zaikoTtlPrdAll);
    data.zaikoTtlPrdAll = data.zaikoTtlPrdAll ? data.zaikoTtlPrdAll : null;  //0をnullに変換
    if(typeof data.zaikoTtlQtyAll === 'string') data.zaikoTtlQtyAll = parseFloat(data.zaikoTtlQtyAll);
    if(typeof data.zaikoTtlAmtAll === 'string') data.zaikoTtlAmtAll = parseFloat(data.zaikoTtlAmtAll);
    data.zaikoTtlAmtAll = data.zaikoTtlAmtAll ? Math.round(data.zaikoTtlAmtAll) : null;  //整数に変換
    data.zaikoTtlRatAll = calcUtil.divide(data.zaikoTtlAmtAll, data.zaikoTtlAmtAll);

    if(typeof data.zaikoTtlPrd1 === 'string') data.zaikoTtlPrd1 = parseInt(data.zaikoTtlPrd1);
    data.zaikoTtlPrd1 = data.zaikoTtlPrd1 ? data.zaikoTtlPrd1 : null;  //0をnullに変換
    if(typeof data.zaikoTtlQty1 === 'string') data.zaikoTtlQty1 = parseFloat(data.zaikoTtlQty1);
    if(typeof data.zaikoTtlAmt1 === 'string') data.zaikoTtlAmt1 = parseFloat(data.zaikoTtlAmt1);
    data.zaikoTtlAmt1 = data.zaikoTtlAmt1 ? Math.round(data.zaikoTtlAmt1) : null;  //整数に変換
    data.zaikoTtlRat1 = calcUtil.divide(data.zaikoTtlAmt1, data.zaikoTtlAmtAll);

    if(typeof data.zaikoTtlPrd2 === 'string') data.zaikoTtlPrd2 = parseInt(data.zaikoTtlPrd2);
    data.zaikoTtlPrd2 = data.zaikoTtlPrd2 ? data.zaikoTtlPrd2 : null;  //0をnullに変換
    if(typeof data.zaikoTtlQty2 === 'string') data.zaikoTtlQty2 = parseFloat(data.zaikoTtlQty2);
    if(typeof data.zaikoTtlAmt2 === 'string') data.zaikoTtlAmt2 = parseFloat(data.zaikoTtlAmt2);
    data.zaikoTtlAmt2 = data.zaikoTtlAmt2 ? Math.round(data.zaikoTtlAmt2) : null;  //整数に変換
    data.zaikoTtlRat2 = calcUtil.divide(data.zaikoTtlAmt2, data.zaikoTtlAmtAll);

    if(typeof data.zaikoTtlPrd3 === 'string') data.zaikoTtlPrd3 = parseInt(data.zaikoTtlPrd3);
    data.zaikoTtlPrd3 = data.zaikoTtlPrd3 ? data.zaikoTtlPrd3 : null;  //0をnullに変換
    if(typeof data.zaikoTtlQty3 === 'string') data.zaikoTtlQty3 = parseFloat(data.zaikoTtlQty3);
    if(typeof data.zaikoTtlAmt3 === 'string') data.zaikoTtlAmt3 = parseFloat(data.zaikoTtlAmt3);
    data.zaikoTtlAmt3 = data.zaikoTtlAmt3 ? Math.round(data.zaikoTtlAmt3) : null;  //整数に変換
    data.zaikoTtlRat3 = calcUtil.divide(data.zaikoTtlAmt3, data.zaikoTtlAmtAll);

    if(typeof data.zaikoTtlPrd4 === 'string') data.zaikoTtlPrd4 = parseInt(data.zaikoTtlPrd4);
    data.zaikoTtlPrd4 = data.zaikoTtlPrd4 ? data.zaikoTtlPrd4 : null;  //0をnullに変換
    if(typeof data.zaikoTtlQty4 === 'string') data.zaikoTtlQty4 = parseFloat(data.zaikoTtlQty4);
    if(typeof data.zaikoTtlAmt4 === 'string') data.zaikoTtlAmt4 = parseFloat(data.zaikoTtlAmt4);
    data.zaikoTtlAmt4 = data.zaikoTtlAmt4 ? Math.round(data.zaikoTtlAmt4) : null;  //整数に変換
    data.zaikoTtlRat4 = calcUtil.divide(data.zaikoTtlAmt4, data.zaikoTtlAmtAll);

    if(typeof data.zaikoTtlPrd5 === 'string') data.zaikoTtlPrd5 = parseInt(data.zaikoTtlPrd5);
    data.zaikoTtlPrd5 = data.zaikoTtlPrd5 ? data.zaikoTtlPrd5 : null;  //0をnullに変換
    if(typeof data.zaikoTtlQty5 === 'string') data.zaikoTtlQty5 = parseFloat(data.zaikoTtlQty5);
    if(typeof data.zaikoTtlAmt5 === 'string') data.zaikoTtlAmt5 = parseFloat(data.zaikoTtlAmt5);
    data.zaikoTtlAmt5 = data.zaikoTtlAmt5 ? Math.round(data.zaikoTtlAmt5) : null;  //整数に変換
    data.zaikoTtlRat5 = calcUtil.divide(data.zaikoTtlAmt5, data.zaikoTtlAmtAll);

  });
  return datas;
}
const parseDataZaikoKeikaData = (datas: ZaikoKeikaData[]): ZaikoKeikaData[] => {
  datas = [...datas];
  datas.forEach((data) => {
    if(typeof data.zaiko101Prd === 'string') data.zaiko101Prd = parseInt(data.zaiko101Prd);
    data.zaiko101Prd = data.zaiko101Prd ? data.zaiko101Prd : null;  //0をnullに変換
    if(typeof data.zaiko101Qty === 'string') data.zaiko101Qty = parseFloat(data.zaiko101Qty);
    if(typeof data.zaiko101Amt === 'string') data.zaiko101Amt = parseFloat(data.zaiko101Amt);
    data.zaiko101Amt = data.zaiko101Amt ? Math.round(data.zaiko101Amt) : null;  //整数に変換
    if(typeof data.zaiko101Rat === 'string') data.zaiko101Rat = parseFloat(data.zaiko101Rat);

    if(typeof data.zaiko031Prd === 'string') data.zaiko031Prd = parseInt(data.zaiko031Prd);
    data.zaiko031Prd = data.zaiko031Prd ? data.zaiko031Prd : null;  //0をnullに変換
    if(typeof data.zaiko031Qty === 'string') data.zaiko031Qty = parseFloat(data.zaiko031Qty);
    if(typeof data.zaiko031Amt === 'string') data.zaiko031Amt = parseFloat(data.zaiko031Amt);
    data.zaiko031Amt = data.zaiko031Amt ? Math.round(data.zaiko031Amt) : null;  //整数に変換
    if(typeof data.zaiko031Rat === 'string') data.zaiko031Rat = parseFloat(data.zaiko031Rat);

    if(typeof data.zaiko011Prd === 'string') data.zaiko011Prd = parseInt(data.zaiko011Prd);
    data.zaiko011Prd = data.zaiko011Prd ? data.zaiko011Prd : null;  //0をnullに変換
    if(typeof data.zaiko011Qty === 'string') data.zaiko011Qty = parseFloat(data.zaiko011Qty);
    if(typeof data.zaiko011Amt === 'string') data.zaiko011Amt = parseFloat(data.zaiko011Amt);
    data.zaiko011Amt = data.zaiko011Amt ? Math.round(data.zaiko011Amt) : null;  //整数に変換
    if(typeof data.zaiko011Rat === 'string') data.zaiko011Rat = parseFloat(data.zaiko011Rat);

    if(typeof data.zaiko000Prd === 'string') data.zaiko000Prd = parseInt(data.zaiko000Prd);
    data.zaiko000Prd = data.zaiko000Prd ? data.zaiko000Prd : null;  //0をnullに変換
    if(typeof data.zaiko000Qty === 'string') data.zaiko000Qty = parseFloat(data.zaiko000Qty);
    if(typeof data.zaiko000Amt === 'string') data.zaiko000Amt = parseFloat(data.zaiko000Amt);
    data.zaiko000Amt = data.zaiko000Amt ? Math.round(data.zaiko000Amt) : null;  //整数に変換
    if(typeof data.zaiko000Rat === 'string') data.zaiko000Rat = parseFloat(data.zaiko000Rat);

    if(typeof data.zaiko999Prd === 'string') data.zaiko999Prd = parseInt(data.zaiko999Prd);
    data.zaiko999Prd = data.zaiko999Prd ? data.zaiko999Prd : null;  //0をnullに変換
    if(typeof data.zaiko999Qty === 'string') data.zaiko999Qty = parseFloat(data.zaiko999Qty);
    if(typeof data.zaiko999Amt === 'string') data.zaiko999Amt = parseFloat(data.zaiko999Amt);
    data.zaiko999Amt = data.zaiko999Amt ? Math.round(data.zaiko999Amt) : null;  //整数に変換
    if(typeof data.zaiko999Rat === 'string') data.zaiko999Rat = parseFloat(data.zaiko999Rat);

    if (typeof data.over === 'string') data.over = parseFloat(data.over);
    data.over = data.over ? Math.round(data.over) : null;  //整数に変換
    if (typeof data.over1_2 === 'string') data.over1_2 = parseFloat(data.over1_2);
    data.over1_2 = data.over1_2 ? Math.round(data.over1_2) : null;  //整数に変換
    if (typeof data.over1_3 === 'string') data.over1_3 = parseFloat(data.over1_3);
    data.over1_3 = data.over1_3 ? Math.round(data.over1_3) : null;  //整数に変換
  });
  return datas;
}
//RowDataGroupに変換
const convertDataGroups = (centerInfos: CenterInfo[], syukkaDatas: SyukkaData[], zaikoTotalDatas: ZaikoTotalData[], zaikoKeikaDatas: ZaikoKeikaData[], zaikoKeikaTotalDatas: ZaikoKeikaData[]): RowDataGroup[] => {
  //商品、センター順でソート
  centerInfos = centerInfos.sort((a,b) => {
    let comp = 0;
    if(comp == 0) {
      comp = compareUtil.compareString(a.centerCD, b.centerCD, true)
    }
    return comp;
  });

  //出荷データのマップ
  const syukkaDatasMap = {};
  syukkaDatas.forEach(syukkaData => {
    {
      const key = `${syukkaData.centerCD} ${syukkaData.teiban}`;
      syukkaDatasMap[key] = syukkaData;
    }
    {
      const key = `${syukkaData.centerCD}`;
      if (syukkaDatasMap[key]) {
        ['syukkaAmtAve', 'syukkaQtyAve', 'syukkaAmtTtl', 'syukkaQtyTtl'].forEach(key2 => {
          syukkaDatasMap[key] = !syukkaData[key2] ? syukkaDatasMap[key] : {
            ...syukkaDatasMap[key],
            [key2]: syukkaDatasMap[key][key2] + syukkaData[key2],
          };
        });
      } else {
        syukkaDatasMap[key] = syukkaData;
      }
    }
  });

  //在庫データのマップ
  const zaikoTotalDatasMap = {};
  zaikoTotalDatas.forEach(zaikoTotalData => {
    {
      const key = `${zaikoTotalData.centerCD} ${zaikoTotalData.teiban}`;
      zaikoTotalDatasMap[key] = zaikoTotalData;
    }
    {
      const key = `${zaikoTotalData.centerCD}`;
      if (zaikoTotalDatasMap[key]) {
        [
          'zaikoTtlPrdAll', 'zaikoTtlQtyAll', 'zaikoTtlAmtAll', 'zaikoTtlRatAll',
          'zaikoTtlPrd1', 'zaikoTtlQty1', 'zaikoTtlAmt1', 'zaikoTtlRat1',
          'zaikoTtlPrd2', 'zaikoTtlQty2', 'zaikoTtlAmt2', 'zaikoTtlRat2',
          'zaikoTtlPrd3', 'zaikoTtlQty3', 'zaikoTtlAmt3', 'zaikoTtlRat3',
          'zaikoTtlPrd4', 'zaikoTtlQty4', 'zaikoTtlAmt4', 'zaikoTtlRat4',
          'zaikoTtlPrd5', 'zaikoTtlQty5', 'zaikoTtlAmt5', 'zaikoTtlRat5',
        ].forEach(key2 => {
          zaikoTotalDatasMap[key] = !zaikoTotalData[key2] ? zaikoTotalDatasMap[key] : {
            ...zaikoTotalDatasMap[key],
            [key2]: zaikoTotalDatasMap[key][key2] + zaikoTotalData[key2],
          };
        });
      } else {
        zaikoTotalDatasMap[key] = zaikoTotalData;
      }
    }
  });

  const zaikoKeikaDatasMap = {};
  zaikoKeikaDatas.forEach(zaikoKeikaData => {
    {
      const key = `${zaikoKeikaData.centerCD} ${zaikoKeikaData.zaikoKbn} ${zaikoKeikaData.teiban}`;
      zaikoKeikaDatasMap[key] = zaikoKeikaData;
    }
    {
      const key = `${zaikoKeikaData.centerCD} ${zaikoKeikaData.zaikoKbn}`;
      if (zaikoKeikaDatasMap[key]) {
        [
          'zaiko101Prd', 'zaiko101Qty', 'zaiko101Amt', 'zaiko101Rat',
          'zaiko031Prd', 'zaiko031Qty', 'zaiko031Amt', 'zaiko031Rat',
          'zaiko011Prd', 'zaiko011Qty', 'zaiko011Amt', 'zaiko011Rat',
          'zaiko000Prd', 'zaiko000Qty', 'zaiko000Amt', 'zaiko000Rat',
          'zaiko999Prd', 'zaiko999Qty', 'zaiko999Amt', 'zaiko999Rat',
          'over1_3', 'over1_2', 'over',
        ].forEach(key2 => {
          zaikoKeikaDatasMap[key] = !zaikoKeikaData[key2] ? zaikoKeikaDatasMap[key] : {
            ...zaikoKeikaDatasMap[key],
            [key2]: zaikoKeikaDatasMap[key][key2] + zaikoKeikaData[key2],
          };
        });
      } else {
        zaikoKeikaDatasMap[key] = zaikoKeikaData;
      }
    }
  });
  zaikoKeikaTotalDatas.forEach(zaikoKeikaData => {
    {
      const key = `${zaikoKeikaData.centerCD} ${zaikoKeikaData.zaikoKbn} ${zaikoKeikaData.teiban}`;
      zaikoKeikaDatasMap[key] = zaikoKeikaData;
    }
    {
      const key = `${zaikoKeikaData.centerCD} ${zaikoKeikaData.zaikoKbn}`;
      if (zaikoKeikaDatasMap[key]) {
        [
          'zaiko101Prd', 'zaiko101Qty', 'zaiko101Amt', 'zaiko101Rat',
          'zaiko031Prd', 'zaiko031Qty', 'zaiko031Amt', 'zaiko031Rat',
          'zaiko011Prd', 'zaiko011Qty', 'zaiko011Amt', 'zaiko011Rat',
          'zaiko000Prd', 'zaiko000Qty', 'zaiko000Amt', 'zaiko000Rat',
          'zaiko999Prd', 'zaiko999Qty', 'zaiko999Amt', 'zaiko999Rat',
          'over1_3', 'over1_2', 'over',
        ].forEach(key2 => {
          zaikoKeikaDatasMap[key] = !zaikoKeikaData[key2] ? zaikoKeikaDatasMap[key] : {
            ...zaikoKeikaDatasMap[key],
            [key2]: zaikoKeikaDatasMap[key][key2] + zaikoKeikaData[key2],
          };
        });
      } else {
        zaikoKeikaDatasMap[key] = zaikoKeikaData;
      }
    }
  });
  return centerInfos.map((centerInfo, i) => {
    const teiban = syukkaDatasMap[`${centerInfo.centerCD} 定番`] ?? null;
    centerInfos[i].teiban = teiban ? '済み' : '未対応';
    const teibans = ['定番', '定番外'].map(teiban => {
      return {
        syukkaData: syukkaDatasMap[`${centerInfo.centerCD} ${teiban}`] ?? {},

        zaikoTotalData: zaikoTotalDatasMap[`${centerInfo.centerCD} ${teiban}`] ?? {},

        zaikoKeikaDataAll: zaikoKeikaDatasMap[`${centerInfo.centerCD} all ${teiban}`] ?? {},
        zaikoKeikaData1: zaikoKeikaDatasMap[`${centerInfo.centerCD} 1 ${teiban}`] ?? {},
        zaikoKeikaData2: zaikoKeikaDatasMap[`${centerInfo.centerCD} 2 ${teiban}`] ?? {},
        zaikoKeikaData3: zaikoKeikaDatasMap[`${centerInfo.centerCD} 3 ${teiban}`] ?? {},
        zaikoKeikaData4: zaikoKeikaDatasMap[`${centerInfo.centerCD} 4 ${teiban}`] ?? {},
        zaikoKeikaData5: zaikoKeikaDatasMap[`${centerInfo.centerCD} 5 ${teiban}`] ?? {},
      };
    });
    //
    const teibanAll = {
      syukkaData: syukkaDatasMap[`${centerInfo.centerCD}`] ?? {},

      zaikoTotalData: zaikoTotalDatasMap[`${centerInfo.centerCD}`] ?? {},

      zaikoKeikaDataAll: zaikoKeikaDatasMap[`${centerInfo.centerCD} all`] ?? {},
      zaikoKeikaData1: zaikoKeikaDatasMap[`${centerInfo.centerCD} 1`] ?? {},
      zaikoKeikaData2: zaikoKeikaDatasMap[`${centerInfo.centerCD} 2`] ?? {},
      zaikoKeikaData3: zaikoKeikaDatasMap[`${centerInfo.centerCD} 3`] ?? {},
      zaikoKeikaData4: zaikoKeikaDatasMap[`${centerInfo.centerCD} 4`] ?? {},
      zaikoKeikaData5: zaikoKeikaDatasMap[`${centerInfo.centerCD} 5`] ?? {},
    };

    return {
      centerInfo: centerInfo,
      teibans: teiban ? { '定番': teibans[0], '定番外': teibans[1], '全体': teibanAll } : { '未対応': teibans[1] },
    };
  });
}
//フィルタ
const filterDataGroups = (dataGroups:RowDataGroup[]): RowDataGroup[] => {
    return dataGroups;
}
//並び順変更
const sortDataGroups = (dataGroups: RowDataGroup[], listSortOrder: CodeName, listSortOrderDesc: boolean, visibleRowsKey: string[]): RowDataGroup[] => {
  if(!dataGroups) {
    return dataGroups;
  }
  const getTotalValue = (o: RowDataGroup, sortKey: string, visibleRowsKey: string[], teiban?: string): number => {
    if (visibleRowsKey.includes('total')) {
      const key = o.centerInfo.teiban === '未対応' ? '未対応' : teiban ?? '定番';
      return o.teibans[key].zaikoTotalData[sortKey];
    }
    const keys = {
      zaikoTtlPrdAll: 'zaikoTtlPrd',  //合計 ｱｲﾃﾑ数
      zaikoTtlQtyAll: 'zaikoTtlQty',  //合計 在庫BL数
      zaikoTtlAmtAll: 'zaikoTtlAmt',  //合計 在庫金額
      zaikoTtlRatAll: 'zaikoTtlRat',  //合計 在庫区分比
    };
    let value: number = 0;
    for (let i = 1; i <= 5; i++) {
      const key = o.centerInfo.teiban === '未対応' ? '未対応' : teiban ?? '定番';
      value += visibleRowsKey.includes('zaiko' + i) ? o.teibans[key].zaikoTotalData[keys[sortKey] + i] : 0;
    }
    return value;
  }
  let asc = !listSortOrderDesc;
  let getSortKey1 = (o:RowDataGroup):string|number => 
    !o || !o.centerInfo ? null : 
    !listSortOrder ? o.centerInfo.centerCD :
    o.centerInfo[listSortOrder.option1] ? o.centerInfo[listSortOrder.option1] :
    getTotalValue(o, listSortOrder.option1, visibleRowsKey, listSortOrder.option6)
  ;
  let getSortKey2 = (o:RowDataGroup):string|number => 
    !o || !o.centerInfo ? null : 
    !listSortOrder ? o.centerInfo.centerCD :
    o.centerInfo[listSortOrder.option2] ? o.centerInfo[listSortOrder.option2] :
    getTotalValue(o, listSortOrder.option2, visibleRowsKey, listSortOrder.option6)
  ;
  let getSortKey3 = (o:RowDataGroup):string|number => 
    !o || !o.centerInfo ? null : 
    !listSortOrder ? o.centerInfo.centerCD :
    o.centerInfo[listSortOrder.option3] ? o.centerInfo[listSortOrder.option3] :
    getTotalValue(o, listSortOrder.option3, visibleRowsKey, listSortOrder.option6)
  ;
  let getSortKey4 = (o:RowDataGroup):string|number => 
    !o || !o.centerInfo ? null : 
    !listSortOrder ? o.centerInfo.centerCD :
    o.centerInfo[listSortOrder.option4] ? o.centerInfo[listSortOrder.option4] :
    getTotalValue(o, listSortOrder.option4, visibleRowsKey, listSortOrder.option6)
  ;
  dataGroups.sort((a: RowDataGroup, b: RowDataGroup) => {
    //第1弾ソート項目
    let va = getSortKey1(a);
    let vb = getSortKey1(b);
    let comp = compareUtil.compareAny2(va, vb, asc);
    //第2弾ソート項目
    if(comp == 0) {
      va = getSortKey2(a);
      vb = getSortKey2(b);
      comp = compareUtil.compareAny2(va, vb, asc);
    }
    //第3弾ソート項目
    if(comp == 0) {
      va = getSortKey3(a);
      vb = getSortKey3(b);
      comp = compareUtil.compareAny2(va, vb, asc);
    }
    //第4弾ソート項目
    if(comp == 0) {
      va = getSortKey4(a);
      vb = getSortKey4(b);
      comp = compareUtil.compareAny2(va, vb, asc);
    }
    return comp;
  });
  return dataGroups;
}
//RowDataGroupの計算
const calcDataGroups = (dataGroups:RowDataGroup[]): RowDataGroup[] => {
  //構成比計算
  dataGroups.forEach(dataGroup => {
    const keys = dataGroup.centerInfo.teiban === '済み' ? ['定番', '定番外', '全体'] : ['未対応'];
    keys.forEach(teiban => {
      if (!dataGroup.teibans[teiban].zaikoTotalData) {
        return;
      }

      if (dataGroup.teibans[teiban].zaikoKeikaData1) {
        dataGroup.teibans[teiban].zaikoKeikaData1.zaiko999Rat = calcUtil.divide(dataGroup.teibans[teiban].zaikoKeikaData1?.zaiko999Amt, dataGroup.teibans[teiban].zaikoTotalData?.zaikoTtlAmt1);
        dataGroup.teibans[teiban].zaikoKeikaData1.zaiko101Rat = calcUtil.divide(dataGroup.teibans[teiban].zaikoKeikaData1?.zaiko101Amt, dataGroup.teibans[teiban].zaikoTotalData?.zaikoTtlAmt1);
        dataGroup.teibans[teiban].zaikoKeikaData1.zaiko031Rat = calcUtil.divide(dataGroup.teibans[teiban].zaikoKeikaData1?.zaiko031Amt, dataGroup.teibans[teiban].zaikoTotalData?.zaikoTtlAmt1);
        dataGroup.teibans[teiban].zaikoKeikaData1.zaiko011Rat = calcUtil.divide(dataGroup.teibans[teiban].zaikoKeikaData1?.zaiko011Amt, dataGroup.teibans[teiban].zaikoTotalData?.zaikoTtlAmt1);
        dataGroup.teibans[teiban].zaikoKeikaData1.zaiko000Rat = calcUtil.divide(dataGroup.teibans[teiban].zaikoKeikaData1?.zaiko000Amt, dataGroup.teibans[teiban].zaikoTotalData?.zaikoTtlAmt1);
      }

      if (dataGroup.teibans[teiban].zaikoKeikaData2) {
        dataGroup.teibans[teiban].zaikoKeikaData2.zaiko999Rat = calcUtil.divide(dataGroup.teibans[teiban].zaikoKeikaData2?.zaiko999Amt, dataGroup.teibans[teiban].zaikoTotalData?.zaikoTtlAmt2);
        dataGroup.teibans[teiban].zaikoKeikaData2.zaiko101Rat = calcUtil.divide(dataGroup.teibans[teiban].zaikoKeikaData2?.zaiko101Amt, dataGroup.teibans[teiban].zaikoTotalData?.zaikoTtlAmt2);
        dataGroup.teibans[teiban].zaikoKeikaData2.zaiko031Rat = calcUtil.divide(dataGroup.teibans[teiban].zaikoKeikaData2?.zaiko031Amt, dataGroup.teibans[teiban].zaikoTotalData?.zaikoTtlAmt2);
        dataGroup.teibans[teiban].zaikoKeikaData2.zaiko011Rat = calcUtil.divide(dataGroup.teibans[teiban].zaikoKeikaData2?.zaiko011Amt, dataGroup.teibans[teiban].zaikoTotalData?.zaikoTtlAmt2);
        dataGroup.teibans[teiban].zaikoKeikaData2.zaiko000Rat = calcUtil.divide(dataGroup.teibans[teiban].zaikoKeikaData2?.zaiko000Amt, dataGroup.teibans[teiban].zaikoTotalData?.zaikoTtlAmt2);
      }

      if (dataGroup.teibans[teiban].zaikoKeikaData3) {
        dataGroup.teibans[teiban].zaikoKeikaData3.zaiko999Rat = calcUtil.divide(dataGroup.teibans[teiban].zaikoKeikaData3?.zaiko999Amt, dataGroup.teibans[teiban].zaikoTotalData?.zaikoTtlAmt3);
        dataGroup.teibans[teiban].zaikoKeikaData3.zaiko101Rat = calcUtil.divide(dataGroup.teibans[teiban].zaikoKeikaData3?.zaiko101Amt, dataGroup.teibans[teiban].zaikoTotalData?.zaikoTtlAmt3);
        dataGroup.teibans[teiban].zaikoKeikaData3.zaiko031Rat = calcUtil.divide(dataGroup.teibans[teiban].zaikoKeikaData3?.zaiko031Amt, dataGroup.teibans[teiban].zaikoTotalData?.zaikoTtlAmt3);
        dataGroup.teibans[teiban].zaikoKeikaData3.zaiko011Rat = calcUtil.divide(dataGroup.teibans[teiban].zaikoKeikaData3?.zaiko011Amt, dataGroup.teibans[teiban].zaikoTotalData?.zaikoTtlAmt3);
        dataGroup.teibans[teiban].zaikoKeikaData3.zaiko000Rat = calcUtil.divide(dataGroup.teibans[teiban].zaikoKeikaData3?.zaiko000Amt, dataGroup.teibans[teiban].zaikoTotalData?.zaikoTtlAmt3);
      }

      if (dataGroup.teibans[teiban].zaikoKeikaData4) {
        dataGroup.teibans[teiban].zaikoKeikaData4.zaiko999Rat = calcUtil.divide(dataGroup.teibans[teiban].zaikoKeikaData4?.zaiko999Amt, dataGroup.teibans[teiban].zaikoTotalData?.zaikoTtlAmt4);
        dataGroup.teibans[teiban].zaikoKeikaData4.zaiko101Rat = calcUtil.divide(dataGroup.teibans[teiban].zaikoKeikaData4?.zaiko101Amt, dataGroup.teibans[teiban].zaikoTotalData?.zaikoTtlAmt4);
        dataGroup.teibans[teiban].zaikoKeikaData4.zaiko031Rat = calcUtil.divide(dataGroup.teibans[teiban].zaikoKeikaData4?.zaiko031Amt, dataGroup.teibans[teiban].zaikoTotalData?.zaikoTtlAmt4);
        dataGroup.teibans[teiban].zaikoKeikaData4.zaiko011Rat = calcUtil.divide(dataGroup.teibans[teiban].zaikoKeikaData4?.zaiko011Amt, dataGroup.teibans[teiban].zaikoTotalData?.zaikoTtlAmt4);
        dataGroup.teibans[teiban].zaikoKeikaData4.zaiko000Rat = calcUtil.divide(dataGroup.teibans[teiban].zaikoKeikaData4?.zaiko000Amt, dataGroup.teibans[teiban].zaikoTotalData?.zaikoTtlAmt4);
      }

      if (dataGroup.teibans[teiban].zaikoKeikaData5) {
        dataGroup.teibans[teiban].zaikoKeikaData5.zaiko999Rat = calcUtil.divide(dataGroup.teibans[teiban].zaikoKeikaData5?.zaiko999Amt, dataGroup.teibans[teiban].zaikoTotalData?.zaikoTtlAmt5);
        dataGroup.teibans[teiban].zaikoKeikaData5.zaiko101Rat = calcUtil.divide(dataGroup.teibans[teiban].zaikoKeikaData5?.zaiko101Amt, dataGroup.teibans[teiban].zaikoTotalData?.zaikoTtlAmt5);
        dataGroup.teibans[teiban].zaikoKeikaData5.zaiko031Rat = calcUtil.divide(dataGroup.teibans[teiban].zaikoKeikaData5?.zaiko031Amt, dataGroup.teibans[teiban].zaikoTotalData?.zaikoTtlAmt5);
        dataGroup.teibans[teiban].zaikoKeikaData5.zaiko011Rat = calcUtil.divide(dataGroup.teibans[teiban].zaikoKeikaData5?.zaiko011Amt, dataGroup.teibans[teiban].zaikoTotalData?.zaikoTtlAmt5);
        dataGroup.teibans[teiban].zaikoKeikaData5.zaiko000Rat = calcUtil.divide(dataGroup.teibans[teiban].zaikoKeikaData5?.zaiko000Amt, dataGroup.teibans[teiban].zaikoTotalData?.zaikoTtlAmt5);
      }

      if (dataGroup.teibans[teiban].zaikoKeikaDataAll) {
        dataGroup.teibans[teiban].zaikoKeikaDataAll.zaiko999Rat = calcUtil.divide(dataGroup.teibans[teiban].zaikoKeikaDataAll?.zaiko999Amt, dataGroup.teibans[teiban].zaikoTotalData?.zaikoTtlAmtAll);
        dataGroup.teibans[teiban].zaikoKeikaDataAll.zaiko101Rat = calcUtil.divide(dataGroup.teibans[teiban].zaikoKeikaDataAll?.zaiko101Amt, dataGroup.teibans[teiban].zaikoTotalData?.zaikoTtlAmtAll);
        dataGroup.teibans[teiban].zaikoKeikaDataAll.zaiko031Rat = calcUtil.divide(dataGroup.teibans[teiban].zaikoKeikaDataAll?.zaiko031Amt, dataGroup.teibans[teiban].zaikoTotalData?.zaikoTtlAmtAll);
        dataGroup.teibans[teiban].zaikoKeikaDataAll.zaiko011Rat = calcUtil.divide(dataGroup.teibans[teiban].zaikoKeikaDataAll?.zaiko011Amt, dataGroup.teibans[teiban].zaikoTotalData?.zaikoTtlAmtAll);
        dataGroup.teibans[teiban].zaikoKeikaDataAll.zaiko000Rat = calcUtil.divide(dataGroup.teibans[teiban].zaikoKeikaDataAll?.zaiko000Amt, dataGroup.teibans[teiban].zaikoTotalData?.zaikoTtlAmtAll);
      }

      //回転数・・・1日平均配送売上（建値）×月間日数÷在庫金額
      if (dataGroup.teibans[teiban].syukkaData) {
        dataGroup.teibans[teiban].syukkaData.cycleCnt = calcUtil.divide(calcUtil.multiply(dataGroup.teibans[teiban].syukkaData?.syukkaAmtAve, dataGroup.teibans[teiban].syukkaData?.daysCountOfMonth), dataGroup.teibans[teiban].zaikoTotalData?.zaikoTtlAmtAll);
      }
    });
  });

  return dataGroups;
}

//行情報に変換
const convertRowInfos = (dataGroups: RowDataGroup[], listSortOrder: CodeName, visibleRowsKey: string[], visibleTeibanKey: string[], visibleOver: boolean): [RowInfo[], number] => {
  const targetRowsKeys = arrayutil.and(rowKeys, visibleRowsKey);
  const targetTeibanKeys = arrayutil.and(teibanKeys, visibleTeibanKey);

  const rowInfos:RowInfo[] = [];
  let fixedRowsTop:number = 0;

  //明細行
  fixedRowsTop = rowInfos.length;

  let beforeDataGroup:RowDataGroup;
  let grouping;
  switch (listSortOrder?.group_code) {
    case 'center':
      grouping = null;
      break;
    case 'bumon_center':
      grouping = 'bumon';
      break;
      default:
      grouping = null;
      break;
  }
  let no = 0;
  let subno = 0;
  dataGroups.forEach(dataGroup => {
    if(rowInfos.length > 0) {
      rowInfos[rowInfos.length-1].rowIndexLast = true; //同一集計行内での最後のrowKeyフラグ
    }

    // グルーピング
    if(grouping == 'bumon' && (!beforeDataGroup || beforeDataGroup.centerInfo.bumonCD != dataGroup.centerInfo.bumonCD)) {
      let length = 0;
      const targetRowsKeys = dataGroup?.centerInfo?.centerCD === '0602' ? arrayutil.and(rowKeys0602, visibleRowsKey) : arrayutil.and(rowKeys, visibleRowsKey);
      const keys = dataGroup.centerInfo.teiban === '済み' ? targetTeibanKeys : ['未対応'];
      keys.forEach(teiban => {
        length += targetRowsKeys.filter((rowKey) => {
          if (visibleOver) {
            const zaikoKeikaData =
              rowKey == 'zaiko1' ? dataGroup.teibans[teiban].zaikoKeikaData1 :
              rowKey == 'zaiko2' ? dataGroup.teibans[teiban].zaikoKeikaData2 :
              rowKey == 'zaiko3' ? dataGroup.teibans[teiban].zaikoKeikaData3 :
              rowKey == 'zaiko4' ? dataGroup.teibans[teiban].zaikoKeikaData4 :
              rowKey == 'zaiko5' ? dataGroup.teibans[teiban].zaikoKeikaData5 :
              rowKey == 'total' ? dataGroup.teibans[teiban].zaikoKeikaDataAll :
              null;
            if (!zaikoKeikaData?.over) {
              return false;
            }
          }
          switch (rowKey) {
            //在庫
            case "zaiko1":
            case "zaiko2":
            case "zaiko3":
            case "zaiko4":
            case "zaiko5":
              return true;
            //合計
            case "total":
              return true;
            default:
              return false;
          }
        }).length;
      });
      if (length > 0) {
        no++;
        subno = 0;
        rowInfos.push({
          TP: "bumon",
          no: no ? `${no}` : '',
          dataGroup: dataGroup,
        });
      }
    }
    const targetRowsKeys = dataGroup?.centerInfo?.centerCD === '0602' ? arrayutil.and(rowKeys0602, visibleRowsKey) : arrayutil.and(rowKeys, visibleRowsKey);

    //明細行
    subno++;
    let rowIndex = 0;
    const keys = dataGroup.centerInfo.teiban === '済み' ? targetTeibanKeys : ['未対応'];
    keys.forEach(teiban => {
      let teibanIndex = 0;

      targetRowsKeys.forEach((rowKey) => {
        if (visibleOver) {
          const zaikoKeikaData =
            rowKey == 'zaiko1' ? dataGroup.teibans[teiban].zaikoKeikaData1 :
            rowKey == 'zaiko2' ? dataGroup.teibans[teiban].zaikoKeikaData2 :
            rowKey == 'zaiko3' ? dataGroup.teibans[teiban].zaikoKeikaData3 :
            rowKey == 'zaiko4' ? dataGroup.teibans[teiban].zaikoKeikaData4 :
            rowKey == 'zaiko5' ? dataGroup.teibans[teiban].zaikoKeikaData5 :
            rowKey == 'total' ? dataGroup.teibans[teiban].zaikoKeikaDataAll :
            null;
          if (!zaikoKeikaData?.over) {
            return;
          }
        }
        switch (rowKey) {
          //在庫
          case "zaiko1":
          case "zaiko2":
          case "zaiko3":
          case "zaiko4":
          case "zaiko5":
            rowInfos.push({
              ...{
                TP: "zaiko",
                no: no ? `${no}` : '',
                subno: `${subno}`,
                dataGroup: dataGroup,
              },
              ...{
                rowKey: rowKey,

                rowIndex: rowIndex++,  //同一集計行内でのrowKeyのindex
                rowIndexLast: false, //同一集計行内での最後のrowKeyフラグ（仮置き）
                teiban: teiban === '未対応' ? '' : teiban,
                teibanIndex: teibanIndex++,

                syukkaData: null,
                zaikoTotalData: dataGroup.teibans[teiban].zaikoTotalData,
                zaikoKeikaData: rowKey == 'zaiko1' ? dataGroup.teibans[teiban].zaikoKeikaData1 :
                  rowKey == 'zaiko2' ? dataGroup.teibans[teiban].zaikoKeikaData2 :
                    rowKey == 'zaiko3' ? dataGroup.teibans[teiban].zaikoKeikaData3 :
                      rowKey == 'zaiko4' ? dataGroup.teibans[teiban].zaikoKeikaData4 :
                        rowKey == 'zaiko5' ? dataGroup.teibans[teiban].zaikoKeikaData5 :
                          null,
              }
            });
            break;
          //合計
          case "total":
            rowInfos.push({
              ...{
                TP: "total",
                no: no ? `${no}` : '',
                subno: `${subno}`,
                dataGroup: dataGroup,
              },
              ...{
                rowKey: rowKey,

                rowIndex: rowIndex++,  //同一集計行内でのrowKeyのindex
                rowIndexLast: false, //同一集計行内での最後のrowKeyフラグ（仮置き）
                teiban: teiban === '未対応' ? '' : teiban,
                teibanIndex: teibanIndex++,

                syukkaData: dataGroup.teibans[teiban].syukkaData,
                zaikoTotalData: dataGroup.teibans[teiban].zaikoTotalData,
                zaikoKeikaData: dataGroup.teibans[teiban].zaikoKeikaDataAll,
              }
            });
            break;

          default:
            break;
        }
      });
    });

    beforeDataGroup = dataGroup;
  });
  if(rowInfos.length > 0) {
    rowInfos[rowInfos.length-1].rowIndexLast = true; //同一集計行内での最後のrowKeyフラグ
  }
  const teibanMaxIndex = rowInfos.length === 0 ? 0 : rowInfos
    .map(rowInfo => rowInfo.teibanIndex ?? 0)
    .reduce((a, b) => Math.max(a, b));
  rowInfos.forEach((rowInfo, index) => {
    rowInfos[index].teibanIndexLast = rowInfo.teibanIndex == teibanMaxIndex;
  });

  return [rowInfos, fixedRowsTop];
}
//配列データに変換
const convertRows = (rowInfos:RowInfo[], colRowModel:ZaikoSummaryColRowModel, visibleAttributes: string[][]): any[][] => {
  return rowInfos.map((rowInfo) => convertRow(rowInfo, colRowModel, visibleAttributes));
}
//配列データに変換
const convertRow = (rowInfo:RowInfo, colRowModel:ZaikoSummaryColRowModel, visibleAttributes: string[][]): any[] => {
  //set No.
  const dataGroup:RowDataGroup = rowInfo.dataGroup;
  return colRowModel.colKeys.map(colKey => {

    //グループ行
    if(rowInfo.TP == "bumon") {
      switch (colKey) {
        case "no":
          return rowInfo.no;
        case "attribute1Label":
          return `${dataGroup.centerInfo.bumonCD} ${dataGroup.centerInfo.bumonNM}`;
        case "attribute1Value":
          return null;
        case "teiban":
          return null;
        case "rowHeader":
          return null;
        case 'syukkaAmtAve':
        case 'syukkaQtyAve':
        case 'syukkaAmtTtl':
        case 'syukkaQtyTtl':
        case 'cycleCnt':
        
        case 'zaikoTtlPrd':
        case 'zaikoTtlQty':
        case 'zaikoTtlAmt':
        
        case 'zaiko101Prd':
        case 'zaiko101Qty':
        case 'zaiko101Amt':
        case 'zaiko101Rat':
        
        case 'zaiko031Prd':
        case 'zaiko031Qty':
        case 'zaiko031Amt':
        case 'zaiko031Rat':
        
        case 'zaiko011Prd':
        case 'zaiko011Qty':
        case 'zaiko011Amt':
        case 'zaiko011Rat':
        
        case 'zaiko000Prd':
        case 'zaiko000Qty':
        case 'zaiko000Amt':
        case 'zaiko000Rat':     
        
        case 'zaiko999Prd':
        case 'zaiko999Qty':
        case 'zaiko999Amt':
        case 'zaiko999Rat':
        
          return null;
       default: {
          return null;
        }
      }
    }
    else {

      //明細行
      switch (colKey) {
        case "no":
          return rowInfo.no ? `${rowInfo.no}-${rowInfo.subno}` : `${rowInfo.subno}`;
        case "attribute1Label":
          {
            let attrIndex = colKey == "attribute1Label" ? 0 : 1;
            let attrKey = visibleAttributes[attrIndex][rowInfo.rowIndex];
            let attr = centerInfoAttributes[attrIndex].find(attr => attr.code == attrKey);
            return attr ? attr.ryaku : null;
          }
        case "attribute1Value":
          {
            let attrIndex = colKey == "attribute1Value" ? 0 : 1;
            let attrKey = visibleAttributes[attrIndex][rowInfo.rowIndex];
            switch(attrKey) {
              case "centerDiv": //"TC/DC"
                return dataGroup.centerInfo.centerDiv;
              case "bumon": //"部門"
                return `${dataGroup.centerInfo.bumonCD} ${dataGroup.centerInfo.bumonNM}`;
              case "bumonCD": //"部門"
                return dataGroup.centerInfo.bumonCD;
              case "bumonNM": //"部門"
                return dataGroup.centerInfo.bumonNM;
              case "center": //"倉庫"
                return `${dataGroup.centerInfo.centerCD} ${dataGroup.centerInfo.centerNM}`;
              case "centerCD": //"倉庫"
                return dataGroup.centerInfo.centerCD;
              case "centerNM": //"倉庫"
                return dataGroup.centerInfo.centerNM;
              default:
                return null;
            }
          }
        case "teiban":
          return rowInfo.teibanIndex === 0 ? rowInfo.teiban : '';

        case "rowHeader":
          return rowInfo?.dataGroup?.centerInfo?.centerCD === '0602' ? headersRow0602[rowInfo.rowKey] : headersRow[rowInfo.rowKey];

        case 'syukkaAmtAve':
          return rowInfo.syukkaData?.syukkaAmtAve;
        case 'syukkaQtyAve':
          return rowInfo.syukkaData?.syukkaQtyAve;
        case 'syukkaAmtTtl':
          return rowInfo.syukkaData?.syukkaAmtTtl;
        case 'syukkaQtyTtl':
          return rowInfo.syukkaData?.syukkaQtyTtl;
        case 'cycleCnt':
          return rowInfo.syukkaData?.cycleCnt;
        
        case 'zaikoTtlPrd':
          {
            switch (rowInfo.rowKey) {
              case "total":
                return rowInfo.zaikoTotalData?.zaikoTtlPrdAll;
              case "zaiko1":
                return rowInfo.zaikoTotalData?.zaikoTtlPrd1;
              case "zaiko2":
                return rowInfo.zaikoTotalData?.zaikoTtlPrd2;
              case "zaiko3":
                return rowInfo.zaikoTotalData?.zaikoTtlPrd3;
              case "zaiko4":
                return rowInfo.zaikoTotalData?.zaikoTtlPrd4;
              case "zaiko5":
                return rowInfo.zaikoTotalData?.zaikoTtlPrd5;
              default:
                return null;
            }
          }
        case 'zaikoTtlQty':
          {
            switch (rowInfo.rowKey) {
              case "total":
                return rowInfo.zaikoTotalData?.zaikoTtlQtyAll;
              case "zaiko1":
                return rowInfo.zaikoTotalData?.zaikoTtlQty1;
              case "zaiko2":
                return rowInfo.zaikoTotalData?.zaikoTtlQty2;
              case "zaiko3":
                return rowInfo.zaikoTotalData?.zaikoTtlQty3;
              case "zaiko4":
                return rowInfo.zaikoTotalData?.zaikoTtlQty4;
              case "zaiko5":
                return rowInfo.zaikoTotalData?.zaikoTtlQty5;
              default:
                return null;
            }
          }
        case 'zaikoTtlAmt':
          {
            switch (rowInfo.rowKey) {
              case "total":
                return rowInfo.zaikoTotalData?.zaikoTtlAmtAll;
              case "zaiko1":
                return rowInfo.zaikoTotalData?.zaikoTtlAmt1;
              case "zaiko2":
                return rowInfo.zaikoTotalData?.zaikoTtlAmt2;
              case "zaiko3":
                return rowInfo.zaikoTotalData?.zaikoTtlAmt3;
              case "zaiko4":
                return rowInfo.zaikoTotalData?.zaikoTtlAmt4;
              case "zaiko5":
                return rowInfo.zaikoTotalData?.zaikoTtlAmt5;
              default:
                return null;
            }
          }

        case 'zaikoTtlRat':
          {
            switch (rowInfo.rowKey) {
              case "total":
                return rowInfo.zaikoTotalData?.zaikoTtlRatAll;
              case "zaiko1":
                return rowInfo.zaikoTotalData?.zaikoTtlRat1;
              case "zaiko2":
                return rowInfo.zaikoTotalData?.zaikoTtlRat2;
              case "zaiko3":
                return rowInfo.zaikoTotalData?.zaikoTtlRat3;
              case "zaiko4":
                return rowInfo.zaikoTotalData?.zaikoTtlRat4;
              case "zaiko5":
                return rowInfo.zaikoTotalData?.zaikoTtlRat5;
              default:
                return null;
            }
          }

        case 'zaiko101Prd':
          return rowInfo.zaikoKeikaData?.zaiko101Prd;
        case 'zaiko101Qty':
          return rowInfo.zaikoKeikaData?.zaiko101Qty;
        case 'zaiko101Amt':
          return rowInfo.zaikoKeikaData?.zaiko101Amt;
        case 'zaiko101Rat':
          return rowInfo.zaikoKeikaData?.zaiko101Rat;
        
        case 'zaiko031Prd':
          return rowInfo.zaikoKeikaData?.zaiko031Prd;
        case 'zaiko031Qty':
          return rowInfo.zaikoKeikaData?.zaiko031Qty;
        case 'zaiko031Amt':
          return rowInfo.zaikoKeikaData?.zaiko031Amt;
        case 'zaiko031Rat':
          return rowInfo.zaikoKeikaData?.zaiko031Rat;
        
        case 'zaiko011Prd':
          return rowInfo.zaikoKeikaData?.zaiko011Prd;
        case 'zaiko011Qty':
          return rowInfo.zaikoKeikaData?.zaiko011Qty;
        case 'zaiko011Amt':
          return rowInfo.zaikoKeikaData?.zaiko011Amt;
        case 'zaiko011Rat':
          return rowInfo.zaikoKeikaData?.zaiko011Rat;
        
        case 'zaiko000Prd':
          return rowInfo.zaikoKeikaData?.zaiko000Prd;
        case 'zaiko000Qty':
          return rowInfo.zaikoKeikaData?.zaiko000Qty;
        case 'zaiko000Amt':
          return rowInfo.zaikoKeikaData?.zaiko000Amt;
        case 'zaiko000Rat':          
          return rowInfo.zaikoKeikaData?.zaiko000Rat;

        case 'zaiko999Prd':
          return rowInfo.zaikoKeikaData?.zaiko999Prd;
        case 'zaiko999Qty':
          return rowInfo.zaikoKeikaData?.zaiko999Qty;
        case 'zaiko999Amt':
          return rowInfo.zaikoKeikaData?.zaiko999Amt;
        case 'zaiko999Rat':
          return rowInfo.zaikoKeikaData?.zaiko999Rat;
    
        case 'over':
          return rowInfo.zaikoKeikaData?.over;
        case 'over1_2':
          return rowInfo.zaikoKeikaData?.over1_2;
        case 'over1_3':
          return rowInfo.zaikoKeikaData?.over1_3;

        default: {
          return dataGroup[colKey];
        }

      }

    }
  });
}

//マージを作成
const createMergeCells = (rowInfos:RowInfo[], colRowModel:ZaikoSummaryColRowModel): {row: number, col: number, rowspan: number, colspan: number}[] => {
  let mergeCells:{row: number, col: number, rowspan: number, colspan: number}[] = [];
  if(rowInfos) {
    let colF = colRowModel.colFromKey('attribute1Label');
    let colT = colRowModel.colFromKey('rowHeader');
    rowInfos.map((rowInfo, index) => {
      if(rowInfo.TP == 'bumon') {
        mergeCells.push({row: index, col: colF, rowspan:1, colspan:colT-colF+1});
      }
    });
  }
  return mergeCells.length == 0 ? null : mergeCells;
}


export const getOptionLabel = (option: CodeName) => {
  return option && option.name ? (option.code + ' ' + option.name) : "";
}

const createSliceContent = (name:string) => createSlice({
  name: name,
  initialState,
  reducers: createReducerContent(),
});

//Page Slice Export
//zaikoSummaryTmp
export const zaikoSummaryTmpSlice = createSliceContent("zaikoSummaryTmp");
