
//テーブルの各列のキー
export const colKeys: string[] = [
  "no",
  "MKCD",
  "MKN",
  "MAD",
  "LDTM",
];

//行固定
export const fixedRowsTop = 0;
//列固定
export const fixedColumnsLeft = 0;

//テーブルの各列のキー逆引き
const colsKeysMap = {};
colKeys.forEach((colKey, index) => {
  let cols: number[] = colsKeysMap[colKey];
  if (!cols) {
    cols = [index];
  }
  else {
    cols.push(index);
  }
  colsKeysMap[colKey] = cols;
});

export const headers = [
  {
    no: "No.",
    //以下に貼り付け
    MKCD: "メーカー<br/>コード",
    MKN: "メーカー名",
    MAD: "発注担当者<br/>メールアドレス",
    LDTM: "リード<br/>タイム",
  },
];

export const headerClass =
{
  no: "tokubaisetting_N",
  //以下に貼り付け
}

export const colWidths =
{
  no: 35,
  //以下に貼り付け
  MKCD: 75,
  MKN: 160,
  MAD: 200,
  LDTM: 80,
};

export const colDataType =
{
  no: { type: 'text', readOnly: true, className: 'htCenter' },
  //以下に貼り付け
  MKCD: { type: 'text', readOnly: false, className: 'htCenter' },
  MKN: { type: 'text', readOnly: true },
  MAD: { type: 'dropdown', source: [/**/], readOnly: false, },
  LDTM: { type: 'numeric', numericFormat: { pattern: '0,0', }, readOnly: false },
};

//function
export const keyFromCol = (column: number): string => {
  return colKeys[column];
}
export const keyFromCols = (columns: number[]): string[] => {
  const vals: string[] = [];
  columns.forEach(column => vals.push(keyFromCol(column)))
  return vals;
}
export const colFromKey = (colKey: string): number => {
  const cols = colsFromKey(colKey);
  return cols && cols.length > 0 ? cols[0] : null;
}
export const colsFromKey = (colKey: string): number[] => {
  return colsKeysMap[colKey];
}
export const colsFromKeys = (colKeys: string[]): number[] => {
  const vals: number[] = [];
  colKeys.forEach(colKey => {
    const colsKeysMap = colsFromKey(colKey);
    if (colsKeysMap) {
      colsKeysMap.forEach(col => vals.push(col));
    }
  })
  return vals;
}
