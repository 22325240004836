import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {RequestParam} from "@/assets/apitype/outputDataListTokuisaki";

import { CodeName } from "@/store/common";

//Page State
export type OutputDataTokuisakiSaveState = {
  //部門
  bu: CodeName | null,
  //倉庫
  center: CodeName | null,
  //得意先
  tokuisaki: CodeName | null,
  //ベンダーコード
  vendorCode: string,
  honbuCode: string | null,
  //過去１か月稼働の得意先のみ表示
  recent: boolean,

  //検索終了した条件
  requestedParamTokuisaki : RequestParam,

};

export const initialState: OutputDataTokuisakiSaveState = {
  //部門
  bu: null,
  //倉庫
  center: null,
  //得意先
  tokuisaki: null,
  //ベンダーコード
  vendorCode: "",
  honbuCode: '',
  //過去１か月稼働の得意先のみ表示
  recent: false,

  requestedParamTokuisaki : {},

};

//Page Slice
const createSliceContent = (name:string) => createSlice({
  name: name,
  initialState,
  reducers: {
    //componentDidMount
    initOnDidMount(state:OutputDataTokuisakiSaveState) {
      //追加属性等はlocalstorageから復元したときにundefinedになっている場合があるためチェックする
      if(!state.bu) {
        state.bu = initialState.bu;
      }
      if(!state.center) {
        state.center = initialState.center;
      }
      if(!state.tokuisaki) {
        state.tokuisaki = initialState.tokuisaki;
      }
      if(!state.vendorCode) {
        state.vendorCode = initialState.vendorCode;
      }
      if (!state.honbuCode) {
        state.honbuCode = initialState.honbuCode;
      }
    },

    // Option Bu
    setBu(state:OutputDataTokuisakiSaveState, action: PayloadAction<CodeName>) {
      state.bu = action.payload;
    },
    setBuList(state:OutputDataTokuisakiSaveState, action: PayloadAction<CodeName[]>) {
      const buList = action.payload;
      let bu = state.bu && state.bu.code? buList.find((bu) => bu.code == state.bu.code) : null;
      if(!bu) {
        bu = buList[0];
      }
      state.bu = bu;
    },
    // Option Center
    setCenter(state:OutputDataTokuisakiSaveState, action: PayloadAction<CodeName>) {
      state.center = action.payload;
    },
    setCenterList(state:OutputDataTokuisakiSaveState, action: PayloadAction<CodeName[]>) {
      const centerList = action.payload;
      let center = state.center && state.center.code? centerList.find((center) => center.code == state.center.code) : null;
      if(!center) {
        center = centerList[0];
      }
      state.center = center;
    },
    // Option Tokuisaki
    setTokuisaki(state:OutputDataTokuisakiSaveState, action: PayloadAction<CodeName>) {
      state.tokuisaki = action.payload;
    },
    setTokuisakiList(state:OutputDataTokuisakiSaveState, action: PayloadAction<CodeName[]>) {
      const tokuisakiList = action.payload;
      let tokuisaki = state.tokuisaki && state.tokuisaki.code? tokuisakiList.find((tokuisaki) => tokuisaki.code == state.tokuisaki.code) : null;
      if(!tokuisaki) {
        tokuisaki = tokuisakiList[0];
      }
      state.tokuisaki = tokuisaki;
    },
    // Option VendorCode
    setVendorCode(state:OutputDataTokuisakiSaveState, action: PayloadAction<string>) {
      state.vendorCode = action.payload;
    },
    setHonbuCode(state: OutputDataTokuisakiSaveState, action: PayloadAction<string>) {
      state.honbuCode = action.payload;
    },
    setRecent(state:OutputDataTokuisakiSaveState, action: PayloadAction<boolean>) {
      state.recent = action.payload;
    },

    clearOption(state:OutputDataTokuisakiSaveState, action: PayloadAction<{
      buList: CodeName[],
      centerList: CodeName[],
      tokuisakiList: CodeName[]
    }>) {
      state.bu = action.payload.buList[0];
      state.center = action.payload.centerList[0];
      state.tokuisaki = action.payload.tokuisakiList[0];
      state.vendorCode = initialState.vendorCode;
      state.honbuCode = initialState.honbuCode;
      state.recent = initialState.recent;
    },

    //検索条件
    setRequestedParamTokuisaki(state:OutputDataTokuisakiSaveState, action: PayloadAction<RequestParam>) {
      state.requestedParamTokuisaki = action.payload;
    },

  },
});

//Page Slice Export
//outputDataTokuisakiSave
export const outputDataTokuisakiSaveSlice = createSliceContent("outputDataTokuisakiSave");
