import { EditingTypes, EditingType } from "@/store/tokubai/manage/tokubaiManageCommon";

import moment from "moment";
moment.updateLocale("ja", {
  months: ["1月","2月","3月","4月","5月","6月","7月","8月","9月","10月","11月","12月",],
  weekdays: ["日曜日", "月曜日", "火曜日", "水曜日", "木曜日", "金曜日", "土曜日"],
  weekdaysShort: ["日", "月", "火", "水", "木", "金", "土"]
});

//テーブルの各列のキー
export const colKeysNo: string[] = [
  "no",
];

////////////////////////////////////////////////////////////
//固定列
////////////////////////////////////////////////////////////

//チェックボックスあり
export const colKeysData_check_fix: string[] = [
  //以下に貼り付け
  "CSQ",
  "MCK",
  "MRG",
  "IJN", "ICD", "INM",
  "IC1", "IC2", "ITN",
];
//チェックボックスなし
export const colKeysData_ref_fix: string[] = [
  //以下に貼り付け
  "CSQ",
  "MRG",
  "IJN", "ICD", "INM",
  "IC1", "IC2", "ITN",
];
//チェックボックスなしステータスあり
export const colKeysData_refall_fix: string[] = [
  //以下に貼り付け
  "CSQ",
  "MCK",
  "MRG",
  "IJN", "ICD", "INM",
  "STS",
  "IC1", "IC2", "ITN",
];
const colKeysData_fix_Map = {};
colKeysData_fix_Map['none'] = [];
colKeysData_fix_Map['edit'] = colKeysData_check_fix;
colKeysData_fix_Map['commited'] = colKeysData_ref_fix;
colKeysData_fix_Map['error'] = colKeysData_check_fix;
colKeysData_fix_Map['furikaed'] = colKeysData_ref_fix;
colKeysData_fix_Map['all'] = colKeysData_refall_fix;
colKeysData_fix_Map['release'] = colKeysData_ref_fix;

////////////////////////////////////////////////////////////
//非固定列
////////////////////////////////////////////////////////////
//共通
export const colKeysData_common_unfix: string[] = [
  //以下に貼り付け
  "MRD", "MRU", "MRC", 
  "OUN",
  "ISC", "ISN", "IFX", "IMC", "IMN", "ICC", "ICN", "IBC", "IBN", "ICP", "ILI", "IPR", "ISD", "IED",
  "TSCD", "TSNM",
  "TCD", "TNM", "TDR",
  "OOS",
  "KFR", "KTO",
  "HPL",
  "OFS", "OND", "OYT", "OKD", "OKB", "OOU",
  "OFIN", "OIN", "OIM", "OLD", "OLT", "OINR", "OFN", "ONU", "ONUS", "OZN", "ONO", "ONOS",
  "OMM",
  "OME",
  "ONT",
  "RVM",
  "TBN", "ZTN", "ZQ1", "ZQ2", "ZQ3", "ZQ4",
];
//編集時
export const colKeysData_edit_unfix: string[] = [...[
], ...colKeysData_common_unfix];
//発注確定済み
export const colKeysData_commited_unfix: string[] = [...[
  "ANO", "ADT",  "AMS",
], ...colKeysData_common_unfix];
//振替済み
export const colKeysData_furikaed_unfix: string[] = [...[
  "FKD",
], ...colKeysData_common_unfix];
//全て
export const colKeysData_all_unfix: string[] = [...[
  "ANO",  "ADT",  "AMS",
  "FKD",
], ...colKeysData_common_unfix];

const colKeysData_unfix_Map = {};
colKeysData_unfix_Map['none'] = [];
colKeysData_unfix_Map['edit'] = colKeysData_edit_unfix;
colKeysData_unfix_Map['commited'] = colKeysData_commited_unfix;
colKeysData_unfix_Map['error'] = colKeysData_commited_unfix;
colKeysData_unfix_Map['furikaed'] = colKeysData_furikaed_unfix;
colKeysData_unfix_Map['all'] = colKeysData_all_unfix;
colKeysData_unfix_Map['release'] = colKeysData_edit_unfix;

////////////////////////////////////////////////////////////
//列定義
////////////////////////////////////////////////////////////
export const colKeysMap = {};
EditingTypes.forEach(editingType => {
  colKeysMap[editingType.key] = [
    ...colKeysNo,
    ...colKeysData_fix_Map[editingType.key],
    ...colKeysData_unfix_Map[editingType.key],
  ];
});

////////////////////////////////////////////////////////////
//行固定
////////////////////////////////////////////////////////////
export const fixedRowsTop = 1;

////////////////////////////////////////////////////////////
//列固定
////////////////////////////////////////////////////////////
export const fixedColumnsLeftMap = {};
EditingTypes.forEach(editingType => {
  fixedColumnsLeftMap[editingType.key] = colKeysNo.length + colKeysData_fix_Map[editingType.key].length;
});

////////////////////////////////////////////////////////////
//テーブルの各列のキー逆引き
////////////////////////////////////////////////////////////
export const colsKeysMapMap = {};
EditingTypes.forEach(editingType => {
  const colsKeysMap = {};
  const colKeys = colKeysMap[editingType.key];
  colKeys.forEach((colKey, index) => {
    let cols:number[] = colsKeysMap[colKey];
    if(!cols){
      cols = [index];
    }
    else {
      cols.push(index);
    }
    colsKeysMap[colKey] = cols;
  });
  colsKeysMapMap[editingType.key] = colsKeysMap;
});

export const headers = [
  {
    no : "No.",
  //以下に貼り付け
  CSQ : "通番",
  MCK: "発注",
  STS: "発注",
  ANO: "ADMS発注情報",  ADT: "ADMS発注情報",  AMS: "ADMS発注情報",
  FKD: "振替日",
  MRD: "入力情報", MRU: "入力情報", MRC: "入力情報", MRG: "入力情報",
  OUN: "発注",
  IJN: "商品指定", IJC: "商品指定", ICD: "商品指定", INM: "商品指定",
  ISC: "商品属性", ISN: "商品属性", IFX: "商品属性", IMC: "商品属性", IMN: "商品属性", ICC: "商品属性", ICN: "商品属性", IBC: "商品属性", IBN: "商品属性", ICP: "商品属性", ILI: "商品属性", IPR: "商品属性", ISD: "商品属性", IED: "商品属性", IC1: "商品属性", IC2: "商品属性", ITN: "商品属性",
  TSCD: "倉庫", TSNM: "倉庫",
  TCD: "納入先", TNM: "納入先", TDR: "納入先",
  OOS: "ｽﾎﾟｯﾄ",
  KFR: "特売期間", KTO: "特売期間",
  HPL: "販売計画",
  OFS: "発注情報", OND: "発注情報", OYT: "発注情報", OKD: "発注情報", OKB: "発注情報", OOU: "発注情報",
  OFIN: "発注情報", OIN: "発注情報", OIM: "発注情報", OLD: "発注情報", OLT: "発注情報", OINR: "発注情報", ONU: "発注情報", ONUS: "発注情報", OZN: "発注情報", ONO: "発注情報", ONOS: "発注情報", OFN: "発注情報",
  OMM: "発注情報",
  OME: "発注情報",
  ONT: "発注情報",
  RVM: "受信管理",
  TBN: "定番", ZTN: "在庫情報", ZQ1: "在庫情報", ZQ2: "在庫情報", ZQ3: "在庫情報", ZQ4: "在庫情報",
  },
  {
    no : "<br/><br/>#",
  //以下に貼り付け
  CSQ : "<br/><br/>#",
  MCK: "<br/><br/>対象",
  STS: "<br/><br/>ステータス",
  ANO: "<br/>ADMS<br/>発注番号", ADT: "<br/>ADMS<br/>連携日", AMS: "<br/>ADMS<br/>結果メッセージ",
  FKD: "<br/><br/>振替日",
  MRD: "<br/><br/>依頼日", MRU: "<br/><br/>依頼者", MRC: "<br/>企業G1<br/>CD", MRG: "<br/>企業G1<br/>名称",
  OUN: "<br/><br/>担当者",
  IJN: "<br/><br/>JAN", IJC: "<br/>同一<br/>JAN数", ICD: "<br/><br/>商品CD", INM: "<br/><br/>商品名",
  ISC: "<br/>仕入先<br/>CD", ISN: "<br/>仕入先<br/>名称", IFX: "<br/>仕入先<br/>FAX", IMC: "<br/>ﾒｰｶｰ<br/>CD", IMN: "<br/>ﾒｰｶｰ<br/>名称", ICC: "<br/>ｶﾃｺﾞﾘ<br/>CD", ICN: "<br/>ｶﾃｺﾞﾘ<br/>名称", IBC: "<br/>PB区分<br/>CD", IBN: "<br/>PB区分<br/>名称", ICP: "<br/><br/>内容量", ILI: "<br/>賞味<br/>期間", IPR: "<br/><br/>定価", ISD: "<br/><br/>発売日", IED: "<br/><br/>終売日", IC1: "<br/>BL<br/>入数", IC2: "<br/>CS<br/>入数", ITN: "<br/>発注<br/>単位",
  TSCD: "定番マスタ<br/>倉庫<br/>CD", TSNM: "定番マスタ<br/>倉庫<br/>名称",
  TCD: "<br/>納入先<br/>CD", TNM: "<br/>納入先<br/>名称", TDR: "<br/><br/>直送",
  OOS: "<br/><br/>ｽﾎﾟｯﾄ",
  KFR: "<br/><br/>出荷開始日", KTO: "<br/><br/>出荷終了日",
  HPL: "<br/>販売計画<br/>BL数",
  OFS: "<br/><br/>出荷日", OND: "<br/><br/>納品日",
  OYT: "<br/><br/>用途",
  OKD: "<br/><br/>倉入日 <font color='red'>*</font>", OKB: "<br/>在庫<br/>区分 <font color='red'>*</font>",
  OOU: "<br/>入庫<br/>単位 <font color='red'>*</font>",
  OFIN: "当初<br/>出荷予定<br/>BL数", OIN: "<br/>出荷予定<br/>BL数", OIM: "<br/>立米<br/>(㎥)", OLD: "<br/>発注<br/>期限日", OLT: "<br/>ﾘｰﾄﾞ<br/>ﾀｲﾑ", OINR: "<br/>出荷予定数<br/>訂正事由", ONU: "<br/>発注<br/>BL数 <font color='red'>*</font>", ONUS: "発注<br/>BL数<br/>小計", ONO: "<br/>余り<br/>BL数", ONOS: "余り<br/>BL数<br/>小計", OZN: "<br/>残使用<br/>BL数", OFN: "<br/>振替済<br/>BL数",
  OMM: "<br/><br/>備考（発注者）",
  OME: "<br/><br/>備考（営業）",
  ONT: "<br/>摘要<br/>（半角のみ）",
  RVM: "<br/><br/>受信管理",
  TBN: "<br/><br/>フラグ", ZTN: "<br/><br/>棚番", ZQ1: "<br/>一般在庫<br/>BL数", ZQ2: "<br/>特売在庫<br/>BL数", ZQ3: "<br/>予約在庫<br/>BL数", ZQ4: "<br/>出切在庫<br/>BL数",
  },
];

export const selectableCol =
{
  no : false,
  //以下に貼り付け
  CSQ: true,
  MCK: false,
  STS: true,
  ANO: true,  ADT: true,  AMS: true,
  FKD: true,
  IJN: true, IJC: true, ICD: false,
  MRD: true, MRU: true, MRC: true, MRG: true,
  OUN: true,
  ISC: true, ISN: true, IFX: true, IMC: true, IMN: true, INM: true, ICC: true, ICN: true, IBC: true, IBN: true, ICP: true, ILI: true, IPR: true, ISD: true, IED: true, IC1: true, IC2: true, ITN: true,
  TSCD: true, TSNM: true,
  TCD: true, TNM: true, TDR: true,
  OOS: true,
  KFR: true, KTO: true,
  HPL: true,
  OFS: true, OND: true, OYT: true, OKD: false, OKB: true, OOU: true,
  OFIN: true, OIN: true, OIM: true, OLD: true, OLT: true, OINR: true, ONU: true, ONUS: false, OZN: true, ONO: true, ONOS: false, OFN: true,
  OMM: true,
  OME: true,
  ONT: true,
  RVM: true,
  TBN: true, ZTN: true, ZQ1: true, ZQ2: true, ZQ3: true, ZQ4: true,

}

export const headerClass =
{
  no : "tokubaimanage_N",
  //以下に貼り付け
  CSQ : "tokubaimanage_N",
  MCK: "tokubaimanage_S",
  STS: "tokubaimanage_S",
  ANO: "tokubaimanage_A",  ADT: "tokubaimanage_A",  AMS: "tokubaimanage_A",
  FKD: "tokubaimanage_A",
  MRD: "tokubaimanage_M", MRU: "tokubaimanage_M", MRC: "tokubaimanage_M", MRG: "tokubaimanage_M",
  OUN: "tokubaimanage_O",
  IJN: "tokubaimanage_I", IJC: "tokubaimanage_I", ICD: "tokubaimanage_I",
  ISC: "tokubaimanage_I", ISN: "tokubaimanage_I", IFX: "tokubaimanage_I", IMC: "tokubaimanage_I", IMN: "tokubaimanage_I", INM: "tokubaimanage_I", ICC: "tokubaimanage_I", ICN: "tokubaimanage_I", IBC: "tokubaimanage_I", IBN: "tokubaimanage_I", ICP: "tokubaimanage_I", ILI: "tokubaimanage_I", IPR: "tokubaimanage_I", ISD: "tokubaimanage_I", IED: "tokubaimanage_I", IC1: "tokubaimanage_I", IC2: "tokubaimanage_I", ITN: "tokubaimanage_I",
  TSCD: "tokubaimanage_S", TSNM: "tokubaimanage_S",
  TCD: "tokubaimanage_S", TNM: "tokubaimanage_S", TDR: "tokubaimanage_S",
  OOS: "tokubaimanage_O",
  KFR: "tokubaimanage_K", KTO: "tokubaimanage_K",
  HPL: "tokubaimanage_K",
  OFS: "tokubaimanage_O", OND: "tokubaimanage_O", OYT: "tokubaimanage_O", OKD: "tokubaimanage_O", OKB: "tokubaimanage_O", OOU: "tokubaimanage_O",
  OFIN: "tokubaimanage_O", OIN: "tokubaimanage_O", OIM: "tokubaimanage_O", OLD: "tokubaimanage_O", OLT: "tokubaimanage_O", OINR: "tokubaimanage_O", ONU: "tokubaimanage_O", ONUS: "tokubaimanage_O", OZN: "tokubaimanage_O", ONO: "tokubaimanage_O", ONOS: "tokubaimanage_O", OFN: "tokubaimanage_O",
  OMM: "tokubaimanage_O",
  OME: "tokubaimanage_O",
  ONT: "tokubaimanage_O",
  RVM: "tokubaimanage_O",
  TBN: "tokubaimanage_T", ZTN: "tokubaimanage_Z", ZQ1: "tokubaimanage_Z", ZQ2: "tokubaimanage_Z", ZQ3: "tokubaimanage_Z", ZQ4: "tokubaimanage_Z",

}

export const colWidths =
{
  no : 40,
  //以下に貼り付け
  CSQ : 50,
  MCK: 40,
  STS: 70,
  ANO: 70,  ADT: 80,  AMS: 100,
  FKD: 80,
  MRD: 80, MRU: 63, MRC: 50, MRG: 100,
  OUN: 63,
  IJN: 120, IJC: 50, ICD: 100,
  ISC: 50, ISN: 100, IFX: 110, IMC: 50, IMN: 100, INM: 240, ICC: 50, ICN: 80, IBC: 50, IBN: 80, ICP: 50, IC1: 50, IC2: 50, ITN: 50, ILI: 70, IPR: 60, ISD: 80, IED: 80,
  OOS: 40,
  KFR: 80, KTO: 80,
  HPL: 70,
  TSCD: 50, TSNM: 130,
  TCD: 80, TNM: 130, TDR: 40,
  OFS: 80, OND: 80, OYT: 100, OKD: 100, OKB: 50, OOU: 50,
  OFIN: 70, OIN: 70, OIM: 55, OLD: 80, OLT: 55, OINR: 140, ONU: 70, ONUS: 70, OZN: 50, ONO: 50, ONOS: 50, OFN: 70,
  OMM: 240,
  OME: 240,
  ONT: 150,
  RVM: 70,
  TBN: 35, ZTN: 100, ZQ1: 70, ZQ2: 70, ZQ3: 70, ZQ4: 70,
};

export const colDataType =
{
  no : {type: 'text', readOnly: true, className: 'htCenter'},
  //以下に貼り付け
  CSQ : {type: 'text', readOnly: true, className: 'htRight'},
  MCK: {type: 'checkbox', checkedTemplate: true, uncheckedTemplate: false, readOnly: false},
  STS: {type: 'text', readOnly: true},
  ANO: {type: 'text', readOnly: true, className: 'htCenter'},
  ADT: {type: 'text', readOnly: true, className: 'htCenter'},
  AMS: {type: 'text', readOnly: true},
  FKD: {type: 'text', readOnly: true, className: 'htCenter'},
  MRD: {type: 'text', readOnly: true, className: 'htCenter'},
  MRU: {type: 'text', readOnly: true},
  MRC: {type: 'text', readOnly: true},
  MRG: {type: 'text', readOnly: true},
  OUN: {type: 'text', readOnly: true},
  IJN: {type: 'text', readOnly: true}, IJC: {type: 'numeric', numericFormat: {pattern: '0,0',}, readOnly: true},
  ICD: {type: 'text', readOnly: true, className: 'htCenter'},
  ISC: {type: 'text', readOnly: true, className: 'htCenter'},
  ISN: {type: 'text', readOnly: true},
  IFX: {type: 'text', readOnly: true},
  IMC: {type: 'text', readOnly: true, className: 'htCenter'},
  IMN: {type: 'text', readOnly: true},
  INM: {type: 'text', readOnly: true},
  ICC: {type: 'text', readOnly: true, className: 'htCenter'},
  ICN: {type: 'text', readOnly: true},
  IBC: {type: 'text', readOnly: true, className: 'htCenter'},
  IBN: {type: 'text', readOnly: true},
  ICP: {type: 'text', readOnly: true, className: 'htRight'},
  ILI: {type: 'numeric', numericFormat: {pattern: '0,0日',}, readOnly: true},
  IPR: {type: 'numeric', numericFormat: {pattern: '0,0',}, readOnly: true},
  ISD: {type: 'text', readOnly: true, className: 'htCenter'},
  IED: {type: 'text', readOnly: true, className: 'htCenter'},
  IC1: {type: 'numeric', numericFormat: {pattern: '0,0',}, readOnly: true},
  IC2: {type: 'numeric', numericFormat: {pattern: '0,0',}, readOnly: true},
  ITN: {type: 'numeric', numericFormat: {pattern: '0,0',}, readOnly: true},

  TSCD: {type: 'text', readOnly: true, className: 'htCenter'},
  TSNM: {type: 'text', readOnly: true},
  TCD: {type: 'text', readOnly: true, className: 'htCenter'},
  TNM: {type: 'text', readOnly: true},
  TDR: {type: 'text', readOnly: true, className: 'htCenter'},
  OOS: {type: 'text', readOnly: true, className: 'htCenter'},
  KFR: {type: 'text', readOnly: true, className: 'htCenter'},
  KTO: {type: 'text', readOnly: true, className: 'htCenter'},
  HPL: { type: 'numeric', numericFormat: { pattern: '0,0', }, readOnly: true },
  OFS: {type: 'text', readOnly: true, className: 'htCenter'},
  OND: {type: 'text', readOnly: true, className: 'htCenter'},
  OYT: {type: 'text', readOnly: true},
  OKD: { type: 'date', dateFormat: 'YY/MM/DD', datePickerConfig: { numberOfMonths: 2, minDate: moment().startOf('day').toDate(), yearSuffix: '年', showMonthAfterYear: true, showDaysInNextAndPreviousMonths: true, i18n: { previousMonth: '前月', nextMonth: '次月', months: moment.months(), weekdays: moment.weekdays(), weekdaysShort: moment.weekdaysShort() } }, readOnly: false, className: 'htCenter' }, 
  OKB: { type: 'dropdown', source: ['一般', '特売', '予約'], readOnly: false, className: 'htCenter' },
  OOU: { type: 'dropdown', source: ['CS', 'BL'], readOnly: false, className: 'htCenter' },
  OFIN: {type: 'numeric', numericFormat: {pattern: '0,0',}, readOnly: true},
  OIN: {type: 'numeric', numericFormat: {pattern: '0,0',}, readOnly: true},
  OIM: {type: 'numeric', numericFormat: {pattern: '0,0.00',}, readOnly: true},
  OLD: {type: 'text', readOnly: true, className: 'htCenter'},
  OLT: {type: 'numeric', numericFormat: {pattern: '0,0',}, readOnly: true},
  OINR: { type: 'text', readOnly: true },
  ONU: {type: 'numeric', numericFormat: {pattern: '0,0',}, readOnly: false},
  ONUS: {type: 'numeric', numericFormat: {pattern: '0,0',}, readOnly: true},
  OZN: {type: 'numeric', numericFormat: {pattern: '0,0',}, readOnly: true},
  ONO: {type: 'numeric', numericFormat: {pattern: '0,0',}, readOnly: true},
  ONOS: {type: 'numeric', numericFormat: {pattern: '0,0',}, readOnly: true},
  OFN: {type: 'numeric', numericFormat: {pattern: '0,0',}, readOnly: true},
  OMM: {type: 'text', readOnly: false},
  OME: {type: 'text', readOnly: false},
  ONT: {type: 'text', readOnly: false},
  RVM: { type: 'dropdown', source: ['未受信', '当日受信', '受信済', '打伝', '確認中', '取消済'], readOnly: false, className: 'htCenter' },
  TBN: {type: 'text', readOnly: true, className: 'htCenter'},
  ZTN: {type: 'text', readOnly: true, className: 'htCenter'},
  ZQ1: {type: 'numeric', numericFormat: {pattern: '0,0.0',}, readOnly: true},
  ZQ2: {type: 'numeric', numericFormat: {pattern: '0,0.0',}, readOnly: true},
  ZQ3: {type: 'numeric', numericFormat: {pattern: '0,0.0',}, readOnly: true},
  ZQ4: {type: 'numeric', numericFormat: {pattern: '0,0.0',}, readOnly: true},
  //ZQ1: {type: 'numeric', numericFormat: {pattern: '0.0',}, readOnly: true}, ZQ2: {type: 'numeric', numericFormat: {pattern: '0.0',}, readOnly: true}, ZQ3: {type: 'numeric', numericFormat: {pattern: '0.0',}, readOnly: true},

  };

//function
export type keyInfo = {key:string, dataType:'no'|'data'};

export const getFixedColumnsLeft = (editingType: EditingType): number => {
  return fixedColumnsLeftMap[editingType.key];
}
export const getColKeys = (editingType: EditingType): string[] => {
  return colKeysMap[editingType.key];
}
export const getColsKeysMap = (editingType: EditingType): number[] => {
  return colsKeysMapMap[editingType.key];
}
export const getColKeysDataFix = (editingType: EditingType): string[] => {
  return colKeysData_fix_Map[editingType.key];
}
export const getColKeysDataUnfix = (editingType: EditingType): string[] => {
  return colKeysData_unfix_Map[editingType.key];
}

export const keyInfoFromCol = (column: number, editingType: EditingType): keyInfo => {
  const colKeysData_fix = getColKeysDataFix(editingType);
  const colKeysData_unfix = getColKeysDataUnfix(editingType);

  if(column < colKeysNo.length) {
    return {key:colKeysNo[column], dataType:"no"};
  }
  column -= colKeysNo.length;

  if(column < colKeysData_fix.length) {
    return {key:colKeysData_fix[column], dataType:"data"};
  }
  column -= colKeysData_fix.length;

  if(column < colKeysData_unfix.length) {
    return {key:colKeysData_unfix[column], dataType:"data"};
  }
  column -= colKeysData_unfix.length;

  return null;
}
export const keyInfoFromCols = (columns: number[], editingType: EditingType): keyInfo[] => {
  const vals:keyInfo[] = [];
  columns.forEach(column => vals.push(keyInfoFromCol(column, editingType)))
  return vals;
}
export const keyFromCol = (column: number, editingType: EditingType): string => {
  const colKeys = getColKeys(editingType);
  return colKeys[column];
}
export const keyFromCols = (columns: number[], editingType: EditingType): string[] => {
  const vals:string[] = [];
  columns.forEach(column => vals.push(keyFromCol(column, editingType)))
  return vals;
}
export const colFromKey = (colKey: string, editingType: EditingType): number => {
  const cols = colsFromKey(colKey, editingType);
  return cols && cols.length > 0 ? cols[0] : null;
}
export const colsFromKey = (colKey:string, editingType: EditingType):number[] => {
  const colsKeysMap = getColsKeysMap(editingType);
  return colsKeysMap[colKey];
}
export const colsFromKeys = (colKeys: string[], editingType: EditingType): number[] => {
  const vals:number[] = [];
  colKeys.forEach(colKey => {
    const colsKeysMap = colsFromKey(colKey, editingType);
    if(colsKeysMap) {
      colsKeysMap.forEach(col => vals.push(col));
    }
  })
  return vals;
}
