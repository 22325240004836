import moment from "moment";
moment.updateLocale("ja", {
  months: ["1月","2月","3月","4月","5月","6月","7月","8月","9月","10月","11月","12月",],
  weekdays: ["日曜日", "月曜日", "火曜日", "水曜日", "木曜日", "金曜日", "土曜日"],
  weekdaysShort: ["日", "月", "火", "水", "木", "金", "土"]
});

//テーブルの各列のキー
export const colKeysNo: string[] = [
  "no",
];

//編集時
export const colKeysData_edit_fix: string[] = [
  //以下に貼り付け
  "MRG", "SNM",
  "IJN",   "ICD", "INM",
];
//参照時
export const colKeysData_ref_fix: string[] = [
  //以下に貼り付け
  "MRG", "SNM",
  "IJN",   "ICD", "INM",
];

//編集時
export const colKeysData_edit_unfix: string[] = [
  //以下に貼り付け
  "MRD", "MRU", "MRC",
  "ISC", "ISN", "IMC", "IMN", "ICC", "ICN", "IBC", "IBN", "ICP", "ILI", "IPR", "ISD", "IED", "IC1", "IC2", "ITN",
  "SCD",
  "OSCD", "OSNM",
  "TCD", "TNM", "TDR",
  "OOS",
  "KFR", "KTO",
  "HPL",
  "OFS", "OND", "OOU", "OON", "OOZ", "OFN", "OAYN", "OMM", "OME", "ONT",
  "RVM",
  "OAN1",
  "OANT", "ORNT",
  "SD1", "SN1", "SD2", "SN2", "SD3", "SN3", "SD4", "SN4", "SD5", "SN5", "SNT",
  "TSTR", "TSPN", "TSSR", "TSN", "TSZ", "OFZN", "OZN", "ONU", "ONUZ",
  "OAN",
  "OARL",
  "OAMM",
  "OAME",
  "OANTE",
  "ARVM",
  "TBN", "ZTN", "ZQ1", "ZQ2", "ZQ3",
];
//参照時
export const colKeysData_ref_unfix: string[] = [
  //以下に貼り付け
  "MRD", "MRU", "MRC",
  "ISC", "ISN", "IMC", "IMN", "ICC", "ICN", "IBC", "IBN", "ICP", "ILI", "IPR", "ISD", "IED", "IC1", "IC2", "ITN",
  "SCD",
  "OSCD", "OSNM",
  "TCD", "TNM", "TDR",
  "OOS",
  "KFR", "KTO",
  "HPL",
  "OFS", "OND", "OOU", "OON", "OOZ", "OFN", "OAYN", "OMM", "OME", "ONT",
  "RVM",
  "OAN1",
  "OANT", "ORNT",
  "SD1", "SN1", "SD2", "SN2", "SD3", "SN3", "SD4", "SN4", "SD5", "SN5", "SNT",
  "OFZN", "OZN", "ONU", "TSTR", "TSPN", "TSSR", "TSN", "TSZ", "ONUZ",
  "OAN",
  "OARL",
  "OAMM",
  "OAME",
  "OANTE",
  "ARVM",
  "TBN", "ZTN", "ZQ1", "ZQ2", "ZQ3",
];

export const colKeys_edit: string[] = [
  ...colKeysNo,
  ...colKeysData_edit_fix,
  ...colKeysData_edit_unfix,
];
export const colKeys_ref: string[] = [
  ...colKeysNo,
  ...colKeysData_ref_fix,
  ...colKeysData_ref_unfix,
];

//行固定
export const fixedRowsTop = 0;
//列固定
export const fixedColumnsLeft_edit = colKeysNo.length + colKeysData_edit_fix.length;
export const fixedColumnsLeft_ref = colKeysNo.length + colKeysData_ref_fix.length;

//テーブルの各列のキー逆引き
const colsKeysMap_edit = {};
const colsKeysMap_ref = {};
colKeys_edit.forEach((colKey, index) => {
  let cols:number[] = colsKeysMap_edit[colKey];
  if(!cols){
    cols = [index];
  }
  else {
    cols.push(index);
  }
  colsKeysMap_edit[colKey] = cols;
});
colKeys_ref.forEach((colKey, index) => {
  let cols:number[] = colsKeysMap_ref[colKey];
  if(!cols){
    cols = [index];
  }
  else {
    cols.push(index);
  }
  colsKeysMap_ref[colKey] = cols;
});


export const headers = [
  {
    no: "No.",
    //以下に貼り付け
    IJN: "商品指定", ICD: "商品指定", INM: "商品指定",
    MRD: "初回発注入力情報", MRU: "初回発注入力情報", MRC: "初回発注入力情報", MRG: "初回発注入力情報",
    ISC: "商品属性", ISN: "商品属性", IMC: "商品属性", IMN: "商品属性", ICC: "商品属性", ICN: "商品属性", IBC: "商品属性", IBN: "商品属性", ICP: "商品属性", ILI: "商品属性", IPR: "商品属性", ISD: "商品属性", IED: "商品属性", IC1: "商品属性", IC2: "商品属性", ITN: "商品属性",
    SCD: "倉庫", SNM: "倉庫",
    OSCD: "倉庫", OSNM: "倉庫",
    TCD: "納入先", TNM: "納入先", TDR: "納入先",
    OOS: "ｽﾎﾟｯﾄ",
    KFR: "特売期間", KTO: "特売期間",
    HPL: "販売計画",
    OFS: "初回発注情報", OND: "初回発注情報", OOU: "初回発注情報", OON: "初回発注情報", OOZ: "初回発注情報", OFN: "初回発注情報", OAYN: "初回発注情報", OMM: "初回発注情報", OME: "初回発注情報", ONT: "初回発注情報",
    RVM: "初回発注情報",
    TBN: "定番", ZTN: "在庫情報", ZQ1: "在庫情報", ZQ2: "在庫情報", ZQ3: "在庫情報",
    OAN1: "追加発注",
    OANT: "累計", ORNT: "リリース",
    SN1: "出荷実績", SD1: "出荷実績", SN2: "出荷実績", SD2: "出荷実績", SN3: "出荷実績", SD3: "出荷実績", SN4: "出荷実績", SD4: "出荷実績", SN5: "出荷実績", SD5: "出荷実績", SNT: "出荷実績",
    OFZN: "進捗", OZN: "進捗", ONU: "進捗", TSTR: "進捗", TSPN: "進捗", TSSR: "進捗", TSN: "進捗", TSZ: "進捗", ONUZ: "進捗",
    OAN: "追加発注",
    OARL: "ﾘﾘｰｽ希望",
    OAMM: "備考",
    OAME: "備考",
    OANTE: "摘要",
    ARVM: "受信管理",
  },
  {
    no: "<br/><br/>#",
    //以下に貼り付け
    IJN: "<br/><br/>JAN", ICD: "<br/><br/>商品CD", INM: "<br/><br/>商品名",
    MRD: "<br/><br/>依頼日", MRU: "<br/><br/>依頼者", MRC: "<br/>企業G1<br/>CD", MRG: "<br/>企業G1<br/>名称",
    ISC: "<br/>仕入先<br/>CD", ISN: "<br/>仕入先<br/>名称", IMC: "<br/>ﾒｰｶｰ<br/>CD", IMN: "<br/>ﾒｰｶｰ<br/>名称", ICC: "<br/>ｶﾃｺﾞﾘ<br/>CD", ICN: "<br/>ｶﾃｺﾞﾘ<br/>名称", IBC: "<br/>PB区分<br/>CD", IBN: "<br/>PB区分<br/>名称", ICP: "<br/><br/>内容量", ILI: "<br/>賞味<br/>期間", IPR: "<br/><br/>定価", ISD: "<br/><br/>発売日", IED: "<br/><br/>終売日", IC1: "<br/>BL<br/>入数", IC2: "<br/>CS<br/>入数", ITN: "<br/>発注<br/>単位",
    SCD: "<br/>倉庫<br/>CD", SNM: "<br/>倉庫<br/>名称",
    OSCD: "発注<br/>倉庫<br/>CD", OSNM: "発注<br/>倉庫<br/>名称",
    TCD: "<br/>納入先<br/>CD", TNM: "<br/>納入先<br/>名称", TDR: "<br/><br/>直送",
    OOS: "<br/><br/>ｽﾎﾟｯﾄ",
    KFR: "<br/><br/>出荷開始日", KTO: "<br/><br/>出荷終了日",
    HPL: "<br/><br/>販売計画",
    OFS: "<br/><br/>出荷日", OND: "<br/><br/>納品日", OOU: "<br/>入庫<br/>単位", OON: "<br/>送込BL数<br/>(A)", OOZ: "送込以降の<br/>必要BL数<br/>(B)", OFN: "初回準備<br/>BL数<br/>(A+B)", OAYN: "<br/>追加発注<br/>予測BL数", OMM: "<br/><br/>備考（発注者）", OME: "<br/><br/>備考（営業）", ONT: "<br/><br/>摘要",
    RVM: "<br/><br/>受信管理",
    TBN: "<br/><br/>フラグ", ZTN: "<br/><br/>棚番", ZQ1: "<br/>一般在庫<br/>BL数", ZQ2: "<br/>特売在庫<br/>BL数", ZQ3: "<br/>予約在庫<br/>BL数",
    OAN1: "追加<br/>出荷予定<br/>BL数",
    OANT: "出荷予定<br/>累計<br/>BL数", ORNT: "ﾘﾘｰｽ希望<br/>累計<br/>BL数",
    SN1: "出荷<br/>BL数<br/>①", SD1: "<br/>出荷日<br/>①", SN2: "出荷<br/>BL数<br/>②", SD2: "<br/>出荷日<br/>②", SN3: "出荷<br/>BL数<br/>③", SD3: "<br/>出荷日<br/>③", SN4: "出荷<br/>BL数<br/>④", SD4: "<br/>出荷日<br/>④", SN5: "出荷<br/>BL数<br/>⑤", SD5: "<br/>出荷日<br/>⑤", SNT: "出荷<br/>BL数<br/>合計",
    OFZN: "<br/>振替済<br/>BL数", OZN: "<br/>残使用<br/>BL数", ONU: "<br/>発注<br/>BL数", TSTR: "<br/>計画<br/>達成率", TSPN: "<br/><br/>計画差", TSSR: "<br/>在庫<br/>消化率", TSN: "<br/><br/>特売残", TSZ: "<br/><br/>残日数", ONUZ: "<br/>発注残<br/>BL数",
    OAN: "<br/>追加<br/>発注BL数",
    OARL: "<br/>リリース<br/>在庫BL数",
    OAMM: "<br/><br/>備考（発注者）",
    OAME: "<br/><br/>備考（営業）",
    OANTE: "<br/>摘要<br/>（半角のみ）",
    ARVM: "<br/><br/>受信管理",
  },
];

export const selectableCol =
{
  no: false,
  //以下に貼り付け
  IJN: true, ICD: false, INM: true,
  MRD: true, MRU: true, MRC: true, MRG: true,
  ISC: true, ISN: true, IMC: true, IMN: true, ICC: true, ICN: true, IBC: true, IBN: true, ICP: true, ILI: true, IPR: true, ISD: true, IED: true, IC1: true, IC2: true, ITN: true,
  SCD: true, SNM: true,
  OSCD: true, OSNM: true,
  TCD: true, TNM: true, TDR: true,
  OOS: true,
  KFR: true, KTO: true,
  HPL: true,
  OFS: true, OND: true, OOU: true, OON: true, OOZ: true, OFN: true, OAYN: true, OMM: true, OME: true, ONT: true,
  RVM: true,
  TBN: true, ZTN: true, ZQ1: true, ZQ2: true, ZQ3: true,
  OAN1: true,
  OANT: true, ORNT: true,
  SN1: true, SD1: true, SN2: true, SD2: true, SN3: true, SD3: true, SN4: true, SD4: true, SN5: true, SD5: true, SNT: true,
  OFZN: true, OZN: true, ONU: true, TSTR: true, TSPN: true, TSSR: true, TSN: true, TSZ: true, ONUZ: true,
  OAN: false, OAMM: false, OAME: false, OANTE: false, ARVM: false, OARL: false,
}

export const headerClass =
{
  no: "tokubaiprogress_N",
  //以下に貼り付け
  IJN: "tokubaiprogress_I", ICD: "tokubaiprogress_I",
  MRD: "tokubaiprogress_GN", MRU: "tokubaiprogress_GN", MRC: "tokubaiprogress_GN", MRG: "tokubaiprogress_GN",
  ISC: "tokubaiprogress_I", ISN: "tokubaiprogress_I", IMC: "tokubaiprogress_I", IMN: "tokubaiprogress_I", INM: "tokubaiprogress_I", ICC: "tokubaiprogress_I", ICN: "tokubaiprogress_I", IBC: "tokubaiprogress_I", IBN: "tokubaiprogress_I", ICP: "tokubaiprogress_I", ILI: "tokubaiprogress_I", IPR: "tokubaiprogress_I", ISD: "tokubaiprogress_I", IED: "tokubaiprogress_I", IC1: "tokubaiprogress_I", IC2: "tokubaiprogress_I", ITN: "tokubaiprogress_I",
  SCD: "tokubaiprogress_S", SNM: "tokubaiprogress_S",
  OSCD: "tokubaiprogress_S", OSNM: "tokubaiprogress_S",
  TCD: "tokubaiprogress_S", TNM: "tokubaiprogress_S", TDR: "tokubaiprogress_S",
  OOS: "tokubaiprogress_GY",
  KFR: "tokubaiprogress_GY", KTO: "tokubaiprogress_GY",
  HPL: "tokubaiprogress_GY",
  OFS: "tokubaiprogress_GY", OND: "tokubaiprogress_GY", OOU: "tokubaiprogress_GY", OON: "tokubaiprogress_GY", OOZ: "tokubaiprogress_GY", OFN: "tokubaiprogress_GY", OAYN: "tokubaiprogress_GY", OMM: "tokubaiprogress_GY", OME: "tokubaiprogress_GY", ONT: "tokubaiprogress_GY",
  RVM: "tokubaiprogress_GY",
  TBN: "tokubaiprogress_T", ZTN: "tokubaiprogress_Z", ZQ1: "tokubaiprogress_Z", ZQ2: "tokubaiprogress_Z", ZQ3: "tokubaiprogress_Z",
  OAN1: "tokubaiprogress_GY",
  OANT: "tokubaiprogress_GY", ORNT: "tokubaiprogress_GY",
  SN1: "tokubaiprogress_P", SD1: "tokubaiprogress_P", SN2: "tokubaiprogress_P", SD2: "tokubaiprogress_P", SN3: "tokubaiprogress_P", SD3: "tokubaiprogress_P", SN4: "tokubaiprogress_P", SD4: "tokubaiprogress_P", SN5: "tokubaiprogress_P", SD5: "tokubaiprogress_P", SNT: "tokubaiprogress_P",
  OFZN: "tokubaiprogress_P", OZN: "tokubaiprogress_P", ONU: "tokubaiprogress_P", TSTR: "tokubaiprogress_P", TSPN: "tokubaiprogress_P", TSSR: "tokubaiprogress_P", TSN: "tokubaiprogress_P", TSZ: "tokubaiprogress_P", ONUZ: "tokubaiprogress_P",
  OAN: "tokubaiprogress_GY", OAMM: "tokubaiprogress_GY", OAME: "tokubaiprogress_GY", OANTE: "tokubaiprogress_GY", ARVM: "tokubaiprogress_GY", OARL: "tokubaiprogress_GY",
}

export const colWidths =
{
  no: 35,
  //以下に貼り付け
  IJN: 120, ICD: 100,
  MRD: 80, MRU: 63, MRC: 50, MRG: 100,
  ISC: 50, ISN: 100, IMC: 50, IMN: 100, INM: 240, ICC: 50, ICN: 80, IBC: 50, IBN: 80, ICP: 50, IC1: 50, IC2: 50, ITN: 50, ILI: 70, IPR: 60, ISD: 80, IED: 80,
  SCD: 50, SNM: 130,
  OSCD: 50, OSNM: 130,
  TCD: 70, TNM: 130, TDR: 40,
  OOS: 40,
  KFR: 80, KTO: 80,
  HPL: 80,
  OFS: 80, OND: 80, OOU: 50, OON: 80, OOZ: 80, OFN: 80, OAYN: 80, OMM: 240, OME: 240, ONT: 150,
  RVM: 70, 
  TBN: 35, ZTN: 60, ZQ1: 70, ZQ2: 70, ZQ3: 70,
  OAN1: 80,
  OANT: 80, ORNT: 80,
  SN1: 60, SD1: 80, SN2: 60, SD2: 80, SN3: 60, SD3: 80, SN4: 60, SD4: 80, SN5: 60, SD5: 80, SNT: 70,
  OFZN: 70, OZN: 70, ONU: 70, TSTR: 70, TSPN: 70, TSSR: 70, TSN: 70, TSZ: 70, ONUZ: 70,
  OAN: 80, OAMM: 240, OAME: 240, OANTE: 200, ARVM: 70, OARL: 80,
};

export const colDataType =
{
  no: { type: 'text', readOnly: true, className: 'htCenter' },
  //以下に貼り付け
  IJN: { type: 'text', readOnly: true },
  ICD: { type: 'text', readOnly: true, className: 'htCenter' },
  MRD: { type: 'text', readOnly: true, className: 'htCenter' },
  MRU: { type: 'text', readOnly: true },
  MRC: { type: 'text', readOnly: true },
  MRG: { type: 'text', readOnly: true },
  ISC: { type: 'text', readOnly: true, className: 'htCenter' },
  ISN: { type: 'text', readOnly: true },
  IMC: { type: 'text', readOnly: true, className: 'htCenter' },
  IMN: { type: 'text', readOnly: true },
  INM: { type: 'text', readOnly: true },
  ICC: { type: 'text', readOnly: true, className: 'htCenter' },
  ICN: { type: 'text', readOnly: true },
  IBC: { type: 'text', readOnly: true, className: 'htCenter' },
  IBN: { type: 'text', readOnly: true },
  ICP: { type: 'text', readOnly: true, className: 'htRight' },
  ILI: { type: 'numeric', numericFormat: { pattern: '0,0日', }, readOnly: true },
  IPR: { type: 'numeric', numericFormat: { pattern: '0,0', }, readOnly: true },
  ISD: { type: 'text', readOnly: true, className: 'htCenter' },
  IED: { type: 'text', readOnly: true, className: 'htCenter' },
  IC1: { type: 'numeric', numericFormat: { pattern: '0,0', }, readOnly: true },
  IC2: { type: 'numeric', numericFormat: { pattern: '0,0', }, readOnly: true },
  ITN: { type: 'numeric', numericFormat: { pattern: '0,0', }, readOnly: true },
  SCD: { type: 'text', readOnly: true, className: 'htCenter' },
  SNM: { type: 'text', readOnly: true },
  OSCD: {type: 'text', readOnly: true, className: 'htCenter'},
  OSNM: {type: 'text', readOnly: true},
  TCD: { type: 'text', readOnly: true, className: 'htCenter' },
  TNM: { type: 'text', readOnly: true },
  TDR: { type: 'text', readOnly: true, className: 'htCenter' },
  OOS: { type: 'text', readOnly: true, className: 'htCenter' },
  KFR: { type: 'text', readOnly: true, className: 'htCenter' },
  KTO: { type: 'text', readOnly: true, className: 'htCenter' },
  HPL: { type: 'numeric', numericFormat: { pattern: '0,0', }, readOnly: true },
  OFS: { type: 'text', readOnly: true, className: 'htCenter' },
  OND: { type: 'text', readOnly: true, className: 'htCenter' },
  OOU: { type: 'text', readOnly: true, className: 'htCenter' },
  OON: { type: 'numeric', numericFormat: { pattern: '0,0', }, readOnly: true },
  OOZ: { type: 'numeric', numericFormat: { pattern: '0,0', }, readOnly: true },
  OFN: { type: 'numeric', numericFormat: { pattern: '0,0', }, readOnly: true },
  OAYN: { type: 'numeric', numericFormat: { pattern: '0,0', }, readOnly: true },
  OMM: { type: 'text', readOnly: true },
  OME: { type: 'text', readOnly: true },
  ONT: { type: 'text', readOnly: true },
  RVM: { type: 'dropdown', source: ['未受信', '当日受信', '受信済', '打伝', '確認中', '取消済'], readOnly: false, className: 'htCenter' },
  TBN: {type: 'text', readOnly: true, className: 'htCenter'},
  ZTN: {type: 'text', readOnly: true, className: 'htCenter'},
  ZQ1: {type: 'numeric', numericFormat: {pattern: '0,0.0',}, readOnly: true},
  ZQ2: {type: 'numeric', numericFormat: {pattern: '0,0.0',}, readOnly: true},
  ZQ3: {type: 'numeric', numericFormat: {pattern: '0,0.0',}, readOnly: true},
  OAN1: { type: 'numeric', numericFormat: { pattern: '0,0', }, readOnly: true },
  OANT: { type: 'numeric', numericFormat: { pattern: '0,0', }, readOnly: true },
  ORNT: { type: 'numeric', numericFormat: { pattern: '0,0', }, readOnly: true },
  SN1: { type: 'numeric', numericFormat: { pattern: '0,0', }, readOnly: true },
  SD1: { type: 'text', readOnly: true, className: 'htCenter' },
  SN2: { type: 'numeric', numericFormat: { pattern: '0,0', }, readOnly: true },
  SD2: { type: 'text', readOnly: true, className: 'htCenter' },
  SN3: { type: 'numeric', numericFormat: { pattern: '0,0', }, readOnly: true },
  SD3: { type: 'text', readOnly: true, className: 'htCenter' },
  SN4: { type: 'numeric', numericFormat: { pattern: '0,0', }, readOnly: true },
  SD4: { type: 'text', readOnly: true, className: 'htCenter' },
  SN5: { type: 'numeric', numericFormat: { pattern: '0,0', }, readOnly: true },
  SD5: { type: 'text', readOnly: true, className: 'htCenter' },
  SNT: { type: 'numeric', numericFormat: { pattern: '0,0', }, readOnly: true },
  OFZN: { type: 'numeric', numericFormat: { pattern: '0,0', }, readOnly: true },
  OZN: { type: 'numeric', numericFormat: { pattern: '0,0', }, readOnly: true },
  ONU: { type: 'numeric', numericFormat: { pattern: '0,0', }, readOnly: true },
  TSTR: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0.0%', }, },
  TSPN: { type: 'numeric', numericFormat: { pattern: '0,0', }, readOnly: true },
  TSSR: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0.0%', }, },
  TSN: { type: 'numeric', numericFormat: { pattern: '0,0', }, readOnly: true },
  TSZ: { type: 'numeric', numericFormat: { pattern: '0,0日', }, readOnly: true },
  ONUZ: { type: 'numeric', numericFormat: { pattern: '0,0', }, readOnly: true },
  OAN: { type: 'numeric', numericFormat: { pattern: '0,0', }, readOnly: false },
  OAMM: { type: 'text', readOnly: false },
  OAME: { type: 'text', readOnly: false },
  OANTE: { type: 'text', readOnly: false },
  ARVM: { type: 'dropdown', source: ['未受信', '当日受信', '受信済', '打伝', '確認中', '取消済'], readOnly: false, className: 'htCenter' },
  OARL: { type: 'numeric', numericFormat: { pattern: '0,0', }, readOnly: false },
};

//function
export type keyInfo = {key:string, dataType:'no'|'data'};

export const getFixedColumnsLeft = (ref: boolean): number => {
  return ref ? fixedColumnsLeft_ref : fixedColumnsLeft_edit;
}
export const getColKeys = (ref: boolean): string[] => {
  return ref ? colKeys_ref : colKeys_edit;
}

export const keyInfoFromCol = (column:number, ref:boolean):keyInfo => {
  const colKeysData_fix = ref ? colKeysData_ref_fix : colKeysData_edit_fix;
  const colKeysData_unfix = ref ? colKeysData_ref_unfix : colKeysData_edit_unfix;

  if(column < colKeysNo.length) {
    return {key:colKeysNo[column], dataType:"no"};
  }
  column -= colKeysNo.length;

  if(column < colKeysData_fix.length) {
    return {key:colKeysData_fix[column], dataType:"data"};
  }
  column -= colKeysData_fix.length;

  if(column < colKeysData_unfix.length) {
    return {key:colKeysData_unfix[column], dataType:"data"};
  }
  column -= colKeysData_unfix.length;

  return null;
}
export const keyInfoFromCols = (columns:number[], ref:boolean):keyInfo[] => {
  const vals:keyInfo[] = [];
  columns.forEach(column => vals.push(keyInfoFromCol(column, ref)))
  return vals;
}
export const keyFromCol = (column:number, ref:boolean):string => {
  const colKeys = ref ? colKeys_ref : colKeys_edit;
  return colKeys[column];
}
export const keyFromCols = (columns:number[], ref:boolean):string[] => {
  const vals:string[] = [];
  columns.forEach(column => vals.push(keyFromCol(column, ref)))
  return vals;
}
export const colFromKey = (colKey:string, ref:boolean):number => {
  const cols = colsFromKey(colKey, ref);
  return cols && cols.length > 0 ? cols[0] : null;
}
export const colsFromKey = (colKey:string, ref:boolean):number[] => {
  const colsKeysMap = ref ? colsKeysMap_ref : colsKeysMap_edit;
  return colsKeysMap[colKey];
}
export const colsFromKeys = (colKeys:string[], ref:boolean):number[] => {
  const vals:number[] = [];
  colKeys.forEach(colKey => {
    const colsKeysMap = colsFromKey(colKey, ref);
    if(colsKeysMap) {
      colsKeysMap.forEach(col => vals.push(col));
    }
  })
  return vals;
}
