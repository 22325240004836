import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { YM, CodeName } from "@/store/common";
import {RequestParam} from "@/assets/apitype/itemList";
import {ItemListTransColRowModel, itemListTransColRowModelDefault} from "@/components/itemlisttrans/ItemListTransTableModel";
import * as arrayUtil from "@/util/arrayUtil";
import moment from 'moment';

//強制表示列
export const mustVisibleColumnsKey = [
  "no", "rowHeader1","rowHeader2","rowHeader3",
  "TTL",
];
//デフォルト表示列
export const defaultVisibleColumnsKey = [
  //以下に貼り付け
  // "ISN", 
  "IMN", "INM", 
  // "ICN", "IBN", 
  "IJN", "ICD", 
  // "ICP", 
  "IC1", "IC2", 
  // "ILI", "IPR", 
];
//デフォルト表示行(月次)1
export const defaultVisibleRowsKeyMonthly = [
  "JJT", "JJR", "JJD",
  "JKT",
  "JRT", "JRD", 
  "KI1KR", "KI2KR", "KI3KR",
  "SQT", "SQP", "SQR", "SQD", "STT", "STR", "STD", "PTT", "PTD", "PRT", "CIT", "CIR", "CID", "CST", "CSR", "CSD", "CTT", "CTR", "CTD", "BTT", "BTD", "ATT", "ATR", "ATD", "ART", "ARD",
  "SHT", "SHR", "SHD",
];

//デフォルト表示行(日次)
export const defaultVisibleRowsKeyDaily = [
  "JJT", //"JJR", "JJD",
  "JKT", //"JKR", "JKD",
  "JRT", //"JRD", 
  "KI1KR", "KI2KR", "KI3KR",
];
//Page State
export type ItemListTransSaveState = {
  //年月
  ym: YM | null,
  ymFrom: YM | null, //推移で使用
  //集計軸  //データ種別
  dataType: "daily" | "quick" | "commit" | null,
  //検索対象
  containSEJ: boolean,

  group2: CodeName[] | null,
  group1: CodeName[] | null,
  shiten: CodeName[] | null,
  center: CodeName[] | null,
  shiire: CodeName[] | null,
  maker: CodeName[] | null,
  tokuisaki: CodeName[] | null,
  pb: CodeName[] | null,
  tag: CodeName[] | null,
  item: string | null,
  category: CodeName[] | null,

  //検索終了した条件
  requestedParam : RequestParam,

  sort: {colKey:string, rowKey:string, asc:boolean},

  hiddenColumns: number[],
  hiddenColumnsKey: string[],
  visibleColumnsKey: string[],

  visibleRowsKeyMonthly: string[],  //現在は未使用。移行として残す
  visibleRowsKeyMonthlyQuick: string[],
  visibleRowsKeyMonthlyCommit: string[],
  visibleRowsKeyDaily: string[],

  showChart: boolean,

};

export const initialItemListTransState: ItemListTransSaveState = {
  //年月
  ym: null,
  ymFrom: null, //推移で使用
  //データ種別
  dataType: "quick",
  //検索対象
  containSEJ: false,

  group2: [],
  group1:  [],
  shiten: [],
  center: [],
  shiire: [],
  maker: [],
  tokuisaki:  [],
  pb: [],
  tag: [],
  item: null,
  category: [],

  requestedParam : {},

  sort: {
    colKey: "TTL", //合計
    rowKey: "STT", //総売上本年実績
    asc: false, //降順
  },
  hiddenColumns: [],
  hiddenColumnsKey: [],
  visibleColumnsKey: defaultVisibleColumnsKey,

  visibleRowsKeyMonthly: defaultVisibleRowsKeyMonthly,
  visibleRowsKeyMonthlyQuick: defaultVisibleRowsKeyMonthly,
  visibleRowsKeyMonthlyCommit: defaultVisibleRowsKeyMonthly,
  visibleRowsKeyDaily: defaultVisibleRowsKeyDaily,

  showChart: true,
};

//Page Slice
export type ItemListTransSaveReducer = {
  initOnDidMount: (state:ItemListTransSaveState, action: PayloadAction<ItemListTransSaveState>) => void,
  resetOnWillUnmount: (state:ItemListTransSaveState) => void,
  setYM: (state:ItemListTransSaveState, action: PayloadAction<{val:YM, ymList:YM[]}>) => void,
  setYMFrom: (state:ItemListTransSaveState, action: PayloadAction<{val:YM, ymList:YM[]}>) => void,
  setYMList: (state:ItemListTransSaveState, action: PayloadAction<YM[]>) => void,
  setDataType: (state:ItemListTransSaveState, action: PayloadAction<string>) => void,
  setContainSEJ: (state:ItemListTransSaveState, action: PayloadAction<boolean>) => void,
  setGroup2: (state:ItemListTransSaveState, action: PayloadAction<CodeName[]>) => void,
  setGroup2List: (state:ItemListTransSaveState, action: PayloadAction<CodeName[]>) => void,
  setGroup1: (state:ItemListTransSaveState, action: PayloadAction<CodeName[]>) => void,
  setGroup1List: (state:ItemListTransSaveState, action: PayloadAction<CodeName[]>) => void,
  setShiten: (state:ItemListTransSaveState, action: PayloadAction<CodeName[]>) => void,
  setShitenList:(state:ItemListTransSaveState, action: PayloadAction<CodeName[]>)  => void,
  setCenter: (state:ItemListTransSaveState, action: PayloadAction<CodeName[]>) => void,
  setCenterList:(state:ItemListTransSaveState, action: PayloadAction<CodeName[]>)  => void,
  setShiire: (state:ItemListTransSaveState, action: PayloadAction<CodeName[]>) => void,
  setShiireList: (state:ItemListTransSaveState, action: PayloadAction<CodeName[]>) => void,
  setMaker: (state:ItemListTransSaveState, action: PayloadAction<CodeName[]>) => void,
  setMakerList: (state:ItemListTransSaveState, action: PayloadAction<CodeName[]>) => void,
  setTokuisaki: (state:ItemListTransSaveState, action: PayloadAction<CodeName[]>) => void,
  setTokuisakiList: (state:ItemListTransSaveState, action: PayloadAction<CodeName[]>) => void,
  setPb: (state:ItemListTransSaveState, action: PayloadAction<CodeName[]>) => void,
  setPbList: (state:ItemListTransSaveState, action: PayloadAction<CodeName[]>) => void,
  setTag: (state:ItemListTransSaveState, action: PayloadAction<CodeName[]>) => void,
  setTagList: (state:ItemListTransSaveState, action: PayloadAction<CodeName[]>) => void,
  setItem: (state:ItemListTransSaveState, action: PayloadAction<string>) => void,
  setCategory: (state:ItemListTransSaveState, action: PayloadAction<CodeName[]>) => void,
  setCategoryList: (state:ItemListTransSaveState, action: PayloadAction<CodeName[]>) => void,
  clearOption: (state:ItemListTransSaveState, action: PayloadAction<{ymList:YM[]}>) => void,
  setRequestedParam: (state:ItemListTransSaveState, action: PayloadAction<RequestParam>) => void,
  setSort: (state:ItemListTransSaveState, action: PayloadAction<{sortColKey:string, sortRowKey:string, sortAsc: boolean}>) => void,
  hideColumnsChange: (state:ItemListTransSaveState, action: PayloadAction<{columns:number[], keys:string[]}>) => void,
  hideColumnKeysChange: (state:ItemListTransSaveState, action: PayloadAction<string[]>) => void,
  visibleRowKeysChange: (state:ItemListTransSaveState, action: PayloadAction<string[]>) => void,
  showChartChange: (state:ItemListTransSaveState, action: PayloadAction<boolean>) => void,
}

const createReducerContent = (name:string, colRowModel:ItemListTransColRowModel, initialState: ItemListTransSaveState, defaultYMOnInit:""|"fiscalYear"|"thisMonth"):ItemListTransSaveReducer => {return {
  //componentDidMount
  initOnDidMount(state:ItemListTransSaveState) {
    //追加属性等はlocalstorageから復元したときにundefinedになっている場合があるためチェックする
    if(state.requestedParam === undefined) {
      state.requestedParam = initialState.requestedParam;
    }

    //最新の年月を選択した状態にする
    if (defaultYMOnInit == "fiscalYear") {
      const now = moment(new Date());
      let thisMonth = moment().set({'year': now.get('year'), 'month': now.get('month'), 'date': 1});
      const first = true;

      thisMonth.add(-3, 'month');
      thisMonth = moment().set({ 'year': thisMonth.get('year'), 'month': 4 - 1, 'date': 1 });
      state.ymFrom = new YM({ code: thisMonth.format('YYYYMM'), name: thisMonth.format('YYYY年MM月'), thisMonth: false });
      thisMonth = moment().set({ 'year': thisMonth.get('year') + 1, 'month': 3 - 1, 'date': 31 });
      state.ym = new YM({ code: thisMonth.format('YYYYMM'), name: thisMonth.format('YYYY年MM月'), thisMonth: first });
      if (state.requestedParam.ym) {
        state.requestedParam.ym = state.ym.code;
        state.requestedParam.ymFrom = state.ymFrom.code;
      }
    }
    else if (defaultYMOnInit == "thisMonth") {
      const now = moment(new Date());
      const thisMonth = moment().set({'year': now.get('year'), 'month': now.get('month'), 'date': 1});
      const first = true;

      state.ym = new YM({ code: thisMonth.format('YYYYMM'), name: thisMonth.format('YYYY年MM月'), thisMonth: first });
      if (state.requestedParam.ym) {
        state.requestedParam.ym = state.ym.code;
      }
    }
    else {
      if(!state.ym) {
        state.ym = initialState.ym;
      }
      if(!state.ymFrom) {
        state.ymFrom = initialState.ymFrom;
      }
    }

    if(!state.dataType) {
      state.dataType = initialState.dataType;
    }
    if (!state.group2 || state.group2.length == 0) {
      state.group2 = initialState.group2;
    }
    if (!state.group1 || state.group1.length == 0) {
      state.group1 = initialState.group1;
    }
    if (!state.shiten || state.shiten.length == 0) {
      state.shiten = initialState.shiten;
    }
    if (!state.center || state.center.length == 0) {
      state.center = initialState.center;
    }
    if (!state.shiire || state.shiire.length == 0) {
      state.shiire = initialState.shiire;
    }
    if (!state.maker || state.maker.length == 0) {
      state.maker = initialState.maker;
    }
    if (!state.tokuisaki || state.tokuisaki.length == 0) {
      state.tokuisaki = initialState.tokuisaki;
    }
    if (!state.pb || state.pb.length == 0) {
      state.pb = initialState.pb;
    }
    if (!state.tag || state.tag.length == 0) {
      state.tag = initialState.tag;
    }
    if (!state.item || state.item.length == 0) {
      state.item = initialState.item;
    }
    if (!state.category || state.category.length == 0) {
      state.category = initialState.category;
    }

    if(!state.sort || !state.sort.colKey) {
      state.sort = initialState.sort;
    }
    //初期表示列0配列は、常に全項目表示とする
    if(initialState.visibleColumnsKey.length == 0) {
      state.visibleColumnsKey = colRowModel.colKeys;
    }
    else if(!state.visibleColumnsKey) {
      state.visibleColumnsKey = initialState.visibleColumnsKey;
    }
    //強制選択列
    state.visibleColumnsKey = arrayUtil.union(arrayUtil.and(colRowModel.colKeys, mustVisibleColumnsKey), state.visibleColumnsKey);
    //非表示列
    state.hiddenColumnsKey = arrayUtil.not(colRowModel.colKeys, state.visibleColumnsKey);

    //行項目
    if (!state.visibleRowsKeyMonthlyQuick || state.visibleRowsKeyMonthlyQuick.length == 0) {
      if(state.visibleRowsKeyMonthly?.length) { //移行
        state.visibleRowsKeyMonthlyQuick = state.visibleRowsKeyMonthly;
      }
      else {
        state.visibleRowsKeyMonthlyQuick = (initialState.visibleRowsKeyMonthlyQuick.length == 0) ? colRowModel.rowKeysMonthlyQuick : initialState.visibleRowsKeyMonthlyQuick;
      }
    }
    if (!state.visibleRowsKeyMonthlyCommit || state.visibleRowsKeyMonthlyCommit.length == 0) {
      if(state.visibleRowsKeyMonthly?.length) { //移行
        state.visibleRowsKeyMonthlyCommit = state.visibleRowsKeyMonthly;
      }
      else {
        state.visibleRowsKeyMonthlyCommit = (initialState.visibleRowsKeyMonthlyCommit.length == 0) ? colRowModel.rowKeysMonthlyCommit : initialState.visibleRowsKeyMonthlyCommit;
      }
    }
    state.visibleRowsKeyMonthly = []; //今後不要

    if (!state.visibleRowsKeyDaily || state.visibleRowsKeyDaily.length == 0) {
      state.visibleRowsKeyDaily = (initialState.visibleRowsKeyDaily.length == 0) ? colRowModel.rowKeysDaily : initialState.visibleRowsKeyDaily;
    }

    //強制選択行
    // state.visibleRowsKeyMonthlyQuick = arrayUtil.union(arrayUtil.and(colRowModel.rowKeysMonthlyQuick, mustVisibleRowsKeyMonthly), state.visibleRowsKeyMonthlyQuick);
    // state.visibleRowsKeyMonthlyCommit = arrayUtil.union(arrayUtil.and(colRowModel.rowKeysMonthlyCommit, mustVisibleRowsKeyMonthly), state.visibleRowsKeyMonthlyCommit);
    // state.visibleRowsKeyDaily = arrayUtil.union(arrayUtil.and(colRowModel.rowKeysDaily, mustVisibleRowsKeyDaily), state.visibleRowsKeyDaily);

    //グラフ表示
    if(state.showChart === undefined) {
      state.showChart = initialState.showChart;
    }

  },
  //componentWillUnmount
  resetOnWillUnmount(state:ItemListTransSaveState) {
      //初期表示列0配列は、常に全項目表示とするため、記憶しない
      if(initialState.visibleColumnsKey.length == 0) {
        state.visibleColumnsKey = [];
      }
  },

  // Option YM
  setYM(state:ItemListTransSaveState, action: PayloadAction<{val:YM, ymList:YM[]}>) {
    const ymList = action.payload.ymList;
    const val = action.payload.val;
    state.ym = val;
    if(state.ym && state.ymFrom) {
      //逆転
      if(state.ym.code < state.ymFrom.code) {
        state.ymFrom = state.ym;
      }

      //最大12ヵ月とする
      const ymFromIndex = ymList.findIndex(ym => ym.code == state.ymFrom.code);
      const ymIndex = ymList.findIndex(ym => ym.code == state.ym.code);
      if(ymFromIndex - ymIndex + 1 > 12) {
        state.ymFrom = ymList[Math.min(ymIndex + 11, ymList.length-1)];
      }
    }
  },
  setYMFrom(state:ItemListTransSaveState, action: PayloadAction<{val:YM, ymList:YM[]}>) {
    const ymList = action.payload.ymList;
    const val = action.payload.val;
    state.ymFrom = val;
    if(state.ym && state.ymFrom) {
      //逆転
      if(state.ym.code < state.ymFrom.code) {
        state.ym = state.ymFrom;
      }

      //最大12ヵ月とする
      const ymFromIndex = ymList.findIndex(ym => ym.code == state.ymFrom.code);
      const ymIndex = ymList.findIndex(ym => ym.code == state.ym.code);
      if(ymFromIndex - ymIndex + 1 > 12) {
        state.ym = ymList[Math.max(ymFromIndex - 11, 0)];
      }
    }
  },
  setYMList(state:ItemListTransSaveState, action: PayloadAction<YM[]>) {
    const ymList = action.payload;
    //ym
    let ym = state.ym && state.ym.code? ymList.find((ym) => ym.code == state.ym.code) : null;
    if(!ym) {
      const isMonthly = dataTypeIsMonthly(state.requestedParam && state.requestedParam.dataType ? state.requestedParam.dataType : initialState.dataType);
      ym = isMonthly ? ymList[0] : ymList.find(data => data.thisMonth);
    }
    state.ym = ym;
    //ymFrom
    let ymFrom = state.ymFrom && state.ymFrom.code? ymList.find((ym) => ym.code == state.ymFrom.code) : null;
    if(!ymFrom) {
      ymFrom = ymList[Math.min(ymList.indexOf(ym)+11, ymList.length-1)]; //１年間
    }
    state.ymFrom = ymFrom;
  },

  //データ種別
  setDataType(state:ItemListTransSaveState, action: PayloadAction<string>) {
    switch(action.payload) {
      case "daily":
        state.dataType = "daily";
        break;
      case "quick":
        state.dataType = "quick";
        break;
      case "commit":
        state.dataType = "commit";
        break;
      default:
        state.dataType = "quick";
        break;
    }
  },
  // setMakerList(state:ItemListTransSaveState, action: PayloadAction<CodeName[]>) {
  //   state.maker = getData(state.maker, action.payload);
  // },
  //検索対象
  setContainSEJ(state:ItemListTransSaveState, action: PayloadAction<boolean>) {
    state.containSEJ = action.payload;
  },
  setGroup2 (state:ItemListTransSaveState, action: PayloadAction<CodeName[]>)     {
    state.group2 = action.payload;
  },
  setGroup2List(state:ItemListTransSaveState, action: PayloadAction<CodeName[]>) {
    state.group2 = getData(state.group2, action.payload);
  },
  setGroup1(state:ItemListTransSaveState, action: PayloadAction<CodeName[]>) {
    state.group1 = action.payload;
  },
  setGroup1List(state:ItemListTransSaveState, action: PayloadAction<CodeName[]>) {
    state.group1 = getData(state.group1, action.payload);
  },
  setShiten(state:ItemListTransSaveState, action: PayloadAction<CodeName[]>) {
    state.shiten = action.payload;
  },
  setShitenList(state:ItemListTransSaveState, action: PayloadAction<CodeName[]>) {
    state.shiten = getData(state.shiten, action.payload);
  },
  setCenter(state:ItemListTransSaveState, action: PayloadAction<CodeName[]>) {
    state.center = action.payload;
  },
  setCenterList(state:ItemListTransSaveState, action: PayloadAction<CodeName[]>) {
    state.center = getData(state.center, action.payload);
  },
  setShiire(state:ItemListTransSaveState, action: PayloadAction<CodeName[]>) {
    state.shiire = action.payload;
  },
  setShiireList(state:ItemListTransSaveState, action: PayloadAction<CodeName[]>) {
    state.shiire = getData(state.shiire, action.payload);
  },
  setMaker(state:ItemListTransSaveState, action: PayloadAction<CodeName[]>) {
    state.maker = action.payload;
  },
  setMakerList(state:ItemListTransSaveState, action: PayloadAction<CodeName[]>) {
    state.maker = getData(state.maker, action.payload);
  },
  setTokuisaki (state:ItemListTransSaveState, action: PayloadAction<CodeName[]>) {
    state.tokuisaki = action.payload;
  },
  setTokuisakiList(state:ItemListTransSaveState, action: PayloadAction<CodeName[]>) {
    state.tokuisaki = getData(state.tokuisaki, action.payload);
  },
  setPb(state:ItemListTransSaveState, action: PayloadAction<CodeName[]>) {
    state.pb = action.payload;
  },
  setPbList(state:ItemListTransSaveState, action: PayloadAction<CodeName[]>) {
    state.pb = getData(state.pb, action.payload);
  },
  setTag(state:ItemListTransSaveState, action: PayloadAction<CodeName[]>) {
    state.tag = action.payload;
  },
  setTagList(state:ItemListTransSaveState, action: PayloadAction<CodeName[]>) {
    state.tag = getData(state.tag, action.payload);
  },
  setItem(state:ItemListTransSaveState, action: PayloadAction<string>) {
    state.item = action.payload;
  },
  setCategory(state:ItemListTransSaveState, action: PayloadAction<CodeName[]>) {
    state.category = action.payload;
  },
  setCategoryList(state:ItemListTransSaveState, action: PayloadAction<CodeName[]>) {
    state.category = getData(state.category, action.payload);
  },

  clearOption(state:ItemListTransSaveState, action: PayloadAction<{
    ymList:YM[],
  }>) {
    const isMonthly = dataTypeIsMonthly(state.requestedParam && state.requestedParam.dataType ? state.requestedParam.dataType : initialState.dataType);
    Object.assign(state, {
      ym: isMonthly ? action.payload.ymList[0] : action.payload.ymList.find(data => data.thisMonth),
      ymFrom: action.payload.ymList[11],
      dataType: initialState.dataType,
      containSEJ: initialState.containSEJ,

      group2: initialState.group2,
      group1: initialState.group1,
      shiten: initialState.shiten,
      center: initialState.center,
      shiire: initialState.shiire,
      maker: initialState.maker,
      tokuisaki: initialState.tokuisaki,
      pb: initialState.pb,
      tag: initialState.tag,
      item: initialState.item,
      category: initialState.category,
    });
  },

  //検索条件
  setRequestedParam(state:ItemListTransSaveState, action: PayloadAction<RequestParam>) {
    state.requestedParam = action.payload;
  },

  setSort(state:ItemListTransSaveState, action: PayloadAction<{sortColKey:string, sortRowKey:string, sortAsc: boolean}>) {
    const colKey = action.payload.sortColKey;
    const rowKey = action.payload.sortRowKey;
    const asc = action.payload.sortAsc;
    state.sort = {
      colKey: colKey,
      rowKey: rowKey,
      asc: asc,
    }
  },
  hideColumnsChange(state:ItemListTransSaveState, action: PayloadAction<{columns:number[], keys:string[]}>){
    console.log('store.hideColumnsChange');
    Object.assign(state, {
      hiddenColumns: action.payload.columns,
      hiddenColumnsKey: action.payload.keys,
      visibleColumnsKey: arrayUtil.not(colRowModel.colKeys, action.payload.keys),
    });
  },
  hideColumnKeysChange(state:ItemListTransSaveState, action: PayloadAction<string[]>){
    console.log('store.hideColumnKeysChange');
    Object.assign(state, {
      hiddenColumns: colRowModel.colFromKeys(action.payload),
      hiddenColumnsKey: action.payload,
      visibleColumnsKey: arrayUtil.not(colRowModel.colKeys, action.payload),
    });
  },
  visibleRowKeysChange(state:ItemListTransSaveState, action: PayloadAction<string[]>){
    console.log('store.visibleRowKeysChange');

    const dataType: "daily" | "quick" | "commit" | string = state.requestedParam?.dataType;
    const isMonthly = dataTypeIsMonthly(dataType);
    if(isMonthly) {
      switch (dataType) {
        case "quick":
          Object.assign(state, {
            visibleRowsKeyMonthlyQuick: action.payload,
          });
          break;
        case "commit":
          Object.assign(state, {
            visibleRowsKeyMonthlyCommit: action.payload,
          });
          break;
        default:
          break;
      }
    }
    else {
      Object.assign(state, {
        visibleRowsKeyDaily: action.payload,
      });
    }
  },
  showChartChange(state:ItemListTransSaveState, action: PayloadAction<boolean>){
    console.log('store.showChartChange');
    state.showChart = action.payload;
  },
}};

const createSliceContent = (name:string, colRowModel:ItemListTransColRowModel, initialState: ItemListTransSaveState, defaultYMOnInit:""|"fiscalYear"|"thisMonth") => createSlice({
  name: name,
  initialState: initialState,
  reducers: createReducerContent(name, colRowModel, initialState, defaultYMOnInit),
});

//集計軸
export const axisName = (axis: "group1" | "group2" | "shiten" | "center" | "maker" | "tokuisaki" | "pb" | "tag" | "item" | "shiire" | "category" | string) => {
  switch(axis) {
    case "group1":
      return "企業グループ1";
    case "group2":
      return "企業グループ2";
    case "shiten":
      return "支店";
    case "center":
      return "倉庫";
    case "maker":
      return "メーカー";
    case "tokuisaki":
      return "得意先";
    case "pb":
      return "PB区分";
    case "tag":
      return "タグ";
    case "item":
      return "商品CDまたはJANコード";
    case "shiire":
      return "仕入先";
    case "category":
      return "商品カテゴリ";
    default:
      return "-";
  }
}

//データ種別
export const dataTypeName = (dataType: "daily" | "quick" | "commit" | string) => {
  switch(dataType) {
    case "daily":
      return "日次速報";
    case "quick":
      return "月次速報";
    case "commit":
      return "月次確報";
    default:
      return "-";
  }
}
//データ種別の月次判定
export const dataTypeIsMonthly = (dataType: "daily" | "quick" | "commit" | string) => {
  switch(dataType) {
    case "daily":
      return false;
    case "quick":
    case "commit":
    default:
      return true;
  }
}
//検索対象
export const containSEJName = (containSEJ:boolean) => {
  if(containSEJ) {
    return "SEJを含む";
  }
  else {
    return "一般";
  }
}

const getData = (target: CodeName[], list: CodeName[]): CodeName[] => {
  let datas = [];
  if (target && target.length > 0) {
    const codes: string[] = target.filter(data => !(!data)).map(data => data.code);
    datas = list.filter(data => codes.indexOf(data.code) !== -1)
      .filter((value, index, self) => self.findIndex(s => s.code == value.code) === index);
  }
  return datas;
};

//Page Slice Export
//itemListTransSaveSlice
export const itemListTransSaveSlice = createSliceContent("itemListTransSave", itemListTransColRowModelDefault, initialItemListTransState, "");
