import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {RequestParam} from "@/assets/apitype/outputDataListSupplier";

import { CodeName } from "@/store/common";
import moment from 'moment';

//Page State
export type OutputDataSupplierSaveState = {
  // 納品月
  ymd: Date | null,
  ymdFrom: Date | null,
  shukkaYmd: Date | null,
  shukkaYmdFrom: Date | null,
  //得意先
  tokuisaki: CodeName | null,
  //部門
  bu: CodeName[] | null,
  //倉庫
  center: CodeName[] | null,
  // ベンダーコード
  supplier: CodeName[] | null,
  honbuCode: string | null,
  // 商品コード
  item: string | null,
  makerList: CodeName[],  //メーカー
  optionMakers: CodeName[],  //メーカー
  optionMakerCode: string,   //メーカーCD
  optionSuppliers: CodeName[],  //仕入れ先
  
  //検索終了した条件
  requestedParam : RequestParam,

};

export const initialState: OutputDataSupplierSaveState = {
  // 納品月
  ymd: null,
  ymdFrom: null,
  shukkaYmd: null,
  shukkaYmdFrom: null,
  //得意先
  tokuisaki: null,
  //部門
  bu: [],
  //倉庫
  center: [],
  // ベンダーコード
  supplier: [],
  honbuCode: '',
  //商品コード
  item: null,
  makerList: [],
  optionMakers: [],      //メーカー
  optionMakerCode: '',   //メーカーCD
  optionSuppliers: [],

  requestedParam : {},

};

//Page Slice
export type OutputDataSupplierSaveReducer = {
  initOnDidMount: (state: OutputDataSupplierSaveState) => void,
  setYMFrom: (state: OutputDataSupplierSaveState, action: PayloadAction<Date>) => void,
  setYM: (state: OutputDataSupplierSaveState, action: PayloadAction<Date>) => void,
  setShukkaYmdFrom: (state: OutputDataSupplierSaveState, action: PayloadAction<Date>) => void,
  setShukkaYmd: (state: OutputDataSupplierSaveState, action: PayloadAction<Date>) => void,
  setTokuisaki: (state: OutputDataSupplierSaveState, action: PayloadAction<CodeName>) => void,
  setTokuisakiList: (state: OutputDataSupplierSaveState, action: PayloadAction<CodeName[]>) => void,
  setBu: (state: OutputDataSupplierSaveState, action: PayloadAction<CodeName[]>) => void,
  setBuList: (state: OutputDataSupplierSaveState, action: PayloadAction<CodeName[]>) => void,
  setCenter: (state: OutputDataSupplierSaveState, action: PayloadAction<CodeName[]>) => void,
  setCenterList: (state: OutputDataSupplierSaveState, action: PayloadAction<CodeName[]>) => void,
  setSupplier: (state: OutputDataSupplierSaveState, action: PayloadAction<CodeName[]>) => void,
  setSupplierList: (state: OutputDataSupplierSaveState, action: PayloadAction<CodeName[]>) => void,
  setHonbuCode: (state: OutputDataSupplierSaveState, action: PayloadAction<string>) => void,
  setItem: (state: OutputDataSupplierSaveState, action: PayloadAction<string>) => void,
  setMakerList: (state: OutputDataSupplierSaveState, action: PayloadAction<CodeName[]>) => void,
  setOptionMakers: (state: OutputDataSupplierSaveState, action: PayloadAction<CodeName[]>) => void,
  setOptionMakerCode: (state: OutputDataSupplierSaveState, action: PayloadAction<string>) => void,
  setOptionSuppliers: (state: OutputDataSupplierSaveState, action: PayloadAction<CodeName[]>) => void,
  clearOption: (state: OutputDataSupplierSaveState, action: PayloadAction<{ tokuisakiList: CodeName[], }>) => void,
  setRequestedParamSupplier: (state: OutputDataSupplierSaveState, action: PayloadAction<RequestParam>) => void,
}

const createReducerContent = (name: string): OutputDataSupplierSaveReducer => {
  return {
    //componentDidMount
    initOnDidMount(state: OutputDataSupplierSaveState) {
      //追加属性等はlocalstorageから復元したときにundefinedになっている場合があるためチェックする
      if (state.requestedParam === undefined) {
        state.requestedParam = initialState.requestedParam;
      }
      const LastMonth = moment().subtract(1, 'month');

      if (!isTerm(state.ymdFrom, state.ymd, state.shukkaYmdFrom, state.shukkaYmd)) {
        if (!state.ymdFrom) {
          state.ymdFrom = LastMonth.startOf('month').toDate();
        }
        if (!state.ymd) {
          state.ymd = LastMonth.endOf('month').toDate();
        }
        if (!state.shukkaYmdFrom) {
          state.shukkaYmdFrom = LastMonth.startOf('month').toDate();
        }
        if (!state.shukkaYmd) {
          state.shukkaYmd = LastMonth.endOf('month').toDate();
        }
      }

      if (!state.tokuisaki) {
        state.tokuisaki = initialState.tokuisaki;
      }
      if (!state.bu || state.bu.length == 0) {
        state.bu = initialState.bu;
      }
      if (!state.center || state.center.length == 0) {
        state.center = initialState.center;
      }
      if (!state.supplier || state.supplier.length == 0) {
        state.supplier = initialState.supplier;
      }
      if (!state.honbuCode) {
        state.honbuCode = initialState.honbuCode;
      }
      if (!state.item || state.item.length == 0) {
        state.item = initialState.item;
      }
      if(state.optionMakers === undefined) {
        state.optionMakers = initialState.optionMakers;
      }
      if(state.optionMakerCode === undefined) {
        state.optionMakerCode = initialState.optionMakerCode;
      }
      if(state.optionSuppliers === undefined) {
        state.optionSuppliers = initialState.optionSuppliers;
      }
    },

    // Option YM
    setYMFrom(state: OutputDataSupplierSaveState, action: PayloadAction<Date>) {
      state.ymdFrom = action.payload;
      if (state.ymd && state.ymdFrom) {
        //逆転（Date型のままだと比較がうまく機能しなかったため数値に変換）
        if (parseInt(moment(state.ymd).format('YYYYMMDD')) < parseInt(moment(state.ymdFrom).format('YYYYMMDD'))) {
          //終了日 ＝ 開始日 + 1か月 - 1日（最大は前日まで）
          const previousDay = moment().subtract(1, 'days').toDate();
          const ymd = moment(state.ymdFrom).add(1, 'months').subtract(1, 'days').toDate();
          state.ymd = ymd > previousDay ? previousDay : ymd;
        }
      }
    },
    setYM(state: OutputDataSupplierSaveState, action: PayloadAction<Date>) {
      state.ymd = action.payload;
      if (state.ymd && state.ymdFrom) {
        //逆転
        if (parseInt(moment(state.ymd).format('YYYYMMDD')) < parseInt(moment(state.ymdFrom).format('YYYYMMDD'))) {
          //開始日 ＝ 終了日 - 1か月 + 1日
          state.ymdFrom = moment(state.ymd).subtract(1, 'months').add(1, 'days').toDate();
        }
      }
    },
    setShukkaYmdFrom(state: OutputDataSupplierSaveState, action: PayloadAction<Date>) {
      state.shukkaYmdFrom = action.payload;
      if (state.shukkaYmd && state.shukkaYmdFrom) {
        //逆転（Date型のままだと比較がうまく機能しなかったため数値に変換）
        if (parseInt(moment(state.shukkaYmd).format('YYYYMMDD')) < parseInt(moment(state.shukkaYmdFrom).format('YYYYMMDD'))) {
          //終了日 ＝ 開始日 + 1か月 - 1日（最大は前日まで）
          const previousDay = moment().subtract(1, 'days').toDate();
          const shukkaYmd = moment(state.shukkaYmdFrom).add(1, 'months').subtract(1, 'days').toDate();
          state.shukkaYmd = shukkaYmd > previousDay ? previousDay : shukkaYmd;
        }
      }
    },
    setShukkaYmd(state: OutputDataSupplierSaveState, action: PayloadAction<Date>) {
      state.shukkaYmd = action.payload;
      if (state.shukkaYmd && state.shukkaYmdFrom) {
        //逆転
        if (parseInt(moment(state.shukkaYmd).format('YYYYMMDD')) < parseInt(moment(state.shukkaYmdFrom).format('YYYYMMDD'))) {
          //開始日 ＝ 終了日 - 1か月 + 1日
          state.shukkaYmdFrom = moment(state.shukkaYmd).subtract(1, 'months').add(1, 'days').toDate();
        }
      }
    },
    // Option Tokuisaki
    setTokuisaki(state: OutputDataSupplierSaveState, action: PayloadAction<CodeName>) {
      state.tokuisaki = action.payload;
    },
    setTokuisakiList(state: OutputDataSupplierSaveState, action: PayloadAction<CodeName[]>) {
      const tokuisakiList = action.payload;
      let tokuisaki = state.tokuisaki && state.tokuisaki.code ? tokuisakiList.find((tokuisaki) => tokuisaki.code == state.tokuisaki.code) : null;
      if (!tokuisaki) {
        tokuisaki = tokuisakiList[0];
      }
      state.tokuisaki = tokuisaki;
    },
    // Option Bu
    setBu(state: OutputDataSupplierSaveState, action: PayloadAction<CodeName[]>) {
      state.bu = action.payload;
    },
    setBuList(state: OutputDataSupplierSaveState, action: PayloadAction<CodeName[]>) {
      state.bu = getData(state.bu, action.payload);
    },
    // Option Center
    setCenter(state: OutputDataSupplierSaveState, action: PayloadAction<CodeName[]>) {
      state.center = action.payload;
    },
    setCenterList(state: OutputDataSupplierSaveState, action: PayloadAction<CodeName[]>) {
      state.center = getData(state.center, action.payload);
    },
    // Option Supplier
    setSupplier(state: OutputDataSupplierSaveState, action: PayloadAction<CodeName[]>) {
      state.supplier = action.payload;
    },
    setSupplierList(state: OutputDataSupplierSaveState, action: PayloadAction<CodeName[]>) {
      state.supplier = getData(state.supplier, action.payload);
    },
    setHonbuCode(state: OutputDataSupplierSaveState, action: PayloadAction<string>) {
      state.honbuCode = action.payload;
    },
    // Option ItemCode
    setItem(state: OutputDataSupplierSaveState, action: PayloadAction<string>) {
      state.item = action.payload;
    },
    setMakerList(state: OutputDataSupplierSaveState, action: PayloadAction<CodeName[]>) {
      state.makerList = action.payload;
    },
    setOptionMakers(state: OutputDataSupplierSaveState, action: PayloadAction<CodeName[]>) {
      state.optionMakers = action.payload;
    },
    setOptionMakerCode(state: OutputDataSupplierSaveState, action: PayloadAction<string>) {
      state.optionMakerCode = action.payload;
    },
    setOptionSuppliers(state: OutputDataSupplierSaveState, action: PayloadAction<CodeName[]>) {
      state.optionSuppliers = action.payload;
    },

    clearOption(state: OutputDataSupplierSaveState, action: PayloadAction<{
      tokuisakiList: CodeName[],
    }>) {
      //先月の月初から月末を選択した状態にする
      const LastMonth = moment().subtract(1, 'month');

      state.ymdFrom = LastMonth.startOf('month').toDate();
      state.ymd = LastMonth.endOf('month').toDate();
      state.shukkaYmdFrom = LastMonth.startOf('month').toDate();
      state.shukkaYmd = LastMonth.endOf('month').toDate();
      state.tokuisaki = action.payload.tokuisakiList[0];
      state.bu = [];
      state.center = [];
      state.supplier = [];
      state.honbuCode = initialState.honbuCode;
      state.item = '';
      state.optionMakers = initialState.optionMakers;
      state.optionMakerCode = initialState.optionMakerCode;
      state.optionSuppliers = initialState.optionSuppliers;
    },

    //検索条件
    setRequestedParamSupplier(state: OutputDataSupplierSaveState, action: PayloadAction<RequestParam>) {
      state.requestedParam = action.payload;
    },
  }
};

const createSliceContent = (name:string) => createSlice({
  name: name,
  initialState: initialState,
  reducers: createReducerContent(name),
});

const getData = (target: CodeName[], list: CodeName[]): CodeName[] => {
  let datas = [];
  if (target && target.length > 0) {
    const codes: string[] = target.map(data => data.code);
    datas = list.filter(data => codes.indexOf(data.code) !== -1);
  }
  return datas;
};

export const isTerm = (ymdFrom: Date | string | null, ymdTo: Date | string | null, shukkaYmdFrom: Date | string | null, shukkaYmdTo: Date | string | null): boolean => {
  const term1 = ((ymdFrom ? 1 : 0) + (ymdTo ? 1 : 0));
  const term2 = ((shukkaYmdFrom ? 1 : 0) + (shukkaYmdTo ? 1 : 0));
  return !((term1 === 1 || term2 === 1) || term1 === 0 && term2 === 0);
}


//Page Slice Export
//outputDataSupplierSave
export const outputDataSupplierSaveSlice = createSliceContent("outputDataSupplierSave");
export const outputDataStoreSaveSlice = createSliceContent("outputDataStoreSave");