import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {colFromKey, colKeysData_ref_fix, colKeysData_edit_fix, colKeysData_ref_unfix, colKeysData_edit_unfix, keyFromCol, keyInfoFromCol, colKeysNo} from "@/components/tokubai/progress/TokubaiProgressTableModel";
import { RequestParam } from "@/assets/apitype/tokubaiProgressRegist";

import Handsontable from 'handsontable';

import * as calcUtil from "@/util/calcUtil";
import * as editorUtil from "@/util/editorUtil";
import * as compareUtil from "@/util/compareUtil";
import {CodeName} from "@/store/common";
import { colDataType } from "@/components/tokubai/progress/TokubaiProgressTableModel";
import moment from 'moment';

export type RetrievedId = {uuid: string, lv:number};
//エラー情報
export interface ErrorInfoData {
  OAN?: string | null,
  OARL?: string | null,
  OANTE?: string | null,
}
export interface WarnInfoData {
  OAN?: string | null,
  ZTN?: string | null,
}

export interface RowData {
  seq?: number,
  a_seq?: number,
  uuid?: string,

  //以下に貼り付け
  MRD?: string | null, MRU?: string | null, MRC?: string | null, MRG?: string | null,
  IJN?: string | null, IJC?: number | null, ICD?: string | null,
  ISC?: string | null, ISN?: string | null, IMC?: string | null, IMN?: string | null, INM?: string | null, ICC?: string | null, ICN?: string | null, IBC?: string | null, IBN?: string | null, ICP?: string | null, ILI?: number | null, IPR?: number | null, ISD?: string | null, IED?: string | null, IC1?: number | null, IC2?: number | null, ITN?: number | null,
  SCD?: string | null, SNM?: string | null,
  OSCD?: string | null, OSNM?: string | null,
  TCD?: string | null, TNM?: string | null, TDR?: boolean | string | null,
  OOS?: string | null,
  KFR?: string | null, KTO?: string | null,
  HPL?: number | null,
  OFS?: string | null,
  OND?: string | null,
  OOU?: string | null,
  OON?: number | null,
  OOZ?: number | null,
  OFN?: number | null,
  OZN?: number | null,
  OAYN?: number | null,
  OMM?: string | null,
  OME?: string | null,
  ONT?: string | null,
  RVM?: string | null,
  TBN?: string | null,
  ZTN?: string | null,
  ZQ1?: number | null, ZQ2?: number | null, ZQ3?: number | null,
  DATEUP?: string | null
  TIMEUP?: string | null

  OAN1?: number | null,
  OANT?: number | null,
  ORNT?: number | null,
  OFZN?: number | null,
  ONU?: number | null,
  ONUZ?: number | null,
  OUC?: string | null,
  OUN?: string | null,
  SN1?: number | null,
  SD1?: string | null,
  SN2?: number | null,
  SD2?: string | null,
  SN3?: number | null,
  SD3?: string | null,
  SN4?: number | null,
  SD4?: string | null,
  SN5?: number | null,
  SD5?: string | null,
  SNT?: number | null,

  TSTR?: number | null,
  TSPN?: number | null,
  TSSR?: number | null,
  TSN?: number | null,
  TSZ?: number | null,

  OAN?: number | null,
  OAMM?: string | null,
  OAME?: string | null,
  OANTE?: string | null,
  OARL?: number | null,
  ARVM?: string | null,

  //
  group1?: string | null,

  //エラー情報
  errorInfo?: ErrorInfoData,
  //警告情報
  warnInfo?: WarnInfoData,

  //編集
  edited?:boolean,
  oldRVM?: string | null,

  //
  seq_tokubai_progress?: number | null,

}

//編集済み判定
export const isEditedRowData = (data:RowData):boolean => {
  if(!data){
    return false;
  }

  return (
    data.OAN > 0 || data.OARL > 0
  );
}

export interface RowInfo {
  no?: string,
  row:number,
  data:RowData,
  dataIndex:number,
}


export type findedCell = {
  row: number,
  col: number,
  data,
  text: string,
}

//Page State
export type State = {
  //倉庫
  centerList: CodeName[],
  //依頼者
  requestUserList: CodeName[],
  //企業グループ１
  group1List: CodeName[],
  //メーカー
  makerList: CodeName[],

  editingType: 'none'|'edit'|'ref',
  //編集中の条件
  editingParam : RequestParam,

  // //検索する条件
  // requestParam : RequestParam,
  // requestParamQueue : RequestParam[],

  progress: Record<string, unknown>,
  datas: RowData[],
  // dataRowIndexes: number[],
  rowInfos: RowInfo[],
  rows, // any[][]
  mergeCells: {row: number, col: number, rowspan: number, colspan: number}[],
  // selectionRowStart: number,
  // selectionRowEnd: number,

  initList: string[] | null,
  conditionsStack: Handsontable.plugins.FiltersPlugin.ColumnConditions[] | null,
  indexedValues: any[] | null,

  errorMessage: string | null,
  infoMessage: string | null,
};

export const initialState: State = {
  //倉庫
  centerList: [],
  //依頼者
  requestUserList: [],
  //企業グループ１
  group1List: [],
  //メーカー
  makerList: [],


  editingType: 'none',
  editingParam : {},

  // requestParam : {},
  // requestParamQueue: [],

  progress: {},
  datas: [],
  // dataRowIndexes: [],
  rowInfos: [],
  rows: [],
  mergeCells: [],
  // selectionRowStart: -1,
  // selectionRowEnd: -1,

  initList: ["group1", "center", "maker", "user"],
  conditionsStack: [],
  indexedValues: [],

  errorMessage: null,
  infoMessage: null,
};


//Page Slice
export const tokubaiProgressTmpSlice = createSlice({
  name: "tokubaiProgressTmp",
  initialState,
  reducers: {
    //componentDidMount
    initOnDidMount() {
      console.log('store.initOnDidMount');
    },
    //componentWillUnmount
    resetOnWillUnmount() {
      console.log('store.resetOnWillUnmount');
    },

    setRequestUserList(state:State, action: PayloadAction<CodeName[]>) {
      state.requestUserList = action.payload;
    },
    // Option Center
    setCenterList(state:State, action: PayloadAction<CodeName[]>) {
      state.centerList = action.payload;
    },
    // Option Group1
    setGroup1List(state:State, action: PayloadAction<CodeName[]>) {
      state.group1List = action.payload;
    },
    // Option Maker
    setMakerList(state:State, action: PayloadAction<CodeName[]>) {
      state.makerList = action.payload;
    },

    setEditingStart(state:State, action: PayloadAction<{editingType: 'none'|'edit'|'ref', editingParam:RequestParam}>) {
      console.log('store.setEditingStart');
      Object.assign(state, {
        editingType: action.payload.editingType,
        editingParam: action.payload.editingParam,
      });
    },
    setEditingEnd(state) {
      console.log('store.setEditingEnd');

      const ref = state.editingType == 'ref';
      const datas = [{}];
      const rowInfos = convertRows(datas, ref);
      Object.assign(state, {
        editingType: 'none',
        editingParam: initialState.editingParam,

        datas: datas,
        // dataRowIndexes: rowInfos.dataRowIndexes,
        rowInfos: rowInfos.rowInfos,
        rows: rowInfos.rows,
        mergeCells: rowInfos.mergeCells,
      });

    },
    setReleaseResult(state:State, action: PayloadAction<number[]>) {
      const seqs = action.payload;
      console.log('store.setReleaseResult');
      const newDatas = state.datas.filter(data => !seqs.includes(data.seq));

      const ref = state.editingType == 'ref';
      const rowInfos = convertRows(newDatas, ref);
      Object.assign(state, {
        rowInfos: rowInfos.rowInfos,
        rows: rowInfos.rows,
        mergeCells: rowInfos.mergeCells,
      });
    },
    // //検索条件
    // setRequestParam(state:State, action: PayloadAction<RequestParam>) {
    //   console.log('store.setRequestParam');
    //   state.requestParam = action.payload;
    // },
    // addRequestParamQueue(state:State, action: PayloadAction<RequestParam[]>) {
    //   console.log('store.addRequestParamQueue');
    //   state.requestParamQueue = [...state.requestParamQueue, ...action.payload];
    // },
    // setRequestParamQueue(state:State, action: PayloadAction<RequestParam[]>) {
    //   console.log('store.setRequestParamQueue');
    //   state.requestParamQueue = action.payload;
    // },
    // clearRequestParamQueue(state:State, action: PayloadAction<RequestParam[]>) {
    //   console.log('store.clearRequestParamQueue');
    //   state.requestParamQueue = [];
    // },

    putProgress(state:State, action: PayloadAction<string>) {
      console.log('store.putProgress');
      const key = action.payload;
      const progressNew = {...state.progress};
      progressNew[key] = true;
      state.progress = progressNew;
    },
    removeProgress(state:State, action: PayloadAction<string>) {
      console.log('store.removeProgress');
      const key = action.payload;
      const progressNew = {};
      Object.keys(state.progress).forEach(k => {
        if(key != k) {
          progressNew[k] = true;
        }
      })
      state.progress = progressNew;
    },

    execSort(state: State, action: PayloadAction<{ key: string, asc: boolean }[]>) {
      console.log('store.execSort');
      const sorts = action.payload;

      let datas = [...state.datas];
      datas = doSort(datas, sorts);
      // datas = resetRowNo(datas, null); //列番号の振りなおし

      const ref = state.editingType == 'ref';
      const rowInfos = convertRows(datas, ref);
      Object.assign(state, {
        datas: datas,
        // dataRowIndexes: rowInfos.dataRowIndexes,
        rowInfos: rowInfos.rowInfos,
        rows: rowInfos.rows,
        mergeCells: rowInfos.mergeCells,
      });
    },
    setDatas(state: State, action: PayloadAction<{ datas: RowData[], sorts: { key: string, asc: boolean }[] }>) {
      console.log('store.setDatas');
      const ref = state.editingType == 'ref';
      let datas = action.payload.datas;
      const sorts = action.payload.sorts;
      datas = parseData(datas);
      datas = calcDatas(datas);
      datas = checkDatas(datas); //データチェック
      datas = doSort(datas, sorts);

      const rowInfos = convertRows(datas, ref);
      Object.assign(state, {
        datas: datas,
        // dataRowIndexes: rowInfos.dataRowIndexes,
        rowInfos: rowInfos.rowInfos,
        rows: rowInfos.rows,
        mergeCells: rowInfos.mergeCells,
      });
    },
    editRowDatas(state: State, action: PayloadAction<{
      editDatas: {
        row: number, col: number, value: string | number | object | null
          , relatedValues?: { key: string, value: string | number | object | null }[]
      }[],
      sorts: { key: string, asc: boolean }[]
    }>) {
      console.log('store.editRowDatas');
      const sorts = action.payload.sorts;
      const editDatas = action.payload.editDatas;
      let newDatas = [...state.datas];
      // const newRows = [...state.rows];

      const editDataContent = (rowInfo:RowInfo, row:number, col:number, key:string, value:string|number|boolean|object|null, ref:boolean) => {
        if(!(row >= 0) || (!key && !(col >= 0))) {
          return;
        }
        if(!key) {
          key = keyFromCol(col, ref);
        }
        if(!(col >= 0)) {
          col = colFromKey(key, ref);
        }
        const keyInfo = keyInfoFromCol(col, ref);

        //parse
        const dataType = colDataType[key];
        value = editorUtil.parseValue(value, dataType.type,
          dataType.type == 'numeric' ? dataType.numericFormat.pattern :
          dataType.type == 'date' ? dataType.dateFormat :
          null);

        let data = newDatas[rowInfo.dataIndex];

        switch (keyInfo.dataType) {
          case "no":
            break;
          case "data":
            data[key] = value;
            data = calcData(data);
            data = checkData(data);
            break;

          default:
            break;
        }
        // newRows[row][col] = value;
      };

      const ref = state.editingType == 'ref';
      editDatas.forEach((editData)=>{
        const key = keyFromCol(editData.col, ref);
        const rowInfo = state.rowInfos[editData.row];
        editDataContent(rowInfo, editData.row, editData.col, key, editData.value, ref);
        //関連データの更新
        if(editData.relatedValues) {
          editData.relatedValues.forEach(relatedValue => {
            editDataContent(rowInfo, editData.row, null, relatedValue.key, relatedValue.value, ref);
          })
        }
      });
      newDatas = doSort(newDatas, sorts);
      const rowInfos = convertRows(newDatas, ref);
      Object.assign(state, {
        datas: newDatas,
        // dataRowIndexes: rowInfos.dataRowIndexes,
        rowInfos: rowInfos.rowInfos,
        rows: rowInfos.rows,
        mergeCells: rowInfos.mergeCells,
      });
    },
    refreshTable(state){
      console.log('store.refreshTable');
      const ref = state.editingType == 'ref';
      const rowInfos = convertRows(state.datas, ref);
      Object.assign(state, {
        // dataRowIndexes: rowInfos.dataRowIndexes,
        rowInfos: rowInfos.rowInfos,
        rows: rowInfos.rows,
        mergeCells: rowInfos.mergeCells,
      });
    },
    setInitList(state:State, action: PayloadAction<string>) {
      if (state.initList.some(data => data == action.payload)) {
        state.initList = state.initList.filter(data => data != action.payload);
      }
    },
    setConditionsStack(state:State, action: PayloadAction<Handsontable.plugins.FiltersPlugin.ColumnConditions[]>) {
      state.conditionsStack = action.payload;
    },
    setIndexedValues(state:State, action: PayloadAction<any[]>) {
      state.indexedValues = action.payload;
    },
    setErrorMessage(state:State, action: PayloadAction<string>) {
      console.log('store.setErrorMessage');
      state.errorMessage = action.payload;
    },
    setInfoMessage(state:State, action: PayloadAction<string>) {
      console.log('store.setInfoMessage');
      state.infoMessage = action.payload;
    },
  },
});

//数値のパース(数値が文字列で返ってくる)
const parseData = (datas:RowData[]): RowData[] => {
  //set No.
  datas.forEach((data) => {
    if(typeof data.IJC === 'string') data.IJC = parseInt(data.IJC);
    if(typeof data.ILI === 'string') data.ILI = parseInt(data.ILI);
    if(typeof data.IPR === 'string') data.IPR = parseInt(data.IPR);
    if(typeof data.IC1 === 'string') data.IC1 = parseInt(data.IC1);
    if(typeof data.IC2 === 'string') data.IC2 = parseInt(data.IC2);
    if(typeof data.ITN === 'string') data.ITN = parseInt(data.ITN);
    data.ISD = data.ISD?.length == 8 && moment(data.ISD).isValid() ? moment(data.ISD).format('YY/MM/DD') : "";
    data.IED = data.IED?.length == 8 && moment(data.IED).isValid() ? moment(data.IED).format('YY/MM/DD') : "";

    if(typeof data.OON === 'string') data.OON = parseInt(data.OON);
    if(typeof data.OOZ === 'string') data.OOZ = parseInt(data.OOZ);
    if(typeof data.OAN1 === 'string') data.OAN1 = parseInt(data.OAN1);

    if(typeof data.OFZN === 'string') data.OFZN = parseInt(data.OFZN);
    if(typeof data.ONU === 'string') data.ONU = parseInt(data.ONU);

    if(typeof data.SN1 === 'string') data.SN1 = parseInt(data.SN1);
    if(typeof data.SN2 === 'string') data.SN2 = parseInt(data.SN2);
    if(typeof data.SN3 === 'string') data.SN3 = parseInt(data.SN3);
    if(typeof data.SN4 === 'string') data.SN4 = parseInt(data.SN4);
    if(typeof data.SN5 === 'string') data.SN5 = parseInt(data.SN5);
    if(typeof data.SNT === 'string') data.SNT = parseInt(data.SNT);

    if(typeof data.ZQ1 === 'string') data.ZQ1 = parseFloat(data.ZQ1);
    if(typeof data.ZQ2 === 'string') data.ZQ2 = parseFloat(data.ZQ2);
    if(typeof data.ZQ3 === 'string') data.ZQ3 = parseFloat(data.ZQ3);
  });
  return datas;
}

//計算
const calcDatas = (datas:RowData[]): RowData[] => {
  return datas.map(data => calcData(data));
}
const calcData = (data:RowData): RowData => {
  //TODO 計算項目
  data.OZN = data.OZN ? data.OZN : null;  //進捗（残使用BL数）
  data.ONU = data.ONU ? data.ONU : null;  //進捗（発注BL数）

  //A+B
  data.OFN = data.OON + data.OOZ;
  //発注累計BL数
  data.OANT = data.OFN + data.OAN1;
  //追加発注予測数 =「販売計画」-「A+B」
  const OAYN = calcUtil.minus2(data.HPL, data.OFN);
  data.OAYN = OAYN ? OAYN : null;
  //発注残BL数 =（振替済BL数+発注BL数）- 出荷BL数合計 - 残使用BL数
  const ONUZ = (data.OFZN + data.ONU) - data.SNT - data.OZN;
  data.ONUZ = ONUZ > 0 ? ONUZ : null;

  // 進捗（計画達成率）＝出荷BL数合計 / 販売計画
  const TSTR = calcUtil.divide(data.SNT, data.HPL);
  data.TSTR = TSTR ? TSTR : 0;
  // 進捗（計画残）＝販売計画 - 出荷BL数合計
  const TSPN = calcUtil.minus(data.HPL, data.SNT);
  data.TSPN = TSPN ? TSPN : null;
  // 進捗（消化率）= 出荷BL数合計 / 出荷予定累計BL数
  const TSSR = calcUtil.divide(data.SNT, data.OANT);
  data.TSSR = TSSR ? TSSR : 0;
  // 進捗（特売残）= 出荷予定累計BL数 ‐ 出荷BL数合計
  const TSN = Math.max(calcUtil.minus(data.OANT, data.SNT), 0);
  data.TSN = TSN ? TSN : null;
  // 進捗（残日数）= 特売期間終了日 - 今日の日数
  const end = moment(data.OOS === '✓' ? data.OFS : data.KTO, 'YY/MM/DD').startOf('day');
  const now = moment().startOf('day');
  const TSZ = end.isValid() ? end.diff(now, 'day') : 0;
  data.TSZ = TSZ ? TSZ : 0;

  data.ARVM = data.ARVM !== undefined ? data.ARVM : '未受信';

  return data;
}

//チェック
const checkDatas = (datas:RowData[]): RowData[] => {
  console.log('store.checkDatas');
  return datas.map(data => checkData(data));
}

const checkData = (data:RowData): RowData => {
  const errorInfo:ErrorInfoData = {};
  const warnInfo:WarnInfoData = {};
  if(!data.errorInfo){
    data.errorInfo = {};
  }
  if(!data.warnInfo){
    data.warnInfo = {};
  }

  //TODO チェック項目
  // リリース在庫BL数 > 発注残BL数
  if (data.OARL > data.ONUZ) {
    errorInfo.OARL = '発注残BL数を超えるリリースになります';
  }

  // 摘要だけ入力があり、追加発注数かリリース項目のどちらにも入力なし
  if (data.OANTE && !data.OAN && !data.OARL) {
    errorInfo.OAN = '入力してください';
    errorInfo.OARL = '入力してください';
  }

  //追加出荷予定BL数 + 出荷予定累計 > 販売計画
  if ((data.OAN && data.OANT && data.HPL) && data.OAN + data.OANT > data.HPL) {
    warnInfo.OAN = '販売計画を超えます';
  }

  if (data.OANTE) {
    if (data.OANTE.length > 20) {
      errorInfo.OANTE = '20字まで入力可能です';
    } else if (!/^[a-zA-Z0-9!-/:-@¥[-`{-~｡-ﾟ ]*$/.test(data.OANTE)) {
      errorInfo.OANTE = '半角のみ入力可能です';
    }
  }

  if ((!data.ZTN || data.ZTN.trim().length <= 0)) {
    warnInfo.ZTN = '棚番がありません';
  }

  data.errorInfo = errorInfo;
  data.warnInfo = warnInfo;

  //編集状態をセットする
  data.edited = isEditedRowData(data);

  return data;
}


//no振りなおし
//no振りなおし
// const resetRowNo = (datas:RowInfo[]): RowData[] => {
//   //set No.
//   datas = datas.map((row, index) => {
//     return {
//       ...row,
//       no: "" + (index+1),
//     }
//   });
//   return datas;
// }

//配列データに変換
const convertRows = (datas:RowData[], ref:boolean): {
  rows:[][],
  // dataRowIndexes:number[],
  rowInfos: RowInfo[],
  mergeCells: {row: number, col: number, rowspan: number, colspan: number}[]
} => {
  console.log('store.convertRows');

  const mergeCells:{row: number, col: number, rowspan: number, colspan: number}[] = [];
  const mergeCols = [];
  //固定列すべてマージは崩れる
  colKeysNo.forEach((colKey) => {
    mergeCols.push(colFromKey(colKey, ref));
  });
  const colKeysData_fix = (ref ? colKeysData_ref_fix : colKeysData_edit_fix);
  const colKeysData_unfix = (ref ? colKeysData_ref_unfix : colKeysData_edit_unfix);
  colKeysData_fix.forEach((colKey) => {
    mergeCols.push(colFromKey(colKey, ref));
  });
  colKeysData_unfix.forEach((colKey) => {
    mergeCols.push(colFromKey(colKey, ref));
  });

  const rows = [];
  // let dataRowIndexes = [];
  const rowInfos:RowInfo[] = [];
  //set No.
  datas.forEach((data, index1) => {

    // const s = rows.length;
    const no:string = "" + (index1 + 1);

    const r = [];
    colKeysNo.forEach((colKey) => {
      switch (colKey) {
        case 'no':
          r.push(no); //No.
          break;
        default:
          r.push(null);
          break;
      }
    });
    colKeysData_fix.forEach((colKey) => {
      r.push(data[colKey]);
    });
    colKeysData_unfix.forEach((colKey) => {
      r.push(data[colKey]);
    });

    rows.push(r);
    // dataRowIndexes.push(index);
    rowInfos.push({
      no: no,
      row:rowInfos.length,
      data: data,
      dataIndex:index1,
    });

    // //マージ
    // let e = rows.length;
    // if(e - s > 1) {
    //   let rowspan = e - s;
    //   mergeCols.forEach(col => {
    //     mergeCells.push({row: s, col: col, rowspan: rowspan, colspan: 1});
    //   });
    // }
  });

  return {
    rows:rows,
    // dataRowIndexes:dataRowIndexes,
    rowInfos:rowInfos,
    mergeCells: mergeCells,
  };
}

//ソート
const doSort = (datas: RowData[], sorts: { key: string, asc: boolean }[]): RowData[] => {
  datas.sort((a, b) => {
    const objA = a;
    const objB = b;
    let comp = 0;

    for (const sort of sorts) {
      const colKey = sort.key;
      const asc = sort.asc;

      const va = objA ? objA[colKey] : null;
      const vb = objB ? objB[colKey] : null;

      //数値型
      if (typeof va === 'number' || typeof vb === 'number') {
        comp = compareUtil.compareNumber(va, vb, asc);
      }
      else if (colKey == 'TBN') {
        const ia = ['〇', '✖'].indexOf(va);
        const ib = ['〇', '✖'].indexOf(vb);
        comp = compareUtil.compareAny(ia, ib, asc);
      }
      else if (typeof va === 'string' || typeof vb === 'string') {
        const da = moment(va);
        const db = moment(vb);
        if (da.isValid() || db.isValid()) {
          comp = compareUtil.compareDate(da.toDate(), db.toDate(), asc);
        } else {
          comp = compareUtil.compareString(va, vb, asc);
        }
      }
      else if ((typeof va === 'object' && va instanceof Date) || (typeof vb === 'object' && vb instanceof Date)) {
        comp = compareUtil.compareDate(va, vb, asc);
      }
      else if (typeof va == 'boolean' || typeof vb == 'boolean') {
        comp = compareUtil.compareBoolean(va, vb, asc);
      }
      if (comp != 0) {
        break;
      }
    }
    if (comp === 0) {
      //それでもソートできない場合、SEQ順とする
      comp = compareUtil.compareNumber(objA['seq'], objB['seq'], true);
    }
    return comp;
  });

  return datas;
}

export const lastUpdateZaiko = (rowInfos: RowInfo[]): string => {
  const map = rowInfos.filter(rowInfo => rowInfo.data && rowInfo.data.DATEUP && rowInfo.data.TIMEUP)
    .map(rowInfo => moment(rowInfo.data.DATEUP.padStart(8, '0') + '' + rowInfo.data.TIMEUP.padStart(6, '0'), 'YYYYMMDDHHmmss'));
  if (map.length == 0) {
    return "";
  }

  const max: moment.Moment = map.reduce((a, b) => a.unix() >= b.unix() ? a : b);
  const now = moment(new Date());
  if (now.diff(max, 'day')) {
    return ' (' + max.format('M/D H') + '時時点)';
  }
  return ' (' + max.format('H') + '時時点)';
}