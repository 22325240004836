import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CodeName } from "@/store/common";
import * as arrayUtil from "@/util/arrayUtil";
import { ZaikoRealColRowModel, zaikoRealColRowModelDefault } from "@/components/zaikoreal/ZaikoRealTableModel";
import { centerItemAttributes, listSortOrders } from "@/store/zaikoreal/zaikoRealCommon";
import { PageProps } from "@/assets/pageprops/zaikoreal";

//強制表示列
export const mustVisibleColumnsKey:string[] = [
  "no", 
  "attribute1Value",
  "attribute2Value",
  "rowHeader", 

  "zaikoKigen1",
  "zaikoQty1",
  "zaikoKigen2",
  "zaikoQty2",
  "zaikoKigen3",
  "zaikoQty3",
  "zaikoKigen4",
  "zaikoQty4",
  "zaikoKigen5",
  "zaikoQty5",
];
//デフォルト表示列
export const defaultVisibleColumnsKey:string[] = [
  //以下に貼り付け
  // "attribute1Label",
  // "attribute2Label",
  "attribute2Value",

  "nohinbi",
  "nyukaDate1",
  "nyukaDate2",
  "nyukaDate3",
  "nyukaDate4",
  "nyukaDate5",
];
//強制表示行
export const mustVisibleRowsKey:string[] = [
];
//デフォルト表示行
export const defaultVisibleRowsKey:string[] = [
  'kigen',
  'total',
];

//デフォルト表示項目(4行必要)
export const defaultVisibleAttributes:string[][] = [
  [
    'itemCD', // 商品CD
    'itemNM', // 商品名
    'blIrisu', // BL入数
    'bestBefore', //賞味期間
  ],
  [
    'centerCD', // 倉庫
    'centerNM', // 倉庫
    'bumon', // 部門
    'tanaban', // 棚番
  ],
];

export const optionsNameList = [
  'optionMakers',
  'optionMakerCode',
  'optionBumons',
  'optionCenters',
  'optionCenterCode',
  'optionItemCode',
  'optionZaiko',
  'optionTeibanFlg',
];
export type ZaikoRealOptions = {
  optionMakers?: CodeName[],        //メーカー
  optionMakerCode?: string,         //メーカーCD
  optionBumons?: CodeName[],        //部門
  optionCenters?: CodeName[],       //倉庫
  optionCenterCode?: string,        //倉庫CD
  optionItemCode?: string,          //商品CD
  optionZaiko?: '' |'zaiko_tanaban'|'zaiko'|'tanaban'| string,   //棚番
  optionTeibanFlg?: "" | "定番のみ" | "定番外のみ",
};
export const displaySettingsNameList = [
  'visibleAttributes',
  'checkedRowKey',
  'checkedColKey',
];
export type DisplaySettings = {
  visibleAttributes?: string[][],    //表示属性
  checkedRowKey?: string[], //行項目選択
  checkedColKey?: string[],  //列項目選択
};

//Page State
export type ZaikoRealSaveState = {
  //検索条件
  optionMakers:CodeName[],    //メーカー
  optionMakerCode:string,            //メーカーCD
  optionBumons:CodeName[],     //部門
  optionCenters:CodeName[],   //倉庫
  optionCenterCode:string,            //倉庫CD
  optionItemCode:string,            //商品CD
  optionZaiko:''|'zaiko_tanaban'|'zaiko'|'tanaban'|string,   //棚番
  optionTeibanFlg: "" | "定番のみ" | "定番外のみ",

  favoriteOptions: { title: CodeName, params: ZaikoRealOptions }[] | null,
  favoriteDisplaySettings: { title: CodeName, params: DisplaySettings }[] | null,

  listSortOrder:CodeName,   //並び順
  listSortOrderDesc:boolean,   //昇順

  hiddenColumns: number[],
  hiddenColumnsKey: string[],
  visibleColumnsKey: string[],
  visibleRowsKey: string[],

  visibleAttributes:string[][],    //表示属性

  visibleZaikoKbnAll: boolean,
  visibleZaikoKbn1: boolean,
  visibleZaikoKbn2: boolean,
  visibleZaikoKbn3: boolean,
  visibleZaikoKbn4: boolean,
  visibleZaikoKbn5: boolean,

};

export const initialZaikoRealState: ZaikoRealSaveState = {
  optionMakers:[],    //メーカー
  optionMakerCode:'',            //メーカーCD
  optionBumons:[],     //部門
  optionCenters:[],   //倉庫
  optionCenterCode:'',            //倉庫CD
  optionItemCode:'',            //商品CD
  optionZaiko:'zaiko',   //棚番
  optionTeibanFlg: "定番のみ",

  favoriteOptions: [],
  favoriteDisplaySettings: [],

  listSortOrder: listSortOrders.find(listSortOrder => listSortOrder.code == 'item_itemCD'),   //並び順
  listSortOrderDesc: false,   //並び順

  hiddenColumns: [],
  hiddenColumnsKey: [],
  visibleColumnsKey: defaultVisibleColumnsKey,
  visibleRowsKey: defaultVisibleRowsKey,

  visibleAttributes: defaultVisibleAttributes,    //表示属性

  visibleZaikoKbnAll: true,
  visibleZaikoKbn1: true,
  visibleZaikoKbn2: true,
  visibleZaikoKbn3: true,
  visibleZaikoKbn4: true,
  visibleZaikoKbn5: true,

};

//Page Slice
export type ZaikoRealSaveReducer = {
  initOnDidMount: (state:ZaikoRealSaveState, action: PayloadAction<PageProps>) => void,
  resetOnWillUnmount: (state:ZaikoRealSaveState) => void,
  clearOption: (state:ZaikoRealSaveState, action: PayloadAction<{}>) => void,
  setOptionMakers: (state:ZaikoRealSaveState, action: PayloadAction<CodeName[]>) => void,
  setOptionMakerCode: (state:ZaikoRealSaveState, action: PayloadAction<string>) => void,
  setOptionBumons: (state:ZaikoRealSaveState, action: PayloadAction<CodeName[]>) => void,
  setOptionCenters: (state:ZaikoRealSaveState, action: PayloadAction<CodeName[]>) => void,
  setOptionCenterCode: (state:ZaikoRealSaveState, action: PayloadAction<string>) => void,
  setOptionItemCode: (state:ZaikoRealSaveState, action: PayloadAction<string>) => void,
  setOptionZaiko: (state:ZaikoRealSaveState, action: PayloadAction<string>) => void,
  setOptionTeibanFlg: (state: ZaikoRealSaveState, action: PayloadAction<"" | "定番のみ" | "定番外のみ">) => void,
  setFavoriteOptions: (state:ZaikoRealSaveState, action: PayloadAction<{ title: CodeName, params: ZaikoRealOptions }[]>) => void,
  setFavoriteDisplaySettings: (state:ZaikoRealSaveState, action: PayloadAction<{ title: CodeName, params: DisplaySettings }[]>) => void,
  setListSortOrder: (state:ZaikoRealSaveState, action: PayloadAction<CodeName>) => void,
  setListSortOrderDesc: (state:ZaikoRealSaveState, action: PayloadAction<boolean>) => void,
  hideColumnKeysChange: (state:ZaikoRealSaveState, action: PayloadAction<string[]>) => void,
  visibleRowKeysChange: (state:ZaikoRealSaveState, action: PayloadAction<string[]>) => void,
  visibleAttributesChange: (state:ZaikoRealSaveState, action: PayloadAction<string[][]>) => void,
  setVisibleZaikoKbnAll: (state:ZaikoRealSaveState, action: PayloadAction<boolean>) => void,
  setVisibleZaikoKbn1: (state:ZaikoRealSaveState, action: PayloadAction<boolean>) => void,
  setVisibleZaikoKbn2: (state:ZaikoRealSaveState, action: PayloadAction<boolean>) => void,
  setVisibleZaikoKbn3: (state:ZaikoRealSaveState, action: PayloadAction<boolean>) => void,
  setVisibleZaikoKbn4: (state:ZaikoRealSaveState, action: PayloadAction<boolean>) => void,
  setVisibleZaikoKbn5: (state:ZaikoRealSaveState, action: PayloadAction<boolean>) => void,
}

const createReducerContent = (name:string, colRowModel:ZaikoRealColRowModel, initialState: ZaikoRealSaveState):ZaikoRealSaveReducer => {return {
  //componentDidMount
  initOnDidMount(state:ZaikoRealSaveState, action: PayloadAction<PageProps>) {
    const props:PageProps = action.payload;

    //自動検索する
    const autoRetrieve =
      // (props.bumons && props.bumons.length > 0) ||
      (props.centers && props.centers.length > 0) ||
      (props.makers && props.makers.length > 0) ||
      (props.items && props.items.length > 0)
    ;

    if(autoRetrieve) {
      state.optionBumons = initialZaikoRealState.optionBumons;

      state.optionCenters = initialZaikoRealState.optionCenters;
      if(props.centers) {
        state.optionCenterCode = props.centers.join('\n');
      }
      else {
        state.optionCenterCode = initialZaikoRealState.optionCenterCode;
      }

      state.optionMakers = initialZaikoRealState.optionMakers;
      if(props.makers) {
        state.optionMakerCode = props.makers.join('\n');
      }
      else {
        state.optionMakerCode = initialZaikoRealState.optionMakerCode;
      }

      if(props.items) {
        state.optionItemCode = props.items.join('\n');
      }
      else {
        state.optionItemCode = initialZaikoRealState.optionItemCode;
      }

      if(props.zaiko) {
        state.optionZaiko = props.zaiko;
      }
      else {
        state.optionZaiko = initialZaikoRealState.optionZaiko;
      }
      if(props.teiban) {
        state.optionTeibanFlg = props.teiban;
      }
      else {
        state.optionTeibanFlg = initialZaikoRealState.optionTeibanFlg;
      }
      if (props.zaikoKbn) {
        const zaikoKbn = props.zaikoKbn.split(',');
        state.visibleZaikoKbn1 = zaikoKbn.indexOf('zaiko1') !== -1;
        state.visibleZaikoKbn2 = zaikoKbn.indexOf('zaiko2') !== -1;
        state.visibleZaikoKbn3 = zaikoKbn.indexOf('zaiko3') !== -1;
        state.visibleZaikoKbn4 = zaikoKbn.indexOf('zaiko4') !== -1;
        state.visibleZaikoKbn5 = zaikoKbn.indexOf('zaiko5') !== -1;
        state.visibleZaikoKbnAll =
          state.visibleZaikoKbn1 &&
          state.visibleZaikoKbn2 &&
          state.visibleZaikoKbn3 &&
          state.visibleZaikoKbn4 &&
          state.visibleZaikoKbn5;
      }
    }
    else {
      if(state.optionMakers === undefined) {
        state.optionMakers = initialZaikoRealState.optionMakers;
      }
      if(state.optionMakerCode === undefined) {
        state.optionMakerCode = initialZaikoRealState.optionMakerCode;
      }
      if(state.optionBumons === undefined) {
        state.optionBumons = initialZaikoRealState.optionBumons;
      }
      if(state.optionCenters === undefined) {
        state.optionCenters = initialZaikoRealState.optionCenters;
      }
      if(state.optionCenterCode === undefined) {
        state.optionCenterCode = initialZaikoRealState.optionCenterCode;
      }
      if(state.optionItemCode === undefined) {
        state.optionItemCode = initialZaikoRealState.optionItemCode;
      }
      if(state.optionZaiko === undefined) {
        state.optionZaiko = initialZaikoRealState.optionZaiko;
      }
      if(state.optionTeibanFlg === undefined) {
        state.optionTeibanFlg = initialZaikoRealState.optionTeibanFlg;
      }
    }

    if(state.favoriteOptions === undefined) {
      state.favoriteOptions = initialZaikoRealState.favoriteOptions;
    }
    if(state.favoriteDisplaySettings === undefined) {
      state.favoriteDisplaySettings = initialZaikoRealState.favoriteDisplaySettings;
    }
    if(!state.listSortOrder) {
      state.listSortOrder = initialState.listSortOrder;
    }
    if(state.listSortOrderDesc === undefined) {
      state.listSortOrderDesc = initialState.listSortOrderDesc;
    }

    //初期表示列0配列は、常に全項目表示とする
    if(initialState.visibleColumnsKey.length == 0) {
      state.visibleColumnsKey = colRowModel.colKeys;
    }
    else if(!state.visibleColumnsKey) {
      state.visibleColumnsKey = initialState.visibleColumnsKey;
    }
    //強制選択列
    state.visibleColumnsKey = arrayUtil.union(arrayUtil.and(colRowModel.colKeys, mustVisibleColumnsKey), state.visibleColumnsKey);
    //非表示列
    state.hiddenColumnsKey = arrayUtil.not(colRowModel.colKeys, state.visibleColumnsKey);

    //行項目
    if (!state.visibleRowsKey || state.visibleRowsKey.length == 0) {
      state.visibleRowsKey = (initialState.visibleRowsKey.length == 0) ? colRowModel.rowKeys : initialState.visibleRowsKey;
    }
    //強制選択行
    state.visibleRowsKey = arrayUtil.union(arrayUtil.and(colRowModel.rowKeys, mustVisibleRowsKey), state.visibleRowsKey);

    //表示項目
    if(!state.visibleAttributes || state.visibleAttributes.length < initialState.visibleAttributes.length) {
      state.visibleAttributes = initialState.visibleAttributes;
    }
    else {
      //6項目必要
      let visibleAttributes = [...state.visibleAttributes];
      let init = false;
      initialState.visibleAttributes.forEach((attrs, index) => {
        if(visibleAttributes[index].length != attrs.length) {
          init = true;
          visibleAttributes[index] = attrs;
        }
      });
      if(init) {
        state.visibleAttributes = visibleAttributes;
      }
    }

    //在庫区分表示
    if(!(state.visibleZaikoKbn1 || state.visibleZaikoKbn2 || state.visibleZaikoKbn3 || state.visibleZaikoKbn4 || state.visibleZaikoKbn5)) {
      state.visibleZaikoKbnAll = true;
      state.visibleZaikoKbn1 = true;
      state.visibleZaikoKbn2 = true;
      state.visibleZaikoKbn3 = true;
      state.visibleZaikoKbn4 = true;
      state.visibleZaikoKbn5 = true;
    }
  },
  //componentWillUnmount
  resetOnWillUnmount(state:ZaikoRealSaveState) {
    //初期表示列0配列は、常に全項目表示とするため、記憶しない
    if(initialState.visibleColumnsKey.length == 0) {
      state.visibleColumnsKey = [];
    }
  },
  clearOption(state:ZaikoRealSaveState, action: PayloadAction<{}>) {
    state.optionMakers = initialZaikoRealState.optionMakers;
    state.optionMakerCode = initialZaikoRealState.optionMakerCode;
    state.optionBumons = initialZaikoRealState.optionBumons;
    state.optionCenters = initialZaikoRealState.optionCenters;
    state.optionCenterCode = initialZaikoRealState.optionCenterCode;
    state.optionItemCode = initialZaikoRealState.optionItemCode;
    state.optionZaiko = initialZaikoRealState.optionZaiko;
    state.optionTeibanFlg = initialZaikoRealState.optionTeibanFlg;
  },
  setOptionMakers (state:ZaikoRealSaveState, action: PayloadAction<CodeName[]>) {
    state.optionMakers = action.payload;
  },
  setOptionMakerCode (state:ZaikoRealSaveState, action: PayloadAction<string>) {
    state.optionMakerCode = action.payload;
  },
  setOptionBumons (state:ZaikoRealSaveState, action: PayloadAction<CodeName[]>) {
    state.optionBumons = action.payload;
  },
  setOptionItemCode (state:ZaikoRealSaveState, action: PayloadAction<string>) {
    state.optionItemCode = action.payload;
  },
  setFavoriteOptions(state:ZaikoRealSaveState, action: PayloadAction<{ title: CodeName, params: ZaikoRealOptions }[]>) {
    state.favoriteOptions = action.payload;
  },
  setFavoriteDisplaySettings(state:ZaikoRealSaveState, action: PayloadAction<{ title: CodeName, params: DisplaySettings }[]>) {
    state.favoriteDisplaySettings = action.payload;
  },
  setOptionCenters (state:ZaikoRealSaveState, action: PayloadAction<CodeName[]>) {
    state.optionCenters = action.payload;
  },
  setOptionCenterCode (state:ZaikoRealSaveState, action: PayloadAction<string>) {
    state.optionCenterCode = action.payload;
  },
  setOptionZaiko(state:ZaikoRealSaveState, action: PayloadAction<string>) {
    state.optionZaiko = action.payload;
  },
  setOptionTeibanFlg(state: ZaikoRealSaveState, action: PayloadAction<"" | "定番のみ" | "定番外のみ">) {
    state.optionTeibanFlg = action.payload;
  },
  setListSortOrder (state:ZaikoRealSaveState, action: PayloadAction<CodeName>) {
    state.listSortOrder = action.payload;
  },
  setListSortOrderDesc (state:ZaikoRealSaveState, action: PayloadAction<boolean>) {
    state.listSortOrderDesc = action.payload;
  },
  hideColumnKeysChange(state:ZaikoRealSaveState, action: PayloadAction<string[]>){
    console.log('store.hideColumnKeysChange');
    //store更新
    state.hiddenColumns = colRowModel.colFromKeys(action.payload);
    state.hiddenColumnsKey = action.payload;
    state.visibleColumnsKey = arrayUtil.not(colRowModel.colKeys, action.payload);
  },
  visibleRowKeysChange(state:ZaikoRealSaveState, action: PayloadAction<string[]>){
    console.log('store.visibleRowKeysChange');
    //store更新
    state.visibleRowsKey = action.payload;
  },
  visibleAttributesChange(state:ZaikoRealSaveState, action: PayloadAction<string[][]>){
    console.log('store.visibleAttributesChange');
    //store更新
    state.visibleAttributes = action.payload;
  },
  setVisibleZaikoKbnAll(state:ZaikoRealSaveState, action: PayloadAction<boolean>) {
    let checkAll = true;
    //全選択 or 全解除は、全選択とする
    state.visibleZaikoKbn1 = true;
    state.visibleZaikoKbn2 = true;
    state.visibleZaikoKbn3 = true;
    state.visibleZaikoKbn4 = true;
    state.visibleZaikoKbn5 = true;
    state.visibleZaikoKbnAll = true;
  },
  setVisibleZaikoKbn1(state:ZaikoRealSaveState, action: PayloadAction<boolean>) {
    let checkAll = 
      action.payload == state.visibleZaikoKbn2 && 
      action.payload == state.visibleZaikoKbn3 && 
      action.payload == state.visibleZaikoKbn4 && 
      action.payload == state.visibleZaikoKbn5;
    if(checkAll) {
      //全選択 or 全解除は、全選択とする
      state.visibleZaikoKbn1 = true;
      state.visibleZaikoKbn2 = true;
      state.visibleZaikoKbn3 = true;
      state.visibleZaikoKbn4 = true;
      state.visibleZaikoKbn5 = true;
      state.visibleZaikoKbnAll = true;
    }
    else {
      state.visibleZaikoKbn1 = action.payload;
      state.visibleZaikoKbnAll = state.visibleZaikoKbn1 && state.visibleZaikoKbn2 && state.visibleZaikoKbn3 && state.visibleZaikoKbn4 && state.visibleZaikoKbn5;
    }

  },
  setVisibleZaikoKbn2(state:ZaikoRealSaveState, action: PayloadAction<boolean>) {
    let checkAll = 
      action.payload == state.visibleZaikoKbn1 && 
      action.payload == state.visibleZaikoKbn3 && 
      action.payload == state.visibleZaikoKbn4 && 
      action.payload == state.visibleZaikoKbn5;
    if(checkAll) {
      //全選択 or 全解除は、全選択とする
      state.visibleZaikoKbn1 = true;
      state.visibleZaikoKbn2 = true;
      state.visibleZaikoKbn3 = true;
      state.visibleZaikoKbn4 = true;
      state.visibleZaikoKbn5 = true;
      state.visibleZaikoKbnAll = true;
    }
    else {
      state.visibleZaikoKbn2 = action.payload;
      state.visibleZaikoKbnAll = state.visibleZaikoKbn1 && state.visibleZaikoKbn2 && state.visibleZaikoKbn3 && state.visibleZaikoKbn4 && state.visibleZaikoKbn5;
    }
  },
  setVisibleZaikoKbn3(state:ZaikoRealSaveState, action: PayloadAction<boolean>) {
    let checkAll = 
      action.payload == state.visibleZaikoKbn1 && 
      action.payload == state.visibleZaikoKbn2 && 
      action.payload == state.visibleZaikoKbn4 && 
      action.payload == state.visibleZaikoKbn5;
    if(checkAll) {
      //全選択 or 全解除は、全選択とする
      state.visibleZaikoKbn1 = true;
      state.visibleZaikoKbn2 = true;
      state.visibleZaikoKbn3 = true;
      state.visibleZaikoKbn4 = true;
      state.visibleZaikoKbn5 = true;
      state.visibleZaikoKbnAll = true;
    }
    else {
      state.visibleZaikoKbn3 = action.payload;
      state.visibleZaikoKbnAll = state.visibleZaikoKbn1 && state.visibleZaikoKbn2 && state.visibleZaikoKbn3 && state.visibleZaikoKbn4 && state.visibleZaikoKbn5;
    }
  },
  setVisibleZaikoKbn4(state:ZaikoRealSaveState, action: PayloadAction<boolean>) {
    let checkAll = 
      action.payload == state.visibleZaikoKbn1 && 
      action.payload == state.visibleZaikoKbn2 && 
      action.payload == state.visibleZaikoKbn3 && 
      action.payload == state.visibleZaikoKbn5;
    if(checkAll) {
      //全選択 or 全解除は、全選択とする
      state.visibleZaikoKbn1 = true;
      state.visibleZaikoKbn2 = true;
      state.visibleZaikoKbn3 = true;
      state.visibleZaikoKbn4 = true;
      state.visibleZaikoKbn5 = true;
      state.visibleZaikoKbnAll = true;
    }
    else {
      state.visibleZaikoKbn4 = action.payload;
      state.visibleZaikoKbnAll = state.visibleZaikoKbn1 && state.visibleZaikoKbn2 && state.visibleZaikoKbn3 && state.visibleZaikoKbn4 && state.visibleZaikoKbn5;
    }
  },
  setVisibleZaikoKbn5(state:ZaikoRealSaveState, action: PayloadAction<boolean>) {
    let checkAll = 
      action.payload == state.visibleZaikoKbn1 && 
      action.payload == state.visibleZaikoKbn2 && 
      action.payload == state.visibleZaikoKbn3 && 
      action.payload == state.visibleZaikoKbn4;
    if(checkAll) {
      //全選択 or 全解除は、全選択とする
      state.visibleZaikoKbn1 = true;
      state.visibleZaikoKbn2 = true;
      state.visibleZaikoKbn3 = true;
      state.visibleZaikoKbn4 = true;
      state.visibleZaikoKbn5 = true;
      state.visibleZaikoKbnAll = true;
    }
    else {
      state.visibleZaikoKbn5 = action.payload;
      state.visibleZaikoKbnAll = state.visibleZaikoKbn1 && state.visibleZaikoKbn2 && state.visibleZaikoKbn3 && state.visibleZaikoKbn4 && state.visibleZaikoKbn5;
    }
  },
}};

const createSliceContent = (name:string, colRowModel:ZaikoRealColRowModel, initialState: ZaikoRealSaveState) => createSlice({
  name: name,
  initialState: initialState,
  reducers: createReducerContent(name, colRowModel, initialState),
});

//Page Slice Export
//zaikoRealSaveSlice
export const zaikoRealSaveSlice = createSliceContent("zaikoRealSave", zaikoRealColRowModelDefault, initialZaikoRealState);
