import { CodeName } from "@/store/common";

//倉庫・商品
export type CenterItem = {
  itemCD?: string | null, //商品CD
  itemNM?: string | null, //商品名
  janCD?: string | null,   //JAN
  makerCD?: string | null, //メーカー
  makerNM?: string | null, //メーカー
  categoryCD?: string | null, //ｶﾃｺﾞﾘ
  categoryNM?: string | null, //ｶﾃｺﾞﾘ
  pbKbnCD?: string | null,
  pbKbnNM?: string | null,
  blIrisu?: number | null,  //容量1(BL入数) 
  csIrisu?: number | null,  //容量2(CS入数)
  capacity?: string | null, //内容量
  bestBefore?: number | null, //賞味期間
  teika?: number | null, //定価
  tatene?: number | null, //建値

  bumonCD?: string, //--部門
  bumonNM?: string | null, //部門
  centerCD?: string | null, //倉庫(4桁コード)
  centerNM?: string | null, //倉庫

  teibanNum?: number,
  teibanNMs?: string,
  tanaban?: string | null, //棚番
  zaikoQty1?: number | null, //在庫数1.一般
  zaikoQty2?: number | null, //在庫数2.特売
  zaikoQty3?: number | null, //在庫数3.予約
  zaikoQty4?: number | null, //在庫数4.出切
  zaikoQty5?: number | null, //在庫数5.返品

  nohinbi?: string, //最終納品日
}
//出荷情報
export type SyukkaData = {
  itemCD?: string | null, //商品CD
  centerCD?: string | null, //倉庫

	nohinbi?: string, //最終納品日
  tokuisakiNum?: number,
  tokuisakiNMs?: string,
}
//賞味期限情報
export type KigenData = {
  itemCD?: string | null, //商品CD
  centerCD?: string | null, //倉庫

	zaikoKbn?: '1'|'2'|'3'|'4'|'5'|string, //1.一般', '2.特売', '3.予約', '4.出切', '5.返品
  nyukaDate?: string | null,
  zaikoKigen?: string | null, //賞味期限
  zaikoKigenInvalid?: boolean, //賞味期限無効 99/99/99
  zaikoKigenOver?: boolean, //賞味期限オーバー
  zaikoKigenOver2_3?: boolean, //2/3許容期限オーバー
  zaikoQty?: number | null, //在庫BL数
}

export const centerItemAttributes:CodeName[][] = [
  [
    new CodeName({group_code:"", group_name:"", code: "", name: "(指定なし)", ryaku: "", }),
    new CodeName({group_code:"item", group_name:"商品属性", code: "itemCD", name: "商品CD", ryaku: "商品CD", }),
    new CodeName({group_code:"item", group_name:"商品属性", code: "itemNM", name: "商品名", ryaku: "商品名", }),
    new CodeName({group_code:"item", group_name:"商品属性", code: "janCD", name: "JAN", ryaku: "JAN", }),
    new CodeName({group_code:"item", group_name:"商品属性", code: "maker", name: "メーカー", ryaku: "メーカー", }),
    new CodeName({group_code:"item", group_name:"商品属性", code: "category", name: "商品カテゴリ", ryaku: "カテゴリ", }),
    new CodeName({group_code:"item", group_name:"商品属性", code: "pb", name: "PB区分", ryaku: "PB区分", }),
    new CodeName({group_code:"item", group_name:"商品属性", code: "blIrisu", name: "BL入数", ryaku: "BL入数", }),
    new CodeName({group_code:"item", group_name:"商品属性", code: "csIrisu", name: "CS入数", ryaku: "CS入数", }),
    new CodeName({group_code:"item", group_name:"商品属性", code: "capacity", name: "内容量", ryaku: "内容量", }),
    new CodeName({group_code:"item", group_name:"商品属性", code: "bestBefore", name: "賞味期間", ryaku: "賞味期間", }),
    new CodeName({group_code:"item", group_name:"商品属性", code: "teika", name: "定価", ryaku: "定価", }),
    new CodeName({group_code:"item", group_name:"商品属性", code: "tatene", name: "建値", ryaku: "建値", }),

    new CodeName({group_code:"zaiko", group_name:"在庫", code: "tanaban", name: "棚番", ryaku: "棚番", }),
  ],
  [
    new CodeName({group_code:"", group_name:"", code: "", name: "(指定なし)", ryaku: "", }),
    new CodeName({group_code:"center", group_name:"センター属性", code: "bumon", name: "部門", ryaku: "部門", }),
    new CodeName({group_code:"center", group_name:"センター属性", code: "bumonCD", name: "部門CD", ryaku: "部門CD", }),
    new CodeName({group_code:"center", group_name:"センター属性", code: "bumonNM", name: "部門名", ryaku: "部門名", }),
    new CodeName({group_code:"center", group_name:"センター属性", code: "center", name: "倉庫", ryaku: "倉庫", }),
    new CodeName({group_code:"center", group_name:"センター属性", code: "centerCD", name: "倉庫CD", ryaku: "倉庫CD", }),
    new CodeName({group_code:"center", group_name:"センター属性", code: "centerNM", name: "倉庫名", ryaku: "倉庫名", }),

    new CodeName({group_code:"zaiko", group_name:"在庫", code: "tanaban", name: "棚番", ryaku: "棚番", }),
  ]
];

//並び順
export const listSortOrders:CodeName[] = [
  new CodeName({group_code:"item_center", group_name:"【商品 → センター】", code: "item_itemCD", name: "商品コード → 倉庫コード順", option1: "itemCD", option2:"itemCD", option3: "centerCD", option4: ""}),
  new CodeName({group_code:"item_center", group_name:"【商品 → センター】", code: "item_itemNM", name: "商品名 → 倉庫コード順", option1: "itemNM", option2:"itemCD", option3: "centerCD", option4: ""}),
  new CodeName({group_code:"item_center", group_name:"【商品 → センター】", code: "item_janCD", name: "JANコード → 倉庫コード順", option1: "janCD", option2:"itemCD", option3: "centerCD", option4: ""}),
  new CodeName({group_code:"item_center", group_name:"【商品 → センター】", code: "item_maker", name: "メーカー → 倉庫コード順", option1: "makerCD", option2:"itemCD", option3: "centerCD", option4: ""}),
  new CodeName({group_code:"item_center", group_name:"【商品 → センター】", code: "item_category", name: "商品カテゴリ → 倉庫コード順", option1: "categoryCD", option2:"itemCD", option3: "centerCD", option4: ""}),
  new CodeName({group_code:"item_center", group_name:"【商品 → センター】", code: "item_pb", name: "PB区分 → 倉庫コード順", option1: "pbKbnCD", option2:"itemCD", option3: "centerCD", option4: ""}),
  new CodeName({group_code:"item_center", group_name:"【商品 → センター】", code: "item_bestBefore", name: "賞味期間 → 倉庫コード順", option1: "bestBefore", option2:"itemCD", option3: "centerCD", option4: ""}),
  new CodeName({group_code:"item_center", group_name:"【商品 → センター】", code: "item_teika", name: "定価 → 倉庫コード順", option1: "teika", option2:"itemCD", option3: "centerCD", option4: ""}),
  new CodeName({group_code:"item_center", group_name:"【商品 → センター】", code: "item_tatene", name: "建値 → 倉庫コード順", option1: "tatene", option2:"itemCD", option3: "centerCD", option4: ""}),

  new CodeName({group_code:"center_item", group_name:"【センター → 商品】", code: "center_centerCD_item", name: "倉庫コード → 商品コード順", option1: "centerCD", option2:"centerCD", option3: "itemCD", option4: ""}),
  new CodeName({group_code:"center_item", group_name:"【センター → 商品】", code: "center_centerNM_item", name: "倉庫名 → 商品コード順", option1: "centerNM", option2:"centerCD", option3: "itemCD", option4: ""}),

  new CodeName({group_code:"center_tanaban", group_name:"【センター → 棚番】", code: "center_centerCD_tanaban", name: "倉庫コード → 棚番順", option1: "centerCD", option2:"centerCD", option3: "tanaban", option4: "itemCD"}),
  new CodeName({group_code:"center_tanaban", group_name:"【センター → 棚番】", code: "center_centerNM_tanaban", name: "倉庫名 → 棚番順", option1: "centerNM", option2:"centerCD", option3: "tanaban", option4: "itemCD"}),
];

export const optionAll = new CodeName({ code: '', name: '全て' });
