import { createSlice, PayloadAction } from "@reduxjs/toolkit";

 import {colKeys} from "@/components/itemlist/ItemListZaikoModel";

import * as calcUtil from "@/util/calcUtil";
import * as compareUtil from "@/util/compareUtil";
import moment from 'moment';

export interface RowDataItemZaiko {
  TP?: "total" | "item",

  BUCD?: string,
  KACD?: string,
  KKAM?: string,
  CNR?: string,

  itblir?: number | null

  // 1.一般：ZIMDWH.ZIRQTY
  RQTY?: number | null, RQTY_PS?: number | null, RAMT?: number | null,
  // 2.特売：ZIMDWH.ZITQTY
  TQTY?: number | null, TQTY_PS?: number | null, TAMT?: number | null,
  // 3.予約：ZIMDWH.ZIYQTY
  YQTY?: number | null, YQTY_PS?: number | null, YAMT?: number | null,
  // 4.出切：ZIMDWH.ZIFQTY
  FQTY?: number | null, FQTY_PS?: number | null, FAMT?: number | null,
  // 5.返品：ZIMDWH.ZIAZKE
  AZKE?: number | null, AZKE_PS?: number | null, AAMT?: number | null,
  // 在庫数
  ZQTY?: number | null, ZQTY_PS?: number | null, ZAMT?: number | null,

  SQTY1?: number | null, SQTY1_PS?: number | null, SKIN1?: number | null,
  SQTY2?: number | null, SQTY2_PS?: number | null, SKIN2?: number | null,
  SQTY3?: number | null, SQTY3_PS?: number | null, SKIN3?: number | null,
  SQTY?: number | null, SQTY_PS?: number | null, SKIN?: number | null,
  SQTYR?: number | null, SKINR?: number | null,

  HQTY1?: number | null, HQTY1_PS?: number | null, HKIN1?: number | null,
  HQTY2?: number | null, HQTY2_PS?: number | null, HKIN2?: number | null,
  HQTY3?: number | null, HQTY3_PS?: number | null, HKIN3?: number | null,
  HQTY4?: number | null, HQTY4_PS?: number | null, HKIN4?: number | null,
  HQTY?: number | null, HQTY_PS?: number | null, HKIN?: number | null,
  HQTYR?: number | null, HKINR?: number | null,

  DATEUP?: string | null,
  TIMEUP?: string | null,
}
export interface RowDataItemZaikoINM {
  INM?: string,
}

//Page State
export type ItemListZaikoTmpState = {
  datas: RowDataItemZaiko[],
  rows: [][],
  mergeCells: [],
  selectionRowStart: number,
  selectionRowEnd: number,

  progress: Record<string, unknown>,
  errorMessage: string | null,
};

export const initialState: ItemListZaikoTmpState = {
  datas: [],
  rows: [],
  mergeCells: null,
  selectionRowStart: -1,
  selectionRowEnd: -1,

  progress: {},
  errorMessage: null,
};

//Page Slice
const createSliceContent = (name:string) => createSlice({
  name: name,
  initialState,
  reducers: {
    setDatas(state:ItemListZaikoTmpState, action: PayloadAction<{datas:RowDataItemZaiko[], sort:{key:string, asc:boolean}}>) {
      const sort = action.payload.sort;

      //計算
      let datas = action.payload.datas;

      datas = parseData(datas);

      datas = calcTotal(datas); //合計行の作成
      datas = calcDatas(datas, "all"); //計算項目の計算

      let key = sort.key;
      datas = doSort(datas, key, sort.asc);  //ソート

      Object.assign(state, {
        datas: datas,
        rows: convertRows(datas),
      });
    },
    execSort(state:ItemListZaikoTmpState, action: PayloadAction<{sort:{key:string, asc:boolean}}>) {
      const sort = action.payload.sort;

      let key = sort.key;
      const asc = sort.asc;
      console.log('zaiko execSort', key);

      let datas = [...state.datas];
      datas = doSort(datas, key, asc);

      Object.assign(state, {
        datas: datas,
        //テーブル用のデータに変換
        rows: convertRows(datas),
      });
    },
    putProgress(state:ItemListZaikoTmpState, action: PayloadAction<string>) {
      const key = action.payload;
      const progressNew = {...state.progress};
      progressNew[key] = true;
      state.progress = progressNew;
    },
    removeProgress(state:ItemListZaikoTmpState, action: PayloadAction<string>) {
      const key = action.payload;
      const progressNew = {};
      Object.keys(state.progress).forEach(k => {
        if(key != k) {
          progressNew[k] = true;
        }
      })
      state.progress = progressNew;
    },
    setErrorMessage(state:ItemListZaikoTmpState, action: PayloadAction<string>) {
      state.errorMessage = action.payload;
    },
  },
});

//数値のパース(数値が文字列で返ってくる)
const parseData = (datas:RowDataItemZaiko[]): RowDataItemZaiko[] => {
  //set No.
  datas.forEach((data) => {
    if (typeof data.itblir === 'string') data.itblir = parseInt(data.itblir);

    if (typeof data.RQTY_PS === 'string') data.RQTY_PS = parseInt(data.RQTY_PS);
    if (typeof data.RAMT === 'string') data.RAMT = parseInt(data.RAMT);
    if (typeof data.TQTY_PS === 'string') data.TQTY_PS = parseInt(data.TQTY_PS);
    if (typeof data.TAMT === 'string') data.TAMT = parseInt(data.TAMT);
    if (typeof data.YQTY_PS === 'string') data.YQTY_PS = parseInt(data.YQTY_PS);
    if (typeof data.YAMT === 'string') data.YAMT = parseInt(data.YAMT);
    if (typeof data.FQTY_PS === 'string') data.FQTY_PS = parseInt(data.FQTY_PS);
    if (typeof data.FAMT === 'string') data.FAMT = parseInt(data.FAMT);
    if (typeof data.AZKE_PS === 'string') data.AZKE_PS = parseInt(data.AZKE_PS);
    if (typeof data.AAMT === 'string') data.AAMT = parseInt(data.AAMT);

    if (typeof data.SQTY1_PS === 'string') data.SQTY1_PS = parseInt(data.SQTY1_PS);
    if (typeof data.SKIN1 === 'string') data.SKIN1 = parseInt(data.SKIN1);
    if (typeof data.SQTY2_PS === 'string') data.SQTY2_PS = parseInt(data.SQTY2_PS);
    if (typeof data.SKIN2 === 'string') data.SKIN2 = parseInt(data.SKIN2);
    if (typeof data.SQTY3_PS === 'string') data.SQTY3_PS = parseInt(data.SQTY3_PS);
    if (typeof data.SKIN3 === 'string') data.SKIN3 = parseInt(data.SKIN3);
    if (typeof data.SQTY_PS === 'string') data.SQTY_PS = parseInt(data.SQTY_PS);
    if (typeof data.SKIN === 'string') data.SKIN = parseInt(data.SKIN);

    if (typeof data.HQTY1_PS === 'string') data.HQTY1_PS = parseInt(data.HQTY1_PS);
    if (typeof data.HKIN1 === 'string') data.HKIN1 = parseInt(data.HKIN1);
    if (typeof data.HQTY2_PS === 'string') data.HQTY2_PS = parseInt(data.HQTY2_PS);
    if (typeof data.HKIN2 === 'string') data.HKIN2 = parseInt(data.HKIN2);
    if (typeof data.HQTY3_PS === 'string') data.HQTY3_PS = parseInt(data.HQTY3_PS);
    if (typeof data.HKIN3 === 'string') data.HKIN3 = parseInt(data.HKIN3);
    if (typeof data.HQTY4_PS === 'string') data.HQTY4_PS = parseInt(data.HQTY4_PS);
    if (typeof data.HKIN4 === 'string') data.HKIN4 = parseInt(data.HKIN4);
    if (typeof data.HQTY_PS === 'string') data.HQTY_PS = parseInt(data.HQTY_PS);
    if (typeof data.HKIN === 'string') data.HKIN = parseInt(data.HKIN);
  });
  return datas;
}
//合計行作成
const calcTotal = (datas:RowDataItemZaiko[]): RowDataItemZaiko[] => {
  const total:RowDataItemZaiko = {
    TP: "total",
    KKAM:"合計",
  };

  //set No.
  datas.forEach((data) => {
    if(data.TP == "item") {
      calcTotalSub(total, data);
    }
  });

  if(datas.length > 0 && datas[0].TP == "total") {
    datas[0] = total;
  }
  else {
    datas = [total, ...datas];
  }

  return datas;
}

//total行作成
const calcTotalSub = (total: RowDataItemZaiko, data: RowDataItemZaiko) => {
  //以下に貼り付け
  total.itblir = data.itblir;

  total.RQTY_PS = calcUtil.plus(total.RQTY_PS, data.RQTY_PS);
  total.RAMT = calcUtil.plus(total.RAMT, data.RAMT);
  total.TQTY_PS = calcUtil.plus(total.TQTY_PS, data.TQTY_PS);
  total.TAMT = calcUtil.plus(total.TAMT, data.TAMT);
  total.YQTY_PS = calcUtil.plus(total.YQTY_PS, data.YQTY_PS);
  total.YAMT = calcUtil.plus(total.YAMT, data.YAMT);
  total.FQTY_PS = calcUtil.plus(total.FQTY_PS, data.FQTY_PS);
  total.FAMT = calcUtil.plus(total.FAMT, data.FAMT);
  total.AZKE_PS = calcUtil.plus(total.AZKE_PS, data.AZKE_PS);
  total.AAMT = calcUtil.plus(total.AAMT, data.AAMT);

  total.SQTY1_PS = calcUtil.plus(total.SQTY1_PS, data.SQTY1_PS);
  total.SKIN1 = calcUtil.plus(total.SKIN1, data.SKIN1);
  total.SQTY2_PS = calcUtil.plus(total.SQTY2_PS, data.SQTY2_PS);
  total.SKIN2 = calcUtil.plus(total.SKIN2, data.SKIN2);
  total.SQTY3_PS = calcUtil.plus(total.SQTY3_PS, data.SQTY3_PS);
  total.SKIN3 = calcUtil.plus(total.SKIN3, data.SKIN3);
  total.SQTY_PS = calcUtil.plus(total.SQTY_PS, data.SQTY_PS);
  total.SKIN = calcUtil.plus(total.SKIN, data.SKIN);

  total.HQTY1_PS = calcUtil.plus(total.HQTY1_PS, data.HQTY1_PS);
  total.HKIN1 = calcUtil.plus(total.HKIN1, data.HKIN1);
  total.HQTY2_PS = calcUtil.plus(total.HQTY2_PS, data.HQTY2_PS);
  total.HKIN2 = calcUtil.plus(total.HKIN2, data.HKIN2);
  total.HQTY3_PS = calcUtil.plus(total.HQTY3_PS, data.HQTY3_PS);
  total.HKIN3 = calcUtil.plus(total.HKIN3, data.HKIN3);
  total.HQTY4_PS = calcUtil.plus(total.HQTY4_PS, data.HQTY4_PS);
  total.HKIN4 = calcUtil.plus(total.HKIN4, data.HKIN4);
  total.HQTY_PS = calcUtil.plus(total.HQTY_PS, data.HQTY_PS);
  total.HKIN = calcUtil.plus(total.HKIN, data.HKIN);
}

//計算
const calcDatas = (datas: RowDataItemZaiko[], targetTP: "all" | "total" | "item"): RowDataItemZaiko[] => {
  const total = datas.filter(data => data.TP == "total")[0]; //total行
  datas.forEach((data) => {
    if (targetTP == "all" || data.TP == targetTP) {

      data.ZQTY_PS = calcUtil.plus(calcUtil.plus(calcUtil.plus(calcUtil.plus(data.RQTY_PS, data.TQTY_PS), data.YQTY_PS), data.FQTY_PS), data.AZKE_PS);
      data.ZAMT = calcUtil.plus(calcUtil.plus(calcUtil.plus(calcUtil.plus(data.RAMT, data.TAMT), data.YAMT), data.FAMT), data.AAMT);

      data.RQTY = data.RQTY_PS ? (Math.floor(data.RQTY_PS / data.itblir * 10) / 10) : null;
      data.TQTY = data.TQTY_PS ? (Math.floor(data.TQTY_PS / data.itblir * 10) / 10) : null;
      data.YQTY = data.YQTY_PS ? (Math.floor(data.YQTY_PS / data.itblir * 10) / 10) : null;
      data.FQTY = data.FQTY_PS ? (Math.floor(data.FQTY_PS / data.itblir * 10) / 10) : null;
      data.AZKE = data.AZKE_PS ? (Math.floor(data.AZKE_PS / data.itblir * 10) / 10) : null;
      data.ZQTY = data.ZQTY_PS ? (Math.floor(data.ZQTY_PS / data.itblir * 10) / 10) : null;

      data.SQTY1 = data.SQTY1_PS ? (Math.floor(data.SQTY1_PS / data.itblir * 10) / 10) : null;
      data.SQTY2 = data.SQTY2_PS ? (Math.floor(data.SQTY2_PS / data.itblir * 10) / 10) : null;
      data.SQTY3 = data.SQTY3_PS ? (Math.floor(data.SQTY3_PS / data.itblir * 10) / 10) : null;
      data.SQTY = data.SQTY_PS ? (Math.floor(data.SQTY_PS / data.itblir * 10) / 10) : null;

      data.HQTY1 = data.HQTY1_PS ? (Math.floor(data.HQTY1_PS / data.itblir * 10) / 10) : null;
      data.HQTY2 = data.HQTY2_PS ? (Math.floor(data.HQTY2_PS / data.itblir * 10) / 10) : null;
      data.HQTY3 = data.HQTY3_PS ? (Math.floor(data.HQTY3_PS / data.itblir * 10) / 10) : null;
      data.HQTY4 = data.HQTY4_PS ? (Math.floor(data.HQTY4_PS / data.itblir * 10) / 10) : null;
      data.HQTY = data.HQTY_PS ? (Math.floor(data.HQTY_PS / data.itblir * 10) / 10) : null;

      // 構成比	合計に対する割合
      data.SQTYR = calcUtil.divide(data.SQTY_PS, total.SQTY_PS);
      data.SKINR = calcUtil.divide(data.SKIN, total.SKIN);

      // 消化率	販売累計 / 仕入累計
      data.HQTYR = calcUtil.divide(data.HQTY_PS, data.SQTY_PS);
      data.HKINR = calcUtil.divide(data.HKIN, data.SKIN);
    }
  });

  return datas;
}

//配列データに変換
const convertRows = (datas:RowDataItemZaiko[]): [][] => {
  const rows = [];
  //set No.
  datas.forEach((data) => {
    const r = [];
    colKeys.forEach((colKey) => {
      r.push(data[colKey]);
    });
    rows.push(r);
  });

  return rows;
}

//ソート
const doSort = (datas:RowDataItemZaiko[], colKey:string, asc:boolean): RowDataItemZaiko[] => {
  console.log('zaiko doSort');
  //Totalのソート
  const sortContTotal = (colKey, asc, objA):number => {
    //合計行は常に上
    return objA.TP == "total" ? -1 : 1;
  };
  //同じ階層同士のソート
  const sortCont = (TP, colKey, asc, objA, objB):number => {
    let comp = 0;
    const va = objA ? objA[colKey] : null;
    const vb = objB ? objB[colKey] : null;
    //数値型
    if(typeof va === 'number' || typeof vb === 'number') {
      comp = compareUtil.compareNumber2(va, vb, asc);
    }
    else if(typeof va === 'string' || typeof vb === 'string') {
      comp = compareUtil.compareString(va, vb, asc);
    }
    return comp;
  };

  datas.sort((a, b) => {
    //合計行は常に上
    if (a.TP == "total" || b.TP == "total") {
      return sortContTotal(colKey, asc, a);
    }

    return sortCont(a.TP, colKey, asc, a, b);
  });

  return datas;
}

export const lastUpdate = (datas: RowDataItemZaiko[]): string => {
  const map = datas.filter(data => data.DATEUP && data.TIMEUP)
    .map(data => moment(data.DATEUP.padStart(8, '0') + '' + data.TIMEUP.padStart(6, '0'), 'YYYYMMDDHHmmss'));
  if (map.length == 0) {
    return "";
  }

  const max: moment.Moment = map.reduce((a, b) => a.unix() >= b.unix() ? a : b);
  const now = moment(new Date());
  if (now.diff(max, 'day')) {
    return ' (' + max.format('M/D H') + '時時点)';
  }
  return ' (' + max.format('H') + '時時点)';
}

//Page Slice Export
//itemListZaikoTmp
export const itemListZaikoTmpSlice = createSliceContent("itemListZaikoTmp");
