
export const plus = (n1:number, n2:number) => {
  if(typeof n1 == 'string') n1 = parseFloat(n1);
  if(typeof n2 == 'string') n2 = parseFloat(n2);

  if(n1 == null && n2 == null) return null; //null or undefined
  if(n1 == null) return n2;
  if(n2 == null) return n1;
  return n1 + n2;
}
export const sum = (n:number[]) => {
  let vv;
  n.forEach(v => {vv = plus(vv, v)});
  return vv;
}

export const minus = (n1:number, n2:number) => {
  if(typeof n1 == 'string') n1 = parseFloat(n1);
  if(typeof n2 == 'string') n2 = parseFloat(n2);

  if(n1 == null && n2 == null) return null; //null or undefined
  if(n1 == null) return -n2;
  if(n2 == null) return n1;
  return n1 - n2;
}

export const minus2 = (n1:number, n2:number) => {
  if(typeof n1 == 'string') n1 = parseFloat(n1);
  if(typeof n2 == 'string') n2 = parseFloat(n2);

  if(n1 == null || n2 == null) return null; //null or undefined
  return n1 - n2;
}

export const divide = (n1:number, n2:number) => {
  if(typeof n1 == 'string') n1 = parseFloat(n1);
  if(typeof n2 == 'string') n2 = parseFloat(n2);

  if(!n2) return null; //0 or null or undefined
  if(n1 == null) return null; //null or undefined
  return n1 / n2;
}

export const multiply = (n1:number, n2:number) => {
  if(typeof n1 == 'string') n1 = parseFloat(n1);
  if(typeof n2 == 'string') n2 = parseFloat(n2);

  if(n1 == null || n2 == null) return null;
  return n1 * n2;
}

export const greater = (n1:number, n2:number) => {
  if(typeof n1 == 'string') n1 = parseFloat(n1);
  if(typeof n2 == 'string') n2 = parseFloat(n2);

  if(n1 == null && n2 == null) return null; //null or undefined
  if(n1 == null) return n2;
  if(n2 == null) return n1;
  return n1 > n2 ? n1 : n2;
}
export const lesser = (n1:number, n2:number) => {
  if(typeof n1 == 'string') n1 = parseFloat(n1);
  if(typeof n2 == 'string') n2 = parseFloat(n2);

  if(n1 == null && n2 == null) return null; //null or undefined
  if(n1 == null) return n2;
  if(n2 == null) return n1;
  return n1 < n2 ? n1 : n2;
}
