import moment from 'moment';
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  colsFromKeys,
} from "@/components/tokubai/manage/TokubaiManageTableModel";

import { CodeName } from "@/store/common";
import { initialStatusList, EditingType, initialRequestDateTypeList } from "@/store/tokubai/manage/tokubaiManageCommon";

export const defaultHiddenColumnsKey = [
  //以下に貼り付け
  "IJN",
  "MRC",
  "ISC",
  "IMC",
  "ICC", "ICN",
  "IBC", "IBN",
  "ILI",
  "IPR",
  "ISD",
  "IED",
  "TCD",
  "OIM",
  "OLT",
];

//Page State
export type State = {
  //期間タイプ
  requestDateType:CodeName | null,
  //期間
  requestDateFrom: Date,
  requestDateTo: Date,
  //発注ステータス
  status: CodeName[] | null,

  //倉庫
  center: CodeName | null,
  //依頼者
  requestUser: CodeName | null,
  //発注担当者
  orderUser: CodeName | null,
  //企業グループ１
  // group1: CodeName | null,
  group1s: CodeName[],
  //メーカー
  // maker: CodeName | null,
  makers: CodeName[],

  item: string | null,
  //ADMS発注小計を表示
  adms: boolean,

  //お気に入りメーカー
  favoriteMakers: { pattern: CodeName, makers: CodeName[] }[] | null,

  // //検索終了した条件
  // requestedParam : RequestParam,

  sorts: { key: string; asc: boolean }[],
  admsSorts: { key: string; asc: boolean }[],
  hiddenColumns: number[],
  hiddenColumnsKey: string[],
  tmpHiddenColumnsKeys: string[], //納入先がある時のhiddenColumnsKey
  tokuisakiFlag: boolean, //納入先判定フラグ
  orderCenterFlag: boolean, //発注倉庫判定フラグ
};

export const initialState: State = {
  //期間タイプ
  requestDateType: initialRequestDateTypeList[0],
  //期間
  requestDateFrom: null,
  requestDateTo: null,
  //発注ステータス
  status: [initialStatusList[0]],

  //倉庫
  center: null,
  //依頼者
  requestUser: null,
  //発注担当者
  orderUser: null,
  //企業グループ１
  // group1: null,
  group1s: [],
  //メーカー
  // maker: null,
  makers: [],

  item: null,
  //ADMS発注小計を表示
  adms: false,

  //お気に入りメーカー
  favoriteMakers: [],

  // requestedParam : {},

  sorts: [
    { key: "OFS", asc: true, }, //初回出荷日(特売期間開始日:KFR)
    { key: "ICD", asc: true, }, //商品CD
    { key: "OYT", asc: true, }, //用途
    { key: "MRG", asc: true, }, //企業グループ１
    { key: "TCD", asc: true, }, //納入先
    { key: "STS", asc: true, }, //ステータス
    { key: "MRU", asc: true, }, //依頼者

  ],
  admsSorts: [
    { key: "ANO", asc: true, }, //ADMS発注番号
    { key: "ICD", asc: true, }, //商品CD
    { key: "OFS", asc: true, }, //出荷日
    { key: "KFR", asc: true, }, //特売期間-開始日
  ],
  hiddenColumns: [],
  hiddenColumnsKey: defaultHiddenColumnsKey,
  tmpHiddenColumnsKeys: [
    "TCD"//納入先CD
  ],
  tokuisakiFlag: null,
  orderCenterFlag: null,
};

//Page Slice
export const tokubaiManageSaveSlice = createSlice({
  name: "tokubaiManageSave",
  initialState,
  reducers: {
    //componentDidMount
    initOnDidMount(state:State) {
      //追加属性等はlocalstorageから復元したときにundefinedになっている場合があるためチェックする
      if (!state.requestUser) {
        state.requestUser = initialState.requestUser;
      }
      if (!state.center) {
        state.center = initialState.center;
      }
      if (!state.requestUser) {
        state.requestUser = initialState.requestUser;
      }
      if (!state.group1s || state.group1s.length == 0) {
        state.group1s = initialState.group1s;
      }
      if (!state.makers || state.makers.length == 0) {
        state.makers = initialState.makers;
      }
      if (!state.item || state.item.length == 0) {
        state.item = initialState.item;
      }
      if (state.adms === null || state.adms === undefined) {
        state.adms = initialState.adms;
      }

      if (!state.status || state.status.length === 0) {
        state.status = initialState.status;
      } else if (state.status && !Array.isArray(state.status)) {
        state.status = [state.status];
      }
      if (!state.requestDateType) {
        state.requestDateType = initialState.requestDateType;
      }
      if (!state.requestDateFrom && !state.requestDateTo) {
        state.requestDateFrom = moment(new Date()).add(-1, 'months').add(1, 'days').toDate();
        state.requestDateTo = new Date();
      }
      if (typeof state.requestDateFrom === 'string') {
        state.requestDateFrom = moment(state.requestDateFrom).toDate();
      }
      if (typeof state.requestDateTo === 'string') {
        state.requestDateTo = moment(state.requestDateTo).toDate();
      }
      if (!state.orderUser) {
        state.orderUser = initialState.orderUser;
      }

      if(!state.favoriteMakers || state.favoriteMakers.length == 0) {
        state.favoriteMakers = initialState.favoriteMakers;
      }

      if (!state.sorts) {
        state.sorts = initialState.sorts;
      }
      if (!state.admsSorts) {
        state.admsSorts = initialState.admsSorts;
      }
      if (!state.hiddenColumnsKey) {
        state.hiddenColumnsKey = initialState.hiddenColumnsKey;
      }
      if (!state.tmpHiddenColumnsKeys) {
        state.tmpHiddenColumnsKeys = initialState.tmpHiddenColumnsKeys;
      }
      if (!state.tokuisakiFlag) {
        state.tokuisakiFlag = initialState.tokuisakiFlag;
      }
      if (!state.orderCenterFlag) {
        state.orderCenterFlag = initialState.orderCenterFlag;
      }
    },

    //Option requestDate
    setRequestDateFrom(state:State, action: PayloadAction<Date>) {
      state.requestDateFrom = action.payload;
      if (state.requestDateFrom && state.requestDateTo && state.requestDateFrom > state.requestDateTo) {
        state.requestDateTo = state.requestDateFrom;
      }
    },
    setRequestDateTo(state:State, action: PayloadAction<Date>) {
      state.requestDateTo = action.payload;
      if (state.requestDateFrom && state.requestDateTo && state.requestDateFrom > state.requestDateTo) {
        state.requestDateFrom = state.requestDateTo;
      }
    },

    // Option RequestUser
    setRequestUser(state:State, action: PayloadAction<CodeName>) {
      state.requestUser = action.payload;
    },
    setRequestUserList(state:State, action: PayloadAction<CodeName[]>) {
      const requestUserList = action.payload;
      let requestUser =
        state.requestUser && state.requestUser.code
          ? requestUserList.find(
              (requestUser) => requestUser.code == state.requestUser.code
            )
          : null;
      if (!requestUser) {
        requestUser = requestUserList[0];
      }
      state.requestUser = requestUser;
    },

    // Option OrderUser
    setOrderUser(state:State, action: PayloadAction<CodeName>) {
      state.orderUser = action.payload;
    },
    setOrderUserList(state:State, action: PayloadAction<CodeName[]>) {
      const orderUserList = action.payload;
      let orderUser =
        state.orderUser && state.orderUser.code
          ? orderUserList.find(
              (orderUser) => orderUser.code == state.orderUser.code
            )
          : null;
      if (!orderUser) {
        orderUser = orderUserList[0];
      }
      state.orderUser = orderUser;
    },

    // Option Center
    setCenter(state:State, action: PayloadAction<CodeName>) {
      state.center = action.payload;
    },
    setCenterList(state:State, action: PayloadAction<CodeName[]>) {
      const centerList = action.payload;
      let center =
        state.center && state.center.code
          ? centerList.find((center) => center.code == state.center.code)
          : null;
      if (!center) {
        center = centerList[0];
      }
      state.center = center;
    },

    // Option Group1
    // setGroup1(state:State, action: PayloadAction<CodeName>) {
    //   state.group1 = action.payload;
    // },
    setGroup1s(state:State, action: PayloadAction<CodeName[]>) {
      state.group1s = action.payload;
    },
    setGroup1List(state:State, action: PayloadAction<CodeName[]>) {
      const group1List = action.payload;
      let group1s = [];
      if (state.group1s && state.group1s.length > 0) {
        const group1CDs: string[] = state.group1s.map((group1) => group1.code);
        group1s = group1List.filter(
          (group1) => group1CDs.indexOf(group1.code) !== -1
        );
      }
      state.group1s = group1s;

      // let group1 = state.group1 && state.group1.code? group1List.find((group1) => group1.code == state.group1.code) : null;
      // if(!group1) {
      //   group1 = group1List[0];
      // }
      // state.group1 = group1;
    },

    // Option Maker
    // setMaker(state:State, action: PayloadAction<CodeName>) {
    //   state.maker = action.payload;
    // },
    setMakers(state:State, action: PayloadAction<CodeName[]>) {
      state.makers = action.payload;
    },
    setMakerList(state:State, action: PayloadAction<CodeName[]>) {
      const makerList = action.payload;
      let makers = [];
      if (state.makers && state.makers.length > 0) {
        const makerCDs: string[] = state.makers.map((maker) => maker.code);
        makers = makerList.filter(
          (maker) => makerCDs.indexOf(maker.code) !== -1
        );
      }
      state.makers = makers;

      // let maker = state.maker && state.maker.code? makerList.find((maker) => maker.code == state.maker.code) : null;
      // if(!maker) {
      //   maker = makerList[0];
      // }
      // state.maker = maker;
    },
    setItem(state:State, action: PayloadAction<string>) {
      state.item = action.payload;
    },
    setAdms(state: State, action: PayloadAction<boolean>) {
      state.adms = action.payload;
    },

    // Option Status
    setStatusCodes(state, action: PayloadAction<{ codes: string[]; list: CodeName[] }>) {
      const codes = action.payload.codes;
      const list = action.payload.list;
      state.status = list.filter((status) => codes.indexOf(status.code) !== -1);
    },
    setStatusList(state: State, action: PayloadAction<CodeName[]>) {
      const codes = state.status && state.status.length > 0 ? state.status.map(s => s.code) : [];
      const statusList = action.payload;
      let status =
        codes.length > 0
          ? statusList.filter((status) => codes.indexOf(status.code) !== -1)
          : null;
      if (!status) {
        status = [statusList[0]];
      }
      state.status = status;
    },
    setRequestDateType(state:State, action: PayloadAction<CodeName>) {
      const requestDateType: CodeName = action.payload;
      state.requestDateType = requestDateType;
    },
    setRequestDateTypeCode(
      state,
      action: PayloadAction<{ code: string; list: CodeName[] }>
    ) {
      state.requestDateType = action.payload.list.find(
        (requestDateType) => requestDateType.code == action.payload.code
      );
    },
    clearOption(
      state,
      action: PayloadAction<{
        centerList: CodeName[];
        requestUserList: CodeName[];
        orderUserList: CodeName[];
        group1List: CodeName[];
        makerList: CodeName[];
        statusList: CodeName[];
        requestDateTypeList: CodeName[];
      }>
    ) {
      state.requestDateType = action.payload.requestDateTypeList[0];
      state.requestDateFrom = moment(new Date()).add(-1, 'months').add(1, 'days').toDate();
      state.requestDateTo = new Date();
      // state.center = action.payload.centerList[0]; //倉庫は初期化しない
      state.requestUser = action.payload.requestUserList[0];
      state.orderUser = action.payload.orderUserList[0];
      state.group1s = [];
      state.makers = [];
      state.item = initialState.item;
      state.adms = initialState.adms;
      state.status = [action.payload.statusList[0]];
    },
    setOption(
      state,
      action: PayloadAction<{
        status: CodeName[];
        requestDateType: CodeName;
        requestDateFrom: Date;
        requestDateTo: Date;
        requestUser: CodeName;
        orderUser: CodeName;
        group1s: CodeName[];
        makers: CodeName[];
        item: string;
        adms: boolean;
      }>
    ) {
      state.requestDateType = action.payload.requestDateType;
      state.requestDateFrom = action.payload.requestDateFrom;
      state.requestDateTo = action.payload.requestDateTo;
      state.requestUser = action.payload.requestUser;
      state.orderUser = action.payload.orderUser;
      state.group1s = action.payload.group1s;
      state.makers = action.payload.makers;
      state.item = action.payload.item;
      state.status = action.payload.status;
      state.adms = action.payload.adms;
    },

    // //検索条件
    // setRequestedParam(state:State, action: PayloadAction<RequestParam>) {
    //   state.requestedParam = action.payload;
    // },

    setSorts(state, action: PayloadAction<{ adms: boolean, sorts: { key: string; asc: boolean }[] }>) {
      if (action.payload.adms) {
        state.admsSorts = action.payload.sorts;
      } else {
        state.sorts = action.payload.sorts;
      }
    },
    tokuisakiFlagChange(state, action: PayloadAction<{ flag: boolean }>) {
      console.log("store.tokuisakiFlagChange");
      Object.assign(state, {
        tokuisakiFlag: action.payload.flag,
      });
    },
    orderCenterFlagChange(state, action: PayloadAction<{ flag: boolean }>) {
      console.log("store.orderCenterFlagChange");
      Object.assign(state, {
        orderCenterFlag: action.payload.flag,
      });
    },
    tmpHiddenColumnsKeysChange(state:State, action: PayloadAction<{ keys: string[] }>) {
      console.log("store.tmpHiddenColumnsKeysChange");
      Object.assign(state, {
        tmpHiddenColumnsKeys: action.payload.keys,
      });
    },
    hideColumnsChange(
      state,
      action: PayloadAction<{ columns: number[]; keys: string[] }>
    ) {
      console.log("store.hideColumnsChange");
      Object.assign(state, {
        hiddenColumns: action.payload.columns,
        hiddenColumnsKey: action.payload.keys,
      });
    },
    hideColumnKeysChange(state:State, action: PayloadAction<{ hiddenColumnsKey: string[], editingType: EditingType }>) {
      console.log("store.hideColumnKeysChange");
      Object.assign(state, {
        hiddenColumns: colsFromKeys(action.payload.hiddenColumnsKey, action.payload.editingType),
        hiddenColumnsKey: action.payload.hiddenColumnsKey,
      });
    },
    // Option FavoriteMakers
    setFavoriteMakers(state:State, action: PayloadAction<{ favoriteMakers: { pattern: CodeName, makers: CodeName[] }[] }>) {
      console.log('store.setFavoriteMakers');

      Object.assign(state, {
        favoriteMakers: action.payload.favoriteMakers,
      });
    },

  },
});
