import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CodeName } from "@/store/common";
import * as arrayUtil from "@/util/arrayUtil";
import { ZaikoSummaryColRowModel, zaikoSummaryColRowModelDefault } from "@/components/zaikosummary/ZaikoSummaryTableModel";
import { centerInfoAttributes, listSortOrders, optionAll } from "@/store/zaikosummary/zaikoSummaryCommon";

import * as zaikoSummaryRetrieve from "@/assets/apitype/zaikoSummaryRetrieve";

//強制表示列
export const mustVisibleColumnsKey:string[] = [
  "no", 
  "attribute1Value",
  "teiban", 
  "rowHeader", 
];
//デフォルト表示列
export const defaultVisibleColumnsKey:string[] = [
  //以下に貼り付け
  "attribute1Label",

  "syukkaAmtAve",
  // "syukkaQtyAve",
  "syukkaAmtTtl",
  // "syukkaQtyTtl",
  "cycleCnt",
  
  // "zaikoTtlPrd",
  // "zaikoTtlQty",
  "zaikoTtlAmt",

  'over1_2',
  'over',

  // "zaiko101Prd",
  // "zaiko101Qty",
  "zaiko101Amt",
  "zaiko101Rat",
  
  // "zaiko031Prd",
  // "zaiko031Qty",
  "zaiko031Amt",
  "zaiko031Rat",
  
  // "zaiko011Prd",
  // "zaiko011Qty",
  "zaiko011Amt",
  "zaiko011Rat",
  
  // "zaiko000Prd",
  // "zaiko000Qty",
  "zaiko000Amt",
  "zaiko000Rat",

  // "zaiko999Prd",
  // "zaiko999Qty",
  "zaiko999Amt",
  "zaiko999Rat",
];
//強制表示行
export const mustVisibleRowsKey:string[] = [
];
export const mustVisibleTeibanKey:string[] = [
];
//デフォルト表示行
export const defaultVisibleRowsKey:string[] = [
  'total',
  'zaiko1',	//在庫1(一般)
  'zaiko2',	//在庫2(特売)
  'zaiko3',	//在庫3(予約)
  'zaiko4',	//在庫4(出切)
  'zaiko5',	//在庫5(返品)
];
export const defaultVisibleTeibanKey: string[] = [
  '定番',
  '定番外',
];

//デフォルト表示項目(4行必要)
export const defaultVisibleAttributes:string[][] = [
  [
    'centerNM', // センター名
    'centerCD', // センターCD
    'centerDiv', // TC/DC
    'bumonNM', // 部門名
  ]
];

export const optionsNameList = [
  'optionMakers',
  'optionMakerCode',
  'optionBumons',
  'optionCenters',
  'optionCenterCode',
  'optionItemCode',
];
export type ZaikoSummaryOptions = {
  optionMakers?: CodeName[],        //メーカー
  optionMakerCode?: string,         //メーカーCD
  optionBumons?: CodeName[],        //部門
  optionCenters?: CodeName[],       //倉庫
  optionCenterCode?: string,        //倉庫CD
  optionItemCode?: string,          //商品CD
};
export const displaySettingsNameList = [
  'visibleAttributes',
  'checkedRowKey',
  'checkedColKey',
];
export type DisplaySettings = {
  visibleAttributes?: string[][],    //表示属性
  checkedRowKey?: string[], //行項目選択
  checkedColKey?: string[],  //列項目選択
};

//Page State
export type ZaikoSummarySaveState = {
  //検索条件
  optionCenterDiv: string,    //TC/DC
  optionBumons:CodeName[],     //部門
  optionCenters:CodeName[],   //倉庫
  optionCenterCode:string,            //倉庫CD

  favoriteOptions: { title: CodeName, params: ZaikoSummaryOptions }[] | null,
  favoriteDisplaySettings: { title: CodeName, params: DisplaySettings }[] | null,

  //検索終了した条件
  requestedParam : zaikoSummaryRetrieve.RequestParam,

  listSortOrder:CodeName,   //並び順
  listSortOrderDesc:boolean,   //昇順

  hiddenColumns: number[],
  hiddenColumnsKey: string[],
  visibleColumnsKey: string[],
  visibleRowsKey: string[],
  visibleTeibanKey: string[],
  visibleOver: boolean,

  visibleAttributes:string[][],    //表示属性
};

export const initialZaikoSummaryState: ZaikoSummarySaveState = {
  optionCenterDiv: optionAll.code,    //TC/DC
  optionBumons:[],     //部門
  optionCenters:[],   //倉庫
  optionCenterCode:'',            //倉庫CD

  favoriteOptions: [],
  favoriteDisplaySettings: [],

  //検索終了した条件
  requestedParam : null,

  listSortOrder: listSortOrders.find(listSortOrder => listSortOrder.code == 'bumon_center_centerCD'),   //並び順
  listSortOrderDesc: false,   //並び順

  hiddenColumns: [],
  hiddenColumnsKey: [],
  visibleColumnsKey: defaultVisibleColumnsKey,
  visibleRowsKey: defaultVisibleRowsKey,
  visibleTeibanKey: defaultVisibleTeibanKey,
  visibleOver: false,

  visibleAttributes: defaultVisibleAttributes,    //表示属性
};

//Page Slice
export type ZaikoSummarySaveReducer = {
  initOnDidMount: (state:ZaikoSummarySaveState) => void,
  resetOnWillUnmount: (state:ZaikoSummarySaveState) => void,
  clearOption: (state:ZaikoSummarySaveState, action: PayloadAction<{}>) => void,
  setOptionCenterDiv: (state:ZaikoSummarySaveState, action: PayloadAction<string>) => void,
  setOptionBumons: (state:ZaikoSummarySaveState, action: PayloadAction<CodeName[]>) => void,
  setOptionCenters: (state:ZaikoSummarySaveState, action: PayloadAction<CodeName[]>) => void,
  setOptionCenterCode: (state:ZaikoSummarySaveState, action: PayloadAction<string>) => void,
  setFavoriteOptions: (state:ZaikoSummarySaveState, action: PayloadAction<{ title: CodeName, params: ZaikoSummaryOptions }[]>) => void,
  setFavoriteDisplaySettings: (state:ZaikoSummarySaveState, action: PayloadAction<{ title: CodeName, params: DisplaySettings }[]>) => void,
  setRequestedParam: (state:ZaikoSummarySaveState, action: PayloadAction<zaikoSummaryRetrieve.RequestParam>) => void,
  setListSortOrder: (state:ZaikoSummarySaveState, action: PayloadAction<CodeName>) => void,
  setListSortOrderDesc: (state:ZaikoSummarySaveState, action: PayloadAction<boolean>) => void,
  hideColumnKeysChange: (state:ZaikoSummarySaveState, action: PayloadAction<string[]>) => void,
  visibleRowKeysChange: (state:ZaikoSummarySaveState, action: PayloadAction<string[]>) => void,
  visibleTeibanKeysChange: (state: ZaikoSummarySaveState, action: PayloadAction<string[]>) => void,
  visibleOverChange: (state: ZaikoSummarySaveState, action: PayloadAction<boolean>) => void,
  visibleAttributesChange: (state:ZaikoSummarySaveState, action: PayloadAction<string[][]>) => void,
}

const createReducerContent = (name:string, colRowModel:ZaikoSummaryColRowModel, initialState: ZaikoSummarySaveState):ZaikoSummarySaveReducer => {return {
  //componentDidMount
  initOnDidMount(state:ZaikoSummarySaveState) {
    if(state.optionCenterDiv === undefined) {
      state.optionCenterDiv = initialZaikoSummaryState.optionCenterDiv;
    }
    if(state.optionBumons === undefined) {
      state.optionBumons = initialZaikoSummaryState.optionBumons;
    }
    if(state.optionCenters === undefined) {
      state.optionCenters = initialZaikoSummaryState.optionCenters;
    }
    if(state.optionCenterCode === undefined) {
      state.optionCenterCode = initialZaikoSummaryState.optionCenterCode;
    }
    if(state.favoriteOptions === undefined) {
      state.favoriteOptions = initialZaikoSummaryState.favoriteOptions;
    }
    if(state.favoriteDisplaySettings === undefined) {
      state.favoriteDisplaySettings = initialZaikoSummaryState.favoriteDisplaySettings;
    }
    if(!state.listSortOrder) {
      state.listSortOrder = initialState.listSortOrder;
    }
    else {
      if (!listSortOrders.find(listSortOrder => listSortOrder.code == state.listSortOrder.code)) {
        state.listSortOrder = initialState.listSortOrder;
      }
    }
    if(state.listSortOrderDesc === undefined) {
      state.listSortOrderDesc = initialState.listSortOrderDesc;
    }

    //初期表示列0配列は、常に全項目表示とする
    if(initialState.visibleColumnsKey.length == 0) {
      state.visibleColumnsKey = colRowModel.colKeys;
    }
    else if(!state.visibleColumnsKey) {
      state.visibleColumnsKey = initialState.visibleColumnsKey;
    }
    //強制選択列
    state.visibleColumnsKey = arrayUtil.union(arrayUtil.and(colRowModel.colKeys, mustVisibleColumnsKey), state.visibleColumnsKey);
    //非表示列
    state.hiddenColumnsKey = arrayUtil.not(colRowModel.colKeys, state.visibleColumnsKey);

    //行項目
    if (!state.visibleRowsKey || state.visibleRowsKey.length == 0) {
      state.visibleRowsKey = (initialState.visibleRowsKey.length == 0) ? colRowModel.rowKeys : initialState.visibleRowsKey;
    }
    //強制選択行
    state.visibleRowsKey = arrayUtil.union(arrayUtil.and(colRowModel.rowKeys, mustVisibleRowsKey), state.visibleRowsKey);

    if (!state.visibleTeibanKey || state.visibleTeibanKey.length == 0) {
      state.visibleTeibanKey = (initialState.visibleTeibanKey.length == 0) ? colRowModel.teibanKeys : initialState.visibleTeibanKey;
    }
    state.visibleTeibanKey = arrayUtil.union(arrayUtil.and(colRowModel.teibanKeys, mustVisibleTeibanKey), state.visibleTeibanKey);

    if(state.visibleOver === undefined) {
      state.visibleOver = initialState.visibleOver;
    }

    //表示項目
    if(!state.visibleAttributes || state.visibleAttributes.length != initialState.visibleAttributes.length) {
      state.visibleAttributes = initialState.visibleAttributes;
    }
    else {
      //6項目必要
      let visibleAttributes = [...state.visibleAttributes];
      let init = false;
      initialState.visibleAttributes.forEach((attrs, index) => {
        if(visibleAttributes[index].length != attrs.length) {
          init = true;
          visibleAttributes[index] = attrs;
        }
      });
      if(init) {
        state.visibleAttributes = visibleAttributes;
      }
    }
  },
  //componentWillUnmount
  resetOnWillUnmount(state:ZaikoSummarySaveState) {
    //初期表示列0配列は、常に全項目表示とするため、記憶しない
    if(initialState.visibleColumnsKey.length == 0) {
      state.visibleColumnsKey = [];
    }
  },
  clearOption(state:ZaikoSummarySaveState, action: PayloadAction<{}>) {
    state.optionBumons = initialZaikoSummaryState.optionBumons;
    state.optionCenters = initialZaikoSummaryState.optionCenters;
    state.optionCenterCode = initialZaikoSummaryState.optionCenterCode;
  },
  setOptionCenterDiv(state: ZaikoSummarySaveState, action: PayloadAction<string>) {
    state.optionCenterDiv = action.payload;
  },
  setOptionBumons (state:ZaikoSummarySaveState, action: PayloadAction<CodeName[]>) {
    state.optionBumons = action.payload;
  },
  setFavoriteOptions(state:ZaikoSummarySaveState, action: PayloadAction<{ title: CodeName, params: ZaikoSummaryOptions }[]>) {
    state.favoriteOptions = action.payload;
  },
  setFavoriteDisplaySettings(state:ZaikoSummarySaveState, action: PayloadAction<{ title: CodeName, params: DisplaySettings }[]>) {
    state.favoriteDisplaySettings = action.payload;
  },
  setRequestedParam(state:ZaikoSummarySaveState, action: PayloadAction<zaikoSummaryRetrieve.RequestParam>) {
    state.requestedParam = action.payload;
  },
  setOptionCenters (state:ZaikoSummarySaveState, action: PayloadAction<CodeName[]>) {
    state.optionCenters = action.payload;
  },
  setOptionCenterCode (state:ZaikoSummarySaveState, action: PayloadAction<string>) {
    state.optionCenterCode = action.payload;
  },
  setListSortOrder (state:ZaikoSummarySaveState, action: PayloadAction<CodeName>) {
    state.listSortOrder = action.payload;
  },
  setListSortOrderDesc (state:ZaikoSummarySaveState, action: PayloadAction<boolean>) {
    state.listSortOrderDesc = action.payload;
  },
  hideColumnKeysChange(state:ZaikoSummarySaveState, action: PayloadAction<string[]>){
    console.log('store.hideColumnKeysChange');
    //store更新
    state.hiddenColumns = colRowModel.colFromKeys(action.payload);
    state.hiddenColumnsKey = action.payload;
    state.visibleColumnsKey = arrayUtil.not(colRowModel.colKeys, action.payload);
  },
  visibleRowKeysChange(state:ZaikoSummarySaveState, action: PayloadAction<string[]>){
    console.log('store.visibleRowKeysChange');
    //store更新
    state.visibleRowsKey = action.payload;
  },
  visibleTeibanKeysChange(state: ZaikoSummarySaveState, action: PayloadAction<string[]>) {
    console.log('store.visibleTeibanKeysChange');
    //store更新
    state.visibleTeibanKey = action.payload;
  },
  visibleOverChange(state: ZaikoSummarySaveState, action: PayloadAction<boolean>) {
    //store更新
    state.visibleOver = action.payload;
  },
  visibleAttributesChange(state:ZaikoSummarySaveState, action: PayloadAction<string[][]>){
    console.log('store.visibleAttributesChange');
    //store更新
    state.visibleAttributes = action.payload;
  },
}};

const createSliceContent = (name:string, colRowModel:ZaikoSummaryColRowModel, initialState: ZaikoSummarySaveState) => createSlice({
  name: name,
  initialState: initialState,
  reducers: createReducerContent(name, colRowModel, initialState),
});

//Page Slice Export
//zaikoSummarySaveSlice
export const zaikoSummarySaveSlice = createSliceContent("zaikoSummarySave", zaikoSummaryColRowModelDefault, initialZaikoSummaryState);
