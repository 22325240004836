import React, { useEffect } from "react";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "@/store";
import { CodeName } from "@/store/common";
import { tokubaiSettingTmpSlice } from "@/store/tokubai/setting/tokubaiSettingTmp";

//Icons

import Grid from '@mui/material/Grid';
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import Typography from '@mui/material/Typography';
import TextField from "@mui/material/TextField";
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';

export const noteItemList: CodeName[] = [];

[
  { code: 'null', name: 'なし' },
  { code: 'group1', name: '企業グループ1名' },
  { code: 'tokuisaki', name: '納入先名' },
  { code: 'syukka', name: '出荷日' },
  { code: 'nouhin', name: '納品日' },
].forEach(cn => {
  noteItemList.push(new CodeName({ code: cn.code, name: cn.name }));
});

//Page Style
const CustomDiv = styled('div')(({ theme }) => ({
  flexGrow: 1,
  margin: theme.spacing(1),
}));
export const CustomPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  marginBottom: theme.spacing(1),
}));
export const CustomFormLabel = styled(FormLabel)(() => ({
  fontSize: "0.766rem",
}));


//Main
const TokubaiSettingCenter = (): JSX.Element => {
  // const router = useRouter();

  //Store, Dispatcher
  const dispatch = useDispatch();
  const userStore = useSelector((state: RootState) => state.user);
  const tokubaiSettingSaveStore = useSelector((state: RootState) => state.tokubaiSettingSave);
  const tokubaiSettingTmpStore = useSelector((state: RootState) => state.tokubaiSettingTmp);

  //DEF componentDidMount
  const componentDidMount = () => {
    console.log('TokubaiSettingCenter.componentDidMount');
  };

  //DEF componentWillUnmount
  const componentWillUnmount = () => {
    console.log('TokubaiSettingCenter.componentWillUnmount');
    dispatch(tokubaiSettingTmpSlice.actions.setEditingEnd());
  }

  useEffect(() => {
    //DO componentWillUnmount
    componentDidMount.bind(this)();
    return () => {
      //DO componentWillUnmount
      componentWillUnmount.bind(this)();
    }
  }, []); //Empty

  useEffect(() => {
    setNoteItem1(tokubaiSettingTmpStore.noteItem1?.code);
  }, [tokubaiSettingTmpStore.noteItem1]);

  useEffect(() => {
    setNoteItem2(tokubaiSettingTmpStore.noteItem2?.code);
  }, [tokubaiSettingTmpStore.noteItem2]);
  useEffect(() => {
    setKuraireNoteItem1(tokubaiSettingTmpStore.kuraireNoteItem1?.code);
    if (tokubaiSettingTmpStore.kuraireNoteItem1?.code != 'null') {
      setKuraire(true);
    }
  }, [tokubaiSettingTmpStore.kuraireNoteItem1]);

  useEffect(() => {
    setKuraireNoteItem2(tokubaiSettingTmpStore.kuraireNoteItem2?.code);
    if (tokubaiSettingTmpStore.kuraireNoteItem2?.code != 'null') {
      setKuraire(true);
    }
  }, [tokubaiSettingTmpStore.kuraireNoteItem2]);

  useEffect(() => {
    setOrderLimitDays(tokubaiSettingTmpStore.orderLimitDays);
  }, [tokubaiSettingTmpStore.orderLimitDays]);

  //DO componentDidUpdate

  //Handler
  const handleNoteItem1Change = (value: string) => {
    dispatch(tokubaiSettingTmpSlice.actions.setNoteItem1(noteItemList.find(cn => cn.code == value)));
  }
  const handleNoteItem2Change = (value: string) => {
    dispatch(tokubaiSettingTmpSlice.actions.setNoteItem2(noteItemList.find(cn => cn.code == value)));
  }
  const handleKuraireNoteItem1Change = (value: string) => {
    dispatch(tokubaiSettingTmpSlice.actions.setKuraireNoteItem1(noteItemList.find(cn => cn.code == value)));
  }
  const handleKuraireNoteItem2Change = (value: string) => {
    dispatch(tokubaiSettingTmpSlice.actions.setKuraireNoteItem2(noteItemList.find(cn => cn.code == value)));
  }
  const handleBlNumChange = (value: string) => {
    const days = parseInt(value);
    setOrderLimitDays(isNaN(days) ? '' : days);
  }
  const handleBlNumBlur = (value: string) => {
    const days = parseInt(value);
    const regex = new RegExp(/^[0-9]*$/);
    if (!regex.test(value) || isNaN(days) || days < 0 || days > 999) {
      // 戻す
      setOrderLimitDays(tokubaiSettingTmpStore.center.OLD);
      return;
    }
    dispatch(tokubaiSettingTmpSlice.actions.setOrderLimitDays(days));
  }
  const handleKuraireChange = (value: boolean) => {
    if (!value) {
      dispatch(tokubaiSettingTmpSlice.actions.setKuraireNoteItem1(noteItemList.find(cn => cn.code == 'null')));
      dispatch(tokubaiSettingTmpSlice.actions.setKuraireNoteItem2(noteItemList.find(cn => cn.code == 'null')));
    }
    setKuraire(value);
  }


  //PageState
  const [noteItem1, setNoteItem1] = React.useState<string>(tokubaiSettingTmpStore.noteItem1 ? tokubaiSettingTmpStore.noteItem1.code : '');
  const [noteItem2, setNoteItem2] = React.useState<string>(tokubaiSettingTmpStore.noteItem2 ? tokubaiSettingTmpStore.noteItem2.code : '');
  const [kuraireNoteItem1, setKuraireNoteItem1] = React.useState<string>(tokubaiSettingTmpStore.kuraireNoteItem1 ? tokubaiSettingTmpStore.kuraireNoteItem1.code : '');
  const [kuraireNoteItem2, setKuraireNoteItem2] = React.useState<string>(tokubaiSettingTmpStore.kuraireNoteItem2 ? tokubaiSettingTmpStore.kuraireNoteItem2.code : '');
  const [orderLimitDays, setOrderLimitDays] = React.useState<number | string>(tokubaiSettingTmpStore.orderLimitDays);
  const [kuraire, setKuraire] = React.useState<boolean>(false);

  return (
    <>
      <CustomDiv>
        {/* 摘要欄自動設定項目 */}
        <>
          <CustomPaper variant="outlined">
            <Grid container spacing={1} direction="row" justifyContent="flex-start" alignItems="center">
              <Grid item>
                <Typography>摘要欄自動設定項目</Typography>
              </Grid>
            </Grid>
            <Grid container spacing={1} direction="row" justifyContent="flex-start" alignItems="center">

              <Grid item>
                <FormControl variant="standard" style={{ minWidth: "135px" }}>
                  <InputLabel
                  >備考項目１</InputLabel>
                  <Select
                    value={noteItem1}
                    onChange={(event) => handleNoteItem1Change(event.target.value)}
                    label="備考項目１"
                    disabled={
                      userStore.permissions.indexOf('tokubai-admin') === -1
                    }
                  >
                    {noteItemList.filter(cn => cn.code != 'tokuisaki').map((cn) => {
                      return (
                        <MenuItem
                          key={cn.code}
                          value={cn.code}
                        >
                          {cn.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item>
                <FormControl variant="standard" style={{ minWidth: "135px" }}>
                  <InputLabel
                  >備考項目２</InputLabel>
                  <Select
                    value={noteItem2}
                    onChange={(event) => handleNoteItem2Change(event.target.value)}
                    label="備考項目２"
                    disabled={
                      userStore.permissions.indexOf('tokubai-admin') === -1
                    }
                  >
                    {noteItemList.filter(cn => cn.code != 'tokuisaki').map((cn) => {
                      return (
                        <MenuItem
                          key={cn.code}
                          value={cn.code}
                        >
                          {cn.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </CustomPaper>
        </>
        {/*  */}
        <>
          <CustomPaper variant="outlined">
            <Grid container spacing={1} direction="row" justifyContent="flex-start" alignItems="center">
              <Grid item>
                <Typography>倉入後納入先自動設定</Typography>
              </Grid>
            </Grid>
            <Grid container spacing={1} direction="row" justifyContent="flex-start" alignItems="center">
              <Grid item>
                <FormControl component="fieldset" >
                  <FormControlLabel
                    control={
                      <Checkbox color="primary"
                        checked={kuraire}
                        onChange={(event, checked: boolean) => handleKuraireChange(checked)}
                        disabled={
                          userStore.permissions.indexOf('tokubai-admin') === -1
                        }
                      />
                    }
                    label={'設定あり'}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={1} direction="row" justifyContent="flex-start" alignItems="center">
              <Grid item>
                <FormControl variant="standard" style={{ minWidth: "135px" }}>
                  <InputLabel
                  >備考項目１</InputLabel>
                  <Select
                    value={kuraireNoteItem1}
                    onChange={(event) => handleKuraireNoteItem1Change(event.target.value)}
                    label="備考項目１"
                    disabled={
                      userStore.permissions.indexOf('tokubai-admin') === -1 || !kuraire
                    }
                  >
                    {noteItemList.filter(cn => cn.code != 'group1').map((cn) => {
                      return (
                        <MenuItem
                          key={cn.code}
                          value={cn.code}
                        >
                          {cn.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl variant="standard" style={{ minWidth: "135px" }}>
                  <InputLabel
                  >備考項目２</InputLabel>
                  <Select
                    value={kuraireNoteItem2}
                    onChange={(event) => handleKuraireNoteItem2Change(event.target.value)}
                    label="備考項目２"
                    disabled={
                      userStore.permissions.indexOf('tokubai-admin') === -1 || !kuraire
                    }
                  >
                    {noteItemList.filter(cn => cn.code != 'group1').map((cn) => {
                      return (
                        <MenuItem
                          key={cn.code}
                          value={cn.code}
                        >
                          {cn.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </CustomPaper>
        </>
        {/* 発注期限アラート日数 */}
        <>
          <CustomPaper variant="outlined">
          <Grid container spacing={1} direction="row" justifyContent="flex-start" alignItems="center">
              <Grid item>
                <Typography>発注期限アラート</Typography>
              </Grid>
            </Grid>
            <Grid container spacing={1} direction="row" justifyContent="flex-start" alignItems="end">
              <Grid item>
                <TextField
                  required
                  style={{ width: '120px' }}
                  variant="standard"
                  type={"number"}
                  value={orderLimitDays}
                  onChange={(event) => handleBlNumChange(event.target.value)}
                  onBlur={(event) => handleBlNumBlur(event.target.value)}
                  disabled={
                    userStore.permissions.indexOf('tokubai-admin') === -1
                  }
                />
              </Grid>
              <Grid item>
                日前
              </Grid>
            </Grid>
          </CustomPaper>
        </>

      </CustomDiv>
    </>
  );
};




//Export
export default TokubaiSettingCenter;
