

//テーブルの各列のキー
export const colKeys: string[] = [
  "no",
  "G1CD",
  "G1NM",
  "G1RK",
  "G1KN",
];

//行固定
export const fixedRowsTop = 0;
//列固定
export const fixedColumnsLeft = 0;

//テーブルの各列のキー逆引き
const colsKeysMap = {};
colKeys.forEach((colKey, index) => {
  let cols: number[] = colsKeysMap[colKey];
  if (!cols) {
    cols = [index];
  }
  else {
    cols.push(index);
  }
  colsKeysMap[colKey] = cols;
});

export const headers = [
  {
    no: "<br/>No.",
    //以下に貼り付け
    G1CD: "CD",
    G1NM: "名称",
    G1RK: "略称",
    G1KN: "カナ",
  },
];

export const selectableCol =
{
  no: false,
  //以下に貼り付け
}

export const headerClass =
{
  no: "tokubaisetting_N",
  //以下に貼り付け
}

export const colWidths =
{
  no: 35,
  //以下に貼り付け
  G1CD: 60,
  G1NM: 180,
  G1RK: 180,
  G1KN: 180,
};

export const colDataType =
{
  no: { type: 'text', readOnly: true, className: 'htCenter' },
  //以下に貼り付け
  G1CD: { type: 'text', readOnly: true, className: 'htCenter' },
  G1NM: { type: 'text', readOnly: true, },
  G1RK: { type: 'text', readOnly: false, },
  G1KN: { type: 'text', readOnly: false, },
};

//function
export const keyFromCol = (column: number): string => {
  return colKeys[column];
}
export const keyFromCols = (columns: number[]): string[] => {
  const vals: string[] = [];
  columns.forEach(column => vals.push(keyFromCol(column)))
  return vals;
}
export const colFromKey = (colKey: string): number => {
  const cols = colsFromKey(colKey);
  return cols && cols.length > 0 ? cols[0] : null;
}
export const colsFromKey = (colKey: string): number[] => {
  return colsKeysMap[colKey];
}
export const colsFromKeys = (colKeys: string[]): number[] => {
  const vals: number[] = [];
  colKeys.forEach(colKey => {
    const colsKeysMap = colsFromKey(colKey);
    if (colsKeysMap) {
      colsKeysMap.forEach(col => vals.push(col));
    }
  })
  return vals;
}
