import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  colsFromKeys,
} from "@/components/tokubai/progress/TokubaiProgressTableModel";

import { CodeName } from "@/store/common";
import moment from "moment";

export const defaultHiddenColumnsKey = [
  //以下に貼り付け
  "IJN",
  "MRC",
  "ISC",
  "IMC",
  "ICC", "ICN",
  "IBC", "IBN",
  "ILI",
  "IPR",
  "ISD",
  "IED",
  "SCD",
  "TCD",
];

//Page State
export type State = {
  //依頼日
  requestDate: Date,
  //初回出荷日/特売期間
  tokubaiDateFrom: Date,
  tokubaiDateTo: Date,
  //特売残ありのみ
  tokubaiZan: boolean,

  //倉庫
  centers: CodeName[] | null,
  //依頼者
  requestUser: CodeName | null,
  //企業グループ１
  group1: CodeName | null,
  //メーカー
  // maker: CodeName | null,
  makers: CodeName[],

  item: string | null,

  // //検索終了した条件
  // requestedParam : RequestParam,

  sorts: { key: string; asc: boolean }[],
  hiddenColumns: number[],
  hiddenColumnsKey: string[],
  tmpHiddenColumnsKeys: string[], //納入先がある時のhiddenColumnsKey
  tokuisakiFlag: boolean, //納入先判定フラグ
  orderCenterFlag: boolean, //発注倉庫判定フラグ
};

export const initialState: State = {
  //依頼日
  requestDate: null,
  //初回出荷日/特売期間
  tokubaiDateFrom: moment(moment(new Date()).format('YYYYMMDD')).add(-7, 'days').toDate(),
  tokubaiDateTo: moment(moment(new Date()).format('YYYYMMDD')).add(7, 'days').toDate(),
  //特売残ありのみ
  tokubaiZan: false,

  //倉庫
  centers: [],
  //依頼者
  requestUser: null,
  //企業グループ１
  group1: null,
  //メーカー
  // maker: null,
  makers: [],

  item: null,

  // requestedParam : {},

  sorts: [{
    key: "ICD", //商品CD
    asc: true, //昇順
  }],
  hiddenColumns: [],
  hiddenColumnsKey: defaultHiddenColumnsKey,
  tmpHiddenColumnsKeys: [
    "TCD"//納入先CD
  ],
  tokuisakiFlag: null,
  orderCenterFlag: null,
};

//Page Slice
export const tokubaiProgressSaveSlice = createSlice({
  name: "tokubaiProgressSave",
  initialState,
  reducers: {
    //componentDidMount
    initOnDidMount(state:State) {
      //requestDateは今日にする
      // state.requestDate = new Date();

      //追加属性等はlocalstorageから復元したときにundefinedになっている場合があるためチェックする
      if (!state.requestUser) {
        state.requestUser = initialState.requestUser;
      }
      if (!state.centers || state.centers.length == 0) {
        state.centers = initialState.centers;
      }
      if (!state.requestUser) {
        state.requestUser = initialState.requestUser;
      }
      if (!state.group1 ) {
        state.group1 = initialState.group1;
      }
      if (!state.makers || state.makers.length == 0) {
        state.makers = initialState.makers;
      }
      if (!state.item || state.item.length == 0) {
        state.item = initialState.item;
      }

      if (!state.sorts) {
        state.sorts = initialState.sorts;
      }
      if (!state.hiddenColumnsKey) {
        state.hiddenColumnsKey = initialState.hiddenColumnsKey;
      }
      if (!state.tmpHiddenColumnsKeys) {
        state.tmpHiddenColumnsKeys = initialState.tmpHiddenColumnsKeys;
      }
      if (!state.tokuisakiFlag) {
        state.tokuisakiFlag = initialState.tokuisakiFlag;
      }
      if (!state.orderCenterFlag) {
        state.orderCenterFlag = initialState.orderCenterFlag;
      }

      if (state.tokubaiDateFrom === undefined) {
        state.tokubaiDateFrom = initialState.tokubaiDateFrom;
      }
      if (state.tokubaiDateTo === undefined) {
        state.tokubaiDateTo = initialState.tokubaiDateTo;
      }

    },

    //Option requestDate
    setRequestDate(state:State, action: PayloadAction<Date>) {
      state.requestDate = action.payload;
    },

    //Option tokubaiDateFrom
    setTokubaiDateFrom(state:State, action: PayloadAction<Date>) {
      state.tokubaiDateFrom = action.payload;
      if (!state.tokubaiDateFrom || state.tokubaiDateFrom > state.tokubaiDateTo) {
        state.tokubaiDateTo = state.tokubaiDateFrom;
      }
    },
    //Option tokubaiDateTo
    setTokubaiDateTo(state:State, action: PayloadAction<Date>) {
      state.tokubaiDateTo = action.payload;
      if (!state.tokubaiDateTo || state.tokubaiDateFrom > state.tokubaiDateTo) {
        state.tokubaiDateFrom = state.tokubaiDateTo;
      }
    },
    setTokubaiZan(state:State, action: PayloadAction<boolean>) {
      state.tokubaiZan = action.payload;
    },

    // Option RequestUser
    setRequestUser(state:State, action: PayloadAction<CodeName>) {
      state.requestUser = action.payload;
    },
    setRequestUserList(state:State, action: PayloadAction<CodeName[]>) {
      const requestUserList = action.payload;
      let requestUser =
        state.requestUser && state.requestUser.code
          ? requestUserList.find(
              (requestUser) => requestUser.code == state.requestUser.code
            )
          : null;
      if (!requestUser) {
        requestUser = requestUserList[0];
      }
      state.requestUser = requestUser;
    },

    // Option Center
    setCenters(state:State, action: PayloadAction<CodeName[]>) {
      state.centers = action.payload;
    },
    setCenterList(state:State, action: PayloadAction<CodeName[]>) {
      // let centerList = action.payload;
      // let center =
      //   state.center && state.center.code
      //     ? centerList.find((center) => center.code == state.center.code)
      //     : null;
      // if (!center) {
      //   center = centerList[0];
      // }
      // state.center = center;
      const centerList = action.payload;
      let centers = [];
      if (state.centers && state.centers.length > 0) {
        const centerCDs: string[] = state.centers.map((center) => center.code);
        centers = centerList.filter(
          (center) => centerCDs.indexOf(center.code) !== -1
        );
      }
      state.centers = centers;
    },

    // Option Group1
    // setGroup1(state:State, action: PayloadAction<CodeName>) {
    //   state.group1 = action.payload;
    // },
    setGroup1(state:State, action: PayloadAction<CodeName>) {
      state.group1 = action.payload;
    },
    setGroup1List(state:State, action: PayloadAction<CodeName[]>) {
      const group1List = action.payload;
      let group1 = state.group1 && state.group1.code? group1List.find((group1) => group1.code == state.group1.code) : null;
      state.group1 = group1;
    },

    // Option Maker
    // setMaker(state:State, action: PayloadAction<CodeName>) {
    //   state.maker = action.payload;
    // },
    setMakers(state:State, action: PayloadAction<CodeName[]>) {
      state.makers = action.payload;
    },
    setMakerList(state:State, action: PayloadAction<CodeName[]>) {
      const makerList = action.payload;
      let makers = [];
      if (state.makers && state.makers.length > 0) {
        const makerCDs: string[] = state.makers.map((maker) => maker.code);
        makers = makerList.filter(
          (maker) => makerCDs.indexOf(maker.code) !== -1
        );
      }
      state.makers = makers;

      // let maker = state.maker && state.maker.code? makerList.find((maker) => maker.code == state.maker.code) : null;
      // if(!maker) {
      //   maker = makerList[0];
      // }
      // state.maker = maker;
    },
    setItem(state:State, action: PayloadAction<string>) {
      state.item = action.payload;
    },

    clearOption(
      state,
      action: PayloadAction<{
        centerList: CodeName[];
        requestUserList: CodeName[];
        group1List: CodeName[];
        makerList: CodeName[];
      }>
    ) {
      state.requestDate = initialState.requestDate;
      state.tokubaiDateFrom = initialState.tokubaiDateFrom;
      state.tokubaiDateTo = initialState.tokubaiDateTo;
      state.tokubaiZan = initialState.tokubaiZan;
      //state.centers = []; 倉庫はリセットしない
      state.requestUser = action.payload.requestUserList[0];
      // state.group1 = action.payload.group1List[0]; //企業グループ1はリセットしない
      state.makers = [];
      state.item = initialState.item;
    },

    // //検索条件
    // setRequestedParam(state:State, action: PayloadAction<RequestParam>) {
    //   state.requestedParam = action.payload;
    // },
    tokuisakiFlagChange(state, action: PayloadAction<{ flag: boolean }>) {
      console.log("store.tokuisakiFlagChange");
      Object.assign(state, {
        tokuisakiFlag: action.payload.flag,
      });
    },
    orderCenterFlagChange(state, action: PayloadAction<{ flag: boolean }>) {
      console.log("store.orderCenterFlagChange");
      Object.assign(state, {
        orderCenterFlag: action.payload.flag,
      });
    },
    tmpHiddenColumnsKeysChange(state:State, action: PayloadAction<{ keys: string[] }>) {
      console.log("store.tmpHiddenColumnsKeysChange");
      Object.assign(state, {
        tmpHiddenColumnsKeys: action.payload.keys,
      });
    },
    setSorts(state, action: PayloadAction<{ key: string; asc: boolean }[]>) {
      state.sorts = action.payload;
    },
    hideColumnsChange(
      state,
      action: PayloadAction<{ columns: number[]; keys: string[] }>
    ) {
      console.log("store.hideColumnsChange");
      Object.assign(state, {
        hiddenColumns: action.payload.columns,
        hiddenColumnsKey: action.payload.keys,
      });
    },
    hideColumnKeysChange(state:State, action: PayloadAction<{hiddenColumnsKey:string[], ref:boolean}>) {
      console.log("store.hideColumnKeysChange");
      Object.assign(state, {
        hiddenColumns: colsFromKeys(action.payload.hiddenColumnsKey, action.payload.ref),
        hiddenColumnsKey: action.payload.hiddenColumnsKey,
      });
    },
  },
});
