import {
  combineReducers
} from "@reduxjs/toolkit";

import {
  createStore,
  applyMiddleware,
  compose,
} from "redux";

import {
  persistReducer
} from "redux-persist";

// import storage from "redux-persist/lib/storage";
import createWebStorage from 'redux-persist/lib/storage/createWebStorage'

// custom stores
import { userSlice } from "@/store/user";
import { userSaveSlice } from "@/store/userSave";
import { xraySaveSlice } from "@/store/xray/xraySave";
import { xrayTmpSlice } from "@/store/xray/xrayTmp";
import { commonSaveSlice } from "@/store/common/commonSave";
import { salesListSaveSlice, salesListReport10SaveSlice, salesListReport20SaveSlice, salesListReport21SaveSlice, salesListReport30SaveSlice, salesListReport40SaveSlice } from "@/store/saleslist/salesListSave";
import { salesListTmpSlice, salesListReport10TmpSlice, salesListReport20TmpSlice, salesListReport21TmpSlice, salesListReport30TmpSlice, salesListReport40TmpSlice } from "@/store/saleslist/salesListTmp";
import { salesListTransSaveSlice, salesListTransReport11SaveSlice, salesListTransReport15SaveSlice, salesListTransReport16SaveSlice, salesListTransReport35SaveSlice, salesListTransReport45SaveSlice } from "@/store/saleslisttrans/salesListTransSave";
import { salesListTransTmpSlice, salesListTransReport11TmpSlice, salesListTransReport15TmpSlice, salesListTransReport16TmpSlice, salesListTransReport35TmpSlice, salesListTransReport45TmpSlice } from "@/store/saleslisttrans/salesListTransTmp";
import { itemListSaveSlice, itemListDirectSaveSlice, salesItemListSaveSlice, itemListReportSSaveSlice, itemListReportCutSaveSlice, keppinItemListSaveSlice } from "@/store/itemlist/itemListSave";
import { itemListTmpSlice, itemListDirectTmpSlice, salesItemListTmpSlice, itemListReportSTmpSlice, itemListReportCutTmpSlice, keppinItemListTmpSlice } from "@/store/itemlist/itemListTmp";
import { itemListTransSaveSlice } from "@/store/itemlisttrans/itemListTransSave";
import { itemListTransTmpSlice } from "@/store/itemlisttrans/itemListTransTmp";
import { itemListZaikoSaveSlice } from "@/store/itemlist/itemListZaikoSave";
import { itemListZaikoTmpSlice } from "@/store/itemlist/itemListZaikoTmp";
import { itemListGroup2SaveSlice } from "@/store/itemlist/itemListGroup2Save";
import { itemListGroup2TmpSlice } from "@/store/itemlist/itemListGroup2Tmp";
import { itemListTokuisakiSaveSlice } from "@/store/itemlist/itemListTokuisakiSave";
import { itemListTokuisakiTmpSlice } from "@/store/itemlist/itemListTokuisakiTmp";
import { outputDataTeibanSaveSlice } from "@/store/outputdata/outputDataTeibanSave";
import { outputDataTeibanTmpSlice } from "@/store/outputdata/outputDataTeibanTmp";
import { outputDataTokuisakiSaveSlice } from "@/store/outputdata/outputDataTokuisakiSave";
import { outputDataTokuisakiTmpSlice } from "@/store/outputdata/outputDataTokuisakiTmp";
import { tokubaiInputSaveSlice } from "@/store/tokubai/input/tokubaiInputSave";
import { tokubaiInputTmpSlice } from "@/store/tokubai/input/tokubaiInputTmp";
import { tokubaiManageSaveSlice } from "@/store/tokubai/manage/tokubaiManageSave";
import { tokubaiManageTmpSlice } from "@/store/tokubai/manage/tokubaiManageTmp";
import { tokubaiProgressSaveSlice } from "@/store/tokubai/progress/tokubaiProgressSave";
import { tokubaiProgressTmpSlice } from "@/store/tokubai/progress/tokubaiProgressTmp";
import { tokubaiSettingSaveSlice } from "@/store/tokubai/setting/tokubaiSettingSave";
import { tokubaiSettingTmpSlice } from "@/store/tokubai/setting/tokubaiSettingTmp";
import { outputDataSupplierSaveSlice, outputDataStoreSaveSlice } from "@/store/outputdata/outputDataSupplierSave";
import { outputDataSupplierTmpSlice, outputDataStoreTmpSlice } from "@/store/outputdata/outputDataSupplierTmp";
import { outputDataZaikoSaveSlice } from "@/store/outputdata/outputDataZaikoSave";
import { outputDataZaikoTmpSlice } from "@/store/outputdata/outputDataZaikoTmp";
import { outputDataShodanSaveSlice } from "@/store/outputdata/outputDataShodanSave";
import { outputDataShodanTmpSlice } from "@/store/outputdata/outputDataShodanTmp";
import { outputDataCenterSaveSlice } from "@/store/outputdata/outputDataCenterSave";
import { outputDataCenterTmpSlice } from "@/store/outputdata/outputDataCenterTmp";
import { zaikoSummarySaveSlice } from "@/store/zaikosummary/zaikoSummarySave";
import { zaikoSummaryTmpSlice } from "@/store/zaikosummary/zaikoSummaryTmp";
import { zaikoRealSaveSlice } from "@/store/zaikoreal/zaikoRealSave";
import { zaikoRealTmpSlice } from "@/store/zaikoreal/zaikoRealTmp";
import { zaikoKigenSaveSlice } from "@/store/zaikokigen/zaikoKigenSave";
import { zaikoKigenTmpSlice } from "@/store/zaikokigen/zaikoKigenTmp";

const rootReducer = combineReducers({
  user: userSlice.reducer,
  userSave: userSaveSlice.reducer,
  xraySave: xraySaveSlice.reducer,
  xrayTmp: xrayTmpSlice.reducer,
  commonSave: commonSaveSlice.reducer,
  salesListSave: salesListSaveSlice.reducer,
  salesListTmp: salesListTmpSlice.reducer,
  salesListReport10Save: salesListReport10SaveSlice.reducer,
  salesListReport10Tmp: salesListReport10TmpSlice.reducer,
  salesListReport20Save: salesListReport20SaveSlice.reducer,
  salesListReport20Tmp: salesListReport20TmpSlice.reducer,
  salesListReport21Save: salesListReport21SaveSlice.reducer,
  salesListReport21Tmp: salesListReport21TmpSlice.reducer,
  salesListReport30Save: salesListReport30SaveSlice.reducer,
  salesListReport30Tmp: salesListReport30TmpSlice.reducer,
  salesListReport40Save: salesListReport40SaveSlice.reducer,
  salesListReport40Tmp: salesListReport40TmpSlice.reducer,

  salesListTransSave: salesListTransSaveSlice.reducer,
  salesListTransTmp: salesListTransTmpSlice.reducer,
  salesListTransReport11Save: salesListTransReport11SaveSlice.reducer,
  salesListTransReport11Tmp: salesListTransReport11TmpSlice.reducer,
  salesListTransReport15Save: salesListTransReport15SaveSlice.reducer,
  salesListTransReport15Tmp: salesListTransReport15TmpSlice.reducer,
  salesListTransReport16Save: salesListTransReport16SaveSlice.reducer,
  salesListTransReport16Tmp: salesListTransReport16TmpSlice.reducer,
  salesListTransReport35Save: salesListTransReport35SaveSlice.reducer,
  salesListTransReport35Tmp: salesListTransReport35TmpSlice.reducer,
  salesListTransReport45Save: salesListTransReport45SaveSlice.reducer,
  salesListTransReport45Tmp: salesListTransReport45TmpSlice.reducer,

  itemListSave: itemListSaveSlice.reducer,
  itemListTmp: itemListTmpSlice.reducer,
  itemListDirectSave: itemListDirectSaveSlice.reducer,
  itemListDirectTmp:  itemListDirectTmpSlice.reducer,
  itemListReportSSave: itemListReportSSaveSlice.reducer,
  itemListReportSTmp: itemListReportSTmpSlice.reducer,
  itemListReportCutSave: itemListReportCutSaveSlice.reducer,
  itemListReportCutTmp: itemListReportCutTmpSlice.reducer,
  salesItemListSave: salesItemListSaveSlice.reducer,
  salesItemListTmp: salesItemListTmpSlice.reducer,
  keppinItemListSave: keppinItemListSaveSlice.reducer,
  keppinItemListTmp: keppinItemListTmpSlice.reducer,

  itemListTransSave: itemListTransSaveSlice.reducer,
  itemListTransTmp: itemListTransTmpSlice.reducer,

  itemListZaikoSave: itemListZaikoSaveSlice.reducer,
  itemListZaikoTmp: itemListZaikoTmpSlice.reducer,
  itemListGroup2Save: itemListGroup2SaveSlice.reducer,
  itemListGroup2Tmp: itemListGroup2TmpSlice.reducer,
  itemListTokuisakiSave: itemListTokuisakiSaveSlice.reducer,
  itemListTokuisakiTmp: itemListTokuisakiTmpSlice.reducer,

  outputDataTeibanSave: outputDataTeibanSaveSlice.reducer,
  outputDataTeibanTmp: outputDataTeibanTmpSlice.reducer,

  outputDataTokuisakiSave: outputDataTokuisakiSaveSlice.reducer,
  outputDataTokuisakiTmp: outputDataTokuisakiTmpSlice.reducer,

  tokubaiInputSave: tokubaiInputSaveSlice.reducer,
  tokubaiInputTmp: tokubaiInputTmpSlice.reducer,
  tokubaiManageSave: tokubaiManageSaveSlice.reducer,
  tokubaiManageTmp: tokubaiManageTmpSlice.reducer,
  tokubaiProgressSave: tokubaiProgressSaveSlice.reducer,
  tokubaiProgressTmp: tokubaiProgressTmpSlice.reducer,
  tokubaiSettingSave: tokubaiSettingSaveSlice.reducer,
  tokubaiSettingTmp: tokubaiSettingTmpSlice.reducer,

  outputDataSupplierSave: outputDataSupplierSaveSlice.reducer,
  outputDataSupplierTmp: outputDataSupplierTmpSlice.reducer,
  outputDataStoreSave: outputDataStoreSaveSlice.reducer,
  outputDataStoreTmp: outputDataStoreTmpSlice.reducer,
  outputDataZaikoSave: outputDataZaikoSaveSlice.reducer,
  outputDataZaikoTmp: outputDataZaikoTmpSlice.reducer,
  outputDataShodanSave: outputDataShodanSaveSlice.reducer,
  outputDataShodanTmp: outputDataShodanTmpSlice.reducer,
  outputDataCenterSave: outputDataCenterSaveSlice.reducer,
  outputDataCenterTmp: outputDataCenterTmpSlice.reducer,

  zaikoSummarySave: zaikoSummarySaveSlice.reducer,
  zaikoSummaryTmp: zaikoSummaryTmpSlice.reducer,  

  zaikoRealSave: zaikoRealSaveSlice.reducer,
  zaikoRealTmp: zaikoRealTmpSlice.reducer,  

  zaikoKigenSave: zaikoKigenSaveSlice.reducer,
  zaikoKigenTmp: zaikoKigenTmpSlice.reducer,  
});

export type RootState = ReturnType<typeof rootReducer>;

/*永続化保存設定 */

// // HACK: `redux-persist failed to create sync storage. falling back to noop storage.`の対応
// // https://github.com/vercel/next.js/discussions/15687#discussioncomment-45319
const createNoopStorage = () => {
  return {
    getItem() {
      return Promise.resolve(null)
    },
    setItem(_key, value) {
      return Promise.resolve(value)
    },
    removeItem() {
      return Promise.resolve()
    },
  }
}
const storage =
  typeof window !== 'undefined'
    ? createWebStorage('local')
    : createNoopStorage()
;
//通常時
const persistConfigDefault = {
  //localstorage key
  key: process.env.NEXT_PUBLIC_STORAGE_KEY,
  version: 1,
  //localstorage
  storage,
  //保存対象
  whitelist: [
    userSaveSlice.name,

    xraySaveSlice.name,

    commonSaveSlice.name,
    salesListSaveSlice.name,
    salesListReport10SaveSlice.name,
    salesListReport20SaveSlice.name,
    salesListReport30SaveSlice.name,
    salesListReport40SaveSlice.name,

    salesListTransSaveSlice.name,
    salesListTransReport11SaveSlice.name,
    salesListTransReport15SaveSlice.name,
    salesListTransReport35SaveSlice.name,
    salesListTransReport45SaveSlice.name,

    itemListSaveSlice.name,
    itemListReportSSaveSlice.name,
    itemListReportCutSaveSlice.name,
    salesItemListSaveSlice.name,

    itemListTransSaveSlice.name,

    itemListZaikoSaveSlice.name,
    itemListGroup2SaveSlice.name,
    itemListTokuisakiSaveSlice.name,

    outputDataTeibanSaveSlice.name,

    outputDataTokuisakiSaveSlice.name,

    tokubaiInputSaveSlice.name,
    tokubaiManageSaveSlice.name,
    tokubaiProgressSaveSlice.name,
    tokubaiSettingSaveSlice.name,

    outputDataSupplierSaveSlice.name,
    outputDataStoreSaveSlice.name,
    outputDataZaikoSaveSlice.name,
    outputDataShodanSaveSlice.name,
    outputDataCenterSaveSlice.name,

    zaikoSummarySaveSlice.name,

    zaikoRealSaveSlice.name,

    zaikoKigenSaveSlice.name,
  ],
  timeout: 500, //500msec
};
//URL共有用
const persistConfigShare = {
  //localstorage key
  key: process.env.NEXT_PUBLIC_STORAGE_KEY + '-share',
  version: 1,
  //localstorage
  storage,
  //保存対象
  whitelist: [
  ],
  timeout: 500, //500msec
};


interface ExtendedWindow extends Window {
  __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
}
declare let window: ExtendedWindow;
const composeReduxDevToolsEnhancers = typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const middlewares = [];
const makeStoreInner = (share:boolean) => {
  const persistedReducer = persistReducer(share ? persistConfigShare : persistConfigDefault, rootReducer);

  return createStore(persistedReducer,composeReduxDevToolsEnhancers(applyMiddleware(...middlewares)));
};

// Reduxのstoreはsingletonが推奨されている
// https://redux.js.org/faq/store-setup#store-setup
// https://qiita.com/nouka/items/74a2784598e7bd5554cd#%E3%82%A2%E3%83%97%E3%83%AA%E3%81%AB%E5%AF%BE%E3%81%97%E3%81%A6-1-%E3%81%A4%E3%81%AE-redux-store-%E3%82%92%E6%8C%81%E3%81%A4%E3%81%93%E3%81%A8
// shareフラグが同一であれば、以前作成したStoreを返す。
let storeCache = null as null | { share: boolean, store: ReturnType<typeof makeStoreInner> };
export const makeStore = (share:boolean) => {
  if (storeCache !== null && storeCache.share === share) {
    return storeCache.store;
  }
  const store = makeStoreInner(share);
  storeCache = {
    share,
    store,
  }
  return store;
}
