//行固定
export const fixedRowsTop = 1;
//列固定
export const fixedColumnsLeft = 4;

//テーブルの各列のキー
export const colKeys: string[] = [
  'KGC2', 'KGG2',
  'KGC1', 'KGG1',
  'HQTY1', 'HQTY2', 'HQTY3', 'HQTY4', 'HQTY', 'HQTY_PS',
  'HKIN1', 'HKIN2', 'HKIN3', 'HKIN4', 'HKIN',
];

//テーブルの各列のキー逆引き
const colKeysMap = {};
colKeys.forEach((colKey, index) => {
  colKeysMap[colKey] = index;
});

export const columnNames = [
  'CD',
  '名称',
  'CD',
  '名称',
  '1.一般', '2.特売', '3.予約', '4.出切', '販売BL数', '販売ﾊﾞﾗ数',
  '1.一般', '2.特売', '3.予約', '4.出切', '販売金額',
];

export const nestedHeaders = [
  [
    { label: '企業グループ2', colspan: 2 },
    { label: '企業グループ1', colspan: 2 },
    { label: '売上BL数 (%YYM%)', colspan: 6 },
    { label: '売上金額 (%YYM%)', colspan: 5 },
  ],
  columnNames,
];

export const headerClasses = [
  {
    KGC2: "itemlist_H", KGG2: "itemlist_H", KGC1: "itemlist_H", KGG1: "itemlist_H",
    //以下に貼り付け
    HQTY1: "itemlist_C", HQTY2: "itemlist_C", HQTY3: "itemlist_C", HQTY4: "itemlist_C", HQTY: "itemlist_C", HQTY_PS: "itemlist_C",
    HKIN1: "itemlist_C", HKIN2: "itemlist_C", HKIN3: "itemlist_C", HKIN4: "itemlist_C", HKIN: "itemlist_C",
  },
  {
    KGC2: "itemlist_H", KGG2: "itemlist_H", KGC1: "itemlist_H", KGG1: "itemlist_H",
    //以下に貼り付け
    HQTY1: "itemlist_C", HQTY2: "itemlist_C", HQTY3: "itemlist_C", HQTY4: "itemlist_C", HQTY: "itemlist_C", HQTY_PS: "itemlist_C",
    HKIN1: "itemlist_C", HKIN2: "itemlist_C", HKIN3: "itemlist_C", HKIN4: "itemlist_C", HKIN: "itemlist_C",
  },
]

export const colWidths = [
  60, 150,
  60, 150,
  80, 80, 80, 80, 80, 80, 
  80, 80, 80, 80, 80, 
];

export const columnTypes = [
  { data: 'KGC2', type: 'text', readOnly: true, className: 'htCenter' },
  { data: 'KGG2', type: 'text', readOnly: true, },
  { data: 'KGC1', type: 'text', readOnly: true, className: 'htCenter' },
  { data: 'KGG1', type: 'text', readOnly: true, },

  { data: 'HQTY1', type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0.0', }, },
  { data: 'HQTY2', type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0.0', }, },
  { data: 'HQTY3', type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0.0', }, },
  { data: 'HQTY4', type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0.0', }, },
  { data: 'HQTY', type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0.0', }, },
  { data: 'HQTY_PS', type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, },

  { data: 'HKIN1', type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, },
  { data: 'HKIN2', type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, },
  { data: 'HKIN3', type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, },
  { data: 'HKIN4', type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, },
  { data: 'HKIN', type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, },

];

//function

export const keyFromCol = (column: number): string => {
  return colKeys[column];
}
export const keyFromCols = (columns: number[]): string[] => {
  const vals: string[] = [];
  columns.forEach(column => vals.push(keyFromCol(column)))
  return vals;
}
export const colFromKey = (colKey: string): number => {
  return colKeysMap[colKey];
}
export const colFromKeys = (colKeys: string[]): number[] => {
  const vals: number[] = [];
  colKeys.forEach(colKey => vals.push(colFromKey(colKey)))
  return vals;
}

