import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RequestParam } from "@/assets/apitype/outputDataListTeiban";
import { CodeName } from "@/store/common";
import moment from 'moment';

//定番マスタ
export interface RowDataTeiban {
  no?: string,
  VNM?: string | null,
  VCD?: string | null,
  THC?: string | null,
  ICD?: string | null,
  JAN?: string | null,
  ITF?: string | null,
  MKN?: string | null,
  PRC?: number | null,
  INM?: string | null,
  KKK?: string | null,
  NPR?: number | null,
  KPR?: number | null,
  JTN?: number | null,
  CAT?: string | null,
  CP1?: number | null,
  CP2?: number | null,
  CSY?: number | null,
  CSX?: number | null,
  CSZ?: number | null,
  CSW?: number | null,
  TPY?: number | null,
  TPX?: number | null,
  TPZ?: number | null,
  TPW?: number | null,
  LMT?: number | null,
  SDT?: number | null,
  CDT?: number | null,
  EDT?: number | null,
  RSD?: number | null,
  RED?: number | null,
  LDD?: number | null,
  TSD?: number | null,
  TED?: number | null,
  HKN?: number | null,
  SKK?: number | null,
}


//Page State
export type OutputDataTeibanTmpState = {
  //倉庫
  centerList: CodeName[],
  //得意先
  tokuisakiList: CodeName[],

  accordionOpenTeiban: boolean,
  //検索する条件
  requestParamsTeiban : RequestParam[],
  //検索終了した条件
  retrievedIDs: string[],

  progress: Record<string, unknown>,
  datasTeiban: RowDataTeiban[][],

  vendorIsIME: boolean,

  errorMessage: string | null,
};

export const initialState: OutputDataTeibanTmpState = {
  //倉庫
  centerList: [],
  //得意先
  tokuisakiList: [],

  accordionOpenTeiban: true,
  requestParamsTeiban : [],
  retrievedIDs: [],

  progress: {},
  datasTeiban: [],

  vendorIsIME: false,

  errorMessage: null,
};

//Page Slice
const createSliceContent = (name:string) => createSlice({
  name: name,
  initialState,
  reducers: {
    setCenterList(state:OutputDataTeibanTmpState, action: PayloadAction<CodeName[]>) {
      state.centerList = action.payload;
    },
    setTokuisakiList(state:OutputDataTeibanTmpState, action: PayloadAction<CodeName[]>) {
      state.tokuisakiList = action.payload;
    },

    setAccordionOpenTeiban(state:OutputDataTeibanTmpState, action: PayloadAction<boolean>) {
      state.accordionOpenTeiban = action.payload;
    },
    //検索条件
    setRequestParamsTeiban(state:OutputDataTeibanTmpState, action: PayloadAction<RequestParam[]>) {
      state.requestParamsTeiban = action.payload;
    },
    setRetrievedID(state:OutputDataTeibanTmpState, action: PayloadAction<string>) {
      state.retrievedIDs = [action.payload];
    },
    addRetrievedID(state:OutputDataTeibanTmpState, action: PayloadAction<string>) {
      const retrievedIDs = [...state.retrievedIDs];
      retrievedIDs.push(action.payload);
      state.retrievedIDs = retrievedIDs;
    },

    putProgress(state:OutputDataTeibanTmpState, action: PayloadAction<string>) {
      const key = action.payload;
      const progressNew = {...state.progress};
      progressNew[key] = true;
      state.progress = progressNew;
    },
    removeProgress(state:OutputDataTeibanTmpState, action: PayloadAction<string>) {
      const key = action.payload;
      const progressNew = {};
      Object.keys(state.progress).forEach(k => {
        if(key != k) {
          progressNew[k] = true;
        }
      })
      state.progress = progressNew;
    },
    //定番マスタ
    searchedTeiban(state:OutputDataTeibanTmpState, action: PayloadAction<{params: RequestParam[], datas:RowDataTeiban[][]}>) {
      //計算
      let datas = action.payload.datas;

      datas = parseDataTeiban(datas);

      datas = doSortTeiban(datas);  //ソート
      datas = resetRowNoTeiban(datas); //列番号の振りなおし

      state.datasTeiban = datas;
    },
    setDatasTeiban(state:OutputDataTeibanTmpState, action: PayloadAction<RowDataTeiban[]>) {
      const datas = action.payload;
      Object.assign(state, {
        datasTeiban: datas,
      });
    },
    setVendorIsIME(state: OutputDataTeibanTmpState, action: PayloadAction<boolean>) {
      state.vendorIsIME = action.payload;
    },
    setErrorMessage(state:OutputDataTeibanTmpState, action: PayloadAction<string>) {
      state.errorMessage = action.payload;
    }
  },
});

//定番マスタ
//数値のパース(数値が文字列で返ってくる)
const parseDataTeiban = (datas:RowDataTeiban[][]): RowDataTeiban[][] => {
  //set No.
  datas.forEach(dd => {
    dd.forEach(data => {
      //ここに貼り付け
      if(typeof data.PRC === 'string') data.PRC = parseFloat(data.PRC);
      if(typeof data.NPR === 'string') data.NPR = parseFloat(data.NPR);
      if(typeof data.KPR === 'string') data.KPR = parseFloat(data.KPR);
      if(typeof data.JTN === 'string') data.JTN = parseFloat(data.JTN);
      if(typeof data.CP1 === 'string') data.CP1 = parseFloat(data.CP1);
      if(typeof data.CP2 === 'string') data.CP2 = parseFloat(data.CP2);
      if(typeof data.CSY === 'string') data.CSY = parseFloat(data.CSY);
      if(typeof data.CSX === 'string') data.CSX = parseFloat(data.CSX);
      if(typeof data.CSZ === 'string') data.CSZ = parseFloat(data.CSZ);
      if(typeof data.CSW === 'string') data.CSW = parseFloat(data.CSW);
      if(typeof data.TPY === 'string') data.TPY = parseFloat(data.TPY);
      if(typeof data.TPX === 'string') data.TPX = parseFloat(data.TPX);
      if(typeof data.TPZ === 'string') data.TPZ = parseFloat(data.TPZ);
      if(typeof data.TPW === 'string') data.TPW = parseFloat(data.TPW);
      if(typeof data.LMT === 'string') data.LMT = parseFloat(data.LMT);
      if(typeof data.SDT === 'string') data.SDT = parseFloat(data.SDT);
      if(typeof data.CDT === 'string') data.CDT = parseFloat(data.CDT);
      if(typeof data.EDT === 'string') data.EDT = parseFloat(data.EDT);
      if(typeof data.RSD === 'string') data.RSD = parseFloat(data.RSD);
      if(typeof data.RED === 'string') data.RED = parseFloat(data.RED);
      if(typeof data.LDD === 'string') data.LDD = parseFloat(data.LDD);
      if(typeof data.TSD === 'string') data.TSD = parseInt(moment(data.TSD).format('YYYYMMDD'));
      if(typeof data.TED === 'string') data.TED = parseInt(moment(data.TED).format('YYYYMMDD'));
      if(typeof data.HKN === 'string') data.HKN = parseFloat(data.HKN);
      if(typeof data.SKK === 'string') data.SKK = parseFloat(data.SKK);
    });
  });
  return datas;
}

//no振りなおし
//no振りなおし
const resetRowNoTeiban = (datas:RowDataTeiban[][]): RowDataTeiban[][] => {
  let no = 1;
  datas.map((row) => {
    return {
      ...row,
      no: "" + (no++),
    }
  });
  return datas;
}

//ソート
const doSortTeiban = (datas:RowDataTeiban[][]): RowDataTeiban[][] => {
  return datas;
}


//Page Slice Export
//outputDataTeibanSave
export const outputDataTeibanTmpSlice = createSliceContent("outputDataTeibanTmp");
