import * as arrayUtil from "@/util/arrayUtil";
//行固定
export const fixedRowsTop = 1;

export const drillDownMaxLv = 8;
export const drillDownTotalMaxLv = ['total', 'lv1', 'lv2', 'lv3', 'lv4', 'lv5', 'lv6', 'lv7', 'lv8'];
export const drillDownLv1MaxLv = ['lv1', 'lv2', 'lv3', 'lv4', 'lv5', 'lv6', 'lv7', 'lv8'];
export const drillDownLv2MaxLv = ['lv2', 'lv3', 'lv4', 'lv5', 'lv6', 'lv7', 'lv8'];
export const bubbleChartCount = 10; //複数選択可能数

export const headers = [
  {
    no : "",
    //以下に貼り付け
    H1CD: "集計軸", H1NM: "集計軸", H2CD: "集計軸", H2NM: "集計軸", H3CD: "集計軸", H3NM: "集計軸", H4CD: "集計軸", H4NM: "集計軸", H5CD: "集計軸", H5NM: "集計軸", H6CD: "集計軸", H6NM: "集計軸", H7CD: "集計軸", H7NM: "集計軸", H8CD: "集計軸", H8NM: "集計軸",
    CDIV: "集計軸",
    TDIV: "集計軸",
    JJT: "受注", JJTK: "受注", JJP: "受注", JJPK: "受注", JJR: "受注", JJD: "受注", 
    JKT: "欠品", JKTK: "欠品", JKP: "欠品", JKPK: "欠品", JKR: "欠品", JKD: "欠品", 
    JRT: "欠品", JRP: "欠品", JRD: "欠品", 
    //欠品責任別
    KI1KA: "欠品責任別", KI1KR: "欠品責任別", KI1KK: "欠品責任別", 
    KI2KA: "欠品責任別", KI2KR: "欠品責任別", KI2KK: "欠品責任別", 
    KI3KA: "欠品責任別", KI3KR: "欠品責任別", KI3KK: "欠品責任別", 
    //欠品区分別
    K10KA: "欠品区分別(1.自社責)", K10KR: "欠品区分別(1.自社責)", K10KK: "欠品区分別(1.自社責)", 
    K11KA: "欠品区分別(1.自社責)", K11KR: "欠品区分別(1.自社責)", K11KK: "欠品区分別(1.自社責)", 
    K12KA: "欠品区分別(1.自社責)", K12KR: "欠品区分別(1.自社責)", K12KK: "欠品区分別(1.自社責)", 
    K13KA: "欠品区分別(1.自社責)", K13KR: "欠品区分別(1.自社責)", K13KK: "欠品区分別(1.自社責)", 
    K14KA: "欠品区分別(1.自社責)", K14KR: "欠品区分別(1.自社責)", K14KK: "欠品区分別(1.自社責)", 
    K15KA: "欠品区分別(1.自社責)", K15KR: "欠品区分別(1.自社責)", K15KK: "欠品区分別(1.自社責)", 
    K16KA: "欠品区分別(1.自社責)", K16KR: "欠品区分別(1.自社責)", K16KK: "欠品区分別(1.自社責)", 
    K17KA: "欠品区分別(1.自社責)", K17KR: "欠品区分別(1.自社責)", K17KK: "欠品区分別(1.自社責)", 
    K19KA: "欠品区分別(1.自社責)", K19KR: "欠品区分別(1.自社責)", K19KK: "欠品区分別(1.自社責)", 
    K20KA: "欠品区分別(2.メーカー責)", K20KR: "欠品区分別(2.メーカー責)", K20KK: "欠品区分別(2.メーカー責)", 
    K21KA: "欠品区分別(2.メーカー責)", K21KR: "欠品区分別(2.メーカー責)", K21KK: "欠品区分別(2.メーカー責)", 
    K22KA: "欠品区分別(2.メーカー責)", K22KR: "欠品区分別(2.メーカー責)", K22KK: "欠品区分別(2.メーカー責)", 
    K29KA: "欠品区分別(2.メーカー責)", K29KR: "欠品区分別(2.メーカー責)", K29KK: "欠品区分別(2.メーカー責)", 
    K30KA: "欠品区分別(3.得意先責)", K30KR: "欠品区分別(3.得意先責)", K30KK: "欠品区分別(3.得意先責)", 
    K31KA: "欠品区分別(3.得意先責)", K31KR: "欠品区分別(3.得意先責)", K31KK: "欠品区分別(3.得意先責)", 
    K32KA: "欠品区分別(3.得意先責)", K32KR: "欠品区分別(3.得意先責)", K32KK: "欠品区分別(3.得意先責)", 
    K33KA: "欠品区分別(3.得意先責)", K33KR: "欠品区分別(3.得意先責)", K33KK: "欠品区分別(3.得意先責)", 
    K41KA: "欠品区分別(3.得意先責)", K41KR: "欠品区分別(3.得意先責)", K41KK: "欠品区分別(3.得意先責)", 
    K34KA: "欠品区分別(3.得意先責)", K34KR: "欠品区分別(3.得意先責)", K34KK: "欠品区分別(3.得意先責)", 
    K39KA: "欠品区分別(3.得意先責)", K39KR: "欠品区分別(3.得意先責)", K39KK: "欠品区分別(3.得意先責)", 
    K40KA: "欠品区分別(3.得意先責)", K40KR: "欠品区分別(3.得意先責)", K40KK: "欠品区分別(3.得意先責)", 
    K42KA: "欠品区分別(3.得意先責)", K42KR: "欠品区分別(3.得意先責)", K42KK: "欠品区分別(3.得意先責)", 
    K43KA: "欠品区分別(3.得意先責)", K43KR: "欠品区分別(3.得意先責)", K43KK: "欠品区分別(3.得意先責)", 
    K44KA: "欠品区分別(3.得意先責)", K44KR: "欠品区分別(3.得意先責)", K44KK: "欠品区分別(3.得意先責)", 
    K49KA: "欠品区分別(3.得意先責)", K49KR: "欠品区分別(3.得意先責)", K49KK: "欠品区分別(3.得意先責)", 
    K50KA: "欠品区分別(3.得意先責)", K50KR: "欠品区分別(3.得意先責)", K50KK: "欠品区分別(3.得意先責)", 

    SLT: "総売上",	SLTK: "総売上",	SLP: "総売上",	SLPK: "総売上",	SLR: "総売上",	SLD: "総売上",			
    SDT: "総売上",	SDTK: "総売上",	SDP: "総売上",	SDPK: "総売上",	SDR: "総売上",	SDD: "総売上",			
    SRT: "総売上",	SRTK: "総売上",	SRP: "総売上",	SRPK: "総売上",	SRR: "総売上",	SRD: "総売上",			
    STT: "総売上",	STTK: "総売上",	STP: "総売上",	STPK: "総売上",	STR: "総売上",	STD: "総売上",	STB: "総売上",	STBK: "総売上",	STBR: "総売上",	STBD: "総売上",
    PBLT: "PB売上",	PBLTK: "PB売上",	PBLP: "PB売上",	PBLPK: "PB売上",	PBLR: "PB売上",	PBLD: "PB売上",			
    PBDT: "PB売上",	PBDTK: "PB売上",	PBDP: "PB売上",	PBDPK: "PB売上",	PBDR: "PB売上",	PBDD: "PB売上",			
    PBRT: "PB売上",	PBRTK: "PB売上",	PBRP: "PB売上",	PBRPK: "PB売上",	PBRR: "PB売上",	PBRD: "PB売上",			
    PBTT: "PB売上",	PBTTK: "PB売上",	PBTP: "PB売上",	PBTPK: "PB売上",	PBTR: "PB売上",	PBTD: "PB売上",			
    PBHT: "PB売上",	PBHTK: "PB売上",	PBHP: "PB売上",	PBHPK: "PB売上",	PBHD: "PB売上",			
    YHLT: "焼半生売上", YHLTK: "焼半生売上", YHLP: "焼半生売上", YHLPK: "焼半生売上", YHLR: "焼半生売上", YHLD: "焼半生売上",
    YHDT: "焼半生売上", YHDTK: "焼半生売上", YHDP: "焼半生売上", YHDPK: "焼半生売上", YHDR: "焼半生売上", YHDD: "焼半生売上",
    YHRT: "焼半生売上", YHRTK: "焼半生売上", YHRP: "焼半生売上", YHRPK: "焼半生売上", YHRR: "焼半生売上", YHRD: "焼半生売上",
    YHTT: "焼半生売上", YHTTK: "焼半生売上", YHTP: "焼半生売上", YHTPK: "焼半生売上", YHTR: "焼半生売上", YHTD: "焼半生売上",
    YHHT: "焼半生売上", YHHTK: "焼半生売上", YHHP: "焼半生売上", YHHPK: "焼半生売上", YHHD: "焼半生売上",
    PTT: "売買差益", PTTK: "売買差益", PTP: "売買差益", PTPK: "売買差益", PTR: "売買差益", PTD: "売買差益", PTB: "売買差益", PTBK: "売買差益", PTBR: "売買差益", PTBD: "売買差益",	
    PRT: "売買差益",	PRTK: "売買差益",	PRP: "売買差益",	PRPK: "売買差益",	PRD: "売買差益",				
    DDT: "値引",	DDTK: "値引",	DDP: "値引",	DDPK: "値引",	DDR: "値引",	DDD: "値引",			
    DBT: "値引",	DBTK: "値引",	DBP: "値引",	DBPK: "値引",	DBR: "値引",	DBD: "値引",			
    DQT: "値引",	DQTK: "値引",	DQP: "値引",	DQPK: "値引",	DQR: "値引",	DQD: "値引",			
    DFT: "値引",	DFTK: "値引",	DFP: "値引",	DFPK: "値引",	DFR: "値引",	DFD: "値引",			
    DTT: "値引",	DTTK: "値引",	DTP: "値引",	DTPK: "値引",	DTR: "値引",	DTD: "値引",	DTB: "値引",	DTBK: "値引",	DTBR: "値引",	DTBD: "値引",
    DRT: "値引",	DRTK: "値引",	DRP: "値引",	DRPK: "値引",	DRD: "値引",				
    NTT: "純売上",	NTTK: "純売上",	NTP: "純売上",	NTPK: "純売上",	NTR: "純売上",	NTD: "純売上",	NTB: "純売上",	NTBK: "純売上",	NTBR: "純売上",	NTBD: "純売上",
    CIT: "修正",	CITK: "修正",	CIP: "修正",	CIPK: "修正",	CIR: "修正",	CID: "修正",			
    CST: "修正",	CSTK: "修正",	CSP: "修正",	CSPK: "修正",	CSR: "修正",	CSD: "修正",			
    CTT: "修正",	CTTK: "修正",	CTP: "修正",	CTPK: "修正",	CTR: "修正",	CTD: "修正",	CTB: "修正",	CTBK: "修正",	CTBR: "修正",	CTBD: "修正",
    OCT: "センターフィ",	OCTK: "センターフィ",	OCP: "センターフィ",	OCPK: "センターフィ",	OCR: "センターフィ",	OCD: "センターフィ",
    TST: "センターフィ",	TSTK: "センターフィ",	TSP: "センターフィ",	TSPK: "センターフィ",	TSR: "センターフィ",	TSD: "センターフィ",			
    CSTT: "センターフィ", CSTTK: "センターフィ", CSTP: "センターフィ", CSTPK: "センターフィ", CSTR: "センターフィ", CSTD: "センターフィ", CSTB: "センターフィ", CSTBR: "センターフィ", CSTBD: "センターフィ",
    CSTRT: "センターフィ", CSTRP: "センターフィ", CSTRD: "センターフィ",
    OPT: "販促費",	OPTK: "販促費",	OPP: "販促費",	OPPK: "販促費",	OPR: "販促費",	OPD: "販促費",
    TPT: "販促費",	TPTK: "販促費",	TPP: "販促費",	TPPK: "販促費",	TPR: "販促費",	TPD: "販促費",			
    PPTT: "販促費", PPTTK: "販促費", PPTP: "販促費", PPTPK: "販促費", PPTR: "販促費", PPTD: "販促費", PPTB: "販促費", PPTBR: "販促費", PPTBD: "販促費",
    PPTRT: "販促費", PPTRP: "販促費", PPTRD: "販促費",
    OET: "ＥＯＳ費・他",	OETK: "ＥＯＳ費・他",	OEP: "ＥＯＳ費・他",	OEPK: "ＥＯＳ費・他",	OER: "ＥＯＳ費・他",	OED: "ＥＯＳ費・他",
    TET: "ＥＯＳ費・他",	TETK: "ＥＯＳ費・他",	TEP: "ＥＯＳ費・他",	TEPK: "ＥＯＳ費・他",	TER: "ＥＯＳ費・他",	TED: "ＥＯＳ費・他",			
    ETT: "ＥＯＳ費・他", ETTK: "ＥＯＳ費・他", ETP: "ＥＯＳ費・他", ETPK: "ＥＯＳ費・他", ETR: "ＥＯＳ費・他", ETD: "ＥＯＳ費・他", ETB: "ＥＯＳ費・他", ETBR: "ＥＯＳ費・他", ETBD: "ＥＯＳ費・他",
    ETRT: "ＥＯＳ費・他", ETRP: "ＥＯＳ費・他", ETRD: "ＥＯＳ費・他",
    KTT: "得意先課金計",	KTTK: "得意先課金計",	KTP: "得意先課金計",	KTPK: "得意先課金計",	KTR: "得意先課金計",	KTD: "得意先課金計",			
    KRT: "得意先課金計",	KRTK: "得意先課金計",	KRP: "得意先課金計",	KRPK: "得意先課金計",	KRD: "得意先課金計",				
    BTT: "売上総利益", BTTK: "売上総利益", BTP: "売上総利益", BTPK: "売上総利益", BTR: "売上総利益", BTD: "売上総利益",
    BRT: "売上総利益", BRTK: "売上総利益", BRP: "売上総利益", BRPK: "売上総利益", BRD: "売上総利益",
    ATT: "売上総利益", ATTK: "売上総利益", ATP: "売上総利益", ATPK: "売上総利益", ATR: "売上総利益", ATD: "売上総利益",
    ART: "売上総利益", ARTK: "売上総利益", ARP: "売上総利益", ARPK: "売上総利益", ARD: "売上総利益",
    GTT: "売上総利益", GTTK: "売上総利益", GTP: "売上総利益", GTPK: "売上総利益", GTR: "売上総利益", GTD: "売上総利益", GTB: "売上総利益", GTBK: "売上総利益", GTBR: "売上総利益", GTBD: "売上総利益",
    GRT: "売上総利益", GRTK: "売上総利益", GRP: "売上総利益", GRPK: "売上総利益", GRD: "売上総利益",
    ZTT: "売上総利益", ZTTK: "売上総利益", ZTP: "売上総利益", ZTPK: "売上総利益", ZTR: "売上総利益", ZTD: "売上総利益", ZTB: "売上総利益", ZTBK: "売上総利益", ZTBR: "売上総利益", ZTBD: "売上総利益",
    ZRT: "売上総利益", ZRTK: "売上総利益", ZRP: "売上総利益", ZRPK: "売上総利益", ZRD: "売上総利益",
    ZCT: "企業粗利",	ZCTK: "企業粗利",	ZCP: "企業粗利",	ZCPK: "企業粗利",	ZCR: "企業粗利",	ZCD: "企業粗利",			
    ZCRT: "企業粗利",	ZCRTK: "企業粗利",	ZCRP: "企業粗利",	ZCRPK: "企業粗利",	ZCRD: "企業粗利",				
    CNDT: "センター経費", CNDP: "センター経費", CNDR: "センター経費", CNDD: "センター経費", CNDRT: "センター経費", CNDRP: "センター経費", CNDRD: "センター経費",
    CNPT: "センター経費", CNPP: "センター経費", CNPR: "センター経費", CNPD: "センター経費", CNPRT: "センター経費", CNPRP: "センター経費", CNPRD: "センター経費",
    CNGT: "センター経費", CNGP: "センター経費", CNGR: "センター経費", CNGD: "センター経費", CNGRT: "センター経費", CNGRP: "センター経費", CNGRD: "センター経費",
    CNAT: "センター経費", CNAP: "センター経費", CNAR: "センター経費", CNAD: "センター経費", CNART: "センター経費", CNARP: "センター経費", CNARD: "センター経費",
    CNET: "センター経費", CNEP: "センター経費", CNER: "センター経費", CNED: "センター経費", CNRT: "センター経費", CNRP: "センター経費", CNRD: "センター経費",
    SNET: "支店経費", SNEP: "支店経費", SNER: "支店経費", SNED: "支店経費", SNRT: "支店経費", SNRP: "支店経費", SNRD: "支店経費",
    ORST: "販管費", ORSP: "販管費", ORSR: "販管費", ORSD: "販管費", ORSRT: "販管費", ORSRP: "販管費", ORSRD: "販管費",
    ORPT: "営業利益", ORPP: "営業利益", ORPR: "営業利益", ORPD: "営業利益", ORPRT: "営業利益", ORPRP: "営業利益", ORPRD: "営業利益",
    SHCT: "仕入実績", SHCP: "仕入実績", SHCR: "仕入実績", SHCD: "仕入実績", 
    SHDT: "仕入実績", SHDP: "仕入実績", SHDR: "仕入実績", SHDD: "仕入実績", 
    SHRCT: "仕入実績", SHRCP: "仕入実績", SHRCR: "仕入実績", SHRCD: "仕入実績", 
    SHRDT: "仕入実績", SHRDP: "仕入実績", SHRDR: "仕入実績", SHRDD: "仕入実績", 
    SHT: "仕入実績", SHP: "仕入実績", SHR: "仕入実績", SHD: "仕入実績", 
    CCDT: "補正",	CCDTK: "補正",	CDDP: "補正",	CDDPK: "補正",	CDDR: "補正",	CDDD: "補正",			
    CDBT: "補正",	CDBTK: "補正",	CDBP: "補正",	CDBPK: "補正",	CDBR: "補正",	CDBD: "補正",			
    CDFT: "補正",	CDFTK: "補正",	CDFP: "補正",	CDFPK: "補正",	CDFR: "補正",	CDFD: "補正",			
    COCT: "補正",	COCTK: "補正",	COCP: "補正",	COCPK: "補正",	COCR: "補正",	COCD: "補正",			
    COPT: "補正",	COPTK: "補正",	COPP: "補正",	COPPK: "補正",	COPR: "補正",	COPD: "補正",			
    COET: "補正",	COETK: "補正",	COEP: "補正",	COEPK: "補正",	COER: "補正",	COED: "補正",			
    CTPT: "補正",	CTPTK: "補正",	CTPP: "補正",	CTPPK: "補正",	CTPR: "補正",	CTPD: "補正",			
    CTST: "補正",	CTSTK: "補正",	CTSP: "補正",	CTSPK: "補正",	CTSR: "補正",	CTSD: "補正",			
    CTET: "補正",	CTETK: "補正",	CTEP: "補正",	CTEPK: "補正",	CTER: "補正",	CTED: "補正",			
  },
  {
    no : "No.",
    //以下に貼り付け
    H1CD: "LV1", H1NM: "LV1", H2CD: "LV2", H2NM: "LV2", H3CD: "LV3", H3NM: "LV3", H4CD: "LV4", H4NM: "LV4", H5CD: "LV5", H5NM: "LV5", H6CD: "LV6", H6NM: "LV6", H7CD: "LV7", H7NM: "LV7", H8CD: "LV8", H8NM: "LV8",
    CDIV: "LV1",
    TDIV: "LV1",
    JJT: "受注額", JJTK: "受注額", JJP: "受注額", JJPK: "受注額", JJR: "受注額", JJD: "受注額", 
    JKT: "欠品額", JKTK: "欠品額", JKP: "欠品額", JKPK: "欠品額", JKR: "欠品額", JKD: "欠品額", 
    JRT: "欠品率", JRP: "欠品率", JRD: "欠品率", 
    //欠品責任別
    KI1KA: '1.自社責', KI1KR: '1.自社責', KI1KK: '1.自社責', 
    KI2KA: '2.メーカー責', KI2KR: '2.メーカー責', KI2KK: '2.メーカー責', 
    KI3KA: '3.得意先責', KI3KR: '3.得意先責', KI3KK: '3.得意先責', 
    //欠品区分別,,
    K10KA: '10.未入力', K10KR: '10.未入力', K10KK: '10.未入力', 
    K11KA: '11.発注漏れ', K11KR: '11.発注漏れ', K11KK: '11.発注漏れ', 
    K12KA: '12.終売案内漏れ', K12KR: '12.終売案内漏れ', K12KK: '12.終売案内漏れ', 
    K13KA: '13.※※使用不可※※', K13KR: '13.※※使用不可※※', K13KK: '13.※※使用不可※※', 
    K14KA: '14.発注数量予測差異', K14KR: '14.発注数量予測差異', K14KK: '14.発注数量予測差異', 
    K15KA: '15.自社出荷ミス', K15KR: '15.自社出荷ミス', K15KK: '15.自社出荷ミス', 
    K16KA: '16.出荷許容切れ', K16KR: '16.出荷許容切れ', K16KK: '16.出荷許容切れ', 
    K17KA: '17.特売連絡漏', K17KR: '17.特売連絡漏', K17KK: '17.特売連絡漏', 
    K19KA: '19.自社その他', K19KR: '19.自社その他', K19KK: '19.自社その他', 
    K20KA: '20.メーカー欠品', K20KR: '20.メーカー欠品', K20KK: '20.メーカー欠品', 
    K21KA: '21.配送', K21KR: '21.配送', K21KK: '21.配送', 
    K22KA: '22.天災', K22KR: '22.天災', K22KK: '22.天災', 
    K29KA: '29.メーカー欠品その他', K29KR: '29.メーカー欠品その他', K29KK: '29.メーカー欠品その他', 
    K30KA: '30.数量訂正・発注取消', K30KR: '30.数量訂正・発注取消', K30KK: '30.数量訂正・発注取消', 
    K31KA: '31.得意先フライング', K31KR: '31.得意先フライング', K31KK: '31.得意先フライング', 
    K32KA: '32.※※使用不可※※', K32KR: '32.※※使用不可※※', K32KK: '32.※※使用不可※※', 
    K33KA: '33.※※使用不可※※', K33KR: '33.※※使用不可※※', K33KK: '33.※※使用不可※※', 
    K41KA: '41.納品日エラー', K41KR: '41.納品日エラー', K41KK: '41.納品日エラー', 
    K34KA: '34.得意先定番外・カット', K34KR: '34.得意先定番外・カット', K34KK: '34.得意先定番外・カット', 
    K39KA: '39.※※使用不可※※', K39KR: '39.※※使用不可※※', K39KK: '39.※※使用不可※※', 
    K40KA: '40.得意先商品CDエラー', K40KR: '40.得意先商品CDエラー', K40KK: '40.得意先商品CDエラー', 
    K42KA: '42.得意先入数エラー', K42KR: '42.得意先入数エラー', K42KK: '42.得意先入数エラー', 
    K43KA: '43.単価エラー', K43KR: '43.単価エラー', K43KK: '43.単価エラー', 
    K44KA: '44.※※使用不可※※', K44KR: '44.※※使用不可※※', K44KK: '44.※※使用不可※※', 
    K49KA: '49.※※使用不可※※', K49KR: '49.※※使用不可※※', K49KK: '49.※※使用不可※※', 
    K50KA: '50.得意先検品ミス', K50KR: '50.得意先検品ミス', K50KK: '50.得意先検品ミス', 
  
    SLT: "配送売上",	SLTK: "配送売上",	SLP: "配送売上",	SLPK: "配送売上",	SLR: "配送売上",	SLD: "配送売上",			
    SDT: "直送売上",	SDTK: "直送売上",	SDP: "直送売上",	SDPK: "直送売上",	SDR: "直送売上",	SDD: "直送売上",			
    SRT: "返品",	SRTK: "返品",	SRP: "返品",	SRPK: "返品",	SRR: "返品",	SRD: "返品",			
    STT: "総売上",	STTK: "総売上",	STP: "総売上",	STPK: "総売上",	STR: "総売上",	STD: "総売上",	STB: "総売上",	STBK: "総売上",	STBR: "総売上",	STBD: "総売上",
    PBLT: "PB配送売上",	PBLTK: "PB配送売上",	PBLP: "PB配送売上",	PBLPK: "PB配送売上",	PBLR: "PB配送売上",	PBLD: "PB配送売上",			
    PBDT: "PB直送売上",	PBDTK: "PB直送売上",	PBDP: "PB直送売上",	PBDPK: "PB直送売上",	PBDR: "PB直送売上",	PBDD: "PB直送売上",			
    PBRT: "PB返品",	PBRTK: "PB返品",	PBRP: "PB返品",	PBRPK: "PB返品",	PBRR: "PB返品",	PBRD: "PB返品",			
    PBTT: "PB売上",	PBTTK: "PB売上",	PBTP: "PB売上",	PBTPK: "PB売上",	PBTR: "PB売上",	PBTD: "PB売上",			
    PBHT: "PB率", PBHTK: "PB率", PBHP: "PB率", PBHPK: "PB率", PBHD: "PB率",
    YHLT: "焼半生配送売上", YHLTK: "焼半生配送売上", YHLP: "焼半生配送売上", YHLPK: "焼半生配送売上", YHLR: "焼半生配送売上", YHLD: "焼半生配送売上",
    YHDT: "焼半生直送売上", YHDTK: "焼半生直送売上", YHDP: "焼半生直送売上", YHDPK: "焼半生直送売上", YHDR: "焼半生直送売上", YHDD: "焼半生直送売上",
    YHRT: "焼半生返品", YHRTK: "焼半生返品", YHRP: "焼半生返品", YHRPK: "焼半生返品", YHRR: "焼半生返品", YHRD: "焼半生返品",
    YHTT: "焼半生売上", YHTTK: "焼半生売上", YHTP: "焼半生売上", YHTPK: "焼半生売上", YHTR: "焼半生売上", YHTD: "焼半生売上",
    YHHT: "焼半生率", YHHTK: "焼半生率", YHHP: "焼半生率", YHHPK: "焼半生率", YHHD: "焼半生率",
    PTT: "売買差益計", PTTK: "売買差益計", PTP: "売買差益計", PTPK: "売買差益計", PTR: "売買差益計", PTD: "売買差益計", PTB: "売買差益計", PTBK: "売買差益計", PTBR: "売買差益計", PTBD: "売買差益計",
    PRT: "売買差益率",	PRTK: "売買差益率",	PRP: "売買差益率",	PRPK: "売買差益率",	PRD: "売買差益率",				
    DDT: "値引",	DDTK: "値引",	DDP: "値引",	DDPK: "値引",	DDR: "値引",	DDD: "値引",			
    DBT: "リベート",	DBTK: "リベート",	DBP: "リベート",	DBPK: "リベート",	DBR: "リベート",	DBD: "リベート",			
    DQT: "リベート建値",	DQTK: "リベート建値",	DQP: "リベート建値",	DQPK: "リベート建値",	DQR: "リベート建値",	DQD: "リベート建値",			
    DFT: "振込リベート",	DFTK: "振込リベート",	DFP: "振込リベート",	DFPK: "振込リベート",	DFR: "振込リベート",	DFD: "振込リベート",			
    DTT: "値引計",	DTTK: "値引計",	DTP: "値引計",	DTPK: "値引計",	DTR: "値引計",	DTD: "値引計",	DTB: "値引計",	DTBK: "値引計",	DTBR: "値引計",	DTBD: "値引計",
    DRT: "値引率",	DRTK: "値引率",	DRP: "値引率",	DRPK: "値引率",	DRD: "値引率",				
    NTT: "純売上",	NTTK: "純売上",	NTP: "純売上",	NTPK: "純売上",	NTR: "純売上",	NTD: "純売上",	NTB: "純売上",	NTBK: "純売上",	NTBR: "純売上",	NTBD: "純売上",
    CIT: "入力修正",	CITK: "入力修正",	CIP: "入力修正",	CIPK: "入力修正",	CIR: "入力修正",	CID: "入力修正",			
    CST: "黒修正",	CSTK: "黒修正",	CSP: "黒修正",	CSPK: "黒修正",	CSR: "黒修正",	CSD: "黒修正",			
    CTT: "修正合計",	CTTK: "修正合計",	CTP: "修正合計",	CTPK: "修正合計",	CTR: "修正合計",	CTD: "修正合計",	CTB: "修正合計",	CTBK: "修正合計",	CTBR: "修正合計",	CTBD: "修正合計",
    OCT: "センターフィ（相殺）",	OCTK: "センターフィ（相殺）",	OCP: "センターフィ（相殺）",	OCPK: "センターフィ（相殺）",	OCR: "センターフィ（相殺）",	OCD: "センターフィ（相殺）",
    TST: "業務委託費（振込）",	TSTK: "業務委託費（振込）",	TSP: "業務委託費（振込）",	TSPK: "業務委託費（振込）",	TSR: "業務委託費（振込）",	TSD: "業務委託費（振込）",			
    CSTT: "センターフィ（合計）", CSTTK: "センターフィ（合計）", CSTP: "センターフィ（合計）", CSTPK: "センターフィ（合計）", CSTR: "センターフィ（合計）", CSTD: "センターフィ（合計）", CSTB: "センターフィ（合計）", CSTBR: "センターフィ（合計）", CSTBD: "センターフィ（合計）",
    CSTRT: "センターフィ率", CSTRP: "センターフィ率", CSTRD: "センターフィ率",
    OPT: "販促費（相殺）",	OPTK: "販促費（相殺）",	OPP: "販促費（相殺）",	OPPK: "販促費（相殺）",	OPR: "販促費（相殺）",	OPD: "販促費（相殺）",
    TPT: "販促費（振込）",	TPTK: "販促費（振込）",	TPP: "販促費（振込）",	TPPK: "販促費（振込）",	TPR: "販促費（振込）",	TPD: "販促費（振込）",			
    PPTT: "販促費（合計）", PPTTK: "販促費（合計）", PPTP: "販促費（合計）", PPTPK: "販促費（合計）", PPTR: "販促費（合計）", PPTD: "販促費（合計）", PPTB: "販促費（合計）", PPTBR: "販促費（合計）", PPTBD: "販促費（合計）",
    PPTRT: "販促費率", PPTRP: "販促費率", PPTRD: "販促費率",
    OET: "ＥＯＳ費・他（相殺）",	OETK: "ＥＯＳ費・他（相殺）",	OEP: "ＥＯＳ費・他（相殺）",	OEPK: "ＥＯＳ費・他（相殺）",	OER: "ＥＯＳ費・他（相殺）",	OED: "ＥＯＳ費・他（相殺）",
    TET: "ＥＯＳ費・他（振込）",	TETK: "ＥＯＳ費・他（振込）",	TEP: "ＥＯＳ費・他（振込）",	TEPK: "ＥＯＳ費・他（振込）",	TER: "ＥＯＳ費・他（振込）",	TED: "ＥＯＳ費・他（振込）",			
    ETT: "ＥＯＳ費・他（合計）", ETTK: "ＥＯＳ費・他（合計）", ETP: "ＥＯＳ費・他（合計）", ETPK: "ＥＯＳ費・他（合計）", ETR: "ＥＯＳ費・他（合計）", ETD: "ＥＯＳ費・他（合計）", ETB: "ＥＯＳ費・他（合計）", ETBR: "ＥＯＳ費・他（合計）", ETBD: "ＥＯＳ費・他（合計）",
    ETRT: "ＥＯＳ費・他率", ETRP: "ＥＯＳ費・他率", ETRD: "ＥＯＳ費・他率",
    KTT: "得意先課金計",	KTTK: "得意先課金計",	KTP: "得意先課金計",	KTPK: "得意先課金計",	KTR: "得意先課金計",	KTD: "得意先課金計",			
    KRT: "得意先課金率",	KRTK: "得意先課金率",	KRP: "得意先課金率",	KRPK: "得意先課金率",	KRD: "得意先課金率",				
    BTT: "修正前企業粗利",	BTTK: "修正前企業粗利",	BTP: "修正前企業粗利",	BTPK: "修正前企業粗利",	BTR: "修正前企業粗利",	BTD: "修正前企業粗利",			
    BRT: "修正前企業粗利率",	BRTK: "修正前企業粗利率",	BRP: "修正前企業粗利率",	BRPK: "修正前企業粗利率",	BRD: "修正前企業粗利率",				
    ATT: "修正後企業粗利",	ATTK: "修正後企業粗利",	ATP: "修正後企業粗利",	ATPK: "修正後企業粗利",	ATR: "修正後企業粗利",	ATD: "修正後企業粗利",			
    ART: "修正後企業粗利率",	ARTK: "修正後企業粗利率",	ARP: "修正後企業粗利率",	ARPK: "修正後企業粗利率",	ARD: "修正後企業粗利率",				
    GTT: "修正後粗利益",	GTTK: "修正後粗利益",	GTP: "修正後粗利益",	GTPK: "修正後粗利益",	GTR: "修正後粗利益",	GTD: "修正後粗利益",	GTB: "修正後粗利益",	GTBK: "修正後粗利益",	GTBR: "修正後粗利益",	GTBD: "修正後粗利益",
    GRT: "修正後粗利益率",	GRTK: "修正後粗利益率",	GRP: "修正後粗利益率",	GRPK: "修正後粗利益率",	GRD: "修正後粗利益率",				
    ZTT: "売上総利益",	ZTTK: "売上総利益",	ZTP: "売上総利益",	ZTPK: "売上総利益",	ZTR: "売上総利益",	ZTD: "売上総利益",	ZTB: "売上総利益",	ZTBK: "売上総利益",	ZTBR: "売上総利益",	ZTBD: "売上総利益",
    ZRT: "売上総利益率",	ZRTK: "売上総利益率",	ZRP: "売上総利益率",	ZRPK: "売上総利益率",	ZRD: "売上総利益率",				
    ZCT: "企業粗利",	ZCTK: "企業粗利",	ZCP: "企業粗利",	ZCPK: "企業粗利",	ZCR: "企業粗利",	ZCD: "企業粗利",			
    ZCRT: "企業粗利率",	ZCRTK: "企業粗利率",	ZCRP: "企業粗利率",	ZCRPK: "企業粗利率",	ZCRD: "企業粗利率",				
    CNDT: "配送費", CNDP: "配送費", CNDR: "配送費", CNDD: "配送費", CNDRT: "配送費率", CNDRP: "配送費率", CNDRD: "配送費率",
    CNPT: "センター人件費", CNPP: "センター人件費", CNPR: "センター人件費", CNPD: "センター人件費", CNPRT: "センター人件費率", CNPRP: "センター人件費率", CNPRD: "センター人件費率",
    CNGT: "一般経費", CNGP: "一般経費", CNGR: "一般経費", CNGD: "一般経費", CNGRT: "一般経費率", CNGRP: "一般経費率", CNGRD: "一般経費率",
    CNAT: "配賦経費", CNAP: "配賦経費", CNAR: "配賦経費", CNAD: "配賦経費", CNART: "配賦経費率", CNARP: "配賦経費率", CNARD: "配賦経費率",
    CNET: "センター経費", CNEP: "センター経費", CNER: "センター経費", CNED: "センター経費", CNRT: "センター経費率", CNRP: "センター経費率", CNRD: "センター経費率",
    SNET: "支店経費", SNEP: "支店経費", SNER: "支店経費", SNED: "支店経費", SNRT: "支店経費率", SNRP: "支店経費率", SNRD: "支店経費率",
    ORST: "販管費", ORSP: "販管費", ORSR: "販管費", ORSD: "販管費", ORSRT: "販管費率", ORSRP: "販管費率", ORSRD: "販管費率",
    ORPT: "営業利益", ORPP: "営業利益", ORPR: "営業利益", ORPD: "営業利益", ORPRT: "営業利益率", ORPRP: "営業利益率", ORPRD: "営業利益率",
    SHCT: "仕入（倉入）", SHCP: "仕入（倉入）", SHCR: "仕入（倉入）", SHCD: "仕入（倉入）", 
    SHDT: "仕入（直送）", SHDP: "仕入（直送）", SHDR: "仕入（直送）", SHDD: "仕入（直送）", 
    SHRCT: "仕入返品（倉入）", SHRCP: "仕入返品（倉入）", SHRCR: "仕入返品（倉入）", SHRCD: "仕入返品（倉入）", 
    SHRDT: "仕入返品（直送）", SHRDP: "仕入返品（直送）", SHRDR: "仕入返品（直送）", SHRDD: "仕入返品（直送）", 
    SHT: "仕入合計", SHP: "仕入合計", SHR: "仕入合計", SHD: "仕入合計",
    CCDT: "値引補正",	CCDTK: "値引補正",	CDDP: "値引補正",	CDDPK: "値引補正",	CDDR: "値引補正",	CDDD: "値引補正",			
    CDBT: "リベート補正",	CDBTK: "リベート補正",	CDBP: "リベート補正",	CDBPK: "リベート補正",	CDBR: "リベート補正",	CDBD: "リベート補正",			
    CDFT: "振込リベート補正",	CDFTK: "振込リベート補正",	CDFP: "振込リベート補正",	CDFPK: "振込リベート補正",	CDFR: "振込リベート補正",	CDFD: "振込リベート補正",			
    COCT: "センターフィ（相殺）補正",	COCTK: "センターフィ（相殺）補正",	COCP: "センターフィ（相殺）補正",	COCPK: "センターフィ（相殺）補正",	COCR: "センターフィ（相殺）補正",	COCD: "センターフィ（相殺）補正",			
    COPT: "販促費（相殺）補正",	COPTK: "販促費（相殺）補正",	COPP: "販促費（相殺）補正",	COPPK: "販促費（相殺）補正",	COPR: "販促費（相殺）補正",	COPD: "販促費（相殺）補正",			
    COET: "ＥＯＳ費・他（相殺）補正",	COETK: "ＥＯＳ費・他（相殺）補正",	COEP: "ＥＯＳ費・他（相殺）補正",	COEPK: "ＥＯＳ費・他（相殺）補正",	COER: "ＥＯＳ費・他（相殺）補正",	COED: "ＥＯＳ費・他（相殺）補正",			
    CTPT: "販促費（振込）補正",	CTPTK: "販促費（振込）補正",	CTPP: "販促費（振込）補正",	CTPPK: "販促費（振込）補正",	CTPR: "販促費（振込）補正",	CTPD: "販促費（振込）補正",			
    CTST: "業務委託費（振込）補正",	CTSTK: "業務委託費（振込）補正",	CTSP: "業務委託費（振込）補正",	CTSPK: "業務委託費（振込）補正",	CTSR: "業務委託費（振込）補正",	CTSD: "業務委託費（振込）補正",			
    CTET: "ＥＯＳ費・他（振込）補正",	CTETK: "ＥＯＳ費・他（振込）補正",	CTEP: "ＥＯＳ費・他（振込）補正",	CTEPK: "ＥＯＳ費・他（振込）補正",	CTER: "ＥＯＳ費・他（振込）補正",	CTED: "ＥＯＳ費・他（振込）補正",			
  },
  {
    no : "",
    //以下に貼り付け
    H1CD: "CD", H1NM: "名称", H2CD: "CD", H2NM: "名称", H3CD: "CD", H3NM: "名称", H4CD: "CD", H4NM: "名称", H5CD: "CD", H5NM: "名称", H6CD: "CD", H6NM: "名称", H7CD: "CD", H7NM: "名称", H8CD: "CD", H8NM: "名称",
    CDIV: "TC/DC",
    TDIV: "自社/委託",
    JJT: "本年実績", JJTK: "本年構成比", JJP: "前年実績", JJPK: "前年構成比", JJR: "前年比", JJD: "前年差", 
    JKT: "本年実績", JKTK: "本年構成比", JKP: "前年実績", JKPK: "前年構成比", JKR: "前年比", JKD: "前年差", 
    JRT: "本年実績", JRP: "前年実績", JRD: "前年差", 
    //欠品責任別
    KI1KA: '欠品額', KI1KR: '欠品率', KI1KK: '欠品構成比', 
    KI2KA: '欠品額', KI2KR: '欠品率', KI2KK: '欠品構成比', 
    KI3KA: '欠品額', KI3KR: '欠品率', KI3KK: '欠品構成比', 
    //欠品区分別,,
    K10KA: '欠品額', K10KR: '欠品率', K10KK: '欠品構成比', 
    K11KA: '欠品額', K11KR: '欠品率', K11KK: '欠品構成比', 
    K12KA: '欠品額', K12KR: '欠品率', K12KK: '欠品構成比', 
    K13KA: '欠品額', K13KR: '欠品率', K13KK: '欠品構成比', 
    K14KA: '欠品額', K14KR: '欠品率', K14KK: '欠品構成比', 
    K15KA: '欠品額', K15KR: '欠品率', K15KK: '欠品構成比', 
    K16KA: '欠品額', K16KR: '欠品率', K16KK: '欠品構成比', 
    K17KA: '欠品額', K17KR: '欠品率', K17KK: '欠品構成比', 
    K19KA: '欠品額', K19KR: '欠品率', K19KK: '欠品構成比', 
    K20KA: '欠品額', K20KR: '欠品率', K20KK: '欠品構成比', 
    K21KA: '欠品額', K21KR: '欠品率', K21KK: '欠品構成比', 
    K22KA: '欠品額', K22KR: '欠品率', K22KK: '欠品構成比', 
    K29KA: '欠品額', K29KR: '欠品率', K29KK: '欠品構成比', 
    K30KA: '欠品額', K30KR: '欠品率', K30KK: '欠品構成比', 
    K31KA: '欠品額', K31KR: '欠品率', K31KK: '欠品構成比', 
    K32KA: '欠品額', K32KR: '欠品率', K32KK: '欠品構成比', 
    K33KA: '欠品額', K33KR: '欠品率', K33KK: '欠品構成比', 
    K41KA: '欠品額', K41KR: '欠品率', K41KK: '欠品構成比', 
    K34KA: '欠品額', K34KR: '欠品率', K34KK: '欠品構成比', 
    K39KA: '欠品額', K39KR: '欠品率', K39KK: '欠品構成比', 
    K40KA: '欠品額', K40KR: '欠品率', K40KK: '欠品構成比', 
    K42KA: '欠品額', K42KR: '欠品率', K42KK: '欠品構成比', 
    K43KA: '欠品額', K43KR: '欠品率', K43KK: '欠品構成比', 
    K44KA: '欠品額', K44KR: '欠品率', K44KK: '欠品構成比', 
    K49KA: '欠品額', K49KR: '欠品率', K49KK: '欠品構成比', 
    K50KA: '欠品額', K50KR: '欠品率', K50KK: '欠品構成比', 

    SLT: "本年実績",	SLTK: "本年構成比",	SLP: "前年実績",	SLPK: "前年構成比",	SLR: "前年比",	SLD: "前年差",			
    SDT: "本年実績",	SDTK: "本年構成比",	SDP: "前年実績",	SDPK: "前年構成比",	SDR: "前年比",	SDD: "前年差",			
    SRT: "本年実績",	SRTK: "本年構成比",	SRP: "前年実績",	SRPK: "前年構成比",	SRR: "前年比",	SRD: "前年差",			
    STT: "本年実績",	STTK: "本年構成比",	STP: "前年実績",	STPK: "前年構成比",	STR: "前年比",	STD: "前年差",	STB: "予算金額",	STBR: "予算達成率",	STBD: "予算差",
    PBLT: "本年実績",	PBLTK: "本年構成比",	PBLP: "前年実績",	PBLPK: "前年構成比",	PBLR: "前年比",	PBLD: "前年差",			
    PBDT: "本年実績",	PBDTK: "本年構成比",	PBDP: "前年実績",	PBDPK: "前年構成比",	PBDR: "前年比",	PBDD: "前年差",			
    PBRT: "本年実績",	PBRTK: "本年構成比",	PBRP: "前年実績",	PBRPK: "前年構成比",	PBRR: "前年比",	PBRD: "前年差",			
    PBTT: "本年実績",	PBTTK: "本年構成比",	PBTP: "前年実績",	PBTPK: "前年構成比",	PBTR: "前年比",	PBTD: "前年差",			
    PBHT: "本年実績",	PBHTK: "本年構成比",	PBHP: "前年実績",	PBHPK: "前年構成比",	PBHD: "前年差",				
    YHLT: "本年実績", YHLTK: "本年構成比", YHLP: "前年実績", YHLPK: "前年構成比", YHLR: "前年比", YHLD: "前年差",
    YHDT: "本年実績", YHDTK: "本年構成比", YHDP: "前年実績", YHDPK: "前年構成比", YHDR: "前年比", YHDD: "前年差",
    YHRT: "本年実績", YHRTK: "本年構成比", YHRP: "前年実績", YHRPK: "前年構成比", YHRR: "前年比", YHRD: "前年差",
    YHTT: "本年実績", YHTTK: "本年構成比", YHTP: "前年実績", YHTPK: "前年構成比", YHTR: "前年比", YHTD: "前年差",
    YHHT: "本年実績", YHHTK: "本年構成比", YHHP: "前年実績", YHHPK: "前年構成比", YHHD: "前年差",
    PTT: "本年実績", PTTK: "本年構成比", PTP: "前年実績", PTPK: "前年構成比", PTR: "前年比", PTD: "前年差", PTB: "予算金額", PTBR: "予算達成率", PTBD: "予算差",	
    PRT: "本年実績",	PRTK: "本年構成比",	PRP: "前年実績",	PRPK: "前年構成比",	PRD: "前年差",				
    DDT: "本年実績",	DDTK: "本年構成比",	DDP: "前年実績",	DDPK: "前年構成比",	DDR: "前年比",	DDD: "前年差",			
    DBT: "本年実績",	DBTK: "本年構成比",	DBP: "前年実績",	DBPK: "前年構成比",	DBR: "前年比",	DBD: "前年差",			
    DQT: "本年実績",	DQTK: "本年構成比",	DQP: "前年実績",	DQPK: "前年構成比",	DQR: "前年比",	DQD: "前年差",			
    DFT: "本年実績",	DFTK: "本年構成比",	DFP: "前年実績",	DFPK: "前年構成比",	DFR: "前年比",	DFD: "前年差",			
    DTT: "本年実績",	DTTK: "本年構成比",	DTP: "前年実績",	DTPK: "前年構成比",	DTR: "前年比",	DTD: "前年差",	DTB: "予算金額",	DTBR: "予算達成率",	DTBD: "予算差",
    DRT: "本年実績",	DRTK: "本年構成比",	DRP: "前年実績",	DRPK: "前年構成比",	DRD: "前年差",				
    NTT: "本年実績",	NTTK: "本年構成比",	NTP: "前年実績",	NTPK: "前年構成比",	NTR: "前年比",	NTD: "前年差",	NTB: "予算金額",	NTBR: "予算達成率",	NTBD: "予算差",
    CIT: "本年実績",	CITK: "本年構成比",	CIP: "前年実績",	CIPK: "前年構成比",	CIR: "前年比",	CID: "前年差",			
    CST: "本年実績",	CSTK: "本年構成比",	CSP: "前年実績",	CSPK: "前年構成比",	CSR: "前年比",	CSD: "前年差",			
    CTT: "本年実績",	CTTK: "本年構成比",	CTP: "前年実績",	CTPK: "前年構成比",	CTR: "前年比",	CTD: "前年差",	CTB: "予算金額",	CTBR: "予算達成率",	CTBD: "予算差",
    OCT: "本年実績", OCTK: "本年構成比", OCP: "前年実績", OCPK: "前年構成比", OCR: "前年比", OCD: "前年差",
    TST: "本年実績",	TSTK: "本年構成比",	TSP: "前年実績",	TSPK: "前年構成比",	TSR: "前年比",	TSD: "前年差",			
    CSTT: "本年実績", CSTTK: "本年構成比", CSTP: "前年実績", CSTPK: "前年構成比", CSTR: "前年比", CSTD: "前年差", CSTB: "予算金額", CSTBR: "予算比", CSTBD: "予算差",
    CSTRT: "本年実績", CSTRP: "前年実績", CSTRD: "前年差",
    OPT: "本年実績", OPTK: "本年構成比", OPP: "前年実績", OPPK: "前年構成比", OPR: "前年比", OPD: "前年差",
    TPT: "本年実績",	TPTK: "本年構成比",	TPP: "前年実績",	TPPK: "前年構成比",	TPR: "前年比",	TPD: "前年差",			
    PPTT: "本年実績", PPTTK: "本年構成比", PPTP: "前年実績", PPTPK: "前年構成比", PPTR: "前年比", PPTD: "前年差", PPTB: "予算金額", PPTBR: "予算比", PPTBD: "予算差",
    PPTRT: "本年実績", PPTRP: "前年実績", PPTRD: "前年差",
    OET: "本年実績", OETK: "本年構成比", OEP: "前年実績", OEPK: "前年構成比", OER: "前年比", OED: "前年差",
    TET: "本年実績",	TETK: "本年構成比",	TEP: "前年実績",	TEPK: "前年構成比",	TER: "前年比",	TED: "前年差",			
    ETT: "本年実績", ETTK: "本年構成比", ETP: "前年実績", ETPK: "前年構成比", ETR: "前年比", ETD: "前年差", ETB: "予算金額", ETBR: "予算比", ETBD: "予算差",
    ETRT: "本年実績", ETRP: "前年実績", ETRD: "前年差",
    KTT: "本年実績",	KTTK: "本年構成比",	KTP: "前年実績",	KTPK: "前年構成比",	KTR: "前年比",	KTD: "前年差",			
    KRT: "本年実績",	KRTK: "本年構成比",	KRP: "前年実績",	KRPK: "前年構成比",	KRD: "前年差",				
    BTT: "本年実績",	BTTK: "本年構成比",	BTP: "前年実績",	BTPK: "前年構成比",	BTR: "前年比",	BTD: "前年差",			
    BRT: "本年実績",	BRTK: "本年構成比",	BRP: "前年実績",	BRPK: "前年構成比",	BRD: "前年差",				
    ATT: "本年実績",	ATTK: "本年構成比",	ATP: "前年実績",	ATPK: "前年構成比",	ATR: "前年比",	ATD: "前年差",			
    ART: "本年実績",	ARTK: "本年構成比",	ARP: "前年実績",	ARPK: "前年構成比",	ARD: "前年差",				
    GTT: "本年実績",	GTTK: "本年構成比",	GTP: "前年実績",	GTPK: "前年構成比",	GTR: "前年比",	GTD: "前年差",	GTB: "予算金額",	GTBR: "予算達成率",	GTBD: "予算差",
    GRT: "本年実績",	GRTK: "本年構成比",	GRP: "前年実績",	GRPK: "前年構成比",	GRD: "前年差",				
    ZTT: "本年実績",	ZTTK: "本年構成比",	ZTP: "前年実績",	ZTPK: "前年構成比",	ZTR: "前年比",	ZTD: "前年差",	ZTB: "予算金額",	ZTBR: "予算達成率",	ZTBD: "予算差",
    ZRT: "本年実績",	ZRTK: "本年構成比",	ZRP: "前年実績",	ZRPK: "前年構成比",	ZRD: "前年差",				
    ZCT: "本年実績",	ZCTK: "本年構成比",	ZCP: "前年実績",	ZCPK: "前年構成比",	ZCR: "前年比",	ZCD: "前年差",			
    ZCRT: "本年実績",	ZCRTK: "本年構成比",	ZCRP: "前年実績",	ZCRPK: "前年構成比",	ZCRD: "前年差",				
    CNDT: "本年実績", CNDP: "前年実績", CNDR: "前年比", CNDD: "前年差", CNDRT: "本年実績", CNDRP: "前年実績", CNDRD: "前年差",
    CNPT: "本年実績", CNPP: "前年実績", CNPR: "前年比", CNPD: "前年差", CNPRT: "本年実績", CNPRP: "前年実績", CNPRD: "前年差",
    CNGT: "本年実績", CNGP: "前年実績", CNGR: "前年比", CNGD: "前年差", CNGRT: "本年実績", CNGRP: "前年実績", CNGRD: "前年差",
    CNAT: "本年実績", CNAP: "前年実績", CNAR: "前年比", CNAD: "前年差", CNART: "本年実績", CNARP: "前年実績", CNARD: "前年差",
    CNET: "本年実績", CNEP: "前年実績", CNER: "前年比", CNED: "前年差", CNRT: "本年実績", CNRP: "前年実績", CNRD: "前年差",
    SNET: "本年実績", SNEP: "前年実績", SNER: "前年比", SNED: "前年差", SNRT: "本年実績", SNRP: "前年実績", SNRD: "前年差",
    ORST: "本年実績", ORSP: "前年実績", ORSR: "前年比", ORSD: "前年差", ORSRT: "本年実績", ORSRP: "前年実績", ORSRD: "前年差",
    ORPT: "本年実績", ORPP: "前年実績", ORPR: "前年比", ORPD: "前年差", ORPRT: "本年実績", ORPRP: "前年実績", ORPRD: "前年差",
    SHCT: "本年実績", SHCP: "前年実績", SHCR: "前年比", SHCD: "前年差",
    SHDT: "本年実績", SHDP: "前年実績", SHDR: "前年比", SHDD: "前年差",
    SHRCT: "本年実績", SHRCP: "前年実績", SHRCR: "前年比", SHRCD: "前年差",
    SHRDT: "本年実績", SHRDP: "前年実績", SHRDR: "前年比", SHRDD: "前年差",
    SHT: "本年実績", SHP: "前年実績", SHR: "前年比", SHD: "前年差",
    CCDT: "本年実績",	CCDTK: "本年構成比",	CDDP: "前年実績",	CDDPK: "前年構成比",	CDDR: "前年比",	CDDD: "前年差",			
    CDBT: "本年実績",	CDBTK: "本年構成比",	CDBP: "前年実績",	CDBPK: "前年構成比",	CDBR: "前年比",	CDBD: "前年差",			
    CDFT: "本年実績",	CDFTK: "本年構成比",	CDFP: "前年実績",	CDFPK: "前年構成比",	CDFR: "前年比",	CDFD: "前年差",			
    COCT: "本年実績",	COCTK: "本年構成比",	COCP: "前年実績",	COCPK: "前年構成比",	COCR: "前年比",	COCD: "前年差",			
    COPT: "本年実績",	COPTK: "本年構成比",	COPP: "前年実績",	COPPK: "前年構成比",	COPR: "前年比",	COPD: "前年差",			
    COET: "本年実績",	COETK: "本年構成比",	COEP: "前年実績",	COEPK: "前年構成比",	COER: "前年比",	COED: "前年差",			
    CTPT: "本年実績",	CTPTK: "本年構成比",	CTPP: "前年実績",	CTPPK: "前年構成比",	CTPR: "前年比",	CTPD: "前年差",			
    CTST: "本年実績",	CTSTK: "本年構成比",	CTSP: "前年実績",	CTSPK: "前年構成比",	CTSR: "前年比",	CTSD: "前年差",			
    CTET: "本年実績",	CTETK: "本年構成比",	CTEP: "前年実績",	CTEPK: "前年構成比",	CTER: "前年比",	CTED: "前年差",			
    //予算構成比
    STBK: "予算構成比", PTBK: "予算構成比", DTBK: "予算構成比", NTBK: "予算構成比", CTBK: "予算構成比", GTBK: "予算構成比", ZTBK: "予算構成比", 
  },
];

export const selectableCol =
{
  no : false,
  //以下に貼り付け
  H1CD: true, H1NM: false, H2CD: true, H2NM: false, H3CD: true, H3NM: false, H4CD: true, H4NM: false, H5CD: true, H5NM: false, H6CD: true, H6NM: false, H7CD: true, H7NM: false, H8CD: true, H8NM: false,
  CDIV: false,
  TDIV: false,
  JJT: true, JJP: true, JJR: true, JJD: true, 
  JKT: true, JKP: true, JKR: true, JKD: true, 
  JRT: true, JRP: true, JRD: true, 
  //欠品責任別
  KI1KA: true, KI1KR: true, KI1KK: true, 
  KI2KA: true, KI2KR: true, KI2KK: true, 
  KI3KA: true, KI3KR: true, KI3KK: true, 
  //欠品区分別,,
  K10KA: true, K10KR: true, K10KK: true, 
  K11KA: true, K11KR: true, K11KK: true, 
  K12KA: true, K12KR: true, K12KK: true, 
  K13KA: true, K13KR: true, K13KK: true, 
  K14KA: true, K14KR: true, K14KK: true, 
  K15KA: true, K15KR: true, K15KK: true, 
  K16KA: true, K16KR: true, K16KK: true, 
  K17KA: true, K17KR: true, K17KK: true, 
  K19KA: true, K19KR: true, K19KK: true, 
  K20KA: true, K20KR: true, K20KK: true, 
  K21KA: true, K21KR: true, K21KK: true, 
  K22KA: true, K22KR: true, K22KK: true, 
  K29KA: true, K29KR: true, K29KK: true, 
  K30KA: true, K30KR: true, K30KK: true, 
  K31KA: true, K31KR: true, K31KK: true, 
  K32KA: true, K32KR: true, K32KK: true, 
  K33KA: true, K33KR: true, K33KK: true, 
  K41KA: true, K41KR: true, K41KK: true, 
  K34KA: true, K34KR: true, K34KK: true, 
  K39KA: true, K39KR: true, K39KK: true, 
  K40KA: true, K40KR: true, K40KK: true, 
  K42KA: true, K42KR: true, K42KK: true, 
  K43KA: true, K43KR: true, K43KK: true, 
  K44KA: true, K44KR: true, K44KK: true, 
  K49KA: true, K49KR: true, K49KK: true, 
  K50KA: true, K50KR: true, K50KK: true, 

  SLT: true, SLP: true, SLR: true, SLD: true, 
  SDT: true, SDP: true, SDR: true, SDD: true, 
  SRT: true, SRP: true, SRR: true, SRD: true, 
  STT: true, STP: true, STR: true, STD: true, STB: true, STBR: true, STBD: true,
  PBLT: true, PBLP: true, PBLR: true, PBLD: true, 
  PBDT: true, PBDP: true, PBDR: true, PBDD: true, 
  PBRT: true, PBRP: true, PBRR: true, PBRD: true, 
  PBTT: true, PBTP: true, PBTR: true, PBTD: true, 
  PBHT: true, PBHP: true, PBHD: true,
  YHLT: true, YHLP: true, YHLR: true, YHLD: true,
  YHDT: true, YHDP: true, YHDR: true, YHDD: true,
  YHRT: true, YHRP: true, YHRR: true, YHRD: true,
  YHTT: true, YHTP: true, YHTR: true, YHTD: true,
  YHHT: true, YHHP: true, YHHD: true,
  PTT: true, PTP: true, PTR: true, PTD: true, PTB: true, PTBR: true, PTBD: true, 
  PRT: true, PRP: true, PRD: true,
  DDT: true, DDP: true, DDR: true, DDD: true, 
  DBT: true, DBP: true, DBR: true, DBD: true, 
  DQT: true, DQP: true, DQR: true, DQD: true, 
  DFT: true, DFP: true, DFR: true, DFD: true, 
  DTT: true, DTP: true, DTR: true, DTD: true, DTB: true, DTBR: true, DTBD: true, 
  DRT: true, DRP: true, DRD: true,
  NTT: true, NTP: true, NTR: true, NTD: true, NTB: true, NTBR: true, NTBD: true,
  CIT: true, CIP: true, CIR: true, CID: true, 
  CST: true, CSP: true, CSR: true, CSD: true, 
  CTT: true, CTP: true, CTR: true, CTD: true, CTB: true, CTBR: true, CTBD: true,
  OCT: true, OCP: true, OCR: true, OCD: true,
  TST: true, TSP: true, TSR: true, TSD: true, 
  CSTT: true, CSTP: true, CSTR: true, CSTD: true, CSTB: true, CSTBR: true, CSTBD: true,
  CSTRT: true, CSTRP: true, CSTRD: true,
  OPT: true, OPP: true, OPR: true, OPD: true,
  TPT: true, TPP: true, TPR: true, TPD: true, 
  PPTT: true, PPTP: true, PPTR: true, PPTD: true, PPTB: true, PPTBR: true, PPTBD: true,
  PPTRT: true, PPTRP: true, PPTRD: true,
  OET: true, OEP: true, OER: true, OED: true,
  TET: true, TEP: true, TER: true, TED: true, 
  ETT: true, ETP: true, ETR: true, ETD: true, ETB: true, ETBR: true, ETBD: true,
  ETRT: true, ETRP: true, ETRD: true,
  KTT: true, KTP: true, KTR: true, KTD: true, 
  KRT: true, KRP: true, KRD: true,
  BTT: true, BTP: true, BTR: true, BTD: true, 
  BRT: true, BRP: true, BRD: true, 
  ATT: true, ATP: true, ATR: true, ATD: true, 
  ART: true, ARP: true, ARD: true, 
  GTT: true, GTP: true, GTR: true, GTD: true, GTB: true, GTBR: true, GTBD: true, 
  GRT: true, GRP: true, GRD: true, 
  ZTT: true, ZTP: true, ZTR: true, ZTD: true, ZTB: true, ZTBR: true, ZTBD: true, 
  ZRT: true, ZRP: true, ZRD: true,
  ZCT: true, ZCP: true, ZCR: true, ZCD: true, 
  ZCRT: true, ZCRP: true, ZCRD: true,
  CNDT: true, CNDP: true, CNDR: true, CNDD: true, CNDRT: true, CNDRP: true, CNDRD: true,
  CNPT: true, CNPP: true, CNPR: true, CNPD: true, CNPRT: true, CNPRP: true, CNPRD: true,
  CNGT: true, CNGP: true, CNGR: true, CNGD: true, CNGRT: true, CNGRP: true, CNGRD: true,
  CNAT: true, CNAP: true, CNAR: true, CNAD: true, CNART: true, CNARP: true, CNARD: true,
  CNET: true, CNEP: true, CNER: true, CNED: true, CNRT: true, CNRP: true, CNRD: true,
  SNET: true, SNEP: true, SNER: true, SNED: true, SNRT: true, SNRP: true, SNRD: true,
  ORST: true, ORSP: true, ORSR: true, ORSD: true, ORSRT: true, ORSRP: true, ORSRD: true,
  ORPT: true, ORPP: true, ORPR: true, ORPD: true, ORPRT: true, ORPRP: true, ORPRD: true,
  SHCT: true, SHCP: true, SHCR: true, SHCD: true, 
  SHDT: true, SHDP: true, SHDR: true, SHDD: true, 
  SHRCT: true, SHRCP: true, SHRCR: true, SHRCD: true, 
  SHRDT: true, SHRDP: true, SHRDR: true, SHRDD: true, 
  SHT: true, SHP: true, SHR: true, SHD: true,
  CCDT: true, CDDP: true, CDDR: true, CDDD: true, 
  CDBT: true, CDBP: true, CDBR: true, CDBD: true, 
  CDFT: true, CDFP: true, CDFR: true, CDFD: true, 
  COCT: true, COCP: true, COCR: true, COCD: true, 
  COPT: true, COPP: true, COPR: true, COPD: true, 
  COET: true, COEP: true, COER: true, COED: true, 
  CTPT: true, CTPP: true, CTPR: true, CTPD: true, 
  CTST: true, CTSP: true, CTSR: true, CTSD: true, 
  CTET: true, CTEP: true, CTER: true, CTED: true, 
  //構成比
  JJTK: true, JJPK: true, 
  JKTK: true, JKPK: true, 
  SLTK: true, SLPK: true, SDTK: true, SDPK: true, SRTK: true, SRPK: true, STTK: true, STPK: true,
  PBLTK: true, PBLPK: true, PBDTK: true, PBDPK: true, PBRTK: true, PBRPK: true, PBTTK: true, PBTPK: true, 
  YHLTK: true, YHLPK: true, YHDTK: true, YHDPK: true, YHRTK: true, YHRPK: true, YHTTK: true, YHTPK: true, 
  PTTK: true, PTPK: true,
  DDTK: true, DDPK: true, DBTK: true, DBPK: true, DQTK: true, DQPK: true, DFTK: true, DFPK: true, DTTK: true, DTPK: true,
  NTTK: true, NTPK: true,
  CITK: true, CIPK: true, CSTK: true, CSPK: true, CTTK: true, CTPK: true, 
  OCTK: true, OCPK: true, 
  TSTK: true, TSPK: true,
  CSTTK: true, CSTPK: true,
  OPTK: true, OPPK: true,
  TPTK: true, TPPK: true,
  PPTTK: true, PPTPK: true,
  OETK: true, OEPK: true,
  TETK: true, TEPK: true,
  ETTK: true, ETPK: true,
  KTTK: true, KTPK: true,
  BTTK: true, BTPK: true,
  ATTK: true, ATPK: true,
  GTTK: true, GTPK: true,
  ZTTK: true, ZTPK: true, ZCTK: true, ZCPK: true,
  CCDTK: true, CDDPK: true, CDBTK: true, CDBPK: true, CDFTK: true, CDFPK: true, COCTK: true, COCPK: true, COPTK: true, COPPK: true, COETK: true, COEPK: true, CTPTK: true, CTPPK: true, CTSTK: true, CTSPK: true, CTETK: true, CTEPK: true,
  //予算構成比
  STBK: true, PTBK: true, DTBK: true, NTBK: true, CTBK: true, GTBK: true, ZTBK: true, 
}

export const headerClasses = [
  {
    no : "saleslist_H",
    //以下に貼り付け
    H1CD: "saleslist_H", H1NM: "saleslist_H", H2CD: "saleslist_H", H2NM: "saleslist_H", H3CD: "saleslist_H", H3NM: "saleslist_H", H4CD: "saleslist_H", H4NM: "saleslist_H", H5CD: "saleslist_H", H5NM: "saleslist_H", H6CD: "saleslist_H", H6NM: "saleslist_H", H7CD: "saleslist_H", H7NM: "saleslist_H", H8CD: "saleslist_H", H8NM: "saleslist_H",
    CDIV: "saleslist_H",
    TDIV: "saleslist_H",
    JJT: "saleslist_J", JJP: "saleslist_J", JJR: "saleslist_J", JJD: "saleslist_J", 
    JKT: "saleslist_K", JKP: "saleslist_K", JKR: "saleslist_K", JKD: "saleslist_K", 
    JRT: "saleslist_K", JRP: "saleslist_K", JRD: "saleslist_K", 
    SHCT: "saleslist_X", SHCP: "saleslist_X", SHCR: "saleslist_X", SHCD: "saleslist_X", 
    SHDT: "saleslist_X", SHDP: "saleslist_X", SHDR: "saleslist_X", SHDD: "saleslist_X", 
    SHRCT: "saleslist_X", SHRCP: "saleslist_X", SHRCR: "saleslist_X", SHRCD: "saleslist_X", 
    SHRDT: "saleslist_X", SHRDP: "saleslist_X", SHRDR: "saleslist_X", SHRDD: "saleslist_X", 
    SHT: "saleslist_X", SHP: "saleslist_X", SHR: "saleslist_X", SHD: "saleslist_X", 
    //欠品責任別
    KI1KA: "saleslist_K2", KI1KR: "saleslist_K2", KI1KK: "saleslist_K2", 
    KI2KA: "saleslist_K2", KI2KR: "saleslist_K2", KI2KK: "saleslist_K2", 
    KI3KA: "saleslist_K2", KI3KR: "saleslist_K2", KI3KK: "saleslist_K2", 
    //欠品区分別,,
    K10KA: "saleslist_K3", K10KR: "saleslist_K3", K10KK: "saleslist_K3",
    K11KA: "saleslist_K3", K11KR: "saleslist_K3", K11KK: "saleslist_K3",
    K12KA: "saleslist_K3", K12KR: "saleslist_K3", K12KK: "saleslist_K3",
    K13KA: "saleslist_K3", K13KR: "saleslist_K3", K13KK: "saleslist_K3",
    K14KA: "saleslist_K3", K14KR: "saleslist_K3", K14KK: "saleslist_K3",
    K15KA: "saleslist_K3", K15KR: "saleslist_K3", K15KK: "saleslist_K3",
    K16KA: "saleslist_K3", K16KR: "saleslist_K3", K16KK: "saleslist_K3",
    K17KA: "saleslist_K3", K17KR: "saleslist_K3", K17KK: "saleslist_K3",
    K19KA: "saleslist_K3", K19KR: "saleslist_K3", K19KK: "saleslist_K3",
    K20KA: "saleslist_K3", K20KR: "saleslist_K3", K20KK: "saleslist_K3",
    K21KA: "saleslist_K3", K21KR: "saleslist_K3", K21KK: "saleslist_K3",
    K22KA: "saleslist_K3", K22KR: "saleslist_K3", K22KK: "saleslist_K3",
    K29KA: "saleslist_K3", K29KR: "saleslist_K3", K29KK: "saleslist_K3",
    K30KA: "saleslist_K3", K30KR: "saleslist_K3", K30KK: "saleslist_K3",
    K31KA: "saleslist_K3", K31KR: "saleslist_K3", K31KK: "saleslist_K3",
    K32KA: "saleslist_K3", K32KR: "saleslist_K3", K32KK: "saleslist_K3",
    K33KA: "saleslist_K3", K33KR: "saleslist_K3", K33KK: "saleslist_K3",
    K41KA: "saleslist_K3", K41KR: "saleslist_K3", K41KK: "saleslist_K3",
    K34KA: "saleslist_K3", K34KR: "saleslist_K3", K34KK: "saleslist_K3",
    K39KA: "saleslist_K3", K39KR: "saleslist_K3", K39KK: "saleslist_K3",
    K40KA: "saleslist_K3", K40KR: "saleslist_K3", K40KK: "saleslist_K3",
    K42KA: "saleslist_K3", K42KR: "saleslist_K3", K42KK: "saleslist_K3",
    K43KA: "saleslist_K3", K43KR: "saleslist_K3", K43KK: "saleslist_K3",
    K44KA: "saleslist_K3", K44KR: "saleslist_K3", K44KK: "saleslist_K3",
    K49KA: "saleslist_K3", K49KR: "saleslist_K3", K49KK: "saleslist_K3",
    K50KA: "saleslist_K3", K50KR: "saleslist_K3", K50KK: "saleslist_K3",

    CCDT: "saleslist_P", CDDP: "saleslist_P", CDDR: "saleslist_P", CDDD: "saleslist_P", 
    CDBT: "saleslist_P", CDBP: "saleslist_P", CDBR: "saleslist_P", CDBD: "saleslist_P", 
    CDFT: "saleslist_P", CDFP: "saleslist_P", CDFR: "saleslist_P", CDFD: "saleslist_P", 
    COCT: "saleslist_P", COCP: "saleslist_P", COCR: "saleslist_P", COCD: "saleslist_P", 
    COPT: "saleslist_P", COPP: "saleslist_P", COPR: "saleslist_P", COPD: "saleslist_P", 
    COET: "saleslist_P", COEP: "saleslist_P", COER: "saleslist_P", COED: "saleslist_P", 
    CTPT: "saleslist_P", CTPP: "saleslist_P", CTPR: "saleslist_P", CTPD: "saleslist_P", 
    CTST: "saleslist_P", CTSP: "saleslist_P", CTSR: "saleslist_P", CTSD: "saleslist_P", 
    CTET: "saleslist_P", CTEP: "saleslist_P", CTER: "saleslist_P", CTED: "saleslist_P",
  },
  {
    no : "saleslist_H",
    //以下に貼り付け
    H1CD: "saleslist_H", H1NM: "saleslist_H", H2CD: "saleslist_H", H2NM: "saleslist_H", H3CD: "saleslist_H", H3NM: "saleslist_H", H4CD: "saleslist_H", H4NM: "saleslist_H", H5CD: "saleslist_H", H5NM: "saleslist_H", H6CD: "saleslist_H", H6NM: "saleslist_H", H7CD: "saleslist_H", H7NM: "saleslist_H", H8CD: "saleslist_H", H8NM: "saleslist_H",
    CDIV: "saleslist_H",
    TDIV: "saleslist_H",
    JJT: "saleslist_J", JJP: "saleslist_J", JJR: "saleslist_J", JJD: "saleslist_J", 
    JKT: "saleslist_K", JKP: "saleslist_K", JKR: "saleslist_K", JKD: "saleslist_K", 
    JRT: "saleslist_K", JRP: "saleslist_K", JRD: "saleslist_K", 
    SHCT: "saleslist_X", SHCP: "saleslist_X", SHCR: "saleslist_X", SHCD: "saleslist_X", 
    SHDT: "saleslist_X", SHDP: "saleslist_X", SHDR: "saleslist_X", SHDD: "saleslist_X", 
    SHRCT: "saleslist_X", SHRCP: "saleslist_X", SHRCR: "saleslist_X", SHRCD: "saleslist_X", 
    SHRDT: "saleslist_X", SHRDP: "saleslist_X", SHRDR: "saleslist_X", SHRDD: "saleslist_X", 
    SHT: "saleslist_X", SHP: "saleslist_X", SHR: "saleslist_X", SHD: "saleslist_X", 
    //欠品責任別
    KI1KA: "saleslist_K2", KI1KR: "saleslist_K2", KI1KK: "saleslist_K2", 
    KI2KA: "saleslist_K2", KI2KR: "saleslist_K2", KI2KK: "saleslist_K2", 
    KI3KA: "saleslist_K2", KI3KR: "saleslist_K2", KI3KK: "saleslist_K2", 
    //欠品区分別,,
    K10KA: "saleslist_K3", K10KR: "saleslist_K3", K10KK: "saleslist_K3",
    K11KA: "saleslist_K3", K11KR: "saleslist_K3", K11KK: "saleslist_K3",
    K12KA: "saleslist_K3", K12KR: "saleslist_K3", K12KK: "saleslist_K3",
    K13KA: "saleslist_K3", K13KR: "saleslist_K3", K13KK: "saleslist_K3",
    K14KA: "saleslist_K3", K14KR: "saleslist_K3", K14KK: "saleslist_K3",
    K15KA: "saleslist_K3", K15KR: "saleslist_K3", K15KK: "saleslist_K3",
    K16KA: "saleslist_K3", K16KR: "saleslist_K3", K16KK: "saleslist_K3",
    K17KA: "saleslist_K3", K17KR: "saleslist_K3", K17KK: "saleslist_K3",
    K19KA: "saleslist_K3", K19KR: "saleslist_K3", K19KK: "saleslist_K3",
    K20KA: "saleslist_K3", K20KR: "saleslist_K3", K20KK: "saleslist_K3",
    K21KA: "saleslist_K3", K21KR: "saleslist_K3", K21KK: "saleslist_K3",
    K22KA: "saleslist_K3", K22KR: "saleslist_K3", K22KK: "saleslist_K3",
    K29KA: "saleslist_K3", K29KR: "saleslist_K3", K29KK: "saleslist_K3",
    K30KA: "saleslist_K3", K30KR: "saleslist_K3", K30KK: "saleslist_K3",
    K31KA: "saleslist_K3", K31KR: "saleslist_K3", K31KK: "saleslist_K3",
    K32KA: "saleslist_K3", K32KR: "saleslist_K3", K32KK: "saleslist_K3",
    K33KA: "saleslist_K3", K33KR: "saleslist_K3", K33KK: "saleslist_K3",
    K41KA: "saleslist_K3", K41KR: "saleslist_K3", K41KK: "saleslist_K3",
    K34KA: "saleslist_K3", K34KR: "saleslist_K3", K34KK: "saleslist_K3",
    K39KA: "saleslist_K3", K39KR: "saleslist_K3", K39KK: "saleslist_K3",
    K40KA: "saleslist_K3", K40KR: "saleslist_K3", K40KK: "saleslist_K3",
    K42KA: "saleslist_K3", K42KR: "saleslist_K3", K42KK: "saleslist_K3",
    K43KA: "saleslist_K3", K43KR: "saleslist_K3", K43KK: "saleslist_K3",
    K44KA: "saleslist_K3", K44KR: "saleslist_K3", K44KK: "saleslist_K3",
    K49KA: "saleslist_K3", K49KR: "saleslist_K3", K49KK: "saleslist_K3",
    K50KA: "saleslist_K3", K50KR: "saleslist_K3", K50KK: "saleslist_K3",
  },
  {
    no : "saleslist_H",
    //以下に貼り付け
    H1CD: "saleslist_H", H1NM: "saleslist_H", H2CD: "saleslist_H", H2NM: "saleslist_H", H3CD: "saleslist_H", H3NM: "saleslist_H", H4CD: "saleslist_H", H4NM: "saleslist_H", H5CD: "saleslist_H", H5NM: "saleslist_H", H6CD: "saleslist_H", H6NM: "saleslist_H", H7CD: "saleslist_H", H7NM: "saleslist_H", H8CD: "saleslist_H", H8NM: "saleslist_H",
    CDIV: "saleslist_H",
    TDIV: "saleslist_H",

    JJT: "saleslist_J", JJP: "saleslist_JP", JJR: "saleslist_JP", JJD: "saleslist_JP", 
    JKT: "saleslist_K", JKP: "saleslist_KP", JKR: "saleslist_KP", JKD: "saleslist_KP", 
    JRT: "saleslist_K", JRP: "saleslist_KP", JRD: "saleslist_KP", 
    //欠品責任別
    KI1KA: "saleslist_K2", KI1KR: "saleslist_K2", KI1KK: "saleslist_K2", 
    KI2KA: "saleslist_K2", KI2KR: "saleslist_K2", KI2KK: "saleslist_K2", 
    KI3KA: "saleslist_K2", KI3KR: "saleslist_K2", KI3KK: "saleslist_K2", 
    //欠品区分別,,
    K10KA: "saleslist_K3", K10KR: "saleslist_K3", K10KK: "saleslist_K3",
    K11KA: "saleslist_K3", K11KR: "saleslist_K3", K11KK: "saleslist_K3",
    K12KA: "saleslist_K3", K12KR: "saleslist_K3", K12KK: "saleslist_K3",
    K13KA: "saleslist_K3", K13KR: "saleslist_K3", K13KK: "saleslist_K3",
    K14KA: "saleslist_K3", K14KR: "saleslist_K3", K14KK: "saleslist_K3",
    K15KA: "saleslist_K3", K15KR: "saleslist_K3", K15KK: "saleslist_K3",
    K16KA: "saleslist_K3", K16KR: "saleslist_K3", K16KK: "saleslist_K3",
    K17KA: "saleslist_K3", K17KR: "saleslist_K3", K17KK: "saleslist_K3",
    K19KA: "saleslist_K3", K19KR: "saleslist_K3", K19KK: "saleslist_K3",
    K20KA: "saleslist_K3", K20KR: "saleslist_K3", K20KK: "saleslist_K3",
    K21KA: "saleslist_K3", K21KR: "saleslist_K3", K21KK: "saleslist_K3",
    K22KA: "saleslist_K3", K22KR: "saleslist_K3", K22KK: "saleslist_K3",
    K29KA: "saleslist_K3", K29KR: "saleslist_K3", K29KK: "saleslist_K3",
    K30KA: "saleslist_K3", K30KR: "saleslist_K3", K30KK: "saleslist_K3",
    K31KA: "saleslist_K3", K31KR: "saleslist_K3", K31KK: "saleslist_K3",
    K32KA: "saleslist_K3", K32KR: "saleslist_K3", K32KK: "saleslist_K3",
    K33KA: "saleslist_K3", K33KR: "saleslist_K3", K33KK: "saleslist_K3",
    K41KA: "saleslist_K3", K41KR: "saleslist_K3", K41KK: "saleslist_K3",
    K34KA: "saleslist_K3", K34KR: "saleslist_K3", K34KK: "saleslist_K3",
    K39KA: "saleslist_K3", K39KR: "saleslist_K3", K39KK: "saleslist_K3",
    K40KA: "saleslist_K3", K40KR: "saleslist_K3", K40KK: "saleslist_K3",
    K42KA: "saleslist_K3", K42KR: "saleslist_K3", K42KK: "saleslist_K3",
    K43KA: "saleslist_K3", K43KR: "saleslist_K3", K43KK: "saleslist_K3",
    K44KA: "saleslist_K3", K44KR: "saleslist_K3", K44KK: "saleslist_K3",
    K49KA: "saleslist_K3", K49KR: "saleslist_K3", K49KK: "saleslist_K3",
    K50KA: "saleslist_K3", K50KR: "saleslist_K3", K50KK: "saleslist_K3",

    SLP: "saleslist_P",  SLR: "saleslist_P",  SLD: "saleslist_P",  
    SDP: "saleslist_P",  SDR: "saleslist_P",  SDD: "saleslist_P",  
    SRP: "saleslist_P",  SRR: "saleslist_P",  SRD: "saleslist_P",  
    STP: "saleslist_P",  STR: "saleslist_P",  STD: "saleslist_P",  STB: "saleslist_B",  STBR: "saleslist_B",  STBD: "saleslist_B",  
    PBLP: "saleslist_P",  PBLR: "saleslist_P",  PBLD: "saleslist_P",  
    PBDP: "saleslist_P",  PBDR: "saleslist_P",  PBDD: "saleslist_P",  
    PBRP: "saleslist_P",  PBRR: "saleslist_P",  PBRD: "saleslist_P",  
    PBTP: "saleslist_P",  PBTR: "saleslist_P",  PBTD: "saleslist_P",  
    PBHP: "saleslist_P",  PBHD: "saleslist_P",  
    YHLP: "saleslist_P", YHLR: "saleslist_P", YHLD: "saleslist_P",
    YHDP: "saleslist_P", YHDR: "saleslist_P", YHDD: "saleslist_P",
    YHRP: "saleslist_P", YHRR: "saleslist_P", YHRD: "saleslist_P",
    YHTP: "saleslist_P", YHTR: "saleslist_P", YHTD: "saleslist_P",
    YHHP: "saleslist_P", YHHD: "saleslist_P",
    PTP: "saleslist_P", PTR: "saleslist_P", PTD: "saleslist_P", PTB: "saleslist_B", PTBR: "saleslist_B", PTBD: "saleslist_B",  
    PRP: "saleslist_P",  PRD: "saleslist_P",  
    DDP: "saleslist_P",  DDR: "saleslist_P",  DDD: "saleslist_P",  
    DBP: "saleslist_P",  DBR: "saleslist_P",  DBD: "saleslist_P",  
    DQP: "saleslist_P",  DQR: "saleslist_P",  DQD: "saleslist_P",  
    DFP: "saleslist_P",  DFR: "saleslist_P",  DFD: "saleslist_P",  
    DTP: "saleslist_P",  DTR: "saleslist_P",  DTD: "saleslist_P",  DTB: "saleslist_B",  DTBR: "saleslist_B",  DTBD: "saleslist_B",  
    DRP: "saleslist_P",  DRD: "saleslist_P",  
    NTP: "saleslist_P",  NTR: "saleslist_P",  NTD: "saleslist_P",  NTB: "saleslist_B",  NTBR: "saleslist_B",  NTBD: "saleslist_B",  
    CIP: "saleslist_P",  CIR: "saleslist_P",  CID: "saleslist_P",  
    CSP: "saleslist_P",  CSR: "saleslist_P",  CSD: "saleslist_P",  
    CTP: "saleslist_P",  CTR: "saleslist_P",  CTD: "saleslist_P",  CTB: "saleslist_B",  CTBR: "saleslist_B",  CTBD: "saleslist_B",  
    OCP: "saleslist_P",  OCR: "saleslist_P",  OCD: "saleslist_P",
    OPP: "saleslist_P",  OPR: "saleslist_P",  OPD: "saleslist_P",
    OEP: "saleslist_P",  OER: "saleslist_P",  OED: "saleslist_P",
    TPP: "saleslist_P",  TPR: "saleslist_P",  TPD: "saleslist_P",  
    PPTP: "saleslist_P", PPTR: "saleslist_P", PPTD: "saleslist_P", PPTB: "saleslist_B", PPTBR: "saleslist_B", PPTBD: "saleslist_B",
    PPTRP: "saleslist_P", PPTRD: "saleslist_P",
    TSP: "saleslist_P",  TSR: "saleslist_P",  TSD: "saleslist_P",  
    CSTP: "saleslist_P", CSTR: "saleslist_P", CSTD: "saleslist_P", CSTB: "saleslist_B", CSTBR: "saleslist_B", CSTBD: "saleslist_B",
    CSTRP: "saleslist_P", CSTRD: "saleslist_P",
    TEP: "saleslist_P",  TER: "saleslist_P",  TED: "saleslist_P",  
    ETP: "saleslist_P", ETR: "saleslist_P", ETD: "saleslist_P", ETB: "saleslist_B", ETBR: "saleslist_B", ETBD: "saleslist_B",
    ETRP: "saleslist_P", ETRD: "saleslist_P",
    KTP: "saleslist_P",  KTR: "saleslist_P",  KTD: "saleslist_P",  
    KRP: "saleslist_P",  KRD: "saleslist_P",  
    BTP: "saleslist_P",  BTR: "saleslist_P",  BTD: "saleslist_P",  
    BRP: "saleslist_P",  BRR: "saleslist_P",  BRD: "saleslist_P",  
    ATP: "saleslist_P",  ATR: "saleslist_P",  ATD: "saleslist_P",  
    ARP: "saleslist_P",  ARD: "saleslist_P",  
    GTP: "saleslist_P",  GTR: "saleslist_P",  GTD: "saleslist_P",  GTB: "saleslist_B",  GTBR: "saleslist_B",  GTBD: "saleslist_B",  
    GRP: "saleslist_P",  GRD: "saleslist_P",  
    ZTP: "saleslist_P",  ZTR: "saleslist_P",  ZTD: "saleslist_P",  ZTB: "saleslist_B",  ZTBR: "saleslist_B",  ZTBD: "saleslist_B",  
    ZRP: "saleslist_P",  ZRD: "saleslist_P",  
    ZCP: "saleslist_P",  ZCR: "saleslist_P",  ZCD: "saleslist_P",  
    ZCRP: "saleslist_P",  ZCRD: "saleslist_P",  
    CDDP: "saleslist_P",  CDDR: "saleslist_P",  CDDD: "saleslist_P",  
    CDBP: "saleslist_P",  CDBR: "saleslist_P",  CDBD: "saleslist_P",  
    CDFP: "saleslist_P",  CDFR: "saleslist_P",  CDFD: "saleslist_P",  
    COCP: "saleslist_P",  COCR: "saleslist_P",  COCD: "saleslist_P",  
    COPP: "saleslist_P",  COPR: "saleslist_P",  COPD: "saleslist_P",  
    COEP: "saleslist_P",  COER: "saleslist_P",  COED: "saleslist_P",  
    CTPP: "saleslist_P",  CTPR: "saleslist_P",  CTPD: "saleslist_P",  
    CTSP: "saleslist_P",  CTSR: "saleslist_P",  CTSD: "saleslist_P",  
    CTEP: "saleslist_P",  CTER: "saleslist_P",  CTED: "saleslist_P",    
    //構成比
    JJTK: "saleslist_KR", JJPK: "saleslist_KR", 
    JKTK: "saleslist_KR", JKPK: "saleslist_KR", 
    SLTK: "saleslist_KR", SLPK: "saleslist_KR", SDTK: "saleslist_KR", SDPK: "saleslist_KR", SRTK: "saleslist_KR", SRPK: "saleslist_KR", STTK: "saleslist_KR", STPK: "saleslist_KR",
    PBLTK: "saleslist_KR", PBLPK: "saleslist_KR", PBDTK: "saleslist_KR", PBDPK: "saleslist_KR", PBRTK: "saleslist_KR", PBRPK: "saleslist_KR", PBTTK: "saleslist_KR", PBTPK: "saleslist_KR",
    YHLTK: "saleslist_KR", YHLPK: "saleslist_KR", YHDTK: "saleslist_KR", YHDPK: "saleslist_KR", YHRTK: "saleslist_KR", YHRPK: "saleslist_KR", YHTTK: "saleslist_KR", YHTPK: "saleslist_KR",
    PTTK: "saleslist_KR", PTPK: "saleslist_KR",
    DDTK: "saleslist_KR", DDPK: "saleslist_KR", DBTK: "saleslist_KR", DBPK: "saleslist_KR", DQTK: "saleslist_KR", DQPK: "saleslist_KR", DFTK: "saleslist_KR", DFPK: "saleslist_KR", DTTK: "saleslist_KR", DTPK: "saleslist_KR",
    NTTK: "saleslist_KR", NTPK: "saleslist_KR",
    CITK: "saleslist_KR", CIPK: "saleslist_KR", CSTK: "saleslist_KR", CSPK: "saleslist_KR", CTTK: "saleslist_KR", CTPK: "saleslist_KR", 
    OCTK: "saleslist_KR", OCPK: "saleslist_KR", 
    TSTK: "saleslist_KR", TSPK: "saleslist_KR",
    CSTTK: "saleslist_KR", CSTPK: "saleslist_KR",
    OPTK: "saleslist_KR", OPPK: "saleslist_KR",
    TPTK: "saleslist_KR", TPPK: "saleslist_KR",
    PPTTK: "saleslist_KR", PPTPK: "saleslist_KR",
    OETK: "saleslist_KR", OEPK: "saleslist_KR",
    TETK: "saleslist_KR", TEPK: "saleslist_KR",
    ETTK: "saleslist_KR", ETPK: "saleslist_KR",
    KTTK: "saleslist_KR", KTPK: "saleslist_KR",
    BTTK: "saleslist_KR", BTPK: "saleslist_KR",
    ATTK: "saleslist_KR", ATPK: "saleslist_KR",
    GTTK: "saleslist_KR", GTPK: "saleslist_KR",
    ZTTK: "saleslist_KR", ZTPK: "saleslist_KR", ZCTK: "saleslist_KR", ZCPK: "saleslist_KR",
    CNDP: "saleslist_P", CNDR: "saleslist_P", CNDD: "saleslist_P", CNDRP: "saleslist_P", CNDRD: "saleslist_P",
    CNPP: "saleslist_P", CNPR: "saleslist_P", CNPD: "saleslist_P", CNPRP: "saleslist_P", CNPRD: "saleslist_P",
    CNGP: "saleslist_P", CNGR: "saleslist_P", CNGD: "saleslist_P", CNGRP: "saleslist_P", CNGRD: "saleslist_P",
    CNAP: "saleslist_P", CNAR: "saleslist_P", CNAD: "saleslist_P", CNARP: "saleslist_P", CNARD: "saleslist_P",
    CNEP: "saleslist_P", CNER: "saleslist_P", CNED: "saleslist_P", CNRP: "saleslist_P", CNRD: "saleslist_P",
    SNEP: "saleslist_P", SNER: "saleslist_P", SNED: "saleslist_P", SNRP: "saleslist_P", SNRD: "saleslist_P",
    ORSP: "saleslist_P", ORSR: "saleslist_P", ORSD: "saleslist_P", ORSRP: "saleslist_P", ORSRD: "saleslist_P",
    ORPP: "saleslist_P", ORPR: "saleslist_P", ORPD: "saleslist_P", ORPRP: "saleslist_P", ORPRD: "saleslist_P",
    SHCT: "saleslist_X", SHCP: "saleslist_X", SHCR: "saleslist_X", SHCD: "saleslist_X", 
    SHDT: "saleslist_X", SHDP: "saleslist_X", SHDR: "saleslist_X", SHDD: "saleslist_X", 
    SHRCT: "saleslist_X", SHRCP: "saleslist_X", SHRCR: "saleslist_X", SHRCD: "saleslist_X", 
    SHRDT: "saleslist_X", SHRDP: "saleslist_X", SHRDR: "saleslist_X", SHRDD: "saleslist_X", 
    SHT: "saleslist_X", SHP: "saleslist_XP", SHR: "saleslist_XP", SHD: "saleslist_XP", 
    CCDTK: "saleslist_KR", CDDPK: "saleslist_KR", CDBTK: "saleslist_KR", CDBPK: "saleslist_KR", CDFTK: "saleslist_KR", CDFPK: "saleslist_KR", COCTK: "saleslist_KR", COCPK: "saleslist_KR", COPTK: "saleslist_KR", COPPK: "saleslist_KR", COETK: "saleslist_KR", COEPK: "saleslist_KR", CTPTK: "saleslist_KR", CTPPK: "saleslist_KR", CTSTK: "saleslist_KR", CTSPK: "saleslist_KR", CTETK: "saleslist_KR", CTEPK: "saleslist_KR",
    //予算構成比
    STBK: "saleslist_B", PTBK: "saleslist_B", DTBK: "saleslist_B", NTBK: "saleslist_B", CTBK: "saleslist_B", GTBK: "saleslist_B", ZTBK: "saleslist_B", 
  },
]

export const colWidths =
{
  no : 70,
  //以下に貼り付け
  H1CD: 80, H1NM: 150, H2CD: 80, H2NM: 150, H3CD: 80, H3NM: 150, H4CD: 80, H4NM: 150, H5CD: 80, H5NM: 150, H6CD: 80, H6NM: 150, H7CD: 80, H7NM: 150, H8CD: 80, H8NM: 150,
  CDIV: 80,
  TDIV: 80,
  JJT: 120, JJP: 120, JJR: 80, JJD: 120, 
  JKT: 120, JKP: 120, JKR: 80, JKD: 120, 
  JRT: 80, JRP: 80, JRD: 80, 
  //欠品責任別
  KI1KA: 100, KI1KR: 80, KI1KK: 80, 
  KI2KA: 100, KI2KR: 80, KI2KK: 80, 
  KI3KA: 100, KI3KR: 80, KI3KK: 80, 
  //欠品区分別,,
  K10KA: 100, K10KR: 80, K10KK: 80, 
  K11KA: 100, K11KR: 80, K11KK: 80, 
  K12KA: 100, K12KR: 80, K12KK: 80, 
  K13KA: 100, K13KR: 80, K13KK: 80, 
  K14KA: 100, K14KR: 80, K14KK: 80, 
  K15KA: 100, K15KR: 80, K15KK: 80, 
  K16KA: 100, K16KR: 80, K16KK: 80, 
  K17KA: 100, K17KR: 80, K17KK: 80, 
  K19KA: 100, K19KR: 80, K19KK: 80, 
  K20KA: 100, K20KR: 80, K20KK: 80, 
  K21KA: 100, K21KR: 80, K21KK: 80, 
  K22KA: 100, K22KR: 80, K22KK: 80, 
  K29KA: 100, K29KR: 80, K29KK: 80, 
  K30KA: 100, K30KR: 80, K30KK: 80, 
  K31KA: 100, K31KR: 80, K31KK: 80, 
  K32KA: 100, K32KR: 80, K32KK: 80, 
  K33KA: 100, K33KR: 80, K33KK: 80, 
  K41KA: 100, K41KR: 80, K41KK: 80, 
  K34KA: 100, K34KR: 80, K34KK: 80, 
  K39KA: 100, K39KR: 80, K39KK: 80, 
  K40KA: 100, K40KR: 80, K40KK: 80, 
  K42KA: 100, K42KR: 80, K42KK: 80, 
  K43KA: 100, K43KR: 80, K43KK: 80, 
  K44KA: 100, K44KR: 80, K44KK: 80, 
  K49KA: 100, K49KR: 80, K49KK: 80, 
  K50KA: 100, K50KR: 80, K50KK: 80, 

  SLT: 120, SLP: 120, SLR: 80, SLD: 120, 
  SDT: 120, SDP: 120, SDR: 80, SDD: 120, 
  SRT: 120, SRP: 120, SRR: 80, SRD: 120, 
  STT: 120, STP: 120, STR: 80, STD: 120, STB: 120, STBR: 80, STBD: 120,
  PBLT: 120, PBLP: 120, PBLR: 80, PBLD: 120, 
  PBDT: 120, PBDP: 120, PBDR: 80, PBDD: 120, 
  PBRT: 120, PBRP: 120, PBRR: 80, PBRD: 120, 
  PBTT: 120, PBTP: 120, PBTR: 80, PBTD: 120, 
  PBHT: 80, PBHP: 80, PBHD: 80,
  YHLT: 120, YHLP: 120, YHLR: 80, YHLD: 120, 
  YHDT: 120, YHDP: 120, YHDR: 80, YHDD: 120, 
  YHRT: 120, YHRP: 120, YHRR: 80, YHRD: 120, 
  YHTT: 120, YHTP: 120, YHTR: 80, YHTD: 120, 
  YHHT: 80, YHHP: 80, YHHD: 80,
  PTT: 120, PTP: 120, PTR: 120, PTD: 120, PTB: 120, PTBR: 80, PTBD: 120, 
  PRT: 80, PRP: 80, PRD: 80,
  DDT: 120, DDP: 120, DDR: 80, DDD: 120, 
  DBT: 120, DBP: 120, DBR: 80, DBD: 120, 
  DQT: 120, DQP: 120, DQR: 80, DQD: 120, 
  DFT: 120, DFP: 120, DFR: 80, DFD: 120, 
  DTT: 120, DTP: 120, DTR: 80, DTD: 120, DTB: 120, DTBR: 80, DTBD: 120, 
  DRT: 80, DRP: 80, DRD: 80,
  NTT: 120, NTP: 120, NTR: 80, NTD: 120, NTB: 120, NTBR: 80, NTBD: 120,
  CIT: 120, CIP: 120, CIR: 80, CID: 120, 
  CST: 120, CSP: 120, CSR: 80, CSD: 120, 
  CTT: 120, CTP: 120, CTR: 80, CTD: 120, CTB: 120, CTBR: 80, CTBD: 120,
  OCT: 120, OCP: 120, OCR: 80, OCD: 120,
  TST: 120, TSP: 120, TSR: 80, TSD: 120, 
  CSTT: 120, CSTP: 120, CSTR: 80, CSTD: 120, CSTB: 120, CSTBR: 80, CSTBD: 120,
  CSTRT: 80, CSTRP: 80, CSTRD: 80,
  OPT: 120, OPP: 120, OPR: 80, OPD: 120,
  TPT: 120, TPP: 120, TPR: 80, TPD: 120, 
  PPTT: 120, PPTP: 120, PPTR: 80, PPTD: 120, PPTB: 120, PPTBR: 80, PPTBD: 120,
  PPTRT: 80, PPTRP: 80, PPTRD: 80,
  OET: 120, OEP: 120, OER: 80, OED: 120,
  TET: 120, TEP: 120, TER: 80, TED: 120, 
  ETT: 120, ETP: 120, ETR: 80, ETD: 120, ETB: 120, ETBR: 80, ETBD: 120,
  ETRT: 80, ETRP: 80, ETRD: 80,
  KTT: 120, KTP: 120, KTR: 80, KTD: 120, 
  KRT: 80, KRP: 80, KRD: 80,
  BTT: 120, BTP: 120, BTR: 80, BTD: 120, 
  BRT: 80, BRP: 80, BRD: 80, 
  ATT: 120, ATP: 120, ATR: 80, ATD: 120, 
  ART: 80, ARP: 80, ARD: 80, 
  GTT: 120, GTP: 120, GTR: 80, GTD: 120, GTB: 120, GTBR: 80, GTBD: 120, 
  GRT: 80, GRP: 80, GRD: 80, 
  ZTT: 120, ZTP: 120, ZTR: 80, ZTD: 120, ZTB: 120, ZTBR: 80, ZTBD: 120, 
  ZRT: 80, ZRP: 80, ZRD: 80,
  ZCT: 120, ZCP: 120, ZCR: 80, ZCD: 120, 
  ZCRT: 80, ZCRP: 80, ZCRD: 80,
  CNDT: 120, CNDP: 120, CNDR: 80, CNDD: 120, CNDRT: 80, CNDRP: 80, CNDRD: 80,
  CNPT: 120, CNPP: 120, CNPR: 80, CNPD: 120, CNPRT: 80, CNPRP: 80, CNPRD: 80,
  CNGT: 120, CNGP: 120, CNGR: 80, CNGD: 120, CNGRT: 80, CNGRP: 80, CNGRD: 80,
  CNAT: 120, CNAP: 120, CNAR: 80, CNAD: 120, CNART: 80, CNARP: 80, CNARD: 80,
  CNET: 120, CNEP: 120, CNER: 80, CNED: 120, CNRT: 80, CNRP: 80, CNRD: 80,
  SNET: 120, SNEP: 120, SNER: 80, SNED: 120, SNRT: 80, SNRP: 80, SNRD: 80,
  ORST: 120, ORSP: 120, ORSR: 80, ORSD: 120, ORSRT: 80, ORSRP: 80, ORSRD: 80,
  ORPT: 120, ORPP: 120, ORPR: 80, ORPD: 120, ORPRT: 80, ORPRP: 80, ORPRD: 80,
  SHCT: 120, SHCP: 120, SHCR: 80, SHCD: 120, 
  SHDT: 120, SHDP: 120, SHDR: 80, SHDD: 120, 
  SHRCT: 120, SHRCP: 120, SHRCR: 80, SHRCD: 120, 
  SHRDT: 120, SHRDP: 120, SHRDR: 80, SHRDD: 120, 
  SHT: 120, SHP: 120, SHR: 80, SHD: 120,
  CCDT: 120, CDDP: 120, CDDR: 80, CDDD: 120, 
  CDBT: 120, CDBP: 120, CDBR: 80, CDBD: 120, 
  CDFT: 120, CDFP: 120, CDFR: 80, CDFD: 120, 
  COCT: 120, COCP: 120, COCR: 80, COCD: 120, 
  COPT: 120, COPP: 120, COPR: 80, COPD: 120, 
  COET: 120, COEP: 120, COER: 80, COED: 120, 
  CTPT: 120, CTPP: 120, CTPR: 80, CTPD: 120, 
  CTST: 120, CTSP: 120, CTSR: 80, CTSD: 120, 
  CTET: 120, CTEP: 120, CTER: 80, CTED: 120, 
  //構成比
  JJTK: 80, JJPK: 80, 
  JKTK: 80, JKPK: 80, 
  SLTK: 80, SLPK: 80, SDTK: 80, SDPK: 80, SRTK: 80, SRPK: 80, STTK: 80, STPK: 80,
  PBLTK: 80, PBLPK: 80, PBDTK: 80, PBDPK: 80, PBRTK: 80, PBRPK: 80, PBTTK: 80, PBTPK: 80, 
  YHLTK: 80, YHLPK: 80, YHDTK: 80, YHDPK: 80, YHRTK: 80, YHRPK: 80, YHTTK: 80, YHTPK: 80,
  PTTK: 80, PTPK: 80,
  DDTK: 80, DDPK: 80, DBTK: 80, DBPK: 80, DQTK: 80, DQPK: 80, DFTK: 80, DFPK: 80, DTTK: 80, DTPK: 80,
  NTTK: 80, NTPK: 80,
  CITK: 80, CIPK: 80, CSTK: 80, CSPK: 80, CTTK: 80, CTPK: 80, 
  OCTK: 80, OCPK: 80, 
  TSTK: 80, TSPK: 80,
  CSTTK: 80, CSTPK: 80,
  OPTK: 80, OPPK: 80,
  TPTK: 80, TPPK: 80,
  PPTTK: 80, PPTPK: 80,
  OETK: 80, OEPK: 80,
  TETK: 80, TEPK: 80,
  ETTK: 80, ETPK: 80,
  KTTK: 80, KTPK: 80,
  BTTK: 80, BTPK: 80,
  ATTK: 80, ATPK: 80,
  GTTK: 80, GTPK: 80,
  ZTTK: 80, ZTPK: 80, ZCTK: 80, ZCPK: 80,
  CCDTK: 80, CDDPK: 80, CDBTK: 80, CDBPK: 80, CDFTK: 80, CDFPK: 80, COCTK: 80, COCPK: 80, COPTK: 80, COPPK: 80, COETK: 80, COEPK: 80, CTPTK: 80, CTPPK: 80, CTSTK: 80, CTSPK: 80, CTETK: 80, CTEPK: 80,
  //予算構成比
  STBK: 80, PTBK: 80, DTBK: 80, NTBK: 80, CTBK: 80, GTBK: 80, ZTBK: 80, 
};

export const colDataType =
{
  no : {type: 'text', readOnly: true,},
  //以下に貼り付け
  H1CD: {type: 'text', readOnly: true,},
  H1NM: {type: 'text', readOnly: true,},
  H2CD: {type: 'text', readOnly: true,},
  H2NM: {type: 'text', readOnly: true,},
  H3CD: {type: 'text', readOnly: true,},
  H3NM: {type: 'text', readOnly: true,},
  H4CD: {type: 'text', readOnly: true,},
  H4NM: {type: 'text', readOnly: true,},
  H5CD: {type: 'text', readOnly: true,},
  H5NM: {type: 'text', readOnly: true,},
  H6CD: {type: 'text', readOnly: true,},
  H6NM: {type: 'text', readOnly: true,},
  H7CD: {type: 'text', readOnly: true,},
  H7NM: {type: 'text', readOnly: true,},
  H8CD: {type: 'text', readOnly: true,},
  H8NM: {type: 'text', readOnly: true,},
  CDIV: {type: 'text', readOnly: true,},
  TDIV: {type: 'text', readOnly: true,},

  JJT: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  JJP: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  JJR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  JJD: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  JKT: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},},
  JKP: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},},
  JKR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  JKD: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},},
  JRT: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  JRP: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  JRD: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0.0P',},},

  //欠品責任別
  KI1KA: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},}, KI1KR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, KI1KK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, 
  KI2KA: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},}, KI2KR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, KI2KK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, 
  KI3KA: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},}, KI3KR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, KI3KK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, 
  //欠品区分別,,,,,,,,,,,
  K10KA: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},}, K10KR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, K10KK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, 
  K11KA: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},}, K11KR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, K11KK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, 
  K12KA: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},}, K12KR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, K12KK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, 
  K13KA: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},}, K13KR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, K13KK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, 
  K14KA: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},}, K14KR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, K14KK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, 
  K15KA: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},}, K15KR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, K15KK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, 
  K16KA: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},}, K16KR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, K16KK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, 
  K17KA: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},}, K17KR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, K17KK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, 
  K19KA: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},}, K19KR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, K19KK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, 
  K20KA: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},}, K20KR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, K20KK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, 
  K21KA: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},}, K21KR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, K21KK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, 
  K22KA: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},}, K22KR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, K22KK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, 
  K29KA: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},}, K29KR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, K29KK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, 
  K30KA: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},}, K30KR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, K30KK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, 
  K31KA: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},}, K31KR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, K31KK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, 
  K32KA: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},}, K32KR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, K32KK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, 
  K33KA: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},}, K33KR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, K33KK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, 
  K41KA: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},}, K41KR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, K41KK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, 
  K34KA: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},}, K34KR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, K34KK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, 
  K39KA: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},}, K39KR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, K39KK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, 
  K40KA: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},}, K40KR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, K40KK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, 
  K42KA: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},}, K42KR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, K42KK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, 
  K43KA: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},}, K43KR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, K43KK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, 
  K44KA: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},}, K44KR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, K44KK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, 
  K49KA: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},}, K49KR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, K49KK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, 
  K50KA: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},}, K50KR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, K50KK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, 


  SLT: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  SLP: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  SLR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  SLD: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  SDT: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  SDP: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  SDR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  SDD: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  SRT: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  SRP: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  SRR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  SRD: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  STT: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  STP: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  STR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  STD: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  STB: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  STBR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  STBD: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},

  PBLT: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  PBLP: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  PBLR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  PBLD: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  PBDT: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  PBDP: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  PBDR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  PBDD: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  PBRT: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  PBRP: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  PBRR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  PBRD: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  PBTT: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  PBTP: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  PBTR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  PBTD: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  PBHT: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  PBHP: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  PBHD: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0P',},},

  YHLT: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  YHLP: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  YHLR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  YHLD: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  YHDT: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  YHDP: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  YHDR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  YHDD: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  YHRT: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  YHRP: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  YHRR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  YHRD: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  YHTT: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  YHTP: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  YHTR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  YHTD: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  YHHT: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  YHHP: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  YHHD: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0P',},},

  PTT: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  PTP: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  PTR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  PTD: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  PTB: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  PTBR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  PTBD: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  PRT: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.00%',},},
  PRP: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.00%',},},
  PRD: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.00P',},},

  DDT: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},},
  DDP: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},},
  DDR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  DDD: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},},
  DBT: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},},
  DBP: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},},
  DBR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  DBD: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},},
  DQT: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},},
  DQP: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},},
  DQR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  DQD: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},},
  DFT: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},},
  DFP: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},},
  DFR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  DFD: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},},
  DTT: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},},
  DTP: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},},
  DTR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  DTD: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},},
  DTB: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  DTBR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  DTBD: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},},
  DRT: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  DRP: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  DRD: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0.0P',},},

  NTT: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  NTP: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  NTR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  NTD: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  NTB: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  NTBR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  NTBD: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},

  CIT: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  CIP: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  CIR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  CID: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  CST: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  CSP: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  CSR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  CSD: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  CTT: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  CTP: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  CTR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  CTD: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  CTB: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  CTBR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  CTBD: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},

  OCT: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  OCP: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  OCR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  OCD: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},},
  OPT: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  OPP: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  OPR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  OPD: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},},
  OET: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  OEP: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  OER: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  OED: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},},

  TPT: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  TPP: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  TPR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  TPD: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},},
  PPTT: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  PPTP: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  PPTR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  PPTD: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},},
  PPTB: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, },
  PPTBR: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0.0%', }, },
  PPTBD: { type: 'numeric', readOnly: true, numericFormat: { pattern: '△0,0', }, },
  PPTRT: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0.0%', }, },
  PPTRP: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0.0%', }, },
  PPTRD: { type: 'numeric', readOnly: true, numericFormat: { pattern: '△0.0P', }, },
  TST: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  TSP: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  TSR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  TSD: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},},
  CSTT: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  CSTP: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  CSTR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  CSTD: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},},
  CSTB: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, },
  CSTBR: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0.0%', }, },
  CSTBD: { type: 'numeric', readOnly: true, numericFormat: { pattern: '△0,0', }, },
  CSTRT: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0.0%', }, },
  CSTRP: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0.0%', }, },
  CSTRD: { type: 'numeric', readOnly: true, numericFormat: { pattern: '△0.0P', }, },
  TET: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  TEP: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  TER: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  TED: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},},
  ETT: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  ETP: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  ETR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  ETD: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},},
  ETB: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, },
  ETBR: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0.0%', }, },
  ETBD: { type: 'numeric', readOnly: true, numericFormat: { pattern: '△0,0', }, },
  ETRT: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0.0%', }, },
  ETRP: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0.0%', }, },
  ETRD: { type: 'numeric', readOnly: true, numericFormat: { pattern: '△0.0P', }, },
  KTT: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  KTP: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  KTR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  KTD: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},},
  KRT: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  KRP: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  KRD: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0.0P',},},

  BTT: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  BTP: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  BTR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  BTD: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  BRT: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  BRP: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  BRD: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0P',},},
  ATT: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  ATP: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  ATR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  ATD: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  ART: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  ARP: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  ARD: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0P',},},
  GTT: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  GTP: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  GTR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  GTD: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  GTB: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  GTBR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  GTBD: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  GRT: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  GRP: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  GRD: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0P',},},
  ZTT: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  ZTP: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  ZTR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  ZTD: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  ZTB: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  ZTBR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  ZTBD: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  ZRT: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.00%',},},
  ZRP: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.00%',},},
  ZRD: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.00P',},},
  ZCT: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  ZCP: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  ZCR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  ZCD: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  ZCRT: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.00%',},},
  ZCRP: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.00%',},},
  ZCRD: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.00P',},},
  CNDT: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, },
  CNDP: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, },
  CNDR: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0.0%', }, },
  CNDD: { type: 'numeric', readOnly: true, numericFormat: { pattern: '△0,0', }, },
  CNDRT: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0.00%', }, },
  CNDRP: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0.00%', }, },
  CNDRD: { type: 'numeric', readOnly: true, numericFormat: { pattern: '△0.00P', }, },
  CNPT: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, },
  CNPP: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, },
  CNPR: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0.0%', }, },
  CNPD: { type: 'numeric', readOnly: true, numericFormat: { pattern: '△0,0', }, },
  CNPRT: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0.00%', }, },
  CNPRP: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0.00%', }, },
  CNPRD: { type: 'numeric', readOnly: true, numericFormat: { pattern: '△0.00P', }, },
  CNGT: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, },
  CNGP: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, },
  CNGR: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0.0%', }, },
  CNGD: { type: 'numeric', readOnly: true, numericFormat: { pattern: '△0,0', }, },
  CNGRT: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0.00%', }, },
  CNGRP: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0.00%', }, },
  CNGRD: { type: 'numeric', readOnly: true, numericFormat: { pattern: '△0.00P', }, },
  CNAT: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, },
  CNAP: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, },
  CNAR: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0.0%', }, },
  CNAD: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, },
  CNART: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0.00%', }, },
  CNARP: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0.00%', }, },
  CNARD: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0.00P', }, },
  CNET: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, },
  CNEP: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, },
  CNER: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0.0%', }, },
  CNED: { type: 'numeric', readOnly: true, numericFormat: { pattern: '△0,0', }, },
  CNRT: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0.00%', }, },
  CNRP: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0.00%', }, },
  CNRD: { type: 'numeric', readOnly: true, numericFormat: { pattern: '△0.00P', }, },
  SNET: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, },
  SNEP: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, },
  SNER: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0.0%', }, },
  SNED: { type: 'numeric', readOnly: true, numericFormat: { pattern: '△0,0', }, },
  SNRT: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0.00%', }, },
  SNRP: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0.00%', }, },
  SNRD: { type: 'numeric', readOnly: true, numericFormat: { pattern: '△0.00P', }, },
  ORST: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, },
  ORSP: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, },
  ORSR: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0.0%', }, },
  ORSD: { type: 'numeric', readOnly: true, numericFormat: { pattern: '△0,0', }, },
  ORSRT: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0.00%', }, },
  ORSRP: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0.00%', }, },
  ORSRD: { type: 'numeric', readOnly: true, numericFormat: { pattern: '△0.00P', }, },
  ORPT: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, },
  ORPP: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, },
  ORPR: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0.0%', }, },
  ORPD: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, },
  ORPRT: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0.00%', }, },
  ORPRP: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0.00%', }, },
  ORPRD: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0.00P', }, },
  SHCT: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, }, 
  SHCP: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, }, 
  SHCR: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0.0%', }, },
  SHCD: { type: 'numeric', readOnly: true, numericFormat: { pattern: '△0,0', }, }, 
  SHDT: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, }, 
  SHDP: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, }, 
  SHDR: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0.0%', }, },
  SHDD: { type: 'numeric', readOnly: true, numericFormat: { pattern: '△0,0', }, }, 
  SHRCT: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, }, 
  SHRCP: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, }, 
  SHRCR: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0.0%', }, },
  SHRCD: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, }, 
  SHRDT: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, }, 
  SHRDP: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, }, 
  SHRDR: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0.0%', }, },
  SHRDD: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, }, 
  SHT: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, },
  SHP: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, },
  SHR: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0.0%', }, },
  SHD: { type: 'numeric', readOnly: true, numericFormat: { pattern: '△0,0', }, },
  CCDT: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  CDDP: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  CDDR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  CDDD: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},},
  CDBT: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  CDBP: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  CDBR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  CDBD: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},},
  CDFT: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  CDFP: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  CDFR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  CDFD: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},},
  COCT: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  COCP: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  COCR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  COCD: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},},
  COPT: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  COPP: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  COPR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  COPD: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},},
  COET: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  COEP: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  COER: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  COED: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},},
  CTPT: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  CTPP: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  CTPR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  CTPD: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},},
  CTST: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  CTSP: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  CTSR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  CTSD: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},},
  CTET: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  CTEP: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  CTER: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  CTED: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},},

  //構成比
  JJTK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, JJPK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, JKTK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, JKPK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  SLTK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, SLPK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, SDTK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, SDPK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, SRTK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, SRPK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, STTK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, STPK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  PBLTK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, PBLPK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, PBDTK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, PBDPK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, PBRTK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, PBRPK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, PBTTK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, PBTPK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  YHLTK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, YHLPK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, YHDTK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, YHDPK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, YHRTK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, YHRPK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, YHTTK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, YHTPK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  PTTK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, PTPK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  DDTK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, DDPK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, DBTK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, DBPK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, DQTK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, DQPK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, DFTK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, DFPK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, DTTK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, DTPK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  NTTK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, NTPK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  CITK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, CIPK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, CSTK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, CSPK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, CTTK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, CTPK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, 
  OCTK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, OCPK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, 
  TSTK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, TSPK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  CSTTK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, CSTPK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  OPTK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, OPPK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  TPTK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, TPPK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  PPTTK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, PPTPK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  OETK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, OEPK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  TETK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, TEPK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  ETTK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, ETPK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  KTTK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, KTPK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  BTTK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, BTPK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  ATTK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, ATPK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  GTTK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, GTPK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  ZTTK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, ZTPK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, ZCTK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, ZCPK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  CCDTK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, CDDPK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, CDBTK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, CDBPK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, CDFTK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, CDFPK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, COCTK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, COCPK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, COPTK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, COPPK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, COETK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, COEPK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, CTPTK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, CTPPK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, CTSTK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, CTSPK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, CTETK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, CTEPK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  //予算構成比
  STBK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, PTBK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, DTBK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, NTBK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, CTBK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, GTBK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, ZTBK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, 
};

export const colRendererName =
{
  no : 'basicRenderer',
  //以下に貼り付け
  H1CD: 'basicRenderer', H1NM: 'basicRenderer', H2CD: 'basicRenderer', H2NM: 'basicRenderer', H3CD: 'basicRenderer', H3NM: 'basicRenderer', H4CD: 'basicRenderer', H4NM: 'basicRenderer', H5CD: 'basicRenderer', H5NM: 'basicRenderer', H6CD: 'basicRenderer', H6NM: 'basicRenderer', H7CD: 'basicRenderer', H7NM: 'basicRenderer', H8CD: 'basicRenderer', H8NM: 'basicRenderer',
  CDIV: 'basicRenderer',
  TDIV: 'basicRenderer',

  JJT: 'basicRenderer', JJP: 'basicRenderer', JJR: 'rateRenderer', JJD: 'basicRenderer', 
  JKT: 'basicRenderer', JKP: 'basicRenderer', JKR: 'nebikiRateRenderer', JKD: 'basicRenderer', 
  JRT: 'keppinRateRenderer', JRP: 'keppinRateRenderer', JRD: 'keppinRateDiffRenderer', 
  //欠品責任別
  KI1KA: 'basicRenderer', KI1KR: 'keppinRateRenderer', KI1KK: 'kouseihiRenderer', 
  KI2KA: 'basicRenderer', KI2KR: 'keppinRateRenderer', KI2KK: 'kouseihiRenderer', 
  KI3KA: 'basicRenderer', KI3KR: 'keppinRateRenderer', KI3KK: 'kouseihiRenderer', 
  //欠品区分別,,
  K10KA: 'basicRenderer', K10KR: 'keppinRateRenderer', K10KK: 'kouseihiRenderer', 
  K11KA: 'basicRenderer', K11KR: 'keppinRateRenderer', K11KK: 'kouseihiRenderer', 
  K12KA: 'basicRenderer', K12KR: 'keppinRateRenderer', K12KK: 'kouseihiRenderer', 
  K13KA: 'basicRenderer', K13KR: 'keppinRateRenderer', K13KK: 'kouseihiRenderer', 
  K14KA: 'basicRenderer', K14KR: 'keppinRateRenderer', K14KK: 'kouseihiRenderer', 
  K15KA: 'basicRenderer', K15KR: 'keppinRateRenderer', K15KK: 'kouseihiRenderer', 
  K16KA: 'basicRenderer', K16KR: 'keppinRateRenderer', K16KK: 'kouseihiRenderer', 
  K17KA: 'basicRenderer', K17KR: 'keppinRateRenderer', K17KK: 'kouseihiRenderer', 
  K19KA: 'basicRenderer', K19KR: 'keppinRateRenderer', K19KK: 'kouseihiRenderer', 
  K20KA: 'basicRenderer', K20KR: 'keppinRateRenderer', K20KK: 'kouseihiRenderer', 
  K21KA: 'basicRenderer', K21KR: 'keppinRateRenderer', K21KK: 'kouseihiRenderer', 
  K22KA: 'basicRenderer', K22KR: 'keppinRateRenderer', K22KK: 'kouseihiRenderer', 
  K29KA: 'basicRenderer', K29KR: 'keppinRateRenderer', K29KK: 'kouseihiRenderer', 
  K30KA: 'basicRenderer', K30KR: 'keppinRateRenderer', K30KK: 'kouseihiRenderer', 
  K31KA: 'basicRenderer', K31KR: 'keppinRateRenderer', K31KK: 'kouseihiRenderer', 
  K32KA: 'basicRenderer', K32KR: 'keppinRateRenderer', K32KK: 'kouseihiRenderer', 
  K33KA: 'basicRenderer', K33KR: 'keppinRateRenderer', K33KK: 'kouseihiRenderer', 
  K41KA: 'basicRenderer', K41KR: 'keppinRateRenderer', K41KK: 'kouseihiRenderer', 
  K34KA: 'basicRenderer', K34KR: 'keppinRateRenderer', K34KK: 'kouseihiRenderer', 
  K39KA: 'basicRenderer', K39KR: 'keppinRateRenderer', K39KK: 'kouseihiRenderer', 
  K40KA: 'basicRenderer', K40KR: 'keppinRateRenderer', K40KK: 'kouseihiRenderer', 
  K42KA: 'basicRenderer', K42KR: 'keppinRateRenderer', K42KK: 'kouseihiRenderer', 
  K43KA: 'basicRenderer', K43KR: 'keppinRateRenderer', K43KK: 'kouseihiRenderer', 
  K44KA: 'basicRenderer', K44KR: 'keppinRateRenderer', K44KK: 'kouseihiRenderer', 
  K49KA: 'basicRenderer', K49KR: 'keppinRateRenderer', K49KK: 'kouseihiRenderer', 
  K50KA: 'basicRenderer', K50KR: 'keppinRateRenderer', K50KK: 'kouseihiRenderer', 


  SLT: 'basicRenderer', SLP: 'basicRenderer', SLR: 'rateRenderer', SLD: 'basicRenderer', 
  SDT: 'basicRenderer', SDP: 'basicRenderer', SDR: 'rateRenderer', SDD: 'basicRenderer', 
  SRT: 'basicRenderer', SRP: 'basicRenderer', SRR: 'nebikiRateRenderer', SRD: 'basicRenderer', 
  STT: 'basicRenderer', STP: 'basicRenderer', STR: 'rateRenderer', STD: 'basicRenderer', STB: 'basicRenderer', STBR: 'rateRenderer', STBD: 'basicRenderer',
  PBLT: "basicRenderer", PBLP: "basicRenderer", PBLR: "rateRenderer", PBLD: "basicRenderer", 
  PBDT: "basicRenderer", PBDP: "basicRenderer", PBDR: "rateRenderer", PBDD: "basicRenderer", 
  PBRT: "basicRenderer", PBRP: "basicRenderer", PBRR: "nebikiRateRenderer", PBRD: "basicRenderer", 
  PBTT: "basicRenderer", PBTP: "basicRenderer", PBTR: "rateRenderer", PBTD: "basicRenderer", 
  PBHT: "pbRateRenderer", PBHP: "pbRateRenderer", PBHD: "basicRenderer",
  YHLT: "basicRenderer", YHLP: "basicRenderer", YHLR: "rateRenderer", YHLD: "basicRenderer", 
  YHDT: "basicRenderer", YHDP: "basicRenderer", YHDR: "rateRenderer", YHDD: "basicRenderer", 
  YHRT: "basicRenderer", YHRP: "basicRenderer", YHRR: "nebikiRateRenderer", YHRD: "basicRenderer", 
  YHTT: "basicRenderer", YHTP: "basicRenderer", YHTR: "rateRenderer", YHTD: "basicRenderer", 
  YHHT: "pbRateRenderer", YHHP: "pbRateRenderer", YHHD: "basicRenderer",
  PTT: 'basicRenderer', PTP: 'basicRenderer', PTR: 'rateRenderer', PTD: 'basicRenderer', PTB: 'basicRenderer', PTBR: 'rateRenderer', PTBD: 'basicRenderer', 
  PRT: 'basicRenderer', PRP: 'basicRenderer', PRD: 'basicRenderer',
  DDT: 'basicRenderer', DDP: 'basicRenderer', DDR: 'nebikiRateRenderer', DDD: 'basicRenderer', 
  DBT: 'basicRenderer', DBP: 'basicRenderer', DBR: 'nebikiRateRenderer', DBD: 'basicRenderer', 
  DQT: 'basicRenderer', DQP: 'basicRenderer', DQR: 'nebikiRateRenderer', DQD: 'basicRenderer', 
  DFT: 'basicRenderer', DFP: 'basicRenderer', DFR: 'nebikiRateRenderer', DFD: 'basicRenderer', 
  DTT: 'basicRenderer', DTP: 'basicRenderer', DTR: 'nebikiRateRenderer', DTD: 'basicRenderer', DTB: 'basicRenderer', DTBR: 'nebikiRateRenderer', DTBD: 'basicRenderer', 
  DRT: 'basicRenderer', DRP: 'basicRenderer', DRD: 'basicRenderer',
  NTT: 'basicRenderer', NTP: 'basicRenderer', NTR: 'rateRenderer', NTD: 'basicRenderer', NTB: 'basicRenderer', NTBR: 'rateRenderer', NTBD: 'basicRenderer',
  CIT: 'basicRenderer', CIP: 'basicRenderer', CIR: 'rateRenderer', CID: 'basicRenderer', 
  CST: 'basicRenderer', CSP: 'basicRenderer', CSR: 'rateRenderer', CSD: 'basicRenderer', 
  CTT: 'basicRenderer', CTP: 'basicRenderer', CTR: 'rateRenderer', CTD: 'basicRenderer', CTB: 'basicRenderer', CTBR: 'rateRenderer', CTBD: 'basicRenderer',
  OCT: 'basicRenderer', OCP: 'basicRenderer', OCR: 'nebikiRateRenderer', OCD: 'basicRenderer',
  TST: 'basicRenderer', TSP: 'basicRenderer', TSR: 'nebikiRateRenderer', TSD: 'basicRenderer', 
  CSTT: 'basicRenderer', CSTP: 'basicRenderer', CSTR: 'nebikiRateRenderer', CSTD: 'basicRenderer', CSTB: 'basicRenderer', CSTBR: 'nebikiRateRenderer', CSTBD: 'basicRenderer',
  CSTRT: 'basicRenderer', CSTRP: 'basicRenderer', CSTRD: 'basicRenderer',
  OPT: 'basicRenderer', OPP: 'basicRenderer', OPR: 'nebikiRateRenderer', OPD: 'basicRenderer',
  TPT: 'basicRenderer', TPP: 'basicRenderer', TPR: 'nebikiRateRenderer', TPD: 'basicRenderer', 
  PPTT: 'basicRenderer', PPTP: 'basicRenderer', PPTR: 'nebikiRateRenderer', PPTD: 'basicRenderer', PPTB: 'basicRenderer', PPTBR: 'nebikiRateRenderer', PPTBD: 'basicRenderer',
  PPTRT: 'basicRenderer', PPTRP: 'basicRenderer', PPTRD: 'basicRenderer',
  OET: 'basicRenderer', OEP: 'basicRenderer', OER: 'nebikiRateRenderer', OED: 'basicRenderer',
  TET: 'basicRenderer', TEP: 'basicRenderer', TER: 'nebikiRateRenderer', TED: 'basicRenderer', 
  ETT: 'basicRenderer', ETP: 'basicRenderer', ETR: 'nebikiRateRenderer', ETD: 'basicRenderer', ETB: 'basicRenderer', ETBR: 'nebikiRateRenderer', ETBD: 'basicRenderer',
  ETRT: 'basicRenderer', ETRP: 'basicRenderer', ETRD: 'basicRenderer',
  KTT: 'basicRenderer', KTP: 'basicRenderer', KTR: 'nebikiRateRenderer', KTD: 'basicRenderer', 
  KRT: 'basicRenderer', KRP: 'basicRenderer', KRD: 'basicRenderer',
  BTT: 'basicRenderer', BTP: 'basicRenderer', BTR: 'rateRenderer', BTD: 'basicRenderer', 
  BRT: 'basicRenderer', BRP: 'basicRenderer', BRD: 'basicRenderer', 
  ATT: 'basicRenderer', ATP: 'basicRenderer', ATR: 'rateRenderer', ATD: 'basicRenderer', 
  ART: 'basicRenderer', ARP: 'basicRenderer', ARD: 'basicRenderer', 
  GTT: 'basicRenderer', GTP: 'basicRenderer', GTR: 'rateRenderer', GTD: 'basicRenderer', GTB: 'basicRenderer', GTBR: 'rateRenderer', GTBD: 'basicRenderer', 
  GRT: 'basicRenderer', GRP: 'basicRenderer', GRD: 'basicRenderer', 
  ZTT: 'basicRenderer', ZTP: 'basicRenderer', ZTR: 'rateRenderer', ZTD: 'basicRenderer', ZTB: 'basicRenderer', ZTBR: 'rateRenderer', ZTBD: 'basicRenderer', 
  ZRT: 'basicRenderer', ZRP: 'basicRenderer', ZRD: 'basicRenderer',  
  ZCT: 'basicRenderer', ZCP: 'basicRenderer', ZCR: 'rateRenderer', ZCD: 'basicRenderer', 
  ZCRT: 'basicRenderer', ZCRP: 'basicRenderer', ZCRD: 'basicRenderer',  
  CNDT: 'basicRenderer', CNDP: 'basicRenderer', CNDR: 'nebikiRateRenderer', CNDD: 'basicRenderer', CNDRT: "basicRenderer", CNDRP: "basicRenderer", CNDRD: "basicRenderer",
  CNPT: 'basicRenderer', CNPP: 'basicRenderer', CNPR: 'nebikiRateRenderer', CNPD: 'basicRenderer', CNPRT: "basicRenderer", CNPRP: "basicRenderer", CNPRD: "basicRenderer",
  CNGT: 'basicRenderer', CNGP: 'basicRenderer', CNGR: 'nebikiRateRenderer', CNGD: 'basicRenderer', CNGRT: "basicRenderer", CNGRP: "basicRenderer", CNGRD: "basicRenderer",
  CNAT: 'basicRenderer', CNAP: 'basicRenderer', CNAR: 'rateRenderer', CNAD: 'basicRenderer', CNART: "basicRenderer", CNARP: "basicRenderer", CNARD: "basicRenderer",
  CNET: 'basicRenderer', CNEP: 'basicRenderer', CNER: 'nebikiRateRenderer', CNED: 'basicRenderer', CNRT: 'basicRenderer', CNRP: 'basicRenderer', CNRD: 'basicRenderer',
  SNET: 'basicRenderer', SNEP: 'basicRenderer', SNER: 'nebikiRateRenderer', SNED: 'basicRenderer', SNRT: 'basicRenderer', SNRP: 'basicRenderer', SNRD: 'basicRenderer',
  ORST: 'basicRenderer', ORSP: 'basicRenderer', ORSR: 'nebikiRateRenderer', ORSD: 'basicRenderer', ORSRT: 'basicRenderer', ORSRP: 'basicRenderer', ORSRD: 'basicRenderer',
  ORPT: 'basicRenderer', ORPP: 'basicRenderer', ORPR: 'rateRenderer', ORPD: 'basicRenderer', ORPRT: 'basicRenderer', ORPRP: 'basicRenderer', ORPRD: 'basicRenderer',
  SHCT: 'basicRenderer', SHCP: 'basicRenderer', SHCR: 'nebikiRateRenderer', SHCD: 'basicRenderer', 
  SHDT: 'basicRenderer', SHDP: 'basicRenderer', SHDR: 'nebikiRateRenderer', SHDD: 'basicRenderer', 
  SHRCT: 'basicRenderer', SHRCP: 'basicRenderer', SHRCR: 'rateRenderer', SHRCD: 'basicRenderer', 
  SHRDT: 'basicRenderer', SHRDP: 'basicRenderer', SHRDR: 'rateRenderer', SHRDD: 'basicRenderer', 
  SHT: 'basicRenderer', SHP: 'basicRenderer', SHR: 'nebikiRateRenderer', SHD: 'basicRenderer', 
  CCDT: 'basicRenderer', CDDP: 'basicRenderer',
  CDDR: 'basicRenderer', CDDD: 'basicRenderer', 
  CDBT: 'basicRenderer', CDBP: 'basicRenderer', CDBR: 'basicRenderer', CDBD: 'basicRenderer', 
  CDFT: 'basicRenderer', CDFP: 'basicRenderer', CDFR: 'basicRenderer', CDFD: 'basicRenderer', 
  COCT: 'basicRenderer', COCP: 'basicRenderer', COCR: 'basicRenderer', COCD: 'basicRenderer', 
  COPT: 'basicRenderer', COPP: 'basicRenderer', COPR: 'basicRenderer', COPD: 'basicRenderer', 
  COET: 'basicRenderer', COEP: 'basicRenderer', COER: 'basicRenderer', COED: 'basicRenderer', 
  CTPT: 'basicRenderer', CTPP: 'basicRenderer', CTPR: 'basicRenderer', CTPD: 'basicRenderer', 
  CTST: 'basicRenderer', CTSP: 'basicRenderer', CTSR: 'basicRenderer', CTSD: 'basicRenderer', 
  CTET: 'basicRenderer', CTEP: 'basicRenderer', CTER: 'basicRenderer', CTED: 'basicRenderer', 
  //構成比
  JJTK: 'kouseihiRenderer', JJPK: 'kouseihiRenderer', 
  JKTK: 'kouseihiRenderer', JKPK: 'kouseihiRenderer', 
  SLTK: 'kouseihiRenderer', SLPK: 'kouseihiRenderer', SDTK: 'kouseihiRenderer', SDPK: 'kouseihiRenderer', SRTK: 'kouseihiRenderer', SRPK: 'kouseihiRenderer', STTK: 'kouseihiRenderer', STPK: 'kouseihiRenderer',
  PBLTK: 'kouseihiRenderer', PBLPK: 'kouseihiRenderer', PBDTK: 'kouseihiRenderer', PBDPK: 'kouseihiRenderer', PBRTK: 'kouseihiRenderer', PBRPK: 'kouseihiRenderer', PBTTK: 'kouseihiRenderer', PBTPK: 'kouseihiRenderer',
  YHLTK: 'kouseihiRenderer', YHLPK: 'kouseihiRenderer', YHDTK: 'kouseihiRenderer', YHDPK: 'kouseihiRenderer', YHRTK: 'kouseihiRenderer', YHRPK: 'kouseihiRenderer', YHTTK: 'kouseihiRenderer', YHTPK: 'kouseihiRenderer',
  PTTK: 'kouseihiRenderer', PTPK: 'kouseihiRenderer',
  DDTK: 'kouseihiRenderer', DDPK: 'kouseihiRenderer', DBTK: 'kouseihiRenderer', DBPK: 'kouseihiRenderer', DQTK: 'kouseihiRenderer', DQPK: 'kouseihiRenderer', DFTK: 'kouseihiRenderer', DFPK: 'kouseihiRenderer', DTTK: 'kouseihiRenderer', DTPK: 'kouseihiRenderer',
  NTTK: 'kouseihiRenderer', NTPK: 'kouseihiRenderer',
  CITK: 'kouseihiRenderer', CIPK: 'kouseihiRenderer', CSTK: 'kouseihiRenderer', CSPK: 'kouseihiRenderer', CTTK: 'kouseihiRenderer', CTPK: 'kouseihiRenderer', 
  OCTK: 'kouseihiRenderer', OCPK: 'kouseihiRenderer', 
  TSTK: 'kouseihiRenderer', TSPK: 'kouseihiRenderer',
  CSTTK: 'kouseihiRenderer', CSTPK: 'kouseihiRenderer',
  OPTK: 'kouseihiRenderer', OPPK: 'kouseihiRenderer',
  TPTK: 'kouseihiRenderer', TPPK: 'kouseihiRenderer',
  PPTTK: 'kouseihiRenderer', PPTPK: 'kouseihiRenderer',
  OETK: 'kouseihiRenderer', OEPK: 'kouseihiRenderer',
  TETK: 'kouseihiRenderer', TEPK: 'kouseihiRenderer',
  ETTK: 'kouseihiRenderer', ETPK: 'kouseihiRenderer',
  KTTK: 'kouseihiRenderer', KTPK: 'kouseihiRenderer',
  BTTK: 'kouseihiRenderer', BTPK: 'kouseihiRenderer',
  ATTK: 'kouseihiRenderer', ATPK: 'kouseihiRenderer',
  GTTK: 'kouseihiRenderer', GTPK: 'kouseihiRenderer',
  ZTTK: 'kouseihiRenderer', ZTPK: 'kouseihiRenderer', ZCTK: 'kouseihiRenderer', ZCPK: 'kouseihiRenderer',
  CCDTK: 'kouseihiRenderer', CDDPK: 'kouseihiRenderer', CDBTK: 'kouseihiRenderer', CDBPK: 'kouseihiRenderer', CDFTK: 'kouseihiRenderer', CDFPK: 'kouseihiRenderer', COCTK: 'kouseihiRenderer', COCPK: 'kouseihiRenderer', COPTK: 'kouseihiRenderer', COPPK: 'kouseihiRenderer', COETK: 'kouseihiRenderer', COEPK: 'kouseihiRenderer', CTPTK: 'kouseihiRenderer', CTPPK: 'kouseihiRenderer', CTSTK: 'kouseihiRenderer', CTSPK: 'kouseihiRenderer', CTETK: 'kouseihiRenderer', CTEPK: 'kouseihiRenderer',
  //予算構成比
  STBK: 'kouseihiRenderer', PTBK: 'kouseihiRenderer', DTBK: 'kouseihiRenderer', NTBK: 'kouseihiRenderer', CTBK: 'kouseihiRenderer', GTBK: 'kouseihiRenderer', ZTBK: 'kouseihiRenderer', 
};

//前年実績がマイナスの場合表示を変更する項目
export const preMinusList = [
  { cur: 'JJT', pre: 'JJP', key: 'JJR', },    //受注
  { cur: 'SLT', pre: 'SLP', key: 'SLR', },    //総売上配送売上
  { cur: 'SDT', pre: 'SDP', key: 'SDR', },    //総売上直送売上
  { cur: 'STT', pre: 'STP', key: 'STR', },    //総売上総売上
  { cur: 'PBLT', pre: 'PBLP', key: 'PBLR', }, //PB売上PB配送売上
  { cur: 'PBDT', pre: 'PBDP', key: 'PBDR', }, //PB売上PB直送売上
  { cur: 'PBTT', pre: 'PBTP', key: 'PBTR', }, //PB売上PB売上
  { cur: 'YHLT', pre: 'YHLP', key: 'YHLR', }, //焼半生売上焼半生配送売上
  { cur: 'YHDT', pre: 'YHDP', key: 'YHDR', }, //焼半生売上焼半生直送売上
  { cur: 'YHTT', pre: 'YHTP', key: 'YHTR', }, //焼半生売上焼半生売上
  { cur: 'NTT', pre: 'NTP', key: 'NTR', },    //純売上純売上
  { cur: 'CIT', pre: 'CIP', key: 'CIR', },    //修正入力修正
  { cur: 'CST', pre: 'CSP', key: 'CSR', },    //修正黒修正
  { cur: 'CTT', pre: 'CTP', key: 'CTR', },    //修正修正合計
  { cur: 'OET', pre: 'OEP', key: 'OER', },    //ＥＯＳ費・他ＥＯＳ費・他（相殺）
  { cur: 'TET', pre: 'TEP', key: 'TER', },    //ＥＯＳ費・他ＥＯＳ費・他（振込）
  { cur: 'ETT', pre: 'ETP', key: 'ETR', },    //ＥＯＳ費・他ＥＯＳ費・他
  { cur: 'BTT', pre: 'BTP', key: 'BTR', },    //総利益修正前企業粗利
  { cur: 'ATT', pre: 'ATP', key: 'ATR', },    //総利益修正後企業粗利
  { cur: 'GTT', pre: 'GTP', key: 'GTR', },    //総利益修正後粗利益
  { cur: 'ZTT', pre: 'ZTP', key: 'ZTR', },    //総利益売上総利益
  { cur: 'ZCT', pre: 'ZCP', key: 'ZCR', },    //企業粗利企業粗利
  { cur: 'CDBT', pre: 'CDBP', key: 'CDBR', }, //補正リベート補正
  { cur: 'CDFT', pre: 'CDFP', key: 'CDFR', }, //補正振込リベート補正
  { cur: 'COET', pre: 'COEP', key: 'COER', }, //補正ＥＯＳ費・他（相殺）補正
  { cur: 'CTST', pre: 'CTSP', key: 'CTSR', }, //補正業務委託費（振込）補正
  { cur: 'CTET', pre: 'CTEP', key: 'CTER', }, //補正ＥＯＳ費・他（振込）補正
];
//前年実績がプラスの場合表示を変更する項目
export const prePlusList = [
  { cur: 'JKT', pre: 'JKP', key: 'JKR', },    //欠品
  { cur: 'SRT', pre: 'SRP', key: 'SRR', },    //総売上返品
  { cur: 'PBRT', pre: 'PBRP', key: 'PBRR', }, //PB売上PB返品
  { cur: 'YHRT', pre: 'YHRP', key: 'YHRR', }, //焼半生売上焼半生返品
  { cur: 'DDT', pre: 'DDP', key: 'DDR', },    //値引値引
  { cur: 'DBT', pre: 'DBP', key: 'DBR', },    //値引リベート
  { cur: 'DQT', pre: 'DQP', key: 'DQR', },    //値引リベート建値
  { cur: 'DFT', pre: 'DFP', key: 'DFR', },    //値引振込リベート
  { cur: 'DTT', pre: 'DTP', key: 'DTR', },    //値引値引計
  { cur: 'OCT', pre: 'OCP', key: 'OCR', },    //センターフィセンターフィ（相殺）
  { cur: 'TST', pre: 'TSP', key: 'TSR', },    //センターフィ業務委託費（振込）
  { cur: 'CSTT', pre: 'CSTP', key: 'CSTR', }, //センターフィセンターフィ
  { cur: 'OPT', pre: 'OPP', key: 'OPR', },    //販促費販促費（相殺）
  { cur: 'TPT', pre: 'TPP', key: 'TPR', },    //販促費販促費（振込）
  { cur: 'PPTT', pre: 'PPTP', key: 'PPTR', }, //販促費販促費
  { cur: 'KTT', pre: 'KTP', key: 'KTR', },    //得意先課金計得意先課金計
  { cur: 'CCDT', pre: 'CDDP', key: 'CDDR', }, //補正値引補正
  { cur: 'COCT', pre: 'COCP', key: 'COCR', }, //補正センターフィ（相殺）補正
  { cur: 'COPT', pre: 'COPP', key: 'COPR', }, //補正販促費（相殺）補正
  { cur: 'CTPT', pre: 'CTPP', key: 'CTPR', }, //補正販促費（振込）補正
];


//列定義情報（動的パラメータ）
interface SalesListColModelInterface {
  //列固定
  fixedColumnsLeft: number,

  //テーブルの各列のキー
  colKeys: string[];
}

export class SalesListColModel implements SalesListColModelInterface {
  //列固定
  fixedColumnsLeft: number;
  //テーブルの各列のキー
  colKeys: string[];
  //ヘッダー
  nestedHeaders; //any[][]
  //テーブルの各列の逆引き用マップ
  colKeysMap;
  
  constructor(param: SalesListColModelInterface) {
    this.fixedColumnsLeft = param.fixedColumnsLeft;
    this.colKeys = param.colKeys;

    //テーブルの各列のキー逆引き
    const colKeysMap = {};
    param.colKeys.forEach((colKey, index) => {
      colKeysMap[colKey] = index;
    });
    this.colKeysMap = colKeysMap;

    //nestedHeaders初期化
    this.initNestedHeaders();
  }

  //nestedHeaders初期化
  initNestedHeaders() {
    //nestedHeaders
    const nestedHeaders = [];
    // const customBorders = [];
    const func = (nestedHeaders:any[][], colKeys:string[])=>{
      headers.forEach((header, index) => {
        const lastHeader = index == headers.length - 1;
        const nestedHeader = [];
  
        let colspan=0;
        let preHeaderVal = null;
        colKeys.forEach((colKey) => {
          const headerVal = header[colKey];
          if(lastHeader) {
            nestedHeader.push(headerVal);
          }
          else {
            if(preHeaderVal !== null && preHeaderVal != headerVal){
              nestedHeader.push(colspan == 1 ? preHeaderVal : {label: preHeaderVal, colspan: colspan});
              colspan=0;
            }
            colspan++;
            preHeaderVal = headerVal;
          }
        });
        if(!lastHeader && colspan > 0) {
          nestedHeader.push(colspan == 1 ? preHeaderVal : {label: preHeaderVal, colspan: colspan});
        }
  
        nestedHeaders.push(nestedHeader);
      });
    }

    func(nestedHeaders, this.colKeys);
    this.nestedHeaders = nestedHeaders;
  }

  //function
  keyFromCol(column:number, dataType:"daily" | "quick" | "commit" | "weekly"):string {
    return this.colKeys[column];
  }
  keyFromCols(columns:number[], dataType:"daily" | "quick" | "commit" | "weekly"):string[] {
    const vals:string[] = [];
    columns.forEach(column => vals.push(this.colKeys[column]))
    return vals;
  }
  colFromKey(colKey:string, dataType:"daily" | "quick" | "commit" | "weekly"):number {
    return this.colKeysMap[colKey];
  }
  colFromKeys(colKeys:string[], dataType:"daily" | "quick" | "commit" | "weekly"):number[] {
    const vals:number[] = [];
    // colKeys.forEach(colKey => vals.push(this.colKeysMap[colKey]))
    colKeys.forEach(colKey => {if(this.colKeysMap[colKey]) vals.push(this.colKeysMap[colKey])});
    return vals;
  }
}

//センター経費
export const colKeysKeihi: string[] = [
  "CNDT", "CNDP", "CNDR", "CNDD", "CNDRT", "CNDRP", "CNDRD",
  "CNPT", "CNPP", "CNPR", "CNPD", "CNPRT", "CNPRP", "CNPRD",
  "CNGT", "CNGP", "CNGR", "CNGD", "CNGRT", "CNGRP", "CNGRD",
  "CNAT", "CNAP", "CNAR", "CNAD", "CNART", "CNARP", "CNARD",
  "CNET", "CNEP", "CNER", "CNED", "CNRT", "CNRP", "CNRD",
  "SNET", "SNEP", "SNER", "SNED", "SNRT", "SNRP", "SNRD",
  "ORST", "ORSP", "ORSR", "ORSD", "ORSRT", "ORSRP", "ORSRD",
  "ORPT", "ORPP", "ORPR", "ORPD", "ORPRT", "ORPRP", "ORPRD",
];
//仕入実績
export const colKeysShiire: string[] = [
  "SHCT", "SHCP", "SHCR", "SHCD", 
  "SHDT", "SHDP", "SHDR", "SHDD", 
  "SHRCT", "SHRCP", "SHRCR", "SHRCD", 
  "SHRDT", "SHRDP", "SHRDR", "SHRDD", 
  "SHT", "SHP", "SHR", "SHD",
];
export const colKeys40: string[] = [
  "CDIV",
  "TDIV",
];
const colKeys: string[] = [
  "no",

  //以下に貼り付け
  "H1CD", "H1NM",
  ...colKeys40,
  "H2CD", "H2NM", "H3CD", "H3NM", "H4CD", "H4NM", "H5CD", "H5NM", "H6CD", "H6NM", "H7CD", "H7NM", "H8CD", "H8NM",

  "JJT", "JJTK", "JJP", "JJPK", "JJR", "JJD",

  "SLT", "SLTK", "SLP", "SLPK", "SLR", "SLD",
  "SDT", "SDP", "SDR", "SDD",
  "SRT", "SRP", "SRR", "SRD",
  "STT", "STTK", "STP", "STPK", "STR", "STD", "STB", "STBR", "STBD",
  "PBLT", "PBLP", "PBLR", "PBLD",
  "PBDT", "PBDP", "PBDR", "PBDD",
  "PBRT", "PBRP", "PBRR", "PBRD",
  "PBTT", "PBTTK", "PBTP", "PBTPK", "PBTR", "PBTD",
  "PBHT", "PBHP", "PBHD",
  "YHLT", "YHLP", "YHLR", "YHLD",
  "YHDT", "YHDP", "YHDR", "YHDD",
  "YHRT", "YHRP", "YHRR", "YHRD",
  "YHTT", "YHTTK", "YHTP", "YHTPK", "YHTR", "YHTD",
  "YHHT", "YHHP", "YHHD",
  "PTT", "PTTK", "PTP", "PTPK", "PTR", "PTD", "PTB", "PTBR", "PTBD",
  "PRT", "PRP", "PRD",

  "JKT", "JKTK", "JKP", "JKPK", "JKR", "JKD",
  "JRT", "JRP", "JRD",
  //欠品責任別
  "KI1KA", "KI1KR", "KI1KK",
  "KI2KA", "KI2KR", "KI2KK",
  "KI3KA", "KI3KR", "KI3KK",

  "NTT", "NTTK", "NTP", "NTPK", "NTR", "NTD", "NTB", "NTBR", "NTBD",
  "CIT", "CIP", "CIR", "CID",
  "CST", "CSP", "CSR", "CSD",
  "CTT", "CTP", "CTR", "CTD", "CTB", "CTBR", "CTBD",

  "DDT", "DDP", "DDR", "DDD",
  "DBT", "DBP", "DBR", "DBD",
  "DQT", "DQP", "DQR", "DQD",
  "DFT", "DFP", "DFR", "DFD",
  "DTT", "DTP", "DTR", "DTD", "DTB", "DTBR", "DTBD",
  "DRT", "DRP", "DRD",

  "BTT", "BTP", "BTR", "BTD",
  "BRT", "BRP", "BRD",
  "ATT", "ATP", "ATR", "ATD",
  "ART", "ARP", "ARD",
  "GTT", "GTP", "GTR", "GTD", "GTB", "GTBK", "GTBR", "GTBD",
  "GRT", "GRP", "GRD",
  "ZTT", "ZTP", "ZTR", "ZTD", "ZTB", "ZTBK", "ZTBR", "ZTBD",
  "ZRT", "ZRP", "ZRD",

  "OCT", "OCP", "OCR", "OCD",
  "TST", "TSP", "TSR", "TSD",
  "CSTT", "CSTP", "CSTR", "CSTD", "CSTB", "CSTBR", "CSTBD",
  "CSTRT", "CSTRP", "CSTRD",
  "OPT", "OPP", "OPR", "OPD",
  "TPT", "TPP", "TPR", "TPD",
  "PPTT", "PPTP", "PPTR", "PPTD", "PPTB", "PPTBR", "PPTBD",
  "PPTRT", "PPTRP", "PPTRD",
  "OET", "OEP", "OER", "OED",
  "TET", "TEP", "TER", "TED",
  "ETT", "ETP", "ETR", "ETD", "ETB", "ETBR", "ETBD",
  "ETRT", "ETRP", "ETRD",

  "CCDT", "CDDP", "CDDR", "CDDD",
  "CDBT", "CDBP", "CDBR", "CDBD",
  "CDFT", "CDFP", "CDFR", "CDFD",
  "COCT", "COCP", "COCR", "COCD",
  "COPT", "COPP", "COPR", "COPD",
  "COET", "COEP", "COER", "COED",
  "CTPT", "CTPP", "CTPR", "CTPD",
  "CTST", "CTSP", "CTSR", "CTSD",
  "CTET", "CTEP", "CTER", "CTED",

  "KTT", "KTP", "KTR", "KTD",
  "KRT", "KRP", "KRD",

  "ZCT", "ZCTK", "ZCP", "ZCPK", "ZCR", "ZCD",
  "ZCRT", "ZCRP", "ZCRD",

  ...colKeysKeihi,
  ...colKeysShiire,

  //欠品区分別
  "K10KA", "K10KR", "K10KK",
  "K11KA", "K11KR", "K11KK",
  "K12KA", "K12KR", "K12KK",
  // "K13KA", "K13KR", "K13KK",
  "K14KA", "K14KR", "K14KK",
  "K15KA", "K15KR", "K15KK",
  "K16KA", "K16KR", "K16KK",
  "K17KA", "K17KR", "K17KK",
  "K19KA", "K19KR", "K19KK",

  "K20KA", "K20KR", "K20KK",
  "K21KA", "K21KR", "K21KK",
  "K22KA", "K22KR", "K22KK",
  "K29KA", "K29KR", "K29KK",

  "K30KA", "K30KR", "K30KK",
  "K31KA", "K31KR", "K31KK",
  // "K32KA", "K32KR", "K32KK",
  // "K33KA", "K33KR", "K33KK",
  "K34KA", "K34KR", "K34KK",
  // "K39KA", "K39KR", "K39KK",
  "K40KA", "K40KR", "K40KK",
  "K41KA", "K41KR", "K41KK",
  "K42KA", "K42KR", "K42KK",
  "K43KA", "K43KR", "K43KK",
  // "K44KA", "K44KR", "K44KK",
  // "K49KA", "K49KR", "K49KK",
  "K50KA", "K50KR", "K50KK",  

];
//受注
const colKeysJutyu: string[] = [
  "JJT", "JJTK", "JJP", "JJPK", "JJR", "JJD",
];
//欠品
const colKeysKeppin: string[] = [
  "JKT", "JKTK", "JKP", "JKPK", "JKR", "JKD",
  "JRT", "JRP", "JRD",
  //欠品責任別
  "KI1KA", "KI1KR", "KI1KK",
  "KI2KA", "KI2KR", "KI2KK",
  "KI3KA", "KI3KR", "KI3KK",

  //欠品区分別
  "K10KA", "K10KR", "K10KK",
  "K11KA", "K11KR", "K11KK",
  "K12KA", "K12KR", "K12KK",
  // "K13KA", "K13KR", "K13KK",
  "K14KA", "K14KR", "K14KK",
  "K15KA", "K15KR", "K15KK",
  "K16KA", "K16KR", "K16KK",
  "K17KA", "K17KR", "K17KK",
  "K19KA", "K19KR", "K19KK",

  "K20KA", "K20KR", "K20KK",
  "K21KA", "K21KR", "K21KK",
  "K22KA", "K22KR", "K22KK",
  "K29KA", "K29KR", "K29KK",

  "K30KA", "K30KR", "K30KK",
  "K31KA", "K31KR", "K31KK",
  // "K32KA", "K32KR", "K32KK",
  // "K33KA", "K33KR", "K33KK",
  "K34KA", "K34KR", "K34KK",
  // "K39KA", "K39KR", "K39KK",
  "K40KA", "K40KR", "K40KK",
  "K41KA", "K41KR", "K41KK",
  "K42KA", "K42KR", "K42KK",
  "K43KA", "K43KR", "K43KK",
  // "K44KA", "K44KR", "K44KK",
  // "K49KA", "K49KR", "K49KK",
  "K50KA", "K50KR", "K50KK",  

];

//SalesList用の列モデル
export const salesListColModelDefault:SalesListColModel = new SalesListColModel({
  //列固定
  fixedColumnsLeft: 17,

  //テーブルの各列のキー
  colKeys: arrayUtil.not(colKeys, colKeys40),
});

//SalesListReport10用の列モデル
export const salesListColModelReport10: SalesListColModel = new SalesListColModel({
  //列固定
  fixedColumnsLeft: 17,

  //テーブルの各列のキー
  colKeys: arrayUtil.not(colKeys, colKeys40),
});

//SalesListReport20用の列モデル
export const salesListColModelReport20: SalesListColModel = new SalesListColModel({
  //列固定
  fixedColumnsLeft: 17,

  //テーブルの各列のキー
  colKeys: arrayUtil.not(colKeys, colKeys40),
});

//SalesListReport21用の列モデル
export const salesListColModelReport21: SalesListColModel = new SalesListColModel({
  //列固定
  fixedColumnsLeft: 17,

  //テーブルの各列のキー
  colKeys: arrayUtil.not(colKeys, colKeys40),
});

//SalesListReport30用の列モデル
export const salesListColModelReport30:SalesListColModel = new SalesListColModel({
  //列固定
  fixedColumnsLeft: 17,

  //テーブルの各列のキー
  colKeys: arrayUtil.not(colKeys, arrayUtil.union(arrayUtil.union(colKeysJutyu, colKeysKeppin), colKeys40)),
});

//SalesListReport40用の列モデル
export const salesListColModelReport40:SalesListColModel = new SalesListColModel({
  //列固定
  fixedColumnsLeft: 17,

  //テーブルの各列のキー
  colKeys: arrayUtil.not(colKeys, arrayUtil.union(colKeysJutyu, colKeysKeppin)),
});

