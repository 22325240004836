import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { colFromKeys } from "@/components/itemlist/ItemListGroup2Model";

export const defaultHiddenColumnsKey = [
  //以下に貼り付け
  'KGC1', 'KGG1'
];

//Page State
export type ItemListGroup2SaveState = {
  sort: { key: string, asc: boolean },

  hiddenColumns: number[],
  hiddenColumnsKey: string[],
  group: 'group2' | 'group1' | null,
};

export const initialState: ItemListGroup2SaveState = {
  sort: {
    key: "HQTY1", //仕入数累計
    asc: false, //降順
  },
 
  hiddenColumns: [],
  hiddenColumnsKey: defaultHiddenColumnsKey,
  group: 'group2',
};

//Page Slice
const createSliceContent = (name: string) => createSlice({
  name: name,
  initialState,
  reducers: {
    //componentDidMount
    initOnDidMount(state: ItemListGroup2SaveState) {
      //追加属性等はlocalstorageから復元したときにundefinedになっている場合があるためチェックする
      if (!state.sort || !state.sort.key) {
        state.sort = initialState.sort;
      }
      if (!state.sort || !state.sort.key) {
        state.sort = initialState.sort;
      }
      if (!state.hiddenColumnsKey) {
        state.hiddenColumnsKey = initialState.hiddenColumnsKey;
      }
      if (!state.group) {
        state.group = initialState.group;
      }
    },
    setSort(state: ItemListGroup2SaveState, action: PayloadAction<{ sortKey: string, sortAsc: boolean }>) {
      console.log('zaiko setSort');
      const key = action.payload.sortKey;
      const asc = action.payload.sortAsc;
      state.sort = {
        key: key,
        asc: asc,
      }
    },
    hideColumnsChange(state: ItemListGroup2SaveState, action: PayloadAction<{ columns: number[], keys: string[] }>) {
      Object.assign(state, {
        hiddenColumns: action.payload.columns,
        hiddenColumnsKey: action.payload.keys,
      });
    },
    hideColumnKeysChange(state: ItemListGroup2SaveState, action: PayloadAction<string[]>) {
      Object.assign(state, {
        hiddenColumns: colFromKeys(action.payload),
        hiddenColumnsKey: action.payload,
      });
    },
    setGroup(state: ItemListGroup2SaveState, action: PayloadAction<string>) {
      switch (action.payload) {
        case "group2":
          state.group = "group2";
          break;
        case "group1":
          state.group = "group1";
          break;
        default:
          state.group = "group2";
          break;
      }
    },
  },
});

//Page Slice Export
//itemListGroup2Save
export const itemListGroup2SaveSlice = createSliceContent("itemListGroup2Save");
