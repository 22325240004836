import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CodeName } from "@/store/common";

import { CenterItem, KigenData, SyukkaData, listSortOrders, centerItemAttributes } from '@/store/zaikokigen/zaikoKigenCommon'
import * as zaikoKigenRetrieve from "@/assets/apitype/zaikoKigenRetrieve";
import { ZaikoKigenColRowModel, rowKeys, headersRow, colDataType, rowDataType, zaikoKbnMixList, zaikoKbnList, zaikoKbn0602List } from "@/components/zaikokigen/ZaikoKigenTableModel";
import * as compareUtil from "@/util/compareUtil";
import * as arrayutil from "@/util/arrayUtil";
import * as editorUtil from "@/util/editorUtil";
import * as calcUtil from "@/util/calcUtil";

//0.00フォーマット
const formatterP00 = new Intl.NumberFormat('ja-JP', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 });
//0,0フォーマット
const formatterN0 = new Intl.NumberFormat('ja-JP', { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0 });

export type RowDataGroup = {
  centerItem: CenterItem,
  syukkaData?: SyukkaData,
  kigenData?: KigenData,
}

export type RowInfo = {
  TP: "item" | "maker" | "center" | "kigen",
  no?: string,
  subno?: string,
  dataGroup:RowDataGroup,
  rowKey?: string,
}

//Page State
export type ZaikoKigenTmpState = {
  accordionOpen: boolean,

  makerList: CodeName[],  //メーカー
  bumonList: CodeName[], //部門
  centerList: CodeName[], //倉庫

  progress: Record<string, unknown>,
  retrieveParam: zaikoKigenRetrieve.RequestParam,  //検索条件(検索済み)
  retrievedParam: zaikoKigenRetrieve.RequestParam,  //検索条件(検索済み)
  shareParam: zaikoKigenRetrieve.RequestParam,
  shareURL: string,  //共有URL

  dataGroupsAll: RowDataGroup[],
  rowInfosAll: RowInfo[],
  rowInfos: RowInfo[],
  fixedRowsTop :number,
  rows: any[][],
  mergeCells: {row: number, col: number, rowspan: number, colspan: number}[],

  lastUpdateTC: string | null,
  lastUpdateDC: string | null,
  lastUpdateHenpin: string | null,
  errorMessage: string | null,
};

export const initialState: ZaikoKigenTmpState = {
  accordionOpen: true,
  makerList: [],
  bumonList: [],
  centerList: [],

  progress: {},
  retrieveParam: null,
  retrievedParam: null,
  shareParam: null,
  shareURL: '',  //共有URL

  dataGroupsAll: [],
  rowInfosAll: [],
  rowInfos: [],
  fixedRowsTop :0,
  rows: [],
  mergeCells: null,

  lastUpdateTC: null,
  lastUpdateDC: null,
  lastUpdateHenpin: null,
  errorMessage: null,
};

//Page Slice
export type ZaikoKigenTmpReducer = {
  setAccordionOpen: (state:ZaikoKigenTmpState, action: PayloadAction<boolean>) => void,
  setShareURL: (state: ZaikoKigenTmpState, action: PayloadAction<string>) => void,
  setShareParam: (state: ZaikoKigenTmpState, action: PayloadAction<zaikoKigenRetrieve.RequestParam>) => void,
  putProgress: (state:ZaikoKigenTmpState, action: PayloadAction<string>) => void,
  removeProgress: (state:ZaikoKigenTmpState, action: PayloadAction<string>) => void,
  setErrorMessage: (state:ZaikoKigenTmpState, action: PayloadAction<string>) => void,
  setMakerList: (state:ZaikoKigenTmpState, action: PayloadAction<CodeName[]>) => void,
  setBumonList: (state:ZaikoKigenTmpState, action: PayloadAction<CodeName[]>) => void,
  setCenterList: (state:ZaikoKigenTmpState, action: PayloadAction<CodeName[]>) => void,
  setRetrieveParam: (state:ZaikoKigenTmpState, action: PayloadAction<zaikoKigenRetrieve.RequestParam>) => void,
  setRetrievedParam: (state:ZaikoKigenTmpState, action: PayloadAction<zaikoKigenRetrieve.RequestParam>) => void,

  searched: (state:ZaikoKigenTmpState, action: PayloadAction<{param: zaikoKigenRetrieve.RequestParam, centerItems: CenterItem[], syukkaDatas: SyukkaData[], kigenDatas: KigenData[], colRowModel:ZaikoKigenColRowModel, 
    listSortOrder:CodeName, listSortOrderDesc:boolean, visibleAttributes:string[][], visibleRowsKey:string[],
    visibleZaikoKbnAll:boolean, visibleZaikoKbn1:boolean, visibleZaikoKbn2:boolean, visibleZaikoKbn3:boolean, visibleZaikoKbn4:boolean, visibleZaikoKbn5:boolean, 
    visibleKigenPattern:string,
  }>) => void,
  refreshTable: (state:ZaikoKigenTmpState, action: PayloadAction<{colRowModel:ZaikoKigenColRowModel, 
    listSortOrder:CodeName, listSortOrderDesc:boolean, visibleAttributes:string[][], visibleRowsKey:string[],
    visibleZaikoKbnAll:boolean, visibleZaikoKbn1:boolean, visibleZaikoKbn2:boolean, visibleZaikoKbn3:boolean, visibleZaikoKbn4:boolean, visibleZaikoKbn5:boolean, 
    visibleKigenPattern:string,
  }>) => void,
  refreshTableWithFilter: (state:ZaikoKigenTmpState, action: PayloadAction<{colRowModel:ZaikoKigenColRowModel, 
    listSortOrder:CodeName, listSortOrderDesc:boolean, visibleAttributes:string[][], visibleRowsKey:string[],
    visibleZaikoKbnAll:boolean, visibleZaikoKbn1:boolean, visibleZaikoKbn2:boolean, visibleZaikoKbn3:boolean, visibleZaikoKbn4:boolean, visibleZaikoKbn5:boolean, 
    visibleKigenPattern:string,
  }>) => void,
  setLastUpdate: (state:ZaikoKigenTmpState, action: PayloadAction<{lastUpdateTC: string, lastUpdateDC: string, lastUpdateHenpin: string}>) => void,
}

const createReducerContent = ():ZaikoKigenTmpReducer => {return {
    setAccordionOpen(state:ZaikoKigenTmpState, action: PayloadAction<boolean>) {
      state.accordionOpen = action.payload;
    },
    setShareURL(state: ZaikoKigenTmpState, action: PayloadAction<string>) {
      state.shareURL = action.payload;
    },
    setShareParam(state: ZaikoKigenTmpState, action: PayloadAction<zaikoKigenRetrieve.RequestParam>) {
      state.shareParam = action.payload;
    },
    putProgress(state:ZaikoKigenTmpState, action: PayloadAction<string>) {
      const key = action.payload;
      const progressNew = {...state.progress};
      progressNew[key] = true;
      state.progress = progressNew;
    },
    removeProgress(state:ZaikoKigenTmpState, action: PayloadAction<string>) {
      const key = action.payload;
      const progressNew = {};
      Object.keys(state.progress).forEach(k => {
        if(key != k) {
          progressNew[k] = true;
        }
      })
      state.progress = progressNew;
    },
    setErrorMessage(state:ZaikoKigenTmpState, action: PayloadAction<string>) {
      state.errorMessage = action.payload;
    },
    setMakerList(state:ZaikoKigenTmpState, action: PayloadAction<CodeName[]>) {
      state.makerList = action.payload;
    },
    setBumonList(state:ZaikoKigenTmpState, action: PayloadAction<CodeName[]>) {
      state.bumonList = action.payload;
    },
    setCenterList(state:ZaikoKigenTmpState, action: PayloadAction<CodeName[]>) {
      state.centerList = action.payload;
    },
    setRetrieveParam(state:ZaikoKigenTmpState, action: PayloadAction<zaikoKigenRetrieve.RequestParam>) {
      state.retrieveParam = action.payload;
    },
    setRetrievedParam(state:ZaikoKigenTmpState, action: PayloadAction<zaikoKigenRetrieve.RequestParam>) {
      state.retrievedParam = action.payload;
    },

    searched(state:ZaikoKigenTmpState, action: PayloadAction<{param: zaikoKigenRetrieve.RequestParam, centerItems: CenterItem[], syukkaDatas: SyukkaData[], kigenDatas: KigenData[], colRowModel:ZaikoKigenColRowModel, 
      listSortOrder:CodeName, listSortOrderDesc:boolean, visibleAttributes:string[][], visibleRowsKey:string[],
      visibleZaikoKbnAll:boolean, visibleZaikoKbn1:boolean, visibleZaikoKbn2:boolean, visibleZaikoKbn3:boolean, visibleZaikoKbn4:boolean, visibleZaikoKbn5:boolean, 
      visibleKigenPattern:string,
    }>) {
      const colRowModel = action.payload.colRowModel;
      const param = action.payload.param;

      let centerItems = parseDataCenterItem(action.payload.centerItems);
      let syukkaDatas = parseDataSyukkaData(action.payload.syukkaDatas);
      let kigenDatas = parseDataKigenData(action.payload.kigenDatas);

      const listSortOrder = action.payload.listSortOrder;
      const listSortOrderDesc = action.payload.listSortOrderDesc;
      const visibleAttributes = action.payload.visibleAttributes;
      const visibleRowsKey = action.payload.visibleRowsKey;
      
      let dataGroupsAll = convertDataGroups(centerItems, syukkaDatas, kigenDatas);
      dataGroupsAll = calcDataGroups(dataGroupsAll);

      dataGroupsAll = sortDataGroups(dataGroupsAll, listSortOrder, listSortOrderDesc);
      let [rowInfosAll, fixedRowsTop] = convertRowInfosAll(dataGroupsAll, listSortOrder, visibleRowsKey);
      rowInfosAll = calcRowInfos(rowInfosAll);
      
      const rowInfos = filterRowInfos(rowInfosAll, action.payload.visibleZaikoKbnAll, action.payload.visibleZaikoKbn1, action.payload.visibleZaikoKbn2, action.payload.visibleZaikoKbn3, action.payload.visibleZaikoKbn4, action.payload.visibleZaikoKbn5, action.payload.visibleKigenPattern);
      //store更新
      state.dataGroupsAll = dataGroupsAll;
      state.rowInfosAll = rowInfosAll;
      state.fixedRowsTop = fixedRowsTop;
      state.rowInfos = rowInfos;
      state.mergeCells = createMergeCells(rowInfos, colRowModel);
      state.rows = convertRows(rowInfos, colRowModel, visibleAttributes);
    },
    refreshTable(state:ZaikoKigenTmpState, action: PayloadAction<{colRowModel:ZaikoKigenColRowModel, 
      listSortOrder:CodeName, listSortOrderDesc:boolean, visibleAttributes:string[][], visibleRowsKey:string[],
      visibleZaikoKbnAll:boolean, visibleZaikoKbn1:boolean, visibleZaikoKbn2:boolean, visibleZaikoKbn3:boolean, visibleZaikoKbn4:boolean, visibleZaikoKbn5:boolean, 
      visibleKigenPattern:string,
    }>){
      console.log('refreshTable');
      if(!state.dataGroupsAll || state.dataGroupsAll.length == 0) {
        return;
      }
      const listSortOrder = action.payload.listSortOrder;
      const listSortOrderDesc = action.payload.listSortOrderDesc;
      const visibleAttributes = action.payload.visibleAttributes;
      const visibleRowsKey = action.payload.visibleRowsKey;
      const colRowModel = action.payload.colRowModel;

      let dataGroupsAll = state.dataGroupsAll;
      dataGroupsAll = sortDataGroups(dataGroupsAll, listSortOrder, listSortOrderDesc);
      let [rowInfosAll, fixedRowsTop] = convertRowInfosAll(dataGroupsAll, listSortOrder, visibleRowsKey);
      rowInfosAll = calcRowInfos(rowInfosAll);

      const rowInfos = filterRowInfos(rowInfosAll, action.payload.visibleZaikoKbnAll, action.payload.visibleZaikoKbn1, action.payload.visibleZaikoKbn2, action.payload.visibleZaikoKbn3, action.payload.visibleZaikoKbn4, action.payload.visibleZaikoKbn5, action.payload.visibleKigenPattern);

      //store更新
      state.rowInfosAll = rowInfosAll;
      state.rowInfos = rowInfos;
      state.fixedRowsTop = fixedRowsTop;
      state.mergeCells = createMergeCells(rowInfos, colRowModel);
      state.rows = convertRows(rowInfos, colRowModel, visibleAttributes);
    },
    refreshTableWithFilter(state:ZaikoKigenTmpState, action: PayloadAction<{colRowModel:ZaikoKigenColRowModel, 
      listSortOrder:CodeName, listSortOrderDesc:boolean, visibleAttributes:string[][], visibleRowsKey:string[], 
      visibleZaikoKbnAll:boolean, visibleZaikoKbn1:boolean, visibleZaikoKbn2:boolean, visibleZaikoKbn3:boolean, visibleZaikoKbn4:boolean, visibleZaikoKbn5:boolean, 
      visibleKigenPattern:string,
    }>){
      console.log('refreshTableWithFilter');
      if(!state.dataGroupsAll || state.dataGroupsAll.length == 0) {
        return;
      }
      const listSortOrder = action.payload.listSortOrder;
      const listSortOrderDesc = action.payload.listSortOrderDesc;
      const visibleAttributes = action.payload.visibleAttributes;
      const visibleRowsKey = action.payload.visibleRowsKey;
      const colRowModel = action.payload.colRowModel;

      const rowInfosAll = state.rowInfosAll;
      const rowInfos = filterRowInfos(rowInfosAll, action.payload.visibleZaikoKbnAll, action.payload.visibleZaikoKbn1, action.payload.visibleZaikoKbn2, action.payload.visibleZaikoKbn3, action.payload.visibleZaikoKbn4, action.payload.visibleZaikoKbn5,
        action.payload.visibleKigenPattern);
      //store更新
      state.rowInfos = rowInfos;
      state.mergeCells = createMergeCells(rowInfos, colRowModel);
      state.rows = convertRows(rowInfos, colRowModel, visibleAttributes);
    },
    setLastUpdate(state:ZaikoKigenTmpState, action: PayloadAction<{lastUpdateTC: string, lastUpdateDC: string, lastUpdateHenpin: string}>) {
      state.lastUpdateTC= action.payload.lastUpdateTC;
      state.lastUpdateDC = action.payload.lastUpdateDC;
      state.lastUpdateHenpin = action.payload.lastUpdateHenpin;
    },

}};

const parseDataCenterItem = (datas:CenterItem[]): CenterItem[] => {
  datas = [...datas];
  datas.forEach((data) => {
    // if(typeof data.termStartDate === 'string') data.blIrisu = parseInt(data.blIrisu);
    // if(typeof data.termEndDate === 'string') data.blIrisu = parseInt(data.blIrisu);

    if(typeof data.blIrisu === 'string') data.blIrisu = parseInt(data.blIrisu);
    if(typeof data.csIrisu === 'string') data.csIrisu = parseInt(data.csIrisu);
    if(typeof data.bestBefore === 'string') data.bestBefore = parseInt(data.bestBefore);
    if(typeof data.tolerance === 'string') data.tolerance = parseInt(data.tolerance);
    if(typeof data.sendoKyoyo === 'string') data.sendoKyoyo = parseInt(data.sendoKyoyo);
    if(typeof data.teika === 'string') data.teika = parseInt(data.teika);
    if(typeof data.tatene === 'string') data.tatene = parseFloat(data.tatene);

    if(typeof data.zaikoQtyBl1 === 'string') data.zaikoQtyBl1 = parseFloat(data.zaikoQtyBl1);
    if(typeof data.zaikoQtyBl2 === 'string') data.zaikoQtyBl2 = parseFloat(data.zaikoQtyBl2);
    if(typeof data.zaikoQtyBl3 === 'string') data.zaikoQtyBl3 = parseFloat(data.zaikoQtyBl3);
    if(typeof data.zaikoQtyBl4 === 'string') data.zaikoQtyBl4 = parseFloat(data.zaikoQtyBl4);
    if(typeof data.zaikoQtyBl5 === 'string') data.zaikoQtyBl5 = parseFloat(data.zaikoQtyBl5);

    if(typeof data.zaikoQtyPs1 === 'string') data.zaikoQtyPs1 = parseFloat(data.zaikoQtyPs1);
    if(typeof data.zaikoQtyPs2 === 'string') data.zaikoQtyPs2 = parseFloat(data.zaikoQtyPs2);
    if(typeof data.zaikoQtyPs3 === 'string') data.zaikoQtyPs3 = parseFloat(data.zaikoQtyPs3);
    if(typeof data.zaikoQtyPs4 === 'string') data.zaikoQtyPs4 = parseFloat(data.zaikoQtyPs4);
    if(typeof data.zaikoQtyPs5 === 'string') data.zaikoQtyPs5 = parseFloat(data.zaikoQtyPs5);
  });
  return datas;
}
const parseDataSyukkaData = (datas: SyukkaData[]): SyukkaData[] => {
  datas = [...datas];
  datas.forEach((data) => {
  });
  return datas;
}
const parseDataKigenData = (datas: KigenData[]): KigenData[] => {
  datas = [...datas];
  datas.forEach((data) => {
    if(typeof data.zaikoQtyBl === 'string') data.zaikoQtyBl = parseFloat(data.zaikoQtyBl);
    if(typeof data.zaikoQtyPs === 'string') data.zaikoQtyPs = parseInt(data.zaikoQtyPs);
    if(typeof data.zaikoQtyPsTotal === 'string') data.zaikoQtyPsTotal = parseInt(data.zaikoQtyPsTotal);
    if(typeof data.zaikoAmtPsTotal === 'string') data.zaikoAmtPsTotal = Math.round(parseFloat(data.zaikoAmtPsTotal));
  });
  return datas;
}
//RowDataGroupに変換
const convertDataGroups = (centerItems: CenterItem[], syukkaDatas: SyukkaData[], kigenDatas: KigenData[]): RowDataGroup[] => {
  //商品、センター順でソート
  centerItems = centerItems.sort((a,b) => {
    let comp = 0;
    comp = compareUtil.compareString(a.itemCD, b.itemCD, true)
    if(comp == 0) {
      comp = compareUtil.compareString(a.centerCD, b.centerCD, true)
    }
    return comp;
  });
  //商品、センター順、在庫区分でソート、入荷日でソート
  kigenDatas = kigenDatas.sort((a,b) => {
    let comp = 0;
    comp = compareUtil.compareString(a.itemCD, b.itemCD, true)
    if(comp == 0) {
      comp = compareUtil.compareString(a.centerCD, b.centerCD, true)
    }
    if(comp == 0) {
      comp = compareUtil.compareString(a.zaikoKbn, b.zaikoKbn, true)
    }
    if(comp == 0) {
      comp = compareUtil.compareString(a.nyukaDate, b.nyukaDate, true)
    }
    return comp;
  });

  //出荷データのマップ
  const syukkaDatasMap = {};
  syukkaDatas.forEach(syukkaData => {
    const key = `${syukkaData.itemCD} ${syukkaData.centerCD}`;
    syukkaDatasMap[key] = syukkaData;
  });

  //属性データのマップ
  const centerItemsMap = {};
  centerItems.forEach(centerItem => {
    const key = `${centerItem.itemCD} ${centerItem.centerCD}`;

    centerItemsMap[key] = centerItem;
  });
  
  return kigenDatas.map(kigenData => {
    const key = `${kigenData.itemCD} ${kigenData.centerCD}`;
    return {
      centerItem: centerItemsMap[key],
      syukkaData: syukkaDatasMap[key],
      kigenData: kigenData,
    };
  });
}
//並び順変更
const sortDataGroups = (dataGroups:RowDataGroup[], listSortOrder:CodeName, listSortOrderDesc:boolean): RowDataGroup[] => {
  if(!dataGroups) {
    return dataGroups;
  }
  let asc = !listSortOrderDesc;
  let getSortKey1 = (o:RowDataGroup):string|number => 
    !o || !o.centerItem ? null : 
    !listSortOrder ? o.centerItem.itemCD :
    o.kigenData && o.kigenData[listSortOrder.option1] ? o.kigenData[listSortOrder.option1] :
    o.centerItem[listSortOrder.option1]
  ;
  let getSortKey2 = (o:RowDataGroup):string|number => 
    !o || !o.centerItem ? null : 
    !listSortOrder ? o.centerItem.itemCD :
    o.kigenData && o.kigenData[listSortOrder.option1] ? o.kigenData[listSortOrder.option2] :
    o.centerItem[listSortOrder.option2]
  ;
  let getSortKey3 = (o:RowDataGroup):string|number => 
    !o || !o.centerItem ? null : 
    !listSortOrder ? o.centerItem.centerCD :
    o.kigenData && o.kigenData[listSortOrder.option1] ? o.kigenData[listSortOrder.option3] :
    o.centerItem[listSortOrder.option3]
  ;
  let getSortKey4 = (o:RowDataGroup):string|number => 
    !o || !o.centerItem ? null : 
    !listSortOrder ? o.centerItem.centerCD :
    o.kigenData && o.kigenData[listSortOrder.option1] ? o.kigenData[listSortOrder.option4] :
    o.centerItem[listSortOrder.option4]
  ;
  dataGroups.sort((a, b) => {
    //第1弾ソート項目
    let va = getSortKey1(a);
    let vb = getSortKey1(b);
    let comp = compareUtil.compareAny(va, vb, asc);
    //第2弾ソート項目
    if(comp == 0) {
      va = getSortKey2(a);
      vb = getSortKey2(b);
      comp = compareUtil.compareAny(va, vb, asc);
    }
    //第3弾ソート項目
    if(comp == 0) {
      va = getSortKey3(a);
      vb = getSortKey3(b);
      comp = compareUtil.compareAny(va, vb, asc);
    }
    //第4弾ソート項目
    if(comp == 0) {
      va = getSortKey4(a);
      vb = getSortKey4(b);
      comp = compareUtil.compareAny(va, vb, asc);
    }
    return comp;
  });
  return dataGroups;
}
//RowDataGroupの計算
const calcDataGroups = (dataGroups:RowDataGroup[]): RowDataGroup[] => {
  //在庫の合計を利用する
  return dataGroups.map(dataGroup => {
    return dataGroup;
  });
}

//行情報に変換
const convertRowInfosAll = (dataGroups:RowDataGroup[], listSortOrder:CodeName, visibleRowsKey:string[]): [RowInfo[], number] => {
  const targetRowsKeys = arrayutil.and(rowKeys, visibleRowsKey);

  const rowInfos:RowInfo[] = [];
  let fixedRowsTop:number = 0;

  //明細行
  fixedRowsTop = rowInfos.length;

  let beforeDataGroup:RowDataGroup;
  let grouping = listSortOrder?.group_code;
  let no = 0;
  let subno = 0;
  dataGroups.forEach(dataGroup => {

    // グルーピング
    if(
      (grouping == 'item' && (!beforeDataGroup || beforeDataGroup.centerItem.itemCD != dataGroup.centerItem.itemCD)) ||
      (grouping == 'maker' && (!beforeDataGroup || beforeDataGroup.centerItem.makerCD != dataGroup.centerItem.makerCD)) ||
      (grouping == 'center' && (!beforeDataGroup || beforeDataGroup.centerItem.centerCD != dataGroup.centerItem.centerCD))
    ) {
      no++;
      subno = 0;
      for (let index = 0; index <= 5; index++) {
        const zaikoKbn = index == 0 ? 'all' : `${index}`;

        const list = dataGroup.centerItem?.centerCD === '0602' ? zaikoKbn0602List : zaikoKbnList;
        if (list[zaikoKbn] || grouping !== 'center') {
          rowInfos.push({
            TP: grouping,
            no: `${no}`,
            dataGroup: {
              centerItem: {
                  makerCD: dataGroup.centerItem?.makerCD,
                  makerNM: dataGroup.centerItem?.makerNM,
                  itemCD: dataGroup.centerItem?.itemCD,
                  itemNM: dataGroup.centerItem?.itemNM,
                  bumonCD: dataGroup.centerItem?.bumonCD,
                  bumonNM: dataGroup.centerItem?.bumonNM,
                  centerCD: dataGroup.centerItem?.centerCD,
                  centerNM: dataGroup.centerItem?.centerNM,
              },
              kigenData: {
                centerCD: dataGroup.centerItem?.centerCD,
                itemCD: dataGroup.centerItem?.itemCD,
                zaikoKbn: zaikoKbn
              },
              syukkaData: {},
            },
          });
        }
      }
    }

    //明細行
    subno++;
    let rowIndex = 0;
    targetRowsKeys.forEach((rowKey) => {
      switch (rowKey) {
        //期限日
        case "kigen":
            rowInfos.push({
              ...{
                TP: "kigen",
                no: `${no}`,
                subno: `${subno}`,
                dataGroup: dataGroup,
              }, 
              ...{
                rowKey: rowKey,
              }
            });
          break;
      
        default:
          break;
      }
    })

    beforeDataGroup = dataGroup;
  });

  return [rowInfos, fixedRowsTop];
}
//RowInfoの計算
const calcRowInfos = (rowInfos:RowInfo[]): RowInfo[] => {
  let rowInfoTotalAll:RowInfo = null;
  let rowInfoTotalMap = {};
  return rowInfos.map(rowInfo => {
    if(rowInfo.TP == 'item' || rowInfo.TP == 'maker' || rowInfo.TP == 'center') {
      if(rowInfo.dataGroup.kigenData.zaikoKbn == 'all') {
        rowInfoTotalAll = rowInfo;
      }
      else {
        rowInfoTotalMap[rowInfo.dataGroup?.kigenData?.zaikoKbn] = rowInfo;
      }
    }
    else if(rowInfo.TP == 'kigen') {
      const rowInfoTotal:RowInfo = rowInfoTotalMap[rowInfo.dataGroup?.kigenData?.zaikoKbn];
      if(rowInfoTotalAll) {
        switch (rowInfo.dataGroup?.kigenData?.zaikoKbn) {
          case '1':
            rowInfoTotalAll.dataGroup.centerItem.zaikoQtyPs1 = calcUtil.plus(rowInfoTotalAll.dataGroup.centerItem.zaikoQtyPs1, rowInfo.dataGroup.centerItem?.zaikoQtyPs1);
            rowInfoTotalAll.dataGroup.centerItem.zaikoQtyBl1 = calcUtil.plus(rowInfoTotalAll.dataGroup.centerItem.zaikoQtyBl1, rowInfo.dataGroup.centerItem?.zaikoQtyBl1);
            break;
          case '2':
            rowInfoTotalAll.dataGroup.centerItem.zaikoQtyPs1 = calcUtil.plus(rowInfoTotalAll.dataGroup.centerItem.zaikoQtyPs1, rowInfo.dataGroup.centerItem?.zaikoQtyPs2);
            rowInfoTotalAll.dataGroup.centerItem.zaikoQtyBl1 = calcUtil.plus(rowInfoTotalAll.dataGroup.centerItem.zaikoQtyBl1, rowInfo.dataGroup.centerItem?.zaikoQtyBl2);
            break;
          case '3':
            rowInfoTotalAll.dataGroup.centerItem.zaikoQtyPs1 = calcUtil.plus(rowInfoTotalAll.dataGroup.centerItem.zaikoQtyPs1, rowInfo.dataGroup.centerItem?.zaikoQtyPs3);
            rowInfoTotalAll.dataGroup.centerItem.zaikoQtyBl1 = calcUtil.plus(rowInfoTotalAll.dataGroup.centerItem.zaikoQtyBl1, rowInfo.dataGroup.centerItem?.zaikoQtyBl3);
            break;
          case '4':
            rowInfoTotalAll.dataGroup.centerItem.zaikoQtyPs1 = calcUtil.plus(rowInfoTotalAll.dataGroup.centerItem.zaikoQtyPs1, rowInfo.dataGroup.centerItem?.zaikoQtyPs4);
            rowInfoTotalAll.dataGroup.centerItem.zaikoQtyBl1 = calcUtil.plus(rowInfoTotalAll.dataGroup.centerItem.zaikoQtyBl1, rowInfo.dataGroup.centerItem?.zaikoQtyBl4);
            break;
          case '5':
            rowInfoTotalAll.dataGroup.centerItem.zaikoQtyPs1 = calcUtil.plus(rowInfoTotalAll.dataGroup.centerItem.zaikoQtyPs1, rowInfo.dataGroup.centerItem?.zaikoQtyPs5);
            rowInfoTotalAll.dataGroup.centerItem.zaikoQtyBl1 = calcUtil.plus(rowInfoTotalAll.dataGroup.centerItem.zaikoQtyBl1, rowInfo.dataGroup.centerItem?.zaikoQtyBl5);
            break;
          default:
            break;
        }
        rowInfoTotalAll.dataGroup.kigenData.zaikoQtyBl = calcUtil.plus(rowInfoTotalAll.dataGroup.kigenData.zaikoQtyBl, rowInfo.dataGroup.kigenData?.zaikoQtyBl);
        rowInfoTotalAll.dataGroup.kigenData.zaikoQtyPs = calcUtil.plus(rowInfoTotalAll.dataGroup.kigenData.zaikoQtyPs, rowInfo.dataGroup.kigenData?.zaikoQtyPs);
        rowInfoTotalAll.dataGroup.kigenData.zaikoQtyPsTotal = calcUtil.plus(rowInfoTotalAll.dataGroup.kigenData.zaikoQtyPsTotal, rowInfo.dataGroup.kigenData?.zaikoQtyPsTotal);
        rowInfoTotalAll.dataGroup.kigenData.zaikoAmtPsTotal = calcUtil.plus(rowInfoTotalAll.dataGroup.kigenData.zaikoAmtPsTotal, rowInfo.dataGroup.kigenData?.zaikoAmtPsTotal);
      }
      if(rowInfoTotal) {
        switch (rowInfo.dataGroup?.kigenData?.zaikoKbn) {
          case '1':
            rowInfoTotal.dataGroup.centerItem.zaikoQtyPs1 = calcUtil.plus(rowInfoTotal.dataGroup.centerItem.zaikoQtyPs1, rowInfo.dataGroup.centerItem?.zaikoQtyPs1);
            rowInfoTotal.dataGroup.centerItem.zaikoQtyBl1 = calcUtil.plus(rowInfoTotal.dataGroup.centerItem.zaikoQtyBl1, rowInfo.dataGroup.centerItem?.zaikoQtyBl1);
            break;
          case '2':
            rowInfoTotal.dataGroup.centerItem.zaikoQtyPs2 = calcUtil.plus(rowInfoTotal.dataGroup.centerItem.zaikoQtyPs2, rowInfo.dataGroup.centerItem?.zaikoQtyPs2);
            rowInfoTotal.dataGroup.centerItem.zaikoQtyBl2 = calcUtil.plus(rowInfoTotal.dataGroup.centerItem.zaikoQtyBl2, rowInfo.dataGroup.centerItem?.zaikoQtyBl2);
            break;
          case '3':
            rowInfoTotal.dataGroup.centerItem.zaikoQtyPs3 = calcUtil.plus(rowInfoTotal.dataGroup.centerItem.zaikoQtyPs3, rowInfo.dataGroup.centerItem?.zaikoQtyPs3);
            rowInfoTotal.dataGroup.centerItem.zaikoQtyBl3 = calcUtil.plus(rowInfoTotal.dataGroup.centerItem.zaikoQtyBl3, rowInfo.dataGroup.centerItem?.zaikoQtyBl3);
            break;
          case '4':
            rowInfoTotal.dataGroup.centerItem.zaikoQtyPs4 = calcUtil.plus(rowInfoTotal.dataGroup.centerItem.zaikoQtyPs4, rowInfo.dataGroup.centerItem?.zaikoQtyPs4);
            rowInfoTotal.dataGroup.centerItem.zaikoQtyBl4 = calcUtil.plus(rowInfoTotal.dataGroup.centerItem.zaikoQtyBl4, rowInfo.dataGroup.centerItem?.zaikoQtyBl4);
            break;
          case '5':
            rowInfoTotal.dataGroup.centerItem.zaikoQtyPs5 = calcUtil.plus(rowInfoTotal.dataGroup.centerItem.zaikoQtyPs5, rowInfo.dataGroup.centerItem?.zaikoQtyPs5);
            rowInfoTotal.dataGroup.centerItem.zaikoQtyBl5 = calcUtil.plus(rowInfoTotal.dataGroup.centerItem.zaikoQtyBl5, rowInfo.dataGroup.centerItem?.zaikoQtyBl5);
            break;
          default:
            break;
        }
        rowInfoTotal.dataGroup.kigenData.zaikoQtyBl = calcUtil.plus(rowInfoTotal.dataGroup.kigenData.zaikoQtyBl, rowInfo.dataGroup.kigenData?.zaikoQtyBl);
        rowInfoTotal.dataGroup.kigenData.zaikoQtyPs = calcUtil.plus(rowInfoTotal.dataGroup.kigenData.zaikoQtyPs, rowInfo.dataGroup.kigenData?.zaikoQtyPs);
        rowInfoTotal.dataGroup.kigenData.zaikoQtyPsTotal = calcUtil.plus(rowInfoTotal.dataGroup.kigenData.zaikoQtyPsTotal, rowInfo.dataGroup.kigenData?.zaikoQtyPsTotal);
        rowInfoTotal.dataGroup.kigenData.zaikoAmtPsTotal = calcUtil.plus(rowInfoTotal.dataGroup.kigenData.zaikoAmtPsTotal, rowInfo.dataGroup.kigenData?.zaikoAmtPsTotal);
      }
    }
    return rowInfo;
  });
}
//行情報に変換
const filterRowInfos = (rowInfosAll:RowInfo[],
  visibleZaikoKbnAll:boolean, visibleZaikoKbn1:boolean, visibleZaikoKbn2:boolean, visibleZaikoKbn3:boolean, visibleZaikoKbn4:boolean, visibleZaikoKbn5:boolean,
  visibleKigenPattern:string  
  ): RowInfo[] => {
    const all_visibleZaikoKbn = !visibleZaikoKbnAll && !visibleZaikoKbn1 && !visibleZaikoKbn2 && !visibleZaikoKbn3 && !visibleZaikoKbn4 && !visibleZaikoKbn5;
    if(all_visibleZaikoKbn && !visibleKigenPattern) {
      return rowInfosAll;
    }

    return rowInfosAll.filter(rowInfo => 
      (all_visibleZaikoKbn ||
          (visibleZaikoKbnAll && rowInfo.dataGroup?.kigenData?.zaikoKbn == 'all') ||
          (visibleZaikoKbn1 && rowInfo.dataGroup?.kigenData?.zaikoKbn == '1') ||
          (visibleZaikoKbn2 && rowInfo.dataGroup?.kigenData?.zaikoKbn == '2') ||
          (visibleZaikoKbn3 && rowInfo.dataGroup?.kigenData?.zaikoKbn == '3') ||
          (visibleZaikoKbn4 && rowInfo.dataGroup?.kigenData?.zaikoKbn == '4') ||
          (visibleZaikoKbn5 && rowInfo.dataGroup?.kigenData?.zaikoKbn == '5')
      )
      && 
      (!visibleKigenPattern ||
        (visibleKigenPattern == 'over' && (rowInfo.dataGroup?.kigenData?.kigenDateError2_3 || rowInfo.dataGroup?.kigenData?.kigenDateError1_2)) ||
        (visibleKigenPattern == 'over1_2' && (rowInfo.dataGroup?.kigenData?.kigenDateOver1_2)) ||
        (visibleKigenPattern == 'over1_3' && (rowInfo.dataGroup?.kigenData?.kigenDateOver2_3)) ||
        (visibleKigenPattern == 'over999' && (rowInfo.dataGroup?.kigenData?.kigenDateInvalid2_3 || rowInfo.dataGroup?.kigenData?.kigenDateInvalid1_2))
      )
    );
}

//配列データに変換
const convertRows = (rowInfos:RowInfo[], colRowModel:ZaikoKigenColRowModel, visibleAttributes: string[][]): any[][] => {
  return rowInfos.map((rowInfo) => convertRow(rowInfo, colRowModel, visibleAttributes));
}
//配列データに変換
const convertRow = (rowInfo:RowInfo, colRowModel:ZaikoKigenColRowModel, visibleAttributes: string[][]): any[] => {
  //set No.
  const dataGroup:RowDataGroup = rowInfo.dataGroup;
  return colRowModel.colKeys.map(colKey => {

    //グループ行
    if(rowInfo.TP == "center" || rowInfo.TP == "item" || rowInfo.TP == "maker") {
      switch (colKey) {
        case "no":
          return rowInfo.no;
        case "centerCD":
          if(rowInfo.TP == "center") {
            return `${dataGroup.centerItem.centerCD} ${dataGroup.centerItem.centerNM}`;
          }
          if(rowInfo.TP == "item") {
            return `${dataGroup.centerItem.itemCD} ${dataGroup.centerItem.itemNM}`;
          }
          if(rowInfo.TP == "maker") {
            return `${dataGroup.centerItem.makerCD} ${dataGroup.centerItem.makerNM}`;
          }
          return null;
        case "rowHeader":
          return null;

        case "teibanNum":
          return null;
        case "teibanNMs":
          return null;

        case "nohinbi":
          return null;
        case "tokuisakiNum":
          return null;
        case "tokuisakiNMs":
          return null;
        case "zaikoKbn":
          if (dataGroup?.kigenData?.zaikoKbn) {
            return rowInfo.TP === 'center' ?
              dataGroup?.centerItem?.centerCD === '0602' ? zaikoKbn0602List[dataGroup?.kigenData?.zaikoKbn] : zaikoKbnList[dataGroup?.kigenData?.zaikoKbn] :
              zaikoKbnMixList[dataGroup?.kigenData?.zaikoKbn];
          }
          return null;
        case "zaikoTotalBl":
          switch (dataGroup?.kigenData?.zaikoKbn) {
            case 'all': return dataGroup?.centerItem?.zaikoQtyBl1;
            case '1': return dataGroup?.centerItem?.zaikoQtyBl1;
            case '2': return dataGroup?.centerItem?.zaikoQtyBl2;
            case '3': return dataGroup?.centerItem?.zaikoQtyBl3;
            case '4': return dataGroup?.centerItem?.zaikoQtyBl4;
            case '5': return dataGroup?.centerItem?.zaikoQtyBl5;
          }
          return null;
        case "zaikoTotalPs":
          switch (dataGroup?.kigenData?.zaikoKbn) {
            case 'all': return dataGroup?.centerItem?.zaikoQtyPs1;
            case '1': return dataGroup?.centerItem?.zaikoQtyPs1;
            case '2': return dataGroup?.centerItem?.zaikoQtyPs2;
            case '3': return dataGroup?.centerItem?.zaikoQtyPs3;
            case '4': return dataGroup?.centerItem?.zaikoQtyPs4;
            case '5': return dataGroup?.centerItem?.zaikoQtyPs5;
          }
          return null;
        case "zaikoQtyBl":
          return dataGroup?.kigenData?.zaikoQtyBl;
        case "zaikoQtyPs":
          return dataGroup?.kigenData?.zaikoQtyPs;
        case "zaikoQtyPsTotal":
          return dataGroup?.kigenData?.zaikoQtyPsTotal;
        case "zaikoAmtPsTotal":
          return dataGroup?.kigenData?.zaikoAmtPsTotal;
       default: {
          return null;
        }
      }
    }
    else {

      //明細行
      switch (colKey) {
        case "no":
          return rowInfo.no && rowInfo.no != '0' ? `${rowInfo.no}-${rowInfo.subno}` : `${rowInfo.subno}`;
        case "bumon": //"部門"
          return `${dataGroup.centerItem.bumonCD} ${dataGroup.centerItem.bumonNM}`;
        case "bumonCD": //"部門"
          return dataGroup.centerItem.bumonCD;
        case "bumonNM": //"部門"
          return dataGroup.centerItem.bumonNM;
        case "center": //"倉庫"
          return `${dataGroup.centerItem.centerCD} ${dataGroup.centerItem.centerNM}`;
        case "centerCD": //"倉庫"
          return dataGroup.centerItem.centerCD;
        case "centerNM": //"倉庫"
          return dataGroup.centerItem.centerNM;
        case "itemCD": //"商品CD"
          return `${dataGroup.centerItem.itemCD}`;
        case "itemNM": //"商品名"
          return `${dataGroup.centerItem.itemNM}`;
        case "janCD": //"JAN"
          return `${dataGroup.centerItem.janCD}`;
        case "makerCD": //"メーカー"
          return dataGroup.centerItem.makerCD;
        case "makerNM": //"メーカー"
          return dataGroup.centerItem.makerNM;
        case "category": //"商品カテゴリ"
          return `${dataGroup.centerItem.categoryCD} ${dataGroup.centerItem.categoryNM}`;
        case "pbKbnCD":
          return dataGroup.centerItem.pbKbnCD;
        case "pbKbnNM":
          return dataGroup.centerItem.pbKbnNM;
        case "irisu": //"入数"
          return `${formatterN0.format(dataGroup.centerItem.blIrisu)} × ${formatterN0.format(dataGroup.centerItem.csIrisu)}`;
        case "blIrisu": //"BL入数"
          return `${formatterN0.format(dataGroup.centerItem.blIrisu)}`;
        case "csIrisu": //"CS入数"
          return `${formatterN0.format(dataGroup.centerItem.csIrisu)}`;
        case "capacity": //"内容量"
          return `${dataGroup.centerItem.capacity}`;
        case "bestBefore": //"賞味期間"
          return `${formatterN0.format(dataGroup.centerItem.bestBefore)}`;
        case "tolerance": //"許容期間"
          return `${formatterN0.format(dataGroup.centerItem.tolerance)}`;
        case "teika": //"定価"
          return `${formatterN0.format(dataGroup.centerItem.teika)}`;
        case "tatene": //"建値"
          return `${formatterP00.format(dataGroup.centerItem.tatene)}`;
        case "tanaban": //"棚番"
          return dataGroup.centerItem.tanaban;

        case "teibanNum":
          return dataGroup.centerItem?.teibanNum;
        case "teibanNMs":
          return dataGroup.centerItem?.teibanNMs;

        case "rowHeader":
          return headersRow[rowInfo.rowKey];
        case "nohinbi":
          return dataGroup.syukkaData?.nohinbi;
        case "tokuisakiNum":
          return dataGroup.syukkaData?.tokuisakiNum;
        case "tokuisakiNMs":
          return dataGroup.syukkaData?.tokuisakiNMs;

        case "zaikoKbn":
          if (dataGroup?.kigenData?.zaikoKbn) {
             return dataGroup?.centerItem?.centerCD === '0602' ? zaikoKbn0602List[dataGroup?.kigenData?.zaikoKbn] : zaikoKbnList[dataGroup?.kigenData?.zaikoKbn];
          }
          return null;
        case "zaikoTotalBl":
          switch (dataGroup?.kigenData?.zaikoKbn) {
            case '1': return dataGroup?.centerItem?.zaikoQtyBl1;
            case '2': return dataGroup?.centerItem?.zaikoQtyBl2;
            case '3': return dataGroup?.centerItem?.zaikoQtyBl3;
            case '4': return dataGroup?.centerItem?.zaikoQtyBl4;
            case '5': return dataGroup?.centerItem?.zaikoQtyBl5;
          }
          return null;
        case "zaikoTotalPs":
          switch (dataGroup?.kigenData?.zaikoKbn) {
            case '1': return dataGroup?.centerItem?.zaikoQtyPs1;
            case '2': return dataGroup?.centerItem?.zaikoQtyPs2;
            case '3': return dataGroup?.centerItem?.zaikoQtyPs3;
            case '4': return dataGroup?.centerItem?.zaikoQtyPs4;
            case '5': return dataGroup?.centerItem?.zaikoQtyPs5;
          }
          return null;
        case "nyukaDate":
          return dataGroup?.kigenData?.nyukaDate;
        case "keikaDays":
          return dataGroup?.kigenData?.keikaDays;
        case "zaikoQtyBl":
          return dataGroup?.kigenData?.zaikoQtyBl;
        case "zaikoQtyPs":
          return dataGroup?.kigenData?.zaikoQtyPs;
        case "zaikoQtyPsTotal":
          return dataGroup?.kigenData?.zaikoQtyPsTotal;
        case "zaikoAmtPsTotal":
          return dataGroup?.kigenData?.zaikoAmtPsTotal;
        case "nohinAbleDate2_3":
          return dataGroup?.kigenData?.nohinAbleDate2_3;
        case "kigenDate2_3":
          return dataGroup?.kigenData?.kigenDate2_3;
        case "nohinAbleDate1_2":
          return dataGroup?.kigenData?.nohinAbleDate1_2;
        case "kigenDate1_2":
          return dataGroup?.kigenData?.kigenDate1_2;
        case "check":
          return `(　　　　　　　)`;
        case "kigenDateReal":
          return ` 　　／　　／　　`;
        case "zaikoQtyBlReal":
          return "";
        default: 
          return dataGroup[colKey];
        }

    }

  });
}

//マージを作成
const createMergeCells = (rowInfos:RowInfo[], colRowModel:ZaikoKigenColRowModel): {row: number, col: number, rowspan: number, colspan: number}[] => {
  let mergeCells:{row: number, col: number, rowspan: number, colspan: number}[] = [];
  if(rowInfos) {
    let colF = colRowModel.colFromKey('centerCD');
    let colT = colRowModel.fixedColumnsLeft-1;
    rowInfos.map((rowInfo, index) => {
      if(rowInfo.TP == "center" || rowInfo.TP == "item" || rowInfo.TP == "maker") {
        mergeCells.push({row: index, col: colF, rowspan:1, colspan:colT-colF+1});
      }
    });
  }
  return mergeCells.length == 0 ? null : mergeCells;
}


export const getOptionLabel = (option: CodeName) => {
  return option && option.name ? (option.code + ' ' + option.name) : "";
}

const createSliceContent = (name:string) => createSlice({
  name: name,
  initialState,
  reducers: createReducerContent(),
});

//Page Slice Export
//zaikoKigenTmp
export const zaikoKigenTmpSlice = createSliceContent("zaikoKigenTmp");
