import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import moment from "moment";

import { RequestParam } from "@/assets/apitype/outputDataListZaiko";
import { CodeName } from "@/store/common";

//在庫マスタ
export interface RowDataZaiko {
  no?: string,
  ICD?: string | null,
  MKCD?: string | null,
  BHCD?: string | null,
  ADR?: string | null,
  IJN?: string | null,
  SMKGN?: number | null,
  OKNSN?: number | null,
  MKC?: string | null,
  INM?: string | null,
  ZKN?: string | null,
  CSIR?: string | null,
  ZQTY?: number | null,
  UDAT?: Date | string | null,
  BLN?: string | number | null,
  PSN?: string | number | null,
  PST?: number | null,
  SMKGD?: Date | string | null,
  OKNSDA?: Date | string | null,
  SMKGDA?: Date | string | null,
  OKNSDB?: Date | string | null,
  SMKGDB?: Date | string | null,
  CHK?: string | null,
  RILI?: string | null,
  RBLN?: string | null,
}


//Page State
export type OutputDataZaikoTmpState = {
  //部門
  buList: CodeName[],
  //倉庫
  centerList: CodeName[],

  //検索する条件
  requestParamZaiko: RequestParam,

  progress: Record<string, unknown>,
  datasZaiko: RowDataZaiko[],

  errorMessage: string | null,
  infoMessage: string | null,
};

export const initialState: OutputDataZaikoTmpState = {
  //部門
  buList: [],
  //倉庫
  centerList: [],

  requestParamZaiko: null,

  progress: {},
  datasZaiko: [],
  errorMessage: null,
  infoMessage: null,
};

//Page Slice
const createSliceContent = (name: string) => createSlice({
  name: name,
  initialState,
  reducers: {
    setBuList(state:OutputDataZaikoTmpState, action: PayloadAction<CodeName[]>) {
      state.buList = action.payload;
    },
    setCenterList(state:OutputDataZaikoTmpState, action: PayloadAction<CodeName[]>) {
      state.centerList = action.payload;
    },

    //検索条件
    setRequestParamZaiko(state:OutputDataZaikoTmpState, action: PayloadAction<RequestParam>) {
      state.requestParamZaiko = action.payload;
    },

    putProgress(state:OutputDataZaikoTmpState, action: PayloadAction<string>) {
      const key = action.payload;
      const progressNew = { ...state.progress };
      progressNew[key] = true;
      state.progress = progressNew;
    },
    removeProgress(state:OutputDataZaikoTmpState, action: PayloadAction<string>) {
      const key = action.payload;
      const progressNew = {};
      Object.keys(state.progress).forEach(k => {
        if (key != k) {
          progressNew[k] = true;
        }
      })
      state.progress = progressNew;
    },
    //定番マスタ
    searchedZaiko(state:OutputDataZaikoTmpState, action: PayloadAction<{ datas: RowDataZaiko[], param: RequestParam }>) {
      //計算
      let datas = action.payload.datas;
      const param = action.payload.param;

      datas = parseDataZaiko(datas);
      datas = calcDatasZaiko(datas); //計算項目の計算
      datas = filterZaiko(datas, param);

      state.datasZaiko = datas;
    },
    setDatasZaiko(state:OutputDataZaikoTmpState, action: PayloadAction<RowDataZaiko[]>) {
      const datas = action.payload;
      Object.assign(state, {
        datasZaiko: datas,
      });
    },
    setErrorMessage(state:OutputDataZaikoTmpState, action: PayloadAction<string>) {
      state.errorMessage = action.payload;
    },
    setInfoMessage(state:OutputDataZaikoTmpState, action: PayloadAction<string>) {
      state.infoMessage = action.payload;
    },
  },
});

//在庫マスタ
//数値のパース(数値が文字列で返ってくる)
const parseDataZaiko = (datas: RowDataZaiko[]): RowDataZaiko[] => {
  const zaikoKbn = {
    "1": "一般",
    "2": "特売",
    "3": "予約",
    "9": "出切",
    "90": "返品",
    "99": "全て",
  };

  //set No.
  datas.forEach(data => {
    //ここに貼り付け
    if (typeof data.ADR === 'string') data.ADR = data.ADR.trim();
    if (typeof data.IJN === 'string') data.IJN = data.IJN.trim();
    if (typeof data.PST === 'string') data.PST = parseFloat(data.PST);
    if (typeof data.SMKGN === 'string') data.SMKGN = parseFloat(data.SMKGN);
    if (typeof data.OKNSN === 'string') data.OKNSN = parseFloat(data.OKNSN);

    if (typeof data.BLN === 'string') data.BLN = parseFloat(data.BLN) ? parseFloat(data.BLN) : '';
    if (typeof data.PSN === 'string') data.PSN = parseFloat(data.PSN) ? parseFloat(data.PSN) : '';

    if (typeof data.ZKN === 'string') data.ZKN = zaikoKbn[data.ZKN];
    if (typeof data.UDAT === 'string') {
      const UDAT = moment(data.UDAT, 'YYYYMMDD').utcOffset(0, true);
      data.UDAT = UDAT.isValid() ? UDAT.toDate() : '99/99/99';
    }
    if (typeof data.SMKGD === 'string') {
      const SMKGD = moment(data.SMKGD, 'YYYYMMDD').utcOffset(0, true);
      data.SMKGD = SMKGD.isValid() ? SMKGD.toDate() : '99/99/99';
      data.SMKGDA = data.SMKGD;
      data.SMKGDB = data.SMKGD;
    }
    data.OKNSDA = moment().add(data.OKNSN, 'day').utcOffset(0, true).toDate();
    data.OKNSDB = moment().add(Math.floor(data.SMKGN / 2), 'day').utcOffset(0, true).toDate();
  });
  return datas;
}
//計算
const calcDatasZaiko = (datas: RowDataZaiko[]): RowDataZaiko[] => {

  datas.forEach(data => {
    //ここに貼り付け
    // data.SLR = calcUtil.divide(data.SLT, data.SLP);
    data.ICD = data.MKCD + data.BHCD;

    data.CHK = "(　　　　　　　)";
    data.RILI = " 　　／　　／　　";
  });
  datas.forEach(data => {
    data.ZQTY = datas.filter(d => d.ICD == data.ICD).reduce((prev, current) => prev + current.PST, 0);
  });
  return datas;
}

const filterZaiko = (datas: RowDataZaiko[], param: RequestParam): RowDataZaiko[] => {

  const baseDate = moment(param.baseDate, 'YYYYMMDD').utcOffset(0, true);

  switch (param.download) {
    case 2:   // ②鮮度切れ(DL区分:download2)の場合				 賞味期限 ＜(基準日+許容期間)の在庫のみ出力
      return datas.filter(data => data.SMKGD < baseDate.clone().add(data.OKNSN, 'day').toDate());
    case 3:   // ③翌週鮮度切れ(DL区分:download3)の場合		 賞味期限 ＜(基準日+7日+許容期間)の在庫のみ出力
      return datas.filter(data => data.SMKGD < baseDate.clone().add(data.OKNSN + 7, 'day').toDate());
    case 4:   // ④翌々週鮮度切れ(DL区分:download4)の場合    賞味期限 ＜(基準日+14日+許容期間)の在庫のみ出力
      return datas.filter(data => data.SMKGD < baseDate.clone().add(data.OKNSN + 14, 'day').toDate());
    case 5:   // ⑤9999/99/99(DL区分:download5)の場合      賞味期限 = 9999/99/99の在庫のみ出力
      return datas.filter(data => data.SMKGD == '99/99/99');
    case 6:   // ⑥賞味期限切れ(DL区分:download6)の場合      賞味期限 ＜基準日の在庫のみ出力
      return datas.filter(data => data.SMKGD < baseDate.toDate());
    case 1:   // ①全て(DL区分:download1)の場合            SQL出力結果を全て出力する。
    default:
      return datas;
  }
}


//Page Slice Export
//outputDataZaikoSave
export const outputDataZaikoTmpSlice = createSliceContent("outputDataZaikoTmp");
