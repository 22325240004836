import { CodeName } from "@/store/common";

//'none':検索前, 'edit'：編集時, 'commited'；確定済み, 'furikae'：振替済み, 'all'：全て
export type EditingType =  {key:'none'|'edit'|'commited'|'error'|'furikaed'|'all'|'release', readonly:boolean};
const EditingTypeNone:EditingType = {key:'none', readonly:true};
export const EditingTypeEdit:EditingType = {key:'edit', readonly:false};
export const EditingTypeCommited:EditingType = {key:'commited', readonly:true};
export const EditingTypeError:EditingType = {key:'error', readonly:false};
export const EditingTypeFurikaed:EditingType = {key:'furikaed', readonly:true};
const EditingTypeAll:EditingType = {key:'all', readonly:true};
const EditingTypeRelease:EditingType = {key:'release', readonly:true};
export const EditingTypes:EditingType[] = [EditingTypeNone, EditingTypeEdit, EditingTypeCommited, EditingTypeError, EditingTypeFurikaed, EditingTypeAll, EditingTypeRelease];
export const toEditingType = (editingTypeKey: string): EditingType => {
  const tmp = EditingTypes.find(editingType => editingType.key == editingTypeKey);
  return tmp ? tmp : EditingTypeNone;
}

//option1；EditingTypes
export const initialStatusList: CodeName[] = [
  //option2: 振替可能フラグ
  //option3: 訂正可能フラグ
  //option4: 表示有無
  //option5: 
  new CodeName({ code: '1', name: '発注前', option1: EditingTypeEdit.key, option2: 'true', option3: 'true', option4: 'true', option5: null, group: 'checkbox' }),
  new CodeName({ code: '2', name: '発注指示済', option1: EditingTypeCommited.key, option2: 'false', option3: 'false', option4: 'false', option5: null, group: 'checkbox' }),
  new CodeName({ code: '3:null,0,1', name: '発注処理中', option1: EditingTypeCommited.key, option2: 'false', option3: 'false', option4: 'true', option5: '2', group: 'checkbox' }), //status_cd:adms_if_status
  new CodeName({ code: '3:2', name: '発注済み', option1: EditingTypeCommited.key, option2: 'true', option3: 'true', option4: 'true', option5: null, group: 'checkbox' }),
  new CodeName({ code: "9", name: "発注エラー", option1: EditingTypeError.key, option2: 'true', option3: 'true', option4: 'true', option5: null, group: 'checkbox' }),
  new CodeName({ code: "F,G", name: "在庫振替済", option1: EditingTypeFurikaed.key, option2: 'true', option3: 'true', option4: 'true', option5: null, group: 'checkbox' }),
  new CodeName({ code: '1,2,3,9,F,G', name: '発注すべて', option1: EditingTypeAll.key, option2: 'false', option3: 'false', option4: 'true', option5: null }),
  new CodeName({ code: 'R', name: 'リリース希望', option1: EditingTypeRelease.key, option2: 'true', option3: 'false', option4: 'true', option5: null }),
  new CodeName({ code: 'C', name: '発注キャンセル', option1: EditingTypeAll.key, option2: 'false', option3: 'false', option4: 'true', option5: null }),
];

export const initialRequestDateTypeList: CodeName[] = [
  new CodeName({ code: '1', name: '依頼日', }),
  new CodeName({ code: '2', name: 'ADMS連携日' }),
  new CodeName({ code: '3', name: '倉入れ日' }),
  new CodeName({ code: '4', name: '出荷日', }),
  new CodeName({ code: '5', name: '納品日', }),
  new CodeName({ code: '6', name: '発注期限日', }),
];
