import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {RequestParam} from "@/assets/apitype/outputDataListTeiban";

import { CodeName } from "@/store/common";

//Page State
export type OutputDataTeibanSaveState = {
  //倉庫
  center: CodeName | null,
  //得意先
  tokuisakis: CodeName[] | null,
  //ベンダーコード
  vendorCode: string | null,
  //定番/定番外
  teiban_flg: "" | "定番" | "定番外",

  //検索終了した条件
  requestedParamsTeiban : RequestParam[],

};

export const initialState: OutputDataTeibanSaveState = {
  //倉庫
  center: null,
  //得意先
  tokuisakis: [],
  //ベンダーコード
  vendorCode: '',
  //定番/定番外
  teiban_flg: "定番",

  requestedParamsTeiban : [],

};

//Page Slice
const createSliceContent = (name:string) => createSlice({
  name: name,
  initialState,
  reducers: {
    //componentDidMount
    initOnDidMount(state:OutputDataTeibanSaveState) {
      //追加属性等はlocalstorageから復元したときにundefinedになっている場合があるためチェックする
      if(!state.center) {
        state.center = initialState.center;
      }
      if(!state.tokuisakis || state.tokuisakis.length == 0) {
        state.tokuisakis = initialState.tokuisakis;
      }
      if (!state.vendorCode) {
        state.vendorCode = initialState.vendorCode;
      }
      if(!state.teiban_flg) {
        state.teiban_flg = initialState.teiban_flg;
      }
    },

    // Option Center
    setCenter(state:OutputDataTeibanSaveState, action: PayloadAction<CodeName>) {
      state.center = action.payload;
    },
    setCenterList(state:OutputDataTeibanSaveState, action: PayloadAction<CodeName[]>) {
      const centerList = action.payload;
      let center = state.center && state.center.code? centerList.find((center) => center.code == state.center.code) : null;
      if(!center) {
        center = centerList[0];
      }
      state.center = center;
    },
    // Option Tokuisaki
    setTokuisakis(state:OutputDataTeibanSaveState, action: PayloadAction<CodeName[]>) {
      state.tokuisakis = action.payload;
    },
    setTokuisakiList(state:OutputDataTeibanSaveState, action: PayloadAction<CodeName[]>) {
      const tokuisakiList = action.payload;
      let tokuisakis = [];
      if(state.tokuisakis && state.tokuisakis.length > 0) {
        const tokuisakiCDs:string[] = state.tokuisakis.map(tokuisaki => tokuisaki.code);
        tokuisakis = tokuisakiList.filter(tokuisaki => tokuisakiCDs.indexOf(tokuisaki.code) !== -1);
      }
      state.tokuisakis = tokuisakis;
    },
    setVendorCode(state: OutputDataTeibanSaveState, action: PayloadAction<string>) {
      state.vendorCode = action.payload;
    },
    setOptionTeibanFlg(state:OutputDataTeibanSaveState, action: PayloadAction<"" | "定番" | "定番外">) {
      state.teiban_flg = action.payload;
    },

    clearOption(state:OutputDataTeibanSaveState, action: PayloadAction<{
      centerList: CodeName[],
    }>) {
      state.center = action.payload.centerList[0];
      state.tokuisakis = [];
      state.teiban_flg = initialState.teiban_flg;
    },

    //検索条件
    setRequestedParamsTeiban(state:OutputDataTeibanSaveState, action: PayloadAction<RequestParam[]>) {
      state.requestedParamsTeiban = action.payload;
    },
  },
});

//Page Slice Export
//outputDataTeibanSave
export const outputDataTeibanSaveSlice = createSliceContent("outputDataTeibanSave");
