import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RequestParam } from "@/assets/apitype/outputDataListTokuisaki";
import { CodeName } from "@/store/common";

//得意先マスタ
export interface RowDataTokuisaki {
  no?: string,
  NEC?: string | null,
  NEN?: string | null,
  NTC?: string | null,
  NTN?: string | null,
  TOC?: string | null,
  VNC?: string | null,
  STC?: string | null,
  KGC?: string | null,
  KGS?: string | null,
  HJC?: string | null,
  DKC?: string | null,
  DKN?: string | null,
  CTC?: string | null,
  CTN?: string | null,
  RTC?: string | null,
  TK1?: string | null,
  TK2?: string | null,
  TK3?: string | null,
  TS1?: string | null,
  TS2?: string | null,
  TS3?: string | null,
  ZIP?: string | null,
  AD1?: string | null,
  AD2?: string | null,
  AD3?: string | null,
  ZIS?: string | null,
  AS1?: string | null,
  AS2?: string | null,
  AS3?: string | null,
  TEL?: string | null,
  FAX?: string | null,
  LED?: string | null,
  REG?: string | null,
  CHG?: string | null,
  ARA?: string | null,
  PRF?: string | null,
  CTY?: string | null,
  GTC?: string | null,
  GTN?: string | null,
  STP?: string | null,
  STF?: string | null,
  DM1?: string | null,
  DM2?: string | null,
  DM3?: string | null,
  SDC?: string | null,
  RDF?: string | null,
  PMF?: string | null,
  SGF?: string | null,
  SFD?: string | null,
  SMT?: string | null,
  MFD?: string | null,
  TDC?: string | null,
  TD1?: string | null,
  TD2?: string | null,
  LEDT?: string | null,
  TNF?: string | null,
  SNF?: string | null,
  EOS?: string | null,
  EDI?: string | null,
  OEC?: string | null,
  OEN?: string | null,
  TCD?: string | null,
  OTC?: string | null,
  OTN?: string | null,
  CCD?: string | null,
  OCC?: string | null,
  }


//Page State
export type OutputDataTokuisakiTmpState = {
  //部門
  buList: CodeName[],
  //倉庫
  centerList: CodeName[],
  //得意先
  tokuisakiList: CodeName[],

  accordionOpenTokuisaki: boolean,
  //検索する条件
  requestParamTokuisaki : RequestParam,
  //検索終了した条件
  retrievedIDs: string[],

  progress: Record<string, unknown>,
  datasTokuisaki: RowDataTokuisaki[],

  isIME: boolean,
  honbuIsIME: boolean,
  errorMessage: string | null,
};

export const initialState: OutputDataTokuisakiTmpState = {
  //部門
  buList: [],
  //倉庫
  centerList: [],
  //得意先
  tokuisakiList: [],

  accordionOpenTokuisaki: true,
  requestParamTokuisaki : {},
  retrievedIDs: [],

  progress: {},
  datasTokuisaki: [],

  isIME: false,
  honbuIsIME: false,
  errorMessage: null,
};

//Page Slice
const createSliceContent = (name:string) => createSlice({
  name: name,
  initialState,
  reducers: {
    setBuList(state:OutputDataTokuisakiTmpState, action: PayloadAction<CodeName[]>) {
      state.buList = action.payload;
    },
    setCenterList(state:OutputDataTokuisakiTmpState, action: PayloadAction<CodeName[]>) {
      state.centerList = action.payload;
    },
    setTokuisakiList(state:OutputDataTokuisakiTmpState, action: PayloadAction<CodeName[]>) {
      state.tokuisakiList = action.payload;
    },

    setAccordionOpenTokuisaki(state:OutputDataTokuisakiTmpState, action: PayloadAction<boolean>) {
      state.accordionOpenTokuisaki = action.payload;
    },
    //検索条件
    setRequestParamTokuisaki(state:OutputDataTokuisakiTmpState, action: PayloadAction<RequestParam>) {
      state.requestParamTokuisaki = action.payload;
    },
    setRetrievedID(state:OutputDataTokuisakiTmpState, action: PayloadAction<string>) {
      state.retrievedIDs = [action.payload];
    },
    addRetrievedID(state:OutputDataTokuisakiTmpState, action: PayloadAction<string>) {
      const retrievedIDs = [...state.retrievedIDs];
      retrievedIDs.push(action.payload);
      state.retrievedIDs = retrievedIDs;
    },

    putProgress(state:OutputDataTokuisakiTmpState, action: PayloadAction<string>) {
      const key = action.payload;
      const progressNew = {...state.progress};
      progressNew[key] = true;
      state.progress = progressNew;
    },
    removeProgress(state:OutputDataTokuisakiTmpState, action: PayloadAction<string>) {
      const key = action.payload;
      const progressNew = {};
      Object.keys(state.progress).forEach(k => {
        if(key != k) {
          progressNew[k] = true;
        }
      })
      state.progress = progressNew;
    },
    //得意先マスタ
    searchedTokuisaki(state:OutputDataTokuisakiTmpState, action: PayloadAction<{param: RequestParam, datas:RowDataTokuisaki[]}>) {
      //計算
      let datas = action.payload.datas;

      datas = doSortTokuisaki(datas);  //ソート
      datas = resetRowNoTokuisaki(datas); //列番号の振りなおし

      Object.assign(state, {
        datasTokuisaki: datas,
      });
    },
    setDatasTokuisaki(state:OutputDataTokuisakiTmpState, action: PayloadAction<RowDataTokuisaki[]>) {
      const datas = action.payload;
      Object.assign(state, {
        datasTokuisaki: datas,
      });
    },
    setIsIME(state:OutputDataTokuisakiTmpState, action: PayloadAction<boolean>){
      state.isIME = action.payload;
    },
    setHonbuIsIME(state: OutputDataTokuisakiTmpState, action: PayloadAction<boolean>) {
      state.honbuIsIME = action.payload;
    },
    setErrorMessage(state:OutputDataTokuisakiTmpState, action: PayloadAction<string>) {
      state.errorMessage = action.payload;
    }
  },
});


//得意先マスタ

//no振りなおし
//no振りなおし
const resetRowNoTokuisaki = (datas:RowDataTokuisaki[]): RowDataTokuisaki[] => {
  let no = 1;
  datas = datas.map((row) => {
    return {
      ...row,
      no: "" + (no++),
    }
  });
  return datas;
}

//ソート
const doSortTokuisaki = (datas:RowDataTokuisaki[]): RowDataTokuisaki[] => {
  return datas;
}


//Page Slice Export
//outputDataTokuisakiTmp
export const outputDataTokuisakiTmpSlice = createSliceContent("outputDataTokuisakiTmp");

