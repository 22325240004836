import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import * as calcUtil from "@/util/calcUtil";
import * as compareUtil from "@/util/compareUtil";

export interface RowDataItemTokuisaki {
  TP?: "total" | "group1" | "item",

  KGC1?: string,
  KGG1?: string,
  TCD1?: string,
  TNM1?: string,
  TCD2?: string,
  TNM2?: string,

  itblir?: number | null

  HQTY1?: number | null, HQTY1_PS?: number | null
  HQTY2?: number | null, HQTY2_PS?: number | null
  HQTY3?: number | null, HQTY3_PS?: number | null
  HQTY4?: number | null, HQTY4_PS?: number | null
  HQTY?: number | null,  HQTY_PS?: number | null

  HKIN1?: number | null
  HKIN2?: number | null
  HKIN3?: number | null
  HKIN4?: number | null
  HKIN?: number | null

  __children?: RowDataItemTokuisaki[] | null
}

//Page State
export type ItemListTokuisakiTmpState = {
  datasAll: RowDataItemTokuisaki[],
  datas: RowDataItemTokuisaki[],
  rows: RowDataItemTokuisaki[],
  mergeCells: [],
  selectionRowStart: number,
  selectionRowEnd: number,

};

export const initialState: ItemListTokuisakiTmpState = {
  datasAll: [],
  datas: [],
  rows: [],
  mergeCells: null,
  selectionRowStart: -1,
  selectionRowEnd: -1,

};

//Page Slice
const createSliceContent = (name:string) => createSlice({
  name: name,
  initialState,
  reducers: {
    setDatas(state:ItemListTokuisakiTmpState, action: PayloadAction<{ datas: RowDataItemTokuisaki[], sort: { key: string, asc: boolean } }>) {
      const sort = action.payload.sort;
      const groupingGroup1 = true;

      //計算
      let datas = action.payload.datas;

      datas = parseData(datas);

      datas = calcTotal(datas); //合計行の作成
      datas = calcDatas(datas, "all"); //計算項目の計算
      const datasAll = [...datas];

      datas = makeDatas(datas, sort, groupingGroup1);

      let key = sort.key;
      datas = doSort(datas, key, sort.asc, groupingGroup1);  //ソート

      Object.assign(state, {
        datasAll: datasAll,
        datas: datas,
        rows: convertRows(datas),
      });
    },
    execSort(state:ItemListTokuisakiTmpState, action: PayloadAction<{ sort: { key: string, asc: boolean } }>) {
      const sort = action.payload.sort;
      const groupingGroup1 = true;

      let key = sort.key;
      const asc = sort.asc;
      console.log('tokuisaki execSort', key);

      let datas = [...state.datas];
      datas = doSort(datas, key, asc, groupingGroup1);

      Object.assign(state, {
        datas: datas,
        //テーブル用のデータに変換
        rows: convertRows(datas),
      });
    },
  },
});

//数値のパース(数値が文字列で返ってくる)
const parseData = (datas:RowDataItemTokuisaki[]): RowDataItemTokuisaki[] => {
  //set No.
  datas.forEach((data) => {
    if (typeof data.itblir === 'string') data.itblir = parseInt(data.itblir);

    if (typeof data.HQTY1_PS === 'string') data.HQTY1_PS = parseInt(data.HQTY1_PS);
    if (typeof data.HQTY2_PS === 'string') data.HQTY2_PS = parseInt(data.HQTY2_PS);
    if (typeof data.HQTY3_PS === 'string') data.HQTY3_PS = parseInt(data.HQTY3_PS);
    if (typeof data.HQTY4_PS === 'string') data.HQTY4_PS = parseInt(data.HQTY4_PS);
    if (typeof data.HQTY_PS === 'string') data.HQTY_PS = parseInt(data.HQTY_PS);
    if (typeof data.HKIN1 === 'string') data.HKIN1 = parseInt(data.HKIN1);
    if (typeof data.HKIN2 === 'string') data.HKIN2 = parseInt(data.HKIN2);
    if (typeof data.HKIN3 === 'string') data.HKIN3 = parseInt(data.HKIN3);
    if (typeof data.HKIN4 === 'string') data.HKIN4 = parseInt(data.HKIN4);
    if (typeof data.HKIN === 'string') data.HKIN = parseInt(data.HKIN);
  });
  return datas;
}
//合計行作成
const calcTotal = (datas:RowDataItemTokuisaki[]): RowDataItemTokuisaki[] => {
  const total:RowDataItemTokuisaki = {
    TP: "total",
    TNM2:"合計",
  };

  //set No.
  datas.forEach((data) => {
    if(data.TP == "item") {
      calcTotalSub(total, data);
    }
  });

  if(datas.length > 0 && datas[0].TP == "total") {
    datas[0] = total;
  }
  else {
    datas = [total, ...datas];
  }

  return datas;
}

//total行作成
const calcTotalSub = (total: RowDataItemTokuisaki, data: RowDataItemTokuisaki) => {
  //以下に貼り付け
  total.itblir = data.itblir;

  total.HQTY1_PS = calcUtil.plus(total.HQTY1_PS, data.HQTY1_PS);
  total.HQTY2_PS = calcUtil.plus(total.HQTY2_PS, data.HQTY2_PS);
  total.HQTY3_PS = calcUtil.plus(total.HQTY3_PS, data.HQTY3_PS);
  total.HQTY4_PS = calcUtil.plus(total.HQTY4_PS, data.HQTY4_PS);
  total.HQTY_PS = calcUtil.plus(total.HQTY_PS, data.HQTY_PS);
  total.HKIN1 = calcUtil.plus(total.HKIN1, data.HKIN1);
  total.HKIN2 = calcUtil.plus(total.HKIN2, data.HKIN2);
  total.HKIN3 = calcUtil.plus(total.HKIN3, data.HKIN3);
  total.HKIN4 = calcUtil.plus(total.HKIN4, data.HKIN4);
  total.HKIN = calcUtil.plus(total.HKIN, data.HKIN);
}

//計算
const calcDatas = (datas: RowDataItemTokuisaki[], targetTP: "all" | "total" | "group1" | "item"): RowDataItemTokuisaki[] => {
  datas.forEach((data) => {
    if (targetTP == "all" || data.TP == targetTP) {
      data.HQTY1 = data.HQTY1_PS ? (Math.floor(data.HQTY1_PS / data.itblir * 10) / 10) : null;
      data.HQTY2 = data.HQTY2_PS ? (Math.floor(data.HQTY2_PS / data.itblir * 10) / 10) : null;
      data.HQTY3 = data.HQTY3_PS ? (Math.floor(data.HQTY3_PS / data.itblir * 10) / 10) : null;
      data.HQTY4 = data.HQTY4_PS ? (Math.floor(data.HQTY4_PS / data.itblir * 10) / 10) : null;
      data.HQTY = data.HQTY_PS ? (Math.floor(data.HQTY_PS / data.itblir * 10) / 10) : null;
    }
  });

  return datas;
}

const makeDatas = (datas: RowDataItemTokuisaki[], sort: { key: string, asc: boolean }, groupingGroup1: boolean): RowDataItemTokuisaki[] => {
  if (groupingGroup1) {
    datas = calcGroup1(datas); //企業グループ１行の作成
    datas = calcDatas(datas, "group1"); //計算項目の計算
  }
  return datas;
};

//企業グループ１行作成
const calcGroup1 = (datas:RowDataItemTokuisaki[]): RowDataItemTokuisaki[] => {
  const group1Map = {};
  datas = datas.filter(data => data.TP != "group1"); //企業グループ１行の削除

  //set No.
  datas.forEach((data) => {
    if(data.TP == "item") {
      const KGC1 = data.KGC1;
      let group1:RowDataItemTokuisaki = group1Map[KGC1];
      if(!group1) {
        group1 = {
          TP: "group1",
          KGC1: data.KGC1,
          TNM2: data.KGG1,
        };
        group1Map[KGC1] = group1;
      }

      calcTotalSub(group1, data);
    }
  });

  //あとのソートのために、group1は上に入れる。
  const newDatas = [];
  Object.keys(group1Map).forEach(KGC1 => newDatas.push(group1Map[KGC1]));
  return [...newDatas, ...datas];
}

//親子関係をもつデータに変換
const convertRows = (datas:RowDataItemTokuisaki[]): RowDataItemTokuisaki[] => {
  let rows = [];

  const total = datas.filter(data => data.TP == "total");
  const group1s = datas.filter(data => data.TP == "group1");
  group1s.forEach(group1 => {
    const items = datas.filter(data => data.TP == "item" && group1.KGC1 == data.KGC1);
    group1.__children = items;
  });

  rows = rows.concat(total);
  rows = rows.concat(group1s);

  return rows;
}

//ソート
const doSort = (datas:RowDataItemTokuisaki[], colKey:string, asc:boolean, groupingGroup1:boolean): RowDataItemTokuisaki[] => {
  console.log('tokuisaki doSort');
  //Totalのソート
  const sortContTotal = (colKey, asc, objA):number => {
    //合計行は常に上
    return objA.TP == "total" ? -1 : 1;
  };
  //同じ階層同士のソート
  const sortCont = (TP, colKey, asc, objA, objB):number => {
    let comp = 0;
    const va = objA ? objA[colKey] : null;
    const vb = objB ? objB[colKey] : null;
    //数値型
    if(typeof va === 'number' || typeof vb === 'number') {
      comp = compareUtil.compareNumber2(va, vb, asc);
    }
    else if(typeof va === 'string' || typeof vb === 'string') {
      comp = compareUtil.compareString(va, vb, asc);
    }
    //それでもソートできない場合、企業グループ１小計ならばコード順とする
    if (comp == 0 && TP == 'group1') {
      comp = compareUtil.compareString(objA ? objA.KGC1 : null, objB ? objB.KGC1 : null, asc);
    }
    return comp;
  };

  //企業グループ１小計あり
  if (groupingGroup1) {
    datas.sort((a, b) => {
      //合計行は常に上
      if (a.TP == "total" || b.TP == "total") {
        return sortContTotal(colKey, asc, a);
      }

      let objA = a;
      let objB = b;
      //階層違い
      if (a.TP != b.TP) {
        const lvA = ['group1', 'item'].indexOf(a.TP);
        const lvB = ['group1', 'item'].indexOf(b.TP);
        if (Math.min(lvA, lvB) == 0) { //LV1
          if (a.KGC1 == b.KGC1) {  //同じコードならLV低い方を上
            return lvA - lvB;
          }
          if (a.TP != 'group1') {
            objA = datas.find(data => data.TP == 'group1' && data.KGC1 == a.KGC1);
          }
          if (b.TP != 'group1') {
            objB = datas.find(data => data.TP == 'group1' && data.KGC1 == b.KGC1);
          }
        }
      }
      //階層同じ
      else {
        if (a.KGC1 != b.KGC1) {
          objA = datas.find(data => data.TP == 'group1' && data.KGC1 == a.KGC1);
          objB = datas.find(data => data.TP == 'group1' && data.KGC1 == b.KGC1);
        }
      }

      return sortCont(objA ? objA.TP : objB ? objB.TP : null, colKey, asc, objA, objB);
    });
  }
  //企業グループ１小計なし
  else {
    datas.sort((a, b) => {
      //合計行は常に上
      if (a.TP == "total" || b.TP == "total") {
        return sortContTotal(colKey, asc, a);
      }

      return sortCont(a.TP, colKey, asc, a, b);
    });
  }
  return datas;
}


//Page Slice Export
export const itemListTokuisakiTmpSlice = createSliceContent("itemListTokuisakiTmp");
