import moment from 'moment';

export const plusDays = (moment:moment.Moment, n:number):moment.Moment => {
  if(!n || !moment) {
    return moment;
  }

  if(!moment.isValid()) {
    return moment;
  }

  return moment.add(n, 'days');
}

export const minusDays = (moment:moment.Moment, n:number):moment.Moment => {
  if(!n || !moment) {
    return moment;
  }

  if(!moment.isValid()) {
    return moment;
  }

  return moment.subtract(n, 'days');
}

/*
  営業日計算
*/

export const enableInBusiness = (moment:moment.Moment, allowMonDay:boolean, allowTueDay:boolean, allowWedDay:boolean, allowThrDay:boolean, allowFriDay:boolean, allowSatDay:boolean, allowSunDay:boolean, allowHoliday:boolean, holidays:any, disableddays?:any):boolean => {
  if(!moment) {
    return false;
  }

  if(!moment.isValid()) {
    return false;
  }

  //１〜７（月〜日）
  const w = moment.isoWeekday(); 

  const b =
    (w != 1 || allowMonDay) &&  //月
    (w != 2 || allowTueDay) &&  //火
    (w != 3 || allowWedDay) &&  //水
    (w != 4 || allowThrDay) &&  //木
    (w != 5 || allowFriDay) &&  //金
    (w != 6 || allowSatDay) &&  //土
    (w != 7 || allowSunDay) &&  //日
    //祝日
    (allowHoliday || !holidays?.[moment.format("YYYY-MM-DD")]) &&
    //不可日
    (!disableddays || !disableddays?.[moment.format("YYYY-MM-DD")])
  ;
  return b;
}

export const plusDaysInBusiness = (moment:moment.Moment, n:number, allowMonDay:boolean, allowTueDay:boolean, allowWedDay:boolean, allowThrDay:boolean, allowFriDay:boolean, allowSatDay:boolean, allowSunDay:boolean, allowHoliday:boolean, holidays:any, disableddays?:any):moment.Moment => {
  if(!n || !moment) {
    return moment;
  }

  if(!moment.isValid()) {
    return moment;
  }

  let t:number = n;
  while(t > 0) {
    moment = moment.add(1, 'days');
    if(enableInBusiness(
      moment,
      allowMonDay,
      allowTueDay,
      allowWedDay,
      allowThrDay,
      allowFriDay,
      allowSatDay,
      allowSunDay,
      allowHoliday,
      holidays,
      disableddays
    )) {
      t--;
    }
  }

  return moment;
}
export const plusDaysInBusinessLeadTime = (moment:moment.Moment, n:number, allowMonDay:boolean, allowTueDay:boolean, allowWedDay:boolean, allowThrDay:boolean, allowFriDay:boolean, allowSatDay:boolean, allowSunDay:boolean, allowHoliday:boolean, holidays:any, disableddays?:any):moment.Moment => {
  if(!n || !moment) {
    return moment;
  }

  if(!moment.isValid()) {
    return moment;
  }

  let t:number = n;
  while(t > 0) {
    //moment = moment.add(1, 'days');
    if(enableInBusiness(
      moment,
      allowMonDay,
      allowTueDay,
      allowWedDay,
      allowThrDay,
      allowFriDay,
      allowSatDay,
      allowSunDay,
      allowHoliday,
      holidays,
      disableddays
    )) {
      t--;
    }
    moment = moment.add(1, 'days');
  }

  return moment;
}

export const minusDaysInBusiness = (moment:moment.Moment, n:number, allowMonDay:boolean, allowTueDay:boolean, allowWedDay:boolean, allowThrDay:boolean, allowFriDay:boolean, allowSatDay:boolean, allowSunDay:boolean, allowHoliday:boolean, holidays:any, disableddays?:any):moment.Moment => {
  if(!n || !moment) {
    return moment;
  }

  if(!moment.isValid()) {
    return moment;
  }

  let t:number = n;
  while(t > 0) {
    moment = moment.subtract(1, 'days');

    //１〜７（月〜日）
    const w = moment.isoWeekday(); 

    if(
      (w != 1 || allowMonDay) &&  //月
      (w != 2 || allowTueDay) &&  //火
      (w != 3 || allowWedDay) &&  //水
      (w != 4 || allowThrDay) &&  //木
      (w != 5 || allowFriDay) &&  //金
      (w != 6 || allowSatDay) &&  //土
      (w != 7 || allowSunDay) &&  //日
      //祝日
      (allowHoliday || !holidays[moment.format("YYYY-MM-DD")]) &&
      //不可日
      (!disableddays || !disableddays?.[moment.format("YYYY-MM-DD")])
    ) {
      t--;
    }
    
  }

  return moment;
}

/*
  土曜日曜祝日を除いた計算
*/
export const plusDaysInBusinessSSH = (moment:moment.Moment, holidays:any, n:number):moment.Moment => {
  if(!n || !moment) {
    return moment;
  }

  if(!moment.isValid()) {
    return moment;
  }

  let t:number = n;
  while(t > 0) {
    moment = moment.add(1, 'days');
    //土曜・日曜・祝日はスキップ
    if(!isSaturdayOrSundayOrHoliday(moment, holidays)) {
      t--;
    }
  }

  return moment;
}

/*
  土曜日曜祝日を除いた計算
*/
export const minusDaysInBusinessSSH = (moment:moment.Moment, holidays:any, n:number):moment.Moment => {
  if(!n || !moment) {
    return moment;
  }

  if(!moment.isValid()) {
    return moment;
  }

  let t:number = n;
  while(t > 0) {
    moment = moment.subtract(1, 'days');
    //土曜・日曜・祝日はスキップ
    if(!isSaturdayOrSundayOrHoliday(moment, holidays)) {
      t--;
    }
  }

  return moment;
}

/*
  日曜祝日を除いた計算
*/
export const plusDaysInBusinessSH = (moment:moment.Moment, holidays:any, n:number):moment.Moment => {
  if(!n || !moment) {
    return moment;
  }

  if(!moment.isValid()) {
    return moment;
  }

  let t:number = n;
  while(t > 0) {
    moment = moment.add(1, 'days');
    //土曜・日曜・祝日はスキップ
    if(!isSundayOrHoliday(moment, holidays)) {
      t--;
    }
  }

  return moment;
}

/*
  日曜祝日を除いた計算
*/
export const minusDaysInBusinessSH = (moment:moment.Moment, holidays:any, n:number):moment.Moment => {
  if(!n || !moment) {
    return moment;
  }

  if(!moment.isValid()) {
    return moment;
  }

  let t:number = n;
  while(t > 0) {
    moment = moment.subtract(1, 'days');
    //土曜・日曜・祝日はスキップ
    if(!isSundayOrHoliday(moment, holidays)) {
      t--;
    }
  }

  return moment;
}

//日曜・祝日
export const isSundayOrHoliday = (moment:moment.Moment, holidays:any):boolean => {
  if(!moment) {
    return false;
  }

  if(!moment.isValid()) {
    return false;
  }

  //１〜７（月〜日）
  const w = moment.isoWeekday(); 
  switch (w) {
    case 7: //日曜
      return true;
    default:
      //祝日
      if(holidays[moment.format("YYYY-MM-DD")]) {
        return true;
      }
      return false;
  }
}
//土曜・日曜・祝日
export const isSaturdayOrSundayOrHoliday = (moment:moment.Moment, holidays:any):boolean => {
  if(!moment) {
    return false;
  }

  if(!moment.isValid()) {
    return false;
  }

  //１〜７（月〜日）
  const w = moment.isoWeekday(); 
  switch (w) {
    case 6: //土曜
    case 7: //日曜
      return true;
    default:
      //祝日
      if(holidays[moment.format("YYYY-MM-DD")]) {
        return true;
      }
      return false;
  }
}


/*
  日曜祝日を除いた日数けいさん
*/
export const daysCountInBusiness = (from:moment.Moment, to:moment.Moment, holidays:any):number => {
  if(!from || !to) {
    return null;
  }

  if(!from.isValid() || !to.isValid()) {
    return null;
  }

  let t:number = 0;
  const toS:string = to.format('YYYYMMDD');
  while(from.format('YYYYMMDD') <= toS) {
    from = from.add(1, 'days');
    //日曜・祝日はスキップ
    if(!isSundayOrHoliday(from, holidays)) {
      t++;
    }
  }

  return t;
}

/*
  日数けいさん
*/
export const daysCount = (from:moment.Moment, to:moment.Moment):number => {
  if(!from || !to) {
    return null;
  }

  if(!from.isValid() || !to.isValid()) {
    return null;
  }

  let t:number = 0;
  let tmp = moment(from.format('YYYY/MM/DD'));
  const toS:string = to.format('YYYYMMDD');
  while(tmp.format('YYYYMMDD') <= toS) {
    tmp = tmp.add(1, 'days');
    t++;
  }

  return t;
}

/*
  pikaday.jsのeventsのフォーマットに変換
  events:array of dates that you would like to differentiate from regular days (e.g. ['Sat Jun 28 2017', 'Sun Jun 29 2017', 'Tue Jul 01 2017',])
 */
const dayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
export const convertPikadayEventDay = (eventday: string): string => {
  if (!eventday) {
    return null;
  }

  let tmp = null;
  const newDate = ((tmp = moment(eventday)).isValid() ? tmp : moment('20' + eventday)).toDate();
  const day = dayNames[newDate.getDay()];
  const month = monthNames[newDate.getMonth()];
  const date = ('0' + newDate.getDate()).slice(-2);
  const year = newDate.getFullYear();
  return day + ' ' + month + ' ' + date + ' ' + year;
}
