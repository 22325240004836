//行固定
export const fixedRowsTop = 0;

//テーブルの各列のキー
export const colKeys: string[] = [
  "TRC",
  "SAC",
  "BSC",
  "BSN",
  "TKH",
  "TKN",
  "TKC",
  "SIN",
  "NHY",
  "KJD",
  "DNK",
  "DKN",
  "SPN",
  "JSN",
  "TSC",
  "JAN",
  "SHC",
  "MNK",
  "KKK",
  "ITN",
  "NOK",
  "BRC",
  "NHK",
  "CSC",
  "BLC",
];

//テーブルの各列のキー逆引き
const colKeysMap = {};
colKeys.forEach((colKey, index) => {
  colKeysMap[colKey] = index;
});

export const headers = [
  {
    no : "",
    //以下に貼り付け
    TRC: "取引先コード",
    SAC: "社店コード",
    BSC: "部門・倉庫コード",
    BSN: "部門・倉庫名",
    TKH: "得意先本部コード",
    TKN: "得意先本部名",
    TKC: "得意先支店コード",
    SIN: "得意先支店名",
    NHY: "納品日",
    KJD: "計上日",
    DNK: "伝区コード",
    DKN: "伝区名",
    SPN: "先方No",
    JSN: "自社No",
    TSC: "得意先商品コード",
    JAN: "JANコード",
    SHC: "商品コード",
    MNK: "メーカー",
    KKK: "規格",
    ITN: "商品名",
    NOK: "納価",
    BRC: "数量",
    NHK: "納品金額",
    CSC: "ケース数量",
    BLC: "ボール数量",
  },
];

export const colWidths =
{
  no : 70,
  //以下に貼り付け
  TRC: "150",
  SAC: "150",
  BSC: "150",
  BSN: "150",
  TKH: "150",
  TKN: "150",
  TKC: "150",
  SIN: "150",
  NHY: "150",
  KJD: "150",
  DNK: "150",
  DKN: "150",
  SPN: "150",
  JSN: "150",
  TSC: "150",
  JAN: "150",
  SHC: "150",
  MNK: "150",
  KKK: "150",
  ITN: "150",
  NOK: "150",
  BRC: "150",
  NHK: "150",
  CSC: "150",
  BLC: "150",
};

export const colDataType =
{
  no : {type: 'text', readOnly: true,},
  //以下に貼り付け
  TRC: {type: 'text'},
  SAC: {type: 'text'},
  BSC: {type: 'text'},
  BSN: {type: 'text'},
  TKH: {type: 'text'},
  TKN: {type: 'text'},
  TKC: {type: 'text'},
  SIN: {type: 'text'},
  NHY: {type: 'text'},
  KJD: {type: 'text'},
  DNK: {type: 'text'},
  DKN: {type: 'text'},
  SPN: {type: 'text'},
  JSN: {type: 'text'},
  TSC: {type: 'text'},
  JAN: {type: 'text'},
  SHC: {type: 'text'},
  MNK: {type: 'text'},
  KKK: {type: 'text'},
  ITN: {type: 'text'},
  NOK: {type: 'numeric', numericFormat: {pattern: '#,##0.0',},},
  BRC: {type: 'numeric', numericFormat: {pattern: '#,##0',},},
  NHK: {type: 'numeric', numericFormat: {pattern: '#,##0',},},
  CSC: {type: 'numeric', numericFormat: {pattern: '#,##0',},},
  BLC: {type: 'numeric', numericFormat: {pattern: '#,##0',},},
};

//伝区名
export const denkNames =
{
  "00": "店出-通常売上",
  "01": "店出-オペミス",
  "02": "店出-未出荷",
  "03": "店出-品違い",
  "04": "店出-単価違い",
  "05": "店出-口座違い",
  "06": "店出-取消し",
  "07": "店出-重複",
  "08": "店出-単位違い",
  "09": "店出-その他",
  "30": "不良品-通常売上返品",
  "31": "不良品-オペミス",
  "32": "不良品-未出荷",
  "33": "不良品-品違い",
  "34": "不良品-単価違い",
  "35": "不良品-口座違い",
  "36": "不良品-取消し",
  "37": "不良品-重複",
  "38": "不良品-単位違い",
  "39": "不良品-その他",
  "51": "サービス-オペミス",
  "52": "サービス-未出荷",
  "53": "サービス-品違い",
  "54": "サービス-単価違い",
  "55": "サービス-口座違い",
  "56": "サービス-取消し",
  "57": "庫内廃棄-個別区分",
  "58": "返品廃棄-個別区分",
  "59": "サービス-その他",
  "10": "直送-通常直送",
  "11": "直送-オペミス",
  "12": "直送-未出荷",
  "13": "直送-品違い",
  "14": "直送-単価違い",
  "15": "直送-口座違い",
  "16": "直送-取消し",
  "17": "直送-重複",
  "18": "直送-単位違い",
  "19": "直送-その他",
  "40": "直送返品-通常直送返品",
  "41": "直送返品-オペミス",
  "42": "直送返品-未出荷",
  "43": "直送返品-品違い",
  "44": "直送返品-単価違い",
  "45": "直送返品-口座違い",
  "46": "直送返品-取消し",
  "47": "直送返品-重複",
  "48": "直送返品-単位違い",
  "49": "直送返品-その他",
}

//function

export const keyFromCol = (column:number):string => {
  return colKeys[column];
}
export const keyFromCols = (columns:number[]):string[] => {
  const vals:string[] = [];
  columns.forEach(column => vals.push(keyFromCol(column)))
  return vals;
}
export const colFromKey = (colKey:string):number => {
  return colKeysMap[colKey];
}
export const colFromKeys = (colKeys:string[]):number[] => {
  const vals:number[] = [];
  colKeys.forEach(colKey => vals.push(colFromKey(colKey)))
  return vals;
}
