import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { colKeys } from "@/components/itemlist/ItemListGroup2Model";
import { RowDataSupplier, parseDataSupplier, parseDataDenkName, doSortSupplier, resetRowNoSupplier } from "@/store/outputdata/outputDataSupplierTmp";
import * as outputDataListSupplierApi from "@/assets/apitype/outputDataListSupplier";

import * as calcUtil from "@/util/calcUtil";
import * as compareUtil from "@/util/compareUtil";
import moment from 'moment';

export interface RowDataItemGroup2 {
  TP?: "total" | "group2" | "group1",

  KGC2?: string,
  KGG2?: string,
  KGC1?: string,
  KGG1?: string,

  itblir?: number | null

  HQTY1?: number | null, HQTY1_PS?: number | null
  HQTY2?: number | null, HQTY2_PS?: number | null
  HQTY3?: number | null, HQTY3_PS?: number | null
  HQTY4?: number | null, HQTY4_PS?: number | null
  HQTY?: number | null,  HQTY_PS?: number | null

  HKIN1?: number | null
  HKIN2?: number | null
  HKIN3?: number | null
  HKIN4?: number | null
  HKIN?: number | null

  DATEUP?: string | null,
  TIMEUP?: string | null,
}

export interface RowDataItemGroup2INM {
  INM?: string,
}

//Page State
export type ItemListGroup2TmpState = {
  datas: RowDataItemGroup2[],
  rows: [][],
  mergeCells: [],
  selectionRowStart: number,
  selectionRowEnd: number,

  datasSupplier: RowDataSupplier[],
  //検索終了した条件
  requestedParam : outputDataListSupplierApi.RequestParam,

  progress: Record<string, unknown>,
  errorMessage: string | null,
};

export const initialState: ItemListGroup2TmpState = {
  datas: [],
  rows: [],
  mergeCells: null,
  selectionRowStart: -1,
  selectionRowEnd: -1,

  datasSupplier: [],
  //検索終了した条件
  requestedParam : {},

  progress: {},
  errorMessage: null,
};

//Page Slice
const createSliceContent = (name: string) => createSlice({
  name: name,
  initialState,
  reducers: {
    setDatas(state: ItemListGroup2TmpState, action: PayloadAction<{ datas: RowDataItemGroup2[], sort: { key: string, asc: boolean } }>) {
      const sort = action.payload.sort;

      //計算
      let datas = action.payload.datas;

      datas = parseData(datas);

      datas = calcTotal(datas); //合計行の作成
      datas = calcGroup2(datas); //企業グループ2行の作成

      datas = calcDatas(datas, "all"); //計算項目の計算

      let key = sort.key;
      datas = doSort(datas, key, sort.asc);  //ソート

      Object.assign(state, {
        datas: datas,
        rows: convertRows(datas),
      });
    },
    execSort(state: ItemListGroup2TmpState, action: PayloadAction<{ sort: { key: string, asc: boolean } }>) {
      const sort = action.payload.sort;

      let key = sort.key;
      const asc = sort.asc;

      let datas = [...state.datas];
      datas = doSort(datas, key, asc);

      Object.assign(state, {
        datas: datas,
        //テーブル用のデータに変換
        rows: convertRows(datas),
      });
    },
    searchedSupplier(state: ItemListGroup2TmpState, action: PayloadAction<{ param: outputDataListSupplierApi.RequestParam, datas: RowDataSupplier[] }>) {
      //計算
      let datas = action.payload.datas;
      const param = action.payload.param;

      datas = parseDataSupplier(datas);
      datas = parseDataDenkName(datas);

      datas = doSortSupplier(datas);  //ソート
      datas = resetRowNoSupplier(datas); //列番号の振りなおし

      Object.assign(state, {
        datasSupplier: datas,
        requestedParam: param,
      });
    },
    putProgress(state: ItemListGroup2TmpState, action: PayloadAction<string>) {
      const key = action.payload;
      const progressNew = { ...state.progress };
      progressNew[key] = true;
      state.progress = progressNew;
    },
    removeProgress(state: ItemListGroup2TmpState, action: PayloadAction<string>) {
      const key = action.payload;
      const progressNew = {};
      Object.keys(state.progress).forEach(k => {
        if (key != k) {
          progressNew[k] = true;
        }
      })
      state.progress = progressNew;
    },
    setErrorMessage(state: ItemListGroup2TmpState, action: PayloadAction<string>) {
      state.errorMessage = action.payload;
    },
  },
});

//数値のパース(数値が文字列で返ってくる)
const parseData = (datas: RowDataItemGroup2[]): RowDataItemGroup2[] => {
  //set No.
  datas.forEach((data) => {
    if (typeof data.itblir === 'string') data.itblir = parseInt(data.itblir);

    if (typeof data.HQTY1_PS === 'string') data.HQTY1_PS = parseInt(data.HQTY1_PS);
    if (typeof data.HQTY2_PS === 'string') data.HQTY2_PS = parseInt(data.HQTY2_PS);
    if (typeof data.HQTY3_PS === 'string') data.HQTY3_PS = parseInt(data.HQTY3_PS);
    if (typeof data.HQTY4_PS === 'string') data.HQTY4_PS = parseInt(data.HQTY4_PS);
    if (typeof data.HQTY_PS === 'string') data.HQTY_PS = parseInt(data.HQTY_PS);
    if (typeof data.HKIN1 === 'string') data.HKIN1 = parseInt(data.HKIN1);
    if (typeof data.HKIN2 === 'string') data.HKIN2 = parseInt(data.HKIN2);
    if (typeof data.HKIN3 === 'string') data.HKIN3 = parseInt(data.HKIN3);
    if (typeof data.HKIN4 === 'string') data.HKIN4 = parseInt(data.HKIN4);
    if (typeof data.HKIN === 'string') data.HKIN = parseInt(data.HKIN);
  });
  return datas;
}
//合計行作成
const calcTotal = (datas: RowDataItemGroup2[]): RowDataItemGroup2[] => {
  const total: RowDataItemGroup2 = {
    TP: "total",
    KGG2: "合計",
  };

  //set No.
  datas.forEach((data) => {
    if (data.TP == "group1") {
      calcTotalSub(total, data);
    }
  });

  if (datas.length > 0 && datas[0].TP == "total") {
    datas[0] = total;
  }
  else {
    datas = [total, ...datas];
  }

  return datas;
}

//企業グループ2行の作成
const calcGroup2 = (datas: RowDataItemGroup2[]): RowDataItemGroup2[] => {
  const group2Map = {};
  datas = datas.filter(data => data.TP != "group2"); //企業グループ2行の削除

  //set No.
  datas.forEach((data) => {
    if (data.TP == "group1") {
      const key = data.KGC2;
      let maker: RowDataItemGroup2 = group2Map[key];
      if (!maker) {
        maker = {
          TP: "group2",
          KGC2: data.KGC2,
          KGG2: data.KGG2,
        };
        group2Map[key] = maker;
      }
      calcTotalSub(maker, data);
    }
  });

  //あとのソートのために、group2は上に入れる。
  const newDatas = [];
  Object.keys(group2Map).forEach(key => newDatas.push(group2Map[key]));
  return [...newDatas, ...datas];
}

//total行作成
const calcTotalSub = (total: RowDataItemGroup2, data: RowDataItemGroup2) => {
  //以下に貼り付け
  total.itblir = data.itblir;

  total.HQTY1_PS = calcUtil.plus(total.HQTY1_PS, data.HQTY1_PS);
  total.HQTY2_PS = calcUtil.plus(total.HQTY2_PS, data.HQTY2_PS);
  total.HQTY3_PS = calcUtil.plus(total.HQTY3_PS, data.HQTY3_PS);
  total.HQTY4_PS = calcUtil.plus(total.HQTY4_PS, data.HQTY4_PS);
  total.HQTY_PS = calcUtil.plus(total.HQTY_PS, data.HQTY_PS);
  total.HKIN1 = calcUtil.plus(total.HKIN1, data.HKIN1);
  total.HKIN2 = calcUtil.plus(total.HKIN2, data.HKIN2);
  total.HKIN3 = calcUtil.plus(total.HKIN3, data.HKIN3);
  total.HKIN4 = calcUtil.plus(total.HKIN4, data.HKIN4);
  total.HKIN = calcUtil.plus(total.HKIN, data.HKIN);
}

//計算
const calcDatas = (datas: RowDataItemGroup2[], targetTP: "all" | "total" | "group2"| "group1"): RowDataItemGroup2[] => {
  const total = datas.filter(data => data.TP == "total")[0]; //total行
  datas.forEach((data) => {
    if (targetTP == "all" || data.TP == targetTP) {
      data.HQTY1 = data.HQTY1_PS ? (Math.floor(data.HQTY1_PS / data.itblir * 10) / 10) : null;
      data.HQTY2 = data.HQTY2_PS ? (Math.floor(data.HQTY2_PS / data.itblir * 10) / 10) : null;
      data.HQTY3 = data.HQTY3_PS ? (Math.floor(data.HQTY3_PS / data.itblir * 10) / 10) : null;
      data.HQTY4 = data.HQTY4_PS ? (Math.floor(data.HQTY4_PS / data.itblir * 10) / 10) : null;
      data.HQTY = data.HQTY_PS ? (Math.floor(data.HQTY_PS / data.itblir * 10) / 10) : null;
    }
  });

  return datas;
}

//配列データに変換
const convertRows = (datas: RowDataItemGroup2[]): [][] => {
  const rows = [];
  //set No.
  datas.forEach((data) => {
    const r = [];
    colKeys.forEach((colKey) => {
      r.push(data[colKey]);
    });
    rows.push(r);
  });

  return rows;
}

//ソート
const doSort = (datas: RowDataItemGroup2[], colKey: string, asc: boolean): RowDataItemGroup2[] => {
  //Totalのソート
  const sortContTotal = (colKey, asc, objA):number => {
    //合計行は常に上
    return objA.TP == "total" ? -1 : 1;
  };
  //同じ階層同士のソート
  const sortCont = (TP, colKey, asc, objA, objB):number => {
    let comp = 0;
    const va = objA ? objA[colKey] : null;
    const vb = objB ? objB[colKey] : null;
    //数値型
    if(typeof va === 'number' || typeof vb === 'number') {
      comp = compareUtil.compareNumber2(va, vb, asc);
    }
    else if(typeof va === 'string' || typeof vb === 'string') {
      comp = compareUtil.compareString(va, vb, asc);
    }
    return comp;
  };

  datas.sort((a, b) => {
    //合計行は常に上
    if (a.TP == "total" || b.TP == "total") {
      return sortContTotal(colKey, asc, a);
    }

    return sortCont(a.TP, colKey, asc, a, b);
  });

  return datas;
}

export const lastUpdate = (datas: RowDataItemGroup2[]): string => {
  const map = datas.filter(data => data.DATEUP && data.TIMEUP)
    .map(data => moment(data.DATEUP.padStart(8, '0') + '' + data.TIMEUP.padStart(6, '0'), 'YYYYMMDDHHmmss'));
  if (map.length == 0) {
    return "";
  }

  const max: moment.Moment = map.reduce((a, b) => a.unix() >= b.unix() ? a : b);
  const now = moment(new Date());
  if (now.diff(max, 'day')) {
    return ' (' + max.format('M/D H') + '時時点)';
  }
  return ' (' + max.format('H') + '時時点)';
}

//Page Slice Export
//itemListGroup2Tmp
export const itemListGroup2TmpSlice = createSliceContent("itemListGroup2Tmp");
