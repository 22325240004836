import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import * as TokubaiSettingOrderUserTableModel from "@/components/tokubai/setting/TokubaiSettingOrderUserTableModel";
import * as TokubaiSettingMakerTableModel from "@/components/tokubai/setting/TokubaiSettingMakerTableModel";
import * as TokubaiSettingCenterModel from "@/components/tokubai/setting/TokubaiSettingCenter";
import * as TokubaiSettingGroup1NameTableModel from "@/components/tokubai/setting/TokubaiSettingGroup1NameTableModel";
import * as TokubaiSettingTokuisakiTableModel from "@/components/tokubai/setting/TokubaiSettingTokuisakiTableModel";

import * as tokubaiSettingGet from "@/assets/apitype/tokubaiSettingGet";
import * as tokubaiSettingRegist from "@/assets/apitype/tokubaiSettingRegist";

import * as editorUtil from "@/util/editorUtil";
import * as compareUtil from "@/util/compareUtil";
import { CodeName } from "@/store/common";

export interface ErrorInfoData {
  MKCD?: string | null,
  MKN?: string | null,
  MAD?: string | null,
  NAM?: string | null,
  LDTM?: string | null,

  G1CD?: string | null,
  G1NM?: string | null,
  G1KN?: string | null,
  TKNM?: string | null,
  TKKN?: string | null,
}

export interface CenterData {
  uuid?: string,

  NOTE1?: string | null,
  NOTE2?: string | null,
  kuraireNOTE1?: string | null,
  kuraireNOTE2?: string | null,
  OLD?: number | null,

  //編集
  edited?: boolean,
  oldNOTE1?: string | null,
  oldNOTE2?: string | null,
  oldKuraireNOTE1?: string | null,
  oldKuraireNOTE2?: string | null,
  oldOLD?: number | null,
}
export interface OrderUserData {
  uuid?: string,

  MAD?: string | null,
  NAM?: string | null,

  //エラー情報
  errorInfo?: ErrorInfoData,

  deleted?: boolean,
  //編集
  edited?: boolean,
  oldMAD?: string | null,
  oldNAM?: string | null,
}
export interface MakerData {
  uuid?: string,

  MKCD?: string | null,
  MKN?: string | null,
  MAD?: string | null,
  LDTM?: number | null,

  //エラー情報
  errorInfo?: ErrorInfoData,

  deleted?: boolean,
  //編集
  edited?: boolean,
  oldMKCD?: string | null,
  oldMAD?: string | null,
  oldLDTM?: number | null,
}

export interface Group1NameData {
  uuid?: string,

  G1CD?: string | null,
  G1NM?: string | null,
  G1RK?: string | null,
  G1KN?: string | null,

  //エラー情報
  errorInfo?: ErrorInfoData,

  deleted?: boolean,
  //編集
  edited?: boolean,
  oldG1RK?: string | null,
  oldG1KN?: string | null,
}

export interface TokuisakiData {
  uuid?: string,

  TKCD?: string | null,
  TKNM?: string | null,
  TKKN?: string | null,

  //エラー情報
  errorInfo?: ErrorInfoData,

  deleted?: boolean,
  //編集
  edited?: boolean,
  oldTKNM?: string | null,
  oldTKKN?: string | null,
}

//Page State
export type State = {
  //倉庫
  centerList: CodeName[],
  //メーカー
  makerList: CodeName[],
  //企業グループ1
  group1List: CodeName[],
  //企業グループ2
  group2List: CodeName[],

  editing: boolean,
  //編集中の条件
  editingParam: tokubaiSettingRegist.RequestParam,

  //検索する条件
  requestParam: tokubaiSettingGet.RequestParam,

  progress: Record<string, unknown>,

  noteItem1: CodeName,
  noteItem2: CodeName,
  kuraireNoteItem1: CodeName,
  kuraireNoteItem2: CodeName,
  orderLimitDays: number,

  center: CenterData,
  orderUsers: OrderUserData[],
  orderUserRows: any[],

  makers: MakerData[],
  makerRows: any[],

  group1Names: Group1NameData[],
  group1NameRows: any[],

  tokuisakis: TokuisakiData[],
  tokuisakiRows: any[],

  initList: string[] | null,
  accordionOpen: boolean,

  errorMessage: string | null,
  infoMessage: string | null,
};

export const initialState: State = {
  //倉庫
  centerList: [],
  //メーカー
  makerList: [],
  //企業グループ1
  group1List: [],
  //企業グループ2
  group2List: [],

  editing: false,
  editingParam: {},

  requestParam: {},

  progress: {},

  center: {},
  noteItem1: TokubaiSettingCenterModel.noteItemList[0],
  noteItem2: TokubaiSettingCenterModel.noteItemList[0],
  kuraireNoteItem1: TokubaiSettingCenterModel.noteItemList[0],
  kuraireNoteItem2: TokubaiSettingCenterModel.noteItemList[0],
  orderLimitDays: 0,
  orderUsers: [],
  orderUserRows: [],

  makers: [],
  makerRows: [],

  group1Names: [],
  group1NameRows: [],

  tokuisakis: [],
  tokuisakiRows: [],

  initList: ["center", "group2"],
  accordionOpen: false,

  errorMessage: null,
  infoMessage: null,
};

//Page Slice
export const tokubaiSettingTmpSlice = createSlice({
  name: "tokubaiSettingTmp",
  initialState,
  reducers: {
    //componentDidMount
    initOnDidMount() {
      console.log('store.initOnDidMount');
    },
    //componentWillUnmount
    resetOnWillUnmount() {
      console.log('store.resetOnWillUnmount');
    },
    putProgress(state:State, action: PayloadAction<string>) {
      console.log('store.putProgress');
      const key = action.payload;
      const progressNew = { ...state.progress };
      progressNew[key] = true;
      state.progress = progressNew;
    },
    removeProgress(state:State, action: PayloadAction<string>) {
      console.log('store.removeProgress');
      const key = action.payload;
      const progressNew = {};
      Object.keys(state.progress).forEach(k => {
        if (key != k) {
          progressNew[k] = true;
        }
      })
      state.progress = progressNew;
    },

    // Option Center
    setCenterList(state:State, action: PayloadAction<CodeName[]>) {
      state.centerList = action.payload;
    },
    // Option Maker
    setMakerList(state:State, action: PayloadAction<CodeName[]>) {
      state.makerList = action.payload;
    },
    // Option Group1
    setGroup1List(state:State, action: PayloadAction<CodeName[]>) {
      state.group1List = action.payload;
    },
    // Option Group2
    setGroup2List(state:State, action: PayloadAction<CodeName[]>) {
      state.group2List = action.payload;
    },
    setEditingStart(state:State, action: PayloadAction<{ editingParam: tokubaiSettingRegist.RequestParam }>) {
      console.log('store.setEditingStart');
      Object.assign(state, {
        editing: true,
        editingParam: action.payload.editingParam,
      });
    },
    setCenterDatas(state:State, action: PayloadAction<{
      center: CenterData,
      orderUsers: OrderUserData[], orderUsersSort: { key: string, asc: boolean }
      makers: MakerData[], makerSort: { key: string, asc: boolean }
    }>) {
      console.log('store.setCenterDatas');
      let center = action.payload.center;
      let orderUsers = action.payload.orderUsers;
      let makers = action.payload.makers;
      const orderUsersSort = action.payload.orderUsersSort;
      const makerSort = action.payload.makerSort;

      {//編集前
        if (center) {
          center.oldNOTE1 = center.NOTE1;
          center.oldNOTE2 = center.NOTE2;
          center.oldKuraireNOTE1 = center.kuraireNOTE1;
          center.oldKuraireNOTE2 = center.kuraireNOTE2;
          if(typeof center.OLD === 'string') center.OLD = parseInt(center.OLD);
          center.oldOLD = center.OLD;
        } else {
          center = {
            NOTE1: TokubaiSettingCenterModel.noteItemList.find(cn => cn.name == 'なし').code,
            NOTE2: TokubaiSettingCenterModel.noteItemList.find(cn => cn.name == 'なし').code,
            kuraireNOTE1: TokubaiSettingCenterModel.noteItemList.find(cn => cn.name == 'なし').code,
            kuraireNOTE2: TokubaiSettingCenterModel.noteItemList.find(cn => cn.name == 'なし').code,
            OLD: 0,
            edited: true,
          }
        }
        orderUsers.forEach(orderUser => {
          orderUser.oldMAD = orderUser.MAD;
          orderUser.oldNAM = orderUser.NAM;
        });
        makers.forEach(maker => {
          const mk = state.makerList.find(data => data.code == maker.MKCD);
          maker.oldMKCD = maker.MKCD;
          maker.MKN = mk ? mk.name : null;
          maker.oldMAD = maker.MAD;
          maker.oldLDTM = maker.LDTM;
        });
      }

      for (let i = orderUsers.length; i < 10; i++) {
        const r: OrderUserData = {};
        orderUsers.push(r);
      }

      for (let i = makers.length; i < 10; i++) {
        const r: MakerData = { MKCD: null, MAD: null };
        makers.push(r);
      }

      //データチェック
      orderUsers = checkOrderUserDatas(orderUsers);
      makers = checkMakerDatas(makers, orderUsers);

      //ソート
      orderUsers = doOrderUserSort(orderUsers, orderUsersSort.key, orderUsersSort.asc);
      makers = doMakerSort(makers, makerSort.key, makerSort.asc);

      Object.assign(state, {
        center: center,
        noteItem1: TokubaiSettingCenterModel.noteItemList.find(cn => cn.code == center.NOTE1),
        noteItem2: TokubaiSettingCenterModel.noteItemList.find(cn => cn.code == center.NOTE2),
        kuraireNoteItem1: TokubaiSettingCenterModel.noteItemList.find(cn => cn.code == center.kuraireNOTE1),
        kuraireNoteItem2: TokubaiSettingCenterModel.noteItemList.find(cn => cn.code == center.kuraireNOTE2),
        orderLimitDays: center.OLD,

        // data: data,
        orderUsers: orderUsers,
        orderUserRows: convertOrderUserRows(orderUsers),

        makers: makers,
        makerRows: convertMakerRows(makers),
      });
    },
    execOrderUserSort(state:State, action: PayloadAction<{ sort: { key: string, asc: boolean } }>) {
      console.log('execOrderUsersSort');
      const sort = action.payload.sort;

      let key = sort.key;
      const asc = sort.asc;

      let orderUsers = [...state.orderUsers];

      orderUsers = doOrderUserSort(orderUsers, key, asc);

      Object.assign(state, {
        orderUsers: orderUsers,
        orderUserRows: convertOrderUserRows(orderUsers),
      });
    },
    execMakerSort(state:State, action: PayloadAction<{ sort: { key: string, asc: boolean } }>) {
      console.log('execMakerSort');
      const sort = action.payload.sort;

      let key = sort.key;
      const asc = sort.asc;

      let makers = [...state.makers];

      makers = doMakerSort(makers, key, asc);

      Object.assign(state, {
        makers: makers,
        makerRows: convertMakerRows(makers),
      });
    },
    setNoteItem1(state:State, action: PayloadAction<CodeName>) {
      const center = state.center;
      center.NOTE1 = action.payload.code;
      state.noteItem1 = TokubaiSettingCenterModel.noteItemList.find(cn => cn.code == center.NOTE1);
      center.edited = isEditedCenterData(center);
      state.center = center;
    },
    setNoteItem2(state:State, action: PayloadAction<CodeName>) {
      const center = state.center;
      center.NOTE2 = action.payload.code;
      state.noteItem2 = TokubaiSettingCenterModel.noteItemList.find(cn => cn.code == center.NOTE2);
      center.edited = isEditedCenterData(center);
      state.center = center;
    },
    setKuraireNoteItem1(state:State, action: PayloadAction<CodeName>) {
      const center = state.center;
      center.kuraireNOTE1 = action.payload.code;
      state.kuraireNoteItem1 = TokubaiSettingCenterModel.noteItemList.find(cn => cn.code == center.kuraireNOTE1);
      center.edited = isEditedCenterData(center);
      state.center = center;
    },
    setKuraireNoteItem2(state:State, action: PayloadAction<CodeName>) {
      const center = state.center;
      center.kuraireNOTE2 = action.payload.code;
      state.kuraireNoteItem2 = TokubaiSettingCenterModel.noteItemList.find(cn => cn.code == center.kuraireNOTE2);
      center.edited = isEditedCenterData(center);
      state.center = center;
    },
    setOrderLimitDays(state:State, action: PayloadAction<number>) {
      const center = state.center;
      center.OLD = action.payload;
      state.orderLimitDays = center.OLD;
      center.edited = isEditedCenterData(center);
      state.center = center;
    },
    addOrderUserRow(state:State, action: PayloadAction<number>) {
      console.log('store.addRow');
      const rowCount = action.payload;

      let newDatas: OrderUserData[] = [];
      for (let i = 0; i < rowCount; i++) {
        const data: OrderUserData = {};
        newDatas.push(data);
      }

      const orderUsers = [...state.orderUsers, ...newDatas];

      Object.assign(state, {
        orderUsers: orderUsers,
        orderUserRows: convertOrderUserRows(orderUsers),
      });
    },
    removeEmptyOrderUserRow(state) {
      console.log('store.removeEmptyRow');
      let datas: OrderUserData[] = [...state.orderUsers];
      datas = datas.filter(data => !(!data.MAD && !data.NAM && !data.deleted));

      //0行になる場合は1行足す
      if (datas.length == 0) {
        const rowCount = 1;
        let newDatas: OrderUserData[] = [];
        for (let i = 0; i < rowCount; i++) {
          const data: OrderUserData = {};
          newDatas.push(data);
        }
        datas = [...datas, ...newDatas];
      }

      Object.assign(state, {
        orderUsers: datas,
        orderUserRows: convertOrderUserRows(datas),
      });
    },
    editOrderUserRowDatas(state:State, action: PayloadAction<{ row: number, col: number, value: string | number | object | null }[]>) {
      console.log('store.editOrderUserRowDatas');
      const newDatas = [...state.orderUsers];
      const model = TokubaiSettingOrderUserTableModel;

      const editDataContent = (row: number, col: number, key: string, value: string | number | boolean | object | null) => {
        if (!(row >= 0) || (!key && !(col >= 0))) {
          return;
        }
        if (!key) {
          key = model.keyFromCol(col);
        }
        if (!(col >= 0)) {
          col = model.colFromKey(key);
        }

        //parse
        const dataType = model.colDataType[key];

        value = editorUtil.parseValue(value, dataType.type,
          dataType.type == 'numeric' ? dataType.numericFormat.pattern :
            dataType.type == 'date' ? dataType.dateFormat :
              null);

        let data = newDatas[row];
        data[key] = value;
        data = checkOrderUserData(data, newDatas);
      };

      action.payload.forEach((editData) => {
        const key = model.keyFromCol(editData.col);

        editDataContent(editData.row, editData.col, key, editData.value);

        if (key == 'MAD') {
          let newData: OrderUserData = newDatas[editData.row];
          //削除時
          if (!editData.value) {
            newData.NAM = null;
            newData.edited = null;
          }
        }
      });
      checkMakerDatas(state.makers, newDatas);

      Object.assign(state, {
        orderUsers: newDatas,
        orderUserRows: convertOrderUserRows(newDatas),
      });
    },
    addMakerRow(state:State, action: PayloadAction<number>) {
      console.log('store.addRow');
      const rowCount = action.payload;

      let newDatas: MakerData[] = [];
      for (let i = 0; i < rowCount; i++) {
        const data: MakerData = {
          MKCD: '',
          MKN: '',
          MAD: '',
          LDTM: null,
        };
        newDatas.push(data);
      }

      const makers = [...state.makers, ...newDatas];

      Object.assign(state, {
        makers: makers,
        makerRows: convertMakerRows(makers),
      });
    },
    removeEmptyMakerRow(state) {
      console.log('store.removeEmptyRow');
      let datas: MakerData[] = [...state.makers];
      datas = datas.filter(data => !(!data.MKCD && !data.deleted));

      //0行になる場合は1行足す
      if (datas.length == 0) {
        const rowCount = 1;
        let newDatas: MakerData[] = [];
        for (let i = 0; i < rowCount; i++) {
          const data: MakerData = {};
          newDatas.push(data);
        }
        datas = [...datas, ...newDatas];
      }

      Object.assign(state, {
        makers: datas,
        makerRows: convertMakerRows(datas),
      });
    },
    editMakerRowDatas(state:State, action: PayloadAction<{ row: number, col: number, value: string | number | object | null }[]>) {
      console.log('store.editMakerRowDatas');
      const newDatas = [...state.makers];
      const model = TokubaiSettingMakerTableModel;

      const editDataContent = (row: number, col: number, key: string, value: string | number | boolean | object | null) => {
        if (!(row >= 0) || (!key && !(col >= 0))) {
          return;
        }
        if (!key) {
          key = model.keyFromCol(col);
        }
        if (!(col >= 0)) {
          col = model.colFromKey(key);
        }
        //parse
        const dataType = model.colDataType[key];

        value = editorUtil.parseValue(value, dataType.type,
          dataType.type == 'numeric' ? dataType.numericFormat.pattern :
            dataType.type == 'date' ? dataType.dateFormat :
              null);

        let data = newDatas[row];
        data[key] = value;
        if (key == 'MKCD') {
          const mk = state.makerList.find(data => data.code == value);
          data.MKN = mk ? mk.name : null;
        }
        data = checkMakerData(data, newDatas, state.orderUsers);
      };

      action.payload.forEach((editData) => {
        const key = model.keyFromCol(editData.col);

        editDataContent(editData.row, editData.col, key, editData.value);

        if (key == 'MKCD') {
          let newData: MakerData = newDatas[editData.row];
          //削除時
          if (!editData.value) {
            newData.MKN = null;
            newData.MAD = null;
            newData.LDTM = null;
            newData.edited = null;
          }
        }
      });

      Object.assign(state, {
        makers: newDatas,
        makerRows: convertMakerRows(newDatas),
      });
    },
    setGroup1Datas(state: State, action: PayloadAction<{
      group1Names: Group1NameData[], group1: CodeName,
      tokuisakis: TokuisakiData[], tokuisakisSort: { key: string, asc: boolean }
    }>) {
      console.log('store.setGroup1Datas');
      let group1Names = action.payload.group1Names;
      const group1 = action.payload.group1;
      let tokuisakis = action.payload.tokuisakis;
      const tokuisakisSort = action.payload.tokuisakisSort;

      {//編集前
        group1Names.forEach(group1Name => {
          const g1 = state.group1List.find(data => data.code == state.editingParam.group2Cd + '_' + group1Name.G1CD);
          group1Name.G1NM = g1 ? g1.option2 : null;
          group1Name.oldG1RK = group1Name.G1RK;
          group1Name.oldG1KN = group1Name.G1KN;
        });
        tokuisakis.forEach(tokuisaki => {
          tokuisaki.oldTKNM = tokuisaki.TKNM;
          tokuisaki.oldTKKN = tokuisaki.TKKN;
        });
      }
      if (group1Names.length === 0) {//未登録の場合は追加
        const g1 = state.group1List.find(data => data.code == group1.code);
        group1Names.push({
          G1CD: group1.code.split('_')[1],
          G1NM: g1 ? g1.option2 : null,
        });
      }
      for (let i = tokuisakis.length; i < 10; i++) {
        const r: TokuisakiData = {};
        tokuisakis.push(r);
      }

      //データチェック
      group1Names = checkGroup1NameDatas(group1Names);
      tokuisakis = checkTokuisakiDatas(tokuisakis);

      //ソート
      tokuisakis = doTokuisakiSort(tokuisakis, tokuisakisSort.key, tokuisakisSort.asc);

      Object.assign(state, {
        group1Names: group1Names,
        group1NameRows: convertGroup1NameRows(group1Names),

        tokuisakis: tokuisakis,
        tokuisakiRows: convertTokuisakiRows(tokuisakis),
      });
    },
    editGroup1NameRowDatas(state: State, action: PayloadAction<{ row: number, col: number, value: string | number | object | null }[]>) {
      console.log('store.editGroup1NameRowDatas');
      const newDatas = [...state.group1Names];
      const model = TokubaiSettingGroup1NameTableModel;

      const editDataContent = (row: number, col: number, key: string, value: string | number | boolean | object | null) => {
        if (!(row >= 0) || (!key && !(col >= 0))) {
          return;
        }
        if (!key) {
          key = model.keyFromCol(col);
        }
        if (!(col >= 0)) {
          col = model.colFromKey(key);
        }

        //parse
        const dataType = model.colDataType[key];

        value = editorUtil.parseValue(value, dataType.type,
          dataType.type == 'numeric' ? dataType.numericFormat.pattern :
            dataType.type == 'date' ? dataType.dateFormat :
              null);

        let data = newDatas[row];
        data[key] = value;
        data = checkGroup1NameData(data, newDatas);
      };

      action.payload.forEach((editData) => {
        const key = model.keyFromCol(editData.col);

        editDataContent(editData.row, editData.col, key, editData.value);
      });

      Object.assign(state, {
        group1Names: newDatas,
        group1NameRows: convertGroup1NameRows(newDatas),
      });
    },
    execTokuisakiSort(state: State, action: PayloadAction<{ sort: { key: string, asc: boolean } }>) {
      console.log('execTokuisakiSort');
      const sort = action.payload.sort;

      let key = sort.key;
      const asc = sort.asc;

      let tokuisakis = [...state.tokuisakis];

      tokuisakis = doTokuisakiSort(tokuisakis, key, asc);

      Object.assign(state, {
        tokuisakis: tokuisakis,
        tokuisakiRows: convertTokuisakiRows(tokuisakis),
      });
    },
    addTokuisakiRow(state: State, action: PayloadAction<number>) {
      console.log('store.addRow');
      const rowCount = action.payload;

      let newDatas: TokuisakiData[] = [];
      for (let i = 0; i < rowCount; i++) {
        const data: TokuisakiData = {};
        newDatas.push(data);
      }

      const tokuisakis = [...state.tokuisakis, ...newDatas];

      Object.assign(state, {
        tokuisakis: tokuisakis,
        tokuisakiRows: convertTokuisakiRows(tokuisakis),
      });
    },
    removeEmptyTokuisakiRow(state) {
      console.log('store.removeEmptyRow');
      let datas: TokuisakiData[] = [...state.tokuisakis];
      datas = datas.filter(data => !(!data.TKNM && !data.TKKN && !data.deleted));

      //0行になる場合は1行足す
      if (datas.length == 0) {
        const rowCount = 1;
        let newDatas: TokuisakiData[] = [];
        for (let i = 0; i < rowCount; i++) {
          const data: TokuisakiData = {};
          newDatas.push(data);
        }
        datas = [...datas, ...newDatas];
      }

      Object.assign(state, {
        tokuisakis: datas,
        tokuisakiRows: convertTokuisakiRows(datas),
      });
    },
    editTokuisakiRowDatas(state: State, action: PayloadAction<{ row: number, col: number, value: string | number | object | null }[]>) {
      console.log('store.editTokuisakiRowDatas');
      const newDatas = [...state.tokuisakis];
      const model = TokubaiSettingTokuisakiTableModel;

      const editDataContent = (row: number, col: number, key: string, value: string | number | boolean | object | null) => {
        if (!(row >= 0) || (!key && !(col >= 0))) {
          return;
        }
        if (!key) {
          key = model.keyFromCol(col);
        }
        if (!(col >= 0)) {
          col = model.colFromKey(key);
        }

        //parse
        const dataType = model.colDataType[key];

        value = editorUtil.parseValue(value, dataType.type,
          dataType.type == 'numeric' ? dataType.numericFormat.pattern :
            dataType.type == 'date' ? dataType.dateFormat :
              null);

        let data = newDatas[row];
        data[key] = value;
        data = checkTokuisakiData(data, newDatas);
      };

      action.payload.forEach((editData) => {
        const key = model.keyFromCol(editData.col);

        editDataContent(editData.row, editData.col, key, editData.value);

        if (key == 'TKNM') {
          let newData: TokuisakiData = newDatas[editData.row];
          //削除時
          if (!editData.value) {
            newData.TKNM = null;
            newData.TKKN = null;
            newData.edited = null;
          }
          newData = checkTokuisakiData(newData, newDatas);
        }
      });

      Object.assign(state, {
        tokuisakis: newDatas,
        tokuisakiRows: convertTokuisakiRows(newDatas),
      });
    },
    setEditingEnd(state) {
      console.log('store.setEditingEnd');

      const orderUsers: OrderUserData[] = [];
      const makers: MakerData[] = [];
      const group1Names: Group1NameData[] = [];
      const tokuisakis: TokuisakiData[] = [];

      Object.assign(state, {
        editing: false,
        editingParam: initialState.editingParam,

        orderUsers: orderUsers,
        orderUserRows: convertOrderUserRows(orderUsers),

        makers: makers,
        makerRows: convertMakerRows(makers),

        group1Names: group1Names,
        group1NameRows: convertGroup1NameRows(group1Names),

        tokuisakis: tokuisakis,
        tokuisakiRows: convertTokuisakiRows(tokuisakis),
      });
    },
    //検索条件
    setRequestParam(state:State, action: PayloadAction<tokubaiSettingGet.RequestParam>) {
      console.log('store.setRequestParam');
      state.requestParam = action.payload;
    },
    setAccordionOpen(state:State, action: PayloadAction<boolean>) {
      state.accordionOpen = action.payload;
    },
    setInitList(state:State, action: PayloadAction<string>) {
      if (state.initList.some(data => data == action.payload)) {
        state.initList = state.initList.filter(data => data != action.payload);
      }
    },
    setErrorMessage(state:State, action: PayloadAction<string>) {
      console.log('store.setErrorMessage');
      state.errorMessage = action.payload;
    },
    setInfoMessage(state:State, action: PayloadAction<string>) {
      console.log('store.setInfoMessage');
      state.infoMessage = action.payload;
    },
  },
});

//配列データに変換
const convertOrderUserRows = (datas: OrderUserData[]): [][] => {
  const rows = [];
  //set No.
  datas.forEach((data, index) => {
    const r = [];
    const no: string = "" + (index + 1);

    TokubaiSettingOrderUserTableModel.colKeys.forEach((colKey) => {
      switch (colKey) {
        case 'no':
          r.push(no); //No.
          break;
        case 'MAD':
          r.push(data.deleted ? data['old' + colKey] : data[colKey]);
          break;
        default:
          r.push(data[colKey]);
          break;
      }
    });
    rows.push(r);
  });
  return rows;
}
const convertMakerRows = (datas: MakerData[]): [][] => {
  const rows = [];
  //set No.
  datas.forEach((data, index) => {
    const r = [];
    const no: string = "" + (index + 1);

    TokubaiSettingMakerTableModel.colKeys.forEach((colKey) => {
      switch (colKey) {
        case 'no':
          r.push(no); //No.
          break;
        case 'MKCD':
          r.push(data.deleted ? data['old' + colKey] : data[colKey]);
          break;
        default:
          r.push(data[colKey]);
          break;
      }
    });
    rows.push(r);
  });
  return rows;
}
const convertGroup1NameRows = (datas: Group1NameData[]): [][] => {
  const rows = [];
  //set No.
  datas.forEach((data, index) => {
    const r = [];
    const no: string = "" + (index + 1);

    TokubaiSettingGroup1NameTableModel.colKeys.forEach((colKey) => {
      switch (colKey) {
        case 'no':
          r.push(no); //No.
          break;
        case 'G1CD':
          r.push(data.deleted ? data['old' + colKey] : data[colKey]);
          break;
        default:
          r.push(data[colKey]);
          break;
      }
    });
    rows.push(r);
  });
  return rows;
}
const convertTokuisakiRows = (datas: TokuisakiData[]): [][] => {
  const rows = [];
  //set No.
  datas.forEach((data, index) => {
    const r = [];
    const no: string = "" + (index + 1);

    TokubaiSettingTokuisakiTableModel.colKeys.forEach((colKey) => {
      switch (colKey) {
        case 'no':
          r.push(no); //No.
          break;
        case 'TKNM':
        case 'TKKN':
          r.push(data.deleted ? data['old' + colKey] : data[colKey]);
          break;
        default:
          r.push(data[colKey]);
          break;
      }
    });
    rows.push(r);
  });
  return rows;
}

export const isEditedCenterData = (data: CenterData): boolean => {
  if(!data){
    return false;
  }

  return (data.oldNOTE1 != data.NOTE1 ||
          data.oldNOTE2 != data.NOTE2 ||
          data.oldKuraireNOTE1 != data.kuraireNOTE1 ||
          data.oldKuraireNOTE2 != data.kuraireNOTE2 ||
          data.oldOLD != data.OLD
  );
}

//チェック
const checkOrderUserDatas = (datas: OrderUserData[]): OrderUserData[] => {
  console.log('store.checkOrderUserDatas');
  return datas.map(data => checkOrderUserData(data, datas));
}

const checkOrderUserData = (data: OrderUserData, datas: OrderUserData[]): OrderUserData => {
  const errorInfo: ErrorInfoData = {};
  if (!data.errorInfo) {
    data.errorInfo = {};
  }

  if (data.MAD) {
    if (datas.filter(d => d.deleted ? d.oldMAD == data.MAD : d.MAD == data.MAD).length > 1) {
      errorInfo.MAD = '重複しています';
    }

    if (!data.NAM) {
      errorInfo.NAM = '入力してください';
    }
  }

  data.errorInfo = errorInfo;

  //編集状態をセットする
  data.edited = (data.oldMAD != data.MAD || data.oldNAM != data.NAM);
  data.deleted = (data.oldMAD && !data.MAD);

  return data;
}

const checkMakerDatas = (datas: MakerData[], orderUsers: OrderUserData[]): MakerData[] => {
  console.log('store.checkDatas');
  return datas.map(data => checkMakerData(data, datas, orderUsers));
}

const checkMakerData = (data: MakerData, datas: MakerData[], orderUsers: OrderUserData[]): MakerData => {
  const errorInfo: ErrorInfoData = {};
  if (!data.errorInfo) {
    data.errorInfo = {};
  }

  if (data.MKCD) {
    if (datas.filter(d => d.deleted ? d.oldMKCD == data.MKCD : d.MKCD == data.MKCD).length > 1) {
        errorInfo.MKCD = '重複しています';
    }
    if (!data.MKN) {
      errorInfo.MKN = '不明なメーカーコードです';
    }

    if (!data.MAD) {
      errorInfo.MAD = '入力してください';
    }
    else {
      if (orderUsers.filter(d => d.MAD).map(d => d.MAD).indexOf(data.MAD) === -1) {
        errorInfo.MAD = '発注担当者一覧にありません';
      }
    }

    if (typeof data.LDTM != 'number' && !data.LDTM) {
      errorInfo.LDTM = '入力してください';
    }
    else if (data.LDTM && (typeof data.LDTM != 'number' || data.LDTM < 0)) {
      errorInfo.LDTM = '数値が不正';
    }
  }

  data.errorInfo = errorInfo;

  //編集状態をセットする
  data.edited = (data.oldMKCD != data.MKCD || data.oldMAD != data.MAD || data.oldLDTM != data.LDTM);
  data.deleted = (data.oldMKCD && !data.MKCD);

  return data;
}

const checkGroup1NameDatas = (datas: Group1NameData[]): Group1NameData[] => {
  console.log('store.checkGroup1NameDatas');
  return datas.map(data => checkGroup1NameData(data, datas));
}
const checkGroup1NameData = (data: Group1NameData, datas: Group1NameData[]): Group1NameData => {
  const errorInfo: ErrorInfoData = {};
  if (!data.errorInfo) {
    data.errorInfo = {};
  }

  if (data.G1CD) {
    if (!data.G1NM) {
      errorInfo.G1NM = '不明な企業グループ1CDです';
    }
    if (data.G1KN) {
      if (data.G1KN.length > 20) {
        errorInfo.G1KN = '20字まで入力可能です';
      } else if (!/^[a-zA-Z0-9!-/:-@¥[-`{-~｡-ﾟ ]*$/.test(data.G1KN)) {
        errorInfo.G1KN = '半角のみ入力可能です';
      }
    }
  }

  data.errorInfo = errorInfo;

  //編集状態をセットする
  data.edited = (data.oldG1RK != data.G1RK || data.oldG1KN != data.G1KN);
  data.deleted = ((data.oldG1RK && !data.G1RK) && (data.oldG1KN && !data.G1KN));

  return data;
}
const checkTokuisakiDatas = (datas: TokuisakiData[]): TokuisakiData[] => {
  console.log('store.checkTokuisakiDatas');
  return datas.map(data => checkTokuisakiData(data, datas));
}
const checkTokuisakiData = (data: TokuisakiData, datas: TokuisakiData[]): TokuisakiData => {
  const errorInfo: ErrorInfoData = {};
  if (!data.errorInfo) {
    data.errorInfo = {};
  }

  if (data.TKNM || data.TKKN) {
    if (datas.filter(d => d.deleted ? d.oldTKNM == data.TKNM : d.TKNM == data.TKNM).length > 1) {
      errorInfo.TKNM = '重複しています';
    }
    if (!data.TKNM) {
      errorInfo.TKNM = '入力してください';
    }
    if (!data.TKKN) {
      errorInfo.TKKN = '入力してください';
    } else {
      if (data.TKKN.length > 20) {
        errorInfo.TKKN = '20字まで入力可能です';
      } else if (!/^[a-zA-Z0-9!-/:-@¥[-`{-~｡-ﾟ ]*$/.test(data.TKKN)) {
        errorInfo.TKKN = '半角のみ入力可能です';
      }
    }
  }

  data.errorInfo = errorInfo;

  //編集状態をセットする
  data.edited = (data.oldTKNM != data.TKNM || data.oldTKKN != data.TKKN);
  data.deleted = (data.oldTKNM && !data.TKNM);

  return data;
}

//ソート
export const compareNumber = (v1:number, v2:number, asc:boolean) => {
  const b1 = v1 === undefined || v1 === null;
  const b2 = v2 === undefined || v2 === null;
  if(b1 && b2) return 0;
  //nullは常に最下層
  if(b1) return 1;
  if(b2) return -1;
  return (v1 === v2 ? 0 : v1 < v2 ? -1 : 1) * (asc ? 1 : -1);
}
export const compareString = (v1:string, v2:string, asc:boolean) => {
  const b1 = v1 === undefined || v1 === null || v1 === '';
  const b2 = v2 === undefined || v2 === null || v2 === '';
  if(b1 && b2) return 0;
  //nullは常に最下層
  if(b1) return 1;
  if(b2) return -1;
  return v1.localeCompare(v2) * (asc ? 1 : -1);
}
export const compareBoolean = (v1:boolean, v2:boolean, asc:boolean) => {
  const b1 = v1;
  const b2 = v2;
  if(b1 && b2) return 0;
  //nullは常に最下層
  if(b1) return 1;
  if(b2) return -1;
  return 0;
}
const doOrderUserSort = (datas: OrderUserData[], colKey: string, asc: boolean): OrderUserData[] => {
  console.log('doOrderUserSort');

  datas.sort((objA, objB) => {
    let comp = 0;
    let va = objA ? (colKey == 'MAD' && objA.deleted && objA[`old${colKey}`] ? objA[`old${colKey}`] : objA[colKey]) : null;
    let vb = objB ? (colKey == 'MAD' && objB.deleted && objB[`old${colKey}`] ? objB[`old${colKey}`] : objB[colKey]) : null;
  
    switch (colKey) {
      case "MAD" :
        comp = compareString(va, vb, asc);
        break;
      case "NAM" :
        comp = compareString(va, vb, asc);
        break;
      default:
        break;
    }
    //それでもソートできない場合、メールアドレス順とする
    if (comp === 0) {
      va = objA ? (objA.deleted && objA.oldMAD ? objA.oldMAD : objA.MAD) : null;
      vb = objB ? (objB.deleted && objB.oldMAD ? objB.oldMAD : objB.MAD) : null;
      comp = compareString(va, vb, asc);
    }
    //それでもソートできない場合、未削除を優先
    if (comp === 0) {
      comp = compareBoolean(objA ? objA.deleted : null, objB ? objB.deleted : null, !asc);
    }
    return comp;
  });

  return datas;
}
const doMakerSort = (datas: MakerData[], colKey: string, asc: boolean): MakerData[] => {
  console.log('doMakerSort');

  datas.sort((objA, objB) => {
    let comp = 0;
    let va = objA ? (colKey == 'MKCD' && objA.deleted && objA[`old${colKey}`] ? objA[`old${colKey}`] : objA[colKey]) : null;
    let vb = objB ? (colKey == 'MKCD' && objB.deleted && objB[`old${colKey}`] ? objB[`old${colKey}`] : objB[colKey]) : null;
    switch (colKey) {
      case "MKCD" :
        comp = compareString(va, vb, asc);
        break;
      case "MKN" :
        comp = compareString(va, vb, asc);
        break;
      case "MAD" :
        comp = compareString(va, vb, asc);
        break;
      case "LDTM" :
        comp = compareNumber(va, vb, asc);
        break;
      default:
        break;
    }
    //それでもソートできない場合、コード順とする
    if (comp === 0) {
      va = objA ? (objA.deleted && objA.oldMKCD ? objA.oldMKCD : objA.MKCD) : null;
      vb = objB ? (objB.deleted && objB.oldMKCD ? objB.oldMKCD : objB.MKCD) : null;
      comp = compareString(va, vb, asc);
    }
    //それでもソートできない場合、未削除を優先
    if (comp === 0) {
      comp = compareBoolean(objA ? objA.deleted : null, objB ? objB.deleted : null, !asc);
    }
    return comp;
  });

  return datas;
}
const doTokuisakiSort = (datas: TokuisakiData[], colKey: string, asc: boolean): TokuisakiData[] => {
  console.log('doTokuisakiSort');

  datas.sort((objA, objB) => {
    let comp = 0;
    let va = objA ? (colKey == 'TKNM' && objA.deleted && objA[`old${colKey}`] ? objA[`old${colKey}`] : objA[colKey]) : null;
    let vb = objB ? (colKey == 'TKNM' && objB.deleted && objB[`old${colKey}`] ? objB[`old${colKey}`] : objB[colKey]) : null;

    switch (colKey) {
      case "TKCD":
        comp = compareNumber(va, vb, asc);
        break;
      case "TKNM":
      case "TKKN":
        comp = compareString(va, vb, asc);
        break;
      default:
        break;
    }
    //それでもソートできない場合、TKNM順とする
    if (comp === 0) {
      va = objA ? (objA.deleted && objA.oldTKNM ? objA.oldTKNM : objA.TKNM) : null;
      vb = objB ? (objB.deleted && objB.oldTKNM ? objB.oldTKNM : objB.TKNM) : null;
      comp = compareString(va, vb, asc);
    }
    //それでもソートできない場合、未削除を優先
    if (comp === 0) {
      comp = compareBoolean(objA ? objA.deleted : null, objB ? objB.deleted : null, !asc);
    }
    return comp;
  });

  return datas;
}
