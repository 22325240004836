import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import moment from "moment";

import { RequestParam } from "@/assets/apitype/outputDataListShodan";
import { CodeName } from "@/store/common";

//在庫マスタ
export interface RowDataShodan {
  no?: string,
  hatsubaiDate?: Date | string | null,
  torihikisakiCd?: string | null,
  janCd?: string | null,
  shohinCd?: string | null,
  shiiresakiCd?: string | null,
  shiiresakiName?: string | null,
  shohinNameKanji?: string | null,
  tokuisakiCd?: string | null,
  instoreCd?: string | null,
  kikaku?: string | null,
  bowlPieceNumber?: number | null,
  casePieceNumber?: number | null,
  hatchuUnit?: number | null,
  saiteiLot?: number | null,
  shomiKikan?: number | null,
  teibanNouka?: number | null,
  teibanBaika?: number | null,
  tokubaiNouka?: number | null,
  tokubaiBaika?: number | null,
  monthlyNouka?: number | null,
  monthlyBaika?: number | null,
  flyerNouka?: number | null,
  flyerBaika?: number | null,
  tatene?: number | null,
  rebate?: number | null,
  tateneHenkobi?: Date | string | null,
  sinTatene?: number | null,
  keisanTatene?: number | null,

  teibanArariRitu?: string | null,
  teibanPlanQty?: string | null,
  teibanNoukaRitu?: string | null,
  teibanTateneRitu?: string | null,
  teibanGaku?: string | null,
  teibanArariGaku?: string | null,
  teibanLastGaku?: string | null,
  teibanLastRitu?: string | null,

  tokubaiArariRitu?: string | null,
  tokubaiPlanQty?: string | null,
  tokubaiNoukaRitu?: string | null,
  tokubaiTateneRitu?: string | null,
  tokubaiGaku?: string | null,
  tokubaiArariGaku?: string | null,
  tokubaiLastGaku?: string | null,
  tokubaiLastRitu?: string | null,

  shukkaStartDate?: string | null,
  orderUser?: string | null,
  okurikomiStoreQty?: string | null,
  predict3day?: string | null,
  okurikomiQty?: string | null,
  shukkaDate?: string | null,
  nohinDate?: string | null,
  orderQty?: string | null,
  orderCheck?: string | null,
  orderStatus?: string | null,
  tanaban?: string | null,
  memo?: string | null,

}


//Page State
export type OutputDataShodanTmpState = {
  group2List: CodeName[],
  group1List: CodeName[],
  //部門
  buList: CodeName[],
  //倉庫
  centerList: CodeName[],

  //検索する条件
  requestParamShodan: RequestParam,

  progress: Record<string, unknown>,
  datasShodan: RowDataShodan[],

  vendorIsIME: boolean,
  honbuIsIME: boolean,
  itemIsIME: boolean,
  errorMessage: string | null,
  infoMessage: string | null,
};

export const initialState: OutputDataShodanTmpState = {
  group2List: [],
  group1List: [],
  //部門
  buList: [],
  //倉庫
  centerList: [],

  requestParamShodan: null,

  progress: {},
  datasShodan: [],

  vendorIsIME: false,
  honbuIsIME: false,
  itemIsIME: false,
  errorMessage: null,
  infoMessage: null,
};

//Page Slice
const createSliceContent = (name: string) => createSlice({
  name: name,
  initialState,
  reducers: {
    setGroup2List(state: OutputDataShodanTmpState, action: PayloadAction<CodeName[]>) {
      state.group2List = action.payload;
    },
    setGroup1List(state: OutputDataShodanTmpState, action: PayloadAction<CodeName[]>) {
      state.group1List = action.payload;
    },
    setBuList(state: OutputDataShodanTmpState, action: PayloadAction<CodeName[]>) {
      state.buList = action.payload;
    },
    setCenterList(state: OutputDataShodanTmpState, action: PayloadAction<CodeName[]>) {
      state.centerList = action.payload;
    },

    //検索条件
    setRequestParamShodan(state: OutputDataShodanTmpState, action: PayloadAction<RequestParam>) {
      state.requestParamShodan = action.payload;
    },

    putProgress(state: OutputDataShodanTmpState, action: PayloadAction<string>) {
      const key = action.payload;
      const progressNew = { ...state.progress };
      progressNew[key] = true;
      state.progress = progressNew;
    },
    removeProgress(state: OutputDataShodanTmpState, action: PayloadAction<string>) {
      const key = action.payload;
      const progressNew = {};
      Object.keys(state.progress).forEach(k => {
        if (key != k) {
          progressNew[k] = true;
        }
      })
      state.progress = progressNew;
    },
    //定番マスタ
    searchedShodan(state: OutputDataShodanTmpState, action: PayloadAction<{ datas: RowDataShodan[], param: RequestParam }>) {
      //計算
      let datas = action.payload.datas;
      const param = action.payload.param;

      datas = parseData(datas);
      datas = calcDatas(datas);

      state.datasShodan = datas;
    },
    setDatasShodan(state: OutputDataShodanTmpState, action: PayloadAction<RowDataShodan[]>) {
      const datas = action.payload;
      Object.assign(state, {
        datasShodan: datas,
      });
    },
    setVendorIsIME(state: OutputDataShodanTmpState, action: PayloadAction<boolean>) {
      state.vendorIsIME = action.payload;
    },
    setHonbuIsIME(state: OutputDataShodanTmpState, action: PayloadAction<boolean>) {
      state.honbuIsIME = action.payload;
    },
    setItemIsIME(state: OutputDataShodanTmpState, action: PayloadAction<boolean>) {
      state.itemIsIME = action.payload;
    },
    setErrorMessage(state: OutputDataShodanTmpState, action: PayloadAction<string>) {
      state.errorMessage = action.payload;
    },
    setInfoMessage(state: OutputDataShodanTmpState, action: PayloadAction<string>) {
      state.infoMessage = action.payload;
    },
  },
});

//数値のパース(数値が文字列で返ってくる)
const parseData = (datas: RowDataShodan[]): RowDataShodan[] => {
  let tmp: number;

  //set No.
  datas.forEach(data => {
    if (typeof data.hatsubaiDate === 'string') {
      const hatsubaiDate = moment(data.hatsubaiDate);
      data.hatsubaiDate = hatsubaiDate.isValid() ? hatsubaiDate.toDate() : null;
    }
    if (typeof data.bowlPieceNumber === 'string') data.bowlPieceNumber = parseInt(data.bowlPieceNumber);
    if (typeof data.casePieceNumber === 'string') data.casePieceNumber = parseInt(data.casePieceNumber);
    if (typeof data.hatchuUnit === 'string') data.hatchuUnit = parseInt(data.hatchuUnit);
    if (typeof data.saiteiLot === 'string') data.saiteiLot = parseInt(data.saiteiLot);
    if (typeof data.shomiKikan === 'string') data.shomiKikan = parseInt(data.shomiKikan);
    if (typeof data.teibanNouka === 'string') data.teibanNouka = (tmp = parseFloat(data.teibanNouka)) ? tmp : null;
    if (typeof data.teibanBaika === 'string') data.teibanBaika = (tmp = parseFloat(data.teibanBaika)) ? tmp : null;
    if (typeof data.tokubaiNouka === 'string') data.tokubaiNouka = (tmp = parseFloat(data.tokubaiNouka)) ? tmp : null;
    if (typeof data.tokubaiBaika === 'string') data.tokubaiBaika = (tmp = parseFloat(data.tokubaiBaika)) ? tmp : null;
    if (typeof data.tatene === 'string') data.tatene = (tmp = parseFloat(data.tatene)) ? tmp : null;
    if (typeof data.rebate === 'string') data.rebate = (tmp = parseFloat(data.rebate)) ? tmp : null;
    if (typeof data.tateneHenkobi === 'string') {
      const tateneHenkobi = moment(data.tateneHenkobi);
      data.tateneHenkobi = tateneHenkobi.isValid() ? tateneHenkobi.toDate() : null;
    }
    if (typeof data.sinTatene === 'string') data.sinTatene = (tmp = parseFloat(data.sinTatene)) ? tmp : null;
    if (typeof data.keisanTatene === 'string') data.keisanTatene = (tmp = parseFloat(data.keisanTatene)) ? tmp : null;
  });
  return datas;
}
//計算
const calcDatas = (datas: RowDataShodan[]): RowDataShodan[] => {

  datas.forEach((data, index) => {
    //ここに貼り付け
    data.no = '' + (index + 1);
  });
  return datas;
}

//Page Slice Export
//outputDataShodanSave
export const outputDataShodanTmpSlice = createSliceContent("outputDataShodanTmp");
