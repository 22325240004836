import moment from "moment";

export const parseValue = (value: string | number | boolean | object | null, editorType: 'text' | 'numeric' | 'checkbox' | 'date' | 'time', format: string) => {
  if(value === undefined || value === null){
    return null;
  }

  switch (editorType) {
    case 'text':
      return parseValueText(value);
    case 'numeric':
      return parseValueNumeric(value, format);
    case 'checkbox':
      return parseValueCheckbox(value);
    case 'date':
      return parseValueDate(value, format);
    case 'time':
      return parseValueTime(value, format);
    default:
      return value;
  }
}
const parseValueText = (value:string|number|boolean|object|null) => {
  if(typeof value != 'string') {
    value = '' + value;
  }
  return value;
}
const parseValueNumeric = (value:string|number|boolean|object|null, format:string) => {
  let valueN:number;
  if(typeof value != 'number') {
    try {
      if(format.indexOf('.') !== -1) {
        valueN = parseFloat('' + value);
      }
      else {
        valueN = parseInt('' + value);
      }
    } catch (e) {
      console.log(e);
      valueN = null;
    }
  }
  else {
    valueN = value;
  }
  if(isNaN(valueN)) {
    return null;
  }
  return valueN;
}
const parseValueDate = (value:string|number|boolean|object|null, format:string) => {
  //dateはstringにする
  if(typeof value != 'string') {
    value = '' + value;
  }

  let fmt = '';
  const slash = (value.match(/\//g) || []).length;
  if (slash != 0) {
    const values: string[] = value.split('/').reverse();
    values.forEach((val, index) => {
      fmt += (fmt ? '/' : '') + ['D', 'M', 'Y'][index].repeat(val.length);
    });
    fmt = fmt.split("").reverse().join("");
  } else {
    const list = { 4: 'MMDD', 6: 'YYMMDD', 8: 'YYYYMMDD' };
    fmt = list[value.length];
  }

  if (!fmt) {
    return null;
  } else if (moment(value, fmt).isValid()) {
    const result = moment(value, fmt);
    if (fmt.indexOf('Y') === -1) {
      //年指定なし
      const now = moment().startOf('day');
      const month = now.diff(result, 'month');
      if (month <= -6) {
        result.add(-1, 'year');
      } else if (month >= 6) {
        result.add(1, 'year');
      }
    }
    return result.format(format);
  } else {
    return null;
  }
    // if(!(typeof value == 'object' && value instanceof Date)) {
    //   try {
    //     return new Date('' + value);
    //   } catch (e) {
    //     console.log(e);
    //     return null;
    //   }
    // }
    // return value;
}
const parseValueCheckbox = (value:string|number|boolean|object|null) => {
  if(typeof value != 'boolean') {
    try {
      value = value == 'true';
    } catch (e) {
      console.log(e);
      value = false;
    }
  }
  return value;
}

export const parseBoolean = (value: string | boolean | null): boolean => {
  if (typeof value === 'boolean') {
    return value;
  }
  if (typeof value === 'string') {
    return value === 'true' ? true : false;
  }
  return false;
}

const parseValueTime = (value: string | number | boolean | object | null, format: string) => {
  //timeはstringにする
  if (typeof value != 'string') {
    value = '' + value;
  }

  let fmt = '';
  const collon = (value.match(/\:/g) || []).length;
  if (collon != 0) {
    const values: string[] = value.split(':');
    values.forEach((val, index) => {
      fmt += (fmt ? ':' : '') + ['H', 'm', 's'][index].repeat(val.length);
    });
  } else {
    const list = { 1: 'H', 2: 'HH', 3: 'Hmm', 4: 'HHmm', 5: 'Hmmss', 6: 'HHmmss' };
    fmt = list[value.length];
  }

  if (!fmt) {
    return null;
  } else if (moment(value, fmt).isValid()) {
    const result = moment(value, fmt);
    return result.format(format);
  } else {
    return null;
  }
}
