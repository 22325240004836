import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { colFromKeys } from "@/components/itemlist/ItemListTokuisakiModel";

//Page State
export type ItemListTokuisakiSaveState = {
  sort: { key: string, asc: boolean },

  hiddenColumns: number[],
  hiddenColumnsKey: string[],
};

export const initialState: ItemListTokuisakiSaveState = {
  sort: {
    key: "HQTY", //
    asc: false, //降順
  },

  hiddenColumns: [],
  hiddenColumnsKey: [],
};

//Page Slice
const createSliceContent = (name: string) => createSlice({
  name: name,
  initialState,
  reducers: {
    //componentDidMount
    initOnDidMount(state) {
      //追加属性等はlocalstorageから復元したときにundefinedになっている場合があるためチェックする
      if (!state.sort || !state.sort.key) {
        state.sort = initialState.sort;
      }

      state.hiddenColumnsKey = initialState.hiddenColumnsKey;
    },
    setSort(state:ItemListTokuisakiSaveState, action: PayloadAction<{ sortKey: string, sortAsc: boolean }>) {
      console.log('tokuisaki setSort');
      const key = action.payload.sortKey;
      const asc = action.payload.sortAsc;
      state.sort = {
        key: key,
        asc: asc,
      }
    },
    hideColumnsChange(state:ItemListTokuisakiSaveState, action: PayloadAction<{ columns: number[], keys: string[] }>) {
      Object.assign(state, {
        hiddenColumns: action.payload.columns,
        hiddenColumnsKey: action.payload.keys,
      });
    },
    hideColumnKeysChange(state:ItemListTokuisakiSaveState, action: PayloadAction<string[]>) {
      Object.assign(state, {
        hiddenColumns: colFromKeys(action.payload),
        hiddenColumnsKey: action.payload,
      });
    },
  },
});

//Page Slice Export
export const itemListTokuisakiSaveSlice = createSliceContent("itemListTokuisakiSave");
