import * as arrayUtil from "@/util/arrayUtil";
import * as SalesListTableModel from "@/components/saleslist/SalesListTableModel"

//行固定
export const fixedRowsTop = 0;

export const drillDownMaxLv = SalesListTableModel.drillDownMaxLv;
export const drillDownTotalMaxLv = SalesListTableModel.drillDownTotalMaxLv;
export const drillDownLv1MaxLv = SalesListTableModel.drillDownLv1MaxLv;
export const drillDownLv2MaxLv = SalesListTableModel.drillDownLv2MaxLv;

export const colKeys45: string[] = [
  "CDIV",
  "TDIV",
];

//ヘッダー列
export const headerColKeys = [
  'no',
  'H1CD', 'H1NM', 'H2CD', 'H2NM', 'H3CD', 'H3NM', 'H4CD', 'H4NM', 'H5CD', 'H5NM', 'H6CD', 'H6NM', 'H7CD', 'H7NM', 'H8CD', 'H8NM',
  ...colKeys45,
];
//行ヘッダー列
export const rowHeaderColKeys = [
  'rowHeader1', 'rowHeader2', 'rowHeader3',
];
//期間列
export const termColKeys = [
  "T01", "T02", "T03", "T04", "T05", "T06", "T07", "T08", "T09", "T10",
  "T11", "T12", "T13", "T14", "T15", "T16", "T17", "T18", "T19", "T20",
  "T21", "T22", "T23", "T24", "T25", "T26", "T27", "T28", "T29", "T30",
  "T31", "T32", "T33", "T34", "T35", "T36", "T37", "T38", "T39", "T40",
  "T41", "T42", "T43", "T44", "T45", "T46", "T47", "T48", "T49", "T50",
  "T51", "T52", "T53",
];

export const headersCol = [
  {
    no : "No.",
    rowHeader1 : "項目", rowHeader2 : "項目", rowHeader3 : "項目",
    //以下に貼り付け
    H1CD: "LV1", H1NM: "LV1", H2CD: "LV2", H2NM: "LV2", H3CD: "LV3", H3NM: "LV3", H4CD: "LV4", H4NM: "LV4", H5CD: "LV5", H5NM: "LV5", H6CD: "LV6", H6NM: "LV6", H7CD: "LV7", H7NM: "LV7", H8CD: "LV8", H8NM: "LV8",
    CDIV: "LV1",
    TDIV: "LV1",
    TTL: "合計",
    T01: "期間", T02: "期間", T03: "期間", T04: "期間", T05: "期間", T06: "期間", T07: "期間", T08: "期間", T09: "期間", T10: "期間",
    T11: "期間", T12: "期間", T13: "期間", T14: "期間", T15: "期間", T16: "期間", T17: "期間", T18: "期間", T19: "期間", T20: "期間",
    T21: "期間", T22: "期間", T23: "期間", T24: "期間", T25: "期間", T26: "期間", T27: "期間", T28: "期間", T29: "期間", T30: "期間",
    T31: "期間" ,T32: "期間" ,T33: "期間" ,T34: "期間" ,T35: "期間" ,T36: "期間" ,T37: "期間" ,T38: "期間" ,T39: "期間" ,T40: "期間" ,
    T41: "期間" ,T42: "期間" ,T43: "期間" ,T44: "期間" ,T45: "期間" ,T46: "期間" ,T47: "期間" ,T48: "期間" ,T49: "期間" ,T50: "期間" ,
    T51: "期間" ,T52: "期間" ,T53: "期間" ,
  },
  {
    no : "",
    rowHeader1 : "大項目", rowHeader2 : "中項目", rowHeader3 : "小項目",
    //以下に貼り付け
    H1CD: "CD", H1NM: "名称", H2CD: "CD", H2NM: "名称", H3CD: "CD", H3NM: "名称", H4CD: "CD", H4NM: "名称", H5CD: "CD", H5NM: "名称", H6CD: "CD", H6NM: "名称", H7CD: "CD", H7NM: "名称", H8CD: "CD", H8NM: "名称",
    CDIV: "TC/DC",
    TDIV: "自社/委託",
    TTL: "合計",
    T01: "%YM01%", T02: "%YM02%", T03: "%YM03%", T04: "%YM04%", T05: "%YM05%", T06: "%YM06%", T07: "%YM07%", T08: "%YM08%", T09: "%YM09%", T10: "%YM10%",
    T11: "%YM11%", T12: "%YM12%", T13: "%YM13%", T14: "%YM14%", T15: "%YM15%", T16: "%YM16%", T17: "%YM17%", T18: "%YM18%", T19: "%YM19%", T20: "%YM20%",
    T21: "%YM21%", T22: "%YM22%", T23: "%YM23%", T24: "%YM24%", T25: "%YM25%", T26: "%YM26%", T27: "%YM27%", T28: "%YM28%", T29: "%YM29%", T30: "%YM30%",
    T31: "%YM31%" ,T32: "%YM32%" ,T33: "%YM33%" ,T34: "%YM34%" ,T35: "%YM35%" ,T36: "%YM36%" ,T37: "%YM37%" ,T38: "%YM38%" ,T39: "%YM39%" ,T40: "%YM40%" ,
    T41: "%YM41%" ,T42: "%YM42%" ,T43: "%YM43%" ,T44: "%YM44%" ,T45: "%YM45%" ,T46: "%YM46%" ,T47: "%YM47%" ,T48: "%YM48%" ,T49: "%YM49%" ,T50: "%YM50%" ,
    T51: "%YM51%" ,T52: "%YM52%" ,T53: "%YM53%" ,
  },
];

export const headersRowMonthly = [
  {
  //以下に貼り付け
  JJT: "受注", JJP: "受注", JJR: "受注", JJD: "受注", 
  JKT: "欠品", JKP: "欠品", JKR: "欠品", JKD: "欠品", 
  JRT: "欠品", JRP: "欠品", JRD: "欠品", 
  //欠品責任別
  KI1KA: "欠品責任別", KI1KR: "欠品責任別", KI1KK: "欠品責任別", 
  KI2KA: "欠品責任別", KI2KR: "欠品責任別", KI2KK: "欠品責任別", 
  KI3KA: "欠品責任別", KI3KR: "欠品責任別", KI3KK: "欠品責任別", 
  //欠品区分別
  K10KA: "欠品区分別(1.自社責)", K10KR: "欠品区分別(1.自社責)", K10KK: "欠品区分別(1.自社責)", 
  K11KA: "欠品区分別(1.自社責)", K11KR: "欠品区分別(1.自社責)", K11KK: "欠品区分別(1.自社責)", 
  K12KA: "欠品区分別(1.自社責)", K12KR: "欠品区分別(1.自社責)", K12KK: "欠品区分別(1.自社責)", 
  K13KA: "欠品区分別(1.自社責)", K13KR: "欠品区分別(1.自社責)", K13KK: "欠品区分別(1.自社責)", 
  K14KA: "欠品区分別(1.自社責)", K14KR: "欠品区分別(1.自社責)", K14KK: "欠品区分別(1.自社責)", 
  K15KA: "欠品区分別(1.自社責)", K15KR: "欠品区分別(1.自社責)", K15KK: "欠品区分別(1.自社責)", 
  K16KA: "欠品区分別(1.自社責)", K16KR: "欠品区分別(1.自社責)", K16KK: "欠品区分別(1.自社責)", 
  K17KA: "欠品区分別(1.自社責)", K17KR: "欠品区分別(1.自社責)", K17KK: "欠品区分別(1.自社責)", 
  K19KA: "欠品区分別(1.自社責)", K19KR: "欠品区分別(1.自社責)", K19KK: "欠品区分別(1.自社責)", 
  K20KA: "欠品区分別(2.メーカー責)", K20KR: "欠品区分別(2.メーカー責)", K20KK: "欠品区分別(2.メーカー責)", 
  K21KA: "欠品区分別(2.メーカー責)", K21KR: "欠品区分別(2.メーカー責)", K21KK: "欠品区分別(2.メーカー責)", 
  K22KA: "欠品区分別(2.メーカー責)", K22KR: "欠品区分別(2.メーカー責)", K22KK: "欠品区分別(2.メーカー責)", 
  K29KA: "欠品区分別(2.メーカー責)", K29KR: "欠品区分別(2.メーカー責)", K29KK: "欠品区分別(2.メーカー責)", 
  K30KA: "欠品区分別(3.得意先責)", K30KR: "欠品区分別(3.得意先責)", K30KK: "欠品区分別(3.得意先責)", 
  K31KA: "欠品区分別(3.得意先責)", K31KR: "欠品区分別(3.得意先責)", K31KK: "欠品区分別(3.得意先責)", 
  K32KA: "欠品区分別(3.得意先責)", K32KR: "欠品区分別(3.得意先責)", K32KK: "欠品区分別(3.得意先責)", 
  K33KA: "欠品区分別(3.得意先責)", K33KR: "欠品区分別(3.得意先責)", K33KK: "欠品区分別(3.得意先責)", 
  K41KA: "欠品区分別(3.得意先責)", K41KR: "欠品区分別(3.得意先責)", K41KK: "欠品区分別(3.得意先責)", 
  K34KA: "欠品区分別(3.得意先責)", K34KR: "欠品区分別(3.得意先責)", K34KK: "欠品区分別(3.得意先責)", 
  K39KA: "欠品区分別(3.得意先責)", K39KR: "欠品区分別(3.得意先責)", K39KK: "欠品区分別(3.得意先責)", 
  K40KA: "欠品区分別(3.得意先責)", K40KR: "欠品区分別(3.得意先責)", K40KK: "欠品区分別(3.得意先責)", 
  K42KA: "欠品区分別(3.得意先責)", K42KR: "欠品区分別(3.得意先責)", K42KK: "欠品区分別(3.得意先責)", 
  K43KA: "欠品区分別(3.得意先責)", K43KR: "欠品区分別(3.得意先責)", K43KK: "欠品区分別(3.得意先責)", 
  K44KA: "欠品区分別(3.得意先責)", K44KR: "欠品区分別(3.得意先責)", K44KK: "欠品区分別(3.得意先責)", 
  K49KA: "欠品区分別(3.得意先責)", K49KR: "欠品区分別(3.得意先責)", K49KK: "欠品区分別(3.得意先責)", 
  K50KA: "欠品区分別(3.得意先責)", K50KR: "欠品区分別(3.得意先責)", K50KK: "欠品区分別(3.得意先責)", 

  SLT: "総売上", SLP: "総売上", SLR: "総売上", SLD: "総売上", 
  SDT: "総売上", SDP: "総売上", SDR: "総売上", SDD: "総売上", 
  SRT: "総売上", SRP: "総売上", SRR: "総売上", SRD: "総売上", 
  STT: "総売上", STP: "総売上", STR: "総売上", STD: "総売上", STB: "総売上", STBR: "総売上", STBD: "総売上",
  PBLT: "PB売上", PBLP: "PB売上", PBLR: "PB売上", PBLD: "PB売上", 
  PBDT: "PB売上", PBDP: "PB売上", PBDR: "PB売上", PBDD: "PB売上", 
  PBRT: "PB売上", PBRP: "PB売上", PBRR: "PB売上", PBRD: "PB売上", 
  PBTT: "PB売上", PBTP: "PB売上", PBTR: "PB売上", PBTD: "PB売上", 
  PBHT: "PB売上", PBHP: "PB売上", PBHD: "PB売上",
  YHLT: "焼半生売上", YHLP: "焼半生売上", YHLR: "焼半生売上", YHLD: "焼半生売上",
  YHDT: "焼半生売上", YHDP: "焼半生売上", YHDR: "焼半生売上", YHDD: "焼半生売上",
  YHRT: "焼半生売上", YHRP: "焼半生売上", YHRR: "焼半生売上", YHRD: "焼半生売上",
  YHTT: "焼半生売上", YHTP: "焼半生売上", YHTR: "焼半生売上", YHTD: "焼半生売上",
  YHHT: "焼半生売上", YHHP: "焼半生売上", YHHD: "焼半生売上",
  PTT: "売買差益", PTP: "売買差益", PTR: "売買差益", PTD: "売買差益", PTB: "売買差益", PTBR: "売買差益", PTBD: "売買差益", 
  PRT: "売買差益", PRP: "売買差益", PRD: "売買差益",
  DDT: "値引", DDP: "値引", DDR: "値引", DDD: "値引", 
  DBT: "値引", DBP: "値引", DBR: "値引", DBD: "値引", 
  DQT: "値引", DQP: "値引", DQR: "値引", DQD: "値引", 
  DFT: "値引", DFP: "値引", DFR: "値引", DFD: "値引", 
  DTT: "値引", DTP: "値引", DTR: "値引", DTD: "値引", DTB: "値引", DTBR: "値引", DTBD: "値引", 
  DRT: "値引", DRP: "値引", DRD: "値引", 
  NTT: "純売上", NTP: "純売上", NTR: "純売上", NTD: "純売上", NTB: "純売上", NTBR: "純売上", NTBD: "純売上",  
  CIT: "修正", CIP: "修正", CIR: "修正", CID: "修正", 
  CST: "修正", CSP: "修正", CSR: "修正", CSD: "修正", 
  CTT: "修正", CTP: "修正", CTR: "修正", CTD: "修正", CTB: "修正", CTBR: "修正", CTBD: "修正",
  OCT: "センターフィ", OCP: "センターフィ", OCR: "センターフィ", OCD: "センターフィ",
  TST: "センターフィ", TSP: "センターフィ", TSR: "センターフィ", TSD: "センターフィ", 
  CSTT: "センターフィ", CSTP: "センターフィ", CSTR: "センターフィ", CSTD: "センターフィ", CSTB: "センターフィ", CSTBR: "センターフィ", CSTBD: "センターフィ",
  CSTRT: "センターフィ", CSTRP: "センターフィ", CSTRD: "センターフィ",
  OPT: "販促費", OPP: "販促費", OPR: "販促費", OPD: "販促費",
  TPT: "販促費", TPP: "販促費", TPR: "販促費", TPD: "販促費", 
  PPTT: "販促費", PPTP: "販促費", PPTR: "販促費", PPTD: "販促費", PPTB: "販促費", PPTBR: "販促費", PPTBD: "販促費",
  PPTRT: "販促費", PPTRP: "販促費", PPTRD: "販促費",
  OET: "ＥＯＳ費・他", OEP: "ＥＯＳ費・他", OER: "ＥＯＳ費・他", OED: "ＥＯＳ費・他",
  TET: "ＥＯＳ費・他", TEP: "ＥＯＳ費・他", TER: "ＥＯＳ費・他", TED: "ＥＯＳ費・他", 
  ETT: "ＥＯＳ費・他", ETP: "ＥＯＳ費・他", ETR: "ＥＯＳ費・他", ETD: "ＥＯＳ費・他", ETB: "ＥＯＳ費・他", ETBR: "ＥＯＳ費・他", ETBD: "ＥＯＳ費・他",
  ETRT: "ＥＯＳ費・他", ETRP: "ＥＯＳ費・他", ETRD: "ＥＯＳ費・他",
  KTT: "得意先課金計", KTP: "得意先課金計", KTR: "得意先課金計", KTD: "得意先課金計", 
  KRT: "得意先課金計", KRP: "得意先課金計", KRD: "得意先課金計",
  BTT: "売上総利益", BTP: "売上総利益", BTR: "売上総利益", BTD: "売上総利益", 
  BRT: "売上総利益", BRP: "売上総利益", BRD: "売上総利益", 
  ATT: "売上総利益", ATP: "売上総利益", ATR: "売上総利益", ATD: "売上総利益", 
  ART: "売上総利益", ARP: "売上総利益", ARD: "売上総利益", 
  GTT: "売上総利益", GTP: "売上総利益", GTR: "売上総利益", GTD: "売上総利益", GTB: "売上総利益", GTBR: "売上総利益", GTBD: "売上総利益", 
  GRT: "売上総利益", GRP: "売上総利益", GRD: "売上総利益", 
  ZTT: "売上総利益", ZTP: "売上総利益", ZTR: "売上総利益", ZTD: "売上総利益", ZTB: "売上総利益", ZTBR: "売上総利益", ZTBD: "売上総利益", 
  ZRT: "売上総利益", ZRP: "売上総利益", ZRD: "売上総利益",
  ZCT: "企業粗利", ZCP: "企業粗利", ZCR: "企業粗利", ZCD: "企業粗利", 
  ZCRT: "企業粗利", ZCRP: "企業粗利", ZCRD: "企業粗利",
  CNDT: "センター経費", CNDP: "センター経費", CNDR: "センター経費", CNDD: "センター経費", CNDRT: "センター経費", CNDRP: "センター経費", CNDRD: "センター経費",
  CNPT: "センター経費", CNPP: "センター経費", CNPR: "センター経費", CNPD: "センター経費", CNPRT: "センター経費", CNPRP: "センター経費", CNPRD: "センター経費",
  CNGT: "センター経費", CNGP: "センター経費", CNGR: "センター経費", CNGD: "センター経費", CNGRT: "センター経費", CNGRP: "センター経費", CNGRD: "センター経費",
  CNAT: "センター経費", CNAP: "センター経費", CNAR: "センター経費", CNAD: "センター経費", CNART: "センター経費", CNARP: "センター経費", CNARD: "センター経費",
  CNET: "センター経費", CNEP: "センター経費", CNER: "センター経費", CNED: "センター経費", CNRT: "センター経費", CNRP: "センター経費", CNRD: "センター経費",
  SNET: "支店経費", SNEP: "支店経費", SNER: "支店経費", SNED: "支店経費", SNRT: "支店経費", SNRP: "支店経費", SNRD: "支店経費",
  ORST: "販管費", ORSP: "販管費", ORSR: "販管費", ORSD: "販管費", ORSRT: "販管費", ORSRP: "販管費", ORSRD: "販管費",
  ORPT: "営業利益", ORPP: "営業利益", ORPR: "営業利益", ORPD: "営業利益", ORPRT: "営業利益", ORPRP: "営業利益", ORPRD: "営業利益",
  SHCT: "仕入実績", SHCP: "仕入実績", SHCR: "仕入実績", SHCD: "仕入実績", 
  SHDT: "仕入実績", SHDP: "仕入実績", SHDR: "仕入実績", SHDD: "仕入実績", 
  SHRCT: "仕入実績", SHRCP: "仕入実績", SHRCR: "仕入実績", SHRCD: "仕入実績", 
  SHRDT: "仕入実績", SHRDP: "仕入実績", SHRDR: "仕入実績", SHRDD: "仕入実績", 
  SHT: "仕入実績", SHP: "仕入実績", SHR: "仕入実績", SHD: "仕入実績", 
  CCDT: "補正", CDDP: "補正", CDDR: "補正", CDDD: "補正", 
  CDBT: "補正", CDBP: "補正", CDBR: "補正", CDBD: "補正", 
  CDFT: "補正", CDFP: "補正", CDFR: "補正", CDFD: "補正", 
  COCT: "補正", COCP: "補正", COCR: "補正", COCD: "補正", 
  COPT: "補正", COPP: "補正", COPR: "補正", COPD: "補正", 
  COET: "補正", COEP: "補正", COER: "補正", COED: "補正", 
  CTPT: "補正", CTPP: "補正", CTPR: "補正", CTPD: "補正", 
  CTST: "補正", CTSP: "補正", CTSR: "補正", CTSD: "補正", 
  CTET: "補正", CTEP: "補正", CTER: "補正", CTED: "補正",
  },
  {
  //以下に貼り付け
  JJT: "受注額", JJP: "受注額", JJR: "受注額", JJD: "受注額", 
  JKT: "欠品額", JKP: "欠品額", JKR: "欠品額", JKD: "欠品額", 
  JRT: "欠品率", JRP: "欠品率", JRD: "欠品率", 
  //欠品責任別
  KI1KA: '1.自社責', KI1KR: '1.自社責', KI1KK: '1.自社責', 
  KI2KA: '2.メーカー責', KI2KR: '2.メーカー責', KI2KK: '2.メーカー責', 
  KI3KA: '3.得意先責', KI3KR: '3.得意先責', KI3KK: '3.得意先責', 
  //欠品区分別,,
  K10KA: '10.未入力', K10KR: '10.未入力', K10KK: '10.未入力', 
  K11KA: '11.発注漏れ', K11KR: '11.発注漏れ', K11KK: '11.発注漏れ', 
  K12KA: '12.終売案内漏れ', K12KR: '12.終売案内漏れ', K12KK: '12.終売案内漏れ', 
  K13KA: '13.※※使用不可※※', K13KR: '13.※※使用不可※※', K13KK: '13.※※使用不可※※', 
  K14KA: '14.発注数量予測差異', K14KR: '14.発注数量予測差異', K14KK: '14.発注数量予測差異', 
  K15KA: '15.自社出荷ミス', K15KR: '15.自社出荷ミス', K15KK: '15.自社出荷ミス', 
  K16KA: '16.出荷許容切れ', K16KR: '16.出荷許容切れ', K16KK: '16.出荷許容切れ', 
  K17KA: '17.特売連絡漏', K17KR: '17.特売連絡漏', K17KK: '17.特売連絡漏', 
  K19KA: '19.自社その他', K19KR: '19.自社その他', K19KK: '19.自社その他', 
  K20KA: '20.メーカー欠品', K20KR: '20.メーカー欠品', K20KK: '20.メーカー欠品', 
  K21KA: '21.配送', K21KR: '21.配送', K21KK: '21.配送', 
  K22KA: '22.天災', K22KR: '22.天災', K22KK: '22.天災', 
  K29KA: '29.メーカー欠品その他', K29KR: '29.メーカー欠品その他', K29KK: '29.メーカー欠品その他', 
  K30KA: '30.数量訂正・発注取消', K30KR: '30.数量訂正・発注取消', K30KK: '30.数量訂正・発注取消', 
  K31KA: '31.得意先フライング', K31KR: '31.得意先フライング', K31KK: '31.得意先フライング', 
  K32KA: '32.※※使用不可※※', K32KR: '32.※※使用不可※※', K32KK: '32.※※使用不可※※', 
  K33KA: '33.※※使用不可※※', K33KR: '33.※※使用不可※※', K33KK: '33.※※使用不可※※', 
  K41KA: '41.納品日エラー', K41KR: '41.納品日エラー', K41KK: '41.納品日エラー', 
  K34KA: '34.得意先定番外・カット', K34KR: '34.得意先定番外・カット', K34KK: '34.得意先定番外・カット', 
  K39KA: '39.※※使用不可※※', K39KR: '39.※※使用不可※※', K39KK: '39.※※使用不可※※', 
  K40KA: '40.得意先商品CDエラー', K40KR: '40.得意先商品CDエラー', K40KK: '40.得意先商品CDエラー', 
  K42KA: '42.得意先入数エラー', K42KR: '42.得意先入数エラー', K42KK: '42.得意先入数エラー', 
  K43KA: '43.単価エラー', K43KR: '43.単価エラー', K43KK: '43.単価エラー', 
  K44KA: '44.※※使用不可※※', K44KR: '44.※※使用不可※※', K44KK: '44.※※使用不可※※', 
  K49KA: '49.※※使用不可※※', K49KR: '49.※※使用不可※※', K49KK: '49.※※使用不可※※', 
  K50KA: '50.得意先検品ミス', K50KR: '50.得意先検品ミス', K50KK: '50.得意先検品ミス', 

  SLT: "配送売上", SLP: "配送売上", SLR: "配送売上", SLD: "配送売上", 
  SDT: "直送売上", SDP: "直送売上", SDR: "直送売上", SDD: "直送売上",
  SRT: "返品", SRP: "返品", SRR: "返品", SRD: "返品", 
  STT: "総売上", STP: "総売上", STR: "総売上", STD: "総売上", STB: "総売上", STBR: "総売上", STBD: "総売上",
  PBLT: "PB配送売上", PBLP: "PB配送売上", PBLR: "PB配送売上", PBLD: "PB配送売上", 
  PBDT: "PB直送売上", PBDP: "PB直送売上", PBDR: "PB直送売上", PBDD: "PB直送売上",  
  PBRT: "PB返品", PBRP: "PB返品", PBRR: "PB返品", PBRD: "PB返品", 
  PBTT: "PB売上", PBTP: "PB売上", PBTR: "PB売上", PBTD: "PB売上", 
  PBHT: "PB率", PBHP: "PB率", PBHD: "PB率",
  YHLT: "焼半生配送売上", YHLP: "焼半生配送売上", YHLR: "焼半生配送売上", YHLD: "焼半生配送売上",
  YHDT: "焼半生直送売上", YHDP: "焼半生直送売上", YHDR: "焼半生直送売上", YHDD: "焼半生直送売上",
  YHRT: "焼半生返品", YHRP: "焼半生返品", YHRR: "焼半生返品", YHRD: "焼半生返品",
  YHTT: "焼半生売上", YHTP: "焼半生売上", YHTR: "焼半生売上", YHTD: "焼半生売上",
  YHHT: "焼半生率", YHHP: "焼半生率", YHHD: "焼半生率",
  PTT: "売買差益計", PTP: "売買差益計", PTR: "売買差益計", PTD: "売買差益計", PTB: "売買差益計", PTBR: "売買差益計", PTBD: "売買差益計", 
  PRT: "売買差益率", PRP: "売買差益率", PRD: "売買差益率",
  DDT: "値引", DDP: "値引", DDR: "値引", DDD: "値引", 
  DBT: "リベート", DBP: "リベート", DBR: "リベート", DBD: "リベート", 
  DQT: "リベート建値", DQP: "リベート建値", DQR: "リベート建値", DQD: "リベート建値", 
  DFT: "振込リベート", DFP: "振込リベート", DFR: "振込リベート", DFD: "振込リベート", 
  DTT: "値引計", DTP: "値引計", DTR: "値引計", DTD: "値引計", DTB: "値引計", DTBR: "値引計", DTBD: "値引計", 
  DRT: "値引率", DRP: "値引率", DRD: "値引率",
  NTT: "純売上", NTP: "純売上", NTR: "純売上", NTD: "純売上", NTB: "純売上", NTBR: "純売上", NTBD: "純売上",
  CIT: "入力修正", CIP: "入力修正", CIR: "入力修正", CID: "入力修正", 
  CST: "黒修正", CSP: "黒修正", CSR: "黒修正", CSD: "黒修正", 
  CTT: "修正合計", CTP: "修正合計", CTR: "修正合計", CTD: "修正合計", CTB: "修正合計", CTBR: "修正合計", CTBD: "修正合計",
  OCT: "センターフィ（相殺）", OCP: "センターフィ（相殺）", OCR: "センターフィ（相殺）", OCD: "センターフィ（相殺）",
  TST: "業務委託費（振込）", TSP: "業務委託費（振込）", TSR: "業務委託費（振込）", TSD: "業務委託費（振込）", 
  CSTT: "センターフィ（合計）", CSTP: "センターフィ（合計）", CSTR: "センターフィ（合計）", CSTD: "センターフィ（合計）", CSTB: "センターフィ（合計）", CSTBR: "センターフィ（合計）", CSTBD: "センターフィ（合計）",
  CSTRT: "センターフィ率", CSTRP: "センターフィ率", CSTRD: "センターフィ率",
  OPT: "販促費（相殺）", OPP: "販促費（相殺）", OPR: "販促費（相殺）", OPD: "販促費（相殺）",
  TPT: "販促費（振込）", TPP: "販促費（振込）", TPR: "販促費（振込）", TPD: "販促費（振込）", 
  PPTT: "販促費（合計）", PPTP: "販促費（合計）", PPTR: "販促費（合計）", PPTD: "販促費（合計）", PPTB: "販促費（合計）", PPTBR: "販促費（合計）", PPTBD: "販促費（合計）",
  PPTRT: "販促費率", PPTRP: "販促費率", PPTRD: "販促費率",
  OET: "ＥＯＳ費・他（相殺）", OEP: "ＥＯＳ費・他（相殺）", OER: "ＥＯＳ費・他（相殺）", OED: "ＥＯＳ費・他（相殺）",
  TET: "ＥＯＳ費・他（振込）", TEP: "ＥＯＳ費・他（振込）", TER: "ＥＯＳ費・他（振込）", TED: "ＥＯＳ費・他（振込）", 
  ETT: "ＥＯＳ費・他（合計）", ETP: "ＥＯＳ費・他（合計）", ETR: "ＥＯＳ費・他（合計）", ETD: "ＥＯＳ費・他（合計）", ETB: "ＥＯＳ費・他（合計）", ETBR: "ＥＯＳ費・他（合計）", ETBD: "ＥＯＳ費・他（合計）",
  ETRT: "ＥＯＳ費・他率", ETRP: "ＥＯＳ費・他率", ETRD: "ＥＯＳ費・他率",
  KTT: "得意先課金計", KTP: "得意先課金計", KTR: "得意先課金計", KTD: "得意先課金計", 
  KRT: "得意先課金率", KRP: "得意先課金率", KRD: "得意先課金率",
  BTT: "修正前企業粗利", BTP: "修正前企業粗利", BTR: "修正前企業粗利", BTD: "修正前企業粗利", 
  BRT: "修正前企業粗利率", BRP: "修正前企業粗利率", BRD: "修正前企業粗利率", 
  ATT: "修正後企業粗利", ATP: "修正後企業粗利", ATR: "修正後企業粗利", ATD: "修正後企業粗利", 
  ART: "修正後企業粗利率", ARP: "修正後企業粗利率", ARD: "修正後企業粗利率", 
  GTT: "修正後粗利益", GTP: "修正後粗利益", GTR: "修正後粗利益", GTD: "修正後粗利益", GTB: "修正後粗利益", GTBR: "修正後粗利益", GTBD: "修正後粗利益", 
  GRT: "修正後粗利益率", GRP: "修正後粗利益率", GRD: "修正後粗利益率", 
  ZTT: "売上総利益", ZTP: "売上総利益", ZTR: "売上総利益", ZTD: "売上総利益", ZTB: "売上総利益", ZTBR: "売上総利益", ZTBD: "売上総利益", 
  ZRT: "売上総利益率", ZRP: "売上総利益率", ZRD: "売上総利益率",
  ZCT: "企業粗利", ZCP: "企業粗利", ZCR: "企業粗利", ZCD: "企業粗利", 
  ZCRT: "企業粗利率", ZCRP: "企業粗利率", ZCRD: "企業粗利率",
  CNDT: "配送費", CNDP: "配送費", CNDR: "配送費", CNDD: "配送費", CNDRT: "配送費率", CNDRP: "配送費率", CNDRD: "配送費率",
  CNPT: "センター人件費", CNPP: "センター人件費", CNPR: "センター人件費", CNPD: "センター人件費", CNPRT: "センター人件費率", CNPRP: "センター人件費率", CNPRD: "センター人件費率",
  CNGT: "一般経費", CNGP: "一般経費", CNGR: "一般経費", CNGD: "一般経費", CNGRT: "一般経費率", CNGRP: "一般経費率", CNGRD: "一般経費率",
  CNAT: "配賦経費", CNAP: "配賦経費", CNAR: "配賦経費", CNAD: "配賦経費", CNART: "配賦経費率", CNARP: "配賦経費率", CNARD: "配賦経費率",
  CNET: "センター経費", CNEP: "センター経費", CNER: "センター経費", CNED: "センター経費", CNRT: "センター経費率", CNRP: "センター経費率", CNRD: "センター経費率",
  SNET: "支店経費", SNEP: "支店経費", SNER: "支店経費", SNED: "支店経費", SNRT: "支店経費率", SNRP: "支店経費率", SNRD: "支店経費率",
  ORST: "販管費", ORSP: "販管費", ORSR: "販管費", ORSD: "販管費", ORSRT: "販管費率", ORSRP: "販管費率", ORSRD: "販管費率",
  ORPT: "営業利益", ORPP: "営業利益", ORPR: "営業利益", ORPD: "営業利益", ORPRT: "営業利益率", ORPRP: "営業利益率", ORPRD: "営業利益率",
  SHCT: "仕入（倉入）", SHCP: "仕入（倉入）", SHCR: "仕入（倉入）", SHCD: "仕入（倉入）", 
  SHDT: "仕入（直送）", SHDP: "仕入（直送）", SHDR: "仕入（直送）", SHDD: "仕入（直送）", 
  SHRCT: "仕入返品（倉入）", SHRCP: "仕入返品（倉入）", SHRCR: "仕入返品（倉入）", SHRCD: "仕入返品（倉入）", 
  SHRDT: "仕入返品（直送）", SHRDP: "仕入返品（直送）", SHRDR: "仕入返品（直送）", SHRDD: "仕入返品（直送）", 
  SHT: "仕入合計", SHP: "仕入合計", SHR: "仕入合計", SHD: "仕入合計",
  CCDT: "値引補正", CDDP: "値引補正", CDDR: "値引補正", CDDD: "値引補正", 
  CDBT: "リベート補正", CDBP: "リベート補正", CDBR: "リベート補正", CDBD: "リベート補正", 
  CDFT: "振込リベート補正", CDFP: "振込リベート補正", CDFR: "振込リベート補正", CDFD: "振込リベート補正", 
  COCT: "センターフィ（相殺）補正", COCP: "センターフィ（相殺）補正", COCR: "センターフィ（相殺）補正", COCD: "センターフィ（相殺）補正", 
  COPT: "販促費（相殺）補正", COPP: "販促費（相殺）補正", COPR: "販促費（相殺）補正", COPD: "販促費（相殺）補正", 
  COET: "ＥＯＳ費・他（相殺）補正", COEP: "ＥＯＳ費・他（相殺）補正", COER: "ＥＯＳ費・他（相殺）補正", COED: "ＥＯＳ費・他（相殺）補正", 
  CTPT: "販促費（振込）補正", CTPP: "販促費（振込）補正", CTPR: "販促費（振込）補正", CTPD: "販促費（振込）補正", 
  CTST: "業務委託費（振込）補正", CTSP: "業務委託費（振込）補正", CTSR: "業務委託費（振込）補正", CTSD: "業務委託費（振込）補正", 
  CTET: "ＥＯＳ費・他（振込）補正", CTEP: "ＥＯＳ費・他（振込）補正", CTER: "ＥＯＳ費・他（振込）補正", CTED: "ＥＯＳ費・他（振込）補正", 
  },
  {
  //以下に貼り付け
  JJT: "本年実績", JJP: "前年実績", JJR: "前年比", JJD: "前年差", 
  JKT: "本年実績", JKP: "前年実績", JKR: "前年比", JKD: "前年差", 
  JRT: "本年実績", JRP: "前年実績", JRD: "前年差", 
  //欠品責任別
  KI1KA: '欠品額', KI1KR: '欠品率', KI1KK: '欠品構成比', 
  KI2KA: '欠品額', KI2KR: '欠品率', KI2KK: '欠品構成比', 
  KI3KA: '欠品額', KI3KR: '欠品率', KI3KK: '欠品構成比', 
  //欠品区分別,,
  K10KA: '欠品額', K10KR: '欠品率', K10KK: '欠品構成比', 
  K11KA: '欠品額', K11KR: '欠品率', K11KK: '欠品構成比', 
  K12KA: '欠品額', K12KR: '欠品率', K12KK: '欠品構成比', 
  K13KA: '欠品額', K13KR: '欠品率', K13KK: '欠品構成比', 
  K14KA: '欠品額', K14KR: '欠品率', K14KK: '欠品構成比', 
  K15KA: '欠品額', K15KR: '欠品率', K15KK: '欠品構成比', 
  K16KA: '欠品額', K16KR: '欠品率', K16KK: '欠品構成比', 
  K17KA: '欠品額', K17KR: '欠品率', K17KK: '欠品構成比', 
  K19KA: '欠品額', K19KR: '欠品率', K19KK: '欠品構成比', 
  K20KA: '欠品額', K20KR: '欠品率', K20KK: '欠品構成比', 
  K21KA: '欠品額', K21KR: '欠品率', K21KK: '欠品構成比', 
  K22KA: '欠品額', K22KR: '欠品率', K22KK: '欠品構成比', 
  K29KA: '欠品額', K29KR: '欠品率', K29KK: '欠品構成比', 
  K30KA: '欠品額', K30KR: '欠品率', K30KK: '欠品構成比', 
  K31KA: '欠品額', K31KR: '欠品率', K31KK: '欠品構成比', 
  K32KA: '欠品額', K32KR: '欠品率', K32KK: '欠品構成比', 
  K33KA: '欠品額', K33KR: '欠品率', K33KK: '欠品構成比', 
  K41KA: '欠品額', K41KR: '欠品率', K41KK: '欠品構成比', 
  K34KA: '欠品額', K34KR: '欠品率', K34KK: '欠品構成比', 
  K39KA: '欠品額', K39KR: '欠品率', K39KK: '欠品構成比', 
  K40KA: '欠品額', K40KR: '欠品率', K40KK: '欠品構成比', 
  K42KA: '欠品額', K42KR: '欠品率', K42KK: '欠品構成比', 
  K43KA: '欠品額', K43KR: '欠品率', K43KK: '欠品構成比', 
  K44KA: '欠品額', K44KR: '欠品率', K44KK: '欠品構成比', 
  K49KA: '欠品額', K49KR: '欠品率', K49KK: '欠品構成比', 
  K50KA: '欠品額', K50KR: '欠品率', K50KK: '欠品構成比', 

  
  SLT: "本年実績", SLP: "前年実績", SLR: "前年比", SLD: "前年差", 
  SDT: "本年実績", SDP: "前年実績", SDR: "前年比", SDD: "前年差", 
  SRT: "本年実績", SRP: "前年実績", SRR: "前年比", SRD: "前年差", 
  STT: "本年実績", STP: "前年実績", STR: "前年比", STD: "前年差", STB: "予算金額", STBR: "予算達成率", STBD: "予算差",
  PBLT: "本年実績", PBLP: "前年実績", PBLR: "前年比", PBLD: "前年差", 
  PBDT: "本年実績", PBDP: "前年実績", PBDR: "前年比", PBDD: "前年差", 
  PBRT: "本年実績", PBRP: "前年実績", PBRR: "前年比", PBRD: "前年差", 
  PBTT: "本年実績", PBTP: "前年実績", PBTR: "前年比", PBTD: "前年差", 
  PBHT: "本年実績", PBHP: "前年実績", PBHD: "前年差",
  YHLT: "本年実績", YHLP: "前年実績", YHLR: "前年比", YHLD: "前年差",
  YHDT: "本年実績", YHDP: "前年実績", YHDR: "前年比", YHDD: "前年差",
  YHRT: "本年実績", YHRP: "前年実績", YHRR: "前年比", YHRD: "前年差",
  YHTT: "本年実績", YHTP: "前年実績", YHTR: "前年比", YHTD: "前年差",
  YHHT: "本年実績", YHHP: "前年実績", YHHD: "前年差",
  PTT: "本年実績", PTP: "前年実績", PTR: "前年比", PTD: "前年差", PTB: "予算金額", PTBR: "予算達成率", PTBD: "予算差", 
  PRT: "本年実績", PRP: "前年実績", PRD: "前年差",
  DDT: "本年実績", DDP: "前年実績", DDR: "前年比", DDD: "前年差", 
  DBT: "本年実績", DBP: "前年実績", DBR: "前年比", DBD: "前年差", 
  DQT: "本年実績", DQP: "前年実績", DQR: "前年比", DQD: "前年差", 
  DFT: "本年実績", DFP: "前年実績", DFR: "前年比", DFD: "前年差", 
  DTT: "本年実績", DTP: "前年実績", DTR: "前年比", DTD: "前年差", DTB: "予算金額", DTBR: "予算達成率", DTBD: "予算差", 
  DRT: "本年実績", DRP: "前年実績", DRD: "前年差",
  NTT: "本年実績", NTP: "前年実績", NTR: "前年比", NTD: "前年差", NTB: "予算金額", NTBR: "予算達成率", NTBD: "予算差",
  CIT: "本年実績", CIP: "前年実績", CIR: "前年比", CID: "前年差", 
  CST: "本年実績", CSP: "前年実績", CSR: "前年比", CSD: "前年差", 
  CTT: "本年実績", CTP: "前年実績", CTR: "前年比", CTD: "前年差", CTB: "予算金額", CTBR: "予算達成率", CTBD: "予算差",
  OCT: "本年実績", OCP: "前年実績", OCR: "前年比", OCD: "前年差",
  TST: "本年実績", TSP: "前年実績", TSR: "前年比", TSD: "前年差", 
  CSTT: "本年実績", CSTP: "前年実績", CSTR: "前年比", CSTD: "前年差", CSTB: "予算金額", CSTBR: "予算比", CSTBD: "予算差",
  CSTRT: "本年実績", CSTRP: "前年実績", CSTRD: "前年差",
  OPT: "本年実績", OPP: "前年実績", OPR: "前年比", OPD: "前年差",
  TPT: "本年実績", TPP: "前年実績", TPR: "前年比", TPD: "前年差", 
  PPTT: "本年実績", PPTP: "前年実績", PPTR: "前年比", PPTD: "前年差", PPTB: "予算金額", PPTBR: "予算比", PPTBD: "予算差",
  PPTRT: "本年実績", PPTRP: "前年実績", PPTRD: "前年差",
  OET: "本年実績", OEP: "前年実績", OER: "前年比", OED: "前年差",
  TET: "本年実績", TEP: "前年実績", TER: "前年比", TED: "前年差", 
  ETT: "本年実績", ETP: "前年実績", ETR: "前年比", ETD: "前年差", ETB: "予算金額", ETBR: "予算比", ETBD: "予算差",
  ETRT: "本年実績", ETRP: "前年実績", ETRD: "前年差",
  KTT: "本年実績", KTP: "前年実績", KTR: "前年比", KTD: "前年差", 
  KRT: "本年実績", KRP: "前年実績", KRD: "前年差",
  BTT: "本年実績", BTP: "前年実績", BTR: "前年比", BTD: "前年差", 
  BRT: "本年実績", BRP: "前年実績", BRD: "前年差", 
  ATT: "本年実績", ATP: "前年実績", ATR: "前年比", ATD: "前年差", 
  ART: "本年実績", ARP: "前年実績", ARD: "前年差", 
  GTT: "本年実績", GTP: "前年実績", GTR: "前年比", GTD: "前年差", GTB: "予算金額", GTBR: "予算達成率", GTBD: "予算差", 
  GRT: "本年実績", GRP: "前年実績", GRD: "前年差", 
  ZTT: "本年実績", ZTP: "前年実績", ZTR: "前年比", ZTD: "前年差", ZTB: "予算金額", ZTBR: "予算達成率", ZTBD: "予算差", 
  ZRT: "本年実績", ZRP: "前年実績", ZRD: "前年差",
  ZCT: "本年実績", ZCP: "前年実績", ZCR: "前年比", ZCD: "前年差", 
  ZCRT: "本年実績", ZCRP: "前年実績", ZCRD: "前年差",
  CNDT: "本年実績", CNDP: "前年実績", CNDR: "前年比", CNDD: "前年差", CNDRT: "本年実績", CNDRP: "前年実績", CNDRD: "前年差",
  CNPT: "本年実績", CNPP: "前年実績", CNPR: "前年比", CNPD: "前年差", CNPRT: "本年実績", CNPRP: "前年実績", CNPRD: "前年差",
  CNGT: "本年実績", CNGP: "前年実績", CNGR: "前年比", CNGD: "前年差", CNGRT: "本年実績", CNGRP: "前年実績", CNGRD: "前年差",
  CNAT: "本年実績", CNAP: "前年実績", CNAR: "前年比", CNAD: "前年差", CNART: "本年実績", CNARP: "前年実績", CNARD: "前年差",
  CNET: "本年実績", CNEP: "前年実績", CNER: "前年比", CNED: "前年差", CNRT: "本年実績", CNRP: "前年実績", CNRD: "前年差",
  SNET: "本年実績", SNEP: "前年実績", SNER: "前年比", SNED: "前年差", SNRT: "本年実績", SNRP: "前年実績", SNRD: "前年差",
  ORST: "本年実績", ORSP: "前年実績", ORSR: "前年比", ORSD: "前年差", ORSRT: "本年実績", ORSRP: "前年実績", ORSRD: "前年差",
  ORPT: "本年実績", ORPP: "前年実績", ORPR: "前年比", ORPD: "前年差", ORPRT: "本年実績", ORPRP: "前年実績", ORPRD: "前年差",
  SHCT: "本年実績", SHCP: "前年実績", SHCR: "前年比", SHCD: "前年差",
  SHDT: "本年実績", SHDP: "前年実績", SHDR: "前年比", SHDD: "前年差",
  SHRCT: "本年実績", SHRCP: "前年実績", SHRCR: "前年比", SHRCD: "前年差",
  SHRDT: "本年実績", SHRDP: "前年実績", SHRDR: "前年比", SHRDD: "前年差",
  SHT: "本年実績", SHP: "前年実績", SHR: "前年比", SHD: "前年差",
  CCDT: "本年実績", CDDP: "前年実績", CDDR: "前年比", CDDD: "前年差", 
  CDBT: "本年実績", CDBP: "前年実績", CDBR: "前年比", CDBD: "前年差", 
  CDFT: "本年実績", CDFP: "前年実績", CDFR: "前年比", CDFD: "前年差", 
  COCT: "本年実績", COCP: "前年実績", COCR: "前年比", COCD: "前年差", 
  COPT: "本年実績", COPP: "前年実績", COPR: "前年比", COPD: "前年差", 
  COET: "本年実績", COEP: "前年実績", COER: "前年比", COED: "前年差", 
  CTPT: "本年実績", CTPP: "前年実績", CTPR: "前年比", CTPD: "前年差", 
  CTST: "本年実績", CTSP: "前年実績", CTSR: "前年比", CTSD: "前年差", 
  CTET: "本年実績", CTEP: "前年実績", CTER: "前年比", CTED: "前年差",
  },
];
export const headersRowDaily = [
  {
  //以下に貼り付け
  STT: "売上実績", STP: "売上実績", STR: "売上実績", STD: "売上実績", 
  PTT: "売上実績", PTP: "売上実績", PTR: "売上実績", PTD: "売上実績", 
  PRT: "売上実績", PRP: "売上実績", PRD: "売上実績",
  KKT: "欠品", KKR: "欠品",
  PBT: "PB実績", PBR: "PB実績",
  },
  {
  //以下に貼り付け
  STT: "売上実績", STP: "売上実績", STR: "売上実績", STD: "売上実績", 
  PTT: "売買差益計", PTP: "売買差益計", PTR: "売買差益計", PTD: "売買差益計", 
  PRT: "売買差益率", PRP: "売買差益率", PRD: "売買差益率",
  KKT: "欠品", KKR: "欠品",
  PBT: "PB実績", PBR: "PB実績",
  },
  {
  //以下に貼り付け
  STT: "本年実績", STP: "前年実績", STR: "前年比", STD: "前年差", 
  PTT: "本年実績", PTP: "前年実績", PTR: "前年比", PTD: "前年差", 
  PRT: "本年実績", PRP: "前年実績", PRD: "前年差",
  KKT: "欠品金額", KKR: "欠品率",
  PBT: "本年実績", PBR: "PB率",
  },
];
export const headersRowWeekly = {...headersRowMonthly}; //月次と同じ

export const selectableCol =
{
  no : false,
  rowHeader1 : false, rowHeader2 : false, rowHeader3 : false,
  //以下に貼り付け
  H1CD: true, H1NM: false, H2CD: true, H2NM: false, H3CD: true, H3NM: false, H4CD: true, H4NM: false, H5CD: true, H5NM: false, H6CD: true, H6NM: false, H7CD: true, H7NM: false, H8CD: true, H8NM: false,
  CDIV: false,
  TDIV: false,
  TTL: false,
  T01: false, T02: false, T03: false, T04: false, T05: false, T06: false, T07: false, T08: false, T09: false, T10: false,
  T11: false, T12: false, T13: false, T14: false, T15: false, T16: false, T17: false, T18: false, T19: false, T20: false,
  T21: false, T22: false, T23: false, T24: false, T25: false, T26: false, T27: false, T28: false, T29: false, T30: false,
  T31: false ,T32: false ,T33: false ,T34: false ,T35: false ,T36: false ,T37: false ,T38: false ,T39: false ,T40: false ,
  T41: false ,T42: false ,T43: false ,T44: false ,T45: false ,T46: false ,T47: false ,T48: false ,T49: false ,T50: false ,
  T51: false ,T52: false ,T53: false ,
}
export const selectableRow =
{
  //以下に貼り付け
  JJT: true, JJP: true, JJR: true, JJD: true, 
  JKT: true, JKP: true, JKR: true, JKD: true, 
  JRT: true, JRP: true, JRD: true, 
  //欠品責任別
  KI1KA: true, KI1KR: true, KI1KK: true, 
  KI2KA: true, KI2KR: true, KI2KK: true, 
  KI3KA: true, KI3KR: true, KI3KK: true, 
  //欠品区分別,,
  K10KA: true, K10KR: true, K10KK: true, 
  K11KA: true, K11KR: true, K11KK: true, 
  K12KA: true, K12KR: true, K12KK: true, 
  K13KA: true, K13KR: true, K13KK: true, 
  K14KA: true, K14KR: true, K14KK: true, 
  K15KA: true, K15KR: true, K15KK: true, 
  K16KA: true, K16KR: true, K16KK: true, 
  K17KA: true, K17KR: true, K17KK: true, 
  K19KA: true, K19KR: true, K19KK: true, 
  K20KA: true, K20KR: true, K20KK: true, 
  K21KA: true, K21KR: true, K21KK: true, 
  K22KA: true, K22KR: true, K22KK: true, 
  K29KA: true, K29KR: true, K29KK: true, 
  K30KA: true, K30KR: true, K30KK: true, 
  K31KA: true, K31KR: true, K31KK: true, 
  K32KA: true, K32KR: true, K32KK: true, 
  K33KA: true, K33KR: true, K33KK: true, 
  K41KA: true, K41KR: true, K41KK: true, 
  K34KA: true, K34KR: true, K34KK: true, 
  K39KA: true, K39KR: true, K39KK: true, 
  K40KA: true, K40KR: true, K40KK: true, 
  K42KA: true, K42KR: true, K42KK: true, 
  K43KA: true, K43KR: true, K43KK: true, 
  K44KA: true, K44KR: true, K44KK: true, 
  K49KA: true, K49KR: true, K49KK: true, 
  K50KA: true, K50KR: true, K50KK: true, 

  
  SLT: true, SLP: true, SLR: true, SLD: true, 
  SDT: true, SDP: true, SDR: true, SDD: true, 
  SRT: true, SRP: true, SRR: true, SRD: true, 
  STT: true, STP: true, STR: true, STD: true, STB: true, STBR: true, STBD: true,
  PBLT: true, PBLP: true, PBLR: true, PBLD: true, 
  PBDT: true, PBDP: true, PBDR: true, PBDD: true, 
  PBRT: true, PBRP: true, PBRR: true, PBRD: true, 
  PBTT: true, PBTP: true, PBTR: true, PBTD: true, 
  PBHT: true, PBHP: true, PBHD: true,
  YHLT: true, YHLP: true, YHLR: true, YHLD: true,
  YHDT: true, YHDP: true, YHDR: true, YHDD: true,
  YHRT: true, YHRP: true, YHRR: true, YHRD: true,
  YHTT: true, YHTP: true, YHTR: true, YHTD: true,
  YHHT: true, YHHP: true, YHHD: true,
  PTT: true, PTP: true, PTR: true, PTD: true, PTB: true, PTBR: true, PTBD: true, 
  PRT: true, PRP: true, PRD: true,
  DDT: true, DDP: true, DDR: true, DDD: true, 
  DBT: true, DBP: true, DBR: true, DBD: true, 
  DQT: true, DQP: true, DQR: true, DQD: true, 
  DFT: true, DFP: true, DFR: true, DFD: true, 
  DTT: true, DTP: true, DTR: true, DTD: true, DTB: true, DTBR: true, DTBD: true, 
  DRT: true, DRP: true, DRD: true,
  NTT: true, NTP: true, NTR: true, NTD: true, NTB: true, NTBR: true, NTBD: true,
  CIT: true, CIP: true, CIR: true, CID: true, 
  CST: true, CSP: true, CSR: true, CSD: true, 
  CTT: true, CTP: true, CTR: true, CTD: true, CTB: true, CTBR: true, CTBD: true,
  OCT: true, OCP: true, OCR: true, OCD: true,
  TST: true, TSP: true, TSR: true, TSD: true, 
  CSTT: true, CSTP: true, CSTR: true, CSTD: true, CSTB: true, CSTBR: true, CSTBD: true,
  CSTRT: true, CSTRP: true, CSTRD: true,
  OPT: true, OPP: true, OPR: true, OPD: true,
  TPT: true, TPP: true, TPR: true, TPD: true, 
  PPTT: true, PPTP: true, PPTR: true, PPTD: true, PPTB: true, PPTBR: true, PPTBD: true,
  PPTRT: true, PPTRP: true, PPTRD: true,
  OET: true, OEP: true, OER: true, OED: true,
  TET: true, TEP: true, TER: true, TED: true, 
  ETT: true, ETP: true, ETR: true, ETD: true, ETB: true, ETBR: true, ETBD: true,
  ETRT: true, ETRP: true, ETRD: true,
  KTT: true, KTP: true, KTR: true, KTD: true, 
  KRT: true, KRP: true, KRD: true,
  BTT: true, BTP: true, BTR: true, BTD: true, 
  BRT: true, BRP: true, BRD: true, 
  ATT: true, ATP: true, ATR: true, ATD: true, 
  ART: true, ARP: true, ARD: true, 
  GTT: true, GTP: true, GTR: true, GTD: true, GTB: true, GTBR: true, GTBD: true, 
  GRT: true, GRP: true, GRD: true, 
  ZTT: true, ZTP: true, ZTR: true, ZTD: true, ZTB: true, ZTBR: true, ZTBD: true, 
  ZRT: true, ZRP: true, ZRD: true,
  ZCT: true, ZCP: true, ZCR: true, ZCD: true, 
  ZCRT: true, ZCRP: true, ZCRD: true,
  CNDT: true, CNDP: true, CNDR: true, CNDD: true, CNDRT: true, CNDRP: true, CNDRD: true,
  CNPT: true, CNPP: true, CNPR: true, CNPD: true, CNPRT: true, CNPRP: true, CNPRD: true,
  CNGT: true, CNGP: true, CNGR: true, CNGD: true, CNGRT: true, CNGRP: true, CNGRD: true,
  CNAT: true, CNAP: true, CNAR: true, CNAD: true, CNART: true, CNARP: true, CNARD: true,
  CNET: true, CNEP: true, CNER: true, CNED: true, CNRT: true, CNRP: true, CNRD: true,
  SNET: true, SNEP: true, SNER: true, SNED: true, SNRT: true, SNRP: true, SNRD: true,
  ORST: true, ORSP: true, ORSR: true, ORSD: true, ORSRT: true, ORSRP: true, ORSRD: true,
  ORPT: true, ORPP: true, ORPR: true, ORPD: true, ORPRT: true, ORPRP: true, ORPRD: true,
  SHCT: true, SHCP: true, SHCR: true, SHCD: true, 
  SHDT: true, SHDP: true, SHDR: true, SHDD: true, 
  SHRCT: true, SHRCP: true, SHRCR: true, SHRCD: true, 
  SHRDT: true, SHRDP: true, SHRDR: true, SHRDD: true, 
  SHT: true, SHP: true, SHR: true, SHD: true,
  CCDT: true, CDDP: true, CDDR: true, CDDD: true, 
  CDBT: true, CDBP: true, CDBR: true, CDBD: true, 
  CDFT: true, CDFP: true, CDFR: true, CDFD: true, 
  COCT: true, COCP: true, COCR: true, COCD: true, 
  COPT: true, COPP: true, COPR: true, COPD: true, 
  COET: true, COEP: true, COER: true, COED: true, 
  CTPT: true, CTPP: true, CTPR: true, CTPD: true, 
  CTST: true, CTSP: true, CTSR: true, CTSD: true, 
  CTET: true, CTEP: true, CTER: true, CTED: true, 
  KKT: true, KKR: true,
  PBT: true, PBR: true,
}

export const headerColClasses = [
  {
    no : "saleslisttrans_H",
    rowHeader1 : "saleslisttrans_H", rowHeader2 : "saleslisttrans_H", rowHeader3 : "saleslisttrans_H",
    //以下に貼り付け
    H1CD: "saleslisttrans_H", H1NM: "saleslisttrans_H", H2CD: "saleslisttrans_H", H2NM: "saleslisttrans_H", H3CD: "saleslisttrans_H", H3NM: "saleslisttrans_H", H4CD: "saleslisttrans_H", H4NM: "saleslisttrans_H", H5CD: "saleslisttrans_H", H5NM: "saleslisttrans_H", H6CD: "saleslisttrans_H", H6NM: "saleslisttrans_H", H7CD: "saleslisttrans_H", H7NM: "saleslisttrans_H", H8CD: "saleslisttrans_H", H8NM: "saleslisttrans_H",
    CDIV: "saleslisttrans_H",
    TDIV: "saleslisttrans_H",
    TTL: "saleslisttrans_H",
    T01: "saleslisttrans_H", T02: "saleslisttrans_H", T03: "saleslisttrans_H", T04: "saleslisttrans_H", T05: "saleslisttrans_H", T06: "saleslisttrans_H", T07: "saleslisttrans_H", T08: "saleslisttrans_H", T09: "saleslisttrans_H", T10: "saleslisttrans_H",
    T11: "saleslisttrans_H", T12: "saleslisttrans_H", T13: "saleslisttrans_H", T14: "saleslisttrans_H", T15: "saleslisttrans_H", T16: "saleslisttrans_H", T17: "saleslisttrans_H", T18: "saleslisttrans_H", T19: "saleslisttrans_H", T20: "saleslisttrans_H",
    T21: "saleslisttrans_H", T22: "saleslisttrans_H", T23: "saleslisttrans_H", T24: "saleslisttrans_H", T25: "saleslisttrans_H", T26: "saleslisttrans_H", T27: "saleslisttrans_H", T28: "saleslisttrans_H", T29: "saleslisttrans_H", T30: "saleslisttrans_H",
    T31: "saleslisttrans_H" ,T32: "saleslisttrans_H" ,T33: "saleslisttrans_H" ,T34: "saleslisttrans_H" ,T35: "saleslisttrans_H" ,T36: "saleslisttrans_H" ,T37: "saleslisttrans_H" ,T38: "saleslisttrans_H" ,T39: "saleslisttrans_H" ,T40: "saleslisttrans_H" ,
    T41: "saleslisttrans_H" ,T42: "saleslisttrans_H" ,T43: "saleslisttrans_H" ,T44: "saleslisttrans_H" ,T45: "saleslisttrans_H" ,T46: "saleslisttrans_H" ,T47: "saleslisttrans_H" ,T48: "saleslisttrans_H" ,T49: "saleslisttrans_H" ,T50: "saleslisttrans_H" ,
    T51: "saleslisttrans_H" ,T52: "saleslisttrans_H" ,T53: "saleslisttrans_H" ,    
  },
  {
    no : "saleslisttrans_H",
    rowHeader1 : "saleslisttrans_H", rowHeader2 : "saleslisttrans_H", rowHeader3 : "saleslisttrans_H",
    //以下に貼り付け
    H1CD: "saleslisttrans_H", H1NM: "saleslisttrans_H", H2CD: "saleslisttrans_H", H2NM: "saleslisttrans_H", H3CD: "saleslisttrans_H", H3NM: "saleslisttrans_H", H4CD: "saleslisttrans_H", H4NM: "saleslisttrans_H", H5CD: "saleslisttrans_H", H5NM: "saleslisttrans_H", H6CD: "saleslisttrans_H", H6NM: "saleslisttrans_H", H7CD: "saleslisttrans_H", H7NM: "saleslisttrans_H", H8CD: "saleslisttrans_H", H8NM: "saleslisttrans_H",
    CDIV: "saleslisttrans_H",
    TDIV: "saleslisttrans_H",
    TTL: "saleslisttrans_H",
    T01: "saleslisttrans_H", T02: "saleslisttrans_H", T03: "saleslisttrans_H", T04: "saleslisttrans_H", T05: "saleslisttrans_H", T06: "saleslisttrans_H", T07: "saleslisttrans_H", T08: "saleslisttrans_H", T09: "saleslisttrans_H", T10: "saleslisttrans_H",
    T11: "saleslisttrans_H", T12: "saleslisttrans_H", T13: "saleslisttrans_H", T14: "saleslisttrans_H", T15: "saleslisttrans_H", T16: "saleslisttrans_H", T17: "saleslisttrans_H", T18: "saleslisttrans_H", T19: "saleslisttrans_H", T20: "saleslisttrans_H",
    T21: "saleslisttrans_H", T22: "saleslisttrans_H", T23: "saleslisttrans_H", T24: "saleslisttrans_H", T25: "saleslisttrans_H", T26: "saleslisttrans_H", T27: "saleslisttrans_H", T28: "saleslisttrans_H", T29: "saleslisttrans_H", T30: "saleslisttrans_H",
    T31: "saleslisttrans_H" ,T32: "saleslisttrans_H" ,T33: "saleslisttrans_H" ,T34: "saleslisttrans_H" ,T35: "saleslisttrans_H" ,T36: "saleslisttrans_H" ,T37: "saleslisttrans_H" ,T38: "saleslisttrans_H" ,T39: "saleslisttrans_H" ,T40: "saleslisttrans_H" ,
    T41: "saleslisttrans_H" ,T42: "saleslisttrans_H" ,T43: "saleslisttrans_H" ,T44: "saleslisttrans_H" ,T45: "saleslisttrans_H" ,T46: "saleslisttrans_H" ,T47: "saleslisttrans_H" ,T48: "saleslisttrans_H" ,T49: "saleslisttrans_H" ,T50: "saleslisttrans_H" ,
    T51: "saleslisttrans_H" ,T52: "saleslisttrans_H" ,T53: "saleslisttrans_H" ,    
  },
];
export const headerRowClasses = [
  {
    JJT: "saleslisttrans_J", JJP: "saleslisttrans_J", JJR: "saleslisttrans_J", JJD: "saleslisttrans_J", 
    JKT: "saleslisttrans_K", JKP: "saleslisttrans_K", JKR: "saleslisttrans_K", JKD: "saleslisttrans_K", 
    JRT: "saleslisttrans_K", JRP: "saleslisttrans_K", JRD: "saleslisttrans_K", 
    SHCT: "saleslisttrans_X", SHCP: "saleslisttrans_X", SHCR: "saleslisttrans_X", SHCD: "saleslisttrans_X", 
    SHDT: "saleslisttrans_X", SHDP: "saleslisttrans_X", SHDR: "saleslisttrans_X", SHDD: "saleslisttrans_X", 
    SHRCT: "saleslisttrans_X", SHRCP: "saleslisttrans_X", SHRCR: "saleslisttrans_X", SHRCD: "saleslisttrans_X", 
    SHRDT: "saleslisttrans_X", SHRDP: "saleslisttrans_X", SHRDR: "saleslisttrans_X", SHRDD: "saleslisttrans_X", 
    SHT: "saleslisttrans_X", SHP: "saleslisttrans_X", SHR: "saleslisttrans_X", SHD: "saleslisttrans_X",
    //欠品責任別
    KI1KA: "saleslisttrans_K2", KI1KR: "saleslisttrans_K2", KI1KK: "saleslisttrans_K2", 
    KI2KA: "saleslisttrans_K2", KI2KR: "saleslisttrans_K2", KI2KK: "saleslisttrans_K2", 
    KI3KA: "saleslisttrans_K2", KI3KR: "saleslisttrans_K2", KI3KK: "saleslisttrans_K2", 
    //欠品区分別,,
    K10KA: "saleslisttrans_K3", K10KR: "saleslisttrans_K3", K10KK: "saleslisttrans_K3",
    K11KA: "saleslisttrans_K3", K11KR: "saleslisttrans_K3", K11KK: "saleslisttrans_K3",
    K12KA: "saleslisttrans_K3", K12KR: "saleslisttrans_K3", K12KK: "saleslisttrans_K3",
    K13KA: "saleslisttrans_K3", K13KR: "saleslisttrans_K3", K13KK: "saleslisttrans_K3",
    K14KA: "saleslisttrans_K3", K14KR: "saleslisttrans_K3", K14KK: "saleslisttrans_K3",
    K15KA: "saleslisttrans_K3", K15KR: "saleslisttrans_K3", K15KK: "saleslisttrans_K3",
    K16KA: "saleslisttrans_K3", K16KR: "saleslisttrans_K3", K16KK: "saleslisttrans_K3",
    K17KA: "saleslisttrans_K3", K17KR: "saleslisttrans_K3", K17KK: "saleslisttrans_K3",
    K19KA: "saleslisttrans_K3", K19KR: "saleslisttrans_K3", K19KK: "saleslisttrans_K3",
    K20KA: "saleslisttrans_K3", K20KR: "saleslisttrans_K3", K20KK: "saleslisttrans_K3",
    K21KA: "saleslisttrans_K3", K21KR: "saleslisttrans_K3", K21KK: "saleslisttrans_K3",
    K22KA: "saleslisttrans_K3", K22KR: "saleslisttrans_K3", K22KK: "saleslisttrans_K3",
    K29KA: "saleslisttrans_K3", K29KR: "saleslisttrans_K3", K29KK: "saleslisttrans_K3",
    K30KA: "saleslisttrans_K3", K30KR: "saleslisttrans_K3", K30KK: "saleslisttrans_K3",
    K31KA: "saleslisttrans_K3", K31KR: "saleslisttrans_K3", K31KK: "saleslisttrans_K3",
    K32KA: "saleslisttrans_K3", K32KR: "saleslisttrans_K3", K32KK: "saleslisttrans_K3",
    K33KA: "saleslisttrans_K3", K33KR: "saleslisttrans_K3", K33KK: "saleslisttrans_K3",
    K41KA: "saleslisttrans_K3", K41KR: "saleslisttrans_K3", K41KK: "saleslisttrans_K3",
    K34KA: "saleslisttrans_K3", K34KR: "saleslisttrans_K3", K34KK: "saleslisttrans_K3",
    K39KA: "saleslisttrans_K3", K39KR: "saleslisttrans_K3", K39KK: "saleslisttrans_K3",
    K40KA: "saleslisttrans_K3", K40KR: "saleslisttrans_K3", K40KK: "saleslisttrans_K3",
    K42KA: "saleslisttrans_K3", K42KR: "saleslisttrans_K3", K42KK: "saleslisttrans_K3",
    K43KA: "saleslisttrans_K3", K43KR: "saleslisttrans_K3", K43KK: "saleslisttrans_K3",
    K44KA: "saleslisttrans_K3", K44KR: "saleslisttrans_K3", K44KK: "saleslisttrans_K3",
    K49KA: "saleslisttrans_K3", K49KR: "saleslisttrans_K3", K49KK: "saleslisttrans_K3",
    K50KA: "saleslisttrans_K3", K50KR: "saleslisttrans_K3", K50KK: "saleslisttrans_K3",

    CCDT: "saleslisttrans_P", CDDP: "saleslisttrans_P", CDDR: "saleslisttrans_P", CDDD: "saleslisttrans_P", 
    CDBT: "saleslisttrans_P", CDBP: "saleslisttrans_P", CDBR: "saleslisttrans_P", CDBD: "saleslisttrans_P", 
    CDFT: "saleslisttrans_P", CDFP: "saleslisttrans_P", CDFR: "saleslisttrans_P", CDFD: "saleslisttrans_P",    
    COCT: "saleslisttrans_P", COCP: "saleslisttrans_P", COCR: "saleslisttrans_P", COCD: "saleslisttrans_P", 
    COPT: "saleslisttrans_P", COPP: "saleslisttrans_P", COPR: "saleslisttrans_P", COPD: "saleslisttrans_P", 
    COET: "saleslisttrans_P", COEP: "saleslisttrans_P", COER: "saleslisttrans_P", COED: "saleslisttrans_P",    
    CTPT: "saleslisttrans_P", CTPP: "saleslisttrans_P", CTPR: "saleslisttrans_P", CTPD: "saleslisttrans_P", 
    CTST: "saleslisttrans_P", CTSP: "saleslisttrans_P", CTSR: "saleslisttrans_P", CTSD: "saleslisttrans_P", 
    CTET: "saleslisttrans_P", CTEP: "saleslisttrans_P", CTER: "saleslisttrans_P", CTED: "saleslisttrans_P",
    KKT: "saleslisttrans_J",  KKR: "saleslisttrans_J",  
    PBT: "saleslisttrans_J",  PBR: "saleslisttrans_J",  
  },
  {
    JJT: "saleslisttrans_J", JJP: "saleslisttrans_J", JJR: "saleslisttrans_J", JJD: "saleslisttrans_J", 
    JKT: "saleslisttrans_K", JKP: "saleslisttrans_K", JKR: "saleslisttrans_K", JKD: "saleslisttrans_K", 
    JRT: "saleslisttrans_K", JRP: "saleslisttrans_K", JRD: "saleslisttrans_K", 
    SHCT: "saleslisttrans_X", SHCP: "saleslisttrans_X", SHCR: "saleslisttrans_X", SHCD: "saleslisttrans_X", 
    SHDT: "saleslisttrans_X", SHDP: "saleslisttrans_X", SHDR: "saleslisttrans_X", SHDD: "saleslisttrans_X", 
    SHRCT: "saleslisttrans_X", SHRCP: "saleslisttrans_X", SHRCR: "saleslisttrans_X", SHRCD: "saleslisttrans_X", 
    SHRDT: "saleslisttrans_X", SHRDP: "saleslisttrans_X", SHRDR: "saleslisttrans_X", SHRDD: "saleslisttrans_X", 
    SHT: "saleslisttrans_X", SHP: "saleslisttrans_X", SHR: "saleslisttrans_X", SHD: "saleslisttrans_X",
    //欠品責任別
    KI1KA: "saleslisttrans_K2", KI1KR: "saleslisttrans_K2", KI1KK: "saleslisttrans_K2", 
    KI2KA: "saleslisttrans_K2", KI2KR: "saleslisttrans_K2", KI2KK: "saleslisttrans_K2", 
    KI3KA: "saleslisttrans_K2", KI3KR: "saleslisttrans_K2", KI3KK: "saleslisttrans_K2", 
    //欠品区分別,,
    K10KA: "saleslisttrans_K3", K10KR: "saleslisttrans_K3", K10KK: "saleslisttrans_K3",
    K11KA: "saleslisttrans_K3", K11KR: "saleslisttrans_K3", K11KK: "saleslisttrans_K3",
    K12KA: "saleslisttrans_K3", K12KR: "saleslisttrans_K3", K12KK: "saleslisttrans_K3",
    K13KA: "saleslisttrans_K3", K13KR: "saleslisttrans_K3", K13KK: "saleslisttrans_K3",
    K14KA: "saleslisttrans_K3", K14KR: "saleslisttrans_K3", K14KK: "saleslisttrans_K3",
    K15KA: "saleslisttrans_K3", K15KR: "saleslisttrans_K3", K15KK: "saleslisttrans_K3",
    K16KA: "saleslisttrans_K3", K16KR: "saleslisttrans_K3", K16KK: "saleslisttrans_K3",
    K17KA: "saleslisttrans_K3", K17KR: "saleslisttrans_K3", K17KK: "saleslisttrans_K3",
    K19KA: "saleslisttrans_K3", K19KR: "saleslisttrans_K3", K19KK: "saleslisttrans_K3",
    K20KA: "saleslisttrans_K3", K20KR: "saleslisttrans_K3", K20KK: "saleslisttrans_K3",
    K21KA: "saleslisttrans_K3", K21KR: "saleslisttrans_K3", K21KK: "saleslisttrans_K3",
    K22KA: "saleslisttrans_K3", K22KR: "saleslisttrans_K3", K22KK: "saleslisttrans_K3",
    K29KA: "saleslisttrans_K3", K29KR: "saleslisttrans_K3", K29KK: "saleslisttrans_K3",
    K30KA: "saleslisttrans_K3", K30KR: "saleslisttrans_K3", K30KK: "saleslisttrans_K3",
    K31KA: "saleslisttrans_K3", K31KR: "saleslisttrans_K3", K31KK: "saleslisttrans_K3",
    K32KA: "saleslisttrans_K3", K32KR: "saleslisttrans_K3", K32KK: "saleslisttrans_K3",
    K33KA: "saleslisttrans_K3", K33KR: "saleslisttrans_K3", K33KK: "saleslisttrans_K3",
    K41KA: "saleslisttrans_K3", K41KR: "saleslisttrans_K3", K41KK: "saleslisttrans_K3",
    K34KA: "saleslisttrans_K3", K34KR: "saleslisttrans_K3", K34KK: "saleslisttrans_K3",
    K39KA: "saleslisttrans_K3", K39KR: "saleslisttrans_K3", K39KK: "saleslisttrans_K3",
    K40KA: "saleslisttrans_K3", K40KR: "saleslisttrans_K3", K40KK: "saleslisttrans_K3",
    K42KA: "saleslisttrans_K3", K42KR: "saleslisttrans_K3", K42KK: "saleslisttrans_K3",
    K43KA: "saleslisttrans_K3", K43KR: "saleslisttrans_K3", K43KK: "saleslisttrans_K3",
    K44KA: "saleslisttrans_K3", K44KR: "saleslisttrans_K3", K44KK: "saleslisttrans_K3",
    K49KA: "saleslisttrans_K3", K49KR: "saleslisttrans_K3", K49KK: "saleslisttrans_K3",
    K50KA: "saleslisttrans_K3", K50KR: "saleslisttrans_K3", K50KK: "saleslisttrans_K3",

    KKT: "saleslisttrans_J",  KKR: "saleslisttrans_J",  
    PBT: "saleslisttrans_J",  PBR: "saleslisttrans_J",  
  },
  {
    JJT: "saleslisttrans_J", JJP: "saleslisttrans_JP", JJR: "saleslisttrans_JP", JJD: "saleslisttrans_JP", 
    JKT: "saleslisttrans_K", JKP: "saleslisttrans_KP", JKR: "saleslisttrans_KP", JKD: "saleslisttrans_KP", 
    JRT: "saleslisttrans_K", JRP: "saleslisttrans_KP", JRD: "saleslisttrans_KP", 
    //欠品責任別
    KI1KA: "saleslisttrans_K2", KI1KR: "saleslisttrans_K2", KI1KK: "saleslisttrans_K2", 
    KI2KA: "saleslisttrans_K2", KI2KR: "saleslisttrans_K2", KI2KK: "saleslisttrans_K2", 
    KI3KA: "saleslisttrans_K2", KI3KR: "saleslisttrans_K2", KI3KK: "saleslisttrans_K2", 
    //欠品区分別,,
    K10KA: "saleslisttrans_K3", K10KR: "saleslisttrans_K3", K10KK: "saleslisttrans_K3",
    K11KA: "saleslisttrans_K3", K11KR: "saleslisttrans_K3", K11KK: "saleslisttrans_K3",
    K12KA: "saleslisttrans_K3", K12KR: "saleslisttrans_K3", K12KK: "saleslisttrans_K3",
    K13KA: "saleslisttrans_K3", K13KR: "saleslisttrans_K3", K13KK: "saleslisttrans_K3",
    K14KA: "saleslisttrans_K3", K14KR: "saleslisttrans_K3", K14KK: "saleslisttrans_K3",
    K15KA: "saleslisttrans_K3", K15KR: "saleslisttrans_K3", K15KK: "saleslisttrans_K3",
    K16KA: "saleslisttrans_K3", K16KR: "saleslisttrans_K3", K16KK: "saleslisttrans_K3",
    K17KA: "saleslisttrans_K3", K17KR: "saleslisttrans_K3", K17KK: "saleslisttrans_K3",
    K19KA: "saleslisttrans_K3", K19KR: "saleslisttrans_K3", K19KK: "saleslisttrans_K3",
    K20KA: "saleslisttrans_K3", K20KR: "saleslisttrans_K3", K20KK: "saleslisttrans_K3",
    K21KA: "saleslisttrans_K3", K21KR: "saleslisttrans_K3", K21KK: "saleslisttrans_K3",
    K22KA: "saleslisttrans_K3", K22KR: "saleslisttrans_K3", K22KK: "saleslisttrans_K3",
    K29KA: "saleslisttrans_K3", K29KR: "saleslisttrans_K3", K29KK: "saleslisttrans_K3",
    K30KA: "saleslisttrans_K3", K30KR: "saleslisttrans_K3", K30KK: "saleslisttrans_K3",
    K31KA: "saleslisttrans_K3", K31KR: "saleslisttrans_K3", K31KK: "saleslisttrans_K3",
    K32KA: "saleslisttrans_K3", K32KR: "saleslisttrans_K3", K32KK: "saleslisttrans_K3",
    K33KA: "saleslisttrans_K3", K33KR: "saleslisttrans_K3", K33KK: "saleslisttrans_K3",
    K41KA: "saleslisttrans_K3", K41KR: "saleslisttrans_K3", K41KK: "saleslisttrans_K3",
    K34KA: "saleslisttrans_K3", K34KR: "saleslisttrans_K3", K34KK: "saleslisttrans_K3",
    K39KA: "saleslisttrans_K3", K39KR: "saleslisttrans_K3", K39KK: "saleslisttrans_K3",
    K40KA: "saleslisttrans_K3", K40KR: "saleslisttrans_K3", K40KK: "saleslisttrans_K3",
    K42KA: "saleslisttrans_K3", K42KR: "saleslisttrans_K3", K42KK: "saleslisttrans_K3",
    K43KA: "saleslisttrans_K3", K43KR: "saleslisttrans_K3", K43KK: "saleslisttrans_K3",
    K44KA: "saleslisttrans_K3", K44KR: "saleslisttrans_K3", K44KK: "saleslisttrans_K3",
    K49KA: "saleslisttrans_K3", K49KR: "saleslisttrans_K3", K49KK: "saleslisttrans_K3",
    K50KA: "saleslisttrans_K3", K50KR: "saleslisttrans_K3", K50KK: "saleslisttrans_K3",

    SLP: "saleslisttrans_P",  SLR: "saleslisttrans_P",  SLD: "saleslisttrans_P",  
    SDP: "saleslisttrans_P",  SDR: "saleslisttrans_P",  SDD: "saleslisttrans_P",  
    SRP: "saleslisttrans_P",  SRR: "saleslisttrans_P",  SRD: "saleslisttrans_P",  
    STP: "saleslisttrans_P",  STR: "saleslisttrans_P",  STD: "saleslisttrans_P",  STB: "saleslisttrans_B",  STBR: "saleslisttrans_B",  STBD: "saleslisttrans_B",  
    PBLP: "saleslisttrans_P",  PBLR: "saleslisttrans_P",  PBLD: "saleslisttrans_P",  
    PBDP: "saleslisttrans_P",  PBDR: "saleslisttrans_P",  PBDD: "saleslisttrans_P",  
    PBRP: "saleslisttrans_P",  PBRR: "saleslisttrans_P",  PBRD: "saleslisttrans_P",  
    PBTP: "saleslisttrans_P",  PBTR: "saleslisttrans_P",  PBTD: "saleslisttrans_P",  
    PBHP: "saleslisttrans_P",  PBHD: "saleslisttrans_P",  
    PTP: "saleslisttrans_P", PTR: "saleslisttrans_P", PTD: "saleslisttrans_P", PTB: "saleslisttrans_B", PTBR: "saleslisttrans_B", PTBD: "saleslisttrans_B",  
    PRP: "saleslisttrans_P",  PRD: "saleslisttrans_P",  
    DDP: "saleslisttrans_P",  DDR: "saleslisttrans_P",  DDD: "saleslisttrans_P",  
    DBP: "saleslisttrans_P",  DBR: "saleslisttrans_P",  DBD: "saleslisttrans_P",  
    DQP: "saleslisttrans_P",  DQR: "saleslisttrans_P",  DQD: "saleslisttrans_P",  
    DFP: "saleslisttrans_P",  DFR: "saleslisttrans_P",  DFD: "saleslisttrans_P",  
    DTP: "saleslisttrans_P",  DTR: "saleslisttrans_P",  DTD: "saleslisttrans_P",  DTB: "saleslisttrans_B",  DTBR: "saleslisttrans_B",  DTBD: "saleslisttrans_B",  
    DRP: "saleslisttrans_P",  DRD: "saleslisttrans_P",  
    NTP: "saleslisttrans_P",  NTR: "saleslisttrans_P",  NTD: "saleslisttrans_P",  NTB: "saleslisttrans_B",  NTBR: "saleslisttrans_B",  NTBD: "saleslisttrans_B",  
    CIP: "saleslisttrans_P",  CIR: "saleslisttrans_P",  CID: "saleslisttrans_P",  
    CSP: "saleslisttrans_P",  CSR: "saleslisttrans_P",  CSD: "saleslisttrans_P",  
    CTP: "saleslisttrans_P",  CTR: "saleslisttrans_P",  CTD: "saleslisttrans_P",  CTB: "saleslisttrans_B",  CTBR: "saleslisttrans_B",  CTBD: "saleslisttrans_B",  
    OCP: "saleslisttrans_P",  OCR: "saleslisttrans_P",  OCD: "saleslisttrans_P",
    OPP: "saleslisttrans_P",  OPR: "saleslisttrans_P",  OPD: "saleslisttrans_P",
    OEP: "saleslisttrans_P",  OER: "saleslisttrans_P",  OED: "saleslisttrans_P",
    OTP: "saleslisttrans_P",  OTR: "saleslisttrans_P",  OTD: "saleslisttrans_P",  
    ORP: "saleslisttrans_P",  
    TPP: "saleslisttrans_P",  TPR: "saleslisttrans_P",  TPD: "saleslisttrans_P",  
    PPTP: "saleslisttrans_P", PPTR: "saleslisttrans_P", PPTD: "saleslisttrans_P", PPTB: "saleslisttrans_B", PPTBR: "saleslisttrans_B", PPTBD: "saleslisttrans_B",
    PPTRP: "saleslisttrans_P", PPTRD: "saleslisttrans_P",
    TSP: "saleslisttrans_P",  TSR: "saleslisttrans_P",  TSD: "saleslisttrans_P",  
    CSTP: "saleslisttrans_P", CSTR: "saleslisttrans_P", CSTD: "saleslisttrans_P", CSTB: "saleslisttrans_B", CSTBR: "saleslisttrans_B", CSTBD: "saleslisttrans_B",
    CSTRP: "saleslisttrans_P", CSTRD: "saleslisttrans_P",
    TEP: "saleslisttrans_P",  TER: "saleslisttrans_P",  TED: "saleslisttrans_P",  
    ETP: "saleslisttrans_P", ETR: "saleslisttrans_P", ETD: "saleslisttrans_P", ETB: "saleslisttrans_B", ETBR: "saleslisttrans_B", ETBD: "saleslisttrans_B",
    ETRP: "saleslisttrans_P", ETRD: "saleslisttrans_P",
    KTP: "saleslisttrans_P",  KTR: "saleslisttrans_P",  KTD: "saleslisttrans_P",  
    KRP: "saleslisttrans_P",  KRD: "saleslisttrans_P",  
    TTP: "saleslisttrans_P",  TTR: "saleslisttrans_P",  TTD: "saleslisttrans_P",  
    TRP: "saleslisttrans_P",  TRR: "saleslisttrans_P",  TRD: "saleslisttrans_P",  
    BTP: "saleslisttrans_P",  BTR: "saleslisttrans_P",  BTD: "saleslisttrans_P",  
    BRP: "saleslisttrans_P",  BRD: "saleslisttrans_P",  
    ATP: "saleslisttrans_P",  ATR: "saleslisttrans_P",  ATD: "saleslisttrans_P",  
    ARP: "saleslisttrans_P",  ARD: "saleslisttrans_P",  
    GTP: "saleslisttrans_P",  GTR: "saleslisttrans_P",  GTD: "saleslisttrans_P",  GTB: "saleslisttrans_B",  GTBR: "saleslisttrans_B",  GTBD: "saleslisttrans_B",  
    GRP: "saleslisttrans_P",  GRD: "saleslisttrans_P",  
    ZTP: "saleslisttrans_P",  ZTR: "saleslisttrans_P",  ZTD: "saleslisttrans_P",  ZTB: "saleslisttrans_B",  ZTBR: "saleslisttrans_B",  ZTBD: "saleslisttrans_B",  
    ZRP: "saleslisttrans_P",  ZRD: "saleslisttrans_P",  
    ZCP: "saleslisttrans_P",  ZCR: "saleslisttrans_P",  ZCD: "saleslisttrans_P",  
    ZCRP: "saleslisttrans_P",  ZCRD: "saleslisttrans_P",  
    CNDP: "saleslisttrans_P", CNDR: "saleslisttrans_P", CNDD: "saleslisttrans_P", CNDRP: "saleslisttrans_P", CNDRD: "saleslisttrans_P",
    CNPP: "saleslisttrans_P", CNPR: "saleslisttrans_P", CNPD: "saleslisttrans_P", CNPRP: "saleslisttrans_P", CNPRD: "saleslisttrans_P",
    CNGP: "saleslisttrans_P", CNGR: "saleslisttrans_P", CNGD: "saleslisttrans_P", CNGRP: "saleslisttrans_P", CNGRD: "saleslisttrans_P",
    CNAP: "saleslisttrans_P", CNAR: "saleslisttrans_P", CNAD: "saleslisttrans_P", CNARP: "saleslisttrans_P", CNARD: "saleslisttrans_P",
    CNEP: "saleslisttrans_P", CNER: "saleslisttrans_P", CNED: "saleslisttrans_P", CNRP: "saleslisttrans_P", CNRD: "saleslisttrans_P",
    SNEP: "saleslisttrans_P", SNER: "saleslisttrans_P", SNED: "saleslisttrans_P", SNRP: "saleslisttrans_P", SNRD: "saleslisttrans_P",
    ORSP: "saleslisttrans_P", ORSR: "saleslisttrans_P", ORSD: "saleslisttrans_P", ORSRP: "saleslisttrans_P", ORSRD: "saleslisttrans_P",
    ORPP: "saleslisttrans_P", ORPR: "saleslisttrans_P", ORPD: "saleslisttrans_P", ORPRP: "saleslisttrans_P", ORPRD: "saleslisttrans_P",
    SHCT: "saleslisttrans_X", SHCP: "saleslisttrans_X", SHCR: "saleslisttrans_X", SHCD: "saleslisttrans_X", 
    SHDT: "saleslisttrans_X", SHDP: "saleslisttrans_X", SHDR: "saleslisttrans_X", SHDD: "saleslisttrans_X", 
    SHRCT: "saleslisttrans_X", SHRCP: "saleslisttrans_X", SHRCR: "saleslisttrans_X", SHRCD: "saleslisttrans_X", 
    SHRDT: "saleslisttrans_X", SHRDP: "saleslisttrans_X", SHRDR: "saleslisttrans_X", SHRDD: "saleslisttrans_X", 
    SHT: "saleslisttrans_X", SHP: "saleslisttrans_XP", SHR: "saleslisttrans_XP", SHD: "saleslisttrans_XP",
    CDDP: "saleslisttrans_P",  CDDR: "saleslisttrans_P",  CDDD: "saleslisttrans_P",  
    CDBP: "saleslisttrans_P",  CDBR: "saleslisttrans_P",  CDBD: "saleslisttrans_P",  
    CDFP: "saleslisttrans_P",  CDFR: "saleslisttrans_P",  CDFD: "saleslisttrans_P",  
    COCP: "saleslisttrans_P",  COCR: "saleslisttrans_P",  COCD: "saleslisttrans_P",  
    COPP: "saleslisttrans_P",  COPR: "saleslisttrans_P",  COPD: "saleslisttrans_P",  
    COEP: "saleslisttrans_P",  COER: "saleslisttrans_P",  COED: "saleslisttrans_P",  
    CTPP: "saleslisttrans_P",  CTPR: "saleslisttrans_P",  CTPD: "saleslisttrans_P",  
    CTSP: "saleslisttrans_P",  CTSR: "saleslisttrans_P",  CTSD: "saleslisttrans_P",  
    CTEP: "saleslisttrans_P",  CTER: "saleslisttrans_P",  CTED: "saleslisttrans_P",  
    KKT: "saleslisttrans_J",  KKR: "saleslisttrans_J",  
    PBT: "saleslisttrans_J",  PBR: "saleslisttrans_J",  
  },
]

export const colWidths =
{
  no : 70,
  rowHeader1 : 100, rowHeader2 : 100, rowHeader3 : 100,
  //以下に貼り付け
  H1CD: 80, H1NM: 150, H2CD: 80, H2NM: 150, H3CD: 80, H3NM: 150, H4CD: 80, H4NM: 150, H5CD: 80, H5NM: 150, H6CD: 80, H6NM: 150, H7CD: 80, H7NM: 150, H8CD: 80, H8NM: 150,
  CDIV: 80,
  TDIV: 80,
  T01: 120, T02: 120, T03: 120, T04: 120, T05: 120, T06: 120, T07: 120, T08: 120, T09: 120, T10: 120,
  T11: 120, T12: 120, T13: 120, T14: 120, T15: 120, T16: 120, T17: 120, T18: 120, T19: 120, T20: 120,
  T21: 120, T22: 120, T23: 120, T24: 120, T25: 120, T26: 120, T27: 120, T28: 120, T29: 120, T30: 120,
  T31: 120 ,T32: 120 ,T33: 120 ,T34: 120 ,T35: 120 ,T36: 120 ,T37: 120 ,T38: 120 ,T39: 120 ,T40: 120 ,
  T41: 120 ,T42: 120 ,T43: 120 ,T44: 120 ,T45: 120 ,T46: 120 ,T47: 120 ,T48: 120 ,T49: 120 ,T50: 120 ,
  T51: 120 ,T52: 120 ,T53: 120 ,  
  TTL: 120,
};

export const colDataType =
{
  no : {type: 'text', readOnly: true,},
  rowHeader1 : {type: 'text', readOnly: true,}, rowHeader2 : {type: 'text', readOnly: true,}, rowHeader3 : {type: 'text', readOnly: true,},
  //以下に貼り付け
  H1CD: {type: 'text', readOnly: true,}, H1NM: {type: 'text', readOnly: true,},
  H2CD: {type: 'text', readOnly: true,}, H2NM: {type: 'text', readOnly: true,},
  H3CD: {type: 'text', readOnly: true,}, H3NM: {type: 'text', readOnly: true,},
  H4CD: {type: 'text', readOnly: true,}, H4NM: {type: 'text', readOnly: true,},
  H5CD: {type: 'text', readOnly: true,}, H5NM: {type: 'text', readOnly: true,},
  H6CD: {type: 'text', readOnly: true,}, H6NM: {type: 'text', readOnly: true,},
  H7CD: {type: 'text', readOnly: true,}, H7NM: {type: 'text', readOnly: true,},
  H8CD: {type: 'text', readOnly: true,}, H8NM: {type: 'text', readOnly: true,},
  CDIV: {type: 'text', readOnly: true,},
  TDIV: {type: 'text', readOnly: true,},
};

export const rowDataType =
{
  JJT: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  JJP: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  JJR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  JJD: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  JKT: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},},
  JKP: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},},
  JKR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  JKD: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},},
  JRT: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  JRP: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  JRD: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0.0P',},},

  //欠品責任別
  KI1KA: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},}, KI1KR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, KI1KK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, 
  KI2KA: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},}, KI2KR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, KI2KK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, 
  KI3KA: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},}, KI3KR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, KI3KK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, 
  //欠品区分別,,,,,,,,,,,
  K10KA: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},}, K10KR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, K10KK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, 
  K11KA: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},}, K11KR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, K11KK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, 
  K12KA: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},}, K12KR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, K12KK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, 
  K13KA: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},}, K13KR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, K13KK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, 
  K14KA: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},}, K14KR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, K14KK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, 
  K15KA: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},}, K15KR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, K15KK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, 
  K16KA: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},}, K16KR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, K16KK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, 
  K17KA: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},}, K17KR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, K17KK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, 
  K19KA: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},}, K19KR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, K19KK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, 
  K20KA: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},}, K20KR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, K20KK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, 
  K21KA: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},}, K21KR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, K21KK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, 
  K22KA: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},}, K22KR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, K22KK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, 
  K29KA: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},}, K29KR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, K29KK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, 
  K30KA: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},}, K30KR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, K30KK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, 
  K31KA: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},}, K31KR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, K31KK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, 
  K32KA: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},}, K32KR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, K32KK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, 
  K33KA: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},}, K33KR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, K33KK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, 
  K41KA: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},}, K41KR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, K41KK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, 
  K34KA: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},}, K34KR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, K34KK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, 
  K39KA: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},}, K39KR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, K39KK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, 
  K40KA: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},}, K40KR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, K40KK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, 
  K42KA: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},}, K42KR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, K42KK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, 
  K43KA: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},}, K43KR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, K43KK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, 
  K44KA: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},}, K44KR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, K44KK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, 
  K49KA: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},}, K49KR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, K49KK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, 
  K50KA: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},}, K50KR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, K50KK: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, 

  SLT: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  SLP: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  SLR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  SLD: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  SDT: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  SDP: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  SDR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  SDD: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  SRT: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  SRP: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  SRR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  SRD: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  STT: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  STP: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  STR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  STD: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  STB: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  STBR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  STBD: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},

  PBLT: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  PBLP: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  PBLR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  PBLD: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  PBDT: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  PBDP: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  PBDR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  PBDD: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  PBRT: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  PBRP: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  PBRR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  PBRD: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  PBTT: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  PBTP: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  PBTR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  PBTD: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  PBHT: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  PBHP: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  PBHD: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0P',},},

  YHLT: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  YHLP: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  YHLR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  YHLD: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  YHDT: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  YHDP: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  YHDR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  YHDD: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  YHRT: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  YHRP: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  YHRR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  YHRD: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  YHTT: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  YHTP: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  YHTR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  YHTD: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  YHHT: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  YHHP: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  YHHD: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0P',},},

  PTT: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  PTP: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  PTR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  PTD: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  PTB: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  PTBR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  PTBD: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  PRT: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.00%',},},
  PRP: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.00%',},},
  PRD: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.00P',},},

  DDT: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},},
  DDP: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},},
  DDR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  DDD: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},},
  DBT: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},},
  DBP: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},},
  DBR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  DBD: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},},
  DQT: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},},
  DQP: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},},
  DQR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  DQD: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},},
  DFT: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},},
  DFP: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},},
  DFR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  DFD: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},},
  DTT: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},},
  DTP: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},},
  DTR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  DTD: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},},
  DTB: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  DTBR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  DTBD: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},},
  DRT: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  DRP: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  DRD: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0.0P',},},

  NTT: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  NTP: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  NTR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  NTD: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  NTB: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  NTBR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  NTBD: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},

  CIT: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  CIP: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  CIR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  CID: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  CST: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  CSP: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  CSR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  CSD: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  CTT: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  CTP: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  CTR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  CTD: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  CTB: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  CTBR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  CTBD: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},

  OCT: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  OCP: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  OCR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  OCD: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},},
  OPT: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  OPP: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  OPR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  OPD: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},},
  OET: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  OEP: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  OER: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  OED: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},},

  TPT: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  TPP: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  TPR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  TPD: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},},
  PPTT: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  PPTP: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  PPTR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  PPTD: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},},
  PPTB: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, },
  PPTBR: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0.0%', }, },
  PPTBD: { type: 'numeric', readOnly: true, numericFormat: { pattern: '△0,0', }, },
  PPTRT: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0.0%', }, },
  PPTRP: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0.0%', }, },
  PPTRD: { type: 'numeric', readOnly: true, numericFormat: { pattern: '△0.0P', }, },
  TST: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  TSP: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  TSR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  TSD: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},},
  CSTT: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  CSTP: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  CSTR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  CSTD: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},},
  CSTB: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, },
  CSTBR: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0.0%', }, },
  CSTBD: { type: 'numeric', readOnly: true, numericFormat: { pattern: '△0,0', }, },
  CSTRT: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0.0%', }, },
  CSTRP: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0.0%', }, },
  CSTRD: { type: 'numeric', readOnly: true, numericFormat: { pattern: '△0.0P', }, },
  TET: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  TEP: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  TER: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  TED: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},},
  ETT: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  ETP: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  ETR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  ETD: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},},
  ETB: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, },
  ETBR: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0.0%', }, },
  ETBD: { type: 'numeric', readOnly: true, numericFormat: { pattern: '△0,0', }, },
  ETRT: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0.0%', }, },
  ETRP: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0.0%', }, },
  ETRD: { type: 'numeric', readOnly: true, numericFormat: { pattern: '△0.0P', }, },
  KTT: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  KTP: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  KTR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  KTD: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},},
  KRT: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  KRP: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  KRD: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0.0P',},},

  BTT: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  BTP: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  BTR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  BTD: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  BRT: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  BRP: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  BRD: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0P',},},
  ATT: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  ATP: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  ATR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  ATD: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  ART: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  ARP: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  ARD: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0P',},},
  GTT: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  GTP: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  GTR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  GTD: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  GTB: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  GTBR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  GTBD: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  GRT: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  GRP: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  GRD: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0P',},},
  ZTT: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  ZTP: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  ZTR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  ZTD: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  ZTB: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  ZTBR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  ZTBD: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  ZRT: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.00%',},},
  ZRP: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.00%',},},
  ZRD: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.00P',},},
  ZCT: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  ZCP: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  ZCR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  ZCD: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  ZCRT: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.00%',},},
  ZCRP: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.00%',},},
  ZCRD: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.00P',},},
  CNDT: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, },
  CNDP: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, },
  CNDR: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0.0%', }, },
  CNDD: { type: 'numeric', readOnly: true, numericFormat: { pattern: '△0,0', }, },
  CNDRT: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0.00%', }, },
  CNDRP: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0.00%', }, },
  CNDRD: { type: 'numeric', readOnly: true, numericFormat: { pattern: '△0.00P', }, },
  CNPT: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, },
  CNPP: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, },
  CNPR: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0.0%', }, },
  CNPD: { type: 'numeric', readOnly: true, numericFormat: { pattern: '△0,0', }, },
  CNPRT: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0.00%', }, },
  CNPRP: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0.00%', }, },
  CNPRD: { type: 'numeric', readOnly: true, numericFormat: { pattern: '△0.00P', }, },
  CNGT: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, },
  CNGP: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, },
  CNGR: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0.0%', }, },
  CNGD: { type: 'numeric', readOnly: true, numericFormat: { pattern: '△0,0', }, },
  CNGRT: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0.00%', }, },
  CNGRP: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0.00%', }, },
  CNGRD: { type: 'numeric', readOnly: true, numericFormat: { pattern: '△0.00P', }, },
  CNAT: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, },
  CNAP: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, },
  CNAR: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0.0%', }, },
  CNAD: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, },
  CNART: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0.00%', }, },
  CNARP: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0.00%', }, },
  CNARD: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0.00P', }, },
  CNET: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, },
  CNEP: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, },
  CNER: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0.0%', }, },
  CNED: { type: 'numeric', readOnly: true, numericFormat: { pattern: '△0,0', }, },
  CNRT: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0.00%', }, },
  CNRP: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0.00%', }, },
  CNRD: { type: 'numeric', readOnly: true, numericFormat: { pattern: '△0.00P', }, },
  SNET: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, },
  SNEP: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, },
  SNER: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0.0%', }, },
  SNED: { type: 'numeric', readOnly: true, numericFormat: { pattern: '△0,0', }, },
  SNRT: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0.00%', }, },
  SNRP: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0.00%', }, },
  SNRD: { type: 'numeric', readOnly: true, numericFormat: { pattern: '△0.00P', }, },
  ORST: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, },
  ORSP: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, },
  ORSR: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0.0%', }, },
  ORSD: { type: 'numeric', readOnly: true, numericFormat: { pattern: '△0,0', }, },
  ORSRT: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0.00%', }, },
  ORSRP: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0.00%', }, },
  ORSRD: { type: 'numeric', readOnly: true, numericFormat: { pattern: '△0.00P', }, },
  ORPT: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, },
  ORPP: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, },
  ORPR: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0.0%', }, },
  ORPD: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, },
  ORPRT: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0.00%', }, },
  ORPRP: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0.00%', }, },
  ORPRD: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0.00P', }, },
  SHCT: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, }, 
  SHCP: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, }, 
  SHCR: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0.0%', }, },
  SHCD: { type: 'numeric', readOnly: true, numericFormat: { pattern: '△0,0', }, }, 
  SHDT: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, }, 
  SHDP: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, }, 
  SHDR: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0.0%', }, },
  SHDD: { type: 'numeric', readOnly: true, numericFormat: { pattern: '△0,0', }, }, 
  SHRCT: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, }, 
  SHRCP: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, }, 
  SHRCR: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0.0%', }, },
  SHRCD: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, }, 
  SHRDT: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, }, 
  SHRDP: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, }, 
  SHRDR: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0.0%', }, },
  SHRDD: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, },   
  SHT: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, },
  SHP: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, },
  SHR: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0.0%', }, },
  SHD: { type: 'numeric', readOnly: true, numericFormat: { pattern: '△0,0', }, },
  CCDT: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  CDDP: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  CDDR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  CDDD: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},},
  CDBT: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  CDBP: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  CDBR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  CDBD: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},},
  CDFT: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  CDFP: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  CDFR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  CDFD: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},},
  COCT: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  COCP: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  COCR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  COCD: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},},
  COPT: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  COPP: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  COPR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  COPD: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},},
  COET: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  COEP: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  COER: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  COED: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},},
  CTPT: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  CTPP: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  CTPR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  CTPD: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},},
  CTST: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  CTSP: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  CTSR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  CTSD: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},},
  CTET: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  CTEP: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  CTER: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
  CTED: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},},

  KKT: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  KKR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},

  PBT: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  PBR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},
};

export const colRendererName =
{
  no : 'basicRenderer',
  rowHeader1 : 'basicRenderer', rowHeader2 : 'basicRenderer', rowHeader3 : 'basicRenderer',
  //以下に貼り付け
  H1CD: 'basicRenderer', H1NM: 'basicRenderer', H2CD: 'basicRenderer', H2NM: 'basicRenderer', H3CD: 'basicRenderer', H3NM: 'basicRenderer', H4CD: 'basicRenderer', H4NM: 'basicRenderer', H5CD: 'basicRenderer', H5NM: 'basicRenderer', H6CD: 'basicRenderer', H6NM: 'basicRenderer', H7CD: 'basicRenderer', H7NM: 'basicRenderer', H8CD: 'basicRenderer', H8NM: 'basicRenderer',
  CDIV: 'basicRenderer',
  TDIV: 'basicRenderer',
  T01: 'basicRenderer', T02: 'basicRenderer', T03: 'basicRenderer', T04: 'basicRenderer', T05: 'basicRenderer', T06: 'basicRenderer', T07: 'basicRenderer', T08: 'basicRenderer', T09: 'basicRenderer', T10: 'basicRenderer',
  T11: 'basicRenderer', T12: 'basicRenderer', T13: 'basicRenderer', T14: 'basicRenderer', T15: 'basicRenderer', T16: 'basicRenderer', T17: 'basicRenderer', T18: 'basicRenderer', T19: 'basicRenderer', T20: 'basicRenderer',
  T21: 'basicRenderer', T22: 'basicRenderer', T23: 'basicRenderer', T24: 'basicRenderer', T25: 'basicRenderer', T26: 'basicRenderer', T27: 'basicRenderer', T28: 'basicRenderer', T29: 'basicRenderer', T30: 'basicRenderer',
  T31: 'basicRenderer' ,T32: 'basicRenderer' ,T33: 'basicRenderer' ,T34: 'basicRenderer' ,T35: 'basicRenderer' ,T36: 'basicRenderer' ,T37: 'basicRenderer' ,T38: 'basicRenderer' ,T39: 'basicRenderer' ,T40: 'basicRenderer' ,
  T41: 'basicRenderer' ,T42: 'basicRenderer' ,T43: 'basicRenderer' ,T44: 'basicRenderer' ,T45: 'basicRenderer' ,T46: 'basicRenderer' ,T47: 'basicRenderer' ,T48: 'basicRenderer' ,T49: 'basicRenderer' ,T50: 'basicRenderer' ,
  T51: 'basicRenderer' ,T52: 'basicRenderer' ,T53: 'basicRenderer' ,  
  TTL: 'basicRenderer',
};

export const rowRendererName =
{
  JJT: 'basicRenderer', JJP: 'basicRenderer', JJR: 'basicRenderer', JJD: 'basicRenderer', 
  JKT: 'basicRenderer', JKP: 'basicRenderer', JKR: 'nebikiRateRenderer', JKD: 'basicRenderer', 
  JRT: 'keppinRateRenderer', JRP: 'keppinRateRenderer', JRD: 'keppinRateDiffRenderer', 
  //欠品責任別
  KI1KA: 'basicRenderer', KI1KR: 'keppinRateRenderer', KI1KK: 'kouseihiRenderer', 
  KI2KA: 'basicRenderer', KI2KR: 'keppinRateRenderer', KI2KK: 'kouseihiRenderer', 
  KI3KA: 'basicRenderer', KI3KR: 'keppinRateRenderer', KI3KK: 'kouseihiRenderer', 
  //欠品区分別,,
  K10KA: 'basicRenderer', K10KR: 'keppinRateRenderer', K10KK: 'kouseihiRenderer', 
  K11KA: 'basicRenderer', K11KR: 'keppinRateRenderer', K11KK: 'kouseihiRenderer', 
  K12KA: 'basicRenderer', K12KR: 'keppinRateRenderer', K12KK: 'kouseihiRenderer', 
  K13KA: 'basicRenderer', K13KR: 'keppinRateRenderer', K13KK: 'kouseihiRenderer', 
  K14KA: 'basicRenderer', K14KR: 'keppinRateRenderer', K14KK: 'kouseihiRenderer', 
  K15KA: 'basicRenderer', K15KR: 'keppinRateRenderer', K15KK: 'kouseihiRenderer', 
  K16KA: 'basicRenderer', K16KR: 'keppinRateRenderer', K16KK: 'kouseihiRenderer', 
  K17KA: 'basicRenderer', K17KR: 'keppinRateRenderer', K17KK: 'kouseihiRenderer', 
  K19KA: 'basicRenderer', K19KR: 'keppinRateRenderer', K19KK: 'kouseihiRenderer', 
  K20KA: 'basicRenderer', K20KR: 'keppinRateRenderer', K20KK: 'kouseihiRenderer', 
  K21KA: 'basicRenderer', K21KR: 'keppinRateRenderer', K21KK: 'kouseihiRenderer', 
  K22KA: 'basicRenderer', K22KR: 'keppinRateRenderer', K22KK: 'kouseihiRenderer', 
  K29KA: 'basicRenderer', K29KR: 'keppinRateRenderer', K29KK: 'kouseihiRenderer', 
  K30KA: 'basicRenderer', K30KR: 'keppinRateRenderer', K30KK: 'kouseihiRenderer', 
  K31KA: 'basicRenderer', K31KR: 'keppinRateRenderer', K31KK: 'kouseihiRenderer', 
  K32KA: 'basicRenderer', K32KR: 'keppinRateRenderer', K32KK: 'kouseihiRenderer', 
  K33KA: 'basicRenderer', K33KR: 'keppinRateRenderer', K33KK: 'kouseihiRenderer', 
  K41KA: 'basicRenderer', K41KR: 'keppinRateRenderer', K41KK: 'kouseihiRenderer', 
  K34KA: 'basicRenderer', K34KR: 'keppinRateRenderer', K34KK: 'kouseihiRenderer', 
  K39KA: 'basicRenderer', K39KR: 'keppinRateRenderer', K39KK: 'kouseihiRenderer', 
  K40KA: 'basicRenderer', K40KR: 'keppinRateRenderer', K40KK: 'kouseihiRenderer', 
  K42KA: 'basicRenderer', K42KR: 'keppinRateRenderer', K42KK: 'kouseihiRenderer', 
  K43KA: 'basicRenderer', K43KR: 'keppinRateRenderer', K43KK: 'kouseihiRenderer', 
  K44KA: 'basicRenderer', K44KR: 'keppinRateRenderer', K44KK: 'kouseihiRenderer', 
  K49KA: 'basicRenderer', K49KR: 'keppinRateRenderer', K49KK: 'kouseihiRenderer', 
  K50KA: 'basicRenderer', K50KR: 'keppinRateRenderer', K50KK: 'kouseihiRenderer', 

  SLT: 'basicRenderer', SLP: 'basicRenderer', SLR: 'rateRenderer', SLD: 'basicRenderer', 
  SDT: 'basicRenderer', SDP: 'basicRenderer', SDR: 'rateRenderer', SDD: 'basicRenderer', 
  SRT: 'basicRenderer', SRP: 'basicRenderer', SRR: 'nebikiRateRenderer', SRD: 'basicRenderer', 
  STT: 'basicRenderer', STP: 'basicRenderer', STR: 'rateRenderer', STD: 'basicRenderer', STB: 'basicRenderer', STBR: 'rateRenderer', STBD: 'basicRenderer',
  PBLT: "basicRenderer", PBLP: "basicRenderer", PBLR: "rateRenderer", PBLD: "basicRenderer", 
  PBDT: "basicRenderer", PBDP: "basicRenderer", PBDR: "rateRenderer", PBDD: "basicRenderer", 
  PBRT: "basicRenderer", PBRP: "basicRenderer", PBRR: "nebikiRateRenderer", PBRD: "basicRenderer", 
  PBTT: "basicRenderer", PBTP: "basicRenderer", PBTR: "rateRenderer", PBTD: "basicRenderer", 
  PBHT: "pbRateRenderer", PBHP: "pbRateRenderer", PBHD: "basicRenderer",
  YHLT: "basicRenderer", YHLP: "basicRenderer", YHLR: "rateRenderer", YHLD: "basicRenderer",
  YHDT: "basicRenderer", YHDP: "basicRenderer", YHDR: "rateRenderer", YHDD: "basicRenderer",
  YHRT: "basicRenderer", YHRP: "basicRenderer", YHRR: "nebikiRateRenderer", YHRD: "basicRenderer",
  YHTT: "basicRenderer", YHTP: "basicRenderer", YHTR: "rateRenderer", YHTD: "basicRenderer",
  YHHT: "pbRateRenderer", YHHP: "pbRateRenderer", YHHD: "basicRenderer",
  PTT: 'basicRenderer', PTP: 'basicRenderer', PTR: 'rateRenderer', PTD: 'basicRenderer', PTB: 'basicRenderer', PTBR: 'rateRenderer', PTBD: 'basicRenderer', 
  PRT: 'basicRenderer', PRP: 'basicRenderer', PRD: 'basicRenderer',
  DDT: 'basicRenderer', DDP: 'basicRenderer', DDR: 'nebikiRateRenderer', DDD: 'basicRenderer', 
  DBT: 'basicRenderer', DBP: 'basicRenderer', DBR: 'nebikiRateRenderer', DBD: 'basicRenderer', 
  DQT: 'basicRenderer', DQP: 'basicRenderer', DQR: 'nebikiRateRenderer', DQD: 'basicRenderer', 
  DFT: 'basicRenderer', DFP: 'basicRenderer', DFR: 'nebikiRateRenderer', DFD: 'basicRenderer', 
  DTT: 'basicRenderer', DTP: 'basicRenderer', DTR: 'nebikiRateRenderer', DTD: 'basicRenderer', DTB: 'basicRenderer', DTBR: 'nebikiRateRenderer', DTBD: 'basicRenderer', 
  DRT: 'basicRenderer', DRP: 'basicRenderer', DRD: 'basicRenderer',
  NTT: 'basicRenderer', NTP: 'basicRenderer', NTR: 'rateRenderer', NTD: 'basicRenderer', NTB: 'basicRenderer', NTBR: 'rateRenderer', NTBD: 'basicRenderer',
  CIT: 'basicRenderer', CIP: 'basicRenderer', CIR: 'rateRenderer', CID: 'basicRenderer', 
  CST: 'basicRenderer', CSP: 'basicRenderer', CSR: 'rateRenderer', CSD: 'basicRenderer', 
  CTT: 'basicRenderer', CTP: 'basicRenderer', CTR: 'rateRenderer', CTD: 'basicRenderer', CTB: 'basicRenderer', CTBR: 'rateRenderer', CTBD: 'basicRenderer',
  OCT: 'basicRenderer', OCP: 'basicRenderer', OCR: 'nebikiRateRenderer', OCD: 'basicRenderer',
  TST: 'basicRenderer', TSP: 'basicRenderer', TSR: 'nebikiRateRenderer', TSD: 'basicRenderer', 
  CSTT: 'basicRenderer', CSTP: 'basicRenderer', CSTR: 'nebikiRateRenderer', CSTD: 'basicRenderer', CSTB: 'basicRenderer', CSTBR: 'nebikiRateRenderer', CSTBD: 'basicRenderer',
  CSTRT: 'basicRenderer', CSTRP: 'basicRenderer', CSTRD: 'basicRenderer',
  OPT: 'basicRenderer', OPP: 'basicRenderer', OPR: 'nebikiRateRenderer', OPD: 'basicRenderer',
  TPT: 'basicRenderer', TPP: 'basicRenderer', TPR: 'nebikiRateRenderer', TPD: 'basicRenderer', 
  PPTT: 'basicRenderer', PPTP: 'basicRenderer', PPTR: 'nebikiRateRenderer', PPTD: 'basicRenderer', PPTB: 'basicRenderer', PPTBR: 'nebikiRateRenderer', PPTBD: 'basicRenderer',
  PPTRT: 'basicRenderer', PPTRP: 'basicRenderer', PPTRD: 'basicRenderer',
  OET: 'basicRenderer', OEP: 'basicRenderer', OER: 'nebikiRateRenderer', OED: 'basicRenderer',
  TET: 'basicRenderer', TEP: 'basicRenderer', TER: 'nebikiRateRenderer', TED: 'basicRenderer', 
  ETT: 'basicRenderer', ETP: 'basicRenderer', ETR: 'nebikiRateRenderer', ETD: 'basicRenderer', ETB: 'basicRenderer', ETBR: 'nebikiRateRenderer', ETBD: 'basicRenderer',
  ETRT: 'basicRenderer', ETRP: 'basicRenderer', ETRD: 'basicRenderer',
  KTT: 'basicRenderer', KTP: 'basicRenderer', KTR: 'nebikiRateRenderer', KTD: 'basicRenderer', 
  KRT: 'basicRenderer', KRP: 'basicRenderer', KRD: 'basicRenderer',
  BTT: 'basicRenderer', BTP: 'basicRenderer', BTR: 'rateRenderer', BTD: 'basicRenderer', 
  BRT: 'basicRenderer', BRP: 'basicRenderer', BRD: 'basicRenderer', 
  ATT: 'basicRenderer', ATP: 'basicRenderer', ATR: 'rateRenderer', ATD: 'basicRenderer', 
  ART: 'basicRenderer', ARP: 'basicRenderer', ARD: 'basicRenderer', 
  GTT: 'basicRenderer', GTP: 'basicRenderer', GTR: 'rateRenderer', GTD: 'basicRenderer', GTB: 'basicRenderer', GTBR: 'rateRenderer', GTBD: 'basicRenderer', 
  GRT: 'basicRenderer', GRP: 'basicRenderer', GRD: 'basicRenderer', 
  ZTT: 'basicRenderer', ZTP: 'basicRenderer', ZTR: 'rateRenderer', ZTD: 'basicRenderer', ZTB: 'basicRenderer', ZTBR: 'rateRenderer', ZTBD: 'basicRenderer', 
  ZRT: 'basicRenderer', ZRP: 'basicRenderer', ZRD: 'basicRenderer',  
  ZCT: 'basicRenderer', ZCP: 'basicRenderer', ZCR: 'rateRenderer', ZCD: 'basicRenderer', 
  ZCRT: 'basicRenderer', ZCRP: 'basicRenderer', ZCRD: 'basicRenderer',  
  CNDT: 'basicRenderer', CNDP: 'basicRenderer', CNDR: 'nebikiRateRenderer', CNDD: 'basicRenderer', CNDRT: "basicRenderer", CNDRP: "basicRenderer", CNDRD: "basicRenderer",
  CNPT: 'basicRenderer', CNPP: 'basicRenderer', CNPR: 'nebikiRateRenderer', CNPD: 'basicRenderer', CNPRT: "basicRenderer", CNPRP: "basicRenderer", CNPRD: "basicRenderer",
  CNGT: 'basicRenderer', CNGP: 'basicRenderer', CNGR: 'nebikiRateRenderer', CNGD: 'basicRenderer', CNGRT: "basicRenderer", CNGRP: "basicRenderer", CNGRD: "basicRenderer",
  CNAT: 'basicRenderer', CNAP: 'basicRenderer', CNAR: 'rateRenderer', CNAD: 'basicRenderer', CNART: "basicRenderer", CNARP: "basicRenderer", CNARD: "basicRenderer",
  CNET: 'basicRenderer', CNEP: 'basicRenderer', CNER: 'nebikiRateRenderer', CNED: 'basicRenderer', CNRT: 'basicRenderer', CNRP: 'basicRenderer', CNRD: 'basicRenderer',
  SNET: 'basicRenderer', SNEP: 'basicRenderer', SNER: 'nebikiRateRenderer', SNED: 'basicRenderer', SNRT: 'basicRenderer', SNRP: 'basicRenderer', SNRD: 'basicRenderer',
  ORST: 'basicRenderer', ORSP: 'basicRenderer', ORSR: 'nebikiRateRenderer', ORSD: 'basicRenderer', ORSRT: 'basicRenderer', ORSRP: 'basicRenderer', ORSRD: 'basicRenderer',
  ORPT: 'basicRenderer', ORPP: 'basicRenderer', ORPR: 'rateRenderer', ORPD: 'basicRenderer', ORPRT: 'basicRenderer', ORPRP: 'basicRenderer', ORPRD: 'basicRenderer',
  SHCT: 'basicRenderer', SHCP: 'basicRenderer', SHCR: 'nebikiRateRenderer', SHCD: 'basicRenderer', 
  SHDT: 'basicRenderer', SHDP: 'basicRenderer', SHDR: 'nebikiRateRenderer', SHDD: 'basicRenderer', 
  SHRCT: 'basicRenderer', SHRCP: 'basicRenderer', SHRCR: 'rateRenderer', SHRCD: 'basicRenderer', 
  SHRDT: 'basicRenderer', SHRDP: 'basicRenderer', SHRDR: 'rateRenderer', SHRDD: 'basicRenderer', 
  SHT: 'basicRenderer', SHP: 'basicRenderer', SHR: 'nebikiRateRenderer', SHD: 'basicRenderer', 
  CCDT: 'basicRenderer', CDDP: 'basicRenderer',
  CDDR: 'basicRenderer', CDDD: 'basicRenderer', 
  CDBT: 'basicRenderer', CDBP: 'basicRenderer', CDBR: 'basicRenderer', CDBD: 'basicRenderer', 
  CDFT: 'basicRenderer', CDFP: 'basicRenderer', CDFR: 'basicRenderer', CDFD: 'basicRenderer', 
  COCT: 'basicRenderer', COCP: 'basicRenderer', COCR: 'basicRenderer', COCD: 'basicRenderer', 
  COPT: 'basicRenderer', COPP: 'basicRenderer', COPR: 'basicRenderer', COPD: 'basicRenderer', 
  COET: 'basicRenderer', COEP: 'basicRenderer', COER: 'basicRenderer', COED: 'basicRenderer', 
  CTPT: 'basicRenderer', CTPP: 'basicRenderer', CTPR: 'basicRenderer', CTPD: 'basicRenderer', 
  CTST: 'basicRenderer', CTSP: 'basicRenderer', CTSR: 'basicRenderer', CTSD: 'basicRenderer', 
  CTET: 'basicRenderer', CTEP: 'basicRenderer', CTER: 'basicRenderer', CTED: 'basicRenderer',
  KKT: 'basicRenderer', KKR: 'basicRenderer',
  PBT: 'basicRenderer', PBR: 'basicRenderer',
};

//グラフ用
//barDataKeys : 前年, 予算, 本年
//lineDataKeys : 前年比, 達成率
export const chartDataKeys = [
  { barDataKeys: ["JJP", "", "JJT"], lineDataKeys: ["JJR"], rowKeys: ["JJT", "JJP", "JJR", "JJD", ] },
  { barDataKeys: ["JKP", "", "JKT"], lineDataKeys: ["JKR"], rowKeys: ["JKT", "JKP", "JKR", "JKD", "JRT", "JRP", "JRD", ] },
  { barDataKeys: ["STP", "STB", "STT"], lineDataKeys: ["STR", "STBR"], rowKeys: ["SLT", "SLP", "SLR", "SLD", "SDT", "SDP", "SDR", "SDD", "SRT", "SRP", "SRR", "SRD", "STT", "STP", "STR", "STD", "STB", "STBR", "STBD",] },
  { barDataKeys: ["PBTP", "PBTB", "PBTT"], lineDataKeys: ["PBTR"], rowKeys: ["PBLT", "PBLP", "PBLR", "PBLD", "PBDT", "PBDP", "PBDR", "PBDD", "PBRT", "PBRP", "PBRR", "PBRD", "PBTT", "PBTP", "PBTR", "PBTD", "PBHT", "PBHP", "PBHD",] },
  { barDataKeys: ["YHTP", "YHTB", "YHTT"], lineDataKeys: ["YHTR"], rowKeys: ["YHLT", "YHLP", "YHLR", "YHLD", "YHDT", "YHDP", "YHDR", "YHDD", "YHRT", "YHRP", "YHRR", "YHRD", "YHTT", "YHTP", "YHTR", "YHTD", "YHHT", "YHHP", "YHHD",] },
  { barDataKeys: ["PTP", "PTB", "PTT"], lineDataKeys: ["PTBR"], rowKeys: ["PTT", "PTP", "PTR", "PTD", "PTB", "PTBR", "PTBD", "PRT", "PRP", "PRD",] },
  { barDataKeys: ["DTP", "DTB", "DTT"], lineDataKeys: ["DTR", "DTBR"], rowKeys: ["DDT", "DDP", "DDR", "DDD", "DBT", "DBP", "DBR", "DBD", "DQT", "DQP", "DQR", "DQD", "DFT", "DFP", "DFR", "DFD", "DTT", "DTP", "DTR", "DTD", "DTB", "DTBR", "DTBD", "DRT", "DRP", "DRD",] },
  { barDataKeys: ["NTP", "NTB", "NTT"], lineDataKeys: ["NTR", "NTBR"], rowKeys: ["NTT", "NTP", "NTR", "NTD", "NTB", "NTBR", "NTBD",] },
  { barDataKeys: ["CTP", "CTB", "CTT"], lineDataKeys: ["CTR", "CTBR"], rowKeys: ["CIT", "CIP", "CIR", "CID", "CST", "CSP", "CSR", "CSD", "CTT", "CTP", "CTR", "CTD", "CTB", "CTBR", "CTBD",] },
  { barDataKeys: ["CSTP", "", "CSTT"], lineDataKeys: ["CSTR",], rowKeys: ["OCT", "OCP", "OCR", "OCD", "TST", "TSP", "TSR", "TSD", "CSTT", "CSTP", "CSTR", "CSTD", "CSTB", "CSTBR", "CSTBD", "CSTRT", "CSTRP", "CSTRD",] },
  { barDataKeys: ["PPTP", "", "PPTT"], lineDataKeys: ["PPTR",], rowKeys: ["OPT", "OPP", "OPR", "OPD", "TPT", "TPP", "TPR", "TPD", "PPTT", "PPTP", "PPTR", "PPTD", "PPTB", "PPTBR", "PPTBD", "PPTRT", "PPTRP", "PPTRD",] },
  { barDataKeys: ["ETP", "", "ETT"], lineDataKeys: ["ETR",], rowKeys: ["OET", "OEP", "OER", "OED", "TET", "TEP", "TER", "TED", "ETT", "ETP", "ETR", "ETD", "ETB", "ETBR", "ETBD", "ETRT", "ETRP", "ETRD",] },
  { barDataKeys: ["KTP", "", "KTT"], lineDataKeys: ["KTR",], rowKeys: ["KTT", "KTP", "KTR", "KTD", "KRT", "KRP", "KRD",] },
  { barDataKeys: ["ZTP", "ZTB", "ZTT"], lineDataKeys: ["ZTR", "ZTBR"], rowKeys: ["BTT", "BTP", "BTR", "BTD", "BRT", "BRP", "BRD", "ATT", "ATP", "ATR", "ATD", "ART", "ARP", "ARD", "GTT", "GTP", "GTR", "GTD", "GTB", "GTBR", "GTBD", "GRT", "GRP", "GRD", "ZTT", "ZTP", "ZTR", "ZTD", "ZTB", "ZTBR", "ZTBD", "ZRT", "ZRP", "ZRD",] },
  { barDataKeys: ["ZCP", "", "ZCT"], lineDataKeys: ["ZCR",], rowKeys: ["ZCT", "ZCP", "ZCR", "ZCD", "ZCRT", "ZCRP", "ZCRD",] },
  { barDataKeys: ["CNEP", "", "CNET"], lineDataKeys: ["CNER"], rowKeys: ["CNDT", "CNDP", "CNDR", "CNDD", "CNDRT", "CNDRP", "CNDRD", "CNPT", "CNPP", "CNPR", "CNPD", "CNPRT", "CNPRP", "CNPRD", "CNGT", "CNGP", "CNGR", "CNGD", "CNGRT", "CNGRP", "CNGRD", "CNAT", "CNAP", "CNAR", "CNAD", "CNART", "CNARP", "CNARD", "CNET", "CNEP", "CNER", "CNED", "CNRT", "CNRP", "CNRD",] },
  { barDataKeys: ["SNEP", "", "SNET"], lineDataKeys: ["SNER"], rowKeys: ["SNET", "SNEP", "SNER", "SNED", "SNRT", "SNRP", "SNRD",] },
  { barDataKeys: ["ORPP", "", "ORPT"], lineDataKeys: ["ORPR"], rowKeys: ["ORST", "ORSP", "ORSR", "ORSD", "ORSRT", "ORSRP", "ORSRD", "ORPT", "ORPP", "ORPR", "ORPD", "ORPRT", "ORPRP", "ORPRD",] },
  { barDataKeys: ["SHP", "", "SHT"], lineDataKeys: ["SHR"], rowKeys: ["SHCT", "SHCP", "SHCR", "SHCD", "SHDT", "SHDP", "SHDR", "SHDD", "SHRCT", "SHRCP", "SHRCR", "SHRCD", "SHRDT", "SHRDP", "SHRDR", "SHRDD", "SHT", "SHP", "SHR", "SHD", ] },
  { barDataKeys: [], lineDataKeys: [], rowKeys: ["CCDT", "CDDP", "CDDR", "CDDD", "CDBT", "CDBP", "CDBR", "CDBD", "CDFT", "CDFP", "CDFR", "CDFD", "COCT", "COCP", "COCR", "COCD", "COPT", "COPP", "COPR", "COPD", "COET", "COEP", "COER", "COED", "CTPT", "CTPP", "CTPR", "CTPD", "CTST", "CTSP", "CTSR", "CTSD", "CTET", "CTEP", "CTER", "CTED",] },
];

//前年実績がマイナスの場合表示を変更する項目
export const preMinusList = [
  { cur: 'JJT', pre: 'JJP', key: 'JJR', },    //受注
  { cur: 'SLT', pre: 'SLP', key: 'SLR', },    //総売上配送売上
  { cur: 'SDT', pre: 'SDP', key: 'SDR', },    //総売上直送売上
  { cur: 'STT', pre: 'STP', key: 'STR', },    //総売上総売上
  { cur: 'PBLT', pre: 'PBLP', key: 'PBLR', }, //PB売上PB配送売上
  { cur: 'PBDT', pre: 'PBDP', key: 'PBDR', }, //PB売上PB直送売上
  { cur: 'PBTT', pre: 'PBTP', key: 'PBTR', }, //PB売上PB売上
  { cur: 'YHLT', pre: 'YHLP', key: 'YHLR', }, //焼半生売上焼半生配送売上
  { cur: 'YHDT', pre: 'YHDP', key: 'YHDR', }, //焼半生売上焼半生直送売上
  { cur: 'YHTT', pre: 'YHTP', key: 'YHTR', }, //焼半生売上焼半生売上
  { cur: 'NTT', pre: 'NTP', key: 'NTR', },    //純売上純売上
  { cur: 'CIT', pre: 'CIP', key: 'CIR', },    //修正入力修正
  { cur: 'CST', pre: 'CSP', key: 'CSR', },    //修正黒修正
  { cur: 'CTT', pre: 'CTP', key: 'CTR', },    //修正修正合計
  { cur: 'OET', pre: 'OEP', key: 'OER', },    //ＥＯＳ費・他ＥＯＳ費・他（相殺）
  { cur: 'TET', pre: 'TEP', key: 'TER', },    //ＥＯＳ費・他ＥＯＳ費・他（振込）
  { cur: 'ETT', pre: 'ETP', key: 'ETR', },    //ＥＯＳ費・他ＥＯＳ費・他
  { cur: 'BTT', pre: 'BTP', key: 'BTR', },    //総利益修正前企業粗利
  { cur: 'ATT', pre: 'ATP', key: 'ATR', },    //総利益修正後企業粗利
  { cur: 'GTT', pre: 'GTP', key: 'GTR', },    //総利益修正後粗利益
  { cur: 'ZTT', pre: 'ZTP', key: 'ZTR', },    //総利益売上総利益
  { cur: 'ZCT', pre: 'ZCP', key: 'ZCR', },    //企業粗利企業粗利
  { cur: 'CDBT', pre: 'CDBP', key: 'CDBR', }, //補正リベート補正
  { cur: 'CDFT', pre: 'CDFP', key: 'CDFR', }, //補正振込リベート補正
  { cur: 'COET', pre: 'COEP', key: 'COER', }, //補正ＥＯＳ費・他（相殺）補正
  { cur: 'CTST', pre: 'CTSP', key: 'CTSR', }, //補正業務委託費（振込）補正
  { cur: 'CTET', pre: 'CTEP', key: 'CTER', }, //補正ＥＯＳ費・他（振込）補正
];
//前年実績がプラスの場合表示を変更する項目
export const prePlusList = [
  { cur: 'JKT', pre: 'JKP', key: 'JKR', },    //欠品
  { cur: 'SRT', pre: 'SRP', key: 'SRR', },    //総売上返品
  { cur: 'PBRT', pre: 'PBRP', key: 'PBRR', }, //PB売上PB返品
  { cur: 'YHRT', pre: 'YHRP', key: 'YHRR', }, //焼半生売上焼半生返品
  { cur: 'DDT', pre: 'DDP', key: 'DDR', },    //値引値引
  { cur: 'DBT', pre: 'DBP', key: 'DBR', },    //値引リベート
  { cur: 'DQT', pre: 'DQP', key: 'DQR', },    //値引リベート建値
  { cur: 'DFT', pre: 'DFP', key: 'DFR', },    //値引振込リベート
  { cur: 'DTT', pre: 'DTP', key: 'DTR', },    //値引値引計
  { cur: 'OCT', pre: 'OCP', key: 'OCR', },    //センターフィセンターフィ（相殺）
  { cur: 'TST', pre: 'TSP', key: 'TSR', },    //センターフィ業務委託費（振込）
  { cur: 'CSTT', pre: 'CSTP', key: 'CSTR', }, //センターフィセンターフィ
  { cur: 'OPT', pre: 'OPP', key: 'OPR', },    //販促費販促費（相殺）
  { cur: 'TPT', pre: 'TPP', key: 'TPR', },    //販促費販促費（振込）
  { cur: 'PPTT', pre: 'PPTP', key: 'PPTR', }, //販促費販促費
  { cur: 'KTT', pre: 'KTP', key: 'KTR', },    //得意先課金計得意先課金計
  { cur: 'CCDT', pre: 'CDDP', key: 'CDDR', }, //補正値引補正
  { cur: 'COCT', pre: 'COCP', key: 'COCR', }, //補正センターフィ（相殺）補正
  { cur: 'COPT', pre: 'COPP', key: 'COPR', }, //補正販促費（相殺）補正
  { cur: 'CTPT', pre: 'CTPP', key: 'CTPR', }, //補正販促費（振込）補正
];


//列定義情報（動的パラメータ）
interface SalesListTransColRowModelInterface {
  //列固定
  fixedColumnsLeft: number,

  //テーブルの各列のキー
  colKeys: string[],
  //テーブルの各行のキー
  // rowKeysMonthly: string[],
  rowKeysMonthlyQuick: string[];
  rowKeysMonthlyCommit: string[];
  rowKeysDaily: string[],
  rowKeysWeekly: string[];
}

export class SalesListTransColRowModel implements SalesListTransColRowModelInterface {
  //列固定
  fixedColumnsLeft: number;
  //テーブルの各列のキー
  colKeys: string[];
  //テーブルの各列のキー
  // rowKeysMonthly: string[];
  rowKeysMonthlyQuick: string[];
  rowKeysMonthlyCommit: string[];
  rowKeysDaily: string[];
  rowKeysWeekly: string[];
  //ヘッダー
  nestedHeaders; //any[][]
  //テーブルの各列の逆引き用マップ
  colKeysMap;
  //テーブルの各行の逆引き用マップ
  // rowKeysMonthlyMap;
  rowKeysMonthlyQuickMap;
  rowKeysMonthlyCommitMap;
  rowKeysDailyMap;
  rowKeysWeeklyMap;

  constructor(param: SalesListTransColRowModelInterface) {
    this.fixedColumnsLeft = param.fixedColumnsLeft;
    this.colKeys = param.colKeys;
    // this.rowKeysMonthly = param.rowKeysMonthly;
    this.rowKeysMonthlyQuick = param.rowKeysMonthlyQuick;
    this.rowKeysMonthlyCommit = param.rowKeysMonthlyCommit;
    this.rowKeysDaily = param.rowKeysDaily;
    this.rowKeysWeekly = param.rowKeysWeekly;

    //テーブルの各列のキー逆引き
    const colKeysMap = {};
    param.colKeys.forEach((colKey, index) => {
      colKeysMap[colKey] = index;
    });
    this.colKeysMap = colKeysMap;
    //テーブルの各行のキー逆引き
    // const rowKeysMonthlyMap = {};
    const rowKeysMonthlyQuickMap = {};
    const rowKeysMonthlyCommitMap = {};
    const rowKeysDailyMap = {};
    const rowKeysWeeklyMap = {};
    // param.rowKeysMonthly.forEach((rowKey, index) => {
    //   rowKeysMonthlyMap[rowKey] = index;
    // });
    param.rowKeysMonthlyQuick.forEach((rowKey, index) => {
      rowKeysMonthlyQuickMap[rowKey] = index;
    });
    param.rowKeysMonthlyCommit.forEach((rowKey, index) => {
      rowKeysMonthlyCommitMap[rowKey] = index;
    });
    param.rowKeysWeekly.forEach((rowKey, index) => {
      rowKeysWeeklyMap[rowKey] = index;
    });
    param.rowKeysDaily.forEach((rowKey, index) => {
      rowKeysDailyMap[rowKey] = index;
    });
    // this.rowKeysMonthlyMap = rowKeysMonthlyMap;
    this.rowKeysMonthlyQuickMap = rowKeysMonthlyQuickMap;
    this.rowKeysMonthlyCommitMap = rowKeysMonthlyCommitMap;
    this.rowKeysDailyMap = rowKeysDailyMap;
    this.rowKeysWeeklyMap = rowKeysWeeklyMap;

    //nestedHeaders初期化
    this.initNestedHeaders();
  }

  //nestedHeaders初期化
  initNestedHeaders() {
    //nestedHeaders
    const nestedHeaders = [];
    // const customBorders = [];
    headersCol.forEach((header, index) => {
      const lastHeader = index == headersCol.length - 1;
      const nestedHeader = [];

      let colspan=0;
      let preHeaderVal = null;
      this.colKeys.forEach((colKey) => {
        const headerVal = header[colKey];
        if(lastHeader) {
          nestedHeader.push(headerVal);
        }
        else {
          if(preHeaderVal !== null && preHeaderVal != headerVal){
            nestedHeader.push(colspan == 1 ? preHeaderVal : {label: preHeaderVal, colspan: colspan});
            colspan=0;
          }
          colspan++;
          preHeaderVal = headerVal;
        }
      });
      if(!lastHeader && colspan > 0) {
        nestedHeader.push(colspan == 1 ? preHeaderVal : {label: preHeaderVal, colspan: colspan});
      }

      nestedHeaders.push(nestedHeader);
    });

    this.nestedHeaders = nestedHeaders;
  }

  //function
  keyFromCol(column:number):string {
    return this.colKeys[column];
  }
  keyFromCols(columns:number[]):string[] {
    const vals:string[] = [];
    columns.forEach(column => vals.push(this.colKeys[column]))
    return vals;
  }
  colFromKey(colKey:string):number {
    return this.colKeysMap[colKey];
  }
  colFromKeys(colKeys:string[]):number[] {
    const vals:number[] = [];
    // colKeys.forEach(colKey => vals.push(this.colKeysMap[colKey]))
    colKeys.forEach(colKey => {if(this.colKeysMap[colKey]) vals.push(this.colKeysMap[colKey])});
    return vals;
  }
  //function
  keyFromRow(row:number, dataType:"daily" | "quick" | "commit" | "weekly" | null):string {
    const rowKeys = 
      dataType == "quick" ? this.rowKeysMonthlyQuick : 
      dataType == "commit" ? this.rowKeysMonthlyCommit : 
      dataType == "daily" ? this.rowKeysDaily : 
      dataType == "weekly" ? this.rowKeysWeekly : 
      [];
    return rowKeys[row];
  }
  keyFromRows(rows:number[], dataType:"daily" | "quick" | "commit" | "weekly" | null):string[] {
    const rowKeys = 
      dataType == "quick" ? this.rowKeysMonthlyQuick : 
      dataType == "commit" ? this.rowKeysMonthlyCommit : 
      dataType == "daily" ? this.rowKeysDaily : 
      dataType == "weekly" ? this.rowKeysWeekly : 
      [];
    const vals:string[] = [];
    rows.forEach(row => vals.push(rowKeys[row]))
    return vals;
  }
  rowFromKey(rowKey:string, dataType:"daily" | "quick" | "commit" | "weekly" | null):number {
    const rowKeysMap = 
        dataType == "quick" ? this.rowKeysMonthlyQuickMap : 
        dataType == "commit" ? this.rowKeysMonthlyCommitMap : 
        dataType == "daily" ? this.rowKeysDailyMap : 
        dataType == "weekly" ? this.rowKeysWeeklyMap : 
        {};
    return rowKeysMap[rowKey];
  }
  rowFromKeys(rowKeys:string[], dataType:"daily" | "quick" | "commit" | "weekly" | null):number[] {
    const rowKeysMap = 
        dataType == "quick" ? this.rowKeysMonthlyQuickMap : 
        dataType == "commit" ? this.rowKeysMonthlyCommitMap : 
        dataType == "daily" ? this.rowKeysDailyMap : 
        dataType == "weekly" ? this.rowKeysWeeklyMap : 
        {};
    const vals:number[] = [];
    rowKeys.forEach(rowKey => vals.push(rowKeysMap[rowKey]))
    return vals;
  }
}

const colKeys: string[] = [
  "no",
  //以下に貼り付け
  "H1CD", "H1NM",
  ...colKeys45,
  "H2CD", "H2NM", "H3CD", "H3NM", "H4CD", "H4NM", "H5CD", "H5NM", "H6CD", "H6NM", "H7CD", "H7NM", "H8CD", "H8NM",
  "rowHeader1", "rowHeader2", "rowHeader3",
  "TTL",
  "T01", "T02", "T03", "T04", "T05", "T06", "T07", "T08", "T09", "T10",
  "T11", "T12", "T13", "T14", "T15", "T16", "T17", "T18", "T19", "T20",
  "T21", "T22", "T23", "T24", "T25", "T26", "T27", "T28", "T29", "T30",
  "T31", "T32", "T33", "T34", "T35", "T36", "T37", "T38", "T39", "T40",
  "T41", "T42", "T43", "T44", "T45", "T46", "T47", "T48", "T49", "T50",
  "T51", "T52", "T53",
];
//センター経費
export const colKeysKeihi: string[] = [
  "CNDT", "CNDP", "CNDR", "CNDD", "CNDRT", "CNDRP", "CNDRD",
  "CNPT", "CNPP", "CNPR", "CNPD", "CNPRT", "CNPRP", "CNPRD",
  "CNGT", "CNGP", "CNGR", "CNGD", "CNGRT", "CNGRP", "CNGRD",
  "CNAT", "CNAP", "CNAR", "CNAD", "CNART", "CNARP", "CNARD",
  "CNET", "CNEP", "CNER", "CNED", "CNRT", "CNRP", "CNRD",
  "SNET", "SNEP", "SNER", "SNED", "SNRT", "SNRP", "SNRD",
  "ORST", "ORSP", "ORSR", "ORSD", "ORSRT", "ORSRP", "ORSRD",
  "ORPT", "ORPP", "ORPR", "ORPD", "ORPRT", "ORPRP", "ORPRD",
];
//仕入実績
export const colKeysShiire: string[] = [
  "SHCT", "SHCP", "SHCR", "SHCD", 
  "SHDT", "SHDP", "SHDR", "SHDD", 
  "SHRCT", "SHRCP", "SHRCR", "SHRCD", 
  "SHRDT", "SHRDP", "SHRDR", "SHRDD", 
  "SHT", "SHP", "SHR", "SHD",
];
const rowKeysMonthlyQuick: string[] = [
  "JJT", "JJP", "JJR", "JJD",

  "SLT", "SLP", "SLR", "SLD",
  "SDT", "SDP", "SDR", "SDD",
  "SRT", "SRP", "SRR", "SRD",
  "STT", "STP", "STR", "STD", "STB", "STBR", "STBD",
  "PBLT", "PBLP", "PBLR", "PBLD",
  "PBDT", "PBDP", "PBDR", "PBDD",
  "PBRT", "PBRP", "PBRR", "PBRD",
  "PBTT", "PBTP", "PBTR", "PBTD",
  "PBHT", "PBHP", "PBHD",
  "YHLT", "YHLP", "YHLR", "YHLD",
  "YHDT", "YHDP", "YHDR", "YHDD",
  "YHRT", "YHRP", "YHRR", "YHRD",
  "YHTT", "YHTP", "YHTR", "YHTD",
  "YHHT", "YHHP", "YHHD",
  "PTT", "PTP", "PTR", "PTD", "PTB", "PTBR", "PTBD",
  "PRT", "PRP", "PRD",

  "JKT", "JKP", "JKR", "JKD",
  "JRT", "JRP", "JRD",
  //欠品責任別
  "KI1KA", "KI1KR", "KI1KK",
  "KI2KA", "KI2KR", "KI2KK",
  "KI3KA", "KI3KR", "KI3KK",

  "NTT", "NTP", "NTR", "NTD", "NTB", "NTBR", "NTBD",
  "CIT", "CIP", "CIR", "CID",
  "CST", "CSP", "CSR", "CSD",
  "CTT", "CTP", "CTR", "CTD", "CTB", "CTBR", "CTBD",

  "DDT", "DDP", "DDR", "DDD",
  "DBT", "DBP", "DBR", "DBD",
  "DQT", "DQP", "DQR", "DQD",
  "DFT", "DFP", "DFR", "DFD",
  "DTT", "DTP", "DTR", "DTD", "DTB", "DTBR", "DTBD",
  "DRT", "DRP", "DRD",

  "BTT", "BTP", "BTR", "BTD",
  "BRT", "BRP", "BRD",
  "ATT", "ATP", "ATR", "ATD",
  "ART", "ARP", "ARD",
  "GTT", "GTP", "GTR", "GTD", "GTB", "GTBR", "GTBD",
  "GRT", "GRP", "GRD",
  "ZTT", "ZTP", "ZTR", "ZTD", "ZTB", "ZTBR", "ZTBD",
  "ZRT", "ZRP", "ZRD",

  "OCT", "OCP", "OCR", "OCD",
  "TST", "TSP", "TSR", "TSD",
  "CSTT", "CSTP", "CSTR", "CSTD", "CSTB", "CSTBR", "CSTBD",
  "CSTRT", "CSTRP", "CSTRD",
  "OPT", "OPP", "OPR", "OPD",
  "TPT", "TPP", "TPR", "TPD",
  "PPTT", "PPTP", "PPTR", "PPTD", "PPTB", "PPTBR", "PPTBD",
  "PPTRT", "PPTRP", "PPTRD",
  "OET", "OEP", "OER", "OED",
  "TET", "TEP", "TER", "TED",
  "ETT", "ETP", "ETR", "ETD", "ETB", "ETBR", "ETBD",
  "ETRT", "ETRP", "ETRD",

  "CCDT", "CDDP", "CDDR", "CDDD",
  "CDBT", "CDBP", "CDBR", "CDBD",
  "CDFT", "CDFP", "CDFR", "CDFD",
  "COCT", "COCP", "COCR", "COCD",
  "COPT", "COPP", "COPR", "COPD",
  "COET", "COEP", "COER", "COED",
  "CTPT", "CTPP", "CTPR", "CTPD",
  "CTST", "CTSP", "CTSR", "CTSD",
  "CTET", "CTEP", "CTER", "CTED",

  "KTT", "KTP", "KTR", "KTD",
  "KRT", "KRP", "KRD",

  "ZCT", "ZCP", "ZCR", "ZCD",
  "ZCRT", "ZCRP", "ZCRD",

  "K10KA", "K10KR", "K10KK",
  "K11KA", "K11KR", "K11KK",
  "K12KA", "K12KR", "K12KK",
  // "K13KA", "K13KR", "K13KK",
  "K14KA", "K14KR", "K14KK",
  "K15KA", "K15KR", "K15KK",
  "K16KA", "K16KR", "K16KK",
  "K17KA", "K17KR", "K17KK",
  "K19KA", "K19KR", "K19KK",

  "K20KA", "K20KR", "K20KK",
  "K21KA", "K21KR", "K21KK",
  "K22KA", "K22KR", "K22KK",
  "K29KA", "K29KR", "K29KK",

  "K30KA", "K30KR", "K30KK",
  "K31KA", "K31KR", "K31KK",
  // "K32KA", "K32KR", "K32KK",
  // "K33KA", "K33KR", "K33KK",
  "K34KA", "K34KR", "K34KK",
  // "K39KA", "K39KR", "K39KK",
  "K40KA", "K40KR", "K40KK",
  "K41KA", "K41KR", "K41KK",
  "K42KA", "K42KR", "K42KK",
  "K43KA", "K43KR", "K43KK",
  // "K44KA", "K44KR", "K44KK",
  // "K49KA", "K49KR", "K49KK",
  "K50KA", "K50KR", "K50KK",  
];
const rowKeysMonthlyCommit: string[] = [
  // "JJT", "JJP", "JJR", "JJD",

  "SLT", "SLP", "SLR", "SLD",
  "SDT", "SDP", "SDR", "SDD",
  "SRT", "SRP", "SRR", "SRD",
  "STT", "STP", "STR", "STD", "STB", "STBR", "STBD",
  "PBLT", "PBLP", "PBLR", "PBLD",
  "PBDT", "PBDP", "PBDR", "PBDD",
  "PBRT", "PBRP", "PBRR", "PBRD",
  "PBTT", "PBTP", "PBTR", "PBTD",
  "PBHT", "PBHP", "PBHD",
  "YHLT", "YHLP", "YHLR", "YHLD",
  "YHDT", "YHDP", "YHDR", "YHDD",
  "YHRT", "YHRP", "YHRR", "YHRD",
  "YHTT", "YHTP", "YHTR", "YHTD",
  "YHHT", "YHHP", "YHHD",
  "PTT", "PTP", "PTR", "PTD", "PTB", "PTBR", "PTBD",
  "PRT", "PRP", "PRD",

  // "JKT", "JKP", "JKR", "JKD",
  // "JRT", "JRP", "JRD", 
  // //欠品責任別
  // "KI1KA", "KI1KR",
  // "KI2KA", "KI2KR",
  // "KI3KA", "KI3KR",

  "NTT", "NTP", "NTR", "NTD", "NTB", "NTBR", "NTBD",
  "CIT", "CIP", "CIR", "CID",
  "CST", "CSP", "CSR", "CSD",
  "CTT", "CTP", "CTR", "CTD", "CTB", "CTBR", "CTBD",

  "DDT", "DDP", "DDR", "DDD",
  "DBT", "DBP", "DBR", "DBD",
  "DQT", "DQP", "DQR", "DQD",
  "DFT", "DFP", "DFR", "DFD",
  "DTT", "DTP", "DTR", "DTD", "DTB", "DTBR", "DTBD",
  "DRT", "DRP", "DRD",

  "BTT", "BTP", "BTR", "BTD",
  "BRT", "BRP", "BRD",
  "ATT", "ATP", "ATR", "ATD",
  "ART", "ARP", "ARD",
  "GTT", "GTP", "GTR", "GTD", "GTB", "GTBR", "GTBD",
  "GRT", "GRP", "GRD",
  "ZTT", "ZTP", "ZTR", "ZTD", "ZTB", "ZTBR", "ZTBD",
  "ZRT", "ZRP", "ZRD",

  "OCT", "OCP", "OCR", "OCD",
  "TST", "TSP", "TSR", "TSD",
  "CSTT", "CSTP", "CSTR", "CSTD", "CSTB", "CSTBR", "CSTBD",
  "CSTRT", "CSTRP", "CSTRD",
  "OPT", "OPP", "OPR", "OPD",
  "TPT", "TPP", "TPR", "TPD",
  "PPTT", "PPTP", "PPTR", "PPTD", "PPTB", "PPTBR", "PPTBD",
  "PPTRT", "PPTRP", "PPTRD",
  "OET", "OEP", "OER", "OED",
  "TET", "TEP", "TER", "TED",
  "ETT", "ETP", "ETR", "ETD", "ETB", "ETBR", "ETBD",
  "ETRT", "ETRP", "ETRD",

  "CCDT", "CDDP", "CDDR", "CDDD",
  "CDBT", "CDBP", "CDBR", "CDBD",
  "CDFT", "CDFP", "CDFR", "CDFD",
  "COCT", "COCP", "COCR", "COCD",
  "COPT", "COPP", "COPR", "COPD",
  "COET", "COEP", "COER", "COED",
  "CTPT", "CTPP", "CTPR", "CTPD",
  "CTST", "CTSP", "CTSR", "CTSD",
  "CTET", "CTEP", "CTER", "CTED",

  "KTT", "KTP", "KTR", "KTD",
  "KRT", "KRP", "KRD",

  "ZCT", "ZCP", "ZCR", "ZCD",
  "ZCRT", "ZCRP", "ZCRD",

  ...colKeysKeihi,
  ...colKeysShiire,

  /*
  //欠品区分別
  "K10KA", "K10KR",
  "K11KA", "K11KR",
  "K12KA", "K12KR",
  // "K13KA", "K13KR",
  "K14KA", "K14KR",
  "K15KA", "K15KR",
  "K16KA", "K16KR",
  "K17KA", "K17KR",
  "K19KA", "K19KR",

  "K20KA", "K20KR",
  "K21KA", "K21KR",
  "K22KA", "K22KR",
  "K29KA", "K29KR",

  "K30KA", "K30KR",
  "K31KA", "K31KR",
  // "K32KA", "K32KR",
  // "K33KA", "K33KR",
  "K34KA", "K34KR",
  // "K39KA", "K39KR",
  "K40KA", "K40KR",
  "K41KA", "K41KR",
  "K42KA", "K42KR",
  "K43KA", "K43KR",
  // "K44KA", "K44KR",
  // "K49KA", "K49KR",
  "K50KA", "K50KR",  
 */

];
const rowKeysWeekly: string[] = [
  "JJT", "JJP", "JJR", "JJD",

  "SLT", "SLP", "SLR", "SLD",
  "SDT", "SDP", "SDR", "SDD",
  "SRT", "SRP", "SRR", "SRD",
  "STT", "STP", "STR", "STD", //"STB", "STBR", "STBD",
  // "PBLT", "PBLP", "PBLR", "PBLD",
  // "PBDT", "PBDP", "PBDR", "PBDD",
  // "PBRT", "PBRP", "PBRR", "PBRD",
  // "PBTT", "PBTP", "PBTR", "PBTD",
  // "PBHT", "PBHP", "PBHD",
  // "YHLT", "YHLP", "YHLR", "YHLD",
  // "YHDT", "YHDP", "YHDR", "YHDD",
  // "YHRT", "YHRP", "YHRR", "YHRD",
  // "YHTT", "YHTP", "YHTR", "YHTD",
  // "YHHT", "YHHP", "YHHD",
  "PTT", "PTP", "PTR", "PTD", //"PTB", "PTBR", "PTBD",
  "PRT", "PRP", "PRD",

  "JKT", "JKP", "JKR", "JKD",
  "JRT", "JRP", "JRD",
  //欠品責任別
  // "KI1KA", "KI1KR", "KI1KK",
  // "KI2KA", "KI2KR", "KI2KK",
  // "KI3KA", "KI3KR", "KI3KK",

  "NTT", "NTP", "NTR", "NTD", //"NTB", "NTBR", "NTBD",
  // "CIT", "CIP", "CIR", "CID",
  // "CST", "CSP", "CSR", "CSD",
  // "CTT", "CTP", "CTR", "CTD", //"CTB", "CTBR", "CTBD",

  // "DDT", "DDP", "DDR", "DDD",
  // "DBT", "DBP", "DBR", "DBD",
  // "DQT", "DQP", "DQR", "DQD",
  // "DFT", "DFP", "DFR", "DFD",
  // "DTT", "DTP", "DTR", "DTD", //"DTB", "DTBR", "DTBD",
  // "DRT", "DRP", "DRD",

  // "BTT", "BTP", "BTR", "BTD",
  // "BRT", "BRP", "BRD",
  // "ATT", "ATP", "ATR", "ATD",
  // "ART", "ARP", "ARD",
  // "GTT", "GTP", "GTR", "GTD", //"GTB", "GTBR", "GTBD",
  // "GRT", "GRP", "GRD",
  // "ZTT", "ZTP", "ZTR", "ZTD", //"ZTB", "ZTBR", "ZTBD",
  // "ZRT", "ZRP", "ZRD",

  // "OCT", "OCP", "OCR", "OCD",
  // "TST", "TSP", "TSR", "TSD",
  // "CSTT", "CSTP", "CSTR", "CSTD", //"CSTB", "CSTBR", "CSTBD",
  // "CSTRT", "CSTRP", "CSTRD",
  // "OPT", "OPP", "OPR", "OPD",
  // "TPT", "TPP", "TPR", "TPD",
  // "PPTT", "PPTP", "PPTR", "PPTD", //"PPTB", "PPTBR", "PPTBD",
  // "PPTRT", "PPTRP", "PPTRD",
  // "OET", "OEP", "OER", "OED",
  // "TET", "TEP", "TER", "TED",
  // "ETT", "ETP", "ETR", "ETD", //"ETB", "ETBR", "ETBD",
  // "ETRT", "ETRP", "ETRD",

  // "CCDT", "CDDP", "CDDR", "CDDD",
  // "CDBT", "CDBP", "CDBR", "CDBD",
  // "CDFT", "CDFP", "CDFR", "CDFD",
  // "COCT", "COCP", "COCR", "COCD",
  // "COPT", "COPP", "COPR", "COPD",
  // "COET", "COEP", "COER", "COED",
  // "CTPT", "CTPP", "CTPR", "CTPD",
  // "CTST", "CTSP", "CTSR", "CTSD",
  // "CTET", "CTEP", "CTER", "CTED",

  // "KTT", "KTP", "KTR", "KTD",
  // "KRT", "KRP", "KRD",

  // "ZCT", "ZCP", "ZCR", "ZCD",
  // "ZCRT", "ZCRP", "ZCRD",

  // "K10KA", "K10KR", "K10KK",
  // "K11KA", "K11KR", "K11KK",
  // "K12KA", "K12KR", "K12KK",
  // // "K13KA", "K13KR", "K13KK",
  // "K14KA", "K14KR", "K14KK",
  // "K15KA", "K15KR", "K15KK",
  // "K16KA", "K16KR", "K16KK",
  // "K17KA", "K17KR", "K17KK",
  // "K19KA", "K19KR", "K19KK",

  // "K20KA", "K20KR", "K20KK",
  // "K21KA", "K21KR", "K21KK",
  // "K22KA", "K22KR", "K22KK",
  // "K29KA", "K29KR", "K29KK",

  // "K30KA", "K30KR", "K30KK",
  // "K31KA", "K31KR", "K31KK",
  // // "K32KA", "K32KR", "K32KK",
  // // "K33KA", "K33KR", "K33KK",
  // "K34KA", "K34KR", "K34KK",
  // // "K39KA", "K39KR", "K39KK",
  // "K40KA", "K40KR", "K40KK",
  // "K41KA", "K41KR", "K41KK",
  // "K42KA", "K42KR", "K42KK",
  // "K43KA", "K43KR", "K43KK",
  // // "K44KA", "K44KR", "K44KK",
  // // "K49KA", "K49KR", "K49KK",
  // "K50KA", "K50KR", "K50KK",  
];

const rowKeysDaily: string[] = [
  "STT", "STP", "STR", "STD",
  "PTT", "PTP", "PTR", "PTD",
  "PRT", "PRP", "PRD",
  "KKT", "KKR",
  "PBT", "PBR",
];

//SalesListTrans用の列モデル
export const salesListTransColRowModelDefault:SalesListTransColRowModel = new SalesListTransColRowModel({
  //列固定
  fixedColumnsLeft: 20,

  //テーブルの各列のキー
  colKeys: arrayUtil.not(colKeys, colKeys45),
  //テーブルの各行のキー
  rowKeysMonthlyQuick: rowKeysMonthlyQuick,
  rowKeysMonthlyCommit: rowKeysMonthlyCommit,
  rowKeysDaily: rowKeysDaily,
  rowKeysWeekly: rowKeysWeekly,
});

export const salesListTransColRowModelReport11:SalesListTransColRowModel = new SalesListTransColRowModel({
  //列固定
  fixedColumnsLeft: 20,

  //テーブルの各列のキー
  colKeys: arrayUtil.not(colKeys, colKeys45),
  //テーブルの各行のキー
  rowKeysMonthlyQuick: [],
  rowKeysMonthlyCommit: [],
  rowKeysDaily: rowKeysDaily,
  rowKeysWeekly: [],
});

export const salesListTransColRowModelReport15:SalesListTransColRowModel = new SalesListTransColRowModel({
  //列固定
  fixedColumnsLeft: 20,

  //テーブルの各列のキー
  colKeys: arrayUtil.not(colKeys, colKeys45),
  //テーブルの各行のキー
  rowKeysMonthlyQuick: rowKeysMonthlyQuick,
  rowKeysMonthlyCommit: [],
  rowKeysDaily: [],
  rowKeysWeekly: [],
});

export const salesListTransColRowModelReport16:SalesListTransColRowModel = new SalesListTransColRowModel({
  //列固定
  fixedColumnsLeft: 20,

  //テーブルの各列のキー
  colKeys: arrayUtil.not(colKeys, colKeys45),
  //テーブルの各行のキー
  rowKeysMonthlyQuick: [],
  rowKeysMonthlyCommit: [],
  rowKeysDaily: [],
  rowKeysWeekly: rowKeysWeekly,
});

export const salesListTransColRowModelReport35:SalesListTransColRowModel = new SalesListTransColRowModel({
  //列固定
  fixedColumnsLeft: 20,

  //テーブルの各列のキー
  colKeys: arrayUtil.not(colKeys, colKeys45),
  //テーブルの各行のキー
  rowKeysMonthlyQuick: [],
  rowKeysMonthlyCommit: rowKeysMonthlyCommit,
  rowKeysDaily: [],
  rowKeysWeekly: [],
});

export const salesListTransColRowModelReport45:SalesListTransColRowModel = new SalesListTransColRowModel({
  //列固定
  fixedColumnsLeft: 20 + 2,

  //テーブルの各列のキー
  colKeys: colKeys,
  //テーブルの各行のキー
  rowKeysMonthlyQuick: [],
  rowKeysMonthlyCommit: rowKeysMonthlyCommit,
  rowKeysDaily: [],
  rowKeysWeekly: [],
});
