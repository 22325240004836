import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {colsFromKeys} from "@/components/tokubai/input/TokubaiInputTableModel";

import {CodeName} from "@/store/common";

export const defaultHiddenColumnsKey = [
  //以下に貼り付け
  "ISC",
  "IMC",
  "ICC", "ICN",
  "IBC", "IBN",
  "ILI",
  "IPR",
  "ISD",
  "IED",
  "SCD",
  "OSCD",
  "TCD",
  "ONT",
  "OLT",
];


//Page State
export type State = {
  //依頼日
  // requestDate: Date,

  //企業グループ１
  group1: CodeName | null,

  //倉庫
  centersIndex: number,
  centersName: string[] | null,
  centers: CodeName[][] | null,
  // centerList: CodeName[],

  // //検索終了した条件
  // requestedParam : RequestParam,

  sort: {key:string, asc:boolean},
  hiddenColumns: number[],
  hiddenColumnsKey: string[],

};

export const initialState: State = {
  //依頼日
  // requestDate: null,

  //企業グループ１
  group1: null,

  //倉庫
  centersIndex: 0,
  centersName: ['定番マスタ全て'],
  centers: [[]],
  // centerList: [],

  // requestedParam : {},

  sort: {
    key: "STT", //総売上本年実績
    asc: false, //降順
  },
  hiddenColumns: [],
  hiddenColumnsKey: defaultHiddenColumnsKey,
};

//Page Slice
export const tokubaiInputSaveSlice = createSlice({
  name: "tokubaiInputSave",
  initialState,
  reducers: {
    //componentDidMount
    initOnDidMount(state:State) {
      // //requestDateは今日にする
      // state.requestDate = new Date();
      //追加属性等はlocalstorageから復元したときにundefinedになっている場合があるためチェックする
      //追加属性等はlocalstorageから復元したときにundefinedになっている場合があるためチェックする
      if(!state.group1) {
        state.group1 = initialState.group1;
      }

      if(!state.centersIndex) {
        state.centersIndex = initialState.centersIndex;
      }
      if(!state.centersName || state.centersName.length == 0) {
        state.centersName = [...initialState.centersName];
      }
      //0番目のみ必ず上書き
      if(state.centersName && state.centersName.length > 0) {
        state.centersName[0] = initialState.centersName[0];
      }

      if(!state.centers || state.centers.length == 0) {
        state.centers = initialState.centers;
      }

      if(!state.sort || !state.sort.key) {
        state.sort = initialState.sort;
      }
      if(!state.hiddenColumnsKey) {
        state.hiddenColumnsKey = initialState.hiddenColumnsKey;
      }

    },

    // Option Group1
    setGroup1(state:State, action: PayloadAction<CodeName>) {
      state.group1 = action.payload;
    },
    setGroup1List(state:State, action: PayloadAction<CodeName[]>) {
      const group1List = action.payload;
      let group1 = state.group1 && state.group1.code? group1List.find((group1) => group1.code == state.group1.code) : null;
      if(!group1) {
        group1 = group1List[0];
      }
      state.group1 = group1;
    },

    clearOption(state:State, action: PayloadAction<{
      group1List:CodeName[],
    }>) {
      state.group1 = action.payload.group1List[0];
    },


    // //検索条件
    // setRequestedParam(state:State, action: PayloadAction<RequestParam>) {
    //   state.requestedParam = action.payload;
    // },

    setSort(state:State, action: PayloadAction<{sortKey:string, sortAsc: boolean}>) {
      const key = action.payload.sortKey;
      const asc = action.payload.sortAsc;
      state.sort = {
        key: key,
        asc: asc,
      }
    },
    hideColumnsChange(state:State, action: PayloadAction<{columns:number[], keys:string[]}>){
      console.log('store.hideColumnsChange');
      Object.assign(state, {
        hiddenColumns: action.payload.columns,
        hiddenColumnsKey: action.payload.keys,
      });
    },
    hideColumnKeysChange(state:State, action: PayloadAction<string[]>){
      console.log('store.hideColumnKeysChange');
      Object.assign(state, {
        hiddenColumns: colsFromKeys(action.payload),
        hiddenColumnsKey: action.payload,
      });
    },
    // Option Center
    setCenters(state:State, action: PayloadAction<{centers:CodeName[][], centersName:string[], centersIndex:number}>) {
      console.log('store.setCenters');
      //重複を除く
      // let centerCDs:string[] = cs.map(center => center.code);

      Object.assign(state, {
        centers: action.payload.centers,
        centersName: action.payload.centersName,
        centersIndex: action.payload.centersIndex,
      });

    },
    setCenterList(state:State, action: PayloadAction<CodeName[]>) {
      const centerList = action.payload;
      const centers = [];
      if(state.centers && state.centers.length > 0) {
        state.centers.forEach(cs => {
          const centerCDs:string[] = cs.map(center => center.code);
          const centersSub = [];
          centerCDs.forEach(centerCD => {
            const center = centerList.find(center => centerCD == center.code);
            if(center) {
              centersSub.push(center);
            }
          });
          centers.push(centersSub);
        });
      }
      state.centers = centers;
    },

  },
});
