import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { colFromKeys } from "@/components/itemlist/ItemListZaikoModel";

export const defaultHiddenColumnsKey = [
  //以下に貼り付け
  'SKIN1', 'SKIN2', 'SKIN3', 'SKIN', 'SKINR', 'HKIN1', 'HKIN2', 'HKIN3', 'HKIN', 'HKINR', 'RAMT', 'TAMT', 'YAMT', 'FAMT', 'AAMT', 'ZAMT',
];

//Page State
export type ItemListZaikoSaveState = {
  sort: {key:string, asc:boolean},
  quantitySort: {key:string, asc:boolean},
  amountSort: {key:string, asc:boolean},

  hiddenColumns: number[],
  hiddenColumnsKey: string[],
  notationType: "quantity" | "amount" | null,
};

export const initialState: ItemListZaikoSaveState = {
  sort: null,
  quantitySort: {
    key: "SQTY", //仕入数累計
    asc: false, //降順
  },
  amountSort: {
    key: "SKIN", //建値仕入累計
    asc: false, //降順
  },
  hiddenColumns: [],
  hiddenColumnsKey: defaultHiddenColumnsKey,
  notationType: "quantity",
};

//Page Slice
const createSliceContent = (name:string) => createSlice({
  name: name,
  initialState,
  reducers: {
    //componentDidMount
    initOnDidMount(state:ItemListZaikoSaveState) {
      //追加属性等はlocalstorageから復元したときにundefinedになっている場合があるためチェックする
      if(!state.quantitySort || !state.quantitySort.key) {
        state.quantitySort = initialState.quantitySort;
      }
      if(!state.amountSort || !state.amountSort.key) {
        state.amountSort = initialState.amountSort;
      }
      if(!state.sort || !state.sort.key) {
        state.sort = initialState.sort;
      }
      if(!state.hiddenColumnsKey) {
        state.hiddenColumnsKey = initialState.hiddenColumnsKey;
      }
      if(state.notationType === undefined) {
        state.notationType = initialState.notationType;
      }
      state.sort = state.notationType == 'quantity' ? state.quantitySort : state.amountSort;
    },
    setSort(state:ItemListZaikoSaveState, action: PayloadAction<{sortKey:string, sortAsc: boolean}>) {
      console.log('zaiko setSort');
      const key = action.payload.sortKey;
      const asc = action.payload.sortAsc;
      state.sort = {
        key: key,
        asc: asc,
      }
    },
    setQuantitySort(state:ItemListZaikoSaveState, action: PayloadAction<{sortKey:string, sortAsc: boolean}>) {
      console.log('zaiko setQuantitySort');
      const key = action.payload.sortKey;
      const asc = action.payload.sortAsc;
      state.quantitySort = {
        key: key,
        asc: asc,
      }
      state.sort =  state.quantitySort ;
    },
    setAmountSort(state:ItemListZaikoSaveState, action: PayloadAction<{sortKey:string, sortAsc: boolean}>) {
      console.log('zaiko setAmountSort');
      const key = action.payload.sortKey;
      const asc = action.payload.sortAsc;
      state.amountSort = {
        key: key,
        asc: asc,
      }
      state.sort =  state.amountSort ;
    },
    hideColumnsChange(state:ItemListZaikoSaveState, action: PayloadAction<{columns:number[], keys:string[]}>){
      Object.assign(state, {
        hiddenColumns: action.payload.columns,
        hiddenColumnsKey: action.payload.keys,
      });
    },
    hideColumnKeysChange(state:ItemListZaikoSaveState, action: PayloadAction<string[]>){
      Object.assign(state, {
        hiddenColumns: colFromKeys(action.payload),
        hiddenColumnsKey: action.payload,
      });
    },
    setNotationType(state:ItemListZaikoSaveState, action: PayloadAction<"quantity" | "amount">) {
      state.notationType = action.payload;
    },
  },
});

//Page Slice Export
//itemListZaikoSave
export const itemListZaikoSaveSlice = createSliceContent("itemListZaikoSave");


