import moment from "moment";
moment.updateLocale("ja", {
  months: ["1月","2月","3月","4月","5月","6月","7月","8月","9月","10月","11月","12月",],
  weekdays: ["日曜日", "月曜日", "火曜日", "水曜日", "木曜日", "金曜日", "土曜日"],
  weekdaysShort: ["日", "月", "火", "水", "木", "金", "土"]
});

//テーブルの各列のキー
export const colKeysNo_data: string[] = [
  "no1",
];
export const colKeysNo_detail: string[] = [
  "no2",
];
export const colKeysData_fix: string[] = [
  //以下に貼り付け
  "IJN", "IJC", "ICD", "INM",
];
export const colKeysDetail_fix: string[] = [
  //以下に貼り付け
  "SCD", "SNM",
  "OSCD", "OSNM",
  "TCD", "TNM","TDR",
];
export const colKeysData_unfix: string[] = [
  //以下に貼り付け
  "ISC", "ISN", "IMC", "IMN", "ICC", "ICN", "IBC", "IBN", "ICP", "ILI", "IPR", "ISD", "IED", "IC1", "IC2", "ITN", 
];
export const colKeysDetail_unfix: string[] = [
  //以下に貼り付け
  "OOS",
  "KFR","KTO",
  "HPL",
  "OFS", "OND", "OOU", "OON", "ONOA", "OOZ", "ONOB", "OFN", "OAN", "OLDA", "OLDB", "OLT", "OMM", "OME", "ONT",
  "RVM",
  "TBN", "ZTN", "ZQ1", "ZQ2", "ZQ3",
];
export const colKeys: string[] = [
  ...colKeysNo_data,
  ...colKeysNo_detail,
  ...colKeysData_fix,
  ...colKeysDetail_fix,
  ...colKeysData_unfix,
  ...colKeysDetail_unfix,
];

//行固定
export const fixedRowsTop = 0;
//列固定
export const fixedColumnsLeft = colKeysNo_data.length + colKeysNo_detail.length + colKeysData_fix.length + colKeysDetail_fix.length;

//テーブルの各列のキー逆引き
const colsKeysMap = {};
colKeys.forEach((colKey, index) => {
  let cols:number[] = colsKeysMap[colKey];
  if(!cols){
    cols = [index];
  }
  else {
    cols.push(index);
  }
  colsKeysMap[colKey] = cols;
});

export const headers = [
  {
    no1 : "No.", no2 : "No.",
  //以下に貼り付け
  IJN: "商品指定", IJC: "商品指定", ICD: "商品指定", INM: "商品指定",
  ISC: "商品属性", ISN: "商品属性", IMC: "商品属性", IMN: "商品属性", ICC: "商品属性", ICN: "商品属性", IBC: "商品属性", IBN: "商品属性", ICP: "商品属性", IC1: "商品属性", IC2: "商品属性", ITN: "商品属性", ILI: "商品属性", IPR: "商品属性", ISD: "商品属性", IED: "商品属性",
  SCD: "倉庫", SNM: "倉庫",
  OSCD: "倉庫", OSNM: "倉庫",
  TCD: "納入先", TNM: "納入先", TDR: "納入先",
  OOS: "ｽﾎﾟｯﾄ",
  KFR: "特売期間", KTO: "特売期間",
  HPL: "販売計画",
  OFS: "発注情報", OND: "発注情報", OOU: "発注情報", OON: "発注情報", ONOA: "発注情報", OOZ: "発注情報", ONOB: "発注情報", OFN: "発注情報", OAN: "発注情報", OLDA: "発注情報", OLDB: "発注情報", OLT: "発注情報", OMM: "発注情報", OME: "発注情報", ONT: "発注情報",
  RVM: "受信管理",
  TBN: "定番", ZTN: "在庫情報", ZQ1: "在庫情報", ZQ2: "在庫情報", ZQ3: "在庫情報",
  },
  {
    no1 : "<br/><br/>#", no2 : "<br/><br/>Sub",
  //以下に貼り付け
  IJN: "<br/><br/>JAN <font color='red'>*</font>",
  IJC: "同一<br/>JAN<br/>数", ICD: "<br/><br/>商品CD <font color='red'>*</font>", INM: "<br/><br/>商品名",
  ISC: "<br/>仕入先<br/>CD", ISN: "<br/>仕入先<br/>名称", IMC: "<br/>ﾒｰｶｰ<br/>CD", IMN: "<br/>ﾒｰｶｰ<br/>名称", ICC: "<br/>ｶﾃｺﾞﾘ<br/>CD", ICN: "<br/>ｶﾃｺﾞﾘ<br/>名称", IBC: "<br/>PB区分<br/>CD", IBN: "<br/>PB区分<br/>名称", ICP: "<br/><br/>内容量", IC1: "<br/>BL<br/>入数", IC2: "<br/>CS<br/>入数", ITN: "<br/>発注<br/>単位", ILI: "<br/>賞味<br/>期間", IPR: "<br/><br/>定価", ISD: "<br/><br/>発売日", IED: "<br/><br/>終売日",
  SCD: "<br/>倉庫<br/>CD", SNM: "<br/>倉庫<br/>名称",
  OSCD: "発注<br/>倉庫<br/>CD", OSNM: "発注<br/>倉庫<br/>名称",
  TCD: "<br/>納入先<br/>CD", TNM: "<br/>納入先<br/>名称", TDR: "<br/><br/>直送",
  OOS: "<br/><br/>ｽﾎﾟｯﾄ",
  KFR: "<br/><br/>出荷開始日 <font color='red'>*</font>", KTO: "<br/><br/>出荷終了日 <font color='red'>*</font>",
  HPL: "<br/>販売計画<br/>BL数",
  OFS: "<br/><br/>出荷日 <font color='red'>*</font>", OND: "<br/><br/>納品日 <font color='red'>*</font>", OOU: "<br/>入庫<br/>単位 <font color='red'>*</font>", OON: "<br/>送込BL数<br/>(A)", ONOA: "余り<br/>BL数<br/>(A)", OOZ: "送込以降の<br/>必要BL数<br/>(B)", ONOB: "余り<br/>BL数<br/>(B)", OFN: "初回準備<br/>BL数<br/>(A+B)", OAN: "<br/>追加発注<br/>予測BL数", OLDA: "発注<br/>期限日<br/>(A)", OLDB: "発注<br/>期限日<br/>(B)", OLT: "<br/>ﾘｰﾄﾞ<br/>ﾀｲﾑ", OMM: "<br/><br/>備考（発注者）",OME: "<br/><br/>備考（営業）", ONT: "<br/>摘要<br/>（半角のみ）",
  RVM: "<br/><br/>受信管理",
  TBN: "<br/><br/>フラグ", ZTN: "<br/><br/>棚番", ZQ1: "<br/>一般在庫<br/>BL数", ZQ2: "<br/>特売在庫<br/>BL数", ZQ3: "<br/>予約在庫<br/>BL数",
  },
];

export const selectableCol =
{
  no1 : false, no2 : false,
  //以下に貼り付け
  IJN: false, IJC: true, ICD: false, ISC: true, ISN: true, IMC: true, IMN: true, INM: true, ICC: true, ICN: true, IBC: true, IBN: true, ICP: true, IC1: true, IC2: true, ITN: true, ILI: true, IPR: true, ISD: true, IED: true,
  SCD: true, SNM: false,
  OSCD: true, OSNM: false,
  TCD: true, TNM: false, TDR: false,
  OOS: false,
  KFR: false, KTO: false,
  HPL: false,
  OFS: false, OND: false, OOU: false, OON: false, ONOA: false, OOZ: false, ONOB: false, OFN: false, OAN: false, OLDA: false, OLDB: false, OLT: true, OMM: true, OME: true, ONT: true,
  RVM: true,
  TBN: true, ZTN: true, ZQ1: true, ZQ2: true, ZQ3: true,
}

export const headerClass =
{
  no1 : "tokubaiinput_N", no2 : "tokubaiinput_N",
  //以下に貼り付け
  IJN: "tokubaiinput_I", IJC: "tokubaiinput_I", ICD: "tokubaiinput_I", ISC: "tokubaiinput_I", ISN: "tokubaiinput_I", IMC: "tokubaiinput_I", IMN: "tokubaiinput_I", INM: "tokubaiinput_I", ICC: "tokubaiinput_I", ICN: "tokubaiinput_I", IBC: "tokubaiinput_I", IBN: "tokubaiinput_I", ICP: "tokubaiinput_I", IC1: "tokubaiinput_I", IC2: "tokubaiinput_I", ITN: "tokubaiinput_I", ILI: "tokubaiinput_I", IPR: "tokubaiinput_I", ISD: "tokubaiinput_I", IED: "tokubaiinput_I",
  SCD: "tokubaiinput_S", SNM: "tokubaiinput_S",
  OSCD: "tokubaiinput_S", OSNM: "tokubaiinput_S",
  TCD: "tokubaiinput_S", TNM: "tokubaiinput_S", TDR: "tokubaiinput_S",
  OOS: "tokubaiinput_O",
  KFR: "tokubaiinput_K", KTO: "tokubaiinput_K",
  HPL: "tokubaiinput_H",
  OFS: "tokubaiinput_O", OND: "tokubaiinput_O", OOU: "tokubaiinput_O", OON: "tokubaiinput_O", ONOA: "tokubaiinput_O", OOZ: "tokubaiinput_O", ONOB: "tokubaiinput_O", OFN: "tokubaiinput_O", OAN: "tokubaiinput_O", OLDA: "tokubaiinput_O", OLDB: "tokubaiinput_O", OLT: "tokubaiinput_O", OMM: "tokubaiinput_O", OME: "tokubaiinput_O", ONT: "tokubaiinput_O",
  RVM: "tokubaiinput_O",
  TBN: "tokubaiinput_T", ZTN: "tokubaiinput_Z", ZQ1: "tokubaiinput_Z", ZQ2: "tokubaiinput_Z", ZQ3: "tokubaiinput_Z",
}

export const colWidths =
{
  no1 : 35, no2 : 35,
  //以下に貼り付け
  IJN: 120, IJC: 40, ICD: 120, ISC: 50, ISN: 100, IMC: 50, IMN: 100, INM: 240, ICC: 50, ICN: 80, IBC: 50, IBN: 80, ICP: 50, IC1: 50, IC2: 50, ITN: 50, ILI: 70, IPR: 60, ISD: 80, IED: 80,
  SCD: 50, SNM: 130,
  OSCD: 50, OSNM: 130,
  TCD: 80, TNM: 130, TDR: 40,
  OOS: 40,
  KFR: 100, KTO: 100,
  HPL: 70,
  OFS: 100, OND: 100, OOU: 50, OON: 70, ONOA: 50, OOZ: 70, ONOB: 50, OFN: 70, OAN: 70, OLDA: 100, OLDB: 100, OLT: 55, OMM: 240, OME: 240, ONT: 150,
  RVM: 70,
  TBN: 35, ZTN: 60, ZQ1: 70, ZQ2: 70, ZQ3: 70,
};

export const colDataType =
{
  no1 : {type: 'text', readOnly: true, className: 'htCenter'},
  no2 : {type: 'text', readOnly: true, className: 'htCenter'},
  //以下に貼り付け
  IJN: {type: 'text', readOnly: false},
  IJC: {type: 'numeric', numericFormat: {pattern: '0,0',}, readOnly: true},
  ICD: {type: 'text', readOnly: false, className: 'htCenter'},
  ISC: {type: 'text', readOnly: true, className: 'htCenter'},
  ISN: {type: 'text', readOnly: true},
  IMC: {type: 'text', readOnly: true, className: 'htCenter'},
  IMN: {type: 'text', readOnly: true},
  INM: {type: 'text', readOnly: true},
  ICC: {type: 'text', readOnly: true, className: 'htCenter'},
  ICN: {type: 'text', readOnly: true},
  IBC: {type: 'text', readOnly: true, className: 'htCenter'},
  IBN: {type: 'text', readOnly: true},
  ICP: {type: 'text', readOnly: true, className: 'htRight'},
  IC1: {type: 'numeric', numericFormat: {pattern: '0,0',}, readOnly: true},
  IC2: {type: 'numeric', numericFormat: {pattern: '0,0',}, readOnly: true},
  ITN: {type: 'numeric', numericFormat: {pattern: '0,0',}, readOnly: true},
  ILI: {type: 'numeric', numericFormat: {pattern: '0,0日',}, readOnly: true},
  IPR: {type: 'numeric', numericFormat: {pattern: '0,0',}, readOnly: true},
  ISD: {type: 'text', readOnly: true, className: 'htCenter'},
  IED: {type: 'text', readOnly: true, className: 'htCenter'},

  SCD: {type: 'text', readOnly: true, className: 'htCenter'},
  SNM: {type: 'text', readOnly: true},
  OSCD: {type: 'text', readOnly: true, className: 'htCenter'},
  OSNM: {type: 'text', readOnly: true},
  TCD: {type: 'text', readOnly: true, className: 'htCenter'},
  TNM: {type: 'text', readOnly: true},
  TDR: {type: 'checkbox', readOnly: true, className: 'htCenter'},

  OOS: {type: 'checkbox', checkedTemplate: true, uncheckedTemplate: false, readOnly: false, className: 'htCenter'},
  KFR: { type: 'date', dateFormat: 'YY/MM/DD', datePickerConfig: { numberOfMonths: 2, minDate: moment().add(1, 'day').startOf('day').toDate(), yearSuffix: '年', showMonthAfterYear: true, showDaysInNextAndPreviousMonths: true, i18n: { previousMonth: '前月', nextMonth: '次月', months: moment.months(), weekdays: moment.weekdays(), weekdaysShort: moment.weekdaysShort() } }, readOnly: false, className: 'htCenter' },
  KTO: { type: 'date', dateFormat: 'YY/MM/DD', datePickerConfig: { numberOfMonths: 2, minDate: moment().add(1, 'day').startOf('day').toDate(), yearSuffix: '年', showMonthAfterYear: true, showDaysInNextAndPreviousMonths: true, i18n: { previousMonth: '前月', nextMonth: '次月', months: moment.months(), weekdays: moment.weekdays(), weekdaysShort: moment.weekdaysShort() } }, readOnly: false, className: 'htCenter' },

  HPL: {type: 'numeric', numericFormat: {pattern: '0,0',}, readOnly: false},

  OFS: { type: 'date', dateFormat: 'YY/MM/DD', datePickerConfig: { numberOfMonths: 2, minDate: moment().startOf('day').toDate(), yearSuffix: '年', showMonthAfterYear: true, showDaysInNextAndPreviousMonths: true, i18n: { previousMonth: '前月', nextMonth: '次月', months: moment.months(), weekdays: moment.weekdays(), weekdaysShort: moment.weekdaysShort() } }, readOnly: false, className: 'htCenter' },
  OND: { type: 'date', dateFormat: 'YY/MM/DD', datePickerConfig: { numberOfMonths: 2, minDate: moment().startOf('day').toDate(), yearSuffix: '年', showMonthAfterYear: true, showDaysInNextAndPreviousMonths: true, i18n: { previousMonth: '前月', nextMonth: '次月', months: moment.months(), weekdays: moment.weekdays(), weekdaysShort: moment.weekdaysShort() } }, readOnly: false, className: 'htCenter' },
  OOU: { type: 'dropdown', source: ['CS', 'BL'], readOnly: false, className: 'htCenter' },
  OON: {type: 'numeric', numericFormat: {pattern: '0,0',}, readOnly: false},
  ONOA: {type: 'numeric', numericFormat: {pattern: '0,0',}, readOnly: true},
  OOZ: {type: 'numeric', numericFormat: {pattern: '0,0',}, readOnly: false},
  ONOB: {type: 'numeric', numericFormat: {pattern: '0,0',}, readOnly: true},
  OFN: {type: 'numeric', numericFormat: {pattern: '0,0',}, readOnly: true},
  OAN: {type: 'numeric', numericFormat: {pattern: '0,0',}, readOnly: true},
  OLDA: { type: 'date', dateFormat: 'YY/MM/DD', datePickerConfig: { numberOfMonths: 2, minDate: moment().startOf('day').toDate(), yearSuffix: '年', showMonthAfterYear: true, showDaysInNextAndPreviousMonths: true, i18n: { previousMonth: '前月', nextMonth: '次月', months: moment.months(), weekdays: moment.weekdays(), weekdaysShort: moment.weekdaysShort() } }, readOnly: false, className: 'htCenter' },
  OLDB: { type: 'date', dateFormat: 'YY/MM/DD', datePickerConfig: { numberOfMonths: 2, minDate: moment().startOf('day').toDate(), yearSuffix: '年', showMonthAfterYear: true, showDaysInNextAndPreviousMonths: true, i18n: { previousMonth: '前月', nextMonth: '次月', months: moment.months(), weekdays: moment.weekdays(), weekdaysShort: moment.weekdaysShort() } }, readOnly: false, className: 'htCenter' },
  OLT: {type: 'numeric', numericFormat: {pattern: '0,0',}, readOnly: true},
  OMM: {type: 'text', readOnly: false},
  OME: {type: 'text', readOnly: false},
  ONT: {type: 'text', readOnly: false},
  RVM: { type: 'dropdown', source: ['未受信', '当日受信', '受信済', '打伝', '確認中', '取消済'], readOnly: false, className: 'htCenter' },
  TBN: {type: 'text', readOnly: true, className: 'htCenter'},
  ZTN: {type: 'text', readOnly: true, className: 'htCenter'},
  ZQ1: {type: 'numeric', numericFormat: {pattern: '0,0.0',}, readOnly: true},
  ZQ2: {type: 'numeric', numericFormat: {pattern: '0,0.0',}, readOnly: true},
  ZQ3: {type: 'numeric', numericFormat: {pattern: '0,0.0',}, readOnly: true},
};

//function
export type keyInfo = {key:string, dataType:'no1'|'no2'|'data'|'detail'};
export const keyInfoFromCol = (column:number):keyInfo => {
  if(column < colKeysNo_data.length) {
    return {key:colKeysNo_data[column], dataType:"no1"};
  }
  column -= colKeysNo_data.length;

  if(column < colKeysNo_detail.length) {
    return {key:colKeysNo_detail[column], dataType:"no2"};
  }
  column -= colKeysNo_detail.length;

  if(column < colKeysData_fix.length) {
    return {key:colKeysData_fix[column], dataType:"data"};
  }
  column -= colKeysData_fix.length;

  if(column < colKeysDetail_fix.length) {
    return {key:colKeysDetail_fix[column], dataType:"detail"};
  }
  column -= colKeysDetail_fix.length;

  if(column < colKeysData_unfix.length) {
    return {key:colKeysData_unfix[column], dataType:"data"};
  }
  column -= colKeysData_unfix.length;

  if(column < colKeysDetail_unfix.length) {
    return {key:colKeysDetail_unfix[column], dataType:"detail"};
  }
  column -= colKeysDetail_unfix.length;

  return null;
}
export const keyInfoFromCols = (columns:number[]):keyInfo[] => {
  const vals:keyInfo[] = [];
  columns.forEach(column => vals.push(keyInfoFromCol(column)))
  return vals;
}
export const keyFromCol = (column:number):string => {
  return colKeys[column];
}
export const keyFromCols = (columns:number[]):string[] => {
  const vals:string[] = [];
  columns.forEach(column => vals.push(keyFromCol(column)))
  return vals;
}
export const colFromKey = (colKey:string):number => {
  const cols = colsFromKey(colKey);
  return cols && cols.length > 0 ? cols[0] : null;
}
export const colsFromKey = (colKey:string):number[] => {
  return colsKeysMap[colKey];
}
export const colsFromKeys = (colKeys:string[]):number[] => {
  const vals:number[] = [];
  colKeys.forEach(colKey => {
    const colsKeysMap = colsFromKey(colKey);
    if(colsKeysMap) {
      colsKeysMap.forEach(col => vals.push(col));
    }
  })
  return vals;
}
