import { CodeName } from "@/store/common";

//倉庫・商品
export type CenterInfo = {
  bumonCD?: string, //--部門
  bumonNM?: string | null, //部門
  centerCD?: string | null, //倉庫(4桁コード)
  centerNM?: string | null, //倉庫
  centerDiv?: 'TC' | 'DC' | null, //TC/DC
  teiban?: '済み' | '未対応',
}
//出荷情報
export type SyukkaData = {
  centerCD?: string | null, //倉庫
  teiban?: string,

  syukkaAmtAve?: number, //配送売上
  syukkaQtyAve?: number, //出荷BL数
  syukkaAmtTtl: number, //配送売上
  syukkaQtyTtl?: number, //出荷BL数
  daysCountOfToday?: number, //経過日数
  daysCountOfMonth?: number, //経過日数
  cycleCnt?: number, //回転数
}
//在庫情報（合計）
export type ZaikoTotalData = {
  centerCD?: string | null, //倉庫
  teiban?: string,

	// zaikoKbn?: 'all'|'1'|'2'|'3'|'4'|'5'|string, //1.一般', '2.特売', '3.予約', '4.出切', '5.返品

  zaikoTtlPrdAll?: number,  //合計 ｱｲﾃﾑ数
  zaikoTtlQtyAll?: number,  //合計 在庫BL数
  zaikoTtlAmtAll?: number,  //合計 在庫金額
  zaikoTtlRatAll?: number,  //合計 在庫区分比

  zaikoTtlPrd1?: number,  //合計 ｱｲﾃﾑ数
  zaikoTtlQty1?: number,  //合計 在庫BL数
  zaikoTtlAmt1?: number,  //合計 在庫金額
  zaikoTtlRat1?: number,  //合計 在庫区分比

  zaikoTtlPrd2?: number,  //合計 ｱｲﾃﾑ数
  zaikoTtlQty2?: number,  //合計 在庫BL数
  zaikoTtlAmt2?: number,  //合計 在庫金額
  zaikoTtlRat2?: number,  //合計 在庫区分比

  zaikoTtlPrd3?: number,  //合計 ｱｲﾃﾑ数
  zaikoTtlQty3?: number,  //合計 在庫BL数
  zaikoTtlAmt3?: number,  //合計 在庫金額
  zaikoTtlRat3?: number,  //合計 在庫区分比

  zaikoTtlPrd4?: number,  //合計 ｱｲﾃﾑ数
  zaikoTtlQty4?: number,  //合計 在庫BL数
  zaikoTtlAmt4?: number,  //合計 在庫金額
  zaikoTtlRat4?: number,  //合計 在庫区分比

  zaikoTtlPrd5?: number,  //合計 ｱｲﾃﾑ数
  zaikoTtlQty5?: number,  //合計 在庫BL数
  zaikoTtlAmt5?: number,  //合計 在庫金額
  zaikoTtlRat5?: number,  //合計 在庫区分比
}

//在庫情報(経過日数別)
export type ZaikoKeikaData = {
  centerCD?: string | null, //倉庫
  teiban?: string,

	zaikoKbn?: 'all'|'1'|'2'|'3'|'4'|'5'|string, //1.一般', '2.特売', '3.予約', '4.出切', '5.返品

  zaiko101Prd?: number,  //101日以上 ｱｲﾃﾑ数
  zaiko101Qty?: number,  //101日以上 在庫BL数
  zaiko101Amt?: number,  //101日以上 在庫金額
  zaiko101Rat?: number,  //101日以上 構成比

  zaiko031Prd?: number,  //100～31日 ｱｲﾃﾑ数
  zaiko031Qty?: number,  //100～31日 在庫BL数
  zaiko031Amt?: number,  //100～31日 在庫金額
  zaiko031Rat?: number,  //100～31日 構成比

  zaiko011Prd?: number,  //30～11日 ｱｲﾃﾑ数
  zaiko011Qty?: number,  //30～11日 在庫BL数
  zaiko011Amt?: number,  //30～11日 在庫金額
  zaiko011Rat?: number,  //30～11日 構成比

  zaiko000Prd?: number,  //10未満 ｱｲﾃﾑ数
  zaiko000Qty?: number,  //10未満 在庫BL数
  zaiko000Amt?: number,  //10未満 在庫金額
  zaiko000Rat?: number,  //10未満 構成比

  zaiko999Prd?: number,  //99999999 ｱｲﾃﾑ数
  zaiko999Qty?: number,  //99999999 在庫BL数
  zaiko999Amt?: number,  //99999999 在庫金額
  zaiko999Rat?: number,  //99999999 構成比

  over1_3?: number,
  over1_2?: number,
  over?: number,
}

export const centerInfoAttributes:CodeName[][] = [
  [
    new CodeName({group_code:"", group_name:"", code: "", name: "(指定なし)", ryaku: "", }),
    new CodeName({group_code:"center", group_name:"センター属性", code: "centerDiv", name: "TC/DC", ryaku: "TC/DC", }),
    new CodeName({group_code:"center", group_name:"センター属性", code: "bumon", name: "部門", ryaku: "部門", }),
    new CodeName({group_code:"center", group_name:"センター属性", code: "bumonCD", name: "部門CD", ryaku: "部門CD", }),
    new CodeName({group_code:"center", group_name:"センター属性", code: "bumonNM", name: "部門名", ryaku: "部門名", }),
    new CodeName({group_code:"center", group_name:"センター属性", code: "center", name: "倉庫", ryaku: "倉庫", }),
    new CodeName({group_code:"center", group_name:"センター属性", code: "centerCD", name: "倉庫CD", ryaku: "倉庫CD", }),
    new CodeName({group_code:"center", group_name:"センター属性", code: "centerNM", name: "倉庫名", ryaku: "倉庫名", }),
  ]
];

//並び順
export const listSortOrders:CodeName[] = [
  new CodeName({group_code:"center", group_name:"【センター】", code: "center_centerCD", name: "倉庫コード", option1: "centerCD", option2:"centerCD", option3: "", option4: ""}),
  new CodeName({group_code:"center", group_name:"【センター】", code: "center_centerNM", name: "倉庫名", option1: "centerNM", option2:"centerCD", option3: "", option4: ""}),
  new CodeName({ group_code: "center", group_name: "【センター】", code: "center_zaikoTtlPrdAll_teiban", name: "ｱｲﾃﾑ数(定番)", option1: "zaikoTtlPrdAll", option2: "centerCD", option3: "", option4: "", option6: "定番" }),
  new CodeName({ group_code: "center", group_name: "【センター】", code: "center_zaikoTtlPrdAll_teibangai", name: "ｱｲﾃﾑ数(定番外)", option1: "zaikoTtlPrdAll", option2: "centerCD", option3: "", option4: "", option6: "定番外" }),
  new CodeName({ group_code: "center", group_name: "【センター】", code: "center_zaikoTtlQtyAll_teiban", name: "在庫BL数(定番)", option1: "zaikoTtlQtyAll", option2: "centerCD", option3: "", option4: "", option6: "定番" }),
  new CodeName({ group_code: "center", group_name: "【センター】", code: "center_zaikoTtlQtyAll_teibangai", name: "在庫BL数(定番外)", option1: "zaikoTtlQtyAll", option2: "centerCD", option3: "", option4: "", option6: "定番外" }),
  new CodeName({ group_code: "center", group_name: "【センター】", code: "center_zaikoTtlAmtAll_teiban", name: "在庫金額(定番)", option1: "zaikoTtlAmtAll", option2: "centerCD", option3: "", option4: "", option6: "定番" }),
  new CodeName({ group_code: "center", group_name: "【センター】", code: "center_zaikoTtlAmtAll_teibangai", name: "在庫金額(定番外)", option1: "zaikoTtlAmtAll", option2: "centerCD", option3: "", option4: "", option6: "定番外" }),
  new CodeName({group_code:"bumon_center", group_name:"【部門 → センター】", code: "bumon_center_centerCD", name: "部門 → 倉庫コード", option1: "bumonCD", option2:"centerCD", option3: "centerCD", option4: ""}),
  new CodeName({group_code:"bumon_center", group_name:"【部門 → センター】", code: "bumon_center_centerNM", name: "部門 → 倉庫名", option1: "bumonCD", option2:"centerNM", option3: "centerCD", option4: ""}),
  new CodeName({ group_code: "bumon_center", group_name: "【部門 → センター】", code: "bumon_zaikoTtlPrdAll_centerCD_teiban", name: "部門 → ｱｲﾃﾑ数(定番)", option1: "bumonCD", option2: "zaikoTtlPrdAll", option3: "centerCD", option4: "", option6: "定番" }),
  new CodeName({ group_code: "bumon_center", group_name: "【部門 → センター】", code: "bumon_zaikoTtlPrdAll_centerCD_teibangai", name: "部門 → ｱｲﾃﾑ数(定番外)", option1: "bumonCD", option2: "zaikoTtlPrdAll", option3: "centerCD", option4: "", option6: "定番外" }),
  new CodeName({ group_code: "bumon_center", group_name: "【部門 → センター】", code: "bumon_zaikoTtlQtyAll_centerCD_teiban", name: "部門 → 在庫BL数(定番)", option1: "bumonCD", option2: "zaikoTtlQtyAll", option3: "centerCD", option4: "", option6: "定番" }),
  new CodeName({ group_code: "bumon_center", group_name: "【部門 → センター】", code: "bumon_zaikoTtlQtyAll_centerCD_teibangai", name: "部門 → 在庫BL数(定番外)", option1: "bumonCD", option2: "zaikoTtlQtyAll", option3: "centerCD", option4: "", option6: "定番外" }),
  new CodeName({ group_code: "bumon_center", group_name: "【部門 → センター】", code: "bumon_zaikoTtlAmtAll_centerCD_teiban", name: "部門 → 在庫金額(定番)", option1: "bumonCD", option2: "zaikoTtlAmtAll", option3: "centerCD", option4: "", option6: "定番" }),
  new CodeName({ group_code: "bumon_center", group_name: "【部門 → センター】", code: "bumon_zaikoTtlAmtAll_centerCD_teibangai", name: "部門 → 在庫金額(定番外)", option1: "bumonCD", option2: "zaikoTtlAmtAll", option3: "centerCD", option4: "", option6: "定番外" }),
];

export const optionAll = new CodeName({ code: 'all', name: '全て' });
