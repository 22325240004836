import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { colFromKey, getColKeysDataFix, getColKeysDataUnfix, keyFromCol, keyInfoFromCol, colKeysNo } from "@/components/tokubai/manage/TokubaiManageTableModel";
import * as tokubaiManageGet from "@/assets/apitype/tokubaiManageGet";
import * as tokubaiManageRegist from "@/assets/apitype/tokubaiManageRegist";

import Handsontable from 'handsontable';

import * as calcUtil from "@/util/calcUtil";
import * as editorUtil from "@/util/editorUtil";
import * as compareUtil from "@/util/compareUtil";
import {CodeName} from "@/store/common";
import { colDataType } from "@/components/tokubai/manage/TokubaiManageTableModel";
import moment from 'moment';

import { initialStatusList, EditingType, initialRequestDateTypeList, toEditingType, EditingTypeEdit, EditingTypeCommited, EditingTypeError, EditingTypeFurikaed } from "@/store/tokubai/manage/tokubaiManageCommon";

export type RetrievedId = {uuid: string, lv:number};

export const ErrorInfoDataTypes = ['noinput', 'invalid'] as const;
export type ErrorInfoDataType =  typeof ErrorInfoDataTypes[number];

//エラー情報
export interface ErrorInfoData {
  MCK?: { msg: string, type: ErrorInfoDataType },
  OKD?: { msg: string, type: ErrorInfoDataType },
  OKB?: { msg: string, type: ErrorInfoDataType },
  ONT?: { msg: string, type: ErrorInfoDataType },
  ONU?: { msg: string, type: ErrorInfoDataType },
  OOU?: { msg: string, type: ErrorInfoDataType },
}
export interface WarnInfoData {
  MCK?: string | null,
  OKD?: string | null, OKB?: string | null,
  ONT?: string | null,
  ONU?: string | null,
  ZTN?: string | null,
}

export interface RowData {
  TP?: "total" | "item",
  no?: string,
  seq?: number,
  motoSeq?: number,
  CSQ?: number,
  uuid?: string,

  //以下に貼り付け
  MCK?: boolean | string | null,
  STS?: string | null,
  ANO?: string | null,
  ADT?: string | null,
  AMS?: string | null,
  FKD?: string | null,
  MRD?: string | null, MRU?: string | null, MRC?: string | null, MRG?: string | null,
  OUC?: string | null, OUN?: string | null,
  IJN?: string | null, IJC?: number | null, ICD?: string | null,
  ISC?: string | null, ISN?: string | null, IMC?: string | null, IMN?: string | null, INM?: string | null, ICC?: string | null, ICN?: string | null, IBC?: string | null, IBN?: string | null, ICP?: string | null, ILI?: number | null, IPR?: number | null, ISD?: string | null, IED?: string | null, IC1?: number | null, IC2?: number | null, ITN?: number | null,
  SCD?: string | null, SNM?: string | null, 
  TSCD?: string | null, TSNM?: string | null, 
  TCD?: string | null,
  TNM?: string | null,
  TDR?: boolean | string | null,
  OOS?: string | null,
  KFR?: string | null, KTO?: string | null,
  HPL?: number | null,
  OFS?: string | null, OND?: string | null, OYT?: string | null, OKD?: string | null, OKB?: string | null,
  OOU?: string | null,
  OFIN?: number | null, OIN?: number | null, CSS?: number | null, OIM?: number | null, OLD?: string | null, OLT?: number | null, OINR?: string | null, ONU?: number | null, ONUS?: number | null, OZN?: number | null, ONO?: number | null, ONOS?: number | null, OFN?: number | null,
  OMM?: string | null, OME?: string | null,
  ONT?: string | null,
  RVM?: string | null,
  TBN?: string | null,
  ZTN?: string | null,
  ZQ1?: number | null, ZQ2?: number | null, ZQ3?: number | null, ZQ4?: number | null,
  DATEUP?: string | null
  TIMEUP?: string | null
  //
  OFR?: number | null,
  KBST?: string | null,
  //FAX
  IFX?: string,


  //
  group1?: string | null,

  //エラー情報
  errorInfo?:ErrorInfoData,
  //警告情報
  warnInfo?:WarnInfoData,

  //編集
  edited?:boolean,
  oldMCK?: boolean | string | null,
  oldOKD?: string | null,
  oldOKB?: string | null,
  oldOOU?: string | null,
  oldONU?: number | null,
  oldOMM?: string | null,
  oldOME?: string | null,
  oldONT?: string | null,
  oldRVM?: string | null,
  //訂正
  oldANO?: string | null,
  oldTDR?: boolean | null,
  //特売期間修正連動用
  oldKFR?: string | null,
  oldKTO?: string | null,
}

//倉庫・メーカー・タグ
export type MakerTagData = {
  uuid?: string,  //データベースに登録済みのUUID。更新時にDB上の値とずれていれば登録エラーとする

  makerCD?: string | null, //商品CD
  centerCD?: string | null, //倉庫

  blInputFlg?: boolean,	//BL入力可不可

  leadTimeFlg?: boolean,	//リードタイム有無
  leadTimeDays?: number,	//リードタイム日数
  leadTimeExcludeSaturdayFlg?: boolean,	//リードタイム(土曜を除く)
  leadTimeContainHolidayFlg?: boolean,	//リードタイム(祝日を含む)

  specifiedDateFlg?: boolean,	//指定日有無
  specifiedDateSunFlg?: boolean,	//指定日：曜日：日
  specifiedDateMonFlg?: boolean,	//指定日：曜日：月
  specifiedDateTueFlg?: boolean,	//指定日：曜日：火
  specifiedDateWedFlg?: boolean,	//指定日：曜日：水
  specifiedDateThrFlg?: boolean,	//指定日：曜日：木
  specifiedDateFriFlg?: boolean,	//指定日：曜日：金
  specifiedDateSatFlg?: boolean,	//指定日：曜日：土
  specifiedDateHolFlg?: boolean,	//指定日：曜日：祝日

  closeTime?: string,

  fractionRounding?: '0' | '1' | '2' | '3', //端数処理 (0:無し 1:切捨て 2:四捨五入 3:切り上げ)

  delFlg?: boolean,
}
//倉庫・商品 (納品不可日)
export type DisableDateData = {
  itemCD?: string | null, //商品CD
  centerCD?: string | null, //倉庫
  disableDates?: any, //{"YYYY-MM-DD":""}
}

//未入力判定
export const isEmptyRowData = (data:RowData) => {
  return !data
    || (
      !data.uuid &&
      !data.MCK &&
      !data.OKD &&
      !data.OKB &&
      !data.OMM &&
      !data.OME &&
      !data.ONT &&
      !data.RVM
    );
}
export const isEditedRowData = (data:RowData):boolean => {
  if(!data){
    return false;
  }

  return (data.MCK != data.oldMCK ||
          data.OKD != data.oldOKD ||
          data.OKB != data.oldOKB ||
          data.OOU != data.oldOOU ||
          data.ONU != data.oldONU ||
          data.OMM != data.oldOMM ||
          data.OME != data.oldOME ||
          data.ONT != data.oldONT ||
          data.RVM != data.oldRVM
    )
  ;
}

export interface RowInfo {
  no?: string,
  row:number,
  data:RowData,
  dataIndex:number,
  makerTagData?: MakerTagData,
}


export type findedCell = {
  row: number,
  col: number,
  data,
  text: string,
}

//Page State
export type State = {
  //倉庫
  centerList: CodeName[],
  //依頼者
  requestUserList: CodeName[],
  //発注担当者
  orderUserList: CodeName[],
  //企業グループ１
  group1List: CodeName[],
  //メーカー
  makerList: CodeName[],
  //発注ステータス
  statusList: CodeName[],
  //期間タイプ
  requestDateTypeList: CodeName[],
  //お気に入りメーカー
  favoriteMakerList: CodeName[],

  editingType: EditingType,
  //編集中の条件
  editingParam : tokubaiManageRegist.RequestParam,

  //検索する条件
  requestParam : tokubaiManageGet.RequestParam,
  // requestParamQueue : RequestParam[],
  //ADMS発注小計を表示
  adms: boolean,

  //メーカー発注設定
  makerTagDatas: MakerTagData[],

  progress: Record<string, unknown>,
  datas: RowData[],
  // dataRowIndexes: number[],
  rowInfos: RowInfo[],
  rows, // any[][]
  mergeCells: {row: number, col: number, rowspan: number, colspan: number}[],
  // selectionRowStart: number,
  // selectionRowEnd: number,

  initList: string[] | null,
  conditionsStack: Handsontable.plugins.FiltersPlugin.ColumnConditions[] | null,
  indexedValues,

  errorMessage: string | null,
  infoMessage: string | null,
};

export const initialState: State = {
  //倉庫
  centerList: [],
  //依頼者
  requestUserList: [],
  //発注担当者
  orderUserList: [],
  //企業グループ１
  group1List: [],
  //メーカー
  makerList: [],
  //発注ステータス
  statusList: initialStatusList,
  //期間タイプ
  requestDateTypeList: initialRequestDateTypeList,

  //お気に入りメーカー
  favoriteMakerList: [],

  editingType: toEditingType('none'),
  editingParam : {},

  requestParam : {},
  // requestParamQueue: [],
  //ADMS発注小計を表示
  adms: false,

  //メーカー発注設定
  makerTagDatas: [],

  progress: {},
  datas: [],
  // dataRowIndexes: [],
  rowInfos: [],
  rows: [],
  mergeCells: [],
  // selectionRowStart: -1,
  // selectionRowEnd: -1,

  initList: ["group1", "center", "maker", "user", "order"],
  conditionsStack: [],
  indexedValues: [],

  errorMessage: null,
  infoMessage: null,
};


//Page Slice
export const tokubaiManageTmpSlice = createSlice({
  name: "tokubaiManageTmp",
  initialState,
  reducers: {
    //componentDidMount
    initOnDidMount() {
      console.log('store.initOnDidMount');
    },
    //componentWillUnmount
    resetOnWillUnmount() {
      console.log('store.resetOnWillUnmount');
    },

    setRequestUserList(state:State, action: PayloadAction<CodeName[]>) {
      state.requestUserList = action.payload;
    },
    setOrderUserList(state:State, action: PayloadAction<CodeName[]>) {
      state.orderUserList = action.payload;
    },
    // Option Center
    setCenterList(state:State, action: PayloadAction<CodeName[]>) {
      state.centerList = action.payload;
    },
    // Option Group1
    setGroup1List(state:State, action: PayloadAction<CodeName[]>) {
      state.group1List = action.payload;
    },
    // Option Maker
    setMakerList(state:State, action: PayloadAction<CodeName[]>) {
      state.makerList = action.payload;
    },
    // Option Status
    setStatusList(state:State, action: PayloadAction<CodeName[]>) {
      state.statusList = action.payload;
    },
    setFavoriteMakerList(state:State, action: PayloadAction<CodeName[]>) {
      state.favoriteMakerList = action.payload;
    },

    setEditingStart(state:State, action: PayloadAction<{ editingType: EditingType, editingParam: tokubaiManageRegist.RequestParam }>) {
      console.log('store.setEditingStart');
      Object.assign(state, {
        editingType: action.payload.editingType,
        editingParam: action.payload.editingParam,
      });
    },
    setEditingEnd(state) {
      console.log('store.setEditingEnd');

      const datas = [{}];
      state.adms = false;
      state.makerTagDatas = [];
      const rowInfos = convertRows(datas, state.editingType, state.adms, state.makerTagDatas);
      Object.assign(state, {
        editingType: toEditingType('none'),
        editingParam: initialState.editingParam,

        datas: datas,
        // dataRowIndexes: rowInfos.dataRowIndexes,
        rowInfos: rowInfos.rowInfos,
        rows: rowInfos.rows,
        mergeCells: rowInfos.mergeCells,
      });

    },
    //検索条件
    setRequestParam(state:State, action: PayloadAction<tokubaiManageGet.RequestParam>) {
      console.log('store.setRequestParam');
      state.requestParam = action.payload;
    },
    // addRequestParamQueue(state:State, action: PayloadAction<RequestParam[]>) {
    //   console.log('store.addRequestParamQueue');
    //   state.requestParamQueue = [...state.requestParamQueue, ...action.payload];
    // },
    // setRequestParamQueue(state:State, action: PayloadAction<RequestParam[]>) {
    //   console.log('store.setRequestParamQueue');
    //   state.requestParamQueue = action.payload;
    // },
    // clearRequestParamQueue(state:State, action: PayloadAction<RequestParam[]>) {
    //   console.log('store.clearRequestParamQueue');
    //   state.requestParamQueue = [];
    // },

    putProgress(state:State, action: PayloadAction<string>) {
      console.log('store.putProgress');
      const key = action.payload;
      const progressNew = {...state.progress};
      progressNew[key] = true;
      state.progress = progressNew;
    },
    removeProgress(state:State, action: PayloadAction<string>) {
      console.log('store.removeProgress');
      const key = action.payload;
      const progressNew = {};
      Object.keys(state.progress).forEach(k => {
        if(key != k) {
          progressNew[k] = true;
        }
      })
      state.progress = progressNew;
    },

    setDatas(state: State, action: PayloadAction<{ datas: RowData[], sorts: { key: string, asc: boolean }[], adms: boolean, makerTagDatas: MakerTagData[] }>) {
      console.log('store.setDatas');
      let datas = action.payload.datas;
      const sorts = action.payload.sorts;
      state.adms = action.payload.adms;
      state.makerTagDatas = action.payload.makerTagDatas;
      datas = parseData(datas);
      datas = calcDatas(datas);
      datas = checkDatas(datas, state.editingType); //データチェック
      datas = calcTotal(datas, state.editingType); //合計行の作成
      datas = doSort(datas, sorts, state.adms);

      const rowInfos = convertRows(datas, state.editingType, state.adms, state.makerTagDatas);
      Object.assign(state, {
        datas: datas,
        // dataRowIndexes: rowInfos.dataRowIndexes,
        rowInfos: rowInfos.rowInfos,
        rows: rowInfos.rows,
        mergeCells: rowInfos.mergeCells,
      });
    },
    execSort(state: State, action: PayloadAction<{ key: string, asc: boolean }[]>) {
      const sorts = action.payload;

      let datas = [...state.datas];
      datas = doSort(datas, sorts, state.adms);
      const rowInfos = convertRows(datas, state.editingType, state.adms, state.makerTagDatas);
      Object.assign(state, {
        datas: datas,
        // dataRowIndexes: rowInfos.dataRowIndexes,
        rowInfos: rowInfos.rowInfos,
        rows: rowInfos.rows,
        mergeCells: rowInfos.mergeCells,
      });
    },
    editRowDatas(state: State, action: PayloadAction<{
      editDatas: {
        row: number, col: number, value: string | number | object | null
          , relatedValues?: { key: string, value: string | number | object | null }[]
          , uuid?: string
      }[],
      sorts: { key: string, asc: boolean }[]
    }>) {
      console.log('store.editRowDatas');
      const sorts = action.payload.sorts;
      const editDatas = action.payload.editDatas;
      let newDatas = [...state.datas];
      // const newRows = [...state.rows];

      const editDataContent = (rowInfo: RowInfo, row: number, col: number, key: string, value: string | number | boolean | object | null, editingType: EditingType) => {
        if(!(row >= 0) || (!key && !(col >= 0))) {
          return;
        }
        if(!key) {
          key = keyFromCol(col, editingType);
        }
        if(!(col >= 0)) {
          col = colFromKey(key, editingType);
        }
        const keyInfo = keyInfoFromCol(col, editingType);

        //parse
        const dataType = colDataType[key];
        value = editorUtil.parseValue(value, dataType.type,
          dataType.type == 'numeric' ? dataType.numericFormat.pattern :
          dataType.type == 'date' ? dataType.dateFormat :
          null);

        let data = newDatas[rowInfo.dataIndex];

        switch (keyInfo.dataType) {
          case "no":
            break;
          case "data":
            data[key] = value;
            data = calcData(data);
            data = checkData(data, editingType);
            break;

          default:
            break;
        }
        // newRows[row][col] = value;
      };

      editDatas.forEach((editData) => {
        const key = keyFromCol(editData.col, state.editingType);
        const rowInfo = state.rowInfos[editData.row];
        editDataContent(rowInfo, editData.row, editData.col, key, editData.value, state.editingType);
        //関連データの更新
        if(editData.relatedValues) {
          editData.relatedValues.forEach(relatedValue => {
            editDataContent(rowInfo, editData.row, null, relatedValue.key, relatedValue.value, state.editingType);
          })
        }
        //uuid
        if (editData.uuid) {
          let data = newDatas[rowInfo.dataIndex];
          data.uuid = editData.uuid;
        }
      });
      newDatas = checkDatas(newDatas, state.editingType);
      newDatas = doSort(newDatas, sorts, state.adms);
      const rowInfos = convertRows(newDatas, state.editingType, state.adms, state.makerTagDatas);
      Object.assign(state, {
        datas: newDatas,
        // dataRowIndexes: rowInfos.dataRowIndexes,
        rowInfos: rowInfos.rowInfos,
        rows: rowInfos.rows,
        mergeCells: rowInfos.mergeCells,
      });
    },
    setFurikaeResults(state, action: PayloadAction<{ datas: RowData[], results: RowData[], sorts: { key: string, asc: boolean }[] }>) {
      const sorts = action.payload.sorts;
      const datas = action.payload.datas;
      const results = action.payload.results;
      const update = (target: RowData, result: RowData) => {
        target.ONU = result.ONU;
        target.OKB = result.OKB;
        target.OFN = result.OFN;
        target.OUC = result.OUC;
        target.OUN = result.OUN;
        //編集前の値をセット
        target.oldMCK = target.MCK;
        target.oldOKD = target.OKD;
        target.oldOKB = target.OKB;
        target.oldOOU = target.OOU;
        target.oldONU = target.ONU;
        target.oldOMM = target.OMM;
        target.oldOME = target.OME;
        target.oldONT = target.ONT;
        target.oldRVM = target.RVM;
      }
      console.log('store.setFurikaeResults');
      //在庫振替後に数量が変わる場合のみ処理する
      results.forEach((result, index) => {
        //一部振替
        if (datas[index].STS == result.STS) {
          const target = state.datas.find(d => d.seq == datas[index].seq);
          update(target, result);
          checkData(target, state.editingType);
        }
        //振替済み
        else {
          if (['all'].indexOf(state.editingType.key) === -1) {
            state.datas = state.datas.filter(d => d.seq != datas[index].seq);
          } else {
            //発注すべては在庫振替済も表示するため残す
            const target = state.datas.find(d => d.seq == datas[index].seq);
            target.STS = result.STS;
            update(target, result);
            checkData(target, state.editingType);
          }
        }
      });

      let newDatas = [...state.datas];
      newDatas = doSort(newDatas, sorts, state.adms);
      const rowInfos = convertRows(newDatas, state.editingType, state.adms, state.makerTagDatas);
      Object.assign(state, {
        // dataRowIndexes: rowInfos.dataRowIndexes,
        rowInfos: rowInfos.rowInfos,
        rows: rowInfos.rows,
        mergeCells: rowInfos.mergeCells,
      });
    },
    setCancelResults(state, action: PayloadAction<number[]>) {
      const seqs = action.payload;
      console.log('store.setCancelResults');
      state.datas = state.datas.filter(data => seqs.indexOf(data.seq) === -1);

      const rowInfos = convertRows(state.datas, state.editingType, state.adms, state.makerTagDatas);
      Object.assign(state, {
        // dataRowIndexes: rowInfos.dataRowIndexes,
        rowInfos: rowInfos.rowInfos,
        rows: rowInfos.rows,
        mergeCells: rowInfos.mergeCells,
      });
    },
    setZanResults(state, action: PayloadAction<{ sakiData: RowData, motoDatas: RowData[], sorts: { key: string, asc: boolean }[] }>) {
      const sorts = action.payload.sorts;
      const sakiData = action.payload.sakiData;
      const motoDatas = action.payload.motoDatas;
      const update = (target: RowData, result: RowData) => {
        target.uuid = result.uuid;
        target.STS = result.STS;
        target.ONU = result.ONU;
        target.OKB = result.OKB;
        target.OFN = result.OFN;
        target.OZN = result.OZN;
        target.OFR = 0;
        target.OUC = result.OUC;
        target.OUN = result.OUN;
        //編集前の値をセット
        target.oldMCK = target.MCK;
        target.oldOKD = target.OKD;
        target.oldOKB = target.OKB;
        target.oldOOU = target.OOU;
        target.oldONU = target.ONU;
        target.oldOMM = target.OMM;
        target.oldOME = target.OME;
        target.oldONT = target.ONT;
        target.oldRVM = target.RVM;
      }
      console.log('store.setZanResults');

      {//振替先
        if (['all'].indexOf(state.editingType.key) === -1 && sakiData.STS === '在庫振替済') {
          state.datas = state.datas.filter(d => d.seq != sakiData.seq);
        } else {
          //発注すべては在庫振替済も表示するため残す
          const target = state.datas.find(d => d.seq == sakiData.seq);
          update(target, sakiData);
          checkData(target, state.editingType);
        }
      }
      motoDatas.forEach(motoData => {//振替元
        const target = state.datas.find(d => d.seq == motoData.seq);
        if (target) {
          update(target, motoData);
          checkData(target, state.editingType);
        }
      });

      let newDatas = [...state.datas];
      newDatas = doSort(newDatas, sorts, state.adms);
      const rowInfos = convertRows(newDatas, state.editingType, state.adms, state.makerTagDatas);
      Object.assign(state, {
        // dataRowIndexes: rowInfos.dataRowIndexes,
        rowInfos: rowInfos.rowInfos,
        rows: rowInfos.rows,
        mergeCells: rowInfos.mergeCells,
      });
    },
    setTanabanResults(state, action: PayloadAction<{ datas: RowData[], results: RowData[], sorts: { key: string, asc: boolean }[] }>) {
      const sorts = action.payload.sorts;
      const datas = action.payload.datas;
      const results = action.payload.results;
      const update = (target: RowData, ZTN: string, KBST: string) => {
        target.ZTN = ZTN;
        target.KBST = KBST;
      }
      console.log('store.setTanabanResults');

      results.forEach((result, index1) => {
        const targets = state.datas.filter(d => d.ICD == datas[index1].ICD);
        targets.forEach((_, index2) => {
          update(targets[index2], result.ZTN, result.KBST);
          checkData(targets[index2], state.editingType);
        });
      });

      let newDatas = [...state.datas];
      newDatas = doSort(newDatas, sorts, state.adms);
      const rowInfos = convertRows(newDatas, state.editingType, state.adms, state.makerTagDatas);
      Object.assign(state, {
        rowInfos: rowInfos.rowInfos,
        rows: rowInfos.rows,
        mergeCells: rowInfos.mergeCells,
      });
    },

    // setRowDatas(state:State, action: PayloadAction<RowData[]>) {
    //   state.rows = action.payload;
    // },
    // rowSelectionChange(state:State, action: PayloadAction<{start:number,end:number}>){
    //   console.log('store.rowSelectionChange');
    //   state.selectionRowStart = action.payload.start;
    //   state.selectionRowEnd = action.payload.end;
    // },
    refreshTable(state){
      console.log('store.refreshTable');
      const rowInfos = convertRows(state.datas, state.editingType, state.adms, state.makerTagDatas);
      Object.assign(state, {
        // dataRowIndexes: rowInfos.dataRowIndexes,
        rowInfos: rowInfos.rowInfos,
        rows: rowInfos.rows,
        mergeCells: rowInfos.mergeCells,
      });
    },
    setMergeCells(state, action: PayloadAction<{ row: number, col: number, rowspan: number, colspan: number }[]>) {
      Object.assign(state, {
        mergeCells: action.payload,
      });
    },
    setInitList(state:State, action: PayloadAction<string>) {
      if (state.initList.some(data => data == action.payload)) {
        state.initList = state.initList.filter(data => data != action.payload);
      }
    },
    setConditionsStack(state:State, action: PayloadAction<Handsontable.plugins.FiltersPlugin.ColumnConditions[]>){
      state.conditionsStack = action.payload;
    },
    setIndexedValues(state:State, action){
      state.indexedValues = action.payload;
    },
    setErrorMessage(state:State, action: PayloadAction<string>) {
      console.log('store.setErrorMessage');
      state.errorMessage = action.payload;
    },
    setInfoMessage(state:State, action: PayloadAction<string>) {
      console.log('store.setInfoMessage');
      state.infoMessage = action.payload;
    },
  },
});

//数値のパース(数値が文字列で返ってくる)
const parseData = (datas:RowData[]): RowData[] => {
  //set No.
  datas.forEach((data) => {
    if(typeof data.MCK === 'string') data.MCK = data.MCK == 'true';
    if(typeof data.IJC === 'string') data.IJC = parseInt(data.IJC);
    if(typeof data.ILI === 'string') data.ILI = parseInt(data.ILI);
    if(typeof data.IPR === 'string') data.IPR = parseInt(data.IPR);
    if(typeof data.IC1 === 'string') data.IC1 = parseInt(data.IC1);
    if(typeof data.IC2 === 'string') data.IC2 = parseInt(data.IC2);
    if(typeof data.ITN === 'string') data.ITN = parseInt(data.ITN);
    data.ISD = data.ISD?.length == 8 && moment(data.ISD).isValid() ? moment(data.ISD).format('YY/MM/DD') : "";
    data.IED = data.IED?.length == 8 && moment(data.IED).isValid() ? moment(data.IED).format('YY/MM/DD') : "";

    if(typeof data.HPL === 'string') data.HPL = parseInt(data.HPL);
    if(typeof data.OFIN === 'string') data.OFIN = parseInt(data.OFIN);
    if(typeof data.OIN === 'string') data.OIN = parseInt(data.OIN);
    if(typeof data.CSS === 'string') data.CSS = parseFloat(data.CSS);
    if(typeof data.ONU === 'string') data.ONU = parseInt(data.ONU);
    if(typeof data.ONO === 'string') data.ONO = parseInt(data.ONO);
    if(typeof data.OFN === 'string') data.OFN = parseInt(data.OFN);
    if(typeof data.OLT === 'string') data.OLT = parseInt(data.OLT);
    if(typeof data.OZN === 'string') data.OZN = parseInt(data.OZN);

    if(typeof data.ZQ1 === 'string') data.ZQ1 = parseFloat(data.ZQ1);
    if(typeof data.ZQ2 === 'string') data.ZQ2 = parseFloat(data.ZQ2);
    if(typeof data.ZQ3 === 'string') data.ZQ3 = parseFloat(data.ZQ3);
    if(typeof data.ZQ4 === 'string') data.ZQ4 = parseFloat(data.ZQ4);

  });
  return datas;
}
//合計行作成
const calcTotal = (datas: RowData[], editingType: EditingType): RowData[] => {
  const total: RowData = {
    TP: "total",
    OKB: "",
    OOU: "",
  };

  //set No.
  datas.forEach((data) => {
    if (data.TP === "item") {
      calcTotalSub(total, data);
    }
  });

  if (datas.length > 0 && datas[0].TP === "total") {
    datas[0] = total;
  }
  else {
    datas = [total, ...datas];
  }

  return datas;
}
const calcTotalSub = (total: RowData, data: RowData) => {
  total.OFIN = calcUtil.plus(total.OFIN, data.OFIN);
  total.OIN = calcUtil.plus(total.OIN, data.OIN);
  total.ONU = calcUtil.plus(total.ONU, data.ONU);
  total.ONUS = calcUtil.plus(total.ONUS, data.ONU);
  total.ONO = calcUtil.plus(total.ONO, data.ONO);
  total.ONOS = calcUtil.plus(total.ONOS, data.ONO);
  total.OFN = calcUtil.plus(total.OFN, data.OFN);
  total.OIM = calcUtil.plus(total.OIM, data.OIM);
}

//計算
const calcDatas = (datas:RowData[]): RowData[] => {
  return datas.map(data => calcData(data));
}
const calcData = (data:RowData): RowData => {
  //TODO 計算項目

  return data;

}

//チェック
const checkDatas = (datas:RowData[], editingType:EditingType): RowData[] => {
  console.log('store.checkDatas');
  return datas.map(data => checkData(data, editingType, datas));
}

export const checkData = (data: RowData, editingType: EditingType, datas?: RowData[]): RowData => {
  let newEditingType = editingType;

  if (['all'].indexOf(editingType.key) !== -1) {
    switch (data.STS) {
      case '発注前':
        newEditingType = EditingTypeEdit;
        break;
      case '発注指示済':
        newEditingType = EditingTypeCommited;
        break;
      case '発注処理中':
        newEditingType = EditingTypeCommited;
        break;
      case '発注済み':
        newEditingType = EditingTypeCommited;
        break;
      case '発注エラー':
        newEditingType = EditingTypeError;
        break;
      case '在庫振替済':
        newEditingType = EditingTypeFurikaed;
        break;
    }
  }
  return _checkData(data, newEditingType, datas);
}
const _checkData = (data: RowData, editingType: EditingType, datas?: RowData[]): RowData => {
  const errorInfo:ErrorInfoData = {};
  const warnInfo:WarnInfoData = {};
  if(!data.errorInfo){
    data.errorInfo = {};
  }
  if(!data.warnInfo){
    data.warnInfo = {};
  }
  const checkDate = (input: string, offset: number): boolean => {
    let inputDate = moment(input).startOf('day');
    if (!inputDate.isValid()) {
      inputDate = moment('20' + input).startOf('day');
    }
    const chk = moment().add(offset, 'day').startOf('day');
    return (inputDate.diff(chk, 'day') >= 0);
  }
  if(editingType.key == 'edit' || editingType.key == 'error') {
    //TODO チェック項目
    data.OIM = null;  //立米
    data.ONO = null;  //余りBL数
    if(data.MCK) {
      if (!data.OKD) {
        errorInfo.OKD = { msg: '入力してください', type: 'noinput' };
      }
      else if (!moment(data.OKD).isValid() && !moment('20' + data.OKD).isValid()) {
        errorInfo.OKD = { msg: '日付が不正', type: 'invalid' };
      }
      else if (!checkDate(data.OKD, 0)) {
        errorInfo.OKD = { msg: '当日以降を指定してください', type: 'invalid' };
      }
      else {
        let OKD = moment(data.OKD).startOf('day');
        if (!OKD.isValid()) {
          OKD = moment('20' + data.OKD).startOf('day');
        }
        let OFS = moment(data.OFS).startOf('day');
        if (!OFS.isValid()) {
          OFS = moment('20' + data.OFS).startOf('day');
        }
        if (OKD.isValid() && OFS.isValid()) {
          const diff = OKD.diff(OFS, 'day');
          if (diff >= 0) {
            warnInfo.OKD =  '出荷日≦倉入日が指定されています';
          } else if (diff <= -7) {
            warnInfo.OKD =  '出荷日１週間前≧倉入日が指定されています';
          }
        }
      }

      if (!data.OKB) {
        errorInfo.OKB = { msg: '選択してください', type: 'noinput' };
      }
      else if (['一般', '特売', '予約'].indexOf(data.OKB) === -1) {
        errorInfo.OKB = { msg: '一般、特売、予約から選択してください', type: 'invalid' };
      }

      if (!data.OOU) {
        errorInfo.OOU = { msg: '選択してください', type: 'noinput' };
      }
      else if (['CS', 'BL'].indexOf(data.OOU) === -1) {
        errorInfo.OOU = { msg: 'CS、BLから選択してください', type: 'invalid' };
      }

      if (data.OOU == 'CS' && data.OKD && data.OKB && datas) {
        const targets = datas.filter(d => d.STS == '発注前' || d.STS == '発注エラー')
          .filter(d => d.MCK && d.ICD == data.ICD && d.OKD == data.OKD && d.OKB == data.OKB && d.ONT == data.ONT);
        const totalONU = targets.reduce((sum, d) => sum + d.ONU, 0);
        if (totalONU && totalONU % (data.IC2 * data.ITN) != 0) {
          const bl = data.IC2 * data.ITN - totalONU % (data.IC2 * data.ITN);
          errorInfo.ONU = { msg: '発注CS数に満たないため、発注時に' + bl + 'BL不足します', type: 'invalid' };
        }
      }

      if (!data.ONU) {
        errorInfo.ONU = { msg: '入力してください', type: 'noinput' };
      }
      else if (typeof data.ONU != 'number' || data.ONU < 0) {
        errorInfo.ONU = { msg: '数値が不正', type: 'invalid' };
      }
      else if (data.OIN > data.ONU + (data.OFN ? data.OFN : 0)) {
        warnInfo.ONU = '出荷予定BL数未満です。';
      }
      if (data.ONT) {
        if (data.ONT.length > 20) {
          errorInfo.ONT = { msg: '20字まで入力可能です', type: 'invalid' };
        } else if (!/^[a-zA-Z0-9!-/:-@¥[-`{-~｡-ﾟ ]*$/.test(data.ONT)) {
          errorInfo.ONT = { msg: '半角のみ入力可能です', type: 'invalid' };
        }
      }
    }
  }
  if (editingType.key != 'none') {
    //発注BL数+振替済BL数-出荷予定BL数-残使用BL数
    const ONO: number = (data.ONU + data.OFN) - data.OIN - data.OZN;  //余りBL数
    data.ONO = ONO !== 0 ? ONO : null;

    //CSサイズ/CS入数*出荷予定BL数()
    const OIM: number = Math.ceil(data.CSS / data.IC2 * data.OIN * 100) / 100;
    data.OIM = OIM > 0 ? OIM : null;
  }
  if ((!data.ZTN || data.ZTN.trim().length <= 0)) {
    warnInfo.ZTN = '棚番がありません';
  }

  data.errorInfo = errorInfo;
  data.warnInfo = warnInfo;

  //編集状態をセットする
  data.edited = isEditedRowData(data);

  return data;
}


//no振りなおし
//no振りなおし
// const resetRowNo = (datas:RowInfo[]): RowData[] => {
//   //set No.
//   datas = datas.map((row, index) => {
//     return {
//       ...row,
//       no: "" + (index+1),
//     }
//   });
//   return datas;
// }

//配列データに変換
const convertRows = (datas: RowData[], editingType: EditingType, adms: boolean, makerTagDatas: MakerTagData[]): {
  rows:[][],
  // dataRowIndexes:number[],
  rowInfos: RowInfo[],
  mergeCells: {row: number, col: number, rowspan: number, colspan: number}[]
} => {
  console.log('store.convertRows');

  let mergeCells:{row: number, col: number, rowspan: number, colspan: number}[] = [];
  const mergeCols = [];
  //固定列すべてマージは崩れる
  colKeysNo.forEach((colKey) => {
    mergeCols.push(colFromKey(colKey, editingType));
  });
  const colKeysData_fix = getColKeysDataFix(editingType);
  const colKeysData_unfix = getColKeysDataUnfix(editingType);
  colKeysData_fix.forEach((colKey) => {
    mergeCols.push(colFromKey(colKey, editingType));
  });
  colKeysData_unfix.forEach((colKey) => {
    mergeCols.push(colFromKey(colKey, editingType));
  });

  const rows = [];
  // let dataRowIndexes = [];
  const rowInfos:RowInfo[] = [];
  //set No.
  datas.forEach((data, index1) => {

    // const s = rows.length;
    const no: string = data.TP === "total" ? "合計" : "" + index1;

    const r = [];
    colKeysNo.forEach((colKey) => {
      switch (colKey) {
        case 'no':
          r.push(no); //No.
          break;
        default:
          r.push(null);
          break;
      }
    });
    colKeysData_fix.forEach((colKey) => {
      r.push(data[colKey]);
    });
    colKeysData_unfix.forEach((colKey) => {
      r.push(data[colKey]);
    });

    rows.push(r);
    // dataRowIndexes.push(index);
    rowInfos.push({
      no: no,
      row:rowInfos.length,
      data: data,
      dataIndex:index1,
      makerTagData: makerTagDatas.find(makerTagData => makerTagData.centerCD == data.SCD && makerTagData.makerCD == data.IMC),
    });

    // //マージ
    // let e = rows.length;
    // if(e - s > 1) {
    //   let rowspan = e - s;
    //   mergeCols.forEach(col => {
    //     mergeCells.push({row: s, col: col, rowspan: rowspan, colspan: 1});
    //   });
    // }
  });

  if (adms) {
    mergeCells = getMergeCells(rowInfos, editingType);
    //マージセルの合計を求める
    [{ val: 'ONU', sum: 'ONUS' }, { val: 'ONO', sum: 'ONOS' }].forEach(colKeyPair => {
      const colNumber: number = colFromKey(colKeyPair.sum, editingType);
      mergeCells.filter(mergeCell => mergeCell.col == colNumber).forEach(mergeCell => {
        const row = mergeCell.row;
        const fdatas = datas.slice(row, row + mergeCell.rowspan).map(data => data[colKeyPair.val]);
        const sum = fdatas.reduce((sum, d) => typeof d !== 'number' ? sum : sum + d, 0);
        rows[row][colNumber] = sum;
        datas[row][colKeyPair.sum] = sum;
      });
    });

    mergeCells = mergeCells.filter(mergeCell => mergeCell.rowspan > 1);
  }

  return {
    rows:rows,
    // dataRowIndexes:dataRowIndexes,
    rowInfos:rowInfos,
    mergeCells: mergeCells,
  };
}

export const getMergeCells = (rowInfos: RowInfo[], editingType: EditingType, indexedValues?: any[], hotTable?): {
  row: number, col: number, rowspan: number, colspan: number
}[] => {
  let mergeCells: { row: number, col: number, rowspan: number, colspan: number }[] = [];

  const mergeCellMap = {};
  rowInfos.forEach((rowInfo, index) => {
    if (indexedValues && indexedValues[index]) {
      //skip
    }
    else {
      const data = rowInfo.data;
      if (data.TP == "item") {
        const uniqKey = data.ANO + '_' + data.ICD + '_' + data.OKD + '_' + data.OKB + '_' + data.ONT;
        let mergeCell: { row: number, rowspan: number } = mergeCellMap[uniqKey];
        if (!mergeCell) {
          mergeCell = {
            row: rowInfo.row,
            rowspan: 1,
          };
          mergeCellMap[uniqKey] = mergeCell;
        } else {
          mergeCell.rowspan++;
        }
      }
    }
  });
  const cols = [
    colFromKey('IJN', editingType),
    colFromKey('ISC', editingType),
    colFromKey('ISN', editingType),
    colFromKey('IMC', editingType),
    colFromKey('IMN', editingType),
    colFromKey('ICC', editingType),
    colFromKey('ICN', editingType),
    colFromKey('IBC', editingType),
    colFromKey('IBN', editingType),
    colFromKey('ICP', editingType),
    colFromKey('ILI', editingType),
    colFromKey('IPR', editingType),
    colFromKey('ISD', editingType),
    colFromKey('IED', editingType),
    colFromKey('IC1', editingType),
    colFromKey('IC2', editingType),
    colFromKey('ITN', editingType),
    colFromKey('ICD', editingType),
    colFromKey('INM', editingType),
    colFromKey('OUN', editingType),
    colFromKey('ANO', editingType),
    colFromKey('ADT', editingType),
    colFromKey('ONUS', editingType),
    colFromKey('ONOS', editingType),
    colFromKey('ONT', editingType),
    colFromKey('ZTN', editingType),
    colFromKey('ZQ1', editingType),
    colFromKey('ZQ2', editingType),
    colFromKey('ZQ3', editingType),
    colFromKey('ZQ4', editingType),
  ];
  Object.keys(mergeCellMap).forEach(uniqKey => {
    const mergeCell = mergeCellMap[uniqKey];
    cols.forEach(col => {
      const row = hotTable ? hotTable.toVisualRow(mergeCell.row) : mergeCell.row;
      mergeCells.push({ row: row, col: col, rowspan: mergeCell.rowspan, colspan: 1 });
    });
  });
  return mergeCells;
}

//ソート
const doSort = (datas: RowData[], sorts: { key: string, asc: boolean }[], adms: boolean): RowData[] => {
  datas.sort((a, b) => {
    //合計行は常に上
    if (a.TP === "total" || b.TP === "total") {
      return a.TP === "total" ? -1 : 1;
    }

    const objA = a;
    const objB = b;
    let comp = 0;

    for (const sort of sorts) {
      const colKey = sort.key;
      const asc = sort.asc;

      //初回出荷日(OFS)は未設定の場合、特売期間開始日(KFR)をみる
      const va = objA ? (colKey === 'OFS' && !objA[colKey] ? objA['KFR'] : objA[colKey]) : null;
      const vb = objB ? (colKey === 'OFS' && !objB[colKey] ? objB['KFR'] : objB[colKey]) : null;

      //数値型
      if (typeof va === 'number' || typeof vb === 'number') {
        comp = compareUtil.compareNumber(va, vb, asc);
      }
      else if (colKey == 'STS') {
        const ia = ['発注前', '発注指示済', '発注処理中', '発注済み', '発注エラー', '在庫振替済', 'リリース振替済'].indexOf(va);
        const ib = ['発注前', '発注指示済', '発注処理中', '発注済み', '発注エラー', '在庫振替済', 'リリース振替済'].indexOf(vb);
        comp = compareUtil.compareNumber(ia, ib, asc);
      }
      else if (colKey == 'OYT') {
        const ia = ['(A)送込BL数', '(B)必要BL数', '追加発注', '在庫ﾘﾘｰｽ'].indexOf(va);
        const ib = ['(A)送込BL数', '(B)必要BL数', '追加発注', '在庫ﾘﾘｰｽ'].indexOf(vb);
        comp = compareUtil.compareNumber(ia, ib, asc);
      }
      else if (colKey == 'TBN') {
        const ia = ['〇', '✖'].indexOf(va);
        const ib = ['〇', '✖'].indexOf(vb);
        comp = compareUtil.compareAny(ia, ib, asc);
      }
      else if (typeof va === 'string' || typeof vb === 'string') {
        const da = moment(va);
        const db = moment(vb);
        if (da.isValid() || db.isValid()) {
          comp = compareUtil.compareDate(da.toDate(), db.toDate(), asc);
        } else {
          comp = compareUtil.compareString(va, vb, asc);
        }
      }
      else if ((typeof va === 'object' && va instanceof Date) || (typeof vb === 'object' && vb instanceof Date)) {
        comp = compareUtil.compareDate(va, vb, asc);
      }
      else if (typeof va == 'boolean' || typeof vb == 'boolean') {
        comp = compareUtil.compareBoolean(va, vb, asc);
      }
      if (comp != 0) {
        break;
      }
    }
    if (comp === 0) {
      //それでもソートできない場合、通番順とする
      comp = compareUtil.compareNumber(objA['CSQ'], objB['CSQ'], true);
    }
    return comp;
  });
  if (adms) {
    let newDatas = [...datas];
    const mergeCellMap = {};
    datas.forEach((data, index) => {
      if (data.TP == "item") {
        const uniqKey = data.ANO + '_' + data.ICD + '_' + data.OKD + '_' + data.OKB + '_' + data.ONT;
        let mergeCell: { CSQ: number, ANO: string, ICD: string, OKD: string, OKB: string, ONT: string, index: number, rowspan: number } = mergeCellMap[uniqKey];
        if (!mergeCell) {
          mergeCell = {
            CSQ: data.CSQ,
            ANO: data.ANO, ICD: data.ICD, OKD: data.OKD, OKB: data.OKB, ONT: data.ONT,
            index: index + 1,
            rowspan: 1,
          };
          mergeCellMap[uniqKey] = mergeCell;
        } else {
          mergeCell.rowspan++;
        }
      }
    });
    Object.keys(mergeCellMap).forEach(uniqKey => {
      const mergeCell = mergeCellMap[uniqKey];
      if (mergeCell.rowspan > 1) {
        const merges = newDatas.filter(d =>
          mergeCell.CSQ != d.CSQ &&
          mergeCell.ANO == d.ANO && mergeCell.ICD == d.ICD && mergeCell.OKD == d.OKD && mergeCell.OKB == d.OKB && mergeCell.ONT == d.ONT);
        newDatas = newDatas.filter(d => !merges.some(m => m.CSQ == d.CSQ));
        newDatas.splice(newDatas.findIndex(d => d.CSQ == mergeCell.CSQ) + 1, 0, ...merges);
      }
    });
    return newDatas;
  }
  return datas;
}
export const lastUpdateZaiko = (rowInfos: RowInfo[]): string => {
  const map = rowInfos.filter(rowInfo => rowInfo.data && rowInfo.data.DATEUP && rowInfo.data.TIMEUP)
    .map(rowInfo => moment(rowInfo.data.DATEUP.padStart(8, '0') + '' + rowInfo.data.TIMEUP.padStart(6, '0'), 'YYYYMMDDHHmmss'));
  if (map.length == 0) {
    return "";
  }

  const max: moment.Moment = map.reduce((a, b) => a.unix() >= b.unix() ? a : b);
  const now = moment(new Date());
  if (now.diff(max, 'day')) {
    return ' (' + max.format('M/D H') + '時時点)';
  }
  return ' (' + max.format('H') + '時時点)';
}