import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { YM, YW, CodeName } from "@/store/common";
import {RequestParam} from "@/assets/apitype/salesList";
import { SalesListTransColRowModel, salesListTransColRowModelDefault, salesListTransColRowModelReport11, salesListTransColRowModelReport15, salesListTransColRowModelReport16, salesListTransColRowModelReport35, salesListTransColRowModelReport45, colKeys45 } from "@/components/saleslisttrans/SalesListTransTableModel";
import * as arrayUtil from "@/util/arrayUtil";
import moment from 'moment';

//強制表示列
export const mustVisibleColumnsKey = [
  "no", "rowHeader1","rowHeader2","rowHeader3",
  "TTL",
  ...colKeys45,
];
//デフォルト表示列
export const defaultVisibleColumnsKey = [
  //以下に貼り付け
  "H1CD", "H1NM", "H2CD", "H2NM", "H3CD", "H3NM", "H4CD", "H4NM", "H5CD", "H5NM", "H6CD", "H6NM", "H7CD", "H7NM", "H8CD", "H8NM",
];
//デフォルト表示行(月次)1
export const defaultVisibleRowsKeyMonthly1 = [
  "JJT", "JJR", "JJD",
  "JKT", 
  "JRT", "JRD", 
  "KI1KR", "KI2KR", "KI3KR",
  "STT", "STR", "STD",
  "STBR", "STBD",
  "PBHT",
  "YHHT",
  "PTT", "PTD",
  "PRT", "PRD",
  "DTT", "DTR", "DTD", "DRT", "DRD",
  "NTT", "NTR", "NTD", "NTBR", "NTBD",
  "CIT", "CIR", "CID", "CST", "CSR", "CSD", "CTT", "CTR", "CTD", "CTBR", "CTBD",
  "BTT", "BTD",
  "ATT", "ATR", "ATD", "ART", "ARD",
  "GTT", "GTR", "GTD", "GTB", "GTBR", "GTBD", "GRT", "GRD",
  "ZTT", "ZTR", "ZTD", "ZTB", "ZTBR", "ZTBD", "ZRT", "ZRD",
  "ZCT", "ZCR", "ZCD", "ZCRT", "ZCRD",
  "CNDT", "CNPT", "CNET", "CNRT", "CNRD",
  "SNET", "SNRT", "SNRD",
  "ORST", "ORSRT", "ORSRD", "ORPT", "ORPR", "ORPD", "ORPRT", "ORPRD",
  "SHT", "SHR", "SHD",
];
//デフォルト表示行(月次)2
export const defaultVisibleRowsKeyMonthly2 = [
  "STT", "STR", "STD",
  "PBHT",
  "YHHT",
  "PTT", "PTD",
];
//デフォルト表示行3
export const defaultVisibleRowsKeyMonthly3 = [
  "STT", "STR",
  "JKT", "JKR", "JRT", "JRD",
  "KI1KA", "KI1KK", "KI1KR", "KI3KA", "KI3KK", "KI3KR",
  "K10KA", "K10KK", "K11KA", "K11KK", "K12KA", "K12KK", "K14KA", "K14KK", "K15KA", "K15KK", "K16KA", "K16KK", "K17KA", "K17KK", "K19KA", "K19KK",
  "K20KA", "K20KK", "K21KA", "K21KK", "K22KA", "K22KK", "K29KA", "K29KK",
  "K30KA", "K30KK", "K31KA", "K31KK", "K34KA", "K34KK", "K40KA", "K40KK", "K41KA", "K41KK", "K42KA", "K42KK", "K43KA", "K43KK", "K50KA", "K50KK",
];
//デフォルト表示行3
export const defaultVisibleRowsKeyWeekly3 = [
  "STT", "STR",
  "JKT", "JKR", "JRT", "JRD",
  // "KI1KA", "KI1KK", "KI1KR", "KI3KA", "KI3KK", "KI3KR",
  // "K10KA", "K10KK", "K11KA", "K11KK", "K12KA", "K12KK", "K14KA", "K14KK", "K15KA", "K15KK", "K16KA", "K16KK", "K17KA", "K17KK", "K19KA", "K19KK",
  // "K20KA", "K20KK", "K21KA", "K21KK", "K22KA", "K22KK", "K29KA", "K29KK",
  // "K30KA", "K30KK", "K31KA", "K31KK", "K34KA", "K34KK", "K40KA", "K40KK", "K41KA", "K41KK", "K42KA", "K42KK", "K43KA", "K43KK", "K50KA", "K50KK",
];

export const defaultVisibleRowsKeyReport15 = [
  "JJT",   //受注         受注額         本年実績
  "JJR",   //受注         受注額         前年比
  "JJD",   //受注         受注額         前年差
  "STT",   //総売上       総売上         本年実績
  "STR",   //総売上       総売上         前年比
  "STD",   //総売上       総売上         前年差
  "STBR",  //総売上       総売上         予算達成率
  "STBD",  //総売上       総売上         予算差
  "PBHT",  //PB売上       PB構成比       本年実績
  "YHHT",
  "PTT",   //売買差益     売買差益計     本年実績
  "PTD",   //売買差益     売買差益計     前年差
  "PRT",   //売買差益     売買差益率     本年実績
  "PRD",   //売買差益     売買差益率     前年差
  "JKT",   //欠品         欠品額         本年実績
  "JRT",   //欠品         欠品率         本年実績
  "KI1KR", //欠品責任別   1.自社責       欠品率
  "KI2KR", //欠品責任別   2.メーカー責   欠品率
  "KI3KR", //欠品責任別   3.得意先責     欠品率
  "CIT",   //修正         入力修正       本年実績
  "CIR",   //修正         入力修正       前年比
  "CST",   //修正         黒修正         本年実績
  "CSR",   //修正         黒修正         前年比
  "CTT",   //修正         修正合計       本年実績
  "CTR",   //修正         修正合計       前年比
  "CTD",   //修正         修正合計       前年差
  "CTBR",  //修正         修正合計       予算達成率
  "CTBD",  //修正         修正合計       予算差
  "ATT",   //総利益       修正後企業粗利 本年実績
  "ATR",   //総利益       修正後企業粗利 前年比
  "ATD",   //総利益       修正後企業粗利 前年差
  "GRT",   //総利益       修正後粗利益率 本年実績
  "GRD",   //総利益       修正後粗利益率 前年差
  "KTT",   //得意先課金計 得意先課金計   本年実績
  "KTR",   //得意先課金計 得意先課金計   前年比
  "KTD",   //得意先課金計 得意先課金計   前年差
  "KRT",   //得意先課金計 得意先課金率   本年実績
  "ZCT",   //企業粗利     企業粗利       本年実績
  "ZCR",   //企業粗利     企業粗利       前年比
  "ZCD",   //企業粗利     企業粗利       前年差
  "ZCRT",  //企業粗利     企業粗利率     本年実績
  "ZCRD",  //企業粗利     企業粗利率     前年差
];

export const defaultVisibleRowsKeyReport16 = [
  "JJT",   //受注         受注額         本年実績
  "JJR",   //受注         受注額         前年比
  "JJD",   //受注         受注額         前年差
  "STT",   //総売上       総売上         本年実績
  "STR",   //総売上       総売上         前年比
  "STD",   //総売上       総売上         前年差
  // "STBR",  //総売上       総売上         予算達成率
  // "STBD",  //総売上       総売上         予算差
  // "PBHT",  //PB売上       PB構成比       本年実績
  // "YHHT",
  "PTT",   //売買差益     売買差益計     本年実績
  "PTD",   //売買差益     売買差益計     前年差
  "PRT",   //売買差益     売買差益率     本年実績
  "PRD",   //売買差益     売買差益率     前年差
  "JKT",   //欠品         欠品額         本年実績
  "JRT",   //欠品         欠品率         本年実績
  // "KI1KR", //欠品責任別   1.自社責       欠品率
  // "KI2KR", //欠品責任別   2.メーカー責   欠品率
  // "KI3KR", //欠品責任別   3.得意先責     欠品率
  // "CIT",   //修正         入力修正       本年実績
  // "CIR",   //修正         入力修正       前年比
  // "CST",   //修正         黒修正         本年実績
  // "CSR",   //修正         黒修正         前年比
  // "CTT",   //修正         修正合計       本年実績
  // "CTR",   //修正         修正合計       前年比
  // "CTD",   //修正         修正合計       前年差
  // "CTBR",  //修正         修正合計       予算達成率
  // "CTBD",  //修正         修正合計       予算差
  // "ATT",   //総利益       修正後企業粗利 本年実績
  // "ATR",   //総利益       修正後企業粗利 前年比
  // "ATD",   //総利益       修正後企業粗利 前年差
  // "GRT",   //総利益       修正後粗利益率 本年実績
  // "GRD",   //総利益       修正後粗利益率 前年差
  // "KTT",   //得意先課金計 得意先課金計   本年実績
  // "KTR",   //得意先課金計 得意先課金計   前年比
  // "KTD",   //得意先課金計 得意先課金計   前年差
  // "KRT",   //得意先課金計 得意先課金率   本年実績
  // "ZCT",   //企業粗利     企業粗利       本年実績
  // "ZCR",   //企業粗利     企業粗利       前年比
  // "ZCD",   //企業粗利     企業粗利       前年差
  // "ZCRT",  //企業粗利     企業粗利率     本年実績
  // "ZCRD",  //企業粗利     企業粗利率     前年差
];

export const defaultVisibleRowsKeyReport35 = [
  "JJT",   //受注         受注額         本年実績
  "JJR",   //受注         受注額         前年比
  "JJD",   //受注         受注額         前年差
  "STT",   //総売上       総売上         本年実績
  "STR",   //総売上       総売上         前年比
  "STD",   //総売上       総売上         前年差
  "STBR",  //総売上       総売上         予算達成率
  "STBD",  //総売上       総売上         予算差
  "PBHT",  //PB売上       PB構成比       本年実績
  "YHHT",
  "PTT",   //売買差益     売買差益計     本年実績
  "PTD",   //売買差益     売買差益計     前年差
  "PRT",   //売買差益     売買差益率     本年実績
  "PRD",   //売買差益     売買差益率     前年差
  // "JKT",   //欠品         欠品額         本年実績
  // "JRT",   //欠品         欠品率         本年実績
  // "KI1KR", //欠品責任別   1.自社責       欠品率
  // "KI2KR", //欠品責任別   2.メーカー責   欠品率
  // "KI3KR", //欠品責任別   3.得意先責     欠品率
  "CIT",   //修正         入力修正       本年実績
  "CIR",   //修正         入力修正       前年比
  "CST",   //修正         黒修正         本年実績
  "CSR",   //修正         黒修正         前年比
  "CTT",   //修正         修正合計       本年実績
  "CTR",   //修正         修正合計       前年比
  "CTD",   //修正         修正合計       前年差
  "CTBR",  //修正         修正合計       予算達成率
  "CTBD",  //修正         修正合計       予算差
  "ATT",   //総利益       修正後企業粗利 本年実績
  "ATR",   //総利益       修正後企業粗利 前年比
  "ATD",   //総利益       修正後企業粗利 前年差
  "GRT",   //総利益       修正後粗利益率 本年実績
  "GRD",   //総利益       修正後粗利益率 前年差
  "KTT",   //得意先課金計 得意先課金計   本年実績
  "KTR",   //得意先課金計 得意先課金計   前年比
  "KTD",   //得意先課金計 得意先課金計   前年差
  "KRT",   //得意先課金計 得意先課金率   本年実績
  "ZCT",   //企業粗利     企業粗利       本年実績
  "ZCR",   //企業粗利     企業粗利       前年比
  "ZCD",   //企業粗利     企業粗利       前年差
  "ZCRT",  //企業粗利     企業粗利率     本年実績
  "ZCRD",  //企業粗利     企業粗利率     前年差
  "CNDT", "CNPT", "CNET", "CNRT", "CNRD",
  "SNET", "SNRT", "SNRD",
  "ORST", "ORSRT", "ORSRD", "ORPT", "ORPR", "ORPD", "ORPRT", "ORPRD",
  "SHT", "SHR", "SHD",
];

export const defaultVisibleRowsKeyReport45 = [
  "SLT", "SLR", "SRT", "STT", "STR", "STD",
  "CNDT", "CNDR", "CNDD", "CNDRT", "CNDRD", "CNPT", "CNPR", "CNPD", "CNPRT", "CNPRD", "CNGT", "CNGR", "CNGD", "CNGRT", "CNGRD", "CNET", "CNER", "CNED", "CNRT", "CNRD",
  "SNET", "SNER", "SNRT", "SNRD",
  "ORST", "ORSR", "ORSD", "ORSRT", "ORSRD",
  "ORPT", "ORPR", "ORPD", "ORPRT", "ORPRD",
  "SHT", "SHR", "SHD",
];

//デフォルト表示行(日次)
export const defaultVisibleRowsKeyDaily = [
  "STT", "STR", "STD", "KKR", "PBR",
];

export const defaultVisibleRowsKeyReport11 = [
  "STT", "STR", "STD", "KKR", "PBR",
];

//Page State
export type SalesListTransSaveState = {
  //年月
  ym: YM | null,
  ymFrom: YM | null, //推移で使用
  //年週
  yw: YW | null,
  ywFrom: YW | null, //推移で使用
  //集計軸
  axisType: "group2" | "shiten" | "center" | "shiire" | "maker" | "category" | null,
  //データ種別
  dataType: "daily" | "quick" | "commit" | "weekly" | null,
  shareDataType: "daily" | "quick" | "commit" | null,
  //検索対象
  containSEJ: boolean,
  group2: CodeName[] | null,
  shiten: CodeName[] | null,
  center: CodeName[] | null,
  shiire: CodeName[] | null,
  maker: CodeName[] | null,
  category: CodeName[] | null,

  //検索終了した条件
  requestedParam : RequestParam,

  sort: {colKey:string, rowKey:string, asc:boolean},

  hiddenColumns: number[],
  hiddenColumnsKey: string[],
  visibleColumnsKey: string[],

  visibleRowsKeyMonthly: string[],  //現在は未使用。移行として残す
  visibleRowsKeyMonthlyQuick: string[],
  visibleRowsKeyMonthlyCommit: string[],
  visibleRowsKeyDaily: string[],
  visibleRowsKeyWeekly: string[],

  showChart: boolean,

  grouping1: boolean,
  grouping2: boolean,

};

export const initialSalesListTransState: SalesListTransSaveState = {
  //年月
  ym: null,
  ymFrom: null, //推移で使用
  //年週
  yw: null,
  ywFrom: null, //推移で使用
  //集計軸
  axisType: "group2",
  //データ種別
  dataType: "quick",
  shareDataType: null,
  //検索対象
  containSEJ: false,
  group2: [],
  shiten: [],
  center: [],
  shiire: [],
  maker: [],
  category: [],

  requestedParam : {},

  sort: {
    colKey: "TTL", //合計
    rowKey: "STT", //総売上本年実績
    asc: false, //降順
  },
  hiddenColumns: [],
  hiddenColumnsKey: [],
  visibleColumnsKey: defaultVisibleColumnsKey,

  visibleRowsKeyMonthly: defaultVisibleRowsKeyMonthly1,
  visibleRowsKeyMonthlyQuick: defaultVisibleRowsKeyMonthly1,
  visibleRowsKeyMonthlyCommit: defaultVisibleRowsKeyMonthly1,
  visibleRowsKeyDaily: defaultVisibleRowsKeyDaily,
  visibleRowsKeyWeekly: defaultVisibleRowsKeyWeekly3,

  showChart: true,

  grouping1: false,
  grouping2: false,

};

export const initialSalesListTransReport11State: SalesListTransSaveState = {
  //年月
  ym: null,
  ymFrom: null,
  //年週
  yw: null,
  ywFrom: null,
  //集計軸
  axisType: "group2",
  //データ種別
  dataType: "daily",
  shareDataType: null,
  //検索対象
  containSEJ: false,
  group2: [],
  shiten: [],
  center: [],
  shiire: [],
  maker: [],
  category: [],

  requestedParam : {},

  sort: {
    colKey: "TTL", //合計
    rowKey: "STT", //総売上本年実績
    asc: false, //降順
  },

  hiddenColumns: [],
  hiddenColumnsKey: [],
  visibleColumnsKey: defaultVisibleColumnsKey,

  visibleRowsKeyMonthly: [],  //0配列は全項目を表示とする
  visibleRowsKeyMonthlyQuick: [],  //0配列は全項目を表示とする
  visibleRowsKeyMonthlyCommit: [],  //0配列は全項目を表示とする
  visibleRowsKeyDaily: defaultVisibleRowsKeyReport11,
  visibleRowsKeyWeekly: [],  //0配列は全項目を表示とする

  showChart: true,

  grouping1: false,
  grouping2: false,

};

export const initialSalesListTransReport15State: SalesListTransSaveState = {
  //年月
  ym: null,
  ymFrom: null,
  //年週
  yw: null,
  ywFrom: null,
  //集計軸
  axisType: "group2",
  //データ種別
  dataType: "quick",
  shareDataType: null,
  //検索対象
  containSEJ: false,
  group2: [],
  shiten: [],
  center: [],
  shiire: [],
  maker: [],
  category: [],

  requestedParam : {},

  sort: {
    colKey: "TTL", //合計
    rowKey: "STT", //総売上本年実績
    asc: false, //降順
  },

  hiddenColumns: [],
  hiddenColumnsKey: [],
  visibleColumnsKey: defaultVisibleColumnsKey,

  visibleRowsKeyMonthly: defaultVisibleRowsKeyReport15,
  visibleRowsKeyMonthlyQuick: defaultVisibleRowsKeyReport15,
  visibleRowsKeyMonthlyCommit: [],
  visibleRowsKeyDaily: [],  //0配列は全項目を表示とする
  visibleRowsKeyWeekly: [],  //0配列は全項目を表示とする

  showChart: true,

  grouping1: false,
  grouping2: false,

};

export const initialSalesListTransReport16State: SalesListTransSaveState = {
  //年月
  ym: null,
  ymFrom: null,
  //年週
  yw: null,
  ywFrom: null,
  //集計軸
  axisType: "group2",
  //データ種別
  dataType: "weekly",
  shareDataType: null,
  //検索対象
  containSEJ: false,
  group2: [],
  shiten: [],
  center: [],
  shiire: [],
  maker: [],
  category: [],

  requestedParam : {},

  sort: {
    colKey: "TTL", //合計
    rowKey: "STT", //総売上本年実績
    asc: false, //降順
  },

  hiddenColumns: [],
  hiddenColumnsKey: [],
  visibleColumnsKey: defaultVisibleColumnsKey,

  visibleRowsKeyMonthly: [],
  visibleRowsKeyMonthlyQuick: [],
  visibleRowsKeyMonthlyCommit: [],
  visibleRowsKeyDaily: [],  //0配列は全項目を表示とする
  visibleRowsKeyWeekly: defaultVisibleRowsKeyReport16,  //0配列は全項目を表示とする

  showChart: true,

  grouping1: false,
  grouping2: false,

};

export const initialSalesListTransReport35State: SalesListTransSaveState = {
  //年月
  ym: null,
  ymFrom: null,
  //年週
  yw: null,
  ywFrom: null,
  //集計軸
  axisType: "group2",
  //データ種別
  dataType: "commit",
  shareDataType: null,
  //検索対象
  containSEJ: false,
  group2: [],
  shiten: [],
  center: [],
  shiire: [],
  maker: [],
  category: [],

  requestedParam : {},

  sort: {
    colKey: "TTL", //合計
    rowKey: "STT", //総売上本年実績
    asc: false, //降順
  },

  hiddenColumns: [],
  hiddenColumnsKey: [],
  visibleColumnsKey: defaultVisibleColumnsKey,

  visibleRowsKeyMonthly: defaultVisibleRowsKeyReport35,
  visibleRowsKeyMonthlyQuick: [],
  visibleRowsKeyMonthlyCommit: defaultVisibleRowsKeyReport35,
  visibleRowsKeyDaily: [],  //0配列は全項目を表示とする
  visibleRowsKeyWeekly: [],

  showChart: true,

  grouping1: false,
  grouping2: false,

};

export const initialSalesListTransReport45State: SalesListTransSaveState = {
  //年月
  ym: null,
  ymFrom: null,
  //年週
  yw: null,
  ywFrom: null,
  //集計軸
  axisType: "center",
  //データ種別
  dataType: "commit",
  shareDataType: null,
  //検索対象
  containSEJ: false,
  group2: [],
  shiten: [],
  center: [],
  shiire: [],
  maker: [],
  category: [],

  requestedParam : {},

  sort: {
    colKey: "TTL", //合計
    rowKey: "STT", //総売上本年実績
    asc: false, //降順
  },

  hiddenColumns: [],
  hiddenColumnsKey: [],
  visibleColumnsKey: defaultVisibleColumnsKey,

  visibleRowsKeyMonthly: defaultVisibleRowsKeyReport45,
  visibleRowsKeyMonthlyQuick: [],
  visibleRowsKeyMonthlyCommit: defaultVisibleRowsKeyReport45,
  visibleRowsKeyDaily: [],  //0配列は全項目を表示とする
  visibleRowsKeyWeekly: [],

  showChart: true,

  grouping1: false,
  grouping2: false,

};

//Page Slice
export type SalesListTransSaveReducer = {
  initOnDidMount: (state:SalesListTransSaveState, action: PayloadAction<SalesListTransSaveState>) => void,
  resetOnWillUnmount: (state:SalesListTransSaveState) => void,
  setYM: (state:SalesListTransSaveState, action: PayloadAction<{val:YM, ymList:YM[]}>) => void,
  setYMFrom: (state:SalesListTransSaveState, action: PayloadAction<{val:YM, ymList:YM[]}>) => void,
  setYMList: (state:SalesListTransSaveState, action: PayloadAction<YM[]>) => void,
  setYW: (state:SalesListTransSaveState, action: PayloadAction<{val:YW, ywList:YW[]}>) => void,
  setYWFrom: (state:SalesListTransSaveState, action: PayloadAction<{val:YW, ywList:YW[]}>) => void,
  setYWList: (state:SalesListTransSaveState, action: PayloadAction<YW[]>) => void,
  setAxisType: (state:SalesListTransSaveState, action: PayloadAction<string>) => void,
  resetAxisType: (state) => void,
  setDataType: (state:SalesListTransSaveState, action: PayloadAction<string>) => void,
  setGroup2: (state: SalesListTransSaveState, action: PayloadAction<CodeName[]>) => void,
  setShiten: (state: SalesListTransSaveState, action: PayloadAction<CodeName[]>) => void,
  setCenter: (state: SalesListTransSaveState, action: PayloadAction<CodeName[]>) => void,
  setShiire: (state: SalesListTransSaveState, action: PayloadAction<CodeName[]>) => void,
  setMaker: (state: SalesListTransSaveState, action: PayloadAction<CodeName[]>) => void,
  setCategory: (state: SalesListTransSaveState, action: PayloadAction<CodeName[]>) => void,
  setContainSEJ: (state:SalesListTransSaveState, action: PayloadAction<boolean>) => void,
  clearOption: (state:SalesListTransSaveState, action: PayloadAction<{ymList:YM[],ywList:YW[]}>) => void,
  setRequestedParam: (state:SalesListTransSaveState, action: PayloadAction<RequestParam>) => void,
  setSort: (state:SalesListTransSaveState, action: PayloadAction<{sortColKey:string, sortRowKey:string, sortAsc: boolean}>) => void,
  hideColumnsChange: (state:SalesListTransSaveState, action: PayloadAction<{columns:number[], keys:string[]}>) => void,
  hideColumnKeysChange: (state:SalesListTransSaveState, action: PayloadAction<string[]>) => void,
  visibleRowKeysChange: (state: SalesListTransSaveState, action: PayloadAction<{ keys: string[], dataType?: "daily" | "quick" | "commit" | "weekly" | null }>) => void,
  showChartChange: (state:SalesListTransSaveState, action: PayloadAction<boolean>) => void,
  setGrouping1: (state: SalesListTransSaveState, action: PayloadAction<boolean>) => void,
  setGrouping2: (state: SalesListTransSaveState, action: PayloadAction<boolean>) => void,
}

const createReducerContent = (name: string, colRowModel: SalesListTransColRowModel, initialState: SalesListTransSaveState, 
  defaultYMOnInit: "" | "fiscalYearQuick" | "fiscalYearCommit" | "thisMonth",
  defaultYWOnInit: "" | "fiscalYearQuick" | "fiscalYearCommit" | "thisWeek",
  defaultAxisType: "" | "init"): SalesListTransSaveReducer => { return {
  //componentDidMount
  initOnDidMount(state:SalesListTransSaveState) {
    //追加属性等はlocalstorageから復元したときにundefinedになっている場合があるためチェックする
    if(state.requestedParam === undefined) {
      state.requestedParam = initialState.requestedParam;
    }

    //最新の年月を選択した状態にする
    if (defaultYMOnInit == "fiscalYearQuick" || defaultYMOnInit == "fiscalYearCommit") {
      const getMonth = (defaultYMOnInit: "fiscalYearQuick" | "fiscalYearCommit", now: moment.Moment = moment(new Date())): {
        from: moment.Moment, to: moment.Moment
      } => {
        // fiscalYearQuick  : 25.月次推移　　 4月の場合:前年4月ー今期3月、今期4月ー今期3月
        // fiscalYearCommit : 35.月次確報推移 4月の場合:前年4月ー今期3月、今期4月ー前月
        let thisMonth = moment().set({ 'year': now.get('year'), 'month': now.get('month'), 'date': 1 });
        const preMonth = now.clone().add(-1, 'month').endOf('month');

        thisMonth.add(now.get('month') === (4 - 1) ? -4 : -3, 'month');
        const end = moment().set({ 'year': thisMonth.get('year') + 1, 'month': 3 - 1, 'date': 31 });
        const from = moment().set({ 'year': thisMonth.get('year'), 'month': 4 - 1, 'date': 1 });
        const to = defaultYMOnInit === "fiscalYearQuick" ? end : now.get('month') === (4 - 1) ? end : preMonth;

        return { from: from, to: to };
      }

      const first = true;
      const formto = getMonth(defaultYMOnInit);
      state.ymFrom = new YM({ code: formto.from.format('YYYYMM'), name: formto.from.format('YYYY年MM月'), thisMonth: false });
      state.ym = new YM({ code: formto.to.format('YYYYMM'), name: formto.to.format('YYYY年MM月'), thisMonth: first });
      if (state.requestedParam.ym) {
        state.requestedParam.ym = state.ym.code;
        state.requestedParam.ymFrom = state.ymFrom.code;
      }
    }
    else if (defaultYMOnInit == "thisMonth") {
      const now = moment(new Date());
      const thisMonth = moment().set({'year': now.get('year'), 'month': now.get('month'), 'date': 1});
      const first = true;

      state.ym = new YM({ code: thisMonth.format('YYYYMM'), name: thisMonth.format('YYYY年MM月'), thisMonth: first });
      if (state.requestedParam.ym) {
        state.requestedParam.ym = state.ym.code;
      }
    }
    else {
      if(!state.ym) {
        state.ym = initialState.ym;
      }
      if(!state.ymFrom) {
        state.ymFrom = initialState.ymFrom;
      }
    }

    //最新の年週を選択した状態にする
    if (defaultYWOnInit == "fiscalYearQuick" || defaultYWOnInit == "fiscalYearCommit") {
      const getMonth = (defaultYWOnInit: "fiscalYearQuick" | "fiscalYearCommit", now: moment.Moment = moment(new Date())): {
        from: moment.Moment, to: moment.Moment
      } => {
        // fiscalYearQuick  : 25.月次推移　　 4月の場合:前年4月ー今期3月、今期4月ー今期3月
        // fiscalYearCommit : 35.月次確報推移 4月の場合:前年4月ー今期3月、今期4月ー前月
        let thisMonth = moment().set({ 'year': now.get('year'), 'month': now.get('month'), 'date': 1 });
        const preMonth = now.clone().add(-1, 'month').endOf('month');

        thisMonth.add(now.get('month') === (4 - 1) ? -4 : -3, 'month');
        const end = moment().set({ 'year': thisMonth.get('year') + 1, 'month': 3 - 1, 'date': 31 });
        const from = moment().set({ 'year': thisMonth.get('year'), 'month': 4 - 1, 'date': 1 });
        const to = defaultYWOnInit === "fiscalYearQuick" ? end : now.get('month') === (4 - 1) ? end : preMonth;

        return { from: from, to: to };
      }

      const first = true;
      const formto = getMonth(defaultYWOnInit);
      state.ywFrom = new YW({ code: formto.from.format('GGWW'), name: formto.from.format('GGGG年WW月'), thisWeek: false });
      state.yw = new YW({ code: formto.to.format('GGWW'), name: formto.to.format('GGGG年WW月'), thisWeek: first });
      if (state.requestedParam.yw) {
        state.requestedParam.yw = state.yw.code;
        state.requestedParam.ywFrom = state.ywFrom.code;
      }
    }
    else if (defaultYWOnInit == "thisWeek") {
      const now = moment(new Date());
      const thisMonth = moment().set({'year': now.get('year'), 'month': now.get('month'), 'date': 1});
      const first = true;

      state.yw = new YW({ code: thisMonth.format('GGWW'), name: thisMonth.format('GGGG年WW月'), thisWeek: first });
      if (state.requestedParam.yw) {
        state.requestedParam.yw = state.yw.code;
      }
    }
    else {
      if(!state.yw) {
        state.yw = initialState.yw;
      }
      if(!state.ywFrom) {
        state.ywFrom = initialState.ywFrom;
      }
    }

    if (!state.axisType || defaultAxisType) {
      state.axisType = initialState.axisType;
    }
    if(!state.dataType) {
      state.dataType = initialState.dataType;
    }
    if(!state.sort || !state.sort.colKey) {
      state.sort = initialState.sort;
    }
    //初期表示列0配列は、常に全項目表示とする
    if(initialState.visibleColumnsKey.length == 0) {
      state.visibleColumnsKey = colRowModel.colKeys;
    }
    else if(!state.visibleColumnsKey) {
      state.visibleColumnsKey = initialState.visibleColumnsKey;
    }
    //強制選択列
    state.visibleColumnsKey = arrayUtil.union(arrayUtil.and(colRowModel.colKeys, mustVisibleColumnsKey), state.visibleColumnsKey);
    //非表示列
    state.hiddenColumnsKey = arrayUtil.not(colRowModel.colKeys, state.visibleColumnsKey);

    //行項目
    if (!state.visibleRowsKeyMonthlyQuick || state.visibleRowsKeyMonthlyQuick.length == 0) {
      if(state.visibleRowsKeyMonthly?.length) { //移行
        state.visibleRowsKeyMonthlyQuick = state.visibleRowsKeyMonthly;
      }
      else {
        state.visibleRowsKeyMonthlyQuick = (initialState.visibleRowsKeyMonthlyQuick.length == 0) ? colRowModel.rowKeysMonthlyQuick : initialState.visibleRowsKeyMonthlyQuick;
      }
    }
    if (!state.visibleRowsKeyMonthlyCommit || state.visibleRowsKeyMonthlyCommit.length == 0) {
      if(state.visibleRowsKeyMonthly?.length) { //移行
        state.visibleRowsKeyMonthlyCommit = state.visibleRowsKeyMonthly;
      }
      else {
        state.visibleRowsKeyMonthlyCommit = (initialState.visibleRowsKeyMonthlyCommit.length == 0) ? colRowModel.rowKeysMonthlyCommit : initialState.visibleRowsKeyMonthlyCommit;
      }
    }
    state.visibleRowsKeyMonthly = []; //今後不要

    if (!state.visibleRowsKeyDaily || state.visibleRowsKeyDaily.length == 0) {
      state.visibleRowsKeyDaily = (initialState.visibleRowsKeyDaily.length == 0) ? colRowModel.rowKeysDaily : initialState.visibleRowsKeyDaily;
    }

    if (!state.visibleRowsKeyWeekly || state.visibleRowsKeyWeekly.length == 0) {
      state.visibleRowsKeyWeekly = (initialState.visibleRowsKeyWeekly.length == 0) ? colRowModel.rowKeysWeekly : initialState.visibleRowsKeyWeekly;
    }


    //強制選択行
    // state.visibleRowsKeyMonthlyQuick = arrayUtil.union(arrayUtil.and(colRowModel.rowKeysMonthlyQuick, mustVisibleRowsKeyMonthly), state.visibleRowsKeyMonthlyQuick);
    // state.visibleRowsKeyMonthlyCommit = arrayUtil.union(arrayUtil.and(colRowModel.rowKeysMonthlyCommit, mustVisibleRowsKeyMonthly), state.visibleRowsKeyMonthlyCommit);
    // state.visibleRowsKeyDaily = arrayUtil.union(arrayUtil.and(colRowModel.rowKeysDaily, mustVisibleRowsKeyDaily), state.visibleRowsKeyDaily);
    
    //グラフ表示
    if(state.showChart === undefined) {
      state.showChart = initialState.showChart;
    }
    if (!state.group2) {
      state.group2 = initialState.group2;
    } else if (!Array.isArray(state.group2)) {
      state.group2 = [state.group2];
    }
    if (!state.shiten) {
      state.shiten = initialState.shiten;
    } else if (!Array.isArray(state.shiten)) {
      state.shiten = [state.shiten];
    }
    if (!state.center) {
      state.center = initialState.center;
    } else if (!Array.isArray(state.center)) {
      state.center = [state.center];
    }
    if (!state.shiire) {
      state.shiire = initialState.shiire;
    } else if (!Array.isArray(state.shiire)) {
      state.shiire = [state.shiire];
    }
    if (!state.maker) {
      state.maker = initialState.maker;
    } else if (!Array.isArray(state.maker)) {
      state.maker = [state.maker];
    }
    if (!state.category) {
      state.category = initialState.category;
    } else if (!Array.isArray(state.category)) {
      state.category = [state.category];
    }
    if (state.grouping1 === undefined) {
      state.grouping1 = initialState.grouping1;
    }
    if (state.grouping2 === undefined) {
      state.grouping2 = initialState.grouping2;
    }
  },
  //componentWillUnmount
  resetOnWillUnmount(state:SalesListTransSaveState) {
      //初期表示列0配列は、常に全項目表示とするため、記憶しない
      if(initialState.visibleColumnsKey.length == 0) {
        state.visibleColumnsKey = [];
      }
  },

  // Option YM
  setYM(state:SalesListTransSaveState, action: PayloadAction<{val:YM, ymList:YM[]}>) {
    const ymList = action.payload.ymList;
    const val = action.payload.val;
    state.ym = val;
    if(state.ym && state.ymFrom) {
      //逆転
      if(state.ym.code < state.ymFrom.code) {
        state.ymFrom = state.ym;
      }

      //最大12ヵ月とする
      const ymFromIndex = ymList.findIndex(ym => ym.code == state.ymFrom.code);
      const ymIndex = ymList.findIndex(ym => ym.code == state.ym.code);
      if(ymFromIndex - ymIndex + 1 > 12) {
        state.ymFrom = ymList[Math.min(ymIndex + 11, ymList.length-1)];
      }
    }
  },
  setYMFrom(state:SalesListTransSaveState, action: PayloadAction<{val:YM, ymList:YM[]}>) {
    const ymList = action.payload.ymList;
    const val = action.payload.val;
    state.ymFrom = val;
    if(state.ym && state.ymFrom) {
      //逆転
      if(state.ym.code < state.ymFrom.code) {
        state.ym = state.ymFrom;
      }

      //最大12ヵ月とする
      const ymFromIndex = ymList.findIndex(ym => ym.code == state.ymFrom.code);
      const ymIndex = ymList.findIndex(ym => ym.code == state.ym.code);
      if(ymFromIndex - ymIndex + 1 > 12) {
        state.ym = ymList[Math.max(ymFromIndex - 11, 0)];
      }
    }
  },
  setYMList(state:SalesListTransSaveState, action: PayloadAction<YM[]>) {
    const ymList = action.payload;
    //ym
    let ym = state.ym && state.ym.code? ymList.find((ym) => ym.code == state.ym.code) : null;
    if(!ym) {
      switch (dataTypeIs(state.requestedParam && state.requestedParam.dataType ? state.requestedParam.dataType : initialState.dataType)) {
        case "daily":
          ym = ymList.find(data => data.thisMonth);
          break;
        case "monthly":
          ym = ymList[0];
          break;
        default:
          break;
      }
    }
    state.ym = ym;
    //ymFrom
    let ymFrom = state.ymFrom && state.ymFrom.code? ymList.find((ym) => ym.code == state.ymFrom.code) : null;
    if(!ymFrom) {
      ymFrom = ymList[Math.min(ymList.indexOf(ym)+11, ymList.length-1)]; //１年間
    }
    state.ymFrom = ymFrom;
  },

  // Option YW
  setYW(state:SalesListTransSaveState, action: PayloadAction<{val:YW, ywList:YW[]}>) {
    const ywList = action.payload.ywList;
    const val = action.payload.val;
    state.yw = val;
    if(state.yw && state.ywFrom) {
      //逆転
      if(state.yw.code < state.ywFrom.code) {
        state.ywFrom = state.yw;
      }

      //最大53週とする
      const ywFromIndex = ywList.findIndex(yw => yw.code == state.ywFrom.code);
      const ywIndex = ywList.findIndex(yw => yw.code == state.yw.code);
      if(ywFromIndex - ywIndex + 1 > 53) {
        state.ywFrom = ywList[Math.min(ywIndex + 52, ywList.length-1)];
      }
    }
  },
  setYWFrom(state:SalesListTransSaveState, action: PayloadAction<{val:YW, ywList:YW[]}>) {
    const ywList = action.payload.ywList;
    const val = action.payload.val;
    state.ywFrom = val;
    if(state.yw && state.ywFrom) {
      //逆転
      if(state.yw.code < state.ywFrom.code) {
        state.yw = state.ywFrom;
      }

      //最大53週とする
      const ywFromIndex = ywList.findIndex(yw => yw.code == state.ywFrom.code);
      const ywIndex = ywList.findIndex(yw => yw.code == state.yw.code);
      if(ywFromIndex - ywIndex + 1 > 53) {
        state.yw = ywList[Math.max(ywFromIndex - 52, 0)];
      }
    }
  },
  setYWList(state:SalesListTransSaveState, action: PayloadAction<YW[]>) {
    const ywList = action.payload;
    //yw
    let yw = state.yw && state.yw.code? ywList.find((yw) => yw.code == state.yw.code) : null;
    if(!yw) {
      switch (dataTypeIs(state.requestedParam && state.requestedParam.dataType ? state.requestedParam.dataType : initialState.dataType)) {
        case "weekly":
          yw = ywList[0];
          break;
        default:
          break;
      }
    }
    state.yw = yw;
    //ywFrom
    let ywFrom = state.ywFrom && state.ywFrom.code? ywList.find((yw) => yw.code == state.ywFrom.code) : null;
    if(!ywFrom) {
      ywFrom = ywList[Math.min(ywList.indexOf(yw)+11, ywList.length-1)]; //１年間
    }
    state.ywFrom = ywFrom;
  },

  //集計軸
  setAxisType(state:SalesListTransSaveState, action: PayloadAction<string>) {
    // let axisType = action.payload;
    // let axisTypes = ["group2","shiten","maker"];
    // let index = axisTypes.indexOf(axisType);

    switch(action.payload) {
      case "group2":
        state.axisType = "group2";
        break;
      case "shiten":
        state.axisType = "shiten";
        break;
      case "center":
        state.axisType = "center";
        break;
      case "shiire":
        state.axisType = "shiire";
        break;
      case "maker":
        state.axisType = "maker";
        break;
      case "category":
        state.axisType = "category";
        break;
      default:
        state.axisType = "group2";
        break;
    }
  },
  resetAxisType(state) {
    state.axisType = initialState.axisType;
  },
  //データ種別
  setDataType(state:SalesListTransSaveState, action: PayloadAction<string>) {
    switch(action.payload) {
      case "daily":
        state.dataType = "daily";
        break;
      case "quick":
        state.dataType = "quick";
        break;
      case "commit":
        state.dataType = "commit";
        break;
      default:
        state.dataType = "quick";
        break;
    }
  },
  setGroup2(state: SalesListTransSaveState, action: PayloadAction<CodeName[]>) {
    state.group2 = action.payload;
  },
  setShiten(state: SalesListTransSaveState, action: PayloadAction<CodeName[]>) {
    state.shiten = action.payload;
  },
  setCenter(state: SalesListTransSaveState, action: PayloadAction<CodeName[]>) {
    state.center = action.payload;
  },
  setShiire(state: SalesListTransSaveState, action: PayloadAction<CodeName[]>) {
    state.shiire = action.payload;
  },
  setMaker(state: SalesListTransSaveState, action: PayloadAction<CodeName[]>) {
    state.maker = action.payload;
  },
  setCategory(state: SalesListTransSaveState, action: PayloadAction<CodeName[]>) {
    state.category = action.payload;
  },
  //検索対象
  setContainSEJ(state:SalesListTransSaveState, action: PayloadAction<boolean>) {
    state.containSEJ = action.payload;
  },

  clearOption(state:SalesListTransSaveState, action: PayloadAction<{
    ymList:YM[],
    ywList:YW[],
  }>) {
    const _dataTypeIs = dataTypeIs(state.requestedParam && state.requestedParam.dataType ? state.requestedParam.dataType : initialState.dataType);
    Object.assign(state, {
      ym: _dataTypeIs != "daily" ? action.payload.ymList[0] : action.payload.ymList.find(data => data.thisMonth),
      ymFrom: action.payload.ymList[11],
      yw: action.payload.ywList[0],
      ywFrom: action.payload.ywList[52],
      axisType: initialState.axisType,
      dataType: initialState.dataType,
      containSEJ: initialState.containSEJ,
      group2: initialState.group2,
      shiten: initialState.shiten,
      center: initialState.center,
      shiire: initialState.shiire,
      maker: initialState.maker,
      category: initialState.category,
    });
  },

  //検索条件
  setRequestedParam(state:SalesListTransSaveState, action: PayloadAction<RequestParam>) {
    state.requestedParam = action.payload;
  },

  setSort(state:SalesListTransSaveState, action: PayloadAction<{sortColKey:string, sortRowKey:string, sortAsc: boolean}>) {
    const colKey = action.payload.sortColKey;
    const rowKey = action.payload.sortRowKey;
    const asc = action.payload.sortAsc;
    state.sort = {
      colKey: colKey,
      rowKey: rowKey,
      asc: asc,
    }
  },
  hideColumnsChange(state:SalesListTransSaveState, action: PayloadAction<{columns:number[], keys:string[]}>){
    console.log('store.hideColumnsChange');
    Object.assign(state, {
      hiddenColumns: action.payload.columns,
      hiddenColumnsKey: action.payload.keys,
      visibleColumnsKey: arrayUtil.not(colRowModel.colKeys, action.payload.keys),
    });
  },
  hideColumnKeysChange(state:SalesListTransSaveState, action: PayloadAction<string[]>){
    console.log('store.hideColumnKeysChange');
    Object.assign(state, {
      hiddenColumns: colRowModel.colFromKeys(action.payload),
      hiddenColumnsKey: action.payload,
      visibleColumnsKey: arrayUtil.not(colRowModel.colKeys, action.payload),
    });
  },
  visibleRowKeysChange(state: SalesListTransSaveState, action: PayloadAction<{ keys: string[], dataType?: "daily" | "quick" | "commit" | null }>) {
    console.log('store.visibleRowKeysChange');
    const keys = action.payload.keys;

    const dataType: "daily" | "quick" | "commit" | "weekly" | null = state.requestedParam?.dataType ? state.requestedParam?.dataType : action.payload.dataType;
    state.shareDataType = action.payload.dataType;
    switch (dataType) {
      case "daily":
        Object.assign(state, {
          visibleRowsKeyDaily: keys,
        });
        break;
      case "quick":
        Object.assign(state, {
          visibleRowsKeyMonthlyQuick: keys,
        });
        break;
      case "commit":
        Object.assign(state, {
          visibleRowsKeyMonthlyCommit: keys,
        });
        break;
      case "weekly":
        Object.assign(state, {
          visibleRowsKeyWeekly: keys,
        });
        break;
      default:
        break;
    }
  },
  showChartChange(state:SalesListTransSaveState, action: PayloadAction<boolean>){
    console.log('store.showChartChange');
    state.showChart = action.payload;
  },
  setGrouping1(state: SalesListTransSaveState, action: PayloadAction<boolean>) {
    state.grouping1 = action.payload;
  },
  setGrouping2(state: SalesListTransSaveState, action: PayloadAction<boolean>) {
    state.grouping2 = action.payload;
  },
}};

const createSliceContent = (name: string, colRowModel: SalesListTransColRowModel, initialState: SalesListTransSaveState, 
  defaultYMOnInit: "" | "fiscalYearQuick" | "fiscalYearCommit" | "thisMonth", 
  defaultYWOnInit: "" | "fiscalYearQuick" | "fiscalYearCommit" | "thisWeek", 
  defaultAxisType: "" | "init") => createSlice({
  name: name,
  initialState: initialState,
  reducers: createReducerContent(name, colRowModel, initialState, defaultYMOnInit, defaultYWOnInit, defaultAxisType),
});

//集計軸
export const axisName = (axis: "group1" | "group2" | "shiten" | "center" | "shiire" | "maker" | "tokuisaki" | "category" | string) => {
  switch(axis) {
    case "group1":
      return "企業グループ1";
    case "group2":
      return "企業グループ2";
    case "shiten":
      return "支店";
    case "center":
      return "倉庫";
    case "shiire":
      return "仕入先";
    case "maker":
      return "メーカー";
    case "tokuisaki":
      return "得意先";
    case "category":
      return "商品カテゴリ";
      default:
      return "-";
  }
}

//データ種別
export const dataTypeName = (dataType: "daily" | "quick" | "commit" | string) => {
  switch(dataType) {
    case "daily":
      return "日次速報";
    case "quick":
      return "月次速報";
    case "commit":
      return "月次確報";
    default:
      return "-";
  }
}
//データ種別の月次判定
export const dataTypeIs = (dataType: "daily" | "quick" | "commit" | "weekly" | string):"daily"|"weekly"|"monthly" => {
  switch(dataType) {
    case "daily":
      return "daily";
    case "weekly":
      return "weekly";
    case "quick":
    case "commit":
    default:
      return "monthly";
  }
}
//検索対象
export const containSEJName = (containSEJ:boolean) => {
  if(containSEJ) {
    return "SEJを含む";
  }
  else {
    return "一般";
  }
}

const getData = (target: CodeName, list: CodeName[]): CodeName[] => {
  let datas = [];
  if (target) {
    datas = list.filter(data => target.code == data.code);
  }
  if (datas.length > 0) {
    return datas[0];
  }
  return null;
};

//Page Slice Export
//salesListTransSaveSlice
export const salesListTransSaveSlice = createSliceContent("salesListTransSave", salesListTransColRowModelDefault, initialSalesListTransState, "", "", "");
export const salesListTransReport11SaveSlice = createSliceContent("salesListTransReport11Save", salesListTransColRowModelReport11, initialSalesListTransReport11State, "thisMonth", "", "");
export const salesListTransReport15SaveSlice = createSliceContent("salesListTransReport15Save", salesListTransColRowModelReport15, initialSalesListTransReport15State, "fiscalYearQuick", "", "");
export const salesListTransReport16SaveSlice = createSliceContent("salesListTransReport16Save", salesListTransColRowModelReport16, initialSalesListTransReport16State, "", "fiscalYearQuick", "");
export const salesListTransReport35SaveSlice = createSliceContent("salesListTransReport35Save", salesListTransColRowModelReport35, initialSalesListTransReport35State, "fiscalYearCommit", "", "");
export const salesListTransReport45SaveSlice = createSliceContent("salesListTransReport45Save", salesListTransColRowModelReport45, initialSalesListTransReport45State, "", "", "init");
