import { CodeName } from "@/store/common";

//行固定
export const fixedRowsTop = 1;

export const drillDownMaxLv = 11;
// export const drillDownTotalMaxLv = ['total', 'lv1', 'lv2', 'lv3', 'lv4', 'lv5', 'lv6', 'lv7', 'lv8', 'lv9', 'lv10', 'lv11'];
export const drillDownLv1MaxLv = ['lv1', 'lv2', 'lv3', 'lv4', 'lv5', 'lv6', 'lv7', 'lv8', 'lv9', 'lv10', 'lv11'];
// export const drillDownLv2MaxLv = ['lv2', 'lv3', 'lv4', 'lv5', 'lv6', 'lv7', 'lv8', 'lv9', 'lv10', 'lv11'];

export const headers = [
  {
    no : "",
  //以下に貼り付け
  ISC: "商品属性", ISN: "商品属性", IMC: "商品属性", IMN: "商品属性", INM: "商品属性", ICC: "商品属性", ICN: "商品属性", IBC: "商品属性", IBN: "商品属性", IJN: "商品属性", ICD: "商品属性", ICP: "商品属性", IC1: "商品属性", IC2: "商品属性", ILI: "商品属性", IPR: "商品属性",
  SCD: "内訳", SNM: "内訳",
  CCD: "内訳", CNM: "内訳",
  TCD: "内訳", TNM: "内訳",
  G2CD: "内訳", G2NM: "内訳",
  G1CD: "内訳", G1NM: "内訳",
  LQT: "販売数量（BL数）", LQP: "販売数量（BL数）", LQR: "販売数量（BL数）", LQD: "販売数量（BL数）",
  DQT: "販売数量（BL数）", DQP: "販売数量（BL数）", DQR: "販売数量（BL数）", DQD: "販売数量（BL数）",
  OQT: "販売数量（BL数）", OQP: "販売数量（BL数）", OQR: "販売数量（BL数）", OQD: "販売数量（BL数）",
  ULT: "販売数量（BL数）", ULP: "販売数量（BL数）", ULR: "販売数量（BL数）", ULD: "販売数量（BL数）",
  UDT: "販売数量（BL数）", UDP: "販売数量（BL数）", UDR: "販売数量（BL数）", UDD: "販売数量（BL数）",
  UPT: "販売数量（BL数）", UPP: "販売数量（BL数）", UPR: "販売数量（BL数）", UPD: "販売数量（BL数）",
  SQT: "販売数量（BL数）", SQP: "販売数量（BL数）", SQR: "販売数量（BL数）", SQD: "販売数量（BL数）",
  RQT: "販売数量（BL数）", RQP: "販売数量（BL数）", RQR: "販売数量（BL数）", RQD: "販売数量（BL数）",
  WQT: "販売数量（BL数）", WQP: "販売数量（BL数）", WQR: "販売数量（BL数）", WQD: "販売数量（BL数）",
  LQT_PS: "販売数量（バラ数）", LQP_PS: "販売数量（バラ数）", LQR_PS: "販売数量（バラ数）", LQD_PS: "販売数量（バラ数）",
  DQT_PS: "販売数量（バラ数）", DQP_PS: "販売数量（バラ数）", DQR_PS: "販売数量（バラ数）", DQD_PS: "販売数量（バラ数）",
  OQT_PS: "販売数量（バラ数）", OQP_PS: "販売数量（バラ数）", OQR_PS: "販売数量（バラ数）", OQD_PS: "販売数量（バラ数）",
  ULT_PS: "販売数量（バラ数）", ULP_PS: "販売数量（バラ数）", ULR_PS: "販売数量（バラ数）", ULD_PS: "販売数量（バラ数）",
  UDT_PS: "販売数量（バラ数）", UDP_PS: "販売数量（バラ数）", UDR_PS: "販売数量（バラ数）", UDD_PS: "販売数量（バラ数）",
  UPT_PS: "販売数量（バラ数）", UPP_PS: "販売数量（バラ数）", UPR_PS: "販売数量（バラ数）", UPD_PS: "販売数量（バラ数）",
  SQT_PS: "販売数量（バラ数）", SQP_PS: "販売数量（バラ数）", SQR_PS: "販売数量（バラ数）", SQD_PS: "販売数量（バラ数）",
  RQT_PS: "販売数量（バラ数）", RQP_PS: "販売数量（バラ数）", RQR_PS: "販売数量（バラ数）", RQD_PS: "販売数量（バラ数）",
  WQT_PS: "販売数量（バラ数）", WQP_PS: "販売数量（バラ数）", WQR_PS: "販売数量（バラ数）", WQD_PS: "販売数量（バラ数）",
  JJT: "受注", JJTK: "受注", JJP: "受注", JJPK: "受注", JJR: "受注", JJD: "受注", 
  JJQT: "受注", JJTQK: "受注", JJQP: "受注", JJPQK: "受注", JJQR: "受注", JJQD: "受注", 
  JKT: "欠品", JKTK: "欠品", JKP: "欠品", JKPK: "欠品", JKR: "欠品", JKD: "欠品", 
  JKQT: "欠品", JKTQK: "欠品", JKQP: "欠品", JKPQK: "欠品", JKQR: "欠品", JKQD: "欠品", 
  JRT: "欠品", JRP: "欠品", JRD: "欠品", 
  //欠品責任別
  KI1KQ: "欠品責任別", KI1KA: "欠品責任別", KI1KR: "欠品責任別", 
  KI2KQ: "欠品責任別", KI2KA: "欠品責任別", KI2KR: "欠品責任別", 
  KI3KQ: "欠品責任別", KI3KA: "欠品責任別", KI3KR: "欠品責任別", 
  //欠品区分別
  K10KQ: "欠品区分別(1.自社責)", K10KA: "欠品区分別(1.自社責)", K10KR: "欠品区分別(1.自社責)", 
  K11KQ: "欠品区分別(1.自社責)", K11KA: "欠品区分別(1.自社責)", K11KR: "欠品区分別(1.自社責)", 
  K12KQ: "欠品区分別(1.自社責)", K12KA: "欠品区分別(1.自社責)", K12KR: "欠品区分別(1.自社責)", 
  K13KQ: "欠品区分別(1.自社責)", K13KA: "欠品区分別(1.自社責)", K13KR: "欠品区分別(1.自社責)", 
  K14KQ: "欠品区分別(1.自社責)", K14KA: "欠品区分別(1.自社責)", K14KR: "欠品区分別(1.自社責)", 
  K15KQ: "欠品区分別(1.自社責)", K15KA: "欠品区分別(1.自社責)", K15KR: "欠品区分別(1.自社責)", 
  K16KQ: "欠品区分別(1.自社責)", K16KA: "欠品区分別(1.自社責)", K16KR: "欠品区分別(1.自社責)", 
  K17KQ: "欠品区分別(1.自社責)", K17KA: "欠品区分別(1.自社責)", K17KR: "欠品区分別(1.自社責)", 
  K19KQ: "欠品区分別(1.自社責)", K19KA: "欠品区分別(1.自社責)", K19KR: "欠品区分別(1.自社責)", 
  K20KQ: "欠品区分別(2.メーカー責)", K20KA: "欠品区分別(2.メーカー責)", K20KR: "欠品区分別(2.メーカー責)", 
  K21KQ: "欠品区分別(2.メーカー責)", K21KA: "欠品区分別(2.メーカー責)", K21KR: "欠品区分別(2.メーカー責)", 
  K22KQ: "欠品区分別(2.メーカー責)", K22KA: "欠品区分別(2.メーカー責)", K22KR: "欠品区分別(2.メーカー責)", 
  K29KQ: "欠品区分別(2.メーカー責)", K29KA: "欠品区分別(2.メーカー責)", K29KR: "欠品区分別(2.メーカー責)", 
  K30KQ: "欠品区分別(3.得意先責)", K30KA: "欠品区分別(3.得意先責)", K30KR: "欠品区分別(3.得意先責)", 
  K31KQ: "欠品区分別(3.得意先責)", K31KA: "欠品区分別(3.得意先責)", K31KR: "欠品区分別(3.得意先責)", 
  K32KQ: "欠品区分別(3.得意先責)", K32KA: "欠品区分別(3.得意先責)", K32KR: "欠品区分別(3.得意先責)", 
  K33KQ: "欠品区分別(3.得意先責)", K33KA: "欠品区分別(3.得意先責)", K33KR: "欠品区分別(3.得意先責)", 
  K41KQ: "欠品区分別(3.得意先責)", K41KA: "欠品区分別(3.得意先責)", K41KR: "欠品区分別(3.得意先責)", 
  K34KQ: "欠品区分別(3.得意先責)", K34KA: "欠品区分別(3.得意先責)", K34KR: "欠品区分別(3.得意先責)", 
  K39KQ: "欠品区分別(3.得意先責)", K39KA: "欠品区分別(3.得意先責)", K39KR: "欠品区分別(3.得意先責)", 
  K40KQ: "欠品区分別(3.得意先責)", K40KA: "欠品区分別(3.得意先責)", K40KR: "欠品区分別(3.得意先責)", 
  K42KQ: "欠品区分別(3.得意先責)", K42KA: "欠品区分別(3.得意先責)", K42KR: "欠品区分別(3.得意先責)", 
  K43KQ: "欠品区分別(3.得意先責)", K43KA: "欠品区分別(3.得意先責)", K43KR: "欠品区分別(3.得意先責)", 
  K44KQ: "欠品区分別(3.得意先責)", K44KA: "欠品区分別(3.得意先責)", K44KR: "欠品区分別(3.得意先責)", 
  K49KQ: "欠品区分別(3.得意先責)", K49KA: "欠品区分別(3.得意先責)", K49KR: "欠品区分別(3.得意先責)", 
  K50KQ: "欠品区分別(3.得意先責)", K50KA: "欠品区分別(3.得意先責)", K50KR: "欠品区分別(3.得意先責)", 

  SLT: "総売上", SLP: "総売上", SLR: "総売上", SLD: "総売上", SDT: "総売上", SDP: "総売上", SDR: "総売上", SDD: "総売上", SRT: "総売上", SRP: "総売上", SRR: "総売上", SRD: "総売上", STT: "総売上", STP: "総売上", STR: "総売上", STD: "総売上",
  PTT: "売買差益", PTP: "売買差益", PTD: "売買差益", PRT: "売買差益", PRP: "売買差益", PRD: "売買差益",
  CIT: "修正", CIP: "修正", CIR: "修正", CID: "修正", CST: "修正", CSP: "修正", CSR: "修正", CSD: "修正", CTT: "修正", CTP: "修正", CTR: "修正", CTD: "修正",
  BTT: "総利益", BTP: "総利益", BTR: "総利益", BTD: "総利益", BRT: "総利益", BRP: "総利益", BRD: "総利益", ATT: "総利益", ATP: "総利益", ATR: "総利益", ATD: "総利益", ART: "総利益", ARP: "総利益", ARD: "総利益",
  SHCT: "仕入実績", SHCP: "仕入実績", SHCR: "仕入実績", SHCD2: "仕入実績", 
  SHDT: "仕入実績", SHDP: "仕入実績", SHDR: "仕入実績", SHDD: "仕入実績", 
  SHRCT: "仕入実績", SHRCP: "仕入実績", SHRCR: "仕入実績", SHRCD: "仕入実績", 
  SHRDT: "仕入実績", SHRDP: "仕入実績", SHRDR: "仕入実績", SHRDD: "仕入実績", 
  SHT: "仕入実績", SHP: "仕入実績", SHR: "仕入実績", SHD: "仕入実績", 
  KUR: "売上構成比", KTR: "売上構成比", KRK: "売上構成比",
  ZTN: "在庫", RQTY: "在庫", TQTY: "在庫", YQTY: "在庫", FQTY: "在庫", AZKE: "在庫", ZQTY: "在庫",
  SQTC: "納品実績", SQTN: "納品実績",
  TGTL: "定番該当企業",
  TG_ALL: "定番該当企業",
  TG01: "定番該当企業", TG02: "定番該当企業", TG03: "定番該当企業", TG04: "定番該当企業", TG05: "定番該当企業", TG06: "定番該当企業", TG07: "定番該当企業", TG08: "定番該当企業", TG09: "定番該当企業", TG10: "定番該当企業",
  TG11: "定番該当企業", TG12: "定番該当企業", TG13: "定番該当企業", TG14: "定番該当企業", TG15: "定番該当企業", TG16: "定番該当企業", TG17: "定番該当企業", TG18: "定番該当企業", TG19: "定番該当企業", TG20: "定番該当企業",
  TG21: "定番該当企業", TG22: "定番該当企業", TG23: "定番該当企業", TG24: "定番該当企業", TG25: "定番該当企業", TG26: "定番該当企業", TG27: "定番該当企業", TG28: "定番該当企業", TG29: "定番該当企業", TG30: "定番該当企業",
  TG31: "定番該当企業", TG32: "定番該当企業", TG33: "定番該当企業", TG34: "定番該当企業", TG35: "定番該当企業", TG36: "定番該当企業", TG37: "定番該当企業", TG38: "定番該当企業", TG39: "定番該当企業", TG40: "定番該当企業",
  TG41: "定番該当企業", TG42: "定番該当企業", TG43: "定番該当企業", TG44: "定番該当企業", TG45: "定番該当企業", TG46: "定番該当企業", TG47: "定番該当企業", TG48: "定番該当企業", TG49: "定番該当企業", TG50: "定番該当企業",
  SGTL: "当月出荷企業",
  SG_ALL: "当月出荷企業",
  SG01: "当月出荷企業", SG02: "当月出荷企業", SG03: "当月出荷企業", SG04: "当月出荷企業", SG05: "当月出荷企業", SG06: "当月出荷企業", SG07: "当月出荷企業", SG08: "当月出荷企業", SG09: "当月出荷企業", SG10: "当月出荷企業",
  SG11: "当月出荷企業", SG12: "当月出荷企業", SG13: "当月出荷企業", SG14: "当月出荷企業", SG15: "当月出荷企業", SG16: "当月出荷企業", SG17: "当月出荷企業", SG18: "当月出荷企業", SG19: "当月出荷企業", SG20: "当月出荷企業",
  SG21: "当月出荷企業", SG22: "当月出荷企業", SG23: "当月出荷企業", SG24: "当月出荷企業", SG25: "当月出荷企業", SG26: "当月出荷企業", SG27: "当月出荷企業", SG28: "当月出荷企業", SG29: "当月出荷企業", SG30: "当月出荷企業",
  SG31: "当月出荷企業", SG32: "当月出荷企業", SG33: "当月出荷企業", SG34: "当月出荷企業", SG35: "当月出荷企業", SG36: "当月出荷企業", SG37: "当月出荷企業", SG38: "当月出荷企業", SG39: "当月出荷企業", SG40: "当月出荷企業",
  SG41: "当月出荷企業", SG42: "当月出荷企業", SG43: "当月出荷企業", SG44: "当月出荷企業", SG45: "当月出荷企業", SG46: "当月出荷企業", SG47: "当月出荷企業", SG48: "当月出荷企業", SG49: "当月出荷企業", SG50: "当月出荷企業",
  },
  {
    no : "No.",
  //以下に貼り付け
  ISC: "仕入先", ISN: "仕入先", IMC: "メーカー", IMN: "メーカー", INM: "商品名", ICC: "カテゴリ", ICN: "カテゴリ", IBC: "PB区分", IBN: "PB区分", IJN: "JAN", ICD: "商品CD", ICP: "内容量", IC1: "入数", IC2: "入数", ILI: "賞味期限", IPR: "定価",
  SCD: "支店", SNM: "支店",
  CCD: "倉庫", CNM: "倉庫",
  TCD: "得意先", TNM: "得意先",
  G2CD: "企業グループ２", G2NM: "企業グループ２",
  G1CD: "企業グループ１", G1NM: "企業グループ１",
  LQT: "配送出荷BL数", LQP: "配送出荷BL数", LQR: "配送出荷BL数", LQD: "配送出荷BL数",
  DQT: "直送出荷BL数", DQP: "直送出荷BL数", DQR: "直送出荷BL数", DQD: "直送出荷BL数",
  OQT: "出荷BL数", OQP: "出荷BL数", OQR: "出荷BL数", OQD: "出荷BL数",
  ULT: "配送未計上売上BL数", ULP: "配送未計上売上BL数", ULR: "配送未計上売上BL数", ULD: "配送未計上売上BL数",
  UDT: "直送未計上売上BL数", UDP: "直送未計上売上BL数", UDR: "直送未計上売上BL数", UDD: "直送未計上売上BL数",
  UPT: "未計上売上BL数", UPP: "未計上売上BL数", UPR: "未計上売上BL数", UPD: "未計上売上BL数",
  SQT: "販売BL数", SQP: "販売BL数", SQR: "販売BL数", SQD: "販売BL数",
  RQT: "返品BL数", RQP: "返品BL数", RQR: "返品BL数", RQD: "返品BL数",
  WQT: "廃棄BL数", WQP: "廃棄BL数", WQR: "廃棄BL数", WQD: "廃棄BL数",
  LQT_PS: "配送出荷バラ数", LQP_PS: "配送出荷バラ数", LQR_PS: "配送出荷バラ数", LQD_PS: "配送出荷バラ数",
  DQT_PS: "直送出荷バラ数", DQP_PS: "直送出荷バラ数", DQR_PS: "直送出荷バラ数", DQD_PS: "直送出荷バラ数",
  OQT_PS: "出荷バラ数", OQP_PS: "出荷バラ数", OQR_PS: "出荷バラ数", OQD_PS: "出荷バラ数",
  ULT_PS: "配送未計上売上バラ数", ULP_PS: "配送未計上売上バラ数", ULR_PS: "配送未計上売上バラ数", ULD_PS: "配送未計上売上バラ数",
  UDT_PS: "直送未計上売上バラ数", UDP_PS: "直送未計上売上バラ数", UDR_PS: "直送未計上売上バラ数", UDD_PS: "直送未計上売上バラ数",
  UPT_PS: "未計上売上バラ数", UPP_PS: "未計上売上バラ数", UPR_PS: "未計上売上バラ数", UPD_PS: "未計上売上バラ数",
  SQT_PS: "販売バラ数", SQP_PS: "販売バラ数", SQR_PS: "販売バラ数", SQD_PS: "販売バラ数",
  RQT_PS: "返品バラ数", RQP_PS: "返品バラ数", RQR_PS: "返品バラ数", RQD_PS: "返品バラ数",
  WQT_PS: "廃棄バラ数", WQP_PS: "廃棄バラ数", WQR_PS: "廃棄バラ数", WQD_PS: "廃棄バラ数",
  JJT: "受注額", JJTK: "受注額", JJP: "受注額", JJPK: "受注額", JJR: "受注額", JJD: "受注額", 
  JJQT: "受注BL数", JJTQK: "受注BL数", JJQP: "受注BL数", JJPQK: "受注BL数", JJQR: "受注BL数", JJQD: "受注BL数", 
  JKT: "欠品額", JKTK: "欠品額", JKP: "欠品額", JKPK: "欠品額", JKR: "欠品額", JKD: "欠品額", 
  JKQT: "欠品BL数", JKTQK: "欠品BL数", JKQP: "欠品BL数", JKPQK: "欠品BL数", JKQR: "欠品BL数", JKQD: "欠品BL数", 
  JRT: "欠品率", JRP: "欠品率", JRD: "欠品率", 
  //欠品責任別
  KI1KQ: '1.自社責', KI1KA: '1.自社責', KI1KR: '1.自社責', 
  KI2KQ: '2.メーカー責', KI2KA: '2.メーカー責', KI2KR: '2.メーカー責', 
  KI3KQ: '3.得意先責', KI3KA: '3.得意先責', KI3KR: '3.得意先責', 

  //欠品区分別
  K10KQ: '10.未入力', K10KA: '10.未入力', K10KR: '10.未入力', 
  K11KQ: '11.発注漏れ', K11KA: '11.発注漏れ', K11KR: '11.発注漏れ', 
  K12KQ: '12.終売案内漏れ', K12KA: '12.終売案内漏れ', K12KR: '12.終売案内漏れ', 
  K13KQ: '13.※※使用不可※※', K13KA: '13.※※使用不可※※', K13KR: '13.※※使用不可※※', 
  K14KQ: '14.発注数量予測差異', K14KA: '14.発注数量予測差異', K14KR: '14.発注数量予測差異', 
  K15KQ: '15.自社出荷ミス', K15KA: '15.自社出荷ミス', K15KR: '15.自社出荷ミス', 
  K16KQ: '16.出荷許容切れ', K16KA: '16.出荷許容切れ', K16KR: '16.出荷許容切れ', 
  K17KQ: '17.特売連絡漏', K17KA: '17.特売連絡漏', K17KR: '17.特売連絡漏', 
  K19KQ: '19.自社その他', K19KA: '19.自社その他', K19KR: '19.自社その他', 
  K20KQ: '20.メーカー欠品', K20KA: '20.メーカー欠品', K20KR: '20.メーカー欠品', 
  K21KQ: '21.配送', K21KA: '21.配送', K21KR: '21.配送', 
  K22KQ: '22.天災', K22KA: '22.天災', K22KR: '22.天災', 
  K29KQ: '29.メーカー欠品その他', K29KA: '29.メーカー欠品その他', K29KR: '29.メーカー欠品その他', 
  K30KQ: '30.数量訂正・発注取消', K30KA: '30.数量訂正・発注取消', K30KR: '30.数量訂正・発注取消', 
  K31KQ: '31.得意先フライング', K31KA: '31.得意先フライング', K31KR: '31.得意先フライング', 
  K32KQ: '32.※※使用不可※※', K32KA: '32.※※使用不可※※', K32KR: '32.※※使用不可※※', 
  K33KQ: '33.※※使用不可※※', K33KA: '33.※※使用不可※※', K33KR: '33.※※使用不可※※', 
  K41KQ: '41.納品日エラー', K41KA: '41.納品日エラー', K41KR: '41.納品日エラー', 
  K34KQ: '34.得意先定番外・カット', K34KA: '34.得意先定番外・カット', K34KR: '34.得意先定番外・カット', 
  K39KQ: '39.※※使用不可※※', K39KA: '39.※※使用不可※※', K39KR: '39.※※使用不可※※', 
  K40KQ: '40.得意先商品CDエラー', K40KA: '40.得意先商品CDエラー', K40KR: '40.得意先商品CDエラー', 
  K42KQ: '42.得意先入数エラー', K42KA: '42.得意先入数エラー', K42KR: '42.得意先入数エラー', 
  K43KQ: '43.単価エラー', K43KA: '43.単価エラー', K43KR: '43.単価エラー', 
  K44KQ: '44.※※使用不可※※', K44KA: '44.※※使用不可※※', K44KR: '44.※※使用不可※※', 
  K49KQ: '49.※※使用不可※※', K49KA: '49.※※使用不可※※', K49KR: '49.※※使用不可※※', 
  K50KQ: '50.得意先検品ミス', K50KA: '50.得意先検品ミス', K50KR: '50.得意先検品ミス', 

  SLT: "配送売上", SLP: "配送売上", SLR: "配送売上", SLD: "配送売上", SDT: "直送売上", SDP: "直送売上", SDR: "直送売上", SDD: "直送売上", SRT: "返品", SRP: "返品", SRR: "返品", SRD: "返品", STT: "総売上", STP: "総売上", STR: "総売上", STD: "総売上",
  PTT: "売買差益計", PTP: "売買差益計", PTD: "売買差益計", PRT: "売買差益率", PRP: "売買差益率", PRD: "売買差益率",
  CIT: "入力修正", CIP: "入力修正", CIR: "入力修正", CID: "入力修正", CST: "黒修正", CSP: "黒修正", CSR: "黒修正", CSD: "黒修正", CTT: "修正合計", CTP: "修正合計", CTR: "修正合計", CTD: "修正合計",
  BTT: "修正前企業粗利", BTP: "修正前企業粗利", BTR: "修正前企業粗利", BTD: "修正前企業粗利", BRT: "修正前企業粗利率", BRP: "修正前企業粗利率", BRD: "修正前企業粗利率", ATT: "修正後企業粗利", ATP: "修正後企業粗利", ATR: "修正後企業粗利", ATD: "修正後企業粗利", ART: "修正後企業粗利率", ARP: "修正後企業粗利率", ARD: "修正後企業粗利率",
  SHCT: "仕入（倉入）", SHCP: "仕入（倉入）", SHCR: "仕入（倉入）", SHCD2: "仕入（倉入）", 
  SHDT: "仕入（直送）", SHDP: "仕入（直送）", SHDR: "仕入（直送）", SHDD: "仕入（直送）", 
  SHRCT: "仕入返品（倉入）", SHRCP: "仕入返品（倉入）", SHRCR: "仕入返品（倉入）", SHRCD: "仕入返品（倉入）", 
  SHRDT: "仕入返品（直送）", SHRDP: "仕入返品（直送）", SHRDR: "仕入返品（直送）", SHRDD: "仕入返品（直送）", 
  SHT: "仕入合計", SHP: "仕入合計", SHR: "仕入合計", SHD: "仕入合計",
  KUR: "配送売上", KTR: "配送売上", KRK: "配送売上",
  ZTN: "棚番", RQTY: "保有B数", TQTY: "保有B数", YQTY: "保有B数", FQTY: "保有B数", AZKE: "保有B数", ZQTY: "保有B数",
  SQTC: "当月%YYM%", SQTN: "翌月%YYM%",
  TGTL: "該当アイテム数",
  TG_ALL: "企業",
  TG01: "%企業T01%", TG02: "%企業T02%", TG03: "%企業T03%", TG04: "%企業T04%", TG05: "%企業T05%", TG06: "%企業T06%", TG07: "%企業T07%", TG08: "%企業T08%", TG09: "%企業T09%", TG10: "%企業T10%",
  TG11: "%企業T11%", TG12: "%企業T12%", TG13: "%企業T13%", TG14: "%企業T14%", TG15: "%企業T15%", TG16: "%企業T16%", TG17: "%企業T17%", TG18: "%企業T18%", TG19: "%企業T19%", TG20: "%企業T20%",
  TG21: "%企業T21%", TG22: "%企業T22%", TG23: "%企業T23%", TG24: "%企業T24%", TG25: "%企業T25%", TG26: "%企業T26%", TG27: "%企業T27%", TG28: "%企業T28%", TG29: "%企業T29%", TG30: "%企業T30%",
  TG31: "%企業T31%", TG32: "%企業T32%", TG33: "%企業T33%", TG34: "%企業T34%", TG35: "%企業T35%", TG36: "%企業T36%", TG37: "%企業T37%", TG38: "%企業T38%", TG39: "%企業T39%", TG40: "%企業T40%",
  TG41: "%企業T41%", TG42: "%企業T42%", TG43: "%企業T43%", TG44: "%企業T44%", TG45: "%企業T45%", TG46: "%企業T46%", TG47: "%企業T47%", TG48: "%企業T48%", TG49: "%企業T49%", TG50: "%企業T50%",
  SGTL: "出荷アイテム数",
  SG_ALL: "企業",
  SG01: "%企業S01%", SG02: "%企業S02%", SG03: "%企業S03%", SG04: "%企業S04%", SG05: "%企業S05%", SG06: "%企業S06%", SG07: "%企業S07%", SG08: "%企業S08%", SG09: "%企業S09%", SG10: "%企業S10%",
  SG11: "%企業S11%", SG12: "%企業S12%", SG13: "%企業S13%", SG14: "%企業S14%", SG15: "%企業S15%", SG16: "%企業S16%", SG17: "%企業S17%", SG18: "%企業S18%", SG19: "%企業S19%", SG20: "%企業S20%",
  SG21: "%企業S21%", SG22: "%企業S22%", SG23: "%企業S23%", SG24: "%企業S24%", SG25: "%企業S25%", SG26: "%企業S26%", SG27: "%企業S27%", SG28: "%企業S28%", SG29: "%企業S29%", SG30: "%企業S30%",
  SG31: "%企業S31%", SG32: "%企業S32%", SG33: "%企業S33%", SG34: "%企業S34%", SG35: "%企業S35%", SG36: "%企業S36%", SG37: "%企業S37%", SG38: "%企業S38%", SG39: "%企業S39%", SG40: "%企業S40%",
  SG41: "%企業S41%", SG42: "%企業S42%", SG43: "%企業S43%", SG44: "%企業S44%", SG45: "%企業S45%", SG46: "%企業S46%", SG47: "%企業S47%", SG48: "%企業S48%", SG49: "%企業S49%", SG50: "%企業S50%",
  },
  {
    no : "",
    SHCD:"仕入先CD", MKCD:"メーカーCD",
  //以下に貼り付け
  ISC: "仕入先CD", ISN: "仕入先名", IMC: "メーカーCD", IMN: "メーカー名", INM: "商品名", ICC: "カテゴリCD", ICN: "カテゴリ名", IBC: "PB区分CD", IBN: "PB区分名", IJN: "JAN", ICD: "商品CD", ICP: "内容量", IC1: "BL入数", IC2: "CS入数", ILI: "賞味期間", IPR: "定価",
  SCD: "支店CD", SNM: "支店名",
  CCD: "倉庫CD", CNM: "倉庫名",
  TCD: "得意先CD", TNM: "得意先名",
  G2CD: "企業G2CD", G2NM: "企業G2名",
  G1CD: "企業G1CD", G1NM: "企業G1名",

  LQT: "本年実績", LQP: "前年実績", LQR: "前年比", LQD: "前年差",
  DQT: "本年実績", DQP: "前年実績", DQR: "前年比", DQD: "前年差",
  OQT: "本年実績", OQP: "前年実績", OQR: "前年比", OQD: "前年差",
  ULT: "本年実績", ULP: "前年実績", ULR: "前年比", ULD: "前年差",
  UDT: "本年実績", UDP: "前年実績", UDR: "前年比", UDD: "前年差",
  UPT: "本年実績", UPP: "前年実績", UPR: "前年比", UPD: "前年差",
  SQT: "本年実績", SQP: "前年実績", SQR: "前年比", SQD: "前年差",
  RQT: "本年実績", RQP: "前年実績", RQR: "前年比", RQD: "前年差",
  WQT: "本年実績", WQP: "前年実績", WQR: "前年比", WQD: "前年差",
  LQT_PS: "本年実績", LQP_PS: "前年実績", LQR_PS: "前年比", LQD_PS: "前年差",
  DQT_PS: "本年実績", DQP_PS: "前年実績", DQR_PS: "前年比", DQD_PS: "前年差",
  OQT_PS: "本年実績", OQP_PS: "前年実績", OQR_PS: "前年比", OQD_PS: "前年差",
  ULT_PS: "本年実績", ULP_PS: "前年実績", ULR_PS: "前年比", ULD_PS: "前年差",
  UDT_PS: "本年実績", UDP_PS: "前年実績", UDR_PS: "前年比", UDD_PS: "前年差",
  UPT_PS: "本年実績", UPP_PS: "前年実績", UPR_PS: "前年比", UPD_PS: "前年差",
  SQT_PS: "本年実績", SQP_PS: "前年実績", SQR_PS: "前年比", SQD_PS: "前年差",
  RQT_PS: "本年実績", RQP_PS: "前年実績", RQR_PS: "前年比", RQD_PS: "前年差",
  WQT_PS: "本年実績", WQP_PS: "前年実績", WQR_PS: "前年比", WQD_PS: "前年差",
  JJT: "本年実績", JJP: "前年実績", JJR: "前年比", JJD: "前年差", 
  JJQT: "本年実績", JJQP: "前年実績", JJQR: "前年比", JJQD: "前年差", 
  JKT: "本年実績", JKP: "前年実績", JKR: "前年比", JKD: "前年差", 
  JKQT: "本年実績", JKQP: "前年実績", JKQR: "前年比", JKQD: "前年差", 
  JRT: "本年実績", JRP: "前年実績", JRD: "前年差", 
  //欠品責任別
  KI1KQ: '欠品BL数', KI1KA: '欠品額', KI1KR: '欠品率', 
  KI2KQ: '欠品BL数', KI2KA: '欠品額', KI2KR: '欠品率', 
  KI3KQ: '欠品BL数', KI3KA: '欠品額', KI3KR: '欠品率', 

  //欠品区分別
  K10KQ: '欠品BL数', K10KA: '欠品額', K10KR: '欠品率', 
  K11KQ: '欠品BL数', K11KA: '欠品額', K11KR: '欠品率', 
  K12KQ: '欠品BL数', K12KA: '欠品額', K12KR: '欠品率', 
  K13KQ: '欠品BL数', K13KA: '欠品額', K13KR: '欠品率', 
  K14KQ: '欠品BL数', K14KA: '欠品額', K14KR: '欠品率', 
  K15KQ: '欠品BL数', K15KA: '欠品額', K15KR: '欠品率', 
  K16KQ: '欠品BL数', K16KA: '欠品額', K16KR: '欠品率', 
  K17KQ: '欠品BL数', K17KA: '欠品額', K17KR: '欠品率', 
  K19KQ: '欠品BL数', K19KA: '欠品額', K19KR: '欠品率', 
  K20KQ: '欠品BL数', K20KA: '欠品額', K20KR: '欠品率', 
  K21KQ: '欠品BL数', K21KA: '欠品額', K21KR: '欠品率', 
  K22KQ: '欠品BL数', K22KA: '欠品額', K22KR: '欠品率', 
  K29KQ: '欠品BL数', K29KA: '欠品額', K29KR: '欠品率', 
  K30KQ: '欠品BL数', K30KA: '欠品額', K30KR: '欠品率', 
  K31KQ: '欠品BL数', K31KA: '欠品額', K31KR: '欠品率', 
  K32KQ: '欠品BL数', K32KA: '欠品額', K32KR: '欠品率', 
  K33KQ: '欠品BL数', K33KA: '欠品額', K33KR: '欠品率', 
  K41KQ: '欠品BL数', K41KA: '欠品額', K41KR: '欠品率', 
  K34KQ: '欠品BL数', K34KA: '欠品額', K34KR: '欠品率', 
  K39KQ: '欠品BL数', K39KA: '欠品額', K39KR: '欠品率', 
  K40KQ: '欠品BL数', K40KA: '欠品額', K40KR: '欠品率', 
  K42KQ: '欠品BL数', K42KA: '欠品額', K42KR: '欠品率', 
  K43KQ: '欠品BL数', K43KA: '欠品額', K43KR: '欠品率', 
  K44KQ: '欠品BL数', K44KA: '欠品額', K44KR: '欠品率', 
  K49KQ: '欠品BL数', K49KA: '欠品額', K49KR: '欠品率', 
  K50KQ: '欠品BL数', K50KA: '欠品額', K50KR: '欠品率', 

  SLT: "本年実績", SLP: "前年実績", SLR: "前年比", SLD: "前年差", SDT: "本年実績", SDP: "前年実績", SDR: "前年比", SDD: "前年差", SRT: "本年実績", SRP: "前年実績", SRR: "前年比", SRD: "前年差", STT: "本年実績", STP: "前年実績", STR: "前年比", STD: "前年差",
  PTT: "本年実績", PTP: "前年実績", PTD: "前年差", PRT: "本年実績", PRP: "前年実績", PRD: "前年差",
  CIT: "本年実績", CIP: "前年実績", CIR: "前年比", CID: "前年差", CST: "本年実績", CSP: "前年実績", CSR: "前年比", CSD: "前年差", CTT: "本年実績", CTP: "前年実績", CTR: "前年比", CTD: "前年差",
  BTT: "本年実績", BTP: "前年実績", BTR: "前年比", BTD: "前年差", BRT: "本年実績", BRP: "前年実績", BRD: "前年差", ATT: "本年実績", ATP: "前年実績", ATR: "前年比", ATD: "前年差", ART: "本年実績", ARP: "前年実績", ARD: "前年差",
  SHCT: "本年実績", SHCP: "前年実績", SHCR: "前年比", SHCD2: "前年差",
  SHDT: "本年実績", SHDP: "前年実績", SHDR: "前年比", SHDD: "前年差",
  SHRCT: "本年実績", SHRCP: "前年実績", SHRCR: "前年比", SHRCD: "前年差",
  SHRDT: "本年実績", SHRDP: "前年実績", SHRDR: "前年比", SHRDD: "前年差",
  SHT: "本年実績", SHP: "前年実績", SHR: "前年比", SHD: "前年差",
  KUR: "売上構成比", KTR: "累計", KRK: "ランク",
  ZTN: "棚番", RQTY: "1.一般", TQTY: "2.特売", YQTY: "3.予約", FQTY: "4.出切", AZKE: "5.返品", ZQTY: "合計",
  SQTC: "出荷B数", SQTN: "出荷B数",
  TGTL: "該当企業数",
  TG_ALL: "定番フラグ",
  TG01: "定番", TG02: "定番", TG03: "定番", TG04: "定番", TG05: "定番", TG06: "定番", TG07: "定番", TG08: "定番", TG09: "定番", TG10: "定番",
  TG11: "定番", TG12: "定番", TG13: "定番", TG14: "定番", TG15: "定番", TG16: "定番", TG17: "定番", TG18: "定番", TG19: "定番", TG20: "定番",
  TG21: "定番", TG22: "定番", TG23: "定番", TG24: "定番", TG25: "定番", TG26: "定番", TG27: "定番", TG28: "定番", TG29: "定番", TG30: "定番",
  TG31: "定番", TG32: "定番", TG33: "定番", TG34: "定番", TG35: "定番", TG36: "定番", TG37: "定番", TG38: "定番", TG39: "定番", TG40: "定番",
  TG41: "定番", TG42: "定番", TG43: "定番", TG44: "定番", TG45: "定番", TG46: "定番", TG47: "定番", TG48: "定番", TG49: "定番", TG50: "定番",
  SGTL: "出荷企業数",
  SG_ALL: "出荷BL",
  SG01: "出荷BL", SG02: "出荷BL", SG03: "出荷BL", SG04: "出荷BL", SG05: "出荷BL", SG06: "出荷BL", SG07: "出荷BL", SG08: "出荷BL", SG09: "出荷BL", SG10: "出荷BL",
  SG11: "出荷BL", SG12: "出荷BL", SG13: "出荷BL", SG14: "出荷BL", SG15: "出荷BL", SG16: "出荷BL", SG17: "出荷BL", SG18: "出荷BL", SG19: "出荷BL", SG20: "出荷BL",
  SG21: "出荷BL", SG22: "出荷BL", SG23: "出荷BL", SG24: "出荷BL", SG25: "出荷BL", SG26: "出荷BL", SG27: "出荷BL", SG28: "出荷BL", SG29: "出荷BL", SG30: "出荷BL",
  SG31: "出荷BL", SG32: "出荷BL", SG33: "出荷BL", SG34: "出荷BL", SG35: "出荷BL", SG36: "出荷BL", SG37: "出荷BL", SG38: "出荷BL", SG39: "出荷BL", SG40: "出荷BL",
  SG41: "出荷BL", SG42: "出荷BL", SG43: "出荷BL", SG44: "出荷BL", SG45: "出荷BL", SG46: "出荷BL", SG47: "出荷BL", SG48: "出荷BL", SG49: "出荷BL", SG50: "出荷BL",
  },
];

export const excelHeaders = [
  {
    ET1: "営業", ER1: "営業", ET2: "営業", ER2: "営業", ET3: "営業", ER3: "営業",
  },
  {
    ET1: "継続希望①", ER1: "継続希望①", ET2: "継続希望②", ER2: "継続希望②", ET3: "継続希望③", ER3: "継続希望③",
  },
  {
    ET1: "対象企業", ER1: "理由", ET2: "対象企業", ER2: "理由", ET3: "対象企業", ER3: "理由",
  },
];

export const selectableCol =
{
  no : false,
  //以下に貼り付け
  ISC: true, ISN: true, IMC: true, IMN: true, INM: true, ICC: true, ICN: true, IBC: true, IBN: true, IJN: true, ICD: true, ICP: true, IC1: true, IC2: true, ILI: true, IPR: true,
  SCD: true, SNM: true,
  CCD: true, CNM: true,
  TCD: true, TNM: true,
  G2CD: true, G2NM: true,
  G1CD: true, G1NM: true,

  LQT: true, LQP: true, LQR: true, LQD: true,
  DQT: true, DQP: true, DQR: true, DQD: true,
  OQT: true, OQP: true, OQR: true, OQD: true,
  ULT: true, ULP: true, ULR: true, ULD: true,
  UDT: true, UDP: true, UDR: true, UDD: true,
  UPT: true, UPP: true, UPR: true, UPD: true,
  SQT: true, SQP: true, SQR: true, SQD: true,
  RQT: true, RQP: true, RQR: true, RQD: true,
  WQT: true, WQP: true, WQR: true, WQD: true,
  LQT_PS: true, LQP_PS: true, LQR_PS: true, LQD_PS: true,
  DQT_PS: true, DQP_PS: true, DQR_PS: true, DQD_PS: true,
  OQT_PS: true, OQP_PS: true, OQR_PS: true, OQD_PS: true,
  ULT_PS: true, ULP_PS: true, ULR_PS: true, ULD_PS: true,
  UDT_PS: true, UDP_PS: true, UDR_PS: true, UDD_PS: true,
  UPT_PS: true, UPP_PS: true, UPR_PS: true, UPD_PS: true,
  SQT_PS: true, SQP_PS: true, SQR_PS: true, SQD_PS: true,
  RQT_PS: true, RQP_PS: true, RQR_PS: true, RQD_PS: true,
  WQT_PS: true, WQP_PS: true, WQR_PS: true, WQD_PS: true,
  JJT: true, JJP: true, JJR: true, JJD: true, 
  JJQT: true, JJQP: true, JJQR: true, JJQD: true, 
  JKT: true, JKP: true, JKR: true, JKD: true, 
  JKQT: true, JKQP: true, JKQR: true, JKQD: true, 
  JRT: true, JRP: true, JRD: true, 
  //欠品責任別
  KI1KQ: true, KI1KA: true, KI1KR: true, 
  KI2KQ: true, KI2KA: true, KI2KR: true, 
  KI3KQ: true, KI3KA: true, KI3KR: true, 
  //欠品区分別
  K10KQ: true, K10KA: true, K10KR: true, 
  K11KQ: true, K11KA: true, K11KR: true, 
  K12KQ: true, K12KA: true, K12KR: true, 
  K13KQ: true, K13KA: true, K13KR: true, 
  K14KQ: true, K14KA: true, K14KR: true, 
  K15KQ: true, K15KA: true, K15KR: true, 
  K16KQ: true, K16KA: true, K16KR: true, 
  K17KQ: true, K17KA: true, K17KR: true, 
  K19KQ: true, K19KA: true, K19KR: true, 
  K20KQ: true, K20KA: true, K20KR: true, 
  K21KQ: true, K21KA: true, K21KR: true, 
  K22KQ: true, K22KA: true, K22KR: true, 
  K29KQ: true, K29KA: true, K29KR: true, 
  K30KQ: true, K30KA: true, K30KR: true, 
  K31KQ: true, K31KA: true, K31KR: true, 
  K32KQ: true, K32KA: true, K32KR: true, 
  K33KQ: true, K33KA: true, K33KR: true, 
  K41KQ: true, K41KA: true, K41KR: true, 
  K34KQ: true, K34KA: true, K34KR: true, 
  K39KQ: true, K39KA: true, K39KR: true, 
  K40KQ: true, K40KA: true, K40KR: true, 
  K42KQ: true, K42KA: true, K42KR: true, 
  K43KQ: true, K43KA: true, K43KR: true, 
  K44KQ: true, K44KA: true, K44KR: true, 
  K49KQ: true, K49KA: true, K49KR: true, 
  K50KQ: true, K50KA: true, K50KR: true, 

  SLT: true, SLP: true, SLR: true, SLD: true, SDT: true, SDP: true, SDR: true, SDD: true, SRT: true, SRP: true, SRR: true, SRD: true, STT: true, STP: true, STR: true, STD: true,
  PTT: true, PTP: true, PTD: true, PRT: true, PRP: true, PRD: true,
  CIT: true, CIP: true, CIR: true, CID: true, CST: true, CSP: true, CSR: true, CSD: true, CTT: true, CTP: true, CTR: true, CTD: true,
  BTT: true, BTP: true, BTR: true, BTD: true, BRT: true, BRP: true, BRD: true, ATT: true, ATP: true, ATR: true, ATD: true, ART: true, ARP: true, ARD: true,
  SHCT: true, SHCP: true, SHCR: true, SHCD2: true, 
  SHDT: true, SHDP: true, SHDR: true, SHDD: true, 
  SHRCT: true, SHRCP: true, SHRCR: true, SHRCD: true, 
  SHRDT: true, SHRDP: true, SHRDR: true, SHRDD: true, 
  SHT: true, SHP: true, SHR: true, SHD: true,
  KUR: true, KTR: true, KRK: true,
  ZTN: true, RQTY: true, TQTY: true, YQTY: true, FQTY: true, AZKE: true, ZQTY: true,
  SQTC: true, SQTN: true,
  TGTL: true,
  TG01: false, TG02: false, TG03: false, TG04: false, TG05: false, TG06: false, TG07: false, TG08: false, TG09: false, TG10: false,
  TG11: false, TG12: false, TG13: false, TG14: false, TG15: false, TG16: false, TG17: false, TG18: false, TG19: false, TG20: false,
  TG21: false, TG22: false, TG23: false, TG24: false, TG25: false, TG26: false, TG27: false, TG28: false, TG29: false, TG30: false,
  TG31: false, TG32: false, TG33: false, TG34: false, TG35: false, TG36: false, TG37: false, TG38: false, TG39: false, TG40: false,
  TG41: false, TG42: false, TG43: false, TG44: false, TG45: false, TG46: false, TG47: false, TG48: false, TG49: false, TG50: false,
  SGTL: true,
  SG01: false, SG02: false, SG03: false, SG04: false, SG05: false, SG06: false, SG07: false, SG08: false, SG09: false, SG10: false,
  SG11: false, SG12: false, SG13: false, SG14: false, SG15: false, SG16: false, SG17: false, SG18: false, SG19: false, SG20: false,
  SG21: false, SG22: false, SG23: false, SG24: false, SG25: false, SG26: false, SG27: false, SG28: false, SG29: false, SG30: false,
  SG31: false, SG32: false, SG33: false, SG34: false, SG35: false, SG36: false, SG37: false, SG38: false, SG39: false, SG40: false,
  SG41: false, SG42: false, SG43: false, SG44: false, SG45: false, SG46: false, SG47: false, SG48: false, SG49: false, SG50: false,
  TG_ALL: true,
  SG_ALL: true,
}

export const headerClasses = [
  {
    no : "itemlist_H",
    //以下に貼り付け
    ISC: "itemlist_I", ISN: "itemlist_I", IMC: "itemlist_I", IMN: "itemlist_I", INM: "itemlist_I", ICC: "itemlist_I", ICN: "itemlist_I", IBC: "itemlist_I", IBN: "itemlist_I", IJN: "itemlist_I", ICD: "itemlist_I", ICP: "itemlist_I", IC1: "itemlist_I", IC2: "itemlist_I", ILI: "itemlist_I", IPR: "itemlist_I",
    SCD: "itemlist_C", SNM: "itemlist_C",
    CCD: "itemlist_C", CNM: "itemlist_C",
    TCD: "itemlist_C", TNM: "itemlist_C",
    G2CD: "itemlist_C", G2NM: "itemlist_C",
    G1CD: "itemlist_C", G1NM: "itemlist_C",
  
    JJT: "itemlist_J", JJP: "itemlist_J", JJR: "itemlist_J", JJD: "itemlist_J", 
    JJQT: "itemlist_J", JJQP: "itemlist_J", JJQR: "itemlist_J", JJQD: "itemlist_J", 
    JKT: "itemlist_K", JKP: "itemlist_K", JKR: "itemlist_K", JKD: "itemlist_K", 
    JKQT: "itemlist_K", JKQP: "itemlist_K", JKQR: "itemlist_K", JKQD: "itemlist_K", 
    JRT: "itemlist_K", JRP: "itemlist_K", JRD: "itemlist_K", 
    //欠品責任別
    KI1KQ: "itemlist_K2", KI1KA: "itemlist_K2", KI1KR: "itemlist_K2", 
    KI2KQ: "itemlist_K2", KI2KA: "itemlist_K2", KI2KR: "itemlist_K2", 
    KI3KQ: "itemlist_K2", KI3KA: "itemlist_K2", KI3KR: "itemlist_K2", 
    //欠品区分別
    K10KQ: "itemlist_K2", K10KA: "itemlist_K2", K10KR: "itemlist_K2", 
    K11KQ: "itemlist_K2", K11KA: "itemlist_K2", K11KR: "itemlist_K2", 
    K12KQ: "itemlist_K2", K12KA: "itemlist_K2", K12KR: "itemlist_K2", 
    K13KQ: "itemlist_K2", K13KA: "itemlist_K2", K13KR: "itemlist_K2", 
    K14KQ: "itemlist_K2", K14KA: "itemlist_K2", K14KR: "itemlist_K2", 
    K15KQ: "itemlist_K2", K15KA: "itemlist_K2", K15KR: "itemlist_K2", 
    K16KQ: "itemlist_K2", K16KA: "itemlist_K2", K16KR: "itemlist_K2", 
    K17KQ: "itemlist_K2", K17KA: "itemlist_K2", K17KR: "itemlist_K2", 
    K19KQ: "itemlist_K2", K19KA: "itemlist_K2", K19KR: "itemlist_K2", 
    K20KQ: "itemlist_K2", K20KA: "itemlist_K2", K20KR: "itemlist_K2", 
    K21KQ: "itemlist_K2", K21KA: "itemlist_K2", K21KR: "itemlist_K2", 
    K22KQ: "itemlist_K2", K22KA: "itemlist_K2", K22KR: "itemlist_K2", 
    K29KQ: "itemlist_K2", K29KA: "itemlist_K2", K29KR: "itemlist_K2", 
    K30KQ: "itemlist_K2", K30KA: "itemlist_K2", K30KR: "itemlist_K2", 
    K31KQ: "itemlist_K2", K31KA: "itemlist_K2", K31KR: "itemlist_K2", 
    K32KQ: "itemlist_K2", K32KA: "itemlist_K2", K32KR: "itemlist_K2", 
    K33KQ: "itemlist_K2", K33KA: "itemlist_K2", K33KR: "itemlist_K2", 
    K41KQ: "itemlist_K2", K41KA: "itemlist_K2", K41KR: "itemlist_K2", 
    K34KQ: "itemlist_K2", K34KA: "itemlist_K2", K34KR: "itemlist_K2", 
    K39KQ: "itemlist_K2", K39KA: "itemlist_K2", K39KR: "itemlist_K2", 
    K40KQ: "itemlist_K2", K40KA: "itemlist_K2", K40KR: "itemlist_K2", 
    K42KQ: "itemlist_K2", K42KA: "itemlist_K2", K42KR: "itemlist_K2", 
    K43KQ: "itemlist_K2", K43KA: "itemlist_K2", K43KR: "itemlist_K2", 
    K44KQ: "itemlist_K2", K44KA: "itemlist_K2", K44KR: "itemlist_K2", 
    K49KQ: "itemlist_K2", K49KA: "itemlist_K2", K49KR: "itemlist_K2", 
    K50KQ: "itemlist_K2", K50KA: "itemlist_K2", K50KR: "itemlist_K2", 

    SHCT: "itemlist_X", SHCP: "itemlist_X", SHCR: "itemlist_X", SHCD2: "itemlist_X", 
    SHDT: "itemlist_X", SHDP: "itemlist_X", SHDR: "itemlist_X", SHDD: "itemlist_X", 
    SHRCT: "itemlist_X", SHRCP: "itemlist_X", SHRCR: "itemlist_X", SHRCD: "itemlist_X", 
    SHRDT: "itemlist_X", SHRDP: "itemlist_X", SHRDR: "itemlist_X", SHRDD: "itemlist_X", 
    SHT: "itemlist_X", SHP: "itemlist_X", SHR: "itemlist_X", SHD: "itemlist_X", 

    KUR: "itemlist_U", KTR: "itemlist_U", KRK: "itemlist_U",
    ZTN: "itemlist_Z", RQTY: "itemlist_Z", TQTY: "itemlist_Z", YQTY: "itemlist_Z", FQTY: "itemlist_Z", AZKE: "itemlist_Z", ZQTY: "itemlist_Z",
    SQTC: "itemlist_Z", SQTN: "itemlist_Z",
    TGTL: "itemlist_T",
    TG01: "itemlist_T", TG02: "itemlist_T", TG03: "itemlist_T", TG04: "itemlist_T", TG05: "itemlist_T", TG06: "itemlist_T", TG07: "itemlist_T", TG08: "itemlist_T", TG09: "itemlist_T", TG10: "itemlist_T",
    TG11: "itemlist_T", TG12: "itemlist_T", TG13: "itemlist_T", TG14: "itemlist_T", TG15: "itemlist_T", TG16: "itemlist_T", TG17: "itemlist_T", TG18: "itemlist_T", TG19: "itemlist_T", TG20: "itemlist_T",
    TG21: "itemlist_T", TG22: "itemlist_T", TG23: "itemlist_T", TG24: "itemlist_T", TG25: "itemlist_T", TG26: "itemlist_T", TG27: "itemlist_T", TG28: "itemlist_T", TG29: "itemlist_T", TG30: "itemlist_T",
    TG31: "itemlist_T", TG32: "itemlist_T", TG33: "itemlist_T", TG34: "itemlist_T", TG35: "itemlist_T", TG36: "itemlist_T", TG37: "itemlist_T", TG38: "itemlist_T", TG39: "itemlist_T", TG40: "itemlist_T",
    TG41: "itemlist_T", TG42: "itemlist_T", TG43: "itemlist_T", TG44: "itemlist_T", TG45: "itemlist_T", TG46: "itemlist_T", TG47: "itemlist_T", TG48: "itemlist_T", TG49: "itemlist_T", TG50: "itemlist_T",
    SGTL: "itemlist_P",
    SG01: "itemlist_P", SG02: "itemlist_P", SG03: "itemlist_P", SG04: "itemlist_P", SG05: "itemlist_P", SG06: "itemlist_P", SG07: "itemlist_P", SG08: "itemlist_P", SG09: "itemlist_P", SG10: "itemlist_P",
    SG11: "itemlist_P", SG12: "itemlist_P", SG13: "itemlist_P", SG14: "itemlist_P", SG15: "itemlist_P", SG16: "itemlist_P", SG17: "itemlist_P", SG18: "itemlist_P", SG19: "itemlist_P", SG20: "itemlist_P",
    SG21: "itemlist_P", SG22: "itemlist_P", SG23: "itemlist_P", SG24: "itemlist_P", SG25: "itemlist_P", SG26: "itemlist_P", SG27: "itemlist_P", SG28: "itemlist_P", SG29: "itemlist_P", SG30: "itemlist_P",
    SG31: "itemlist_P", SG32: "itemlist_P", SG33: "itemlist_P", SG34: "itemlist_P", SG35: "itemlist_P", SG36: "itemlist_P", SG37: "itemlist_P", SG38: "itemlist_P", SG39: "itemlist_P", SG40: "itemlist_P",
    SG41: "itemlist_P", SG42: "itemlist_P", SG43: "itemlist_P", SG44: "itemlist_P", SG45: "itemlist_P", SG46: "itemlist_P", SG47: "itemlist_P", SG48: "itemlist_P", SG49: "itemlist_P", SG50: "itemlist_P",
  },
  {
    no : "itemlist_H",
    //以下に貼り付け
    ISC: "itemlist_I", ISN: "itemlist_I", IMC: "itemlist_I", IMN: "itemlist_I", INM: "itemlist_I", ICC: "itemlist_I", ICN: "itemlist_I", IBC: "itemlist_I", IBN: "itemlist_I", IJN: "itemlist_I", ICD: "itemlist_I", ICP: "itemlist_I", IC1: "itemlist_I", IC2: "itemlist_I", ILI: "itemlist_I", IPR: "itemlist_I",
    SCD: "itemlist_C", SNM: "itemlist_C",
    CCD: "itemlist_C", CNM: "itemlist_C",
    TCD: "itemlist_C", TNM: "itemlist_C",
    G2CD: "itemlist_C", G2NM: "itemlist_C",
    G1CD: "itemlist_C", G1NM: "itemlist_C",
    JJT: "itemlist_J", JJP: "itemlist_J", JJR: "itemlist_J", JJD: "itemlist_J", 
    JJQT: "itemlist_J", JJQP: "itemlist_J", JJQR: "itemlist_J", JJQD: "itemlist_J", 
    JKT: "itemlist_K", JKP: "itemlist_K", JKR: "itemlist_K", JKD: "itemlist_K", 
    JKQT: "itemlist_K", JKQP: "itemlist_K", JKQR: "itemlist_K", JKQD: "itemlist_K", 
    JRT: "itemlist_K", JRP: "itemlist_K", JRD: "itemlist_K", 
    //欠品責任別
    KI1KQ: "itemlist_K2", KI1KA: "itemlist_K2", KI1KR: "itemlist_K2", 
    KI2KQ: "itemlist_K2", KI2KA: "itemlist_K2", KI2KR: "itemlist_K2", 
    KI3KQ: "itemlist_K2", KI3KA: "itemlist_K2", KI3KR: "itemlist_K2", 
    //欠品区分別
    K10KQ: "itemlist_K2", K10KA: "itemlist_K2", K10KR: "itemlist_K2", 
    K11KQ: "itemlist_K2", K11KA: "itemlist_K2", K11KR: "itemlist_K2", 
    K12KQ: "itemlist_K2", K12KA: "itemlist_K2", K12KR: "itemlist_K2", 
    K13KQ: "itemlist_K2", K13KA: "itemlist_K2", K13KR: "itemlist_K2", 
    K14KQ: "itemlist_K2", K14KA: "itemlist_K2", K14KR: "itemlist_K2", 
    K15KQ: "itemlist_K2", K15KA: "itemlist_K2", K15KR: "itemlist_K2", 
    K16KQ: "itemlist_K2", K16KA: "itemlist_K2", K16KR: "itemlist_K2", 
    K17KQ: "itemlist_K2", K17KA: "itemlist_K2", K17KR: "itemlist_K2", 
    K19KQ: "itemlist_K2", K19KA: "itemlist_K2", K19KR: "itemlist_K2", 
    K20KQ: "itemlist_K2", K20KA: "itemlist_K2", K20KR: "itemlist_K2", 
    K21KQ: "itemlist_K2", K21KA: "itemlist_K2", K21KR: "itemlist_K2", 
    K22KQ: "itemlist_K2", K22KA: "itemlist_K2", K22KR: "itemlist_K2", 
    K29KQ: "itemlist_K2", K29KA: "itemlist_K2", K29KR: "itemlist_K2", 
    K30KQ: "itemlist_K2", K30KA: "itemlist_K2", K30KR: "itemlist_K2", 
    K31KQ: "itemlist_K2", K31KA: "itemlist_K2", K31KR: "itemlist_K2", 
    K32KQ: "itemlist_K2", K32KA: "itemlist_K2", K32KR: "itemlist_K2", 
    K33KQ: "itemlist_K2", K33KA: "itemlist_K2", K33KR: "itemlist_K2", 
    K41KQ: "itemlist_K2", K41KA: "itemlist_K2", K41KR: "itemlist_K2", 
    K34KQ: "itemlist_K2", K34KA: "itemlist_K2", K34KR: "itemlist_K2", 
    K39KQ: "itemlist_K2", K39KA: "itemlist_K2", K39KR: "itemlist_K2", 
    K40KQ: "itemlist_K2", K40KA: "itemlist_K2", K40KR: "itemlist_K2", 
    K42KQ: "itemlist_K2", K42KA: "itemlist_K2", K42KR: "itemlist_K2", 
    K43KQ: "itemlist_K2", K43KA: "itemlist_K2", K43KR: "itemlist_K2", 
    K44KQ: "itemlist_K2", K44KA: "itemlist_K2", K44KR: "itemlist_K2", 
    K49KQ: "itemlist_K2", K49KA: "itemlist_K2", K49KR: "itemlist_K2", 
    K50KQ: "itemlist_K2", K50KA: "itemlist_K2", K50KR: "itemlist_K2", 

    SHCT: "itemlist_X", SHCP: "itemlist_X", SHCR: "itemlist_X", SHCD2: "itemlist_X", 
    SHDT: "itemlist_X", SHDP: "itemlist_X", SHDR: "itemlist_X", SHDD: "itemlist_X", 
    SHRCT: "itemlist_X", SHRCP: "itemlist_X", SHRCR: "itemlist_X", SHRCD: "itemlist_X", 
    SHRDT: "itemlist_X", SHRDP: "itemlist_X", SHRDR: "itemlist_X", SHRDD: "itemlist_X", 
    SHT: "itemlist_X", SHP: "itemlist_X", SHR: "itemlist_X", SHD: "itemlist_X", 

    KUR: "itemlist_U", KTR: "itemlist_U", KRK: "itemlist_U",
    ZTN: "itemlist_Z", RQTY: "itemlist_Z", TQTY: "itemlist_Z", YQTY: "itemlist_Z", FQTY: "itemlist_Z", AZKE: "itemlist_Z", ZQTY: "itemlist_Z",
    SQTC: "itemlist_Z", SQTN: "itemlist_Z", 
    TGTL: "itemlist_T",
    TG01: "itemlist_T", TG02: "itemlist_T", TG03: "itemlist_T", TG04: "itemlist_T", TG05: "itemlist_T", TG06: "itemlist_T", TG07: "itemlist_T", TG08: "itemlist_T", TG09: "itemlist_T", TG10: "itemlist_T",
    TG11: "itemlist_T", TG12: "itemlist_T", TG13: "itemlist_T", TG14: "itemlist_T", TG15: "itemlist_T", TG16: "itemlist_T", TG17: "itemlist_T", TG18: "itemlist_T", TG19: "itemlist_T", TG20: "itemlist_T",
    TG21: "itemlist_T", TG22: "itemlist_T", TG23: "itemlist_T", TG24: "itemlist_T", TG25: "itemlist_T", TG26: "itemlist_T", TG27: "itemlist_T", TG28: "itemlist_T", TG29: "itemlist_T", TG30: "itemlist_T",
    TG31: "itemlist_T", TG32: "itemlist_T", TG33: "itemlist_T", TG34: "itemlist_T", TG35: "itemlist_T", TG36: "itemlist_T", TG37: "itemlist_T", TG38: "itemlist_T", TG39: "itemlist_T", TG40: "itemlist_T",
    TG41: "itemlist_T", TG42: "itemlist_T", TG43: "itemlist_T", TG44: "itemlist_T", TG45: "itemlist_T", TG46: "itemlist_T", TG47: "itemlist_T", TG48: "itemlist_T", TG49: "itemlist_T", TG50: "itemlist_T",
    SGTL: "itemlist_P",
    SG01: "itemlist_P", SG02: "itemlist_P", SG03: "itemlist_P", SG04: "itemlist_P", SG05: "itemlist_P", SG06: "itemlist_P", SG07: "itemlist_P", SG08: "itemlist_P", SG09: "itemlist_P", SG10: "itemlist_P",
    SG11: "itemlist_P", SG12: "itemlist_P", SG13: "itemlist_P", SG14: "itemlist_P", SG15: "itemlist_P", SG16: "itemlist_P", SG17: "itemlist_P", SG18: "itemlist_P", SG19: "itemlist_P", SG20: "itemlist_P",
    SG21: "itemlist_P", SG22: "itemlist_P", SG23: "itemlist_P", SG24: "itemlist_P", SG25: "itemlist_P", SG26: "itemlist_P", SG27: "itemlist_P", SG28: "itemlist_P", SG29: "itemlist_P", SG30: "itemlist_P",
    SG31: "itemlist_P", SG32: "itemlist_P", SG33: "itemlist_P", SG34: "itemlist_P", SG35: "itemlist_P", SG36: "itemlist_P", SG37: "itemlist_P", SG38: "itemlist_P", SG39: "itemlist_P", SG40: "itemlist_P",
    SG41: "itemlist_P", SG42: "itemlist_P", SG43: "itemlist_P", SG44: "itemlist_P", SG45: "itemlist_P", SG46: "itemlist_P", SG47: "itemlist_P", SG48: "itemlist_P", SG49: "itemlist_P", SG50: "itemlist_P",
  },
  {
    no : "itemlist_H",
    //以下に貼り付け
    ISC: "itemlist_I", ISN: "itemlist_I", IMC: "itemlist_I", IMN: "itemlist_I", INM: "itemlist_I", ICC: "itemlist_I", ICN: "itemlist_I", IBC: "itemlist_I", IBN: "itemlist_I", IJN: "itemlist_I", ICD: "itemlist_I", ICP: "itemlist_I", IC1: "itemlist_I", IC2: "itemlist_I", ILI: "itemlist_I", IPR: "itemlist_I",
    SCD: "itemlist_C", SNM: "itemlist_C",
    CCD: "itemlist_C", CNM: "itemlist_C",
    TCD: "itemlist_C", TNM: "itemlist_C",
    G2CD: "itemlist_C", G2NM: "itemlist_C",
    G1CD: "itemlist_C", G1NM: "itemlist_C",
    JJT: "itemlist_J", JJP: "itemlist_JP", JJR: "itemlist_JP", JJD: "itemlist_JP", 
    JJQT: "itemlist_J", JJQP: "itemlist_JP", JJQR: "itemlist_JP", JJQD: "itemlist_JP", 
    JKT: "itemlist_K", JKP: "itemlist_KP", JKR: "itemlist_KP", JKD: "itemlist_KP", 
    JKQT: "itemlist_K", JKQP: "itemlist_KP", JKQR: "itemlist_KP", JKQD: "itemlist_KP", 
    JRT: "itemlist_K", JRP: "itemlist_KP", JRD: "itemlist_KP", 
    //欠品責任別
    KI1KQ: "itemlist_K2", KI1KA: "itemlist_K2", KI1KR: "itemlist_K2", 
    KI2KQ: "itemlist_K2", KI2KA: "itemlist_K2", KI2KR: "itemlist_K2", 
    KI3KQ: "itemlist_K2", KI3KA: "itemlist_K2", KI3KR: "itemlist_K2", 
    //欠品区分別
    K10KQ: "itemlist_K2", K10KA: "itemlist_K2", K10KR: "itemlist_K2", 
    K11KQ: "itemlist_K2", K11KA: "itemlist_K2", K11KR: "itemlist_K2", 
    K12KQ: "itemlist_K2", K12KA: "itemlist_K2", K12KR: "itemlist_K2", 
    K13KQ: "itemlist_K2", K13KA: "itemlist_K2", K13KR: "itemlist_K2", 
    K14KQ: "itemlist_K2", K14KA: "itemlist_K2", K14KR: "itemlist_K2", 
    K15KQ: "itemlist_K2", K15KA: "itemlist_K2", K15KR: "itemlist_K2", 
    K16KQ: "itemlist_K2", K16KA: "itemlist_K2", K16KR: "itemlist_K2", 
    K17KQ: "itemlist_K2", K17KA: "itemlist_K2", K17KR: "itemlist_K2", 
    K19KQ: "itemlist_K2", K19KA: "itemlist_K2", K19KR: "itemlist_K2", 
    K20KQ: "itemlist_K2", K20KA: "itemlist_K2", K20KR: "itemlist_K2", 
    K21KQ: "itemlist_K2", K21KA: "itemlist_K2", K21KR: "itemlist_K2", 
    K22KQ: "itemlist_K2", K22KA: "itemlist_K2", K22KR: "itemlist_K2", 
    K29KQ: "itemlist_K2", K29KA: "itemlist_K2", K29KR: "itemlist_K2", 
    K30KQ: "itemlist_K2", K30KA: "itemlist_K2", K30KR: "itemlist_K2", 
    K31KQ: "itemlist_K2", K31KA: "itemlist_K2", K31KR: "itemlist_K2", 
    K32KQ: "itemlist_K2", K32KA: "itemlist_K2", K32KR: "itemlist_K2", 
    K33KQ: "itemlist_K2", K33KA: "itemlist_K2", K33KR: "itemlist_K2", 
    K41KQ: "itemlist_K2", K41KA: "itemlist_K2", K41KR: "itemlist_K2", 
    K34KQ: "itemlist_K2", K34KA: "itemlist_K2", K34KR: "itemlist_K2", 
    K39KQ: "itemlist_K2", K39KA: "itemlist_K2", K39KR: "itemlist_K2", 
    K40KQ: "itemlist_K2", K40KA: "itemlist_K2", K40KR: "itemlist_K2", 
    K42KQ: "itemlist_K2", K42KA: "itemlist_K2", K42KR: "itemlist_K2", 
    K43KQ: "itemlist_K2", K43KA: "itemlist_K2", K43KR: "itemlist_K2", 
    K44KQ: "itemlist_K2", K44KA: "itemlist_K2", K44KR: "itemlist_K2", 
    K49KQ: "itemlist_K2", K49KA: "itemlist_K2", K49KR: "itemlist_K2", 
    K50KQ: "itemlist_K2", K50KA: "itemlist_K2", K50KR: "itemlist_K2", 

    LQP: "itemlist_P", LQR: "itemlist_P", LQD: "itemlist_P",
    DQP: "itemlist_P", DQR: "itemlist_P", DQD: "itemlist_P",
    OQP: "itemlist_P", OQR: "itemlist_P", OQD: "itemlist_P",
    ULP: "itemlist_P", ULR: "itemlist_P", ULD: "itemlist_P",
    UDP: "itemlist_P", UDR: "itemlist_P", UDD: "itemlist_P",
    UPP: "itemlist_P", UPR: "itemlist_P", UPD: "itemlist_P",
    SQT: "itemlist_P", SQP: "itemlist_P", SQR: "itemlist_P", SQD: "itemlist_P",
    RQP: "itemlist_P", RQR: "itemlist_P", RQD: "itemlist_P",
    WQP: "itemlist_P", WQR: "itemlist_P", WQD: "itemlist_P",
    LQP_PS: "itemlist_P", LQR_PS: "itemlist_P", LQD_PS: "itemlist_P",
    DQP_PS: "itemlist_P", DQR_PS: "itemlist_P", DQD_PS: "itemlist_P",
    OQP_PS: "itemlist_P", OQR_PS: "itemlist_P", OQD_PS: "itemlist_P",
    ULP_PS: "itemlist_P", ULR_PS: "itemlist_P", ULD_PS: "itemlist_P",
    UDP_PS: "itemlist_P", UDR_PS: "itemlist_P", UDD_PS: "itemlist_P",
    UPP_PS: "itemlist_P", UPR_PS: "itemlist_P", UPD_PS: "itemlist_P",
    SQT_PS: "itemlist_P", SQP_PS: "itemlist_P", SQR_PS: "itemlist_P", SQD_PS: "itemlist_P",
    RQP_PS: "itemlist_P", RQR_PS: "itemlist_P", RQD_PS: "itemlist_P",
    WQP_PS: "itemlist_P", WQR_PS: "itemlist_P", WQD_PS: "itemlist_P",
    SLP: "itemlist_P", SLR: "itemlist_P", SLD: "itemlist_P",
    SDP: "itemlist_P", SDR: "itemlist_P", SDD: "itemlist_P",
    SRP: "itemlist_P", SRR: "itemlist_P", SRD: "itemlist_P",
    STP: "itemlist_P", STR: "itemlist_P", STD: "itemlist_P",
    PTP: "itemlist_P", PTD: "itemlist_P",
    PRP: "itemlist_P", PRD: "itemlist_P",
    CIP: "itemlist_P", CIR: "itemlist_P", CID: "itemlist_P",
    CSP: "itemlist_P", CSR: "itemlist_P", CSD: "itemlist_P",
    CTP: "itemlist_P", CTR: "itemlist_P", CTD: "itemlist_P",
    BTP: "itemlist_P", BTR: "itemlist_P", BTD: "itemlist_P",
    BRP: "itemlist_P", BRD: "itemlist_P",
    ATP: "itemlist_P", ATR: "itemlist_P", ATD: "itemlist_P",
    ARP: "itemlist_P", ARD: "itemlist_P",
    SHCT: "itemlist_X", SHCP: "itemlist_X", SHCR: "itemlist_X", SHCD2: "itemlist_X", 
    SHDT: "itemlist_X", SHDP: "itemlist_X", SHDR: "itemlist_X", SHDD: "itemlist_X", 
    SHRCT: "itemlist_X", SHRCP: "itemlist_X", SHRCR: "itemlist_X", SHRCD: "itemlist_X", 
    SHRDT: "itemlist_X", SHRDP: "itemlist_X", SHRDR: "itemlist_X", SHRDD: "itemlist_X", 
    SHT: "itemlist_X", SHP: "itemlist_X", SHR: "itemlist_X", SHD: "itemlist_X", 
    KUR: "itemlist_U", KTR: "itemlist_U", KRK: "itemlist_U",
    ZTN: "itemlist_Z", RQTY: "itemlist_Z", TQTY: "itemlist_Z", YQTY: "itemlist_Z", FQTY: "itemlist_Z", AZKE: "itemlist_Z", ZQTY: "itemlist_Z",
    SQTC: "itemlist_Z", SQTN: "itemlist_Z",
    TGTL: "itemlist_T",
    TG01: "itemlist_T", TG02: "itemlist_T", TG03: "itemlist_T", TG04: "itemlist_T", TG05: "itemlist_T", TG06: "itemlist_T", TG07: "itemlist_T", TG08: "itemlist_T", TG09: "itemlist_T", TG10: "itemlist_T",
    TG11: "itemlist_T", TG12: "itemlist_T", TG13: "itemlist_T", TG14: "itemlist_T", TG15: "itemlist_T", TG16: "itemlist_T", TG17: "itemlist_T", TG18: "itemlist_T", TG19: "itemlist_T", TG20: "itemlist_T",
    TG21: "itemlist_T", TG22: "itemlist_T", TG23: "itemlist_T", TG24: "itemlist_T", TG25: "itemlist_T", TG26: "itemlist_T", TG27: "itemlist_T", TG28: "itemlist_T", TG29: "itemlist_T", TG30: "itemlist_T",
    TG31: "itemlist_T", TG32: "itemlist_T", TG33: "itemlist_T", TG34: "itemlist_T", TG35: "itemlist_T", TG36: "itemlist_T", TG37: "itemlist_T", TG38: "itemlist_T", TG39: "itemlist_T", TG40: "itemlist_T",
    TG41: "itemlist_T", TG42: "itemlist_T", TG43: "itemlist_T", TG44: "itemlist_T", TG45: "itemlist_T", TG46: "itemlist_T", TG47: "itemlist_T", TG48: "itemlist_T", TG49: "itemlist_T", TG50: "itemlist_T",
    SGTL: "itemlist_P",
    SG01: "itemlist_P", SG02: "itemlist_P", SG03: "itemlist_P", SG04: "itemlist_P", SG05: "itemlist_P", SG06: "itemlist_P", SG07: "itemlist_P", SG08: "itemlist_P", SG09: "itemlist_P", SG10: "itemlist_P",
    SG11: "itemlist_P", SG12: "itemlist_P", SG13: "itemlist_P", SG14: "itemlist_P", SG15: "itemlist_P", SG16: "itemlist_P", SG17: "itemlist_P", SG18: "itemlist_P", SG19: "itemlist_P", SG20: "itemlist_P",
    SG21: "itemlist_P", SG22: "itemlist_P", SG23: "itemlist_P", SG24: "itemlist_P", SG25: "itemlist_P", SG26: "itemlist_P", SG27: "itemlist_P", SG28: "itemlist_P", SG29: "itemlist_P", SG30: "itemlist_P",
    SG31: "itemlist_P", SG32: "itemlist_P", SG33: "itemlist_P", SG34: "itemlist_P", SG35: "itemlist_P", SG36: "itemlist_P", SG37: "itemlist_P", SG38: "itemlist_P", SG39: "itemlist_P", SG40: "itemlist_P",
    SG41: "itemlist_P", SG42: "itemlist_P", SG43: "itemlist_P", SG44: "itemlist_P", SG45: "itemlist_P", SG46: "itemlist_P", SG47: "itemlist_P", SG48: "itemlist_P", SG49: "itemlist_P", SG50: "itemlist_P",
  },
];
export const excelHeaderClasses = [
  {
    ET1: "itemlist_E", ER1: "itemlist_E", ET2: "itemlist_E", ER2: "itemlist_E", ET3: "itemlist_E", ER3: "itemlist_E",
  },
  {
    ET1: "itemlist_E", ER1: "itemlist_E", ET2: "itemlist_E", ER2: "itemlist_E", ET3: "itemlist_E", ER3: "itemlist_E",
  },
  {
    ET1: "itemlist_E", ER1: "itemlist_E", ET2: "itemlist_E", ER2: "itemlist_E", ET3: "itemlist_E", ER3: "itemlist_E",
  },
];

export const colWidths =
{
  no : 100,
  //以下に貼り付け
  ISC: 80, ISN: 150, IMC: 80, IMN: 150, INM: 150, ICC: 80, ICN: 150, IBC: 80, IBN: 80,  IJN: 150, ICD: 100, ICP: 80, IC1: 80, IC2: 80, ILI: 80, IPR: 80,
  SCD: 80, SNM: 150,
  CCD: 80, CNM: 150,
  TCD: 80, TNM: 150,
  G2CD: 80, G2NM: 150,
  G1CD: 80, G1NM: 150,

  LQT: 120, LQP: 120, LQR: 80, LQD: 120,
  DQT: 120, DQP: 120, DQR: 80, DQD: 120,
  OQT: 120, OQP: 120, OQR: 80, OQD: 120,
  ULT: 120, ULP: 120, ULR: 80, ULD: 120,
  UDT: 120, UDP: 120, UDR: 80, UDD: 120,
  UPT: 120, UPP: 120, UPR: 80, UPD: 120,
  SQT: 120, SQP: 120, SQR: 80, SQD: 120,
  RQT: 120, RQP: 120, RQR: 80, RQD: 120,
  WQT: 120, WQP: 120, WQR: 80, WQD: 120,
  LQT_PS: 120, LQP_PS: 120, LQR_PS: 80, LQD_PS: 120,
  DQT_PS: 120, DQP_PS: 120, DQR_PS: 80, DQD_PS: 120,
  OQT_PS: 120, OQP_PS: 120, OQR_PS: 80, OQD_PS: 120,
  ULT_PS: 120, ULP_PS: 120, ULR_PS: 80, ULD_PS: 120,
  UDT_PS: 120, UDP_PS: 120, UDR_PS: 80, UDD_PS: 120,
  UPT_PS: 120, UPP_PS: 120, UPR_PS: 80, UPD_PS: 120,
  SQT_PS: 120, SQP_PS: 120, SQR_PS: 80, SQD_PS: 120,
  RQT_PS: 120, RQP_PS: 120, RQR_PS: 80, RQD_PS: 120,
  WQT_PS: 120, WQP_PS: 120, WQR_PS: 80, WQD_PS: 120,
  JJT: 120, JJP: 120, JJR: 80, JJD: 120, 
  JJQT: 120, JJQP: 120, JJQR: 80, JJQD: 120, 
  JKT: 120, JKP: 120, JKR: 80, JKD: 120, 
  JKQT: 120, JKQP: 120, JKQR: 80, JKQD: 120, 
  JRT: 80, JRP: 80, JRD: 80, 
  //欠品責任別
  KI1KQ: 100, KI1KA: 100, KI1KR: 80, 
  KI2KQ: 100, KI2KA: 100, KI2KR: 80, 
  KI3KQ: 100, KI3KA: 100, KI3KR: 80, 
  //欠品区分別
  K10KQ: 100, K10KA: 100, K10KR: 80, 
  K11KQ: 100, K11KA: 100, K11KR: 80, 
  K12KQ: 100, K12KA: 100, K12KR: 80, 
  K13KQ: 100, K13KA: 100, K13KR: 80, 
  K14KQ: 100, K14KA: 100, K14KR: 80, 
  K15KQ: 100, K15KA: 100, K15KR: 80, 
  K16KQ: 100, K16KA: 100, K16KR: 80, 
  K17KQ: 100, K17KA: 100, K17KR: 80, 
  K19KQ: 100, K19KA: 100, K19KR: 80, 
  K20KQ: 100, K20KA: 100, K20KR: 80, 
  K21KQ: 100, K21KA: 100, K21KR: 80, 
  K22KQ: 100, K22KA: 100, K22KR: 80, 
  K29KQ: 100, K29KA: 100, K29KR: 80, 
  K30KQ: 100, K30KA: 100, K30KR: 80, 
  K31KQ: 100, K31KA: 100, K31KR: 80, 
  K32KQ: 100, K32KA: 100, K32KR: 80, 
  K33KQ: 100, K33KA: 100, K33KR: 80, 
  K41KQ: 100, K41KA: 100, K41KR: 80, 
  K34KQ: 100, K34KA: 100, K34KR: 80, 
  K39KQ: 100, K39KA: 100, K39KR: 80, 
  K40KQ: 100, K40KA: 100, K40KR: 80, 
  K42KQ: 100, K42KA: 100, K42KR: 80, 
  K43KQ: 100, K43KA: 100, K43KR: 80, 
  K44KQ: 100, K44KA: 100, K44KR: 80, 
  K49KQ: 100, K49KA: 100, K49KR: 80, 
  K50KQ: 100, K50KA: 100, K50KR: 80, 
  SLT: 120, SLP: 120, SLR: 80, SLD: 120, SDT: 120, SDP: 120, SDR: 80, SDD: 120, SRT: 120, SRP: 120, SRR: 80, SRD: 120, STT: 120, STP: 120, STR: 80, STD: 120,
  PTT: 120, PTP: 120, PTD: 120, PRT: 80, PRP: 80, PRD: 80,
  CIT: 120, CIP: 120, CIR: 80, CID: 120, CST: 120, CSP: 120, CSR: 80, CSD: 120, CTT: 120, CTP: 120, CTR: 80, CTD: 120,
  BTT: 120, BTP: 120, BTR: 80, BTD: 120, BRT: 80, BRP: 80, BRR: 150, BRD: 80, ATT: 120, ATP: 120, ATR: 80, ATD: 120, ART: 80, ARP: 80, ARR: 150, ARD: 80,
  SHCT: 120, SHCP: 120, SHCR: 80, SHCD2: 120, 
  SHDT: 120, SHDP: 120, SHDR: 80, SHDD: 120, 
  SHRCT: 120, SHRCP: 120, SHRCR: 80, SHRCD: 120, 
  SHRDT: 120, SHRDP: 120, SHRDR: 80, SHRDD: 120, 
  SHT: 120, SHP: 120, SHR: 80, SHD: 120,
  KUR: 80, KTR: 80, KRK: 80,
  ZTN: 80, RQTY: 80, TQTY: 80, YQTY: 80, FQTY: 80, AZKE: 80, ZQTY: 80,
  SQTC: 80, SQTN: 80,
  TGTL: 80,
  TG01: 80, TG02: 80, TG03: 80, TG04: 80, TG05: 80, TG06: 80, TG07: 80, TG08: 80, TG09: 80, TG10: 80,
  TG11: 80, TG12: 80, TG13: 80, TG14: 80, TG15: 80, TG16: 80, TG17: 80, TG18: 80, TG19: 80, TG20: 80,
  TG21: 80, TG22: 80, TG23: 80, TG24: 80, TG25: 80, TG26: 80, TG27: 80, TG28: 80, TG29: 80, TG30: 80,
  TG31: 80, TG32: 80, TG33: 80, TG34: 80, TG35: 80, TG36: 80, TG37: 80, TG38: 80, TG39: 80, TG40: 80,
  TG41: 80, TG42: 80, TG43: 80, TG44: 80, TG45: 80, TG46: 80, TG47: 80, TG48: 80, TG49: 80, TG50: 80,
  SGTL: 80,
  SG01: 80, SG02: 80, SG03: 80, SG04: 80, SG05: 80, SG06: 80, SG07: 80, SG08: 80, SG09: 80, SG10: 80,
  SG11: 80, SG12: 80, SG13: 80, SG14: 80, SG15: 80, SG16: 80, SG17: 80, SG18: 80, SG19: 80, SG20: 80,
  SG21: 80, SG22: 80, SG23: 80, SG24: 80, SG25: 80, SG26: 80, SG27: 80, SG28: 80, SG29: 80, SG30: 80,
  SG31: 80, SG32: 80, SG33: 80, SG34: 80, SG35: 80, SG36: 80, SG37: 80, SG38: 80, SG39: 80, SG40: 80,
  SG41: 80, SG42: 80, SG43: 80, SG44: 80, SG45: 80, SG46: 80, SG47: 80, SG48: 80, SG49: 80, SG50: 80,
};
export const excelColWidths =
{
  ET1: 100, ER1: 200, ET2: 100, ER2: 200, ET3: 100, ER3: 200,
};

export const colDataType =
{
  no : {type: 'text', readOnly: true,},
  //以下に貼り付け
  ISC: {type: 'text', readOnly: true,}, ISN: {type: 'text', readOnly: true,}, IMC: {type: 'text', readOnly: true,}, IMN: {type: 'text', readOnly: true,}, INM: {type: 'text', readOnly: true,}, ICC: {type: 'text', readOnly: true,}, ICN: {type: 'text', readOnly: true,}, IBC: {type: 'text', readOnly: true,}, IBN: {type: 'text', readOnly: true,}, IJN: {type: 'text', readOnly: true,}, ICD: {type: 'text', readOnly: true,}, ICP: {type: 'text', readOnly: true,}, 
  SCD: {type: 'text', readOnly: true,}, SNM: {type: 'text', readOnly: true,},
  CCD: {type: 'text', readOnly: true,}, CNM: {type: 'text', readOnly: true,},
  TCD: {type: 'text', readOnly: true,}, TNM: {type: 'text', readOnly: true,},
  G2CD: {type: 'text', readOnly: true,}, G2NM: {type: 'text', readOnly: true,},
  G1CD: {type: 'text', readOnly: true,}, G1NM: {type: 'text', readOnly: true,},

  IC1: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  IC2: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  ILI: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0日',},},
  IPR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  LQT: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0.0', }, }, LQP: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0.0', }, }, LQR: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0.00%', }, }, LQD: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0.0', }, },
  DQT: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0.0', }, }, DQP: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0.0', }, }, DQR: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0.00%', }, }, DQD: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0.0', }, },
  OQT: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0.0', }, }, OQP: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0.0', }, }, OQR: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0.00%', }, }, OQD: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0.0', }, },
  ULT: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0.0', }, }, ULP: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0.0', }, }, ULR: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0.00%', }, }, ULD: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0.0', }, },
  UDT: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0.0', }, }, UDP: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0.0', }, }, UDR: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0.00%', }, }, UDD: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0.0', }, },
  UPT: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0.0', }, }, UPP: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0.0', }, }, UPR: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0.00%', }, }, UPD: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0.0', }, },
  SQT: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0.0', }, }, SQP: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0.0', }, }, SQR: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0.00%', }, }, SQD: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0.0', }, },
  RQT: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0.0', }, }, RQP: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0.0', }, }, RQR: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0.00%', }, }, RQD: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0.0', }, },
  WQT: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0.0', }, }, WQP: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0.0', }, }, WQR: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0.00%', }, }, WQD: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0.0', }, },
  LQT_PS: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, }, LQP_PS: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, }, LQR_PS: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0.00%', }, }, LQD_PS: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, },
  DQT_PS: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, }, DQP_PS: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, }, DQR_PS: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0.00%', }, }, DQD_PS: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, },
  OQT_PS: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, }, OQP_PS: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, }, OQR_PS: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0.00%', }, }, OQD_PS: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, },
  ULT_PS: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, }, ULP_PS: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, }, ULR_PS: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0.00%', }, }, ULD_PS: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, },
  UDT_PS: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, }, UDP_PS: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, }, UDR_PS: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0.00%', }, }, UDD_PS: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, },
  UPT_PS: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, }, UPP_PS: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, }, UPR_PS: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0.00%', }, }, UPD_PS: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, },
  SQT_PS: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, }, SQP_PS: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, }, SQR_PS: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0.00%', }, }, SQD_PS: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, },
  RQT_PS: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, }, RQP_PS: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, }, RQR_PS: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0.00%', }, }, RQD_PS: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, },
  WQT_PS: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, }, WQP_PS: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, }, WQR_PS: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0.00%', }, }, WQD_PS: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, },
  SLT: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},}, SLP: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},}, SLR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.00%',},}, SLD: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},}, SDT: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},}, SDP: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},}, SDR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.00%',},}, SDD: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},}, SRT: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},}, SRP: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},}, SRR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.00%',},}, SRD: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},}, STT: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},}, STP: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},}, STR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.00%',},}, STD: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  PTT: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},}, PTP: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},}, PTD: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},}, PRT: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.00%',},}, PRP: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.00%',},}, PRD: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.00P',},},
  CIT: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},}, CIP: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},}, CIR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.00%',},}, CID: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},}, CST: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},}, CSP: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},}, CSR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.00%',},}, CSD: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},}, CTT: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},}, CTP: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},}, CTR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.00%',},}, CTD: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  BTT: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},}, BTP: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},}, BTR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.00%',},}, BTD: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},}, BRT: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.00%',},}, BRP: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.00%',},}, BRD: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.00P',},}, ATT: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},}, ATP: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},}, ATR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.00%',},}, ATD: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},}, ART: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.00%',},}, ARP: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.00%',},}, ARD: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.00P',},}, 
  SHCT: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, }, 
  SHCP: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, }, 
  SHCR: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0.0%', }, },
  SHCD2: { type: 'numeric', readOnly: true, numericFormat: { pattern: '△0,0', }, }, 
  SHDT: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, }, 
  SHDP: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, }, 
  SHDR: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0.0%', }, },
  SHDD: { type: 'numeric', readOnly: true, numericFormat: { pattern: '△0,0', }, }, 
  SHRCT: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, }, 
  SHRCP: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, }, 
  SHRCR: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0.0%', }, },
  SHRCD: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, }, 
  SHRDT: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, }, 
  SHRDP: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, }, 
  SHRDR: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0.0%', }, },
  SHRDD: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, }, 
  SHT: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, },
  SHP: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, },
  SHR: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0.0%', }, },
  SHD: { type: 'numeric', readOnly: true, numericFormat: { pattern: '△0,0', }, },
  KUR: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0.00%', }, }, KTR: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0.00%', }, }, KRK: { type: 'text', readOnly: true, },
  JJT: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},  JJP: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},  JJR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},  JJD: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  JJQT: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0.0',},},  JJQP: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0.0',},},  JJQR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},  JJQD: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  JKT: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},},  JKP: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},},  JKR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},  JKD: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},},
  JKQT: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0.0',},},  JKQP: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0.0',},},  JKQR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},  JKQD: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},},
  JRT: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},  JRP: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},},  JRD: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0.0P',},},
  //欠品責任別
  KI1KQ: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0.0',},}, KI1KA: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},}, KI1KR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, 
  KI2KQ: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0.0',},}, KI2KA: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},}, KI2KR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, 
  KI3KQ: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0.0',},}, KI3KA: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},}, KI3KR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, 
  //欠品区分別
  K10KQ: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0.0',},}, K10KA: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},}, K10KR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, 
  K11KQ: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0.0',},}, K11KA: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},}, K11KR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, 
  K12KQ: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0.0',},}, K12KA: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},}, K12KR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, 
  K13KQ: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0.0',},}, K13KA: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},}, K13KR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, 
  K14KQ: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0.0',},}, K14KA: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},}, K14KR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, 
  K15KQ: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0.0',},}, K15KA: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},}, K15KR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, 
  K16KQ: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0.0',},}, K16KA: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},}, K16KR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, 
  K17KQ: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0.0',},}, K17KA: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},}, K17KR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, 
  K19KQ: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0.0',},}, K19KA: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},}, K19KR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, 
  K20KQ: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0.0',},}, K20KA: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},}, K20KR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, 
  K21KQ: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0.0',},}, K21KA: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},}, K21KR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, 
  K22KQ: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0.0',},}, K22KA: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},}, K22KR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, 
  K29KQ: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0.0',},}, K29KA: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},}, K29KR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, 
  K30KQ: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0.0',},}, K30KA: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},}, K30KR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, 
  K31KQ: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0.0',},}, K31KA: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},}, K31KR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, 
  K32KQ: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0.0',},}, K32KA: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},}, K32KR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, 
  K33KQ: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0.0',},}, K33KA: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},}, K33KR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, 
  K41KQ: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0.0',},}, K41KA: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},}, K41KR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, 
  K34KQ: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0.0',},}, K34KA: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},}, K34KR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, 
  K39KQ: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0.0',},}, K39KA: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},}, K39KR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, 
  K40KQ: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0.0',},}, K40KA: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},}, K40KR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, 
  K42KQ: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0.0',},}, K42KA: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},}, K42KR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, 
  K43KQ: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0.0',},}, K43KA: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},}, K43KR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, 
  K44KQ: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0.0',},}, K44KA: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},}, K44KR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, 
  K49KQ: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0.0',},}, K49KA: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},}, K49KR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, 
  K50KQ: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0.0',},}, K50KA: {type: 'numeric', readOnly: true, numericFormat: {pattern: '△0,0',},}, K50KR: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0.0%',},}, 

  ZTN: {type: 'text', readOnly: true}, RQTY: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0.0',},},  TQTY: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0.0',},},  YQTY: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0.0',},},  FQTY: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0.0',},},  AZKE: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0.0',},},  ZQTY: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0.0',},},
  SQTC: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0.0', }, }, SQTN: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0.0', }, },
  TGTL: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, },
  TG01: { type: 'text', readOnly: true, className: 'htCenter' },
  TG02: { type: 'text', readOnly: true, className: 'htCenter' },
  TG03: { type: 'text', readOnly: true, className: 'htCenter' },
  TG04: { type: 'text', readOnly: true, className: 'htCenter' },
  TG05: { type: 'text', readOnly: true, className: 'htCenter' },
  TG06: { type: 'text', readOnly: true, className: 'htCenter' },
  TG07: { type: 'text', readOnly: true, className: 'htCenter' },
  TG08: { type: 'text', readOnly: true, className: 'htCenter' },
  TG09: { type: 'text', readOnly: true, className: 'htCenter' },
  TG10: { type: 'text', readOnly: true, className: 'htCenter' },
  TG11: { type: 'text', readOnly: true, className: 'htCenter' },
  TG12: { type: 'text', readOnly: true, className: 'htCenter' },
  TG13: { type: 'text', readOnly: true, className: 'htCenter' },
  TG14: { type: 'text', readOnly: true, className: 'htCenter' },
  TG15: { type: 'text', readOnly: true, className: 'htCenter' },
  TG16: { type: 'text', readOnly: true, className: 'htCenter' },
  TG17: { type: 'text', readOnly: true, className: 'htCenter' },
  TG18: { type: 'text', readOnly: true, className: 'htCenter' },
  TG19: { type: 'text', readOnly: true, className: 'htCenter' },
  TG20: { type: 'text', readOnly: true, className: 'htCenter' },
  TG21: { type: 'text', readOnly: true, className: 'htCenter' },
  TG22: { type: 'text', readOnly: true, className: 'htCenter' },
  TG23: { type: 'text', readOnly: true, className: 'htCenter' },
  TG24: { type: 'text', readOnly: true, className: 'htCenter' },
  TG25: { type: 'text', readOnly: true, className: 'htCenter' },
  TG26: { type: 'text', readOnly: true, className: 'htCenter' },
  TG27: { type: 'text', readOnly: true, className: 'htCenter' },
  TG28: { type: 'text', readOnly: true, className: 'htCenter' },
  TG29: { type: 'text', readOnly: true, className: 'htCenter' },
  TG30: { type: 'text', readOnly: true, className: 'htCenter' },
  TG31: { type: 'text', readOnly: true, className: 'htCenter' },
  TG32: { type: 'text', readOnly: true, className: 'htCenter' },
  TG33: { type: 'text', readOnly: true, className: 'htCenter' },
  TG34: { type: 'text', readOnly: true, className: 'htCenter' },
  TG35: { type: 'text', readOnly: true, className: 'htCenter' },
  TG36: { type: 'text', readOnly: true, className: 'htCenter' },
  TG37: { type: 'text', readOnly: true, className: 'htCenter' },
  TG38: { type: 'text', readOnly: true, className: 'htCenter' },
  TG39: { type: 'text', readOnly: true, className: 'htCenter' },
  TG40: { type: 'text', readOnly: true, className: 'htCenter' },
  TG41: { type: 'text', readOnly: true, className: 'htCenter' },
  TG42: { type: 'text', readOnly: true, className: 'htCenter' },
  TG43: { type: 'text', readOnly: true, className: 'htCenter' },
  TG44: { type: 'text', readOnly: true, className: 'htCenter' },
  TG45: { type: 'text', readOnly: true, className: 'htCenter' },
  TG46: { type: 'text', readOnly: true, className: 'htCenter' },
  TG47: { type: 'text', readOnly: true, className: 'htCenter' },
  TG48: { type: 'text', readOnly: true, className: 'htCenter' },
  TG49: { type: 'text', readOnly: true, className: 'htCenter' },
  TG50: { type: 'text', readOnly: true, className: 'htCenter' },
  SGTL: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, },
  SG01: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0.0', }, },
  SG02: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0.0', }, },
  SG03: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0.0', }, },
  SG04: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0.0', }, },
  SG05: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0.0', }, },
  SG06: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0.0', }, },
  SG07: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0.0', }, },
  SG08: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0.0', }, },
  SG09: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0.0', }, },
  SG10: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0.0', }, },
  SG11: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0.0', }, },
  SG12: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0.0', }, },
  SG13: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0.0', }, },
  SG14: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0.0', }, },
  SG15: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0.0', }, },
  SG16: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0.0', }, },
  SG17: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0.0', }, },
  SG18: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0.0', }, },
  SG19: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0.0', }, },
  SG20: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0.0', }, },
  SG21: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0.0', }, },
  SG22: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0.0', }, },
  SG23: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0.0', }, },
  SG24: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0.0', }, },
  SG25: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0.0', }, },
  SG26: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0.0', }, },
  SG27: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0.0', }, },
  SG28: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0.0', }, },
  SG29: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0.0', }, },
  SG30: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0.0', }, },
  SG31: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0.0', }, },
  SG32: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0.0', }, },
  SG33: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0.0', }, },
  SG34: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0.0', }, },
  SG35: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0.0', }, },
  SG36: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0.0', }, },
  SG37: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0.0', }, },
  SG38: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0.0', }, },
  SG39: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0.0', }, },
  SG40: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0.0', }, },
  SG41: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0.0', }, },
  SG42: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0.0', }, },
  SG43: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0.0', }, },
  SG44: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0.0', }, },
  SG45: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0.0', }, },
  SG46: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0.0', }, },
  SG47: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0.0', }, },
  SG48: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0.0', }, },
  SG49: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0.0', }, },
  SG50: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0.0', }, },
};
export const excelColDataType =
{
  ET1: { type: 'text', readOnly: true, }, ER1: { type: 'text', readOnly: true, },
  ET2: { type: 'text', readOnly: true, }, ER2: { type: 'text', readOnly: true, },
  ET3: { type: 'text', readOnly: true, }, ER3: { type: 'text', readOnly: true, },
};

export const colRendererName =
{
  no : 'basicRenderer',
  //以下に貼り付け
  ISC: 'basicRenderer', ISN: 'basicRenderer', IMC: 'basicRenderer', IMN: 'basicRenderer', INM: 'basicRenderer', ICC: 'basicRenderer', ICN: 'basicRenderer', IBC: 'basicRenderer', IBN: 'basicRenderer', IJN: 'basicRenderer', ICD: 'basicRenderer', ICP: 'basicRenderer', IC1: 'basicRenderer', IC2: 'basicRenderer', ILI: 'basicRenderer', IPR: 'basicRenderer',
  SCD: 'basicRenderer', SNM: 'basicRenderer',
  CCD: 'basicRenderer', CNM: 'basicRenderer',
  TCD: 'basicRenderer', TNM: 'basicRenderer',
  G2CD: 'basicRenderer', G2NM: 'basicRenderer',
  G1CD: 'basicRenderer', G1NM: 'basicRenderer',
  LQT: 'basicRenderer', LQP: 'basicRenderer', LQR: 'rateRenderer', LQD: 'basicRenderer',
  DQT: 'basicRenderer', DQP: 'basicRenderer', DQR: 'rateRenderer', DQD: 'basicRenderer',
  OQT: 'basicRenderer', OQP: 'basicRenderer', OQR: 'rateRenderer', OQD: 'basicRenderer',
  ULT: 'basicRenderer', ULP: 'basicRenderer', ULR: 'rateRenderer', ULD: 'basicRenderer',
  UDT: 'basicRenderer', UDP: 'basicRenderer', UDR: 'rateRenderer', UDD: 'basicRenderer',
  UPT: 'basicRenderer', UPP: 'basicRenderer', UPR: 'rateRenderer', UPD: 'basicRenderer',
  SQT: 'basicRenderer', SQP: 'basicRenderer', SQR: 'rateRenderer', SQD: 'basicRenderer',
  RQT: 'basicRenderer', RQP: 'basicRenderer', RQR: 'rateRenderer', RQD: 'basicRenderer',
  WQT: 'basicRenderer', WQP: 'basicRenderer', WQR: 'rateRenderer', WQD: 'basicRenderer',
  LQT_PS: 'basicRenderer', LQP_PS: 'basicRenderer', LQR_PS: 'rateRenderer', LQD_PS: 'basicRenderer',
  DQT_PS: 'basicRenderer', DQP_PS: 'basicRenderer', DQR_PS: 'rateRenderer', DQD_PS: 'basicRenderer',
  OQT_PS: 'basicRenderer', OQP_PS: 'basicRenderer', OQR_PS: 'rateRenderer', OQD_PS: 'basicRenderer',
  ULT_PS: 'basicRenderer', ULP_PS: 'basicRenderer', ULR_PS: 'rateRenderer', ULD_PS: 'basicRenderer',
  UDT_PS: 'basicRenderer', UDP_PS: 'basicRenderer', UDR_PS: 'rateRenderer', UDD_PS: 'basicRenderer',
  UPT_PS: 'basicRenderer', UPP_PS: 'basicRenderer', UPR_PS: 'rateRenderer', UPD_PS: 'basicRenderer',
  SQT_PS: 'basicRenderer', SQP_PS: 'basicRenderer', SQR_PS: 'rateRenderer', SQD_PS: 'basicRenderer',
  RQT_PS: 'basicRenderer', RQP_PS: 'basicRenderer', RQR_PS: 'rateRenderer', RQD_PS: 'basicRenderer',
  WQT_PS: 'basicRenderer', WQP_PS: 'basicRenderer', WQR_PS: 'rateRenderer', WQD_PS: 'basicRenderer',
  SLT: 'basicRenderer', SLP: 'basicRenderer', SLR: 'rateRenderer', SLD: 'basicRenderer', SDT: 'basicRenderer', SDP: 'basicRenderer', SDR: 'rateRenderer', SDD: 'basicRenderer', SRT: 'basicRenderer', SRP: 'basicRenderer', SRR: 'rateRenderer', SRD: 'basicRenderer', STT: 'basicRenderer', STP: 'basicRenderer', STR: 'rateRenderer', STD: 'basicRenderer',
  PTT: 'basicRenderer', PTP: 'basicRenderer', PTD: 'basicRenderer', PRT: 'basicRenderer', PRP: 'basicRenderer', PRR: 'basicRenderer', PRD: 'basicRenderer',
  JJT: 'basicRenderer', JJP: 'basicRenderer', JJR: 'basicRenderer', JJD: 'basicRenderer', 
  JJQT: 'basicRenderer', JJQP: 'basicRenderer', JJQR: 'basicRenderer', JJQD: 'basicRenderer', 
  JKT: 'basicRenderer', JKP: 'basicRenderer', JKR: 'nebikiRateRenderer', JKD: 'basicRenderer', 
  JKQT: 'basicRenderer', JKQP: 'basicRenderer', JKQR: 'nebikiRateRenderer', JKQD: 'basicRenderer', 
  JRT: 'keppinRateRenderer', JRP: 'keppinRateRenderer', JRD: 'keppinRateDiffRenderer', 
  //欠品責任別
  KI1KQ: 'basicRenderer', KI1KA: 'basicRenderer', KI1KR: 'keppinRateRenderer', 
  KI2KQ: 'basicRenderer', KI2KA: 'basicRenderer', KI2KR: 'keppinRateRenderer', 
  KI3KQ: 'basicRenderer', KI3KA: 'basicRenderer', KI3KR: 'keppinRateRenderer', 
  //欠品区分別
  K10KQ: 'basicRenderer', K10KA: 'basicRenderer', K10KR: 'keppinRateRenderer', 
  K11KQ: 'basicRenderer', K11KA: 'basicRenderer', K11KR: 'keppinRateRenderer', 
  K12KQ: 'basicRenderer', K12KA: 'basicRenderer', K12KR: 'keppinRateRenderer', 
  K13KQ: 'basicRenderer', K13KA: 'basicRenderer', K13KR: 'keppinRateRenderer', 
  K14KQ: 'basicRenderer', K14KA: 'basicRenderer', K14KR: 'keppinRateRenderer', 
  K15KQ: 'basicRenderer', K15KA: 'basicRenderer', K15KR: 'keppinRateRenderer', 
  K16KQ: 'basicRenderer', K16KA: 'basicRenderer', K16KR: 'keppinRateRenderer', 
  K17KQ: 'basicRenderer', K17KA: 'basicRenderer', K17KR: 'keppinRateRenderer', 
  K19KQ: 'basicRenderer', K19KA: 'basicRenderer', K19KR: 'keppinRateRenderer', 
  K20KQ: 'basicRenderer', K20KA: 'basicRenderer', K20KR: 'keppinRateRenderer', 
  K21KQ: 'basicRenderer', K21KA: 'basicRenderer', K21KR: 'keppinRateRenderer', 
  K22KQ: 'basicRenderer', K22KA: 'basicRenderer', K22KR: 'keppinRateRenderer', 
  K29KQ: 'basicRenderer', K29KA: 'basicRenderer', K29KR: 'keppinRateRenderer', 
  K30KQ: 'basicRenderer', K30KA: 'basicRenderer', K30KR: 'keppinRateRenderer', 
  K31KQ: 'basicRenderer', K31KA: 'basicRenderer', K31KR: 'keppinRateRenderer', 
  K32KQ: 'basicRenderer', K32KA: 'basicRenderer', K32KR: 'keppinRateRenderer', 
  K33KQ: 'basicRenderer', K33KA: 'basicRenderer', K33KR: 'keppinRateRenderer', 
  K41KQ: 'basicRenderer', K41KA: 'basicRenderer', K41KR: 'keppinRateRenderer', 
  K34KQ: 'basicRenderer', K34KA: 'basicRenderer', K34KR: 'keppinRateRenderer', 
  K39KQ: 'basicRenderer', K39KA: 'basicRenderer', K39KR: 'keppinRateRenderer', 
  K40KQ: 'basicRenderer', K40KA: 'basicRenderer', K40KR: 'keppinRateRenderer', 
  K42KQ: 'basicRenderer', K42KA: 'basicRenderer', K42KR: 'keppinRateRenderer', 
  K43KQ: 'basicRenderer', K43KA: 'basicRenderer', K43KR: 'keppinRateRenderer', 
  K44KQ: 'basicRenderer', K44KA: 'basicRenderer', K44KR: 'keppinRateRenderer', 
  K49KQ: 'basicRenderer', K49KA: 'basicRenderer', K49KR: 'keppinRateRenderer', 
  K50KQ: 'basicRenderer', K50KA: 'basicRenderer', K50KR: 'keppinRateRenderer', 
  
  CIT: 'basicRenderer', CIP: 'basicRenderer', CIR: 'rateRenderer', CID: 'basicRenderer', CST: 'basicRenderer', CSP: 'basicRenderer', CSR: 'rateRenderer', CSD: 'basicRenderer', CTT: 'basicRenderer', CTP: 'basicRenderer', CTR: 'rateRenderer', CTD: 'basicRenderer',
  BTT: 'basicRenderer', BTP: 'basicRenderer', BTR: 'rateRenderer', BTD: 'basicRenderer', BRT: 'basicRenderer', BRP: 'basicRenderer', BRR: 'basicRenderer', BRD: 'basicRenderer', ATT: 'basicRenderer', ATP: 'basicRenderer', ATR: 'rateRenderer', ATD: 'basicRenderer', ART: 'basicRenderer', ARP: 'basicRenderer', ARR: 'basicRenderer', ARD: 'basicRenderer', 
  SHCT: 'basicRenderer', SHCP: 'basicRenderer', SHCR: 'nebikiRateRenderer', SHCD2: 'basicRenderer', 
  SHDT: 'basicRenderer', SHDP: 'basicRenderer', SHDR: 'nebikiRateRenderer', SHDD: 'basicRenderer', 
  SHRCT: 'basicRenderer', SHRCP: 'basicRenderer', SHRCR: 'rateRenderer', SHRCD: 'basicRenderer', 
  SHRDT: 'basicRenderer', SHRDP: 'basicRenderer', SHRDR: 'rateRenderer', SHRDD: 'basicRenderer', 
  SHT: 'basicRenderer', SHP: 'basicRenderer', SHR: 'nebikiRateRenderer', SHD: 'basicRenderer', 
  KUR: 'basicRenderer', KTR: 'basicRenderer', KRK: 'basicRenderer',
  ZTN: 'basicRenderer', RQTY: 'basicRenderer', TQTY: 'basicRenderer', YQTY: 'basicRenderer', FQTY: 'basicRenderer', AZKE: 'basicRenderer', ZQTY: 'basicRenderer',
  SQTC: 'basicRenderer', SQTN: 'basicRenderer',
  TGTL: 'basicRenderer',
  TG01: 'basicRenderer', TG02: 'basicRenderer', TG03: 'basicRenderer', TG04: 'basicRenderer', TG05: 'basicRenderer', TG06: 'basicRenderer', TG07: 'basicRenderer', TG08: 'basicRenderer', TG09: 'basicRenderer', TG10: 'basicRenderer',
  TG11: 'basicRenderer', TG12: 'basicRenderer', TG13: 'basicRenderer', TG14: 'basicRenderer', TG15: 'basicRenderer', TG16: 'basicRenderer', TG17: 'basicRenderer', TG18: 'basicRenderer', TG19: 'basicRenderer', TG20: 'basicRenderer',
  TG21: 'basicRenderer', TG22: 'basicRenderer', TG23: 'basicRenderer', TG24: 'basicRenderer', TG25: 'basicRenderer', TG26: 'basicRenderer', TG27: 'basicRenderer', TG28: 'basicRenderer', TG29: 'basicRenderer', TG30: 'basicRenderer',
  TG31: 'basicRenderer', TG32: 'basicRenderer', TG33: 'basicRenderer', TG34: 'basicRenderer', TG35: 'basicRenderer', TG36: 'basicRenderer', TG37: 'basicRenderer', TG38: 'basicRenderer', TG39: 'basicRenderer', TG40: 'basicRenderer',
  TG41: 'basicRenderer', TG42: 'basicRenderer', TG43: 'basicRenderer', TG44: 'basicRenderer', TG45: 'basicRenderer', TG46: 'basicRenderer', TG47: 'basicRenderer', TG48: 'basicRenderer', TG49: 'basicRenderer', TG50: 'basicRenderer',
  SGTL: 'basicRenderer',
  SG01: 'basicRenderer', SG02: 'basicRenderer', SG03: 'basicRenderer', SG04: 'basicRenderer', SG05: 'basicRenderer', SG06: 'basicRenderer', SG07: 'basicRenderer', SG08: 'basicRenderer', SG09: 'basicRenderer', SG10: 'basicRenderer',
  SG11: 'basicRenderer', SG12: 'basicRenderer', SG13: 'basicRenderer', SG14: 'basicRenderer', SG15: 'basicRenderer', SG16: 'basicRenderer', SG17: 'basicRenderer', SG18: 'basicRenderer', SG19: 'basicRenderer', SG20: 'basicRenderer',
  SG21: 'basicRenderer', SG22: 'basicRenderer', SG23: 'basicRenderer', SG24: 'basicRenderer', SG25: 'basicRenderer', SG26: 'basicRenderer', SG27: 'basicRenderer', SG28: 'basicRenderer', SG29: 'basicRenderer', SG30: 'basicRenderer',
  SG31: 'basicRenderer', SG32: 'basicRenderer', SG33: 'basicRenderer', SG34: 'basicRenderer', SG35: 'basicRenderer', SG36: 'basicRenderer', SG37: 'basicRenderer', SG38: 'basicRenderer', SG39: 'basicRenderer', SG40: 'basicRenderer',
  SG41: 'basicRenderer', SG42: 'basicRenderer', SG43: 'basicRenderer', SG44: 'basicRenderer', SG45: 'basicRenderer', SG46: 'basicRenderer', SG47: 'basicRenderer', SG48: 'basicRenderer', SG49: 'basicRenderer', SG50: 'basicRenderer',
};

//前年実績がマイナスの場合表示を変更する項目
export const preMinusList = [
  { cur: 'JJT', pre: 'JJP', key: 'JJR', },    //受注
  { cur: 'JJQT', pre: 'JJQP', key: 'JJQR', },    //受注
  { cur: 'LQT', pre: 'LQP', key: 'LQR', },
  { cur: 'DQT', pre: 'DQP', key: 'DQR', },
  { cur: 'OQT', pre: 'OQP', key: 'OQR', },
  { cur: 'ULT', pre: 'ULP', key: 'ULR', },
  { cur: 'UDT', pre: 'UDP', key: 'UDR', },
  { cur: 'UPT', pre: 'UPP', key: 'UPR', },
  { cur: 'SQT', pre: 'SQP', key: 'SQR', },
  { cur: 'SLT', pre: 'SLP', key: 'SLR', },    //総売上配送売上
  { cur: 'SDT', pre: 'SDP', key: 'SDR', },    //総売上直送売上
  { cur: 'STT', pre: 'STP', key: 'STR', },    //総売上総売上
];
//前年実績がプラスの場合表示を変更する項目
export const prePlusList = [
  { cur: 'JKT', pre: 'JKP', key: 'JKR', },    //欠品
  { cur: 'JKQT', pre: 'JKQP', key: 'JKQR', },    //欠品
  { cur: 'RQT', pre: 'RQP', key: 'RQR', },
  { cur: 'WQT', pre: 'WQP', key: 'WQR', },
  { cur: 'SRT', pre: 'SRP', key: 'SRR', },    //総売上返品
];


export interface RankData {
  rank_id?: string, rank_name?: string,
}

export const cutRankHeaders = [
  "ランク", "ｱｲﾃﾑ数", "ｱｲﾃﾑ総数", "構成比", "基準"
];
export const cutRankColDataType = [
  { type: 'text', readOnly: true, },
  { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, },
  { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, },
  { type: 'numeric', readOnly: true, numericFormat: { pattern: '0.0%', }, },
  { type: 'text', readOnly: true, },
];
export const cutRankDatas = [
  ["S", 0, 0, 0, "構成比累計の0%以上40％未満"],
  ["A", 0, 0, 0, "構成比累計の40％以上70％未満"],
  ["B", 0, 0, 0, "構成比累計の70％以上90％未満"],
  ["C", 0, 0, 0, "構成比累計の90％以上"],
  ["Cα", 0, 0, 0, "Cランク内のCɤ以外"],
  ["Cɤ", 0, 0, 0, ""],
  ["Z", 0, 0, 0, "配送売上100円以下"],
  ["ZX", 0, 0, 0, "Zランク内の新商品以外(カット)"],
  ["Zn", 0, 0, 0, "Zランク内の新商品(当月以降販売開始)"],
];

export const cutRankColWidths = [40, 60, 60, 60, 240];

export const cutRankList: RankData[] = [
  { rank_id: "6", rank_name: "S" },
  { rank_id: "5", rank_name: "A" },
  { rank_id: "4", rank_name: "B" },
  { rank_id: "3", rank_name: "Cα" },
  { rank_id: "2", rank_name: "Cɤ" },
  { rank_id: "1", rank_name: "ZX" },
  { rank_id: "7", rank_name: "Zn" },
];

export const pbKinds: CodeName[] = [
  { code: "", name: "全て", labelString(): string { return this.name } },
  { code: "pb", name: "PBのみ", labelString(): string { return this.name } },
  { code: "notPb", name: "PB以外", labelString(): string { return this.name } },
];
export const teibans: CodeName[] = [
  { code: "", name: "全て", labelString(): string { return this.name } },
  { code: "teiban", name: "定番", labelString(): string { return this.name } },
  { code: "teibangai", name: "定番外", labelString(): string { return this.name } },
];


//列定義情報（動的パラメータ）
interface ItemListColModelInterface {
  //列固定
  fixedColumnsLeft: number,

  //テーブルの各列のキー
  colKeys: string[],
  excelColKeys: string[],
}

export class ItemListColModel implements ItemListColModelInterface {
  //列固定
  fixedColumnsLeft: number;
  //テーブルの各列のキー
  colKeys: string[];
  excelColKeys: string[];
  //ヘッダー[][]
  nestedHeaders;
  //テーブルの各列の逆引き用マップ
  colKeysMap;

  constructor(param: ItemListColModelInterface) {
    this.fixedColumnsLeft = param.fixedColumnsLeft;
    this.colKeys = param.colKeys;
    this.excelColKeys = param.excelColKeys;

    //テーブルの各列のキー逆引き
    const colKeysMap = {};
    param.colKeys.forEach((colKey, index) => {
      colKeysMap[colKey] = index;
    });
    this.colKeysMap = colKeysMap;

    //nestedHeaders初期化
    this.initNestedHeaders();
  }

  //nestedHeaders初期化
  initNestedHeaders() {
    //nestedHeaders
    const nestedHeaders = [];
    // const customBorders = [];
    headers.forEach((header, index) => {
      const lastHeader = index == headers.length - 1;
      const nestedHeader = [];

      let colspan=0;
      let preHeaderVal = null;
      this.colKeys.forEach((colKey) => {
        const headerVal = header[colKey];
        if(lastHeader) {
          nestedHeader.push(headerVal);
        }
        else {
          if(preHeaderVal !== null && preHeaderVal != headerVal){
            nestedHeader.push(colspan == 1 ? preHeaderVal : {label: preHeaderVal, colspan: colspan});
            colspan=0;
            // //枠線
            // customBorders.push({
            //   range: {
            //     from: {row: 1,col: index2},
            //     to: {row: 10,col: index2},
            //   },
            //   right: {
            //     width: 1,
            //     color: '#000000'
            //   }});
          }
          colspan++;
          preHeaderVal = headerVal;
        }
      });
      if(!lastHeader && colspan > 0) {
        nestedHeader.push(colspan == 1 ? preHeaderVal : {label: preHeaderVal, colspan: colspan});
      }

      nestedHeaders.push(nestedHeader);
    });

    this.nestedHeaders = nestedHeaders;
  }

  //function
  keyFromCol(column:number):string {
    return this.colKeys[column];
  }
  keyFromCols(columns:number[]):string[] {
    const vals:string[] = [];
    // columns.forEach(column => vals.push(this.keyFromCol(column)))
    columns.forEach(column => vals.push(this.colKeys[column]))
    return vals;
  }
  colFromKey(colKey:string):number {
    return this.colKeysMap[colKey];
  }
  colFromKeys(colKeys:string[]):number[] {
    const vals:number[] = [];
    // colKeys.forEach(colKey => vals.push(this.colFromKey(colKey)))
    // colKeys.forEach(colKey => vals.push(this.colKeysMap[colKey]))
    colKeys.forEach(colKey => {if(this.colKeysMap[colKey]) vals.push(this.colKeysMap[colKey])});
    return vals;
  }
}

//仕入実績
export const colKeysShiire: string[] = [
  "SHCT", "SHCP", "SHCR", "SHCD2", 
  "SHDT", "SHDP", "SHDR", "SHDD", 
  "SHRCT", "SHRCP", "SHRCR", "SHRCD", 
  "SHRDT", "SHRDP", "SHRDR", "SHRDD", 
  "SHT", "SHP", "SHR", "SHD",
];

//ItemList用の列モデル
export const itemListColModelDefault:ItemListColModel = new ItemListColModel({
  //列固定
  fixedColumnsLeft: 6,

  //テーブルの各列のキー
  colKeys: [
    "no",

    //以下に貼り付け
    "ISC", "ISN", "IMC", "IMN", "INM", "ICC", "ICN", "IBC", "IBN", "IJN", "ICD", "ICP", "IC1", "IC2", "ILI", "IPR",
    "SCD", "SNM",
    "CCD", "CNM",
    "TCD", "TNM",
    "G2CD", "G2NM",
    "G1CD", "G1NM",
  
    "JJQT", "JJQP", "JJQR", "JJQD",
    "JJT", "JJP", "JJR", "JJD",
    "LQT", "LQP", "LQR", "LQD",
    "DQT", "DQP", "DQR", "DQD",
    "OQT", "OQP", "OQR", "OQD",
    "ULT", "ULP", "ULR", "ULD",
    "UDT", "UDP", "UDR", "UDD",
    "UPT", "UPP", "UPR", "UPD",
    "SQT", "SQP", "SQR", "SQD",
    "RQT", "RQP", "RQR", "RQD",
    "WQT", "WQP", "WQR", "WQD",
    "LQT_PS", "LQP_PS", "LQR_PS", "LQD_PS",
    "DQT_PS", "DQP_PS", "DQR_PS", "DQD_PS",
    "OQT_PS", "OQP_PS", "OQR_PS", "OQD_PS",
    "ULT_PS", "ULP_PS", "ULR_PS", "ULD_PS",
    "UDT_PS", "UDP_PS", "UDR_PS", "UDD_PS",
    "UPT_PS", "UPP_PS", "UPR_PS", "UPD_PS",
    "SQT_PS", "SQP_PS", "SQR_PS", "SQD_PS",
    "RQT_PS", "RQP_PS", "RQR_PS", "RQD_PS",
    "WQT_PS", "WQP_PS", "WQR_PS", "WQD_PS",
    "SLT", "SLP", "SLR", "SLD", "SDT", "SDP", "SDR", "SDD", "SRT", "SRP", "SRR", "SRD", "STT", "STP", "STR", "STD",
    "PTT", "PTP", "PTD", "PRT", "PRP", "PRD",

    "JKQT", "JKQP", "JKQR", "JKQD",
    "JKT", "JKP", "JKR", "JKD",
    "JRT", "JRP", "JRD", 
    //欠品責任別
    "KI1KQ", "KI1KA", "KI1KR",
    "KI2KQ", "KI2KA", "KI2KR",
    "KI3KQ", "KI3KA", "KI3KR",

    "CIT", "CIP", "CIR", "CID", "CST", "CSP", "CSR", "CSD", "CTT", "CTP", "CTR", "CTD",
    "BTT", "BTP", "BTR", "BTD", "BRT", "BRP", "BRD", "ATT", "ATP", "ATR", "ATD", "ART", "ARP", "ARD",

    ...colKeysShiire,

    //欠品区分別
    "K10KQ", "K10KA", "K10KR",
    "K11KQ", "K11KA", "K11KR",
    "K12KQ", "K12KA", "K12KR",
    // "K13KQ", "K13KA", "K13KR",
    "K14KQ", "K14KA", "K14KR",
    "K15KQ", "K15KA", "K15KR",
    "K16KQ", "K16KA", "K16KR",
    "K17KQ", "K17KA", "K17KR",
    "K19KQ", "K19KA", "K19KR",

    "K20KQ", "K20KA", "K20KR",
    "K21KQ", "K21KA", "K21KR",
    "K22KQ", "K22KA", "K22KR",
    "K29KQ", "K29KA", "K29KR",

    "K30KQ", "K30KA", "K30KR",
    "K31KQ", "K31KA", "K31KR",
    // "K32KQ", "K32KA", "K32KR",
    // "K33KQ", "K33KA", "K33KR",
    "K34KQ", "K34KA", "K34KR",
    // "K39KQ", "K39KA", "K39KR",
    "K40KQ", "K40KA", "K40KR",
    "K41KQ", "K41KA", "K41KR",
    "K42KQ", "K42KA", "K42KR",
    "K43KQ", "K43KA", "K43KR",
    // "K44KQ", "K44KA", "K44KR",
    // "K49KQ", "K49KA", "K49KR",
    "K50KQ", "K50KA", "K50KR",

  ],
  excelColKeys: [],

});


export const colKeysTeibanGroup1s: string[] = [
  "TG01", "TG02", "TG03", "TG04", "TG05", "TG06", "TG07", "TG08", "TG09", "TG10",
  "TG11", "TG12", "TG13", "TG14", "TG15", "TG16", "TG17", "TG18", "TG19", "TG20",
  "TG21", "TG22", "TG23", "TG24", "TG25", "TG26", "TG27", "TG28", "TG29", "TG30",
  "TG31", "TG32", "TG33", "TG34", "TG35", "TG36", "TG37", "TG38", "TG39", "TG40",
  "TG41", "TG42", "TG43", "TG44", "TG45", "TG46", "TG47", "TG48", "TG49", "TG50",
];
export const colKeysShukkaGroup1s: string[] = [
  "SG01", "SG02", "SG03", "SG04", "SG05", "SG06", "SG07", "SG08", "SG09", "SG10",
  "SG11", "SG12", "SG13", "SG14", "SG15", "SG16", "SG17", "SG18", "SG19", "SG20",
  "SG21", "SG22", "SG23", "SG24", "SG25", "SG26", "SG27", "SG28", "SG29", "SG30",
  "SG31", "SG32", "SG33", "SG34", "SG35", "SG36", "SG37", "SG38", "SG39", "SG40",
  "SG41", "SG42", "SG43", "SG44", "SG45", "SG46", "SG47", "SG48", "SG49", "SG50",
];
export const itemListColModelReportCut:ItemListColModel = new ItemListColModel({
  //列固定
  fixedColumnsLeft: 6,

  //テーブルの各列のキー
  colKeys: [
    "no",

    //以下に貼り付け
    "ISC", "ISN", "IMC", "IMN", "INM", "ICC", "ICN", "IBC", "IBN", "IJN", "ICD", "ICP", "IC1", "IC2", "ILI", "IPR",

    "SLT", "SLP", "SLR", "SLD",
    "STT", "STP", "STR", "STD",
    "KUR", "KTR", "KRK",
    "ZTN", "RQTY", "TQTY", "YQTY", "FQTY", "AZKE", "ZQTY",
    "SQTC", "SQTN",
    "TGTL", ...colKeysTeibanGroup1s,
    "SGTL", ...colKeysShukkaGroup1s,
  ],
  excelColKeys: [
    "ET1", "ER1", "ET2", "ER2", "ET3", "ER3",
  ],

});
export const itemListColModelKeppin:ItemListColModel = new ItemListColModel({
  //列固定
  fixedColumnsLeft: 6,

  //テーブルの各列のキー
  colKeys: [
    "no",

    //以下に貼り付け
    "ISC", "ISN", "IMC", "IMN", "INM", "ICC", "ICN", "IBC", "IBN", "IJN", "ICD", "ICP", "IC1", "IC2", "ILI", "IPR",
    "SCD", "SNM",
    "CCD", "CNM",
    "TCD", "TNM",
    "G2CD", "G2NM",
    "G1CD", "G1NM",
  
    "JJQT", "JJQP", "JJQR", "JJQD",
    "JJT", "JJP", "JJR", "JJD",
    "LQT", "LQP", "LQR", "LQD",
    "DQT", "DQP", "DQR", "DQD",
    "OQT", "OQP", "OQR", "OQD",
    "ULT", "ULP", "ULR", "ULD",
    "UDT", "UDP", "UDR", "UDD",
    "UPT", "UPP", "UPR", "UPD",
    "SQT", "SQP", "SQR", "SQD",
    "RQT", "RQP", "RQR", "RQD",
    "WQT", "WQP", "WQR", "WQD",
    "LQT_PS", "LQP_PS", "LQR_PS", "LQD_PS",
    "DQT_PS", "DQP_PS", "DQR_PS", "DQD_PS",
    "OQT_PS", "OQP_PS", "OQR_PS", "OQD_PS",
    "ULT_PS", "ULP_PS", "ULR_PS", "ULD_PS",
    "UDT_PS", "UDP_PS", "UDR_PS", "UDD_PS",
    "UPT_PS", "UPP_PS", "UPR_PS", "UPD_PS",
    "SQT_PS", "SQP_PS", "SQR_PS", "SQD_PS",
    "RQT_PS", "RQP_PS", "RQR_PS", "RQD_PS",
    "WQT_PS", "WQP_PS", "WQR_PS", "WQD_PS",
    "SLT", "SLP", "SLR", "SLD", "SDT", "SDP", "SDR", "SDD", "SRT", "SRP", "SRR", "SRD", "STT", "STP", "STR", "STD",
    "PTT", "PTP", "PTD", "PRT", "PRP", "PRD",

    "JKQT", "JKQP", "JKQR", "JKQD",
    "JKT", "JKP", "JKR", "JKD",
    "JRT", "JRP", "JRD", 
    //欠品責任別
    "KI1KQ", "KI1KA", "KI1KR",
    "KI2KQ", "KI2KA", "KI2KR",
    "KI3KQ", "KI3KA", "KI3KR",

    "CIT", "CIP", "CIR", "CID", "CST", "CSP", "CSR", "CSD", "CTT", "CTP", "CTR", "CTD",
    "BTT", "BTP", "BTR", "BTD", "BRT", "BRP", "BRD", "ATT", "ATP", "ATR", "ATD", "ART", "ARP", "ARD",

    ...colKeysShiire,

    //欠品区分別
    "K10KQ", "K10KA", "K10KR",
    "K11KQ", "K11KA", "K11KR",
    "K12KQ", "K12KA", "K12KR",
    // "K13KQ", "K13KA", "K13KR",
    "K14KQ", "K14KA", "K14KR",
    "K15KQ", "K15KA", "K15KR",
    "K16KQ", "K16KA", "K16KR",
    "K17KQ", "K17KA", "K17KR",
    "K19KQ", "K19KA", "K19KR",

    "K20KQ", "K20KA", "K20KR",
    "K21KQ", "K21KA", "K21KR",
    "K22KQ", "K22KA", "K22KR",
    "K29KQ", "K29KA", "K29KR",

    "K30KQ", "K30KA", "K30KR",
    "K31KQ", "K31KA", "K31KR",
    // "K32KQ", "K32KA", "K32KR",
    // "K33KQ", "K33KA", "K33KR",
    "K34KQ", "K34KA", "K34KR",
    // "K39KQ", "K39KA", "K39KR",
    "K40KQ", "K40KA", "K40KR",
    "K41KQ", "K41KA", "K41KR",
    "K42KQ", "K42KA", "K42KR",
    "K43KQ", "K43KA", "K43KR",
    // "K44KQ", "K44KA", "K44KR",
    // "K49KQ", "K49KA", "K49KR",
    "K50KQ", "K50KA", "K50KR",
  ],
  excelColKeys: [],
});
