import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { YM, YW, CodeName } from "@/store/common";
import {RequestParam} from "@/assets/apitype/salesList";
import { SalesListColModel, salesListColModelDefault, salesListColModelReport10, salesListColModelReport20, salesListColModelReport21, salesListColModelReport30, salesListColModelReport40, colKeysKeihi, colKeysShiire, colKeys40 } from "@/components/saleslist/SalesListTableModel";
import * as arrayUtil from "@/util/arrayUtil";
import moment from 'moment';

//強制表示列
export const mustVisibleColumnsKey = [
  "no",
  ...colKeys40,
];
//非表示列　月次速報
export const unVisibleColumnsKeyQuick = [
  ...colKeysKeihi
];
//非表示列　月次確報
export const unVisibleColumnsKeyCommit = [
  "JJT", "JJTK", "JJP", "JJPK", "JJR", "JJD",
  "JKT", "JKTK", "JKP", "JKPK", "JKR", "JKD",
  "JRT", "JRP", "JRD",

  //欠品責任別
  "KI1KA", "KI1KR", "KI1KK",
  "KI2KA", "KI2KR", "KI2KK",
  "KI3KA", "KI3KR", "KI3KK",
  //欠品区分別
  "K10KA", "K10KR", "K10KK",
  "K11KA", "K11KR", "K11KK",
  "K12KA", "K12KR", "K12KK",
  "K13KA", "K13KR", "K13KK",
  "K14KA", "K14KR", "K14KK",
  "K15KA", "K15KR", "K15KK",
  "K16KA", "K16KR", "K16KK",
  "K17KA", "K17KR", "K17KK",
  "K19KA", "K19KR", "K19KK",
  "K20KA", "K20KR", "K20KK",
  "K21KA", "K21KR", "K21KK",
  "K22KA", "K22KR", "K22KK",
  "K29KA", "K29KR", "K29KK",
  "K30KA", "K30KR", "K30KK",
  "K31KA", "K31KR", "K31KK",
  "K32KA", "K32KR", "K32KK",
  "K33KA", "K33KR", "K33KK",
  "K41KA", "K41KR", "K41KK",
  "K34KA", "K34KR", "K34KK",
  "K39KA", "K39KR", "K39KK",
  "K40KA", "K40KR", "K40KK",
  "K42KA", "K42KR", "K42KK",
  "K43KA", "K43KR", "K43KK",
  "K44KA", "K44KR", "K44KK",
  "K49KA", "K49KR", "K49KK",
  "K50KA", "K50KR", "K50KK",
];
//非表示列　週次
export const unVisibleColumnsKeyWeekly = [
  ...colKeysKeihi,
  ...colKeysShiire,

  "STB", "STBK", "STBR", "STBD",
  "PTB", "PTBK", "PTBR", "PTBD",

  "PBLT", "PBLTK", "PBLP", "PBLPK", "PBLR", "PBLD",
  "PBDT", "PBDTK", "PBDP", "PBDPK", "PBDR", "PBDD",
  "PBRT", "PBRTK", "PBRP", "PBRPK", "PBRR", "PBRD",
  "PBTT", "PBTTK", "PBTP", "PBTPK", "PBTR", "PBTD",
  "PBHT", "PBHP", "PBHD",
  "YHLT", "YHLTK", "YHLP", "YHLPK", "YHLR", "YHLD",
  "YHDT", "YHDTK", "YHDP", "YHDPK", "YHDR", "YHDD",
  "YHRT", "YHRTK", "YHRP", "YHRPK", "YHRR", "YHRD",
  "YHTT", "YHTTK", "YHTP", "YHTPK", "YHTR", "YHTD",
  "YHHT", "YHHP", "YHHD",

  "NTB", "NTBK", "NTBR", "NTBD",

  "CIT", "CITK", "CIP", "CIPK", "CIR", "CID",
  "CST", "CSTK", "CSP", "CSPK", "CSR", "CSD",
  "CTT", "CTTK", "CTP", "CTPK", "CTR", "CTD", "CTB", "CTBK", "CTBR", "CTBD",

  "DDT", "DDTK", "DDP", "DDPK", "DDR", "DDD",
  "DBT", "DBTK", "DBP", "DBPK", "DBR", "DBD",
  "DQT", "DQTK", "DQP", "DQPK", "DQR", "DQD",
  "DFT", "DFTK", "DFP", "DFPK", "DFR", "DFD",
  "DTT", "DTTK", "DTP", "DTPK", "DTR", "DTD", "DTB", "DTBK", "DTBR", "DTBD",
  "DRT", "DRP", "DRD",

  "BTT", "BTTK", "BTP", "BTPK", "BTR", "BTD",
  "BRT", "BRP", "BRD",
  "ATT", "ATTK", "ATP", "ATPK", "ATR", "ATD",
  "ART", "ARP", "ARD",
  "GTT", "GTTK", "GTP", "GTPK", "GTR", "GTD", "GTB", "GTBK", "GTBR", "GTBD",
  "GRT", "GRP", "GRD",
  "ZTT", "ZTTK", "ZTP", "ZTPK", "ZTR", "ZTD", "ZTB", "ZTBK", "ZTBR", "ZTBD",
  "ZRT", "ZRP", "ZRD",

  "OCT", "OCTK", "OCP", "OCPK", "OCR", "OCD",
  "TST", "TSTK", "TSP", "TSPK", "TSR", "TSD",
  "CSTT", "CSTTK", "CSTP", "CSTPK", "CSTR", "CSTD", "CSTB", "CSTBR", "CSTBD",
  "OPT", "OPTK", "OPP", "OPPK", "OPR", "OPD",
  "TPT", "TPTK", "TPP", "TPPK", "TPR", "TPD",
  "PPTT", "PPTTK", "PPTP", "PPTPK", "PPTR", "PPTD", "PPTB", "PPTBR", "PPTBD",
  "OET", "OETK", "OEP", "OEPK", "OER", "OED",
  "TET", "TETK", "TEP", "TEPK", "TER", "TED",
  "ETT", "ETTK", "ETP", "ETPK", "ETR", "ETD", "ETB", "ETBR", "ETBD",
  "KTT", "KTTK", "KTP", "KTPK", "KTR", "KTD",
  "KRT", "KRP", "KRD",

  "ZCT", "ZCTK", "ZCP", "ZCPK", "ZCR", "ZCD",
  "ZCRT", "ZCRP", "ZCRD",

  "CCDT", "CCDTK", "CDDP", "CDDPK", "CDDR", "CDDD",
  "CDBT", "CDBTK", "CDBP", "CDBPK", "CDBR", "CDBD",
  "CDFT", "CDFTK", "CDFP", "CDFPK", "CDFR", "CDFD",
  "COCT", "COCTK", "COCP", "COCPK", "COCR", "COCD",
  "COPT", "COPTK", "COPP", "COPPK", "COPR", "COPD",
  "COET", "COETK", "COEP", "COEPK", "COER", "COED",
  "CTPT", "CTPTK", "CTPP", "CTPPK", "CTPR", "CTPD",
  "CTST", "CTSTK", "CTSP", "CTSPK", "CTSR", "CTSD",
  "CTET", "CTETK", "CTEP", "CTEPK", "CTER", "CTED",

  //欠品責任別
  "KI1KA", "KI1KR", "KI1KK",
  "KI2KA", "KI2KR", "KI2KK",
  "KI3KA", "KI3KR", "KI3KK",
  //欠品区分別
  "K10KA", "K10KR", "K10KK",
  "K11KA", "K11KR", "K11KK",
  "K12KA", "K12KR", "K12KK",
  "K13KA", "K13KR", "K13KK",
  "K14KA", "K14KR", "K14KK",
  "K15KA", "K15KR", "K15KK",
  "K16KA", "K16KR", "K16KK",
  "K17KA", "K17KR", "K17KK",
  "K19KA", "K19KR", "K19KK",
  "K20KA", "K20KR", "K20KK",
  "K21KA", "K21KR", "K21KK",
  "K22KA", "K22KR", "K22KK",
  "K29KA", "K29KR", "K29KK",
  "K30KA", "K30KR", "K30KK",
  "K31KA", "K31KR", "K31KK",
  "K32KA", "K32KR", "K32KK",
  "K33KA", "K33KR", "K33KK",
  "K41KA", "K41KR", "K41KK",
  "K34KA", "K34KR", "K34KK",
  "K39KA", "K39KR", "K39KK",
  "K40KA", "K40KR", "K40KK",
  "K42KA", "K42KR", "K42KK",
  "K43KA", "K43KR", "K43KK",
  "K44KA", "K44KR", "K44KK",
  "K49KA", "K49KR", "K49KK",
  "K50KA", "K50KR", "K50KK",
];
//受注関連項目
export const unVisibleColumnsKeyAdjus = [
  "JJT", "JJTK", "JJP", "JJPK", "JJR", "JJD",
  "JKT", "JKTK", "JKP", "JKPK", "JKR", "JKD",
  "JRT", "JRP", "JRD", 
  //欠品責任別
  "KI1KA", "KI1KR", "KI1KK",
  "KI2KA", "KI2KR", "KI2KK",
  "KI3KA", "KI3KR", "KI3KK",
  //欠品区分別
  "K10KA", "K10KR", "K10KK",
  "K11KA", "K11KR", "K11KK",
  "K12KA", "K12KR", "K12KK",
  "K13KA", "K13KR", "K13KK",
  "K14KA", "K14KR", "K14KK",
  "K15KA", "K15KR", "K15KK",
  "K16KA", "K16KR", "K16KK",
  "K17KA", "K17KR", "K17KK",
  "K19KA", "K19KR", "K19KK",
  "K20KA", "K20KR", "K20KK",
  "K21KA", "K21KR", "K21KK",
  "K22KA", "K22KR", "K22KK",
  "K29KA", "K29KR", "K29KK",
  "K30KA", "K30KR", "K30KK",
  "K31KA", "K31KR", "K31KK",
  "K32KA", "K32KR", "K32KK",
  "K33KA", "K33KR", "K33KK",
  "K41KA", "K41KR", "K41KK",
  "K34KA", "K34KR", "K34KK",
  "K39KA", "K39KR", "K39KK",
  "K40KA", "K40KR", "K40KK",
  "K42KA", "K42KR", "K42KK",
  "K43KA", "K43KR", "K43KK",
  "K44KA", "K44KR", "K44KK",
  "K49KA", "K49KR", "K49KK",
  "K50KA", "K50KR", "K50KK",
];
//仕入実績
export const unVisibleColumnsKeyShiire = [
  ...colKeysShiire
];
//デフォルト表示列1
export const defaultVisibleColumnsKey1 = [
  //以下に貼り付け
  "H1CD", "H1NM", "H2CD", "H2NM", "H3CD", "H3NM", "H4CD", "H4NM", "H5CD", "H5NM", "H6CD", "H6NM", "H7CD", "H7NM", "H8CD", "H8NM",
  "JJT", "JJR", "JJD",
  "JKT", 
  "JRT", "JRD", 
  "KI1KR", "KI2KR", "KI3KR",
  "STT", "STR", "STD",
  "STBR", "STBD",
  "PBHT",
  "YHHT",
  "PTT", "PTD",
  "PRT", "PRD",
  "DTT", "DTR", "DTD", "DRT", "DRD",
  "NTT", "NTR", "NTD", "NTBR", "NTBD",
  "CIT", "CIR", "CID", "CST", "CSR", "CSD", "CTT", "CTR", "CTD", "CTBR", "CTBD",
  "BTT", "BTD",
  "ATT", "ATR", "ATD", "ART", "ARD",
  "GTT", "GTR", "GTD", "GTB", "GTBR", "GTBD", "GRT", "GRD",
  "ZTT", "ZTR", "ZTD", "ZTB", "ZTBR", "ZTBD", "ZRT", "ZRD",
  "ZCT", "ZCR", "ZCD", "ZCRT", "ZCRD",
  "CNDT", "CNPT", "CNET", "CNRT", "CNRD",
  "SNET", "SNRT", "SNRD",
  "ORST", "ORSRT", "ORSRD", "ORPT", "ORPR", "ORPD", "ORPRT", "ORPRD",
  "SHT", "SHR", "SHD",
];
//デフォルト表示列2
export const defaultVisibleColumnsKey2 = [
  //以下に貼り付け
  "H1CD", "H1NM", "H2CD", "H2NM", "H3CD", "H3NM", "H4CD", "H4NM", "H5CD", "H5NM", "H6CD", "H6NM", "H7CD", "H7NM", "H8CD", "H8NM",
  "STT", "STR", "STD",
  "PBHT",
  "YHHT",
  "PTT", "PTD",
];
//デフォルト表示列3
export const defaultVisibleColumnsKey3 = [
  //以下に貼り付け
  "H1CD", "H1NM", "H2CD", "H2NM", "H3CD", "H3NM", "H4CD", "H4NM", "H5CD", "H5NM", "H6CD", "H6NM", "H7CD", "H7NM", "H8CD", "H8NM",
  "STT", "STR",
  "JKT", "JKR", "JRT", "JRD",
  "KI1KA", "KI1KK", "KI1KR", "KI3KA", "KI3KK", "KI3KR",
  "K10KA", "K10KK", "K11KA", "K11KK", "K12KA", "K12KK", "K14KA", "K14KK", "K15KA", "K15KK", "K16KA", "K16KK", "K17KA", "K17KK", "K19KA", "K19KK",
  "K20KA", "K20KK", "K21KA", "K21KK", "K22KA", "K22KK", "K29KA", "K29KK",
  "K30KA", "K30KK", "K31KA", "K31KK", "K34KA", "K34KK", "K40KA", "K40KK", "K41KA", "K41KK", "K42KA", "K42KK", "K43KA", "K43KK", "K50KA", "K50KK",
];
export const defaultVisibleColumnsKeyReport10 = [
  //以下に貼り付け
  "H1CD", "H1NM", "H2CD", "H2NM", "H3CD", "H3NM", "H4CD", "H4NM", "H5CD", "H5NM", "H6CD", "H6NM", "H7CD", "H7NM", "H8CD", "H8NM",
  "STT", "STP", "STR", "STD",
  "PBHT",
  "YHHT",
  "PTT", "PTP", "PTD", "PRT", "PRP", "PRD",
];
export const defaultVisibleColumnsKeyReport20 = [
  //以下に貼り付け
  "H1CD", "H1NM", "H2CD", "H2NM", "H3CD", "H3NM", "H4CD", "H4NM", "H5CD", "H5NM", "H6CD", "H6NM", "H7CD", "H7NM", "H8CD", "H8NM",
  "SLT", "SLR", "SLD", "SDT", "SDR", "SDD", "SRT", "SRR", "SRD", "STT", "STP", "STR", "STD", "STBR", "STBD",
  "PBHT", "PBHD",
  "YHHT", "YHHD",
  "PTT", "PTP", "PTR", "PTD", "PTBR", "PTBD", "PRT", "PRD",
  "NTT", "NTP", "NTR", "NTD", "NTBR", "NTBD",
  "CIT", "CIR", "CID", "CST", "CSR", "CSD", "CTT", "CTR", "CTD", "CTBR", "CTBD",
  "DDT", "DDR", "DDD", "DBT", "DBR", "DBD", "DQT", "DQP", "DQR", "DQD", "DFT", "DFR", "DFD", "DTT", "DTR", "DTD", "DTBR", "DTBD", "DRT", "DRD",
  "BTT", "BTR", "BTD", "BRT", "BRD",
  "ATT", "ATR", "ATD", "ART", "ARD",
  "GTT", "GTR", "GTD", "GTBR", "GTBD", "GRT", "GRD",
  "ZTT", "ZTR", "ZTD", "ZTBR", "ZTBD", "ZRT", "ZRD",
  "OCT", "OCR", "OCD",
  "TST", "TSR", "TSD",
  "CSTT", "CSTR", "CSTD", "CSTBR", "CSTBD",
  "OPT", "OPR", "OPD",
  "TPT", "TPR", "TPD",
  "PPTT", "PPTR", "PPTD", "PPTBR", "PPTBD",
  "OET", "OER", "OED",
  "TET", "TER", "TED",
  "ETT", "ETR", "ETD", "ETBR", "ETBD",
  "KTT", "KTR", "KTD", "KRT", "KRD",
  "ZCT", "ZCR", "ZCD", "ZCRT", "ZCRD",
  "CNDT", "CNDR", "CNDD", "CNDRT", "CNDRD", "CNPT", "CNPR", "CNPD", "CNPRT", "CNPRD", "CNGT", "CNGR", "CNGD", "CNGRT", "CNGRD", "CNAT", "CNAR", "CNAD", "CNET", "CNER", "CNED", "CNRT", "CNRD",
  "SNET", "SNER", "SNED", "SNRT", "SNRD",
  "ORST", "ORSR", "ORSD", "ORSRT", "ORSRD", "ORPT", "ORPR", "ORPD", "ORPRT", "ORPRD",
  "SHT", "SHR", "SHD",
];
export const defaultVisibleColumnsKeyReport21 = [
  //以下に貼り付け
  "H1CD", "H1NM", "H2CD", "H2NM", "H3CD", "H3NM", "H4CD", "H4NM", "H5CD", "H5NM", "H6CD", "H6NM", "H7CD", "H7NM", "H8CD", "H8NM",
  "JJT", "JJR", "JJD",
  "JKT", 
  "JRT", "JRD", 
  "KI1KR", "KI2KR", "KI3KR",
  "STT", "STR", "STD",
  "STBR", "STBD",
  "PBHT",
  "YHHT",
  "PTT", "PTD",
  "PRT", "PRD",
  "DTT", "DTR", "DTD", "DRT", "DRD",
  "NTT", "NTR", "NTD", "NTBR", "NTBD",
  "CIT", "CIR", "CID", "CST", "CSR", "CSD", "CTT", "CTR", "CTD", "CTBR", "CTBD",
  "BTT", "BTD",
  "ATT", "ATR", "ATD", "ART", "ARD",
  "GTT", "GTR", "GTD", "GTB", "GTBR", "GTBD", "GRT", "GRD",
  "ZTT", "ZTR", "ZTD", "ZTB", "ZTBR", "ZTBD", "ZRT", "ZRD",
  "ZCT", "ZCR", "ZCD", "ZCRT", "ZCRD",
  "CNDT", "CNPT", "CNET", "CNRT", "CNRD",
  "SNET", "SNRT", "SNRD",
  "ORST", "ORSRT", "ORSRD", "ORPT", "ORPR", "ORPD", "ORPRT", "ORPRD",
  "SHT", "SHR", "SHD",
];
export const defaultVisibleColumnsKeyReport30 = defaultVisibleColumnsKey1;
export const defaultVisibleColumnsKeyReport40 = [
  //以下に貼り付け
  "H1CD", "H1NM", "H2CD", "H2NM", "H3CD", "H3NM", "H4CD", "H4NM", "H5CD", "H5NM", "H6CD", "H6NM", "H7CD", "H7NM", "H8CD", "H8NM",
  "SLT", "SLR", "SRT", "STT", "STR", "STD",
  "CNDT", "CNDR", "CNDD", "CNDRT", "CNDRD", "CNPT", "CNPR", "CNPD", "CNPRT", "CNPRD", "CNGT", "CNGR", "CNGD", "CNGRT", "CNGRD", "CNET", "CNER", "CNED", "CNRT", "CNRD",
  "SNET", "SNER", "SNRT", "SNRD",
  "ORST", "ORSR", "ORSD", "ORSRT", "ORSRD",
  "ORPT", "ORPR", "ORPD", "ORPRT", "ORPRD",
  "SHT", "SHR", "SHD",
];

//Page State
export type SalesListSaveState = {
  //年月
  ym: YM | null,
  //年月
  yw: YW | null,
  //集計軸
  axisType: "group2" | "shiten" | "center" | "shiire" | "maker" | "category" | null,
  //データ種別
  dataType: "quick" | "commit" | "weekly" | null,
  //検索対象
  containSEJ: boolean,

  group2: CodeName[] | null,
  shiten: CodeName[] | null,
  center: CodeName[] | null,
  shiire: CodeName[] | null,
  maker: CodeName[] | null,
  category: CodeName[] | null,

  //検索終了した条件
  requestedParam : RequestParam,
  //自動検索
  autoRetrieve: boolean,

  sort: {key:string, asc:boolean},
  hiddenColumnsKey: string[],
  visibleColumnsKey: string[],
  hiddenColumnsKey_setting: string[],  //表示設定

  grouping1: boolean,
  grouping2: boolean,

};

export const initialSalesListState: SalesListSaveState = {
  //年月
  ym: null,
  //年週
  yw: null,
  //集計軸
  axisType: "group2",
  //データ種別
  dataType: "quick",
  //検索対象
  containSEJ: false,

  group2: [],
  shiten: [],
  center: [],
  shiire: [],
  maker: [],
  category: [],

  requestedParam : {},
  //自動検索
  autoRetrieve: true,

  sort: {
    key: "STT", //総売上本年実績
    asc: false, //降順
  },
  hiddenColumnsKey: [],
  visibleColumnsKey: defaultVisibleColumnsKey1,
  hiddenColumnsKey_setting: [],

  grouping1: false,
  grouping2: false,

};


export const initialSalesListReport10State: SalesListSaveState = {
  //年月
  ym: null,
  //年週
  yw: null,
  //集計軸
  axisType: "group2",
  //データ種別
  dataType: "quick",
  //検索対象
  containSEJ: false,

  group2: [],
  shiten: [],
  center: [],
  shiire: [],
  maker: [],
  category: [],

  requestedParam : {},
  //自動検索
  autoRetrieve: true,

  sort: {
    key: "STT", //総売上本年実績
    asc: false, //降順
  },
  hiddenColumnsKey: [],
  visibleColumnsKey: defaultVisibleColumnsKeyReport10,  //0配列は全項目を表示とする
  hiddenColumnsKey_setting: [],

  grouping1: false,
  grouping2: false,
};

export const initialSalesListReport20State: SalesListSaveState = {
  //年月
  ym: null,
  //年週
  yw: null,
  //集計軸
  axisType: "group2",
  //データ種別
  dataType: "quick",
  //検索対象
  containSEJ: false,

  group2: [],
  shiten: [],
  center: [],
  shiire: [],
  maker: [],
  category: [],

  requestedParam: {},
  //自動検索
  autoRetrieve: true,

  sort: {
    key: "STT", //総売上本年実績
    asc: false, //降順
  },
  hiddenColumnsKey: [],
  visibleColumnsKey: defaultVisibleColumnsKeyReport20,  //0配列は全項目を表示とする
  hiddenColumnsKey_setting: [],

  grouping1: false,
  grouping2: false,
};

export const initialSalesListReport21State: SalesListSaveState = {
  //年月
  ym: null,
  //年週
  yw: null,
  //集計軸
  axisType: "group2",
  //データ種別
  dataType: "weekly",
  //検索対象
  containSEJ: false,

  group2: [],
  shiten: [],
  center: [],
  shiire: [],
  maker: [],
  category: [],

  requestedParam: {},
  //自動検索
  autoRetrieve: true,

  sort: {
    key: "STT", //総売上本年実績
    asc: false, //降順
  },
  hiddenColumnsKey: [],
  visibleColumnsKey: defaultVisibleColumnsKeyReport21,  //0配列は全項目を表示とする
  hiddenColumnsKey_setting: [],

  grouping1: false,
  grouping2: false,
};

export const initialSalesListReport30State: SalesListSaveState = {
  //年月
  ym: null,
  //年週
  yw: null,
  //集計軸
  axisType: "group2",
  //データ種別
  dataType: "commit",
  //検索対象
  containSEJ: false,

  group2: [],
  shiten: [],
  center: [],
  shiire: [],
  maker: [],
  category: [],

  requestedParam : {},
  //自動検索
  autoRetrieve: true,

  sort: {
    key: "STT", //総売上本年実績
    asc: false, //降順
  },
  hiddenColumnsKey: [],
  visibleColumnsKey: defaultVisibleColumnsKeyReport30,
  hiddenColumnsKey_setting: [],

  grouping1: false,
  grouping2: false,
};

export const initialSalesListReport40State: SalesListSaveState = {
  //年月
  ym: null,
  //年週
  yw: null,
  //集計軸
  axisType: "center",
  //データ種別
  dataType: "commit",
  //検索対象
  containSEJ: false,

  group2: [],
  shiten: [],
  center: [],
  shiire: [],
  maker: [],
  category: [],

  requestedParam : {},
  //自動検索
  autoRetrieve: true,

  sort: {
    key: "STT", //総売上本年実績
    asc: false, //降順
  },
  hiddenColumnsKey: [],
  visibleColumnsKey: defaultVisibleColumnsKeyReport40,
  hiddenColumnsKey_setting: [],

  grouping1: false,
  grouping2: false,
};

//Page Slice
export type SalesListSaveReducer = {
  initOnDidMount: (state:SalesListSaveState, action: PayloadAction<SalesListSaveState>) => void,
  setYM: (state:SalesListSaveState, action: PayloadAction<YM>) => void,
  setYMList: (state:SalesListSaveState, action: PayloadAction<YM[]>) => void,
  setYW: (state:SalesListSaveState, action: PayloadAction<YW>) => void,
  setYWList: (state:SalesListSaveState, action: PayloadAction<YW[]>) => void,
  setAxisType: (state:SalesListSaveState, action: PayloadAction<string>) => void,
  setDataType: (state:SalesListSaveState, action: PayloadAction<string>) => void,
  setContainSEJ: (state:SalesListSaveState, action: PayloadAction<boolean>) => void,
  setGroup2: (state: SalesListSaveState, action: PayloadAction<CodeName[]>) => void,
  setShiten: (state: SalesListSaveState, action: PayloadAction<CodeName[]>) => void,
  setCenter: (state: SalesListSaveState, action: PayloadAction<CodeName[]>) => void,
  setShiire: (state: SalesListSaveState, action: PayloadAction<CodeName[]>) => void,
  setMaker: (state: SalesListSaveState, action: PayloadAction<CodeName[]>) => void,
  setCategory: (state: SalesListSaveState, action: PayloadAction<CodeName[]>) => void,
  clearOption: (state:SalesListSaveState, action: PayloadAction<{ymList:YM[],ywList:YW[]}>) => void,
  setRequestedParam: (state:SalesListSaveState, action: PayloadAction<RequestParam>) => void,
  setAutoRetrieve: (state:SalesListSaveState, action: PayloadAction<boolean>) => void,
  setSort: (state:SalesListSaveState, action: PayloadAction<{sortKey:string, sortAsc: boolean}>) => void,
  // hideColumnsChange: (state:SalesListSaveState, action: PayloadAction<{columns:number[], keys:string[], dataType: "daily" | "quick" | "commit" | "weekly"}>) => void,
  hideColumnKeysChange: (state:SalesListSaveState, action: PayloadAction<{keys:string[], dataType: "daily" | "quick" | "commit" | "weekly"}>) => void,
  hideColumnKeysChangeSetting: (state:SalesListSaveState, action: PayloadAction<{keys:string[], dataType: "daily" | "quick" | "commit" | "weekly"}>) => void,
  setGrouping1: (state: SalesListSaveState, action: PayloadAction<boolean>) => void,
  setGrouping2: (state: SalesListSaveState, action: PayloadAction<boolean>) => void,
}

const createReducerContent = (name: string, colModel: SalesListColModel, initialState: SalesListSaveState, defaultYMOnInit: "" | "thisMonth" | "prevMonth", defaultYWOnInit: "" | "thisWeek" | "prevWeek", defaultAxisType: "" | "init"): SalesListSaveReducer => { return {
  //componentDidMount
  initOnDidMount(state:SalesListSaveState) {
    //追加属性等はlocalstorageから復元したときにundefinedになっている場合があるためチェックする
    if(state.requestedParam === undefined) {
      state.requestedParam = initialState.requestedParam;
    }
    if(state.autoRetrieve === undefined) {
      state.autoRetrieve = initialState.autoRetrieve;
    }

    //最新の年月を選択した状態にする
    if(defaultYMOnInit) {
      const now = moment(new Date());
      const thisMonth = moment().set({'year': now.get('year'), 'month': now.get('month'), 'date': 1});
      let first = true;

      //最新月を表示しない場合は、1か月ずらす
      if (defaultYMOnInit == "prevMonth") {
        thisMonth.add(-1, 'month');
        first = false;
      }

      state.ym = new YM({ code: thisMonth.format('YYYYMM'), name: thisMonth.format('YYYY年MM月'), thisMonth: first });
      if (state.requestedParam.ym) {
        state.requestedParam.ym = state.ym.code;
      }
    }
    else {
      if(!state.ym) {
        state.ym = initialState.ym;
      }
    }
    //最新の年週を選択した状態にする
    if(defaultYWOnInit) {
      const now = moment(new Date());
      const thisMonth = moment(now);
      let first = true;

      //最新月を表示しない場合は、1か月ずらす
      if (defaultYWOnInit == "prevWeek") {
        thisMonth.add(-1, 'week');
        first = false;
      }
      else if ([1, 2].indexOf(thisMonth.weekday()) !== -1) { //0:日, 1:月, 2:火
        thisMonth.add(-1, 'week');
        first = false;
      }

      state.yw = new YW({ code: thisMonth.format('GGWW'), name: thisMonth.format('GGGG年WW月'), thisWeek: first });
      if (state.requestedParam.yw) {
        state.requestedParam.yw = state.yw.code;
      }
    }
    else {
      if(!state.yw) {
        state.yw = initialState.yw;
      }
    }

    if (!state.axisType || defaultAxisType) {
      state.axisType = initialState.axisType;
    }
    if(!state.dataType) {
      state.dataType = initialState.dataType;
    }
    if(!state.sort || !state.sort.key) {
      state.sort = initialState.sort;
    }
    if (!state.visibleColumnsKey || state.visibleColumnsKey.length == 0) {
      state.visibleColumnsKey = (initialState.visibleColumnsKey.length == 0) ? colModel.colKeys : initialState.visibleColumnsKey;
    }
    //強制選択列
    state.visibleColumnsKey = arrayUtil.union(arrayUtil.and(colModel.colKeys, mustVisibleColumnsKey), state.visibleColumnsKey);
    //非表示列
    state.hiddenColumnsKey = arrayUtil.not(colModel.colKeys, state.visibleColumnsKey);

    if (!state.hiddenColumnsKey_setting || state.hiddenColumnsKey_setting.length == 0) {
      state.hiddenColumnsKey_setting = state.hiddenColumnsKey;
    }
    if (!state.group2) {
      state.group2 = initialState.group2;
    } else if (!Array.isArray(state.group2)) {
      state.group2 = [state.group2];
    }
    if (!state.shiten) {
      state.shiten = initialState.shiten;
    } else if (!Array.isArray(state.shiten)) {
      state.shiten = [state.shiten];
    }
    if (!state.center) {
      state.center = initialState.center;
    } else if (!Array.isArray(state.center)) {
      state.center = [state.center];
    }
    if (!state.shiire) {
      state.shiire = initialState.shiire;
    } else if (!Array.isArray(state.shiire)) {
      state.shiire = [state.shiire];
    }
    if (!state.maker) {
      state.maker = initialState.maker;
    } else if (!Array.isArray(state.maker)) {
      state.maker = [state.maker];
    }
    if (!state.category) {
      state.category = initialState.category;
    } else if (!Array.isArray(state.category)) {
      state.category = [state.category];
    }
    if (state.grouping1 === undefined) {
      state.grouping1 = initialState.grouping1;
    }
    if (state.grouping2 === undefined) {
      state.grouping2 = initialState.grouping2;
    }
  },

  // Option YM
  setYM(state:SalesListSaveState, action: PayloadAction<YM>) {
    state.ym = action.payload;
  },
  setYMList(state:SalesListSaveState, action: PayloadAction<YM[]>) {
    const ymList = action.payload;
    let ym = state.ym && state.ym.code? ymList.find((ym) => ym.code == state.ym.code) : null;
    if(!ym) {
      ym = ymList[0];
    }
    state.ym = ym;
  },
  // Option YW
  setYW(state:SalesListSaveState, action: PayloadAction<YW>) {
    state.yw = action.payload;
  },
  setYWList(state:SalesListSaveState, action: PayloadAction<YW[]>) {
    const ywList = action.payload;
    let yw = state.yw && state.yw.code? ywList.find((yw) => yw.code == state.yw.code) : null;
    if(!yw) {
      yw = ywList[0];
    }
    state.yw = yw;
  },

  //集計軸
  setAxisType(state:SalesListSaveState, action: PayloadAction<string>) {

    switch(action.payload) {
      case "group2":
        state.axisType = "group2";
        break;
      case "shiten":
        state.axisType = "shiten";
        break;
      case "center":
        state.axisType = "center";
        break;
      case "shiire":
        state.axisType = "shiire";
        break;
      case "maker":
        state.axisType = "maker";
        break;
      case "category":
        state.axisType = "category";
        break;
      default:
        state.axisType = "group2";
        break;
    }
  },
  //データ種別
  setDataType(state:SalesListSaveState, action: PayloadAction<string>) {
    switch(action.payload) {
      case "quick":
        state.dataType = "quick";
        break;
      case "commit":
        state.dataType = "commit";
        break;
      case "weekly":
        state.dataType = "weekly";
        break;
      default:
        state.dataType = "quick";
        break;
    }
  },
  //検索対象
  setContainSEJ(state:SalesListSaveState, action: PayloadAction<boolean>) {
    state.containSEJ = action.payload;
  },
  setGroup2(state: SalesListSaveState, action: PayloadAction<CodeName[]>) {
    state.group2 = action.payload;
  },
  setShiten(state: SalesListSaveState, action: PayloadAction<CodeName[]>) {
    state.shiten = action.payload;
  },
  setCenter(state: SalesListSaveState, action: PayloadAction<CodeName[]>) {
    state.center = action.payload;
  },
  setShiire(state: SalesListSaveState, action: PayloadAction<CodeName[]>) {
    state.shiire = action.payload;
  },
  setMaker(state: SalesListSaveState, action: PayloadAction<CodeName[]>) {
    state.maker = action.payload;
  },
  setCategory(state: SalesListSaveState, action: PayloadAction<CodeName[]>) {
    state.category = action.payload;
  },

  clearOption(state:SalesListSaveState, action: PayloadAction<{
    ymList:YM[],
    ywList:YW[],
  }>) {
    Object.assign(state, {
      ym: action.payload.ymList[0],
      yw: action.payload.ywList[0],
      axisType: initialState.axisType,
      dataType: initialState.dataType,
      containSEJ: initialState.containSEJ,
      group2: initialState.group2,
      shiten: initialState.shiten,
      center: initialState.center,
      shiire: initialState.shiire,
      maker: initialState.maker,
      category: initialState.category,
    });
  },

  //検索条件
  setRequestedParam(state:SalesListSaveState, action: PayloadAction<RequestParam>) {
    state.requestedParam = action.payload;
  },
  setAutoRetrieve(state:SalesListSaveState, action: PayloadAction<boolean>) {
    state.autoRetrieve = action.payload;
  },

  setSort(state:SalesListSaveState, action: PayloadAction<{sortKey:string, sortAsc: boolean}>) {
    const key = action.payload.sortKey;
    const asc = action.payload.sortAsc;
    state.sort = {
      key: key,
      asc: asc,
    }
  },
  // hideColumnsChange(state:SalesListSaveState, action: PayloadAction<{columns:number[], keys:string[], dataType: "daily" | "quick" | "commit" | "weekly"}>){
  //   console.log('store.hideColumnsChange');
  //   const dataType = action.payload.dataType;
  //   Object.assign(state, {
  //     // hiddenColumns: action.payload.columns,
  //     hiddenColumnsKey: action.payload.keys,
  //     visibleColumnsKey: arrayUtil.not(colModel.colKeys, action.payload.keys),
  //   });
  // },
  hideColumnKeysChange(state:SalesListSaveState, action: PayloadAction<{keys:string[], dataType: "daily" | "quick" | "commit" | "weekly"}>){
    console.log('store.hideColumnKeysChange');
    const dataType = action.payload.dataType;
    const colKeys = colModel.colKeys;
    const hiddenColumnsKey = action.payload.keys;

    Object.assign(state, {
      // hiddenColumns: colModel.colFromKeys(hiddenColumnsKey, dataType),
      hiddenColumnsKey: hiddenColumnsKey,
      visibleColumnsKey: arrayUtil.not(colKeys, hiddenColumnsKey),
    });
  },
  hideColumnKeysChangeSetting(state:SalesListSaveState, action: PayloadAction<{keys:string[], dataType: "daily" | "quick" | "commit" | "weekly"}>){
    console.log('store.hideColumnKeysChangeSetting');
    const dataType = action.payload.dataType;
    const colKeys = colModel.colKeys;
    const hiddenColumnsKey = action.payload.keys;

    Object.assign(state, {
      hiddenColumnsKey_setting: hiddenColumnsKey,
    });
  },
  setGrouping1(state: SalesListSaveState, action: PayloadAction<boolean>) {
    state.grouping1 = action.payload;
  },
  setGrouping2(state: SalesListSaveState, action: PayloadAction<boolean>) {
    state.grouping2 = action.payload;
  },
}};

const createSliceContent = (name: string, colModel: SalesListColModel, initialState: SalesListSaveState, defaultYMOnInit: "" | "thisMonth" | "prevMonth", defaultYWOnInit: "" | "thisWeek" | "prevWeek", defaultAxisType: "" | "init") => createSlice({
  name: name,
  initialState: initialState,
  reducers: createReducerContent(name, colModel, initialState, defaultYMOnInit, defaultYWOnInit, defaultAxisType),
});

//集計軸
export const axisName = (axis: "group1" | "group2" | "shiten" | "center" | "shiire" | "maker" | "tokuisaki" | "category" | string) => {
  switch(axis) {
    case "group1":
      return "企業グループ1";
    case "group2":
      return "企業グループ2";
    case "shiten":
      return "支店";
    case "center":
      return "倉庫";
    case "shiire":
      return "仕入先";
    case "maker":
      return "メーカー";
    case "tokuisaki":
      return "得意先";
    case "category":
      return "商品カテゴリ";
    default:
      return "-";
  }
}

//データ種別
export const dataTypeName = (dataType: "quick" | "commit" | "weekly" | string) => {
  switch(dataType) {
    case "quick":
      return "月次速報";
    case "commit":
      return "月次確報";
    case "weekly":
      return "週次";
    default:
      return "-";
  }
}
//検索対象
export const containSEJName = (containSEJ:boolean) => {
  if(containSEJ) {
    return "SEJを含む";
  }
  else {
    return "一般";
  }
}

//Page Slice Export
//salesListSaveSlice
export const salesListSaveSlice = createSliceContent("salesListSave", salesListColModelDefault, initialSalesListState, "", "", "");
export const salesListReport10SaveSlice = createSliceContent("salesListReport10Save", salesListColModelReport10, initialSalesListReport10State, "thisMonth", "", "");
export const salesListReport20SaveSlice = createSliceContent("salesListReport20Save", salesListColModelReport20, initialSalesListReport20State, "prevMonth", "", "");
export const salesListReport21SaveSlice = createSliceContent("salesListReport21Save", salesListColModelReport21, initialSalesListReport21State, "", "thisWeek", "");
export const salesListReport30SaveSlice = createSliceContent("salesListReport30Save", salesListColModelReport30, initialSalesListReport30State, "prevMonth", "", "");
export const salesListReport40SaveSlice = createSliceContent("salesListReport40Save", salesListColModelReport40, initialSalesListReport40State, "", "", "init");
