import { createSlice, PayloadAction } from "@reduxjs/toolkit";

//Page State
export type XraySaveState = {
};

export const initialXrayState: XraySaveState = {
};

//Page Slice
export type XraySaveReducer = {
  initOnDidMount: (state: XraySaveState) => void,
}

const createReducerContent = (name: string, initialState: XraySaveState): XraySaveReducer => {
  return {
    //componentDidMount
    initOnDidMount(state: XraySaveState) {
    },
  }
};

const createSliceContent = (name: string, initialState: XraySaveState) => createSlice({
  name: name,
  initialState: initialState,
  reducers: createReducerContent(name, initialState),
});

//Page Slice Export
//xraySaveSlice
export const xraySaveSlice = createSliceContent("xraySave", initialXrayState);
