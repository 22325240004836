import moment from 'moment';

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RequestParam } from "@/assets/apitype/outputDataListZaiko";
import { CodeName } from "@/store/common";

type ZaikoKbn = "1" | "2" | "3" | "9" | "90";
export const ZaikoKbns: ZaikoKbn[] = ["1", "2", "3", "9", "90"];
type ZaikoKbnAll = ZaikoKbn | "99";

//Page State
export type OutputDataZaikoSaveState = {
  // 基準日
  ymd: Date | null,
  //部門
  bu: CodeName | null,
  //倉庫
  center: CodeName | null,

  // 一般(1)、特売(2)、予約(3)、出切(9)、返品(90)、全て(99)
  zaikoKbns: ZaikoKbnAll[] | null,
  order: "address" | "itemcd" | null,

  //検索終了した条件
  requestedParamZaiko: RequestParam,

};

export const initialState: OutputDataZaikoSaveState = {
  // 基準日
  ymd: null,
  //部門
  bu: null,
  //倉庫
  center: null,
  zaikoKbns: ["99"] ,//全て
  order: "address" ,

  requestedParamZaiko: null,

};

//Page Slice
const createSliceContent = (name: string) => createSlice({
  name: name,
  initialState,
  reducers: {
    //componentDidMount
    initOnDidMount(state:OutputDataZaikoSaveState) {
      //追加属性等はlocalstorageから復元したときにundefinedになっている場合があるためチェックする

      const LastMonth = moment().startOf('day');
      state.ymd = LastMonth.toDate();

      if (!state.bu) {
        state.bu = initialState.bu;
      }
      if (!state.center) {
        state.center = initialState.center;
      }
      if (!state.zaikoKbns) {
        state.zaikoKbns = initialState.zaikoKbns;
      }

      if (state.requestedParamZaiko === undefined) {
        state.requestedParamZaiko = initialState.requestedParamZaiko;
      }
    },

    setYM(state:OutputDataZaikoSaveState, action: PayloadAction<Date>) {
      state.ymd = action.payload;
    },
    // Option Bu
    setBu(state:OutputDataZaikoSaveState, action: PayloadAction<CodeName>) {
      state.bu = action.payload;
    },
    setBuList(state:OutputDataZaikoSaveState, action: PayloadAction<CodeName[]>) {
      const buList = action.payload;
      let bu = state.bu && state.bu.code ? buList.find((bu) => bu.code == state.bu.code) : null;
      if (!bu) {
        bu = buList[0];
      }
      state.bu = bu;
    },
    // Option Center
    setCenter(state:OutputDataZaikoSaveState, action: PayloadAction<CodeName>) {
      state.center = action.payload;
    },
    setCenterList(state:OutputDataZaikoSaveState, action: PayloadAction<CodeName[]>) {
      const centerList = action.payload;
      let center = state.center && state.center.code ? centerList.find((center) => center.code == state.center.code) : null;
      if (!center) {
        center = centerList[0];
      }
      state.center = center;
    },
    //
    setZaikoKbns(state:OutputDataZaikoSaveState, action: PayloadAction<string[]>) {
      state.zaikoKbns = action.payload as ZaikoKbnAll[];
    },
    //
    setOrder(state:OutputDataZaikoSaveState, action: PayloadAction<string>) {
      switch (action.payload) {
        case "address":
          state.order = "address";
          break;
        case "itemcd":
          state.order = "itemcd";
          break;
        default:
          state.order = "address";
          break;
      }
    },
    clearOption(state:OutputDataZaikoSaveState, action: PayloadAction<{
      buList: CodeName[],
      centerList: CodeName[],
    }>) {
      const LastMonth = moment().startOf('day');
      state.ymd = LastMonth.toDate();
      state.bu = action.payload.buList[0];
      state.center = action.payload.centerList[0];
      state.zaikoKbns = ["99"];
      state.order = "address";
    },

    //検索条件
    setRequestedParamZaiko(state:OutputDataZaikoSaveState, action: PayloadAction<RequestParam>) {
      state.requestedParamZaiko = action.payload;
    },
  },
});

//Page Slice Export
//outputDataZaikoSave
export const outputDataZaikoSaveSlice = createSliceContent("outputDataZaikoSave");
