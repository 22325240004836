import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {RowData, parseData, calcData, calcTotalSub, calcTotalSubPS} from "@/store/itemlist/itemListTmp"; //itemlistのRowDataを流用する

import { ItemListTransColRowModel, headerColKeys, headersRowMonthly, headersRowDaily, termColKeys, drillDownMaxLv } from "@/components/itemlisttrans/ItemListTransTableModel";
import { RequestParam } from "@/assets/apitype/itemList";
import { YM, CodeName } from "@/store/common";

import * as calcUtil from "@/util/calcUtil";
import * as compareUtil from "@/util/compareUtil";
import * as arrayUtil from "@/util/arrayUtil";
import moment from "moment";
import { dataTypeIsMonthly } from "./itemListTransSave";
moment.updateLocale("ja", {
  months: ["1月","2月","3月","4月","5月","6月","7月","8月","9月","10月","11月","12月",],
  weekdays: ["日曜日", "月曜日", "火曜日", "水曜日", "木曜日", "金曜日", "土曜日"],
  weekdaysShort: ["日", "月", "火", "水", "木", "金", "土"]
});

//強制表示行
export const mustVisibleRowsKeyMonthly = [
];
export const mustVisibleRowsKeyDaily = [
];
//受注関連項目
export const unVisibleRowsKeyAdjus = [
  "JJQT", "JJQP", "JJQR", "JJQD",
  "JJT", "JJP", "JJR", "JJD",

  "JKQT", "JKQP", "JKQR", "JKQD",
  "JKT", "JKP", "JKR", "JKD",
  "JRT", "JRP", "JRD", 
  //欠品責任別
  "KI1KQ", "KI1KA", "KI1KR",
  "KI2KQ", "KI2KA", "KI2KR",
  "KI3KQ", "KI3KA", "KI3KR",

  //欠品区分別
  "K10KQ", "K10KA", "K10KR",
  "K11KQ", "K11KA", "K11KR",
  "K12KQ", "K12KA", "K12KR",
  // "K13KQ", "K13KA", "K13KR",
  "K14KQ", "K14KA", "K14KR",
  "K15KQ", "K15KA", "K15KR",
  "K16KQ", "K16KA", "K16KR",
  "K17KQ", "K17KA", "K17KR",
  "K19KQ", "K19KA", "K19KR",

  "K20KQ", "K20KA", "K20KR",
  "K21KQ", "K21KA", "K21KR",
  "K22KQ", "K22KA", "K22KR",
  "K29KQ", "K29KA", "K29KR",

  "K30KQ", "K30KA", "K30KR",
  "K31KQ", "K31KA", "K31KR",
  // "K32KQ", "K32KA", "K32KR",
  // "K33KQ", "K33KA", "K33KR",
  "K34KQ", "K34KA", "K34KR",
  // "K39KQ", "K39KA", "K39KR",
  "K40KQ", "K40KA", "K40KR",
  "K41KQ", "K41KA", "K41KR",
  "K42KQ", "K42KA", "K42KR",
  "K43KQ", "K43KA", "K43KR",
  // "K44KQ", "K44KA", "K44KR",
  // "K49KQ", "K49KA", "K49KR",
  "K50KQ", "K50KA", "K50KR",
];


export type RetrievedId = {uuid: string, lv:number};

export type findedCell = {
  row: number,
  col: number,
  data: any,
  text: string,
}

export type RowInfo = {
  dataGroup:RowDataGroup,
  rowKey:string,

  rowIndex:number,  //同一集計行内でのrowKeyのindex
  rowIndexLast:boolean, //同一集計行内での最後のrowKeyフラグ

  rowHeaderIndex1:number,  //同一集計行内での同一rowHeader1のindex
  rowHeaderIndex2:number,  //同一集計行内での同一rowHeader1&2のindex
  rowHeaderIndex1Last:boolean,
  rowHeaderIndex2Last:boolean,

}

export type YMData = {
  YMID?: number | null, //年月
  data:RowData,
}

export type RowDataGroup = {
  TP: "total" | "teiban" | "teibangai" | "grouping1" | "grouping2" | "grouping3" | "grouping4" | "grouping5" | "item",
  // drillDowned: boolean,
  no?: string,
  CAT: "def" | "sej" | "mix", //一般/SEJ/混在
  IID?: string | null,
  //以下に貼り付け
  ISC?: string | null, ISN?: string | null, IMC?: string | null, IMN?: string | null, INM?: string | null, ICC?: string | null, ICN?: string | null, IPC?: string | null, IPN?: string | null, IJN?: string | null, ICD?: string | null, ICP?: string | null, IC1?: number | null, IC2?: number | null, ILI?: number | null, IPR?: number | null, 
  IBC?: string | null,
  IBN?: string | null,

  ymDatas:YMData[],
  dataTTL:RowData,
}

//Page State
export type ItemListTransTmpState = {
  //年月
  ymList: YM[],
  group2List: CodeName[],
  group1List: CodeName[],
  shitenList: CodeName[],
  centerList: CodeName[],
  makerList: CodeName[],
  tokuisakiList: CodeName[],
  pbList: CodeName[],
  tagList: CodeName[],
  categoryList: CodeName[],

  accordionOpen: boolean,
  //検索する条件
  requestParam : RequestParam,
  requestParamQueue : RequestParam[],
  //検索終了した条件
  retrievedIDs: RetrievedId[],
  //共有URL
  shareURL: string,

  progress: Record<string, unknown>,
  dataYMs: YM[],
  dataGroups: RowDataGroup[],
  rowInfos: RowInfo[],
  rows: any[][],
  mergeCells: {row: number, col: number, rowspan: number, colspan: number}[],
  selectionRowStart: number,
  selectionRowEnd: number,
  selectionRowInfo:RowInfo,

  findKeyword:string,
  findedCellSelect: findedCell,
  findedCells: findedCell[],
  findedCellsOver: boolean,

  isIME: boolean,
  initList: string[] | null,

  lastUpdate: string | null,
  adjus0Imported: boolean | null,
  errorMessage: string | null,
};

export const initialState: ItemListTransTmpState = {
  //年月
  ymList: [],
  group2List: [],
  group1List: [],
  shitenList: [],
  centerList: [],
  makerList: [],
  tokuisakiList: [],
  pbList: [],
  tagList: [],
  categoryList: [],

  accordionOpen: true,
  requestParam : {},
  requestParamQueue: [],
  retrievedIDs: [],
  //共有URL
  shareURL: '',

  progress: {},
  dataYMs: [],
  dataGroups: [],
  rowInfos: [],
  rows: [],
  mergeCells: null,
  selectionRowStart: -1,
  selectionRowEnd: -1,
  selectionRowInfo:null,

  findKeyword:'',
  findedCellSelect: null,
  findedCells: [],
  findedCellsOver: false,

  isIME: false,
  initList: ["group2", "group1", "shiten", "center", "maker", "tokuisaki", "pb", "tag", "category"],

  lastUpdate:  null,
  adjus0Imported: false,
  errorMessage: null,
};

//Page Slice
export type ItemListTransTmpReducer = {
  setYMList: (state:ItemListTransTmpState, action: PayloadAction<YM[]>) => void,
  setGroup2List: (state:ItemListTransTmpState, action: PayloadAction<CodeName[]>) => void,
  setGroup1List: (state:ItemListTransTmpState, action: PayloadAction<CodeName[]>) => void,
  setShitenList: (state:ItemListTransTmpState, action: PayloadAction<CodeName[]>) => void,
  setCenterList: (state:ItemListTransTmpState, action: PayloadAction<CodeName[]>) => void,
  setMakerList: (state:ItemListTransTmpState, action: PayloadAction<CodeName[]>) => void,
  setTokuisakiList: (state:ItemListTransTmpState, action: PayloadAction<CodeName[]>) => void,
  setPbList: (state:ItemListTransTmpState, action: PayloadAction<CodeName[]>) => void,
  setTagList: (state:ItemListTransTmpState, action: PayloadAction<CodeName[]>) => void,
  setCategoryList: (state:ItemListTransTmpState, action: PayloadAction<CodeName[]>) => void,
  setAccordionOpen: (state:ItemListTransTmpState, action: PayloadAction<boolean>) => void,
  setRequestParam: (state:ItemListTransTmpState, action: PayloadAction<RequestParam>) => void,
  addRequestParamQueue: (state:ItemListTransTmpState, action: PayloadAction<RequestParam[]>) => void,
  setRequestParamQueue: (state:ItemListTransTmpState, action: PayloadAction<RequestParam[]>) => void,
  clearRequestParamQueue: (state:ItemListTransTmpState, action: PayloadAction<RequestParam[]>) => void,
  setRetrievedID: (state:ItemListTransTmpState, action: PayloadAction<RetrievedId>) => void,
  addRetrievedID: (state:ItemListTransTmpState, action: PayloadAction<RetrievedId>) => void,
  removeRetrievedIDsOfLv: (state:ItemListTransTmpState, action: PayloadAction<number>) => void,
  setShareURL: (state:ItemListTransTmpState, action: PayloadAction<string>) => void,
  putProgress: (state:ItemListTransTmpState, action: PayloadAction<string>) => void,
  removeProgress: (state:ItemListTransTmpState, action: PayloadAction<string>) => void,
  searched: (state:ItemListTransTmpState, action: PayloadAction<{param: RequestParam, datas:RowData[], sort:{colKey:string, rowKey:string, asc:boolean}, colRowModel:ItemListTransColRowModel, visibleRowsKey:string[]}>) => void,
  execSort: (state:ItemListTransTmpState, action: PayloadAction<{sort:{colKey:string, rowKey:string, asc:boolean}, colRowModel:ItemListTransColRowModel, visibleRowsKey:string[], isMonthly:boolean}>) => void,
  setDatas: (state:ItemListTransTmpState, action: PayloadAction<{dataGroups:RowDataGroup[], colRowModel:ItemListTransColRowModel, visibleRowsKey:string[], isMonthly:boolean}>) => void,
  rowSelectionChange: (state:ItemListTransTmpState, action: PayloadAction<{start:number,end:number}>) => void,
  refreshTable: (state:ItemListTransTmpState, action: PayloadAction<{colRowModel:ItemListTransColRowModel, visibleRowsKey:string[], isMonthly:boolean}>) => void,
  clearFindKeyword: (state) => void,
  setFindKeyword: (state:ItemListTransTmpState, action: PayloadAction<string>) => void,
  setFindedCellSelect: (state:ItemListTransTmpState, action: PayloadAction<findedCell>) => void,
  setFindedCells: (state:ItemListTransTmpState, action: PayloadAction<{findedCells:findedCell[], over:boolean}>) => void,
  setLastUpdate: (state:ItemListTransTmpState, action: PayloadAction<string | null>) => void,
  setAdjus0Imported: (state:ItemListTransTmpState, action: PayloadAction<boolean | null>) => void,
  setIsIME: (state:ItemListTransTmpState, action: PayloadAction<boolean>) => void,
  setInitList: (state:ItemListTransTmpState, action: PayloadAction<string>) => void,
  setErrorMessage: (state:ItemListTransTmpState, action: PayloadAction<string>) => void,
}

const createReducerContent = ():ItemListTransTmpReducer => {return {

    // Option YM
    setYMList(state:ItemListTransTmpState, action: PayloadAction<YM[]>) {
      state.ymList = action.payload;
    },
    setGroup2List(state:ItemListTransTmpState, action: PayloadAction<CodeName[]>) {
      state.group2List = action.payload;
    },
    setGroup1List(state:ItemListTransTmpState, action: PayloadAction<CodeName[]>) {
      state.group1List = action.payload;
    },
    setShitenList(state:ItemListTransTmpState, action: PayloadAction<CodeName[]>) {
      state.shitenList = action.payload;
    },
    setCenterList(state:ItemListTransTmpState, action: PayloadAction<CodeName[]>) {
      state.centerList = action.payload;
    },
    setMakerList(state:ItemListTransTmpState, action: PayloadAction<CodeName[]>) {
      state.makerList = action.payload;
    },
    setTokuisakiList(state:ItemListTransTmpState, action: PayloadAction<CodeName[]>) {
      state.tokuisakiList = action.payload;
    },
    setPbList(state:ItemListTransTmpState, action: PayloadAction<CodeName[]>) {
      state.pbList = action.payload;
    },
    setTagList(state:ItemListTransTmpState, action: PayloadAction<CodeName[]>) {
      state.tagList = action.payload;
    },
    setCategoryList(state:ItemListTransTmpState, action: PayloadAction<CodeName[]>) {
      state.categoryList = action.payload;
    },

    setAccordionOpen(state:ItemListTransTmpState, action: PayloadAction<boolean>) {
      state.accordionOpen = action.payload;
    },
    //検索条件
    setRequestParam(state:ItemListTransTmpState, action: PayloadAction<RequestParam>) {
      state.requestParam = action.payload;
    },
    addRequestParamQueue(state:ItemListTransTmpState, action: PayloadAction<RequestParam[]>) {
      state.requestParamQueue = [...state.requestParamQueue, ...action.payload];
    },
    setRequestParamQueue(state:ItemListTransTmpState, action: PayloadAction<RequestParam[]>) {
      state.requestParamQueue = action.payload;
    },
    clearRequestParamQueue(state) {
      state.requestParamQueue = [];
    },
    setRetrievedID(state:ItemListTransTmpState, action: PayloadAction<RetrievedId>) {
      state.retrievedIDs = [action.payload];
    },
    addRetrievedID(state:ItemListTransTmpState, action: PayloadAction<RetrievedId>) {
      const retrievedIDs = [...state.retrievedIDs];
      retrievedIDs.push(action.payload);
      state.retrievedIDs = retrievedIDs;
    },
    removeRetrievedIDsOfLv(state:ItemListTransTmpState, action: PayloadAction<number>) {
      let retrievedIDs = [...state.retrievedIDs];
      const lv = action.payload;
      retrievedIDs = retrievedIDs.filter(retrievedID => retrievedID.lv < lv);
      state.retrievedIDs = retrievedIDs;
    },

    setShareURL(state:ItemListTransTmpState, action: PayloadAction<string>) {
      state.shareURL = action.payload;
    },

    putProgress(state:ItemListTransTmpState, action: PayloadAction<string>) {
      const key = action.payload;
      const progressNew = {...state.progress};
      progressNew[key] = true;
      state.progress = progressNew;
    },
    removeProgress(state:ItemListTransTmpState, action: PayloadAction<string>) {
      const key = action.payload;
      const progressNew = {};
      Object.keys(state.progress).forEach(k => {
        if(key != k) {
          progressNew[k] = true;
        }
      })
      state.progress = progressNew;
    },
    searched(state:ItemListTransTmpState, action: PayloadAction<{param: RequestParam, datas:RowData[], sort:{colKey:string, rowKey:string, asc:boolean}, colRowModel:ItemListTransColRowModel, visibleRowsKey:string[]}>) {
      const colRowModel = action.payload.colRowModel;
      const sort = action.payload.sort;
      //計算
      let datas = action.payload.datas;
      const param = action.payload.param;
      const visibleRowsKey = action.payload.visibleRowsKey;
      const isMonthly = dataTypeIsMonthly(param.dataType);
      
      const calcKepping = param.dataType != "daily"; //日別は欠品を計算しない

      //URL共有判定
      const notShare = !param.shareid;

      datas = parseData(datas);
      let dataYMs:YM[];
      //日次検索の場合
      if(param.dataType == 'daily') {
        dataYMs = [];
        let ym = param.ym;
        const momentymd = moment(ym + '01');
        while(param.ym == ym) {
          dataYMs.push(new YM({code:momentymd.format('YYYYMMDD'), name:momentymd.format('MM/DD(ddd)'), thisMonth:false}));
          momentymd.add(1, 'days');
          ym = momentymd.format('YYYYMM');
        }
        {//重複除去
          //日付、実績あり（欠品またはPB実績有効）順で並び替え
          datas.sort((a, b) => {
            const objA = a;
            const objB = b;
            let comp = 0;
            comp = compareUtil.compareNumber2(objA ? objA.YMID : null, objB ? objB.YMID : null, true);
            return comp;
          });
        }
      }
      //月次検索の場合
      else {
        dataYMs = state.ymList.filter(ym => ym.code >= param.ymFrom && ym.code <= param.ym);
      }
      dataYMs = dataYMs.sort((a,b) => a.code == b.code ? 0 : a.code < b.code ? -1 : 1);
      let dataGroups = convertDataGroups(datas, calcKepping);

      // dataGroups = calcDataGroups(dataGroups, dataGroups, calcKepping); //計算項目の計算
      dataGroups = calcTotalGroup(dataGroups, calcKepping); //合計行の作成
      dataGroups = doSortGroup(dataGroups, dataYMs, sort.colKey, sort.rowKey, sort.asc, false, !notShare);  //ソート
      dataGroups = resetGroupRowNo(dataGroups, null); //列番号の振りなおし

      const rowInfos = convertRowInfos(dataGroups, visibleRowsKey, isMonthly, state.adjus0Imported);
      Object.assign(state, {
        dataYMs: dataYMs,
        dataGroups: dataGroups,
        rowInfos: rowInfos,
        rows: convertRows(rowInfos, colRowModel, dataYMs, isMonthly),
        // mergeCells: createMergeCells(rowInfos, colRowModel, visibleRowsKey), //描写が遅すぎる
      });
    },
    execSort(state:ItemListTransTmpState, action: PayloadAction<{sort:{colKey:string, rowKey:string, asc:boolean}, colRowModel:ItemListTransColRowModel, visibleRowsKey:string[], isMonthly:boolean}>) {
      const isMonthly = action.payload.isMonthly;
      const colRowModel = action.payload.colRowModel;
      const sort = action.payload.sort;
      const colKey = sort.colKey;
      const rowKey = sort.rowKey;
      const asc = sort.asc;
      const visibleRowsKey = action.payload.visibleRowsKey;

      let dataGroups = [...state.dataGroups];
      dataGroups = doSortGroup(dataGroups, state.dataYMs, colKey, rowKey, asc, false, false);
      dataGroups = resetGroupRowNo(dataGroups, null); //列番号の振りなおし

      const rowInfos = convertRowInfos(dataGroups, visibleRowsKey, isMonthly, state.adjus0Imported);
      Object.assign(state, {
        dataGroups: dataGroups,
        rowInfos: rowInfos,
        rows: convertRows(rowInfos, colRowModel, state.dataYMs, isMonthly),
        // mergeCells: createMergeCells(rowInfos, colRowModel, visibleRowsKey),
      });
    },
    setDatas(state:ItemListTransTmpState, action: PayloadAction<{dataGroups:RowDataGroup[], colRowModel:ItemListTransColRowModel, visibleRowsKey:string[], isMonthly:boolean}>) {
      const isMonthly = action.payload.isMonthly;
      const colRowModel = action.payload.colRowModel;
      const dataGroups = action.payload.dataGroups;
      const visibleRowsKey = action.payload.visibleRowsKey;
      const rowInfos = convertRowInfos(dataGroups, visibleRowsKey, isMonthly, state.adjus0Imported);
      Object.assign(state, {
        dataGroups: dataGroups,
        rowInfos: rowInfos,
        rows: convertRows(rowInfos, colRowModel, state.dataYMs, isMonthly),
        // mergeCells: createMergeCells(rowInfos, colRowModel, visibleRowsKey),
      });
    },
    // editRowDatas(state:ItemListTransTmpState, action: PayloadAction<{row:number, key:string, value:string|number|object|null, relatedValues?: {key:string, value:string|number|object|null}[]}[]>) {
    //   const newData = [...state.rows];
    //   action.payload.forEach((editData)=>{
    //     let data = newData[editData.row];
    //     data[editData.key] = editData.value;
    //     //関連データの更新
    //     if(editData.relatedValues) {
    //       editData.relatedValues.forEach(relatedValue => {
    //         data[relatedValue.key] = relatedValue.value;
    //       })
    //     }
    //   })
    //   state.rows = newData;
    // },
    // setRowDatas(state:ItemListTransTmpState, action: PayloadAction<RowData[]>) {
    //   state.rows = action.payload;
    // },
    rowSelectionChange(state:ItemListTransTmpState, action: PayloadAction<{start:number,end:number}>){
      state.selectionRowStart = action.payload.start;
      state.selectionRowEnd = action.payload.end;
      state.selectionRowInfo = state.rowInfos[action.payload.start];
    },
    refreshTable(state:ItemListTransTmpState, action: PayloadAction<{colRowModel:ItemListTransColRowModel, visibleRowsKey:string[], isMonthly:boolean}>){
      console.log('refreshTable');
      const isMonthly = action.payload.isMonthly;
      const visibleRowsKey = action.payload.visibleRowsKey;
      const colRowModel = action.payload.colRowModel;
      const rowInfos = convertRowInfos(state.dataGroups, visibleRowsKey, isMonthly, state.adjus0Imported);
      Object.assign(state, {
        rowInfos: rowInfos,
        rows: convertRows(rowInfos, colRowModel, state.dataYMs, isMonthly),
        // mergeCells: createMergeCells(rowInfos, colRowModel, visibleRowsKey),
      });
    },
    clearFindKeyword(state) {
      Object.assign(state, {
        findKeyword: '',
        findedCellSelect: null,
        findedCells: [],
        findedCellsOver: false,
      });
    },
    setFindKeyword(state:ItemListTransTmpState, action: PayloadAction<string>) {
      state.findKeyword = action.payload;
    },
    setFindedCellSelect(state:ItemListTransTmpState, action: PayloadAction<findedCell>) {
      state.findedCellSelect = action.payload;
    },
    setFindedCells(state:ItemListTransTmpState, action: PayloadAction<{findedCells:findedCell[], over:boolean}>) {
      state.findedCells = action.payload.findedCells;
      state.findedCellsOver = action.payload.over;
    },
    setIsIME(state:ItemListTransTmpState, action: PayloadAction<boolean>){
      state.isIME = action.payload;
    },
    setInitList(state:ItemListTransTmpState, action: PayloadAction<string>) {
      if (state.initList.some(data => data == action.payload)) {
        state.initList = state.initList.filter(data => data != action.payload);
      }
    },
    setLastUpdate(state:ItemListTransTmpState, action: PayloadAction<string | null>) {
      state.lastUpdate = action.payload;
    },
    setAdjus0Imported(state:ItemListTransTmpState, action: PayloadAction<boolean | null>) {
      state.adjus0Imported = action.payload;
    },
    setErrorMessage(state:ItemListTransTmpState, action: PayloadAction<string>) {
      state.errorMessage = action.payload;
    }
}};

const createSliceContent = (name:string) => createSlice({
  name: name,
  initialState,
  reducers: createReducerContent(),
});

//RowDataGroupに変換
const convertDataGroups = (datas:RowData[], calcKepping:boolean): RowDataGroup[] => {
  //コード順でソート
  const asc = true;
  datas = datas.sort((a,b) => {
    const objA = a;
    const objB = b;
    let comp = 0;
    comp = compareUtil.compareString(objA?.IID, objB?.IID, asc);
    return comp;
  });

  const dataGroups:RowDataGroup[] = [];
  let preData = null;
  let dataGroup:RowDataGroup;
  datas.forEach(data => {
    let chk = true;
    if (preData?.IID != data?.IID) {
      chk = false;
    }

    if (!preData || !chk) {
      dataGroup = {
        TP: data.TP,
        // drillDowned: data.drillDowned,
        CAT: data.CAT, //一般/SEJ/混在
        ymDatas:[],
        dataTTL:{},
      };

      //ヘッダー項目はコピー
      headerColKeys.forEach(colKey => {
        dataGroup[colKey] = data[colKey];
      });

      dataGroups.push(dataGroup);
    }
    data = calcData(data, "all", null, null, null, calcKepping);
    dataGroup.ymDatas.push({YMID:data.YMID, data:data});

    dataGroup.dataTTL = calcTotalSub(dataGroup.dataTTL, data);
    dataGroup.dataTTL = calcTotalSubPS(dataGroup.dataTTL, data, []);
    preData = data;
  })
  dataGroups.forEach(dataGroup => {
    dataGroup.ymDatas.sort((a, b) => a.YMID - b.YMID);
  });
  dataGroup.dataTTL = calcData(dataGroup.dataTTL, "all", null, null, null, calcKepping);
  return dataGroups;
}

//合計行作成
const calcTotalGroup = (dataGroups:RowDataGroup[], calcKepping:boolean): RowDataGroup[] => {
  const totalGroup:RowDataGroup = {
    TP: "total",
    CAT:  "def",  //暫定的にdefにする
    // drillDowned: true,
    ymDatas: [],
    dataTTL:{},
  };

  //ヘッダー項目は初期化
  headerColKeys.forEach(colKey => {
    totalGroup[colKey] = null;
  });
  

  //set No.
  dataGroups.forEach((dataGroup) => {
    // if(dataGroup.TP == "lv1") {
      if(dataGroup.ymDatas) {
        dataGroup.ymDatas.forEach(ymData => {
          const YMID = ymData.YMID;
          const data:RowData = ymData.data;
          let totalYMData = totalGroup.ymDatas.find(ymData => ymData.YMID == YMID);
          if(!totalYMData) {
            totalYMData = {
              YMID:ymData.YMID,
              data: {},
            };
            totalGroup.ymDatas.push(totalYMData);
          }
          let total:RowData = totalYMData.data;
          //縦の合計
          total = calcTotalSub(total, data);
          total = calcTotalSubPS(total, data, []);
        });
      }
      //sej含む場合はmix
      if(dataGroup.CAT == "mix" || dataGroup.CAT == "sej" ) {
        totalGroup.CAT = 'mix';
      }
    // }
  });

  //横の合計
  totalGroup.ymDatas.sort((a, b) => compareUtil.compareAny(a.YMID, b.YMID, true));
  totalGroup.ymDatas.forEach(ymData => {
    const data:RowData = ymData.data;
    ymData.data = calcData(ymData.data, "all", null, null, null, calcKepping);

    totalGroup.dataTTL = calcTotalSub(totalGroup.dataTTL, data);
    totalGroup.dataTTL = calcTotalSubPS(totalGroup.dataTTL, data, []);
  });
  totalGroup.dataTTL = calcData(totalGroup.dataTTL, "all", null, null, null, calcKepping);

  if(dataGroups.length > 0 && dataGroups[0].TP == "total") {
    dataGroups[0] = totalGroup;
  }
  else {
    dataGroups = [totalGroup, ...dataGroups];
  }

  return dataGroups;
}

// //計算
// const calcDataGroups = (dataGroups:RowDataGroup[], dataGroupsAll:RowDataGroup[], calcKepping:boolean): RowDataGroup[] => {

//   dataGroups.forEach(dataGroup => {

//     //各年月
//     dataGroup.ymDatas.forEach(ymData => {
//       const data:RowData = ymData.data;

//       ymData.data = calcData(data, "all", null, null, null, calcKepping);
//     });

//     //合計列
//     {
//       dataGroup.dataTTL = calcData(dataGroup.dataTTL, "all", null, null, null, calcKepping);
//     }
//   });

//   return dataGroups;
// }

//no振りなおし
const resetGroupRowNo = (dataGroups:RowDataGroup[], parentGroup:RowDataGroup): RowDataGroup[] => {
  //set No.
  dataGroups = dataGroups.map((row, index) => {
    let no = row.TP == "total" ? "合計" : `${index}`;

    return {
      ...row,
      no: no,
    }
  });
  return dataGroups;
}

//ドリルダウンの差し込み
const spliceDataGroup = (addIndex: number, addDataGroups:RowDataGroup[], destDataGroups:RowDataGroup[]): RowDataGroup[] => {
  const args = [].concat([addIndex,0]).concat(addDataGroups);
  Array.prototype.splice.apply(destDataGroups,args);
  return destDataGroups;
}

//マージを作成
// const createMergeCells = (rowInfos:RowInfo[], colRowModel:ItemListTransColRowModel, visibleRowsKey:string[]): {row: number, col: number, rowspan: number, colspan: number}[] => {
//   if(visibleRowsKey.length <= 1) {
//     return null;
//   }

//   const mergeCells:{row: number, col: number, rowspan: number, colspan: number}[] = [];

//   const cols = [
//     colRowModel.colFromKey('no'),
//     colRowModel.colFromKey('H1CD'),
//     colRowModel.colFromKey('H1NM'),
//     colRowModel.colFromKey('H2CD'),
//     colRowModel.colFromKey('H2NM'),
//     colRowModel.colFromKey('H3CD'),
//     colRowModel.colFromKey('H3NM'),
//     colRowModel.colFromKey('H4CD'),
//     colRowModel.colFromKey('H4NM'),
//     colRowModel.colFromKey('H5CD'),
//     colRowModel.colFromKey('H5NM'),
//   ];

//   let prev:RowInfo;
//   let rowStart:number;
//   let rowSpan:number;
//   rowInfos.forEach((rowInfo, index)=> {
//     if(!prev || prev.dataGroup.no != rowInfo.dataGroup.no) {
//       if(prev) {
//         cols.forEach(col => {
//           mergeCells.push({row: rowStart, col: col, rowspan: rowSpan, colspan: 1});
//         });
//       }
//       rowStart = index;
//       rowSpan = 0;
//     }
//     rowSpan++;
//     prev = rowInfo;
//   });
//   if(prev) {
//     cols.forEach(col => {
//       mergeCells.push({row: rowStart, col: col, rowspan: rowSpan, colspan: 1});
//     });
//   }
//   if(mergeCells.length == 0){
//     return null;
//   }

//   return mergeCells.length == 0 ? null : mergeCells;
// }
//行情報に変換
const convertRowInfos = (dataGroups:RowDataGroup[], visibleRowsKey:string[], isMonthly:boolean, adjus0Imported:boolean): RowInfo[] => {

  const rowKeyInfos:{
    rowKey: string,
    rowIndex:number,
    rowIndexLast:boolean,
    rowHeaderIndex1:number,
    rowHeaderIndex2:number,
    rowHeaderIndex1Last:boolean,
    rowHeaderIndex2Last:boolean,
  }[] = [];

  let rowHeaderCount1 = 0;
  let rowHeaderCount2 = 0;
  let prevHeadersRow1 = null;
  let prevHeadersRow2 = null;
  let prevRowKeyInfo;
  const headersRow = isMonthly ? headersRowMonthly : headersRowDaily;

  //強制表示
  visibleRowsKey = arrayUtil.union(visibleRowsKey, !isMonthly ? mustVisibleRowsKeyDaily : mustVisibleRowsKeyMonthly);
  //受注未取り込み時の強制非表示
  if(!adjus0Imported) {
    visibleRowsKey = arrayUtil.not(visibleRowsKey, unVisibleRowsKeyAdjus);
  }

  visibleRowsKey.forEach((visibleRowKey, index) => {
    const headersRow1 = headersRow[0][visibleRowKey];
    const headersRow2 = headersRow[1][visibleRowKey];

    if(prevRowKeyInfo) {
      prevRowKeyInfo.rowHeaderIndex1Last = headersRow1 != prevHeadersRow1;
      prevRowKeyInfo.rowHeaderIndex2Last = headersRow1 != prevHeadersRow1 || headersRow2 != prevHeadersRow2;
    }

    rowHeaderCount1 = headersRow1 != prevHeadersRow1 ? 0 : rowHeaderCount1;
    rowHeaderCount2 = headersRow1 != prevHeadersRow1 || headersRow2 != prevHeadersRow2 ? 0 : rowHeaderCount2;

    rowKeyInfos.push({
      rowKey: visibleRowKey,
      rowIndex:index,
      rowIndexLast:visibleRowsKey.length - 1 == index,
      rowHeaderIndex1: rowHeaderCount1++,
      rowHeaderIndex2: rowHeaderCount2++,
      rowHeaderIndex1Last: false, //一時的に仮置き
      rowHeaderIndex2Last: false, //一時的に仮置き
    });

    prevRowKeyInfo = rowKeyInfos[rowKeyInfos.length - 1];
    prevHeadersRow1 = headersRow1;
    prevHeadersRow2 = headersRow2;
  });
  if(prevRowKeyInfo) {
    prevRowKeyInfo.rowHeaderIndex1Last = true;
    prevRowKeyInfo.rowHeaderIndex2Last = true;
  }

  const rowInfos:RowInfo[] = [];
  dataGroups.forEach(dataGroup => {
    rowKeyInfos.forEach((rowKeyInfo) => {
      rowInfos.push({...{
          dataGroup: dataGroup,
        }, ...rowKeyInfo}
      );
    })
  });
  return rowInfos;
}
  //配列データに変換
const convertRows = (rowInfos:RowInfo[], colRowModel:ItemListTransColRowModel, dataYMs: YM[], isMonthly:boolean): [][] => {
  const headersRow = isMonthly ? headersRowMonthly : headersRowDaily;
  const rows = [];
  //set No.
  rowInfos.forEach((rowInfo) => {
    const dataGroup:RowDataGroup = rowInfo.dataGroup;
    const r = [];
    colRowModel.colKeys.forEach((colKey) => {
      switch (colKey) {
        case "rowHeader1":
          r.push(headersRow[0][rowInfo.rowKey]);
          break;
        case "rowHeader2":
          r.push(headersRow[1][rowInfo.rowKey]);
          break;
        case "rowHeader3":
          r.push(headersRow[2][rowInfo.rowKey]);
          break;
        case "TTL":
          r.push(dataGroup.dataTTL[rowInfo.rowKey]);
          break;
        default: {
          //期間列
          const termIndex = termColKeys.indexOf(colKey);
          if(termIndex >= 0) {
            const dataYM = dataYMs[termIndex];
            const YMID:number = dataYM ? parseInt(dataYM.code) : -1;
            const ymData:YMData = dataYM ? dataGroup.ymDatas.find(ymData => ymData.YMID == YMID) : null;
            r.push(ymData ? ymData.data[rowInfo.rowKey] : null);
          }
          else {
            r.push(dataGroup[colKey]);
          }
        }
          break;
      }
    });
    rows.push(r);
  });

  return rows;
}

//ソート
const doSortGroup = (dataGroups:RowDataGroup[], dataYMs: YM[], colKey:string, rowKey:string, asc:boolean, lvKeySort:boolean, equalsIsCode:boolean): RowDataGroup[] => {
  dataGroups.sort((a, b) => {
    //合計行は常に上
    if (a.TP == "total" || b.TP == "total") {
      return a.TP == "total" ? -1 : 1;
    }

    let objA = a;
    let objB = b;
    let comp = 0;

    if (lvKeySort) {
      comp = compareUtil.compareString(objA?.IID, objB?.IID, asc);
      return comp;
    }

    let va = null;
    let vb = null;
    if (rowKey) {
      let dataA: RowData = null;
      let dataB: RowData = null;
      if (colKey == 'TTL') {
        dataA = objA ? objA.dataTTL : null;
        dataB = objB ? objB.dataTTL : null;
      }
      else {
        const termIndex = termColKeys.indexOf(colKey);
        const dataYM = dataYMs[termIndex];
        //存在しない列の場合は、合計列を使う
        if (!dataYM) {
          dataA = objA ? objA.dataTTL : null;
          dataB = objB ? objB.dataTTL : null;
        }
        else {
          const ymDataA = objA ? objA.ymDatas.find(ymData => ymData.YMID == parseInt(dataYM.code)) : null;
          const ymDataB = objB ? objB.ymDatas.find(ymData => ymData.YMID == parseInt(dataYM.code)) : null;
          dataA = ymDataA ? ymDataA.data : null;
          dataB = ymDataB ? ymDataB.data : null;
        }
      }
      va = dataA ? dataA[rowKey] : null;
      vb = dataB ? dataB[rowKey] : null;
    }
    else {
      va = objA ? objA[colKey] : null;
      vb = objB ? objB[colKey] : null;
    }

    //数値型
    if (typeof va === 'number' || typeof vb === 'number') {
      comp = compareUtil.compareNumber2(va, vb, asc);
    }
    else if (typeof va === 'string' || typeof vb === 'string') {
      comp = compareUtil.compareString(va, vb, asc);
    }
    if (comp != 0) {
      return comp;
    }
    

    //それでもソートできない場合はコード順
    if (equalsIsCode) {
        comp = compareUtil.compareString(objA?.IID, objB?.IID, asc);
    }
    return comp;
  });

  return dataGroups;
}

export const getOptionLabel = (option: CodeName) => {
  return option && option.name ? (option.code + ' ' + option.name) : "";
}
export const getOptionLabelGroup1 = (option: CodeName) => {
  // 企業グループ2CD(5桁)と'_'を削除
  return option && option.name ? (option.code.substring(5 + 1) + ' ' + option.name) : "";
}
export const getOptionLabelCenter = (option: CodeName) => {
  return option && option.name ? (option.code.replace('_', '') + ' ' + option.name) : "";
}
export const getOptionLabelPb = (option: CodeName) => {
  return option && option.name ? ((option.code === "全選択" ? '' : option.code + ' ') + option.name) : "";
}

// 複数指定時の区切り文字
const delim = ",";

export const targetName = (targetNM:string) => {
  if(!targetNM) {
    return "-";
  }
  else {
    const targetNMs: string[] = targetNM.split(delim);
    return targetNMs[0];
  }
}

export const targetCnt = (params : RequestParam) => {
  const targets = [];
  for (let i = 1; i <= drillDownMaxLv; i++) {
    targets.push(params['d' + i + '_target']);
  }
  const names = targets.filter(target => target).map(target => ({ target: target, length: target.split(delim).length })).sort((a, b) => b.length - a.length);
  const targetNM = names[0].target;

  if (!targetNM) {
    return "";
  }
  else {
    const targetNMs: string[] = targetNM.split(delim);
    return '(他' + (targetNMs.length - 1) + '件)';
  }
}

//検索条件の複数指定判定
export const isMultipleConditions = (requestedParam: RequestParam): boolean => {
  if (!requestedParam.d1_targetNM) {
    return false;
  }
  const targetNMs: string[] = requestedParam.d1_targetNM.split(delim);
  return targetNMs.length > 1;
}


//Page Slice Export
//itemListTransTmp
export const itemListTransTmpSlice = createSliceContent("itemListTransTmp");
