import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CodeName } from "@/store/common";

import { CenterItem, KigenData, SyukkaData, listSortOrders, centerItemAttributes } from '@/store/zaikoreal/zaikoRealCommon'
import * as zaikoRealRetrieve from "@/assets/apitype/zaikoRealRetrieve";
import { ZaikoRealColRowModel, rowKeys, headersRow, colDataType, rowDataType } from "@/components/zaikoreal/ZaikoRealTableModel";
import * as compareUtil from "@/util/compareUtil";
import * as arrayutil from "@/util/arrayUtil";
import * as editorUtil from "@/util/editorUtil";
import * as calcUtil from "@/util/calcUtil";

//0.00フォーマット
const formatterP00 = new Intl.NumberFormat('ja-JP', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 });
//0,0フォーマット
const formatterN0 = new Intl.NumberFormat('ja-JP', { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0 });

export type RowDataGroup = {
  centerItem: CenterItem,
  syukkaData?: SyukkaData,
  kigenDatas1?: KigenData[],
  kigenDatas2?: KigenData[],
  kigenDatas3?: KigenData[],
  kigenDatas4?: KigenData[],
  kigenDatas5?: KigenData[],
  maxkigenDatasSize?: number,

  kigenDataTotal1?: KigenData,
  kigenDataTotal2?: KigenData,
  kigenDataTotal3?: KigenData,
  kigenDataTotal4?: KigenData,
  kigenDataTotal5?: KigenData,

}

export type RowInfo = {
  TP: "item" | "center" | "kigen" | "total",
  no?: string,
  subno?: string,
  dataGroup:RowDataGroup,
  rowKey?: string,

  rowIndex?:number,  //同一集計行内でのrowKeyのindex
  rowIndexLast?:boolean, //同一集計行内での最後のrowKeyフラグ

  kigenData1?: KigenData,
  kigenData2?: KigenData,
  kigenData3?: KigenData,
  kigenData4?: KigenData,
  kigenData5?: KigenData,
}

//Page State
export type ZaikoRealTmpState = {
  accordionOpen: boolean,

  makerList: CodeName[],  //メーカー
  bumonList: CodeName[], //部門
  centerList: CodeName[], //倉庫

  progress: Record<string, unknown>,
  retrieveParam: zaikoRealRetrieve.RequestParam,  //検索条件(検索済み)
  retrievedParam: zaikoRealRetrieve.RequestParam,  //検索条件(検索済み)
  shareParam: zaikoRealRetrieve.RequestParam,
  shareURL: string,  //共有URL

  dataGroupsAll: RowDataGroup[],
  dataGroups: RowDataGroup[],
  rowInfos: RowInfo[],
  fixedRowsTop :number,
  rows: any[][],
  mergeCells: {row: number, col: number, rowspan: number, colspan: number}[],

  lastUpdate: string | null,
  errorMessage: string | null,
};

export const initialState: ZaikoRealTmpState = {
  accordionOpen: true,
  makerList: [],
  bumonList: [],
  centerList: [],

  progress: {},
  retrieveParam: null,
  retrievedParam: null,
  shareParam: null,
  shareURL: '',  //共有URL

  dataGroupsAll: [],
  dataGroups: [],
  rowInfos: [],
  fixedRowsTop :0,
  rows: [],
  mergeCells: null,

  lastUpdate: null,
  errorMessage: null,
};

//Page Slice
export type ZaikoRealTmpReducer = {
  setAccordionOpen: (state:ZaikoRealTmpState, action: PayloadAction<boolean>) => void,
  setShareURL: (state: ZaikoRealTmpState, action: PayloadAction<string>) => void,
  setShareParam: (state: ZaikoRealTmpState, action: PayloadAction<zaikoRealRetrieve.RequestParam>) => void,
  putProgress: (state:ZaikoRealTmpState, action: PayloadAction<string>) => void,
  removeProgress: (state:ZaikoRealTmpState, action: PayloadAction<string>) => void,
  setErrorMessage: (state:ZaikoRealTmpState, action: PayloadAction<string>) => void,
  setMakerList: (state:ZaikoRealTmpState, action: PayloadAction<CodeName[]>) => void,
  setBumonList: (state:ZaikoRealTmpState, action: PayloadAction<CodeName[]>) => void,
  setCenterList: (state:ZaikoRealTmpState, action: PayloadAction<CodeName[]>) => void,
  setRetrieveParam: (state:ZaikoRealTmpState, action: PayloadAction<zaikoRealRetrieve.RequestParam>) => void,
  setRetrievedParam: (state:ZaikoRealTmpState, action: PayloadAction<zaikoRealRetrieve.RequestParam>) => void,

  searched: (state:ZaikoRealTmpState, action: PayloadAction<{param: zaikoRealRetrieve.RequestParam, centerItems: CenterItem[], syukkaDatas: SyukkaData[], kigenDatas: KigenData[], colRowModel:ZaikoRealColRowModel, 
    listSortOrder:CodeName, listSortOrderDesc:boolean, visibleAttributes:string[][], visibleRowsKey:string[],
    visibleZaikoKbn1:boolean, visibleZaikoKbn2:boolean, visibleZaikoKbn3:boolean, visibleZaikoKbn4:boolean, visibleZaikoKbn5:boolean, 
  }>) => void,
  refreshTableWithFilter: (state:ZaikoRealTmpState, action: PayloadAction<{colRowModel:ZaikoRealColRowModel, 
    listSortOrder:CodeName, listSortOrderDesc:boolean, visibleAttributes:string[][], visibleRowsKey:string[],
    visibleZaikoKbn1:boolean, visibleZaikoKbn2:boolean, visibleZaikoKbn3:boolean, visibleZaikoKbn4:boolean, visibleZaikoKbn5:boolean, 
  }>) => void,
  refreshTable: (state:ZaikoRealTmpState, action: PayloadAction<{colRowModel:ZaikoRealColRowModel, 
    listSortOrder:CodeName, listSortOrderDesc:boolean, visibleAttributes:string[][], visibleRowsKey:string[]}>) => void,
  setLastUpdate: (state:ZaikoRealTmpState, action: PayloadAction<string | null>) => void,
}

const createReducerContent = ():ZaikoRealTmpReducer => {return {
    setAccordionOpen(state:ZaikoRealTmpState, action: PayloadAction<boolean>) {
      state.accordionOpen = action.payload;
    },
    setShareURL(state: ZaikoRealTmpState, action: PayloadAction<string>) {
      state.shareURL = action.payload;
    },
    setShareParam(state: ZaikoRealTmpState, action: PayloadAction<zaikoRealRetrieve.RequestParam>) {
      state.shareParam = action.payload;
    },
    putProgress(state:ZaikoRealTmpState, action: PayloadAction<string>) {
      const key = action.payload;
      const progressNew = {...state.progress};
      progressNew[key] = true;
      state.progress = progressNew;
    },
    removeProgress(state:ZaikoRealTmpState, action: PayloadAction<string>) {
      const key = action.payload;
      const progressNew = {};
      Object.keys(state.progress).forEach(k => {
        if(key != k) {
          progressNew[k] = true;
        }
      })
      state.progress = progressNew;
    },
    setErrorMessage(state:ZaikoRealTmpState, action: PayloadAction<string>) {
      state.errorMessage = action.payload;
    },
    setMakerList(state:ZaikoRealTmpState, action: PayloadAction<CodeName[]>) {
      state.makerList = action.payload;
    },
    setBumonList(state:ZaikoRealTmpState, action: PayloadAction<CodeName[]>) {
      state.bumonList = action.payload;
    },
    setCenterList(state:ZaikoRealTmpState, action: PayloadAction<CodeName[]>) {
      state.centerList = action.payload;
    },
    setRetrieveParam(state:ZaikoRealTmpState, action: PayloadAction<zaikoRealRetrieve.RequestParam>) {
      state.retrieveParam = action.payload;
    },
    setRetrievedParam(state:ZaikoRealTmpState, action: PayloadAction<zaikoRealRetrieve.RequestParam>) {
      state.retrievedParam = action.payload;
    },

    searched(state:ZaikoRealTmpState, action: PayloadAction<{param: zaikoRealRetrieve.RequestParam, centerItems: CenterItem[], syukkaDatas: SyukkaData[], kigenDatas: KigenData[], colRowModel:ZaikoRealColRowModel, 
      listSortOrder:CodeName, listSortOrderDesc:boolean, visibleAttributes:string[][], visibleRowsKey:string[],
      visibleZaikoKbn1:boolean, visibleZaikoKbn2:boolean, visibleZaikoKbn3:boolean, visibleZaikoKbn4:boolean, visibleZaikoKbn5:boolean, 
    }>) {
      const colRowModel = action.payload.colRowModel;
      const param = action.payload.param;

      let centerItems = parseDataCenterItem(action.payload.centerItems);
      let syukkaDatas = parseDataSyukkaData(action.payload.syukkaDatas);
      let kigenDatas = parseDataKigenData(action.payload.kigenDatas);

      const listSortOrder = action.payload.listSortOrder;
      const listSortOrderDesc = action.payload.listSortOrderDesc;
      const visibleAttributes = action.payload.visibleAttributes;
      const visibleRowsKey = action.payload.visibleRowsKey;
      
      let dataGroupsAll = convertDataGroups(centerItems, syukkaDatas, kigenDatas);
      dataGroupsAll = calcDataGroups(dataGroupsAll);

      let dataGroups = filterDataGroups(dataGroupsAll, action.payload.visibleZaikoKbn1, action.payload.visibleZaikoKbn2, action.payload.visibleZaikoKbn3, action.payload.visibleZaikoKbn4, action.payload.visibleZaikoKbn5);
      dataGroups = sortDataGroups(dataGroups, listSortOrder, listSortOrderDesc);
      const [rowInfos, fixedRowsTop] = convertRowInfos(dataGroups, listSortOrder, visibleRowsKey);
      
      //store更新
      state.dataGroupsAll = dataGroupsAll;
      state.dataGroups = dataGroups;
      state.rowInfos = rowInfos;
      state.fixedRowsTop = fixedRowsTop;
      state.mergeCells = createMergeCells(rowInfos, colRowModel);
      state.rows = convertRows(rowInfos, colRowModel, visibleAttributes);
    },
    refreshTableWithFilter(state:ZaikoRealTmpState, action: PayloadAction<{colRowModel:ZaikoRealColRowModel, 
      listSortOrder:CodeName, listSortOrderDesc:boolean, visibleAttributes:string[][], visibleRowsKey:string[], 
      visibleZaikoKbn1:boolean, visibleZaikoKbn2:boolean, visibleZaikoKbn3:boolean, visibleZaikoKbn4:boolean, visibleZaikoKbn5:boolean, 
    }>){
      console.log('refreshTableWithFilter');
      if(!state.dataGroupsAll || state.dataGroupsAll.length == 0) {
        return;
      }
      const listSortOrder = action.payload.listSortOrder;
      const listSortOrderDesc = action.payload.listSortOrderDesc;
      const visibleAttributes = action.payload.visibleAttributes;
      const visibleRowsKey = action.payload.visibleRowsKey;
      const colRowModel = action.payload.colRowModel;

      let dataGroupsAll = state.dataGroupsAll;
      let dataGroups = filterDataGroups(dataGroupsAll, action.payload.visibleZaikoKbn1, action.payload.visibleZaikoKbn2, action.payload.visibleZaikoKbn3, action.payload.visibleZaikoKbn4, action.payload.visibleZaikoKbn5);
      dataGroups = sortDataGroups(dataGroups, listSortOrder, listSortOrderDesc);
      const [rowInfos, fixedRowsTop] = convertRowInfos(dataGroups, listSortOrder, visibleRowsKey);
      //store更新
      state.dataGroups = dataGroups;
      state.rowInfos = rowInfos;
      state.fixedRowsTop = fixedRowsTop;
      state.mergeCells = createMergeCells(rowInfos, colRowModel);
      state.rows = convertRows(rowInfos, colRowModel, visibleAttributes);
    },
    refreshTable(state:ZaikoRealTmpState, action: PayloadAction<{colRowModel:ZaikoRealColRowModel, 
      listSortOrder:CodeName, listSortOrderDesc:boolean, visibleAttributes:string[][], visibleRowsKey:string[],
      }>){
      console.log('refreshTable');
      if(!state.dataGroups || state.dataGroups.length == 0) {
        return;
      }
      const listSortOrder = action.payload.listSortOrder;
      const listSortOrderDesc = action.payload.listSortOrderDesc;
      const visibleAttributes = action.payload.visibleAttributes;
      const visibleRowsKey = action.payload.visibleRowsKey;
      const colRowModel = action.payload.colRowModel;

      let dataGroups = state.dataGroups;
      dataGroups = sortDataGroups(dataGroups, listSortOrder, listSortOrderDesc);
      const [rowInfos, fixedRowsTop] = convertRowInfos(dataGroups, listSortOrder, visibleRowsKey);
      //store更新
      state.rowInfos = rowInfos;
      state.fixedRowsTop = fixedRowsTop;
      state.mergeCells = createMergeCells(rowInfos, colRowModel);
      state.rows = convertRows(rowInfos, colRowModel, visibleAttributes);
    },
    setLastUpdate(state:ZaikoRealTmpState, action: PayloadAction<string | null>) {
      state.lastUpdate = action.payload;
    },

}};

const parseDataCenterItem = (datas:CenterItem[]): CenterItem[] => {
  datas = [...datas];
  datas.forEach((data) => {
    // if(typeof data.termStartDate === 'string') data.blIrisu = parseInt(data.blIrisu);
    // if(typeof data.termEndDate === 'string') data.blIrisu = parseInt(data.blIrisu);

    if(typeof data.blIrisu === 'string') data.blIrisu = parseInt(data.blIrisu);
    if(typeof data.csIrisu === 'string') data.csIrisu = parseInt(data.csIrisu);
    if(typeof data.bestBefore === 'string') data.bestBefore = parseInt(data.bestBefore);
    if(typeof data.teika === 'string') data.teika = parseInt(data.teika);
    if(typeof data.tatene === 'string') data.tatene = parseFloat(data.tatene);

    if(typeof data.zaikoQty1 === 'string') data.zaikoQty1 = parseFloat(data.zaikoQty1);
    if(typeof data.zaikoQty2 === 'string') data.zaikoQty2 = parseFloat(data.zaikoQty2);
    if(typeof data.zaikoQty3 === 'string') data.zaikoQty3 = parseFloat(data.zaikoQty3);
    if(typeof data.zaikoQty4 === 'string') data.zaikoQty4 = parseFloat(data.zaikoQty4);
    if(typeof data.zaikoQty5 === 'string') data.zaikoQty5 = parseFloat(data.zaikoQty5);
  });
  return datas;
}
const parseDataSyukkaData = (datas: SyukkaData[]): SyukkaData[] => {
  datas = [...datas];
  datas.forEach((data) => {
  });
  return datas;
}
const parseDataKigenData = (datas: KigenData[]): KigenData[] => {
  datas = [...datas];
  datas.forEach((data) => {
    if(typeof data.zaikoQty === 'string') data.zaikoQty = parseFloat(data.zaikoQty);
  });
  return datas;
}
//RowDataGroupに変換
const convertDataGroups = (centerItems: CenterItem[], syukkaDatas: SyukkaData[], kigenDatas: KigenData[]): RowDataGroup[] => {
  //商品、センター順でソート
  centerItems = centerItems.sort((a,b) => {
    let comp = 0;
    comp = compareUtil.compareString(a.itemCD, b.itemCD, true)
    if(comp == 0) {
      comp = compareUtil.compareString(a.centerCD, b.centerCD, true)
    }
    return comp;
  });
  //商品、センター順、期限日でソート
  kigenDatas = kigenDatas.sort((a,b) => {
    let comp = 0;
    comp = compareUtil.compareString(a.itemCD, b.itemCD, true)
    if(comp == 0) {
      comp = compareUtil.compareString(a.centerCD, b.centerCD, true)
    }
    if(comp == 0) {
      comp = compareUtil.compareString(a.zaikoKigen, b.zaikoKigen, true)
    }
    return comp;
  });

  //出荷データのマップ
  const syukkaDatasMap = {};
  syukkaDatas.forEach(syukkaData => {
    const key = `${syukkaData.itemCD} ${syukkaData.centerCD}`;
    syukkaDatasMap[key] = syukkaData;
  });

  //出荷データのマップ
  const kigenData1Map = {};
  const kigenData2Map = {};
  const kigenData3Map = {};
  const kigenData4Map = {};
  const kigenData5Map = {};
  kigenDatas.forEach(kigenData => {
    const key = `${kigenData.itemCD} ${kigenData.centerCD}`;
    const kigenDataMap = 
      kigenData.zaikoKbn == '1' ? kigenData1Map :
      kigenData.zaikoKbn == '2' ? kigenData2Map :
      kigenData.zaikoKbn == '3' ? kigenData3Map :
      kigenData.zaikoKbn == '4' ? kigenData4Map :
      kigenData.zaikoKbn == '5' ? kigenData5Map :
      null;
    if(!kigenDataMap) {
      return;
    }
    let kigenDataList:KigenData[] = kigenDataMap[key];
    if(!kigenDataList) {
      kigenDataList = [];
      kigenDataMap[key] = kigenDataList;
    }
    kigenDataList.push(kigenData);
  });
  //返品だけは在庫から取得する
  centerItems.filter(centerItem => centerItem.zaikoQty5).forEach(centerItem => {
    const key = `${centerItem.itemCD} ${centerItem.centerCD}`;
    let kigenDataList:KigenData[] = kigenData5Map[key];
    if(!kigenDataList) {
      kigenDataList = [];
      kigenData5Map[key] = kigenDataList;
    }
    kigenDataList.push({
      centerCD: centerItem.centerCD,
      itemCD: centerItem.itemCD,
      zaikoKbn: '5', //返品
      zaikoKigen: '99/99/99',
      zaikoKigenInvalid: true,
      zaikoQty: centerItem.zaikoQty5,
    });
    
  });

  return centerItems.map(centerItem => {
    const key = `${centerItem.itemCD} ${centerItem.centerCD}`;
    const kigenDatas1 = kigenData1Map[key];
    const kigenDatas2 = kigenData2Map[key];
    const kigenDatas3 = kigenData3Map[key];
    const kigenDatas4 = kigenData4Map[key];
    const kigenDatas5 = kigenData5Map[key];

    return {
      centerItem: centerItem,
      syukkaData: syukkaDatasMap[key],
      kigenDatas1: kigenDatas1,
      kigenDatas2: kigenDatas2,
      kigenDatas3: kigenDatas3,
      kigenDatas4: kigenDatas4,
      kigenDatas5: kigenDatas5,
      maxkigenDatasSize: Math.max(
        kigenDatas1 ? kigenDatas1.length : 0,
        kigenDatas2 ? kigenDatas2.length : 0,
        kigenDatas3 ? kigenDatas3.length : 0,
        kigenDatas4 ? kigenDatas4.length : 0,
        kigenDatas5 ? kigenDatas5.length : 0,
        3 //最低3行
        ),
    };
  });
}
//フィルタ
const filterDataGroups = (dataGroups:RowDataGroup[], 
  visibleZaikoKbn1:boolean, visibleZaikoKbn2:boolean, visibleZaikoKbn3:boolean, visibleZaikoKbn4:boolean, visibleZaikoKbn5:boolean
  ): RowDataGroup[] => {
    return dataGroups.filter(dataGroup => 
        (visibleZaikoKbn1 && visibleZaikoKbn2 && visibleZaikoKbn3 && visibleZaikoKbn4 && visibleZaikoKbn5) ||
        (visibleZaikoKbn1 && dataGroup.centerItem.zaikoQty1) ||
        (visibleZaikoKbn2 && dataGroup.centerItem.zaikoQty2) ||
        (visibleZaikoKbn3 && dataGroup.centerItem.zaikoQty3) ||
        (visibleZaikoKbn4 && dataGroup.centerItem.zaikoQty4) ||
        (visibleZaikoKbn5 && dataGroup.centerItem.zaikoQty5)
   );
}
//並び順変更
const sortDataGroups = (dataGroups:RowDataGroup[], listSortOrder:CodeName, listSortOrderDesc:boolean): RowDataGroup[] => {
  if(!dataGroups) {
    return dataGroups;
  }
  let asc = !listSortOrderDesc;
  let getSortKey1 = (o:RowDataGroup):string|number => 
    !o || !o.centerItem ? null : 
    !listSortOrder ? o.centerItem.itemCD :
    o.centerItem[listSortOrder.option1]
  ;
  let getSortKey2 = (o:RowDataGroup):string|number => 
    !o || !o.centerItem ? null : 
    !listSortOrder ? o.centerItem.itemCD :
    o.centerItem[listSortOrder.option2]
  ;
  let getSortKey3 = (o:RowDataGroup):string|number => 
    !o || !o.centerItem ? null : 
    !listSortOrder ? o.centerItem.centerCD :
    o.centerItem[listSortOrder.option3]
  ;
  let getSortKey4 = (o:RowDataGroup):string|number => 
    !o || !o.centerItem ? null : 
    !listSortOrder ? o.centerItem.centerCD :
    o.centerItem[listSortOrder.option4]
  ;
  dataGroups.sort((a, b) => {
    //第1弾ソート項目
    let va = getSortKey1(a);
    let vb = getSortKey1(b);
    let comp = compareUtil.compareAny(va, vb, asc);
    //第2弾ソート項目
    if(comp == 0) {
      va = getSortKey2(a);
      vb = getSortKey2(b);
      comp = compareUtil.compareAny(va, vb, asc);
    }
    //第3弾ソート項目
    if(comp == 0) {
      va = getSortKey3(a);
      vb = getSortKey3(b);
      comp = compareUtil.compareAny(va, vb, asc);
    }
    //第4弾ソート項目
    if(comp == 0) {
      va = getSortKey4(a);
      vb = getSortKey4(b);
      comp = compareUtil.compareAny(va, vb, asc);
    }
    return comp;
  });
  return dataGroups;
}
//RowDataGroupの計算
const calcDataGroups = (dataGroups:RowDataGroup[]): RowDataGroup[] => {
  /*
  //賞味期限別を合計する
  const sumKigenDatas = (kigenDatas:KigenData[]): KigenData => {
    const kigenDataTotal:KigenData = {
      zaikoQty: 0,
    };
    if(kigenDatas && kigenDatas.length > 0) {
      kigenDatas.forEach(kigenData => {
        kigenDataTotal.zaikoQty = calcUtil.plus(kigenDataTotal.zaikoQty, kigenData.zaikoQty);
      });
    }
    return kigenDataTotal;
  }
  ;

  return dataGroups.map(dataGroup => {
    dataGroup.kigenDataTotal1 = sumKigenDatas(dataGroup.kigenDatas1);
    dataGroup.kigenDataTotal2 = sumKigenDatas(dataGroup.kigenDatas2);
    dataGroup.kigenDataTotal3 = sumKigenDatas(dataGroup.kigenDatas3);
    dataGroup.kigenDataTotal4 = sumKigenDatas(dataGroup.kigenDatas4);
    dataGroup.kigenDataTotal5 = sumKigenDatas(dataGroup.kigenDatas5);
    return dataGroup;
  });
  */
  //在庫の合計を利用する
  return dataGroups.map(dataGroup => {
    dataGroup.kigenDataTotal1 = dataGroup.centerItem.zaikoQty1 ? {zaikoQty: dataGroup.centerItem.zaikoQty1} : null;
    dataGroup.kigenDataTotal2 = dataGroup.centerItem.zaikoQty2 ? {zaikoQty: dataGroup.centerItem.zaikoQty2} : null;
    dataGroup.kigenDataTotal3 = dataGroup.centerItem.zaikoQty3 ? {zaikoQty: dataGroup.centerItem.zaikoQty3} : null;
    dataGroup.kigenDataTotal4 = dataGroup.centerItem.zaikoQty4 ? {zaikoQty: dataGroup.centerItem.zaikoQty4} : null;
    dataGroup.kigenDataTotal5 = dataGroup.centerItem.zaikoQty5 ? {zaikoQty: dataGroup.centerItem.zaikoQty5} : null;
    return dataGroup;
  });
}

//行情報に変換
const convertRowInfos = (dataGroups:RowDataGroup[], listSortOrder:CodeName, visibleRowsKey:string[]): [RowInfo[], number] => {
  const targetRowsKeys = arrayutil.and(rowKeys, visibleRowsKey);

  const rowInfos:RowInfo[] = [];
  let fixedRowsTop:number = 0;

  //明細行
  fixedRowsTop = rowInfos.length;

  let beforeDataGroup:RowDataGroup;
  let grouping;
  switch (listSortOrder?.group_code) {
    case 'item_center':
      grouping = 'item';
      break;
    case 'center_item':
      grouping = 'center';
      break;
    case 'center_tanaban':
      grouping = 'center';
      break;
    default:
      grouping = 'item';
      break;
  }
  let no = 0;
  let subno = 0;
  dataGroups.forEach(dataGroup => {
    if(rowInfos.length > 0) {
      rowInfos[rowInfos.length-1].rowIndexLast = true; //同一集計行内での最後のrowKeyフラグ
    }

    // グルーピング
    if(grouping == 'item' && (!beforeDataGroup || beforeDataGroup.centerItem.itemCD != dataGroup.centerItem.itemCD)) {
      no++;
      subno = 0;
      rowInfos.push({
        TP: "item",
        no: `${no}`,
        dataGroup: dataGroup,
      });
    }
    else if(grouping == 'center' && (!beforeDataGroup || beforeDataGroup.centerItem.centerCD != dataGroup.centerItem.centerCD)) {
      no++;
      subno = 0;
      rowInfos.push({
        TP: "center",
        no: `${no}`,
        dataGroup: dataGroup,
      });
    }

    //明細行
    subno++;
    let rowIndex = 0;
    targetRowsKeys.forEach((rowKey) => {
      switch (rowKey) {
        //期限日
        case "kigen":
          const maxkigenDatasSize = dataGroup.maxkigenDatasSize;
          for (let i = 0; i < maxkigenDatasSize; i++) {
            rowInfos.push({
              ...{
                TP: "kigen",
                no: `${no}`,
                subno: `${subno}`,
                dataGroup: dataGroup,
              }, 
              ...{
                rowKey: rowKey,
  
                rowIndex: rowIndex++,  //同一集計行内でのrowKeyのindex
                rowIndexLast: false, //同一集計行内での最後のrowKeyフラグ（仮置き）
  
                kigenData1: dataGroup.kigenDatas1 && dataGroup.kigenDatas1.length > i ? dataGroup.kigenDatas1[i] : null,
                kigenData2: dataGroup.kigenDatas2 && dataGroup.kigenDatas2.length > i ? dataGroup.kigenDatas2[i] : null,
                kigenData3: dataGroup.kigenDatas3 && dataGroup.kigenDatas3.length > i ? dataGroup.kigenDatas3[i] : null,
                kigenData4: dataGroup.kigenDatas4 && dataGroup.kigenDatas4.length > i ? dataGroup.kigenDatas4[i] : null,
                kigenData5: dataGroup.kigenDatas5 && dataGroup.kigenDatas5.length > i ? dataGroup.kigenDatas5[i] : null,
              }
            });
          }
          break;
        //合計
        case "total":
          rowInfos.push({
            ...{
              TP: "total",
              no: `${no}`,
              subno: `${subno}`,
              dataGroup: dataGroup,
            }, 
            ...{
              rowKey: rowKey,

              rowIndex: rowIndex++,  //同一集計行内でのrowKeyのindex
              rowIndexLast: false, //同一集計行内での最後のrowKeyフラグ（仮置き）

              kigenData1: dataGroup.kigenDataTotal1,
              kigenData2: dataGroup.kigenDataTotal2,
              kigenData3: dataGroup.kigenDataTotal3,
              kigenData4: dataGroup.kigenDataTotal4,
              kigenData5: dataGroup.kigenDataTotal5,
            }
          });
          break;
      
        default:
          break;
      }
    })

    beforeDataGroup = dataGroup;
  });
  if(rowInfos.length > 0) {
    rowInfos[rowInfos.length-1].rowIndexLast = true; //同一集計行内での最後のrowKeyフラグ
  }

  return [rowInfos, fixedRowsTop];
}
//配列データに変換
const convertRows = (rowInfos:RowInfo[], colRowModel:ZaikoRealColRowModel, visibleAttributes: string[][]): any[][] => {
  return rowInfos.map((rowInfo) => convertRow(rowInfo, colRowModel, visibleAttributes));
}
//配列データに変換
const convertRow = (rowInfo:RowInfo, colRowModel:ZaikoRealColRowModel, visibleAttributes: string[][]): any[] => {
  //set No.
  const dataGroup:RowDataGroup = rowInfo.dataGroup;
  return colRowModel.colKeys.map(colKey => {

    //グループ行
    if(rowInfo.TP == "center" || rowInfo.TP == "item") {
      switch (colKey) {
        case "no":
          return rowInfo.no;
        case "attribute1Label":
          if(rowInfo.TP == "center") {
            return `${dataGroup.centerItem.centerCD} ${dataGroup.centerItem.centerNM}`;
          }
          if(rowInfo.TP == "item") {
            return `${dataGroup.centerItem.itemCD} ${dataGroup.centerItem.itemNM}`;
          }
          return null;
        case "attribute1Value":
        case "attribute2Label":
        case "attribute2Value":
          return null;
        case "rowHeader":
          return null;
        case "teibanNum":
          return null;
        case "teibanNMs":
          return null;

        case "nohinbi":
          return null;
        case "tokuisakiNum":
          return null;
        case "tokuisakiNMs":
          return null;
        case "nyukaDate1":
          return null;
        case "zaikoKigen1":
          return null;
        case "zaikoQty1":
          return null;
        case "nyukaDate2":
          return null;
        case "zaikoKigen2":
          return null;
        case "zaikoQty2":
          return null;
        case "nyukaDate3":
          return null;
        case "zaikoKigen3":
          return null;
        case "zaikoQty3":
          return null;
        case "nyukaDate4":
          return null;
        case "zaikoKigen4":
          return null;
        case "zaikoQty4":
          return null;
        case "nyukaDate5":
          return null;
        case "zaikoKigen5":
          return null;
        case "zaikoQty5":
          return null;
       default: {
          return null;
        }
      }
    }
    else {

      //明細行
      switch (colKey) {
        case "no":
          return `${rowInfo.no}-${rowInfo.subno}`;
        case "attribute1Label":
        case "attribute2Label":
          {
            let attrIndex = colKey == "attribute1Label" ? 0 : 1;
            let attrKey = visibleAttributes[attrIndex][rowInfo.rowIndex];
            let attr = centerItemAttributes[attrIndex].find(attr => attr.code == attrKey);
            return attr ? attr.ryaku : null;
          }
        case "teibanNum":
          return dataGroup.centerItem?.teibanNum;
        case "teibanNMs":
          return dataGroup.centerItem?.teibanNMs;
        case "attribute1Value":
        case "attribute2Value":
          {
            let attrIndex = colKey == "attribute1Value" ? 0 : 1;
            let attrKey = visibleAttributes[attrIndex][rowInfo.rowIndex];
            switch(attrKey) {
              case "bumon": //"部門"
                return `${dataGroup.centerItem.bumonCD} ${dataGroup.centerItem.bumonNM}`;
              case "bumonCD": //"部門"
                return dataGroup.centerItem.bumonCD;
              case "bumonNM": //"部門"
                return dataGroup.centerItem.bumonNM;
              case "center": //"倉庫"
                return `${dataGroup.centerItem.centerCD} ${dataGroup.centerItem.centerNM}`;
              case "centerCD": //"倉庫"
                return dataGroup.centerItem.centerCD;
              case "centerNM": //"倉庫"
                return dataGroup.centerItem.centerNM;
              case "itemCD": //"商品CD"
                return `${dataGroup.centerItem.itemCD}`;
              case "itemNM": //"商品名"
                return `${dataGroup.centerItem.itemNM}`;
              case "janCD": //"JAN"
                return `${dataGroup.centerItem.janCD}`;
              case "maker": //"メーカー"
                return `${dataGroup.centerItem.makerCD} ${dataGroup.centerItem.makerNM}`;
              case "category": //"商品カテゴリ"
                return `${dataGroup.centerItem.categoryCD} ${dataGroup.centerItem.categoryNM}`;
              case "pb": //"PB区分"
                return !dataGroup.centerItem.pbKbnCD ? '' : `${dataGroup.centerItem.pbKbnCD} ${dataGroup.centerItem.pbKbnNM}`;
              case "blIrisu": //"BL入数"
                return `${formatterN0.format(dataGroup.centerItem.blIrisu)}`;
              case "csIrisu": //"CS入数"
                return `${formatterN0.format(dataGroup.centerItem.csIrisu)}`;
              case "capacity": //"内容量"
                return `${dataGroup.centerItem.capacity}`;
              case "bestBefore": //"賞味期間"
                return `${formatterN0.format(dataGroup.centerItem.bestBefore)}日`;
              case "teika": //"定価"
                return `${formatterN0.format(dataGroup.centerItem.teika)}円`;
              case "tatene": //"建値"
                return `${formatterP00.format(dataGroup.centerItem.tatene)}円`;
              case "tanaban": //"棚番"
                return dataGroup.centerItem.tanaban;
              default:
                return null;
            }
          }
        case "rowHeader":
          return headersRow[rowInfo.rowKey];
        case "nohinbi":
          return dataGroup.syukkaData?.nohinbi;
        case "tokuisakiNum":
          return dataGroup.syukkaData?.tokuisakiNum;
        case "tokuisakiNMs":
          return dataGroup.syukkaData?.tokuisakiNMs;
        case "nyukaDate1":
          return rowInfo.TP == 'total' && rowInfo.kigenData1?.zaikoQty ? '合計' : rowInfo.kigenData1?.nyukaDate;
        case "zaikoKigen1":
          return rowInfo.TP == 'total' && rowInfo.kigenData1?.zaikoQty ? '合計' : rowInfo.kigenData1?.zaikoKigen;
        case "zaikoQty1":
          return rowInfo.kigenData1?.zaikoQty;
        case "nyukaDate2":
          return rowInfo.TP == 'total' && rowInfo.kigenData2?.zaikoQty ? '合計' : rowInfo.kigenData2?.nyukaDate;
        case "zaikoKigen2":
          return rowInfo.TP == 'total' && rowInfo.kigenData2?.zaikoQty ? '合計' : rowInfo.kigenData2?.zaikoKigen;
        case "zaikoQty2":
          return rowInfo.kigenData2?.zaikoQty;
        case "nyukaDate3":
          return rowInfo.TP == 'total' && rowInfo.kigenData3?.zaikoQty ? '合計' : rowInfo.kigenData3?.nyukaDate;
        case "zaikoKigen3":
          return rowInfo.TP == 'total' && rowInfo.kigenData3?.zaikoQty ? '合計' : rowInfo.kigenData3?.zaikoKigen;
        case "zaikoQty3":
          return rowInfo.kigenData3?.zaikoQty;
        case "nyukaDate4":
          return rowInfo.TP == 'total' && rowInfo.kigenData4?.zaikoQty ? '合計' : rowInfo.kigenData4?.nyukaDate;
        case "zaikoKigen4":
          return rowInfo.TP == 'total' && rowInfo.kigenData4?.zaikoQty ? '合計' : rowInfo.kigenData4?.zaikoKigen;
        case "zaikoQty4":
          return rowInfo.kigenData4?.zaikoQty;
        case "nyukaDate5":
          return rowInfo.TP == 'total' && rowInfo.kigenData5?.zaikoQty ? '合計' : rowInfo.kigenData5?.nyukaDate;
        case "zaikoKigen5":
          return rowInfo.TP == 'total' && rowInfo.kigenData5?.zaikoQty ? '合計' : rowInfo.kigenData5?.zaikoKigen;
        case "zaikoQty5":
          return rowInfo.kigenData5?.zaikoQty;
        default: {
          return dataGroup[colKey];
        }

      }

    }
  });
}

//マージを作成
const createMergeCells = (rowInfos:RowInfo[], colRowModel:ZaikoRealColRowModel): {row: number, col: number, rowspan: number, colspan: number}[] => {
  let mergeCells:{row: number, col: number, rowspan: number, colspan: number}[] = [];
  if(rowInfos) {
    let colF = colRowModel.colFromKey('attribute1Label');
    let colT = colRowModel.colFromKey('attribute2Value');
    rowInfos.map((rowInfo, index) => {
      if(rowInfo.TP == 'center' || rowInfo.TP == 'item') {
        mergeCells.push({row: index, col: colF, rowspan:1, colspan:colT-colF+1});
      }
    });
  }
  return mergeCells.length == 0 ? null : mergeCells;
}


export const getOptionLabel = (option: CodeName) => {
  return option && option.name ? (option.code + ' ' + option.name) : "";
}

const createSliceContent = (name:string) => createSlice({
  name: name,
  initialState,
  reducers: createReducerContent(),
});

//Page Slice Export
//zaikoRealTmp
export const zaikoRealTmpSlice = createSliceContent("zaikoRealTmp");
