import moment from "moment";
moment.updateLocale("ja", {
  months: ["1月","2月","3月","4月","5月","6月","7月","8月","9月","10月","11月","12月",],
  weekdays: ["日曜日", "月曜日", "火曜日", "水曜日", "木曜日", "金曜日", "土曜日"],
  weekdaysShort: ["日", "月", "火", "水", "木", "金", "土"]
});

//ヘッダー列
export const headerColKeys = [
  'no',
  'attribute1Label', 
  'attribute1Value', 
  'attribute2Label', 
  'attribute2Value', 
  'teibanNum',
  'teibanNMs',
];
//行ヘッダー列
export const rowHeaderColKeys = [
  // 'rowHeader', 
];
//ヘッダー列
export const bodyColKeys = [
  'nohinbi',	//最終納品日
  'tokuisakiNum', //直近1カ月出荷得意先数
  'tokuisakiNMs', //直近1カ月出荷得意先名（カンマ区切り複数）

  'nyukaDate1',	  //入荷日1(一般)
  'zaikoKigen1',	//賞味期限1(一般)
  'zaikoQty1',	  //在庫数1(一般)
  'nyukaDate2',	  //入荷日2(特売)
  'zaikoKigen2',	//賞味期限2(特売)
  'zaikoQty2',	  //在庫数2(特売)
  'nyukaDate3',	  //入荷日3(予約)
  'zaikoKigen3',	//賞味期限3(予約)
  'zaikoQty3',	  //在庫数3(予約)
  'nyukaDate4',	  //入荷日4(出切)
  'zaikoKigen4',	//賞味期限4(出切)
  'zaikoQty4',	  //在庫数4(出切)
  'nyukaDate5',	  //入荷日5(返品)
  'zaikoKigen5',	//賞味期限5(返品)
  'zaikoQty5',	  //在庫数5(返品)
];

export const rowKeys:string[] = [
  'kigen',
  'total',
];

export const headersCol = [
  {
    no : "No.",
    attribute1Label: "商品属性", attribute1Value: "商品属性", 
    attribute2Label: "倉庫属性", attribute2Value: "倉庫属性", 
    teibanNum: '定番',
    teibanNMs: '定番',

    rowHeader: "項目", 

    nohinbi: "最終",
    tokuisakiNum: '直近1カ月出荷',
    tokuisakiNMs: '直近1カ月出荷',

    nyukaDate1: "一般",
    zaikoKigen1: "一般",
    zaikoQty1: "一般",
    // nyukaDate1: "一般/良品",
    // zaikoKigen1: "一般/良品",
    // zaikoQty1: "一般/良品",
    nyukaDate2: "特売",
    zaikoKigen2: "特売",
    zaikoQty2: "特売",
    nyukaDate3: "予約",
    zaikoKigen3: "予約",
    zaikoQty3: "予約",
    nyukaDate4: "出切",
    zaikoKigen4: "出切",
    zaikoQty4: "出切",
    nyukaDate5: "返品",
    zaikoKigen5: "返品",
    zaikoQty5: "返品",
  },
  {
    no : "",
    attribute1Label: "項目名", attribute1Value: "値", 
    attribute2Label: "項目名", attribute2Value: "値", 
    teibanNum: '得意先数',
    teibanNMs: '得意先名',

    rowHeader : "名称", 

    nohinbi: "納品日",
    tokuisakiNum: '得意先数',
    tokuisakiNMs: '得意先名',

    nyukaDate1: "入荷日",
    zaikoKigen1: "賞味期限",
    zaikoQty1: "在庫BL数",
    nyukaDate2: "入荷日",
    zaikoKigen2: "賞味期限",
    zaikoQty2: "在庫BL数",
    nyukaDate3: "入荷日",
    zaikoKigen3: "賞味期限",
    zaikoQty3: "在庫BL数",
    nyukaDate4: "入荷日",
    zaikoKigen4: "賞味期限",
    zaikoQty4: "在庫BL数",
    nyukaDate5: "入荷日",
    zaikoKigen5: "賞味期限",
    zaikoQty5: "在庫BL数",
  },
];

export const headersRow = {
  kigen: '賞味期限',
  total: '合計',
};

export const selectableCol =
{
  no : false,
  attribute1Label: true, 
  attribute1Value: false, 
  attribute2Label: true, 
  attribute2Value: false, 
  teibanNum: true,
  teibanNMs: true,
  rowHeader : false, 

  nohinbi: true, 
  tokuisakiNum: true,
  tokuisakiNMs: true,

  nyukaDate1: true, 
  zaikoKigen1: false, 
  zaikoQty1: false, 
  nyukaDate2: true, 
  zaikoKigen2: false, 
  zaikoQty2: false, 
  nyukaDate3: true, 
  zaikoKigen3: false, 
  zaikoQty3: false, 
  nyukaDate4: true, 
  zaikoKigen4: false, 
  zaikoQty4: false, 
  nyukaDate5: true, 
  zaikoKigen5: false, 
  zaikoQty5: false, 
}
export const selectableRow = {
  kigen: true,
  total: true,
};



export const headerColClasses = [
  {
    no : "zaikoreal_H",
    attribute1Label: "zaikoreal_H", 
    attribute1Value: "zaikoreal_H", 
    attribute2Label: "zaikoreal_H", 
    attribute2Value: "zaikoreal_H", 
    teibanNum: 'zaikoreal_T',
    teibanNMs: 'zaikoreal_T',

    rowHeader : "zaikoreal_H",

    nohinbi: "zaikoreal_H_N_noBorder",  //下線なし
    tokuisakiNum: "zaikoreal_H_N",
    tokuisakiNMs: "zaikoreal_H_N",

    nyukaDate1: "zaikoreal_H_Z",
    zaikoKigen1: "zaikoreal_H_Z",
    zaikoQty1: "zaikoreal_H_Z",
    nyukaDate2: "zaikoreal_H_Z",
    zaikoKigen2: "zaikoreal_H_Z",
    zaikoQty2: "zaikoreal_H_Z",
    nyukaDate3: "zaikoreal_H_Z",
    zaikoKigen3: "zaikoreal_H_Z",
    zaikoQty3: "zaikoreal_H_Z",
    nyukaDate4: "zaikoreal_H_Z",
    zaikoKigen4: "zaikoreal_H_Z",
    zaikoQty4: "zaikoreal_H_Z",
    nyukaDate5: "zaikoreal_H_Z",
    zaikoKigen5: "zaikoreal_H_Z",
    zaikoQty5: "zaikoreal_H_Z",
  },
  {
    no : "zaikoreal_H",
    attribute1Label: "zaikoreal_H", 
    attribute1Value: "zaikoreal_H", 
    attribute2Label: "zaikoreal_H", 
    attribute2Value: "zaikoreal_H", 
    teibanNum: 'zaikoreal_T',
    teibanNMs: 'zaikoreal_T',

    rowHeader : "zaikoreal_H", 

    nohinbi: "zaikoreal_H_N",
    tokuisakiNum: "zaikoreal_H_N",
    tokuisakiNMs: "zaikoreal_H_N",

    nyukaDate1: "zaikoreal_H_Z",
    zaikoKigen1: "zaikoreal_H_Z",
    zaikoQty1: "zaikoreal_H_Z",
    nyukaDate2: "zaikoreal_H_Z",
    zaikoKigen2: "zaikoreal_H_Z",
    zaikoQty2: "zaikoreal_H_Z",
    nyukaDate3: "zaikoreal_H_Z",
    zaikoKigen3: "zaikoreal_H_Z",
    zaikoQty3: "zaikoreal_H_Z",
    nyukaDate4: "zaikoreal_H_Z",
    zaikoKigen4: "zaikoreal_H_Z",
    zaikoQty4: "zaikoreal_H_Z",
    nyukaDate5: "zaikoreal_H_Z",
    zaikoKigen5: "zaikoreal_H_Z",
    zaikoQty5: "zaikoreal_H_Z",
  },
];
export const headerRowClasses = {
  kigen: 'zaikoreal_RH',
  total: 'zaikoreal_RH_total',
};

export const colWidths =
{
  no : 50,
  attribute1Label: 70, 
  attribute1Value: 120, 
  attribute2Label: 70, 
  attribute2Value: 120, 
  teibanNum: 80,
  teibanNMs: 240,

  rowHeader : 100, 

  nohinbi: 80,
  tokuisakiNum: 80,
  tokuisakiNMs: 240,

  nyukaDate1: 80,
  zaikoKigen1: 80,
  zaikoQty1: 50,
  nyukaDate2: 80,
  zaikoKigen2: 80,
  zaikoQty2: 50,
  nyukaDate3: 80,
  zaikoKigen3: 80,
  zaikoQty3: 50,
  nyukaDate4: 80,
  zaikoKigen4: 80,
  zaikoQty4: 50,
  nyukaDate5: 80,
  zaikoKigen5: 80,
  zaikoQty5: 50,
};

export const colDataType =
{
  no : {type: 'text', readOnly: true,},
  attribute1Label: {type: 'text', readOnly: true, className: 'htRight',}, 
  attribute1Value: {type: 'text', readOnly: true,}, 
  attribute2Label: {type: 'text', readOnly: true, className: 'htRight',}, 
  attribute2Value: {type: 'text', readOnly: true,}, 
  teibanNum: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, },
  teibanNMs: { type: 'text', readOnly: true, },

  rowHeader: {type: 'text', readOnly: true,}, 

  nohinbi: {type: 'text', readOnly: true, className: 'htCenter',}, 
  tokuisakiNum: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, },
  tokuisakiNMs: { type: 'text', readOnly: true, },

  nyukaDate1: {type: 'text', readOnly: true, className: 'htCenter',}, 
  zaikoKigen1: {type: 'text', readOnly: true, className: 'htCenter',}, 
  zaikoQty1: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0.0', }, },
  nyukaDate2: {type: 'text', readOnly: true, className: 'htCenter',}, 
  zaikoKigen2: {type: 'text', readOnly: true, className: 'htCenter',}, 
  zaikoQty2: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0.0', }, },
  nyukaDate3: {type: 'text', readOnly: true, className: 'htCenter',}, 
  zaikoKigen3: {type: 'text', readOnly: true, className: 'htCenter',}, 
  zaikoQty3: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0.0', }, },
  nyukaDate4: {type: 'text', readOnly: true, className: 'htCenter',}, 
  zaikoKigen4: {type: 'text', readOnly: true, className: 'htCenter',}, 
  zaikoQty4: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0.0', }, },
  nyukaDate5: {type: 'text', readOnly: true, className: 'htCenter',}, 
  zaikoKigen5: {type: 'text', readOnly: true, className: 'htCenter',}, 
  zaikoQty5: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0.0', }, },
};

export const rowDataType = {};

export const rowRendererName =
{
};

//列定義情報（動的パラメータ）
interface ZaikoRealColRowModelInterface {
  //列固定
  fixedColumnsLeft: number,

  //テーブルの各列のキー
  colKeys: string[],
  //テーブルの各行のキー
  rowKeys: string[],
}

export class ZaikoRealColRowModel implements ZaikoRealColRowModelInterface {
  //列固定
  fixedColumnsLeft: number;
  //テーブルの各列のキー
  colKeys: string[];
  //テーブルの各列のキー
  rowKeys: string[];
  //ヘッダー
  nestedHeaders; //any[][]
  nestedHeadersBackup; //any[][]
  //ヘッダー区切り
  nestedHeadersGroupIndex: number[];
  //テーブルの各列の逆引き用マップ
  colKeysMap;
  //テーブルの各行の逆引き用マップ
  rowKeysMap;

  constructor(param: ZaikoRealColRowModelInterface) {
    this.fixedColumnsLeft = param.fixedColumnsLeft;
    this.colKeys = param.colKeys;
    this.rowKeys = param.rowKeys;

    //テーブルの各列のキー逆引き
    const colKeysMap = {};
    param.colKeys.forEach((colKey, index) => {
      colKeysMap[colKey] = index;
    });
    this.colKeysMap = colKeysMap;
    //テーブルの各行のキー逆引き
    const rowKeysMap = {};
    param.rowKeys.forEach((rowKey, index) => {
      rowKeysMap[rowKey] = index;
    });
    this.rowKeysMap = rowKeysMap;

    //nestedHeaders初期化
    this.initNestedHeaders();
  }

  //nestedHeaders初期化
  initNestedHeaders() {
    //nestedHeaders
    const nestedHeaders = [];
    const nestedHeadersGroupIndex = [];
    // const customBorders = [];
    headersCol.forEach((header, index) => {
      const lastHeader = index == headersCol.length - 1;
      const nestedHeader = [];

      let colspan=0;
      let preHeaderVal = null;
      this.colKeys.forEach((colKey) => {
        const headerVal = header[colKey];
        if(lastHeader) {
          nestedHeader.push(headerVal);
        }
        else {
          if(preHeaderVal !== null && preHeaderVal != headerVal){
            nestedHeader.push(colspan == 1 ? preHeaderVal : {label: preHeaderVal, colspan: colspan});
            colspan=0;
          }
          colspan++;
          preHeaderVal = headerVal;
        }
        //0行目でグループ
        if(index == 0) {
          nestedHeadersGroupIndex.push(colspan-1);
        }
      });
      if(!lastHeader && colspan > 0) {
        nestedHeader.push(colspan == 1 ? preHeaderVal : {label: preHeaderVal, colspan: colspan});
      }

      nestedHeaders.push(nestedHeader);
    });

    this.nestedHeaders = nestedHeaders;
    this.nestedHeadersBackup = nestedHeaders.map(nestedHeadersSub => [...nestedHeadersSub]);
    this.nestedHeadersGroupIndex = nestedHeadersGroupIndex;
  }

  //function
  keyFromCol(column:number):string {
    return this.colKeys[column];
  }
  keyFromCols(columns:number[]):string[] {
    const vals:string[] = [];
    columns.forEach(column => vals.push(this.colKeys[column]))
    return vals;
  }
  colFromKey(colKey:string):number {
    return this.colKeysMap[colKey];
  }
  colFromKeys(colKeys:string[]):number[] {
    const vals:number[] = [];
    colKeys.forEach(colKey => vals.push(this.colKeysMap[colKey]))
    return vals;
  }
  //function
  keyFromRow(row:number):string {
    const rowKeys = this.rowKeys;
    return rowKeys[row];
  }
  keyFromRows(rows:number[]):string[] {
    const rowKeys = this.rowKeys;
    const vals:string[] = [];
    rows.forEach(row => vals.push(rowKeys[row]))
    return vals;
  }
  rowFromKey(rowKey:string):number {
    const rowKeysMap = this.rowKeysMap;
    return rowKeysMap[rowKey];
  }
  rowFromKeys(rowKeys:string[]):number[] {
    const rowKeysMap = this.rowKeysMap;
    const vals:number[] = [];
    rowKeys.forEach(rowKey => vals.push(rowKeysMap[rowKey]))
    return vals;
  }
}

//ZaikoReal用の列モデル
export const zaikoRealColRowModelDefault:ZaikoRealColRowModel = new ZaikoRealColRowModel({
  //列固定
  fixedColumnsLeft: headerColKeys.length + rowHeaderColKeys.length,

  //テーブルの各列のキー
  colKeys: [
    ...headerColKeys,
    ...rowHeaderColKeys,
    ...bodyColKeys,
  ],
  rowKeys: rowKeys,
});
