import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CodeName } from "@/store/common";

//Page State
export type State = {
  //倉庫
  center: CodeName | null,
  //企業グループ1
  group1: CodeName | null,

  orderUsersSort: { key: string, asc: boolean },
  makerSort: { key: string, asc: boolean },
  group1NamesSort: { key: string, asc: boolean },
  tokuisakisSort: { key: string, asc: boolean },
};

export const initialState: State = {
  //倉庫
  center: null,
  //企業グループ1
  group1:  null,

  orderUsersSort: {
    key: "MAD",
    asc: true,
  },
  makerSort: {
    key: "MKCD",
    asc: true,
  },

  group1NamesSort: {
    key: "G1CD",
    asc: true,
  },
  tokuisakisSort: {
    key: "TKCD",
    asc: true,
  },
};

//Page Slice
export const tokubaiSettingSaveSlice = createSlice({
  name: "tokubaiSettingSave",
  initialState,
  reducers: {
    //componentDidMount
    initOnDidMount(state:State) {
      //追加属性等はlocalstorageから復元したときにundefinedになっている場合があるためチェックする
      if (!state.center) {
        state.center = initialState.center;
      }
      if (!state.group1) {
        state.group1 = initialState.group1;
      }
      if(!state.orderUsersSort || !state.orderUsersSort.key) {
        state.orderUsersSort = initialState.orderUsersSort;
      }
      if(!state.makerSort || !state.makerSort.key) {
        state.makerSort = initialState.makerSort;
      }

      if(!state.group1NamesSort || !state.group1NamesSort.key) {
        state.group1NamesSort = initialState.group1NamesSort;
      }
      if (!state.tokuisakisSort || !state.tokuisakisSort.key) {
        state.tokuisakisSort = initialState.tokuisakisSort;
      }
    },

    // Option Center
    setCenter(state:State, action: PayloadAction<CodeName>) {
      state.center = action.payload;
    },
    setCenterList(state:State, action: PayloadAction<CodeName[]>) {
      const centerList = action.payload;
      let center =
        state.center && state.center.code
          ? centerList.find((center) => center.code == state.center.code)
          : null;
      if (!center) {
        center = centerList[0];
      }
      state.center = center;
    },
    // Option Group1
    setGroup1(state: State, action: PayloadAction<CodeName>) {
      state.group1 = action.payload;
    },
    setGroup1List(state: State, action: PayloadAction<CodeName[]>) {
      const group1List = action.payload;
      let group1 =
        state.group1 && state.group1.code
          ? group1List.find((group1) => group1.code == state.group1.code)
          : null;
      if (!group1) {
        group1 = group1List[0];
      }
      state.group1 = group1;
    },
    setOrderUsersSort(state:State, action: PayloadAction<{ sortKey: string, sortAsc: boolean }>) {
      console.log('setOrderUsersSort');
      const key = action.payload.sortKey;
      const asc = action.payload.sortAsc;
      state.orderUsersSort = {
        key: key,
        asc: asc,
      }
    },
    setMakerSort(state:State, action: PayloadAction<{ sortKey: string, sortAsc: boolean }>) {
      console.log('setMakerSort');
      const key = action.payload.sortKey;
      const asc = action.payload.sortAsc;
      state.makerSort = {
        key: key,
        asc: asc,
      }
    },

    setTokuisakisSort(state:State, action: PayloadAction<{ sortKey: string, sortAsc: boolean }>) {
      console.log('setTokuisakisSort');
      const key = action.payload.sortKey;
      const asc = action.payload.sortAsc;
      state.tokuisakisSort = {
        key: key,
        asc: asc,
      }
    },
  },
});
