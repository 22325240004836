import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CodeName } from "../common";

export const displaySettingsNameList = [
  'checked',
];
export type DisplaySettings = {
  checked?: string[],  //列項目選択
};

//Page State
export type CommonSaveState = {
  favoriteDisplaySettings: { title: CodeName, params: DisplaySettings }[] | null,
};

export const initialCommonState: CommonSaveState = {
  favoriteDisplaySettings: [],
};

//Page Slice
export type CommonSaveReducer = {
  initOnDidMount: (state: CommonSaveState) => void,
  resetOnWillUnmount: (state: CommonSaveState) => void,
  setFavoriteDisplaySettings: (state: CommonSaveState, action: PayloadAction<{ title: CodeName, params: DisplaySettings }[]>) => void,
}

const createReducerContent = (name: string, initialState: CommonSaveState): CommonSaveReducer => {
  return {
    //componentDidMount
    initOnDidMount(state: CommonSaveState) {
      if (state.favoriteDisplaySettings === undefined) {
        state.favoriteDisplaySettings = initialState.favoriteDisplaySettings;
      }
    },
    //componentWillUnmount
    resetOnWillUnmount(state: CommonSaveState) {
    },
    setFavoriteDisplaySettings(state: CommonSaveState, action: PayloadAction<{ title: CodeName, params: DisplaySettings }[]>) {
      state.favoriteDisplaySettings = action.payload;
    },
  }
};

const createSliceContent = (name: string, initialState: CommonSaveState) => createSlice({
  name: name,
  initialState: initialState,
  reducers: createReducerContent(name, initialState),
});

//Page Slice Export
//commonSaveSlice
export const commonSaveSlice = createSliceContent("commonSave", initialCommonState);
