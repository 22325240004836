import moment from 'moment';

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RequestParam } from "@/assets/apitype/outputDataListShodan";
import { CodeName } from "@/store/common";

//Page State
export type OutputDataShodanSaveState = {
  group2s: CodeName[],
  group1s: CodeName[],
  //ベンダーコード
  vendorCode: string | null,
  honbuCode: string | null,
  //部門
  bu: CodeName | null,
  //倉庫
  center: CodeName | null,
  // 商品コード
  item: string | null,

  //検索終了した条件
  requestedParamShodan: RequestParam,

};

export const initialState: OutputDataShodanSaveState = {
  group2s: [],
  group1s: [],
  //ベンダーコード
  vendorCode: '',
  honbuCode: '',
  //部門
  bu: null,
  //倉庫
  center: null,
  //商品コード
  item: '',

  //検索終了した条件
  requestedParamShodan: null,

};

//Page Slice
const createSliceContent = (name: string) => createSlice({
  name: name,
  initialState,
  reducers: {
    //componentDidMount
    initOnDidMount(state: OutputDataShodanSaveState) {
      //追加属性等はlocalstorageから復元したときにundefinedになっている場合があるためチェックする

      if(state.group2s === undefined) {
        state.group2s = initialState.group2s;
      }
      if(state.group1s === undefined) {
        state.group1s = initialState.group1s;
      }
      if (!state.vendorCode) {
        state.vendorCode = initialState.vendorCode;
      }
      if (!state.honbuCode) {
        state.honbuCode = initialState.honbuCode;
      }
      if (!state.bu) {
        state.bu = initialState.bu;
      }
      if (!state.center) {
        state.center = initialState.center;
      }
      if (!state.item || state.item.length == 0) {
        state.item = initialState.item;
      }
      if (state.requestedParamShodan === undefined) {
        state.requestedParamShodan = initialState.requestedParamShodan;
      }
    },
    setGroup2s(state: OutputDataShodanSaveState, action: PayloadAction<CodeName[]>) {
      state.group2s = action.payload;
    },
    setGroup2List(state: OutputDataShodanSaveState, action: PayloadAction<CodeName[]>) {
      state.group2s = getData(state.group2s, action.payload);
    },
    setGroup1s(state: OutputDataShodanSaveState, action: PayloadAction<CodeName[]>) {
      state.group1s = action.payload;
    },
    setGroup1List(state: OutputDataShodanSaveState, action: PayloadAction<CodeName[]>) {
      state.group1s = getData(state.group1s, action.payload);
    },
    setVendorCode(state: OutputDataShodanSaveState, action: PayloadAction<string>) {
      state.vendorCode = action.payload;
    },
    setHonbuCode(state: OutputDataShodanSaveState, action: PayloadAction<string>) {
      state.honbuCode = action.payload;
    },
    // Option Bu
    setBu(state: OutputDataShodanSaveState, action: PayloadAction<CodeName>) {
      state.bu = action.payload;
    },
    setBuList(state: OutputDataShodanSaveState, action: PayloadAction<CodeName[]>) {
      const buList = action.payload;
      let bu = state.bu && state.bu.code ? buList.find((bu) => bu.code == state.bu.code) : null;
      if (!bu) {
        bu = buList[0];
      }
      state.bu = bu;
    },
    // Option Center
    setCenter(state: OutputDataShodanSaveState, action: PayloadAction<CodeName>) {
      state.center = action.payload;
    },
    setCenterList(state: OutputDataShodanSaveState, action: PayloadAction<CodeName[]>) {
      const centerList = action.payload;
      let center = state.center && state.center.code ? centerList.find((center) => center.code == state.center.code) : null;
      if (!center) {
        center = centerList[0];
      }
      state.center = center;
    },
    setItem(state: OutputDataShodanSaveState, action: PayloadAction<string>) {
      state.item = action.payload;
    },
    clearOption(state: OutputDataShodanSaveState, action: PayloadAction<{
      buList: CodeName[],
      centerList: CodeName[],
    }>) {
      state.group2s = initialState.group2s;
      state.group1s = initialState.group1s;
      state.vendorCode = initialState.vendorCode;
      state.honbuCode = initialState.honbuCode;
      state.bu = action.payload.buList[0];
      state.center = action.payload.centerList[0];
      state.item = initialState.item;
    },

    //検索条件
    setRequestedParamShodan(state: OutputDataShodanSaveState, action: PayloadAction<RequestParam>) {
      state.requestedParamShodan = action.payload;
    },
  },
});

const getData = (target: CodeName[], list: CodeName[]): CodeName[] => {
  let datas = [];
  if (target && target.length > 0) {
    const codes: string[] = target.filter(data => !(!data)).map(data => data.code);
    datas = list.filter(data => codes.indexOf(data.code) !== -1)
      .filter((value, index, self) => self.findIndex(s => s.code == value.code) === index);
  }
  return datas;
};

//Page Slice Export
//outputDataShodanSave
export const outputDataShodanSaveSlice = createSliceContent("outputDataShodanSave");
