import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type UserSaveState = {
  url: string,
};

const initialState: UserSaveState = {
  url: null,
};

export const userSaveSlice = createSlice({
  name: "userSave",
  initialState,
  // HACK: reducerは肥大化したらファイル分けたくなるかも
  reducers: {
    //componentDidMount
    initOnDidMount(state:UserSaveState) {
    },
    //componentWillUnmount
    resetOnWillUnmount() {
      //pass
    },
    setUrl(state:UserSaveState, action: PayloadAction<string>) {
      state.url = action.payload;
    },
  },
});