import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CodeName, YM } from "@/store/common";

//Page State
export type OutputDataCenterSaveState = {
  //年月
  ym: YM | null,
  //企業グループ１
  group1: CodeName | null,
  syuyaku: CodeName | null,
};

export const initialState: OutputDataCenterSaveState = {
  //年月
  ym: null,
  //企業グループ１
  group1: null,
  syuyaku: null,
};

//Page Slice
const createSliceContent = (name: string) => createSlice({
  name: name,
  initialState,
  reducers: {
    //componentDidMount
    initOnDidMount(state: OutputDataCenterSaveState) {
      //追加属性等はlocalstorageから復元したときにundefinedになっている場合があるためチェックする
      if (!state.ym) {
        state.ym = initialState.ym;
      }
      if (!state.group1) {
        state.group1 = initialState.group1;
      }
      if (!state.syuyaku) {
        state.syuyaku = initialState.syuyaku;
      }
    },
    setYM(state: OutputDataCenterSaveState, action: PayloadAction<YM>) {
      state.ym = action.payload;
    },
    setYMList(state: OutputDataCenterSaveState, action: PayloadAction<YM[]>) {
      const ymList = action.payload;
      let ym = state.ym && state.ym.code ? ymList.find((ym) => ym.code == state.ym.code) : null;
      if (!ym) {
        ym = ymList[0];
      }
      state.ym = ym;
    },
    setGroup1(state: OutputDataCenterSaveState, action: PayloadAction<CodeName>) {
      state.group1 = action.payload;
    },
    setGroup1List(state: OutputDataCenterSaveState, action: PayloadAction<CodeName[]>) {
      state.group1 = action.payload.find(cn => cn.code == state.group1?.code);
    },
    setSyuyaku(state: OutputDataCenterSaveState, action: PayloadAction<CodeName>) {
      state.syuyaku = action.payload;
    },
    setSyuyakuList(state: OutputDataCenterSaveState, action: PayloadAction<CodeName[]>) {
      state.syuyaku = action.payload.find(cn => cn.code == state.syuyaku?.code);
    },
    clearOption(state: OutputDataCenterSaveState, action: PayloadAction<{
      ymList: YM[],
    }>) {
      Object.assign(state, {
        ym: action.payload.ymList[0],
        group1: initialState.group1,
        syuyaku: initialState.syuyaku,
      });
    },
  },
});

//Page Slice Export
export const outputDataCenterSaveSlice = createSliceContent("outputDataCenterSave");
