//行固定
export const fixedRowsTop = 1;
//列固定
export const fixedColumnsLeft = 2;

//テーブルの各列のキー
export const colKeys: string[] = [
  'KKAM', 'CNR',
  'SQTY1', 'SQTY2', 'SQTY3', 'SQTY', 'SQTYR', 'HQTY1', 'HQTY2', 'HQTY3', 'HQTY4', 'HQTY', 'HQTYR', 'RQTY', 'TQTY', 'YQTY', 'FQTY', 'AZKE', 'ZQTY',
  'SKIN1', 'SKIN2', 'SKIN3', 'SKIN', 'SKINR', 'HKIN1', 'HKIN2', 'HKIN3', 'HKIN4', 'HKIN', 'HKINR', 'RAMT', 'TAMT', 'YAMT', 'FAMT', 'AAMT', 'ZAMT',
];
//エクセルの数量シートで使用
export const quantityColKeys: string[] = [
  'KKAM', 'CNR',
  'SQTY1', 'SQTY2', 'SQTY3', 'SQTY', 'SQTY_PS', 'SQTYR', 'HQTY1', 'HQTY2', 'HQTY3', 'HQTY4', 'HQTY','HQTY_PS', 'HQTYR', 'RQTY', 'TQTY', 'YQTY', 'FQTY', 'AZKE','ZQTY','ZQTY_PS',
];
//エクセルの金額シートで使用
export const amountColKeys: string[] = [
  'KKAM', 'CNR',
  'SKIN1', 'SKIN2', 'SKIN3', 'SKIN', 'SKINR', 'HKIN1', 'HKIN2', 'HKIN3', 'HKIN4', 'HKIN', 'HKINR', 'RAMT', 'TAMT', 'YAMT', 'FAMT', 'AAMT', 'ZAMT',
];

//テーブルの各列のキー逆引き
const colKeysMap = {};
colKeys.forEach((colKey, index) => {
  colKeysMap[colKey] = index;
});

export const columnNames = [
  '倉庫名', '棚番',
  '1.一般', '2.特売', '3.予約', '仕入BL数', '構成比', '1.一般', '2.特売', '3.予約', '4.出切', '販売BL数', '消化率', '1.一般', '2.特売', '3.予約', '4.出切', '5.返品', '合計',
  '1.一般', '2.特売', '3.予約', '建値仕入', '構成比', '1.一般', '2.特売', '3.予約', '4.出切', '販売金額', '消化率', '1.一般', '2.特売', '3.予約', '4.出切', '5.返品', '合計',
];
//エクセルの数量シートで使用
export const quantityColumnNames = [
  '倉庫名', '棚番',
  '1.一般', '2.特売', '3.予約', '仕入BL数', '仕入ﾊﾞﾗ数', '構成比', '1.一般', '2.特売', '3.予約', '4.出切', '販売BL数', '販売ﾊﾞﾗ数', '消化率', '1.一般', '2.特売', '3.予約', '4.出切', '5.返品', '在庫BL数','在庫ﾊﾞﾗ数',
];
//エクセルの金額シートで使用
export const amountColumnNames = [
  '倉庫名', '棚番',
  '1.一般', '2.特売', '3.予約', '建値仕入', '構成比', '1.一般', '2.特売', '3.予約', '4.出切', '販売金額', '消化率', '1.一般', '2.特売', '3.予約', '4.出切', '5.返品', '在庫金額'
];

export const nestedHeaders = [
  [
    { label: '倉庫', colspan: 2 },
    { label: '仕入BL数 (%YYM%)', colspan: 5 }, { label: '売上BL数 (%YYM%)', colspan: 6 }, { label: '在庫BL数%HH%', colspan: 6 },
    { label: '仕入金額 (%YYM%)', colspan: 5 }, { label: '売上金額 (%YYM%)', colspan: 6 }, { label: '在庫金額%HH%', colspan: 6 },
  ],
  columnNames,
];
//エクセルの数量シートで使用
export const quantityNestedHeaders = [
  [
    { label: '倉庫', colspan: 2 },
    { label: '仕入数 (%YYM%)', colspan: 6 }, { label: '売上数 (%YYM%)', colspan: 7 }, { label: '在庫数%HH%', colspan: 7 },
  ],
  quantityColumnNames,
];
//エクセルの金額シートで使用
export const amountNestedHeaders = [
  [
    { label: '倉庫', colspan: 2 },
    { label: '仕入金額 (%YYM%)', colspan: 5 }, { label: '売上金額 (%YYM%)', colspan: 6 }, { label: '在庫金額%HH%', colspan: 6 },
  ],
  amountColumnNames,
];

export const headerClasses = [
  {
    KKAM: "itemlist_H", CNR: "itemlist_H",
    //以下に貼り付け
    SQTY1: "itemlist_I", SQTY2: "itemlist_I", SQTY3: "itemlist_I", SQTY: "itemlist_I", SQTY_PS: "itemlist_I", SQTYR: "itemlist_I",
    SKIN1: "itemlist_I", SKIN2: "itemlist_I", SKIN3: "itemlist_I", SKIN: "itemlist_I", SKINR: "itemlist_I",
    HQTY1: "itemlist_C", HQTY2: "itemlist_C", HQTY3: "itemlist_C", HQTY4: "itemlist_C", HQTY: "itemlist_C", HQTY_PS: "itemlist_C", HQTYR: "itemlist_C",
    HKIN1: "itemlist_C", HKIN2: "itemlist_C", HKIN3: "itemlist_C", HKIN4: "itemlist_C", HKIN: "itemlist_C", HKINR: "itemlist_C",
    RQTY: "itemlist_X", TQTY: "itemlist_X", YQTY: "itemlist_X", FQTY: "itemlist_X", AZKE: "itemlist_X", ZQTY: "itemlist_X", ZQTY_PS: "itemlist_X",
    RAMT: "itemlist_X", TAMT: "itemlist_X", YAMT: "itemlist_X", FAMT: "itemlist_X", AAMT: "itemlist_X", ZAMT: "itemlist_X",
  },
  {
    KKAM: "itemlist_H", CNR: "itemlist_H",
    //以下に貼り付け
    SQTY1: "itemlist_I", SQTY2: "itemlist_I", SQTY3: "itemlist_I", SQTY: "itemlist_I", SQTY_PS: "itemlist_I", SQTYR: "itemlist_I",
    SKIN1: "itemlist_I", SKIN2: "itemlist_I", SKIN3: "itemlist_I", SKIN: "itemlist_I", SKINR: "itemlist_I",
    HQTY1: "itemlist_C", HQTY2: "itemlist_C", HQTY3: "itemlist_C", HQTY4: "itemlist_C", HQTY: "itemlist_C", HQTY_PS: "itemlist_C", HQTYR: "itemlist_C",
    HKIN1: "itemlist_C", HKIN2: "itemlist_C", HKIN3: "itemlist_C", HKIN4: "itemlist_C", HKIN: "itemlist_C", HKINR: "itemlist_C",
    RQTY: "itemlist_X", TQTY: "itemlist_X", YQTY: "itemlist_X", FQTY: "itemlist_X", AZKE: "itemlist_X", ZQTY: "itemlist_X", ZQTY_PS: "itemlist_X",
    RAMT: "itemlist_X", TAMT: "itemlist_X", YAMT: "itemlist_X", FAMT: "itemlist_X", AAMT: "itemlist_X", ZAMT: "itemlist_X",
  },
]

export const colWidths = [
  150, 100,
  80, 80, 80, 80, 80,
  80, 80, 80, 80, 80,
  80, 80, 80, 80, 80, 80, 80,
  80, 80, 80, 80, 80, 80,
  80, 80, 80, 80, 80,
  80, 80, 80, 80, 80, 80,
];

export const columnTypes = [
  { data: 'KKAM', type: 'text', readOnly: true, },
  { data: 'CNR', type: 'text', readOnly: true, },

  { data: 'SQTY1', type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0.0', }, },
  { data: 'SQTY2', type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0.0', }, },
  { data: 'SQTY3', type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0.0', }, },
  { data: 'SQTY', type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0.0', }, },
  { data: 'SQTYR', type: 'numeric', readOnly: true, numericFormat: { pattern: '0.0%', }, },

  { data: 'HQTY1', type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0.0', }, },
  { data: 'HQTY2', type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0.0', }, },
  { data: 'HQTY3', type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0.0', }, },
  { data: 'HQTY4', type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0.0', }, },
  { data: 'HQTY', type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0.0', }, },
  { data: 'HQTYR', type: 'numeric', readOnly: true, numericFormat: { pattern: '0.0%', }, },

  { data: 'RQTY', type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0.0', }, },
  { data: 'TQTY', type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0.0', }, },
  { data: 'YQTY', type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0.0', }, },
  { data: 'FQTY', type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0.0', }, },
  { data: 'AZKE', type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0.0', }, },
  { data: 'ZQTY', type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0.0', }, },

  { data: 'SKIN1', type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, },
  { data: 'SKIN2', type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, },
  { data: 'SKIN3', type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, },
  { data: 'SKIN', type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, },
  { data: 'SKINR', type: 'numeric', readOnly: true, numericFormat: { pattern: '0.0%', }, },

  { data: 'HKIN1', type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, },
  { data: 'HKIN2', type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, },
  { data: 'HKIN3', type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, },
  { data: 'HKIN4', type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, },
  { data: 'HKIN', type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, },
  { data: 'HKINR', type: 'numeric', readOnly: true, numericFormat: { pattern: '0.0%', }, },

  { data: 'RAMT', type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, },
  { data: 'TAMT', type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, },
  { data: 'YAMT', type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, },
  { data: 'FAMT', type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, },
  { data: 'AAMT', type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, },
  { data: 'ZAMT', type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, },
];

//エクセルの数量シートで使用
export const quantityColumnExcelTypes = [
  { data: 'KKAM', type: 'text', readOnly: true, },
  { data: 'CNR', type: 'text', readOnly: true, },

  { data: 'SQTY1', type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0.0', }, },
  { data: 'SQTY2', type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0.0', }, },
  { data: 'SQTY3', type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0.0', }, },
  { data: 'SQTY', type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0.0', }, },
  { data: 'SQTY_PS', type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, },
  { data: 'SQTYR', type: 'numeric', readOnly: true, numericFormat: { pattern: '0.0%', }, },

  { data: 'HQTY1', type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0.0', }, },
  { data: 'HQTY2', type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0.0', }, },
  { data: 'HQTY3', type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0.0', }, },
  { data: 'HQTY4', type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0.0', }, },
  { data: 'HQTY', type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0.0', }, },
  { data: 'HQTY_PS', type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, },
  { data: 'HQTYR', type: 'numeric', readOnly: true, numericFormat: { pattern: '0.0%', }, },

  { data: 'RQTY', type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0.0', }, },
  { data: 'TQTY', type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0.0', }, },
  { data: 'YQTY', type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0.0', }, },
  { data: 'FQTY', type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0.0', }, },
  { data: 'AZKE', type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0.0', }, },
  { data: 'ZQTY', type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0.0', }, },
  { data: 'ZQTY_PS', type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, },
]
//エクセルの金額シートで使用
export const amountColumnExcelTypes = [
  { data: 'KKAM', type: 'text', readOnly: true, },
  { data: 'CNR', type: 'text', readOnly: true, },

  { data: 'SKIN1', type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, },
  { data: 'SKIN2', type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, },
  { data: 'SKIN3', type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, },
  { data: 'SKIN', type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, },
  { data: 'SKINR', type: 'numeric', readOnly: true, numericFormat: { pattern: '0.0%', }, },

  { data: 'HKIN1', type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, },
  { data: 'HKIN2', type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, },
  { data: 'HKIN3', type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, },
  { data: 'HKIN4', type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, },
  { data: 'HKIN', type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, },
  { data: 'HKINR', type: 'numeric', readOnly: true, numericFormat: { pattern: '0.0%', }, },

  { data: 'RAMT', type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, },
  { data: 'TAMT', type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, },
  { data: 'YAMT', type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, },
  { data: 'FAMT', type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, },
  { data: 'AAMT', type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, },
  { data: 'ZAMT', type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, },
];

//function

export const keyFromCol = (column:number):string => {
  return colKeys[column];
}
export const keyFromCols = (columns:number[]):string[] => {
  const vals:string[] = [];
  columns.forEach(column => vals.push(keyFromCol(column)))
  return vals;
}
export const colFromKey = (colKey:string):number => {
  return colKeysMap[colKey];
}
export const colFromKeys = (colKeys:string[]):number[] => {
  const vals:number[] = [];
  colKeys.forEach(colKey => vals.push(colFromKey(colKey)))
  return vals;
}

